import React, { FC } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import TransferList from "../../TransferList";

interface GenericModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    primaryButtonLabel?: string;
    outlineButtonLabel?: string;
    onPrimaryButtonClick?: () => void;
    onOutlineButtonClick?: () => void;
    options: any[],
    setSelecionadaMulti: (value: any) => void,
    selecionadaMulti: any[],
    size?: 'sm' | 'lg' | 'xl',
    anos?: any[],
    setAnoSelected?: (value: any) => void
}

const ModalTransferList: FC<GenericModalProps> = ({
    show,
    onHide,
    title,
    primaryButtonLabel = "Confirmar",
    outlineButtonLabel = "Cancelar",
    onPrimaryButtonClick,
    onOutlineButtonClick,
    options,
    setAnoSelected,
    setSelecionadaMulti,
    selecionadaMulti,
    size = 'lg',
    anos
}) => {
    function gerarSelect(anos: any[]) {
        var optionYear = []
        for (let i = anos[0].min; i <= anos[0].max; i++) optionYear.push(i)
        return (
            <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Ano do informe</Form.Label>
                <Form.Control id={'selectAnosinput'} as="select" defaultValue="Choose..." onChange={(e) => setAnoSelected(e)}>
                    <option key={'-1'} value={'-1'}>Escolha o ano</option>
                    {optionYear.map((i: any) => { return <option key={i} value={i}>{i}</option> })}
                </Form.Control>
            </Form.Group>
        )
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size={size}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TransferList
                    onChange={setSelecionadaMulti}
                    options={options}
                    selected={selecionadaMulti}
                />
                {anos && gerarSelect(anos)}
            </Modal.Body>
            <Modal.Footer>
                {outlineButtonLabel && (
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={onOutlineButtonClick || onHide}
                    >
                        {outlineButtonLabel}
                    </Button>
                )}
                {primaryButtonLabel && (
                    <Button
                        size="sm"
                        variant="primary"
                        onClick={document.getElementById('selectAnosinput')?.value === '-1' ? () => window.alert("Escolha o ano que deseja gerar o informe.") : onPrimaryButtonClick}
                    >
                        {primaryButtonLabel}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default ModalTransferList;
