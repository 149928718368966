import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px 40px;
  overflow-y: auto;
  gap: 20px;
  border-radius: 8px;
  max-width: 1920px;

  @media (max-width: 720px) {
    width: 100%;
    max-height: 100%;
    padding: 20px 0px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0);
    gap: 20px;
  }
`;

export const StyledContentContainer = styled.div`
width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 40px 10px;
  overflow-y: auto;
  gap: 20px;
  border-radius: 8px;

  @media (max-width: 720px) {
    width: 100%;
    max-height: 100%;
    padding: 20px 0px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0);
    gap: 20px;
  }
`;

export const StyledTitle = styled.h2`
  color: #119E91;
  font-weight: bold;
`;

export const StyledData = styled.h4`
  color: #119E91;
`;
export const StyledDataStrong = styled.strong`
  color: #119E91;
`;


export const StyledText = styled.p`
  align-self: baseline;
  padding-left: 20px;
`;

export const StyledList = styled.ul`
  width: 100%;
`;

export const StyledListItem = styled.li`
  margin: 20px 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: ${props => props.expandido ? '#ffff' : 'transparent'};
`;

export const StyledDetails = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const StyledImageContainer = styled.img`
  width: 70%;
  height: auto;
  align-self: center;

  @media (max-width: 720px) {
    width: 300px;
  }
`;

export const StyledVideoContainer = styled.video`
  width: 40%;
  height: 300px;
  align-self: center;

  @media (max-width: 720px) {
    width: 250px;
    height: 240px;
  }
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 720px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const StyledTypeContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 720px) {
    width: 80%;
  }
`;

export const StyledTypeButtonContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 720px) {
    width: 80%;
  }
`;

export const StyledArchiveButton = styled.div`
  margin-top: 20px;
`;