import React, { useEffect, useState } from "react";
import { dashboardData } from "./mock.ts";
import {
  DashboardContainer,
  Title,
  Value,
  PercentageRow,
  Percentage,
  Select,
  SelectContainer,
  DataItem,
  Row,
  SelectDateContainer,
  RowSpinner,
} from "./styles.ts";
import { Button, TextField, Tooltip } from "@material-ui/core";
import { useCombos } from "../../contexts/useCombo.js";
import Spinner from "react-bootstrap/esm/Spinner";
import { useUnidades } from "../../contexts/useUnidades.js";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../../services/api.js";
import { useCookies } from "react-cookie";
interface SelectedComboProps {
  value: string;
  label: string;
  tipo: string;
  categoria: string;
}

const ToplineTicker: React.FC<SelectedComboProps> = ({ value, label }) => {
  const div = dashboardData.reduce((rows, item, index) => {
    if (index % 4 === 0) rows.push([]);
    rows[rows.length - 1].push(item);
    return rows;
  }, []);

  const loadRestaurante = false;
  const combos = useCombos(loadRestaurante);
  const unidades = useUnidades();
  const [opcoesUnidades, setOpcoesUnidades] = useState([]);
  const [opcoesCarregadas, setOpcoesCarregadas] = useState(false);
  const [selectedCombo, setSelectedCombo] = useState<SelectedComboProps>({
    value: value,
    label: label,
    tipo: "combo",
    categoria: "Combos",
  });
  const [selectedMonth, setSelectedMonth] = useState("ytd");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(["usuario"]);
  const [dashboardApiData, setDashboardApiData] = useState([]);
  const isMobile = window.innerWidth < 720;

  async function loadDashboardData() {
    try {
      const response = await api.get(
        `/dashboard/topline/indicadores?mes=${selectedMonth}&unidade_id=${selectedCombo?.value}&tipo_unidade=${selectedCombo?.tipo}&ano=${selectedYear}&usuario_id=${cookies.usuario.id}`,
        {
          headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
        }
      );
      setDashboardApiData(response.data);
    } catch (error) {
      console.error("Erro ao carregar dados: " + error);
    }
  }

  useEffect(() => {
    loadDashboardData();
  }, []);

  const handleComboChange = (event, value) => {
    setSelectedCombo(value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleApplyClick = () => {
    setIsLoading(true);
    loadDashboardData();

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (unidades) {
      const opcoes = unidades.map((unidade) => ({
        categoria: "Unidades",
        tipo: "unidade",
        label: unidade.unidade,
        value: unidade.id,
      }));
      setOpcoesUnidades(opcoes);
    }
  }, [unidades]);

  const opcoesCombos =
    combos?.map((combo) => ({
      categoria: "Combos",
      tipo: "combo",
      label: combo.label,
      value: combo.value,
    })) || [];

  const opcoesRede = {
    categoria: "Rede",
    tipo: "rede",
    label: "Rede",
    value: "rede",
  };

  useEffect(() => {
    if (combos.length > 0 && unidades.length > 0) {
      setOpcoesCarregadas(true);
    }
  }, [combos, unidades]);

  const opcoesAgrupadas = [...opcoesCombos, ...opcoesUnidades, opcoesRede];

  const isButtonDisabled = !selectedCombo && !selectedMonth && !selectedYear;
  return (
    <DashboardContainer>
      <SelectContainer>
        <SelectDateContainer>
          <Select onChange={handleMonthChange}>
            <option value={"ytd"}>YTD</option>
            {[
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro",
            ].map((month, index) => (
              <option key={"mes" + index} value={index + 1}>
                {month}
              </option>
            ))}
          </Select>
          <Select onChange={handleYearChange}>
            {Array(new Date().getFullYear() - 2018)
              .fill(1)
              .map((_, idx) => {
                const year = new Date().getFullYear() - idx;
                return (
                  <option key={"ano" + idx} value={year}>
                    {year}
                  </option>
                );
              })}
          </Select>
        </SelectDateContainer>
        {opcoesCarregadas ? (
          <Autocomplete
            value={selectedCombo}
            onChange={handleComboChange}
            sx={{ width: isMobile ? "100%" : 200 }}
            options={opcoesAgrupadas.sort(
              (a, b) => -b.categoria.localeCompare(a.categoria)
            )}
            groupBy={(option) => option.categoria}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Escolha uma opção" />
            )}
          />
        ) : (
          <div>Carregando opções...</div>
        )}
      </SelectContainer>

      {isLoading ? (
        <RowSpinner>
          <Spinner animation="border" role="status" variant="primary" />
        </RowSpinner>
      ) : (
        <Row>
          {dashboardApiData.map((data, rowIndex) => (
            <DataItem key={rowIndex}>
              <Title>{data.titulo}</Title>
              <Value style={{ color: data.cor_valor_format }}>
                {data.valor_format}
              </Value>
              <PercentageRow>
                <Tooltip title={data.tooltip_variacao_text} placement="right">
                  <Percentage
                    style={{ color: data.cor_variacao_periodo_anterior }}
                  >
                    {data.variacao_periodo_anterior}
                  </Percentage>
                </Tooltip>
                <Tooltip
                  title={data.tooltip_variacao_atingir_budget}
                  placement="left"
                >
                  <Percentage
                    style={{ color: data.cor_variacao_atingir_budget }}
                  >
                    {data.variacao_atingir_budget}
                  </Percentage>
                </Tooltip>
              </PercentageRow>
            </DataItem>
          ))}
        </Row>
      )}

      <Button
        variant="contained"
        onClick={handleApplyClick}
        disabled={isButtonDisabled}
      >
        Aplicar
      </Button>
    </DashboardContainer>
  );
};

export default ToplineTicker;
