const initial_state = {
    cargos: [],
    permissoes:[],
};

export default function cargos(state = initial_state, action) {
    switch (action.type) {
        case 'CARREGAR_CARGOS':
            return { ...state, cargos: action.cargosLista };

        case 'CARREGAR_PERMISSOES':
            return { ...state, permissoes: action.permissoes };
    
        default:
            return state;
    }
}