import { Button } from "@material-ui/core";
import React from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BiCommentAdd } from "react-icons/bi";
import { IoAttach } from "react-icons/io5";
import { RiQuestionAnswerLine, RiQuestionnaireLine } from "react-icons/ri";
import ModalGenerico from "../modalgenerico.tsx";
import { useCookies } from "react-cookie";

interface ModalComentariosProps {
  open: boolean;
  onClose: () => void;
  title: string;
  razaoData: any[];
  modalComentariosShow: any;
  setNivelContaAtual: (analytics: string) => void;
  comentarios: any[];
  setModalNovoComentarios: (any) => void;
  modalShow: any[];
  notify: (message: any) => void;
  onClickButton: () => void;
  destinatarios: any[];
  anexos: any[];
  setModalRespostaVisible: any;
  adicionarNovoComentario: boolean;
}

const ModalComentarios: React.FC<ModalComentariosProps> = ({
  open,
  onClose,
  title,
  modalComentariosShow,
  comentarios,
  setModalNovoComentarios,
  onClickButton,
  destinatarios,
  anexos,
  setModalRespostaVisible,
  adicionarNovoComentario = true,
}) => {
  const [cookies] = useCookies(["usuario"]);
  const blink = async (id: string) => {
    const element = document.getElementById(`destinatario-${id}`);

    if (!element) return;

    const toggleStyles = (backgroundColor: string, color: string) => {
      element.style.backgroundColor = backgroundColor;
      element.style.color = color;
    };

    const wait = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    toggleStyles("blue", "white");
    await wait(50);
    toggleStyles("transparent", "black");
    await wait(50);
    toggleStyles("blue", "white");
    await wait(50);
    toggleStyles("transparent", "black");
  };

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      buttonContent={<BiCommentAdd />}
      buttonContentOptional={
        adicionarNovoComentario ? (
          <OverlayTrigger
            overlay={
              <Tooltip id={""} style={{ zIndex: 100000 }}>
                Adicionar comentarios!
              </Tooltip>
            }
          >
            <Button
              size="large"
              style={{
                marginLeft: "8px",
                backgroundColor: "lightblue",
                minWidth: "auto",
                width: "40px",
                height: "40px",
                padding: "0px",
              }}
              id={"buttonAddComentarios-"}
              onClick={() => {
                setModalNovoComentarios([
                  true,
                  modalComentariosShow[1],
                  modalComentariosShow[2],
                  modalComentariosShow[4],
                  //o 3 é para passar o mes(number) ou string
                ]);
              }}
            >
              <BiCommentAdd />
            </Button>
          </OverlayTrigger>
        ) : null
      }
      dialogContent={
        <>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{ width: "3%" }}></th>
                <th style={{ width: "10%" }}>Unidade</th>
                <th style={{ width: "10%" }}>Remetente</th>
                <th style={{ width: "10%" }}>Destinatários</th>
                <th style={{ width: "10%" }}>Data</th>
                <th>Comentário</th>
                <th style={{ textAlign: "center", width: "10%" }}>Anexos</th>
              </tr>
            </thead>
            <tbody>
              {comentarios
                ?.filter(
                  (d) =>
                    (modalComentariosShow[2].codigo_id ===
                      d.registrodrerazao_id ||
                      modalComentariosShow[2].id === d.registrodrerazao_id) &&
                    (parseFloat(d.mes) ===
                      parseFloat(modalComentariosShow[3]) ||
                      modalComentariosShow[3].includes(d.mes)) &&
                    d.drerazaocomentario_id === null &&
                    d.origem === modalComentariosShow[4]
                )
                .map((d, idx) => {
                  return (
                    <>
                      <tr
                        key={"cmt1" + idx}
                        onClick={() => {
                          Array.from(
                            document.getElementsByClassName("respostas" + d.id)
                          ).forEach((lin) => {
                            if (lin instanceof HTMLElement) {
                              lin.hidden = !lin.hidden;
                            }
                          });
                        }}
                        id={idx.toString()}
                        className={
                          "comentariosheader-" + modalComentariosShow[1]
                        }
                      >
                        {destinatarios?.find(
                          (dest) =>
                            dest.drerazaocomentario_id === parseFloat(d.id) &&
                            dest.id === cookies.usuario.id
                        ) ? (
                          <td
                            onClick={() =>
                              setModalRespostaVisible([
                                true,
                                d,
                                modalComentariosShow[3],
                              ])
                            }
                          >
                            {destinatarios?.filter(
                              (dest) =>
                                dest.drerazaocomentario_id === parseFloat(d.id)
                            ).length > 0 ? (
                              <RiQuestionnaireLine />
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          <td onClick={() => blink(d.id)}>
                            {destinatarios?.filter(
                              (dest) =>
                                dest.drerazaocomentario_id === parseFloat(d.id)
                            ).length > 0 ? (
                              <RiQuestionAnswerLine />
                            ) : (
                              ""
                            )}
                          </td>
                        )}
                        <td>
                          {(() => {
                            const unidadesStr =
                              sessionStorage.getItem("unidades");
                            if (!unidadesStr) return "Unidade não encontrada";

                            const unidades = JSON.parse(unidadesStr);
                            const unidadeEncontrada = unidades?.find(
                              (uni) => uni.id === d.unidade_id
                            );

                            return unidadeEncontrada
                              ? unidadeEncontrada.unidade
                              : "Unidade não encontrada";
                          })()}
                        </td>

                        <td>{d.nome}</td>

                        {destinatarios?.find(
                          (dest) =>
                            dest.drerazaocomentario_id === parseFloat(d.id) &&
                            dest.id === cookies.usuario.id
                        ) ? (
                          <td id={"destinatario-" + d.id}>
                            {destinatarios
                              ?.filter(
                                (dest) =>
                                  dest.drerazaocomentario_id ===
                                  parseFloat(d.id)
                              )
                              .reduce((acc, cv) => {
                                if (cv.id === cookies.usuario.id) {
                                  return acc + " Eu";
                                } else {
                                  return acc + " " + cv.nome;
                                }
                              }, "")}
                          </td>
                        ) : (
                          <td id={"destinatario-" + d.id}>
                            {destinatarios
                              ?.filter(
                                (dest) =>
                                  dest.drerazaocomentario_id ===
                                  parseFloat(d.id)
                              )
                              .reduce((acc, cv) => acc + " " + cv.nome, "")}
                          </td>
                        )}
                        <td>
                          {d.timestamp.substring(10, 8) +
                            "/" +
                            d.timestamp.substring(5, 7) +
                            "/" +
                            d.timestamp.substring(4, 0)}
                        </td>

                        <td>{d.comentario}</td>
                        <td style={{ textAlign: "center" }}>
                          {anexos?.find(
                            (anexo) =>
                              anexo.drerazaocomentario_id === parseInt(d.id)
                          ) ? (
                            <Button
                              style={{
                                minWidth: "auto",
                                width: "40px",
                                height: "40px",
                                padding: "0px",
                              }}
                              onClick={() =>
                                window.open(
                                  anexos?.find(
                                    (anexo) =>
                                      anexo.drerazaocomentario_id ===
                                      parseInt(d.id)
                                  ).url
                                )
                              }
                              variant="outlined"
                              size="small"
                            >
                              <IoAttach />
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                      {comentarios?.find(
                        (respostas) =>
                          (modalComentariosShow[2].codigo_id ===
                            respostas.registrodrerazao_id ||
                            modalComentariosShow[2].id ===
                              respostas.registrodrerazao_id) &&
                          parseFloat(respostas.mes) ===
                            parseFloat(modalComentariosShow[3]) &&
                          respostas.drerazaocomentario_id === parseFloat(d.id)
                      ) ? (
                        <tr
                          key={"cmt2" + idx}
                          className={"respostas" + d.id}
                          hidden
                        >
                          <th colSpan={6}>Respostas:</th>
                          <th style={{ textAlign: "center", width: "10%" }}>
                            Anexos
                          </th>
                        </tr>
                      ) : null}
                      {comentarios
                        ?.filter(
                          (respostas) =>
                            (modalComentariosShow[2].codigo_id ===
                              respostas.registrodrerazao_id ||
                              modalComentariosShow[2].id ===
                                respostas.registrodrerazao_id) &&
                            parseFloat(respostas.mes) ===
                              parseFloat(modalComentariosShow[3]) &&
                            respostas.drerazaocomentario_id ===
                              parseFloat(d.id) &&
                            respostas.origem === modalComentariosShow[4]
                        )
                        .map((respostas, idx) => {
                          return (
                            <tr
                              key={"resposta" + idx}
                              className={
                                "respostas" + respostas.drerazaocomentario_id
                              }
                              hidden
                            >
                              <td></td>
                              <td></td>
                              <td>{respostas.nome}</td>
                              <td></td>
                              <td>
                                {d.timestamp.substring(10, 8) +
                                  "/" +
                                  d.timestamp.substring(5, 7) +
                                  "/" +
                                  d.timestamp.substring(4, 0)}
                              </td>
                              <td>{respostas.comentario}</td>
                              <td style={{ textAlign: "center" }}>
                                {anexos?.find(
                                  (anexo) =>
                                    anexo.drerazaocomentario_id ===
                                    parseInt(respostas.id)
                                ) ? (
                                  <Button
                                    style={{
                                      minWidth: "auto",
                                      width: "40px",
                                      height: "40px",
                                      padding: "0px",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        anexos?.find(
                                          (anexo) =>
                                            anexo.drerazaocomentario_id ===
                                            parseInt(respostas.id)
                                        ).url
                                      )
                                    }
                                    variant="outlined"
                                    size="small"
                                  >
                                    <IoAttach />
                                  </Button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  );
                })}
            </tbody>
          </Table>
        </>
      }
    />
  );
};

export default ModalComentarios;
