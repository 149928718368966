export interface HotelData {
  nome: string;
  realizado: Realizado;
  pickUp: string;
 orcado: Orcado;
  diferencaBudget: string;
  corDiferenca: string;
}

export interface Realizado{
  aptsOcupados: number;
  taxaOcupacao: string;
  taxaOcupacaoAnterior: string;
  diariaMedia: string;
  revPar: string;
  receita: string;
  ranking: number;
}

export interface Orcado{
  aptsOcupados: number;
  taxaOcupacao: string;
  taxaOcupacaoAnterior: string;
  diariaMedia: string;
  revPar: string;
  receita: string;
  ranking: number;
}

export const mockData: HotelData[] = [
  {
    nome: "Copacabana",
    realizado:{
    aptsOcupados: 5163,
    taxaOcupacao: "62%",
    taxaOcupacaoAnterior: "84%",
    diariaMedia: "R$ 362,22",
    revPar: "R$ 223,43",
    receita: "R$ 1.870.146,79",
    ranking: 4,
  },
    pickUp: "R$ 11.966,35",
    orcado:{
      aptsOcupados: 5417,
      taxaOcupacao: "65%",
      taxaOcupacaoAnterior: "84%",
      diariaMedia: "R$ 368,71",
      revPar: "R$ 239,66",
      receita: "R$ 1.907.309,31",
      ranking: 4,
    },
    diferencaBudget: "R$ 127.162,52",
    corDiferenca: "#73c16c"
  },  
  {
    nome: "batata",
    realizado:{
    aptsOcupados: 5163,
    taxaOcupacao: "62%",
    taxaOcupacaoAnterior: "84%",
    diariaMedia: "R$ 362,22",
    revPar: "R$ 223,43",
    receita: "R$ 1.870.146,79",
    ranking: 4,
  },
    pickUp: "R$ 11.966,35",
    orcado:{
      aptsOcupados: 5417,
      taxaOcupacao: "65%",
      taxaOcupacaoAnterior: "84%",
      diariaMedia: "R$ 368,71",
      revPar: "R$ 239,66",
      receita: "R$ 1.907.309,31",
      ranking: 4,
    },
    diferencaBudget: "-R$ 127.162,52",
    corDiferenca: "#ff4d4d"
  },  
  {
    nome: "minha linda batata",
    realizado:{
    aptsOcupados: 5163,
    taxaOcupacao: "62%",
    taxaOcupacaoAnterior: "84%",
    diariaMedia: "R$ 362,22",
    revPar: "R$ 223,43",
    receita: "R$ 1.870.146,79",
    ranking: 4,
  },
    pickUp: "R$ 11.966,35",
    orcado:{
      aptsOcupados: 5417,
      taxaOcupacao: "65%",
      taxaOcupacaoAnterior: "84%",
      diariaMedia: "R$ 368,71",
      revPar: "R$ 239,66",
      receita: "R$ 1.907.309,31",
      ranking: 4,
    },
    diferencaBudget: "R$ 127.162,52",
    corDiferenca: "#73c16c"
  },  
  {
    nome: "batata",
    realizado:{
    aptsOcupados: 5163,
    taxaOcupacao: "62%",
    taxaOcupacaoAnterior: "84%",
    diariaMedia: "R$ 362,22",
    revPar: "R$ 223,43",
    receita: "R$ 1.870.146,79",
    ranking: 4,
  },
    pickUp: "R$ 11.966,35",
    orcado:{
      aptsOcupados: 5417,
      taxaOcupacao: "65%",
      taxaOcupacaoAnterior: "84%",
      diariaMedia: "R$ 368,71",
      revPar: "R$ 239,66",
      receita: "R$ 1.907.309,31",
      ranking: 4,
    },
    diferencaBudget: "-R$ 127.162,52",
    corDiferenca: "#ff4d4d"
  },  
  {
    nome: "batata",
    realizado:{
    aptsOcupados: 5163,
    taxaOcupacao: "62%",
    taxaOcupacaoAnterior: "84%",
    diariaMedia: "R$ 362,22",
    revPar: "R$ 223,43",
    receita: "R$ 1.870.146,79",
    ranking: 4,
  },
    pickUp: "R$ 11.966,35",
    orcado:{
      aptsOcupados: 5417,
      taxaOcupacao: "65%",
      taxaOcupacaoAnterior: "84%",
      diariaMedia: "R$ 368,71",
      revPar: "R$ 239,66",
      receita: "R$ 1.907.309,31",
      ranking: 4,
    },
    diferencaBudget: "-R$ 127.162,52",
    corDiferenca: "#ff4d4d"
  },  
    ]

    export enum TableCellTitle {
      aptsOcupados= 'Apartamentos Ocupados',
      taxaOcupacao= "Taxa de Ocupação",
      taxaOcupacaoAnterior= 'Taxa Ocupacao Anterior',
      diariaMedia= 'Diaria Media',
      revPar= 'RevPar',
      receita= "Receita",
      pickUp= 'PickUp',
      diferencaBudget= 'Diferenca Budget',
    }

    export const visibilidade = {
        realizado_aptsOcupados: false,
        realizado_taxaOcupacao: true,
        realizado_taxaOcupacaoAnterior: true,
        realizado_diariaMedia: true,
        realizado_revPar: true,
        realizado_receita: true,
        orcado_aptsOcupados: false,
        orcado_taxaOcupacao: true,
        orcado_taxaOcupacaoAnterior: true,
        orcado_diariaMedia: true,
        orcado_revPar: true,
        orcado_receita: true,
        pickUp: true,
        diferencaBudget: true
    }