import React, { useEffect, useState } from "react";
import { AiOutlineFileText, AiOutlinePlus } from "react-icons/ai";
import { BiTargetLock } from "react-icons/bi";
import { LuHotel } from "react-icons/lu";
import { Action, Fab } from "react-tiny-fab";
import ModalSelectCombo from "../../../../components/modals/models/modalToplineDash.tsx";
import { useCombos } from "../../../../contexts/useCombo.js";
import {
  LayoutContainer,
  StyledDiv,
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTdContent,
  StyledTh,
  StyledThead,
  StyledThHeader,
  StyledTr,
  StyledTrColums,
  TitleContainer,
} from "./styles.ts";
// import api from "../../../services/api.js";
import { Select } from "@material-ui/core";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { ToastContainer, ToastContentProps, toast } from "react-toastify";
import { formatarValor } from "../../../../helpers/formatarValor.ts";
import ModalVariacaoVertical from "../components/modalVaricaoVertical.tsx";
import { CustomTooltip } from "../tooltip.tsx";
import { SelectContas } from "../components/variacaoVertical.tsx";
import Combo from "../contexts/fetchRealizadoxRealizado.ts";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import ModalRazao from "../../../../components/modals/models/modalRazao.tsx";
import { Unidade, ValorMensalOuAcumulado } from "../load-combo-types.ts";
import openRazao from "../contexts/load-data.ts";
import { FormControl } from "react-bootstrap";
import ModalComentarios from "../../../../components/modals/models/modalComentarios.tsx";
import ModalNovoComentario from "../../../../components/modals/models/modalNovoComentario.tsx";
import ModalRespostas from "../../../../components/modals/models/modalRespostas.tsx";
import { RiMessage2Fill, RiMessage2Line } from "react-icons/ri";
import ModalRazaoAllContas from "../../../../components/modals/models/modalRazaoAllContas.tsx";
import api from "../../../../services/api.js";
import { FaChartLine } from "react-icons/fa6";
import { IoArrowUpOutline } from "react-icons/io5";
import { IoArrowDownSharp } from "react-icons/io5";

interface Combos {
  label: string;
  value: string | number;
  combo_id: any;
}
interface VariacaoConta {
  codigo_id: string | number;
  mensal: {
    valoresMensais: { anterior: any; atual: any } | undefined;
    valoresCorrigidosMensais: { anterior: any; atual: any } | undefined;
  };
  acumulado: {
    valoresAcumulados: { anterior: any; atual: any } | undefined;
    valoresCorrigidosAcumulados: { anterior: any; atual: any } | undefined;
  };
}
export default function DreRealizadoXRealizadoWeb() {
  const [cookies] = useCookies(["usuario"]);
  const dispatch = useDispatch();
  const loadRestaurante = false;
  const combos = useCombos(loadRestaurante);
  const [variacao, setVariacao] = useState<"R$" | "%">("%");
  const [contaSelecionada, setContaSelecionada] = useState<string | number>("");
  const [unidades, setUnidades] = useState<Combos[]>([]);
  const [opcoesCarregadas, setOpcoesCarregadas] = useState(false);
  const [modalVariacaoOpen, setModalVariacaoOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<any>(1);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [contasExpandidas, setContasExpandidas] = useState({});
  const [variacaoVertical, setVariacaoVertical] =
    useState<VariacaoConta | null>(null);
  const [isVariacaoVerticalVisible, setIsVariacaoVerticalVisible] =
    useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [selectedCombo, setSelectedCombo] = useState<any>();
  const [titleSelectedCombo, setTitleSelectedCombo] = useState("");
  const handleComboChange = (event: any, value: any) => {
    setSelectedCombo(value);
  };
  const [termoPesquisa, setTermoPesquisa] = useState("");
  const [loadCombos, setLoadCombos] = useState<Unidade>([]);
  const [indexarIPCA, setIndexarIPCA] = useState(false);

  //comentarios
  const [anexos, setAnexos] = useState<any>([]);
  const [comentarios, setComentarios] = useState<any>([]);
  const [destinatarios, setDestinatarios] = useState<any>([]);
  const [modalComentariosShow, setModalComentariosShow] = useState([
    false,
    null,
    { id: "" },
  ]);
  const [modalNovoComentarios, setModalNovoComentarios] = useState([false]);
  const [modalRespostaVisible, setModalRespostaVisible] = useState([
    false,
    { nome: "", comentario: "" },
  ]);
  const [nivelContaAtual, setNivelContaAtual] = useState<any>(null);
  const [tooltipText, setToolipText] = useState("Adicionar anexo");
  const [modalShow, setModalShow] = useState<any>([false]);
  const [razaoData, setRazaoData] = useState<any>([]);
  const [searchRazaoText, setSearchRazaoText] = useState<any>();
  const [razaoMemory, setRazaoMemory] = useState<any>();
  //final comentarios
  const [modalRazaoAllMonths, setModalRazaoAllMonths] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [totalSaldo, setTotalSaldo] = useState();
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [eventKeyDown, setEventKeyDown] = useState<fetchModalMesesDataParams>();
  const [isAcumulado, setIsAcumulado] = useState(false);
  interface fetchModalMesesDataParams {
    mesInicial: string | number;
    ano: string | number;
    mesFinal?: string | number;
  }
  const fetchModalMesesData = async ({
    mesInicial,
    ano,
    mesFinal,
  }: fetchModalMesesDataParams) => {
    try {
      setIsLoading(true);
      const mes = mesFinal ? `${mesInicial}-${mesFinal}` : mesInicial;
      const anoConsultado = ano;
      const unidade = selectedCombo.comp_combos;
      const search =
        document
          .getElementById("searchInput")
          ?.value.trim()
          .toLowerCase()
          .replace(/,/g, ".") || "";

      const queryParams = new URLSearchParams({
        mes,
        anoConsultado,
        unidade,
        search,
        limit,
        offset,
        organizacao_id: JSON.stringify(cookies.usuario.organizacao_id),
      } as any).toString();

      const response = await api.get(`/razoes?${queryParams}`, {
        headers: {
          authorization: `Bearer ${cookies.usuario.accessToken}`,
        },
      });

      setModalRazaoAllMonths(true);
      setTotalSaldo(response.data.total);
      const newData = response.data.razaoData;

      if (newData.length) {
        setRazaoData((current: any) => [...current, ...newData]);
        setOffset((currentOffset) => currentOffset + limit);
      }
    } catch (err) {
      console.error("Erro ao buscar os dados:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (text: string) => {
    setIsSearchEmpty(text === "");
  };

  const handleKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      setRazaoData([]);
      fetchModalMesesData(eventKeyDown);
    }
  };

  const toggleConta = (codigo_id: string | number) => {
    if (termoPesquisa) return;

    setContasExpandidas((prevState) => ({
      ...prevState,
      [codigo_id]: !prevState[codigo_id],
    }));
  };

  function filtraRazao(searchRazaoText: string) {
    if (searchRazaoText === "") {
      return setRazaoData(razaoMemory);
    }
    const filteredRazao = razaoData.filter(
      (registro: {
        historico: string;
        centrodecusto_id: string | any[];
        data: string | any[];
        credito: {
          toLocaleString: (
            arg0: string,
            arg1: { style: string; currency: string }
          ) => string;
        };
        debito: {
          toLocaleString: (
            arg0: string,
            arg1: { style: string; currency: string }
          ) => string;
        };
      }) => {
        let registroLowercase = registro.historico.toLowerCase();

        let searchTermLowercase = searchRazaoText.toLowerCase();

        return (
          registroLowercase.indexOf(searchTermLowercase) > -1 ||
          registro.centrodecusto_id.includes(searchRazaoText) ||
          registro.data.includes(searchRazaoText) ||
          (
            registro.credito.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }) + " C"
          ).includes(searchRazaoText) ||
          (
            registro.debito.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }) + " D"
          ).includes(searchRazaoText)
        );
      }
    );
    setRazaoData(filteredRazao);
  }

  const opcoesCombos =
    combos?.map((combo: {}) => ({
      categoria: "Combos",
      tipo: "combo",
      ...combo,
    })) || [];

  const notify = (
    $msg:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | ((props: ToastContentProps) => React.ReactNode)
      | null
      | undefined
  ) =>
    toast($msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  const opcoesAgrupadas = [...opcoesCombos];
  const myUnidadesMemory = sessionStorage.getItem("unidadesMemory");

  type VariacaoTipo = "vertical" | "horizontal";
  const calcularVariacao = (
    atual: number,
    anterior: number,
    tipo: VariacaoTipo = "horizontal"
  ) => {
    if (variacao === "R$") {
      return atual - anterior;
    }

    const variacaoPercentual =
      tipo === "vertical"
        ? (atual / anterior) * 100
        : ((atual - anterior) / Math.abs(anterior)) * 100;

    if (isNaN(variacaoPercentual) || !isFinite(variacaoPercentual)) return 0;
    return variacaoPercentual;
  };

  const determinarEstiloDoTexto = (valor: number) => {
    if (valor > 0) return { color: "green", icon: <IoArrowUpOutline /> };
    if (valor < 0) return { color: "red", icon: <IoArrowDownSharp /> };
    return;
  };

  const handleConfirmar = (codigo_id: string | number) => {
    const valoresMensais = loadCombos?.mensal?.find(
      (m) => m?.codigo_id === codigo_id
    )?.value;
    const valoresAcumulados = loadCombos?.acumulado?.find(
      (a) => a?.codigo_id === codigo_id
    )?.value;

    const valoresCorrigidosMensais = loadCombos?.mensal?.find(
      (m) => m?.codigo_id === codigo_id
    )?.valueCorrigido;
    const valoresCorrigidosAcumulados = loadCombos?.acumulado?.find(
      (a) => a?.codigo_id === codigo_id
    )?.valueCorrigido;

    setVariacaoVertical({
      codigo_id: codigo_id,
      mensal: { valoresMensais, valoresCorrigidosMensais },
      acumulado: { valoresAcumulados, valoresCorrigidosAcumulados },
    });
    setIsVariacaoVerticalVisible(true);
    setModalVariacaoOpen(false);
  };

  const handleMonthChange = (event: { target: { value: any } }) => {
    setSelectedMonth(event.target.value);
  };
  const handleYearChange = (event: {
    target: { value: React.SetStateAction<number> };
  }) => {
    setSelectedYear(event.target.value);
  };

  const filtrarContas = (
    contaPai: {
      codigo_id: any;
      descricao: any;
      nivel?: number;
      destaque?: boolean;
      ordem?: number;
      composicao: any;
      contasuperior?: string;
    },
    termoPesquisa: string,
    contas: any[]
  ) => {
    const correspondePai = contaPai.descricao
      .toLowerCase()
      .includes(termoPesquisa.toLowerCase());

    const correspondeCodigoIdPai = contaPai.codigo_id
      .toLowerCase()
      .includes(termoPesquisa.toLowerCase());

    const correspondeFilha = contaPai.composicao?.some((idFilha: any) => {
      const contaFilha = contas.find(
        (c: { codigo_id: any }) => c.codigo_id === idFilha
      );
      return contaFilha?.descricao
        .toLowerCase()
        .includes(termoPesquisa.toLowerCase());
    });

    const correspondeCodigoIdFilha = contaPai.composicao?.some(
      (idFilha: any) => {
        const contaFilha = contas.find(
          (c: { codigo_id: any }) => c.codigo_id === idFilha
        );
        return contaFilha?.codigo_id.includes(termoPesquisa.toLowerCase());
      }
    );

    return (
      correspondePai ||
      correspondeFilha ||
      correspondeCodigoIdPai ||
      correspondeCodigoIdFilha
    );
  };

  useEffect(() => {
    if (termoPesquisa) {
      setContasExpandidas({});
    }
  }, [termoPesquisa]);

  useEffect(() => {
    if (combos?.length > 0) {
      setOpcoesCarregadas(true);
      setSelectedCombo(combos[0]);
    }
    openModal();
  }, [combos]);

  useEffect(() => {
    if (!opcoesCarregadas) return;
    let array: Combos[] = [];
    if (myUnidadesMemory) {
      JSON.parse(myUnidadesMemory).map(
        (loadCombos: { loadCombos: any; id: any; combo_id: any }) => {
          array.push({
            label: loadCombos.loadCombos,
            value: loadCombos.id,
            combo_id: loadCombos.combo_id,
          });
        }
      );
    }
    setUnidades(array);
  }, [myUnidadesMemory, opcoesCarregadas]);

  const fetchData = async () => {
    try {
      const response = await Combo({
        unidade_id: JSON.stringify(selectedCombo?.comp_combos),
        mes: selectedMonth,
        ano: selectedYear,
        cookies: cookies,
      });
      setTitleSelectedCombo(selectedCombo.label);
      setIsAcumulado(response?.indice?.acumulado[0].value?.length <= 1);
      closeModal();
      setIsVariacaoVerticalVisible(false);
      setVariacaoVertical(null);
      setLoadCombos(response);
      setDestinatarios([
        ...response?.destinatariosAntigos,
        ...response?.destinatariosNovos,
      ]);
      setAnexos([...response?.anexosAntigos, ...response?.anexosNovos]);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const handleRightClick = (
    event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
    descricao: string | boolean | { id: string } | null,
    linha: boolean | { id: string } | null,
    meses: boolean | { id: string } | number[] | null
  ) => {
    event.preventDefault();
    setModalComentariosShow([true, descricao, linha, meses, "realizado"]);
  };

  function construirMeses(mesSelecionado: string, tipo: string) {
    let mesIndex = parseInt(mesSelecionado, 10);

    if (isNaN(mesIndex) || mesIndex < 1 || mesIndex > 12) {
      throw new Error("Mês inválido: " + mesSelecionado);
    }

    if (tipo === "mensal") {
      return [mesIndex];
    } else {
      return Array.from({ length: mesIndex }, (_, i) => i + 1);
    }
  }

  function iconComentario(
    tipo,
    epoca,
    isFilha = false,
    contasComposicao = [],
    isDestaque = false,
    grupo
  ) {
    // epoca: novo ou antigo
    // grupo: mensal ou acumulado

    // Função para encontrar comentário pelo código_id.
    const encontrarComentario = (codigo_id) =>
      loadCombos[`comentarios_${epoca}_${grupo}`]?.find(
        (comentario) => comentario?.codigodre_id === codigo_id
      );

    // Filtra e encontra comentários válidos uma vez.
    const comentariosFilhosValidos = contasComposicao
      ?.map((d: any) => encontrarComentario(d?.codigo_id))
      ?.filter(Boolean);

    // Verifica se deve mostrar comentário baseado em tipo ou filhos.
    const mostrarComentario =
      ((tipo?.comentarios && tipo?.comentarios[epoca]?.length > 0) ||
        comentariosFilhosValidos?.length > 0) &&
      !isDestaque;

    if (!mostrarComentario) {
      return;
    }

    // Determina a cor do ícone com base nas condições fornecidas.
    const cor: any = (() => {
      if (
        //paramos nessa condição
        tipo?.comentarios[epoca].some(
          (c) => c.registrodrerazao_id === tipo.codigo_id
        ) ||
        isFilha
      ) {
        return tipo?.comentarios[epoca]?.some(
          (comentario) => comentario.drerazaocomentario_id != null
        )
          ? { filled: true, style: { color: "green" } }
          : tipo?.comentarios[epoca]?.some(
              (comentario) => comentario.destinatarios?.length > 0
            )
          ? { filled: true, style: { color: "red" } }
          : { filled: true, style: { color: "blue" } };
      } else if (comentariosFilhosValidos.length) {
        return comentariosFilhosValidos.some(
          (comentario) => comentario.drerazaocomentario_id != null
        )
          ? { filled: false, style: { color: "green" } }
          : comentariosFilhosValidos.some(
              (comentario) => comentario.destinatarios?.length > 0
            )
          ? { filled: false, style: { color: "red" } }
          : { filled: false, style: { color: "blue" } };
      }
      return { filled: true, style: { display: "none" } };
    })();

    if (cor?.filled) {
      return <RiMessage2Fill style={cor?.style} />;
    } else {
      return <RiMessage2Line style={cor?.style} />;
    }
  }

  return (
    <LayoutContainer>
      <TitleContainer>
        <div className="icon">
          <BiTargetLock />
        </div>
        <div className="text">
          DRE Realizado X Realizado - {titleSelectedCombo}
        </div>
      </TitleContainer>
      <StyledTable>
        <StyledThead>
          <StyledTrColums>
            <StyledThHeader rowSpan={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                Conta
                <input
                  type="text"
                  placeholder="Pesquisar..."
                  value={termoPesquisa}
                  onChange={(e) => setTermoPesquisa(e.target.value)}
                />
              </div>
            </StyledThHeader>
            <StyledThHeader colSpan={3}>Mensal</StyledThHeader>
            <StyledThHeader
              style={isAcumulado === true ? { display: "none" } : {}}
              colSpan={3}
            >
              Acumulado
            </StyledThHeader>
          </StyledTrColums>
          <StyledTrColums>
            {loadCombos?.colunas?.map((coluna, index) => (
              <StyledTh
                key={index}
                style={
                  index === 0
                    ? { display: "none" }
                    : isAcumulado === true && [4, 5, 6].includes(index)
                    ? { display: "none" }
                    : {
                        display: coluna.hidden ? "none" : undefined,
                      }
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  {coluna.label}{" "}
                  {index === 1 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <FaChartLine
                        style={{ width: 20, cursor: "pointer" }}
                        onClick={() => {
                          fetchModalMesesData({
                            mesInicial: selectedMonth,
                            ano: selectedYear - 1,
                          });
                          setEventKeyDown({
                            mesInicial: selectedMonth,
                            ano: selectedYear - 1,
                          });
                        }}
                      />
                      {indexarIPCA && (
                        <CustomTooltip unidade={loadCombos} tipo="mensal" />
                      )}
                    </div>
                  )}
                  {index === 2 && (
                    <>
                      <FaChartLine
                        style={{ width: 20, cursor: "pointer" }}
                        onClick={() => {
                          fetchModalMesesData({
                            mesInicial: selectedMonth,
                            ano: selectedYear,
                          });
                          setEventKeyDown({
                            mesInicial: selectedMonth,
                            ano: selectedYear,
                          });
                        }}
                      />
                    </>
                  )}
                  {index === 4 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <FaChartLine
                        style={{ width: 20, cursor: "pointer" }}
                        onClick={() => {
                          fetchModalMesesData({
                            mesInicial: 1,
                            mesFinal: selectedMonth,
                            ano: selectedYear - 1,
                          });
                          setEventKeyDown({
                            mesInicial: 1,
                            mesFinal: selectedMonth,
                            ano: selectedYear - 1,
                          });
                        }}
                      />
                      {indexarIPCA && (
                        <CustomTooltip unidade={loadCombos} tipo="acumulado" />
                      )}
                    </div>
                  )}
                  {index === 5 && (
                    <>
                      <FaChartLine
                        style={{ width: 20, cursor: "pointer" }}
                        onClick={() => {
                          fetchModalMesesData({
                            mesInicial: 1,
                            mesFinal: selectedMonth,
                            ano: selectedYear,
                          });
                          setEventKeyDown({
                            mesInicial: 1,
                            mesFinal: selectedMonth,
                            ano: selectedYear,
                          });
                        }}
                      />
                    </>
                  )}
                </div>
              </StyledTh>
            ))}
          </StyledTrColums>
        </StyledThead>
        <StyledTbody>
          {loadCombos?.contas
            ?.filter((conta) => {
              return termoPesquisa
                ? conta.nivel
                : conta.composicao !== null && conta.nivel === 1;
            })
            .filter((contaPai) =>
              filtrarContas(contaPai, termoPesquisa, loadCombos.contas)
            )
            .sort((a, b) => a.ordem - b.ordem)
            .map((contaPai) => {
              const contasFilhas = contaPai?.composicao
                ? loadCombos?.contas.filter(
                    (c) => c.contasuperior === contaPai.codigo_id
                  )
                : [];

              const valoresMensaisPai = loadCombos?.mensal?.find(
                (m) => m?.codigo_id === contaPai?.codigo_id
              );
              const valoresAcumuladosPai = loadCombos?.acumulado?.find(
                (a) => a?.codigo_id === contaPai?.codigo_id
              );

              const variacaoMensalPai = valoresMensaisPai
                ? indexarIPCA === true
                  ? calcularVariacao(
                      +valoresMensaisPai?.value?.atual,
                      +valoresMensaisPai?.valueCorrigido?.anterior
                    ).toFixed(2)
                  : calcularVariacao(
                      +valoresMensaisPai?.value?.atual,
                      +valoresMensaisPai?.value?.anterior
                    ).toFixed(2)
                : "-";

              const variacaoAcumuladaPai = valoresAcumuladosPai
                ? indexarIPCA === true
                  ? calcularVariacao(
                      +valoresAcumuladosPai.value.atual,
                      +valoresAcumuladosPai.valueCorrigido.anterior
                    ).toFixed(2)
                  : calcularVariacao(
                      +valoresAcumuladosPai.value.atual,
                      +valoresAcumuladosPai.value.anterior
                    ).toFixed(2)
                : "-";

              const variacaoVerticalMensalPai = valoresMensaisPai
                ? indexarIPCA === true
                  ? calcularVariacao(
                      +valoresMensaisPai?.valueCorrigido.anterior,
                      +variacaoVertical?.mensal?.valoresCorrigidosMensais
                        ?.anterior,
                      "vertical"
                    ).toFixed(2)
                  : calcularVariacao(
                      +valoresMensaisPai?.value.anterior,
                      +variacaoVertical?.mensal?.valoresMensais?.anterior,
                      "vertical"
                    ).toFixed(2)
                : "-";

              const variacaoVerticalAtualPai = valoresMensaisPai
                ? indexarIPCA === true
                  ? calcularVariacao(
                      +valoresMensaisPai?.value.atual,
                      +variacaoVertical?.mensal?.valoresMensais?.atual,
                      "vertical"
                    ).toFixed(2)
                  : calcularVariacao(
                      +valoresMensaisPai?.value.atual,
                      +variacaoVertical?.mensal?.valoresMensais?.atual,
                      "vertical"
                    ).toFixed(2)
                : "-";

              const variacaoVerticalMensalAcumuladoPai = valoresAcumuladosPai
                ? indexarIPCA === true
                  ? calcularVariacao(
                      +valoresAcumuladosPai?.valueCorrigido.anterior,
                      +variacaoVertical?.acumulado?.valoresCorrigidosAcumulados
                        ?.anterior,
                      "vertical"
                    ).toFixed(2)
                  : calcularVariacao(
                      +valoresAcumuladosPai?.value.anterior,
                      +variacaoVertical?.acumulado?.valoresAcumulados?.anterior,
                      "vertical"
                    ).toFixed(2)
                : "-";

              const variacaoVerticalAtualAcumuladoPai = valoresAcumuladosPai
                ? indexarIPCA === true
                  ? calcularVariacao(
                      +valoresAcumuladosPai?.value?.atual,
                      +variacaoVertical?.acumulado?.valoresCorrigidosAcumulados
                        ?.atual,
                      "vertical"
                    )?.toFixed(2)
                  : calcularVariacao(
                      +valoresAcumuladosPai?.value?.atual,
                      +variacaoVertical?.acumulado?.valoresAcumulados?.atual,
                      "vertical"
                    )?.toFixed(2)
                : "-";

              const ContaPaiNome =
                contaPai?.codigo_id === contaPai?.descricao
                  ? contaPai.descricao
                  : `● ${contaPai?.codigo_id} - ${contaPai?.descricao}`;

              return (
                <>
                  <StyledTr
                    style={{
                      display:
                        termoPesquisa && contaPai?.destaque ? "none" : "",
                    }}
                    key={contaPai?.codigo_id}
                    onClick={() => {
                      if (contaPai?.destaque !== true) {
                        toggleConta(contaPai?.codigo_id);
                      }
                    }}
                    destaque={contaPai?.destaque}
                    selectedRow={
                      variacaoVertical?.codigo_id === contaPai?.codigo_id
                    }
                  >
                    <StyledTd>{ContaPaiNome}</StyledTd>
                    <StyledTd
                      onContextMenu={(event) => {
                        setComentarios(valoresMensaisPai?.comentarios.antigos);
                        handleRightClick(
                          event,
                          contaPai.descricao,
                          contaPai,
                          construirMeses(selectedMonth, "mensal")
                        );
                      }}
                      onClick={
                        contaPai.composicao === null
                          ? () => {
                              setComentarios(
                                valoresMensaisPai?.comentarios.antigos
                              );

                              openRazao({
                                ano: selectedYear - 1,
                                cookies: cookies,
                                dispatch: dispatch,
                                mes: selectedMonth,
                                codigo: contaPai.codigo_id,
                                unidadeId: JSON.stringify(
                                  selectedCombo.comp_combos
                                ),
                                setAnexosRazao: setAnexos,
                                setModalShow: setModalShow,
                                setRazaoData: setRazaoData,
                                setRazaoMemory: setRazaoMemory,
                                valor: valoresMensaisPai?.value.anterior,
                                conta: contaPai.descricao,
                                mesFinal: selectedMonth,
                                type: "combos",
                                notify: notify,
                              });
                            }
                          : () => null
                      }
                    >
                      <StyledTdContent>
                        {formatarValor(
                          indexarIPCA === true
                            ? valoresMensaisPai?.valueCorrigido.anterior
                            : valoresMensaisPai?.value.anterior,
                          "moeda"
                        ) ?? "R$ -"}{" "}
                        {isVariacaoVerticalVisible && (
                          <div
                            style={{
                              color: determinarEstiloDoTexto(
                                +variacaoVerticalMensalPai
                              )?.color,
                            }}
                          >
                            {variacao +
                              " " +
                              formatarValor(
                                variacaoVerticalMensalPai,
                                "numero"
                              )}
                            {
                              determinarEstiloDoTexto(
                                +variacaoVerticalMensalPai
                              )?.icon
                            }
                          </div>
                        )}
                        <div>
                          {iconComentario(
                            valoresMensaisPai,
                            "antigos",
                            false,
                            contasFilhas,
                            contaPai?.destaque,
                            "mensal"
                          )}{" "}
                        </div>
                      </StyledTdContent>
                    </StyledTd>
                    <StyledTd
                      onContextMenu={(event) => {
                        setComentarios(valoresMensaisPai?.comentarios.novos);

                        handleRightClick(
                          event,
                          contaPai.descricao,
                          contaPai,
                          construirMeses(selectedMonth, "mensal")
                        );
                      }}
                      onClick={
                        contaPai.composicao === null
                          ? () => {
                              setComentarios(
                                valoresMensaisPai?.comentarios.novos
                              );

                              openRazao({
                                ano: selectedYear,
                                cookies: cookies,
                                dispatch: dispatch,
                                mes: selectedMonth,
                                codigo: contaPai.codigo_id,
                                unidadeId: JSON.stringify(
                                  selectedCombo.comp_combos
                                ),
                                setAnexosRazao: setAnexos,
                                setModalShow: setModalShow,
                                setRazaoData: setRazaoData,
                                setRazaoMemory: setRazaoMemory,
                                valor: valoresMensaisPai?.value.atual,
                                conta: contaPai.descricao,
                                mesFinal: selectedMonth,
                                type: "combos",
                                notify: notify,
                              });
                            }
                          : () => null
                      }
                    >
                      <StyledTdContent>
                        {formatarValor(
                          valoresMensaisPai?.value.atual,
                          "moeda"
                        ) ?? "R$ -"}{" "}
                        {isVariacaoVerticalVisible && (
                          <div
                            style={{
                              color: determinarEstiloDoTexto(
                                +variacaoVerticalAtualPai
                              )?.color,
                            }}
                          >
                            {variacao +
                              " " +
                              formatarValor(variacaoVerticalAtualPai, "numero")}
                            {
                              determinarEstiloDoTexto(+variacaoVerticalAtualPai)
                                ?.icon
                            }
                          </div>
                        )}
                        <div>
                          {iconComentario(
                            valoresMensaisPai,
                            "novos",
                            false,
                            contasFilhas,
                            contaPai?.destaque,
                            "mensal"
                          )}{" "}
                        </div>
                      </StyledTdContent>
                    </StyledTd>
                    <StyledTd
                      style={{
                        color: determinarEstiloDoTexto(+variacaoMensalPai)
                          ?.color,
                      }}
                    >
                      <StyledTdContent>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                            alignItems: "center",
                          }}
                        >
                          {variacaoMensalPai !== "-"
                            ? variacao === "%"
                              ? variacaoMensalPai + "%"
                              : formatarValor(
                                  parseFloat(variacaoMensalPai),
                                  "moeda"
                                )
                            : "-"}
                          {determinarEstiloDoTexto(+variacaoMensalPai)?.icon}
                        </div>
                      </StyledTdContent>
                    </StyledTd>
                    <StyledTd
                      style={isAcumulado === true ? { display: "none" } : {}}
                      onContextMenu={(event) => {
                        setComentarios(
                          valoresAcumuladosPai?.comentarios.antigos
                        );

                        handleRightClick(
                          event,
                          contaPai.descricao,
                          contaPai,
                          construirMeses(selectedMonth, "acumulado")
                        );
                      }}
                      onClick={
                        contaPai.composicao === null
                          ? () => {
                              setComentarios(
                                valoresAcumuladosPai?.comentarios.antigos
                              );

                              openRazao({
                                ano: selectedYear - 1,
                                cookies: cookies,
                                dispatch: dispatch,
                                mes: "01",
                                codigo: contaPai.codigo_id,
                                unidadeId: JSON.stringify(
                                  selectedCombo.comp_combos
                                ),
                                setAnexosRazao: setAnexos,
                                setModalShow: setModalShow,
                                setRazaoData: setRazaoData,
                                setRazaoMemory: setRazaoMemory,
                                valor: valoresAcumuladosPai?.value.anterior,
                                conta: contaPai.descricao,
                                mesFinal: selectedMonth,
                                type: "combos",
                                notify: notify,
                              });
                            }
                          : () => null
                      }
                    >
                      <StyledTdContent>
                        {formatarValor(
                          indexarIPCA === true
                            ? valoresAcumuladosPai?.valueCorrigido.anterior
                            : valoresAcumuladosPai?.value.anterior,
                          "moeda" ?? "R$ -"
                        )}{" "}
                        {isVariacaoVerticalVisible && (
                          <div
                            style={{
                              color: determinarEstiloDoTexto(
                                +variacaoVerticalMensalAcumuladoPai
                              )?.color,
                            }}
                          >
                            {variacao +
                              " " +
                              formatarValor(
                                variacaoVerticalMensalAcumuladoPai,
                                "numero"
                              )}
                            {
                              determinarEstiloDoTexto(
                                +variacaoVerticalMensalAcumuladoPai
                              )?.icon
                            }
                          </div>
                        )}
                        <div>
                          {iconComentario(
                            valoresAcumuladosPai,
                            "antigos",
                            false,
                            contasFilhas,
                            contaPai?.destaque,
                            "acumulado"
                          )}{" "}
                        </div>
                      </StyledTdContent>
                    </StyledTd>
                    <StyledTd
                      style={isAcumulado === true ? { display: "none" } : {}}
                      onContextMenu={(event) => {
                        setComentarios(valoresAcumuladosPai?.comentarios.novos);

                        handleRightClick(
                          event,
                          contaPai.descricao,
                          contaPai,
                          construirMeses(selectedMonth, "mensal")
                        );
                      }}
                      onClick={
                        contaPai.composicao === null
                          ? () => {
                              setComentarios(
                                valoresAcumuladosPai?.comentarios.novos
                              );

                              openRazao({
                                ano: selectedYear,
                                cookies: cookies,
                                dispatch: dispatch,
                                mes: "01",
                                codigo: contaPai.codigo_id,
                                unidadeId: JSON.stringify(
                                  selectedCombo.comp_combos
                                ),
                                setAnexosRazao: setAnexos,
                                setModalShow: setModalShow,
                                setRazaoData: setRazaoData,
                                setRazaoMemory: setRazaoMemory,
                                valor: valoresAcumuladosPai?.value.atual,
                                conta: contaPai.descricao,
                                mesFinal: selectedMonth,
                                type: "combos",
                                notify: notify,
                              });
                            }
                          : () => null
                      }
                    >
                      <StyledTdContent>
                        {formatarValor(
                          valoresAcumuladosPai?.value.atual,
                          "moeda" ?? "R$ -"
                        )}{" "}
                        {isVariacaoVerticalVisible && (
                          <div
                            style={{
                              color: determinarEstiloDoTexto(
                                +variacaoVerticalAtualAcumuladoPai
                              )?.color,
                            }}
                          >
                            {variacao +
                              " " +
                              formatarValor(
                                variacaoVerticalAtualAcumuladoPai,
                                "numero"
                              )}
                            {
                              determinarEstiloDoTexto(
                                +variacaoVerticalAtualAcumuladoPai
                              )?.icon
                            }
                          </div>
                        )}
                        <div>
                          {iconComentario(
                            valoresAcumuladosPai,
                            "novos",
                            false,
                            contasFilhas,
                            contaPai?.destaque,
                            "acumulado"
                          )}{" "}
                        </div>
                      </StyledTdContent>
                    </StyledTd>
                    <StyledTd
                      style={
                        isAcumulado === true
                          ? { display: "none" }
                          : {
                              color: determinarEstiloDoTexto(
                                +variacaoAcumuladaPai
                              )?.color,
                            }
                      }
                    >
                      <StyledTdContent>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                            alignItems: "center",
                          }}
                        >
                          {variacaoAcumuladaPai !== "-"
                            ? variacao === "%"
                              ? variacaoAcumuladaPai + "%"
                              : formatarValor(
                                  parseFloat(variacaoAcumuladaPai),
                                  "moeda"
                                )
                            : "-"}
                          {determinarEstiloDoTexto(+variacaoAcumuladaPai)?.icon}
                        </div>
                      </StyledTdContent>
                    </StyledTd>
                  </StyledTr>
                  {contasExpandidas[contaPai?.codigo_id] &&
                    contasFilhas.map((contaFilha) => {
                      if (!contaFilha) return null;

                      const valoresMensaisFilha = loadCombos?.mensal?.find(
                        (m) => m?.codigo_id === contaFilha?.codigo_id
                      );
                      const valoresAcumuladosFilha = loadCombos.acumulado.find(
                        (a) => a?.codigo_id === contaFilha?.codigo_id
                      );

                      const variacaoMensalFilha = valoresMensaisFilha
                        ? indexarIPCA === true
                          ? calcularVariacao(
                              +valoresMensaisFilha?.value?.atual,
                              +valoresMensaisFilha?.valueCorrigido?.anterior
                            ).toFixed(2)
                          : calcularVariacao(
                              +valoresMensaisFilha?.value?.atual,
                              +valoresMensaisFilha?.value?.anterior
                            ).toFixed(2)
                        : "-";

                      const variacaoAcumuladaFilha = valoresAcumuladosFilha
                        ? indexarIPCA === true
                          ? calcularVariacao(
                              +valoresAcumuladosFilha?.value?.atual,
                              +valoresAcumuladosFilha?.valueCorrigido?.anterior
                            ).toFixed(2)
                          : calcularVariacao(
                              +valoresAcumuladosFilha?.value?.atual,
                              +valoresAcumuladosFilha?.value?.anterior
                            ).toFixed(2)
                        : "-";

                      const variacaoVerticalMensalFilha = valoresMensaisFilha
                        ? indexarIPCA === true
                          ? calcularVariacao(
                              +valoresMensaisFilha?.value?.atual,
                              +variacaoVertical?.mensal
                                ?.valoresCorrigidosMensais?.atual,
                              "vertical"
                            ).toFixed(2)
                          : calcularVariacao(
                              +valoresMensaisFilha?.value?.atual,
                              +variacaoVertical?.mensal.valoresMensais?.atual,
                              "vertical"
                            ).toFixed(2)
                        : "-";

                      const variacaoVerticalMensalAnteriorFilha =
                        valoresMensaisFilha
                          ? indexarIPCA === true
                            ? calcularVariacao(
                                +valoresMensaisFilha?.valueCorrigido.anterior,
                                +variacaoVertical?.mensal
                                  .valoresCorrigidosMensais?.anterior,
                                "vertical"
                              ).toFixed(2)
                            : calcularVariacao(
                                +valoresMensaisFilha?.value.anterior,
                                +variacaoVertical?.mensal.valoresMensais
                                  ?.anterior,
                                "vertical"
                              ).toFixed(2)
                          : "-";

                      const variacaoVerticalAcumuladoFilha =
                        valoresAcumuladosFilha
                          ? indexarIPCA === true
                            ? calcularVariacao(
                                +valoresAcumuladosFilha?.value?.atual,
                                +variacaoVertical?.acumulado.valoresAcumulados
                                  ?.atual,
                                "vertical"
                              ).toFixed(2)
                            : calcularVariacao(
                                +valoresAcumuladosFilha?.value?.atual,
                                +variacaoVertical?.acumulado.valoresAcumulados
                                  ?.atual,
                                "vertical"
                              ).toFixed(2)
                          : "-";

                      const variacaoVerticalAcumuladoAnteriorFilha =
                        valoresAcumuladosFilha
                          ? indexarIPCA === true
                            ? calcularVariacao(
                                +valoresAcumuladosFilha?.valueCorrigido
                                  .anterior,
                                +variacaoVertical?.acumulado
                                  .valoresCorrigidosAcumulados?.anterior,
                                "vertical"
                              ).toFixed(2)
                            : calcularVariacao(
                                +valoresAcumuladosFilha?.value.anterior,
                                +variacaoVertical?.acumulado.valoresAcumulados
                                  ?.anterior,
                                "vertical"
                              ).toFixed(2)
                          : "-";

                      const ContaFilhaNome =
                        contaFilha?.codigo_id === contaFilha?.descricao
                          ? contaFilha.descricao
                          : `● ${contaFilha?.codigo_id} - ${contaFilha?.descricao}`;

                      const contasFilhasV2 = contaFilha?.composicao
                        ? loadCombos?.contas.filter(
                            (c) => c.contasuperior === contaFilha.codigo_id
                          )
                        : [];

                      const temContasFilhasV2 =
                        contaFilha.composicao && contasFilhasV2.length > 0;

                      return (
                        <>
                          <StyledTr
                            selectedRow={
                              variacaoVertical?.codigo_id ===
                              contaFilha?.codigo_id
                            }
                            key={contaFilha?.codigo_id}
                            onClick={() =>
                              temContasFilhasV2 &&
                              toggleConta(contaFilha.codigo_id)
                            }
                          >
                            <StyledTd>{ContaFilhaNome}</StyledTd>
                            <StyledTd
                              onClick={() => {
                                if (contaFilha.composicao === null) {
                                  setComentarios(
                                    valoresMensaisFilha?.comentarios.antigos
                                  );
                                  openRazao({
                                    ano: selectedYear - 1,
                                    cookies: cookies,
                                    dispatch: dispatch,
                                    mes: selectedMonth,
                                    codigo: contaFilha.codigo_id,
                                    unidadeId: JSON.stringify(
                                      selectedCombo.comp_combos
                                    ),
                                    setAnexosRazao: setAnexos,
                                    setModalShow: setModalShow,
                                    setRazaoData: setRazaoData,
                                    setRazaoMemory: setRazaoMemory,
                                    valor: valoresMensaisFilha?.value.anterior,
                                    conta: contaFilha.descricao,
                                    mesFinal: selectedMonth,
                                    type: "combos",
                                    notify: notify,
                                  });
                                }
                              }}
                            >
                              <StyledTdContent>
                                {valoresMensaisFilha
                                  ? ` ${formatarValor(
                                      indexarIPCA === true
                                        ? valoresMensaisFilha.valueCorrigido
                                            .anterior
                                        : valoresMensaisFilha.value.anterior,
                                      "moeda"
                                    )}`
                                  : "R$ -"}{" "}
                                {isVariacaoVerticalVisible && (
                                  <div
                                    style={{
                                      color: determinarEstiloDoTexto(
                                        +variacaoVerticalMensalAnteriorFilha
                                      )?.color,
                                    }}
                                  >
                                    {variacao +
                                      " " +
                                      formatarValor(
                                        variacaoVerticalMensalAnteriorFilha,
                                        "numero"
                                      )}
                                    {
                                      determinarEstiloDoTexto(
                                        +variacaoVerticalMensalAnteriorFilha
                                      )?.icon
                                    }
                                  </div>
                                )}
                                <div>
                                  {iconComentario(
                                    valoresMensaisFilha,
                                    "antigos",
                                    true,
                                    contasFilhasV2,
                                    null,
                                    "mensal"
                                  )}{" "}
                                </div>
                              </StyledTdContent>
                            </StyledTd>
                            <StyledTd
                              onClick={() => {
                                if (contaFilha.composicao === null) {
                                  setComentarios(
                                    valoresMensaisFilha?.comentarios.novos
                                  );

                                  openRazao({
                                    ano: selectedYear,
                                    cookies: cookies,
                                    dispatch: dispatch,
                                    mes: selectedMonth,
                                    codigo: contaFilha.codigo_id,
                                    unidadeId: JSON.stringify(
                                      selectedCombo.comp_combos
                                    ),
                                    setAnexosRazao: setAnexos,
                                    setModalShow: setModalShow,
                                    setRazaoData: setRazaoData,
                                    setRazaoMemory: setRazaoMemory,
                                    valor: valoresMensaisFilha?.value.atual,
                                    conta: contaFilha.descricao,
                                    mesFinal: selectedMonth,
                                    type: "combos",
                                    notify: notify,
                                  });
                                }
                                // Se contaFilha.composicao não for null, o código simplesmente não faz nada
                                // ou você pode adicionar um else aqui para lidar com essa situação, se necessário
                              }}
                            >
                              <StyledTdContent>
                                {valoresMensaisFilha
                                  ? ` ${formatarValor(
                                      valoresMensaisFilha.value.atual,
                                      "moeda"
                                    )}`
                                  : "R$ -"}{" "}
                                {isVariacaoVerticalVisible && (
                                  <div
                                    style={{
                                      color: determinarEstiloDoTexto(
                                        +variacaoVerticalMensalFilha
                                      )?.color,
                                    }}
                                  >
                                    {variacao +
                                      " " +
                                      formatarValor(
                                        variacaoVerticalMensalFilha,
                                        "numero"
                                      )}
                                    {
                                      determinarEstiloDoTexto(
                                        +variacaoVerticalMensalFilha
                                      )?.icon
                                    }
                                  </div>
                                )}
                                <div>
                                  {iconComentario(
                                    valoresMensaisFilha,
                                    "novos",
                                    true,
                                    contasFilhasV2,
                                    null,
                                    "mensal"
                                  )}
                                </div>
                              </StyledTdContent>
                            </StyledTd>
                            <StyledTd
                              style={{
                                color: determinarEstiloDoTexto(
                                  +variacaoMensalFilha
                                )?.color,
                              }}
                            >
                              <StyledTdContent>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  {variacaoMensalFilha !== "-"
                                    ? variacao === "%"
                                      ? variacaoMensalFilha + "%"
                                      : formatarValor(
                                          +variacaoMensalFilha,
                                          "moeda"
                                        )
                                    : "-"}
                                  {
                                    determinarEstiloDoTexto(
                                      +variacaoMensalFilha
                                    )?.icon
                                  }
                                </div>
                              </StyledTdContent>
                            </StyledTd>
                            <StyledTd
                              style={
                                isAcumulado === true ? { display: "none" } : {}
                              }
                              onClick={() => {
                                if (contaFilha.composicao === null) {
                                  setComentarios(
                                    valoresAcumuladosFilha?.comentarios.antigos
                                  );

                                  openRazao({
                                    ano: selectedYear - 1,
                                    cookies: cookies,
                                    dispatch: dispatch,
                                    mes: "01",
                                    codigo: contaFilha.codigo_id,
                                    unidadeId: JSON.stringify(
                                      selectedCombo.comp_combos
                                    ),
                                    setAnexosRazao: setAnexos,
                                    setModalShow: setModalShow,
                                    setRazaoData: setRazaoData,
                                    setRazaoMemory: setRazaoMemory,
                                    valor:
                                      valoresAcumuladosFilha?.value.anterior,
                                    conta: contaFilha.descricao,
                                    mesFinal: selectedMonth,
                                    type: "combos",
                                    notify: notify,
                                  });
                                }
                              }}
                            >
                              <StyledTdContent>
                                {valoresAcumuladosFilha
                                  ? ` ${formatarValor(
                                      indexarIPCA === true
                                        ? valoresAcumuladosFilha.valueCorrigido
                                            .anterior
                                        : valoresAcumuladosFilha.value.anterior,
                                      "moeda"
                                    )}`
                                  : "R$ -"}{" "}
                                {isVariacaoVerticalVisible && (
                                  <div
                                    style={{
                                      color: determinarEstiloDoTexto(
                                        +variacaoVerticalAcumuladoAnteriorFilha
                                      )?.color,
                                    }}
                                  >
                                    {variacao +
                                      " " +
                                      formatarValor(
                                        variacaoVerticalAcumuladoAnteriorFilha,
                                        "numero"
                                      )}
                                    {
                                      determinarEstiloDoTexto(
                                        +variacaoVerticalAcumuladoAnteriorFilha
                                      )?.icon
                                    }
                                  </div>
                                )}
                                <div>
                                  {iconComentario(
                                    valoresAcumuladosFilha,
                                    "antigos",
                                    true,
                                    null,
                                    null,
                                    "acumulado"
                                  )}
                                </div>
                              </StyledTdContent>
                            </StyledTd>
                            <StyledTd
                              style={
                                isAcumulado === true ? { display: "none" } : {}
                              }
                              onClick={() => {
                                if (contaFilha.composicao === null) {
                                  setComentarios(
                                    valoresAcumuladosFilha?.comentarios.novos
                                  );

                                  openRazao({
                                    ano: selectedYear,
                                    cookies: cookies,
                                    dispatch: dispatch,
                                    mes: "01",
                                    codigo: contaFilha.codigo_id,
                                    unidadeId: JSON.stringify(
                                      selectedCombo.comp_combos
                                    ),
                                    setAnexosRazao: setAnexos,
                                    setModalShow: setModalShow,
                                    setRazaoData: setRazaoData,
                                    setRazaoMemory: setRazaoMemory,
                                    valor: valoresAcumuladosFilha?.value.atual,
                                    conta: contaFilha.descricao,
                                    mesFinal: selectedMonth,
                                    type: "combos",
                                    notify: notify,
                                  });
                                }
                              }}
                            >
                              <StyledTdContent>
                                {valoresAcumuladosFilha
                                  ? ` ${formatarValor(
                                      valoresAcumuladosFilha.value.atual,
                                      "moeda"
                                    )}`
                                  : "R$ -"}{" "}
                                {isVariacaoVerticalVisible && (
                                  <div
                                    style={{
                                      color: determinarEstiloDoTexto(
                                        +variacaoVerticalAcumuladoFilha
                                      )?.color,
                                    }}
                                  >
                                    {variacao +
                                      " " +
                                      formatarValor(
                                        variacaoVerticalAcumuladoFilha,
                                        "numero"
                                      )}
                                    {
                                      determinarEstiloDoTexto(
                                        +variacaoVerticalAcumuladoFilha
                                      )?.icon
                                    }
                                  </div>
                                )}
                                <div>
                                  {iconComentario(
                                    valoresAcumuladosFilha,
                                    "novos",
                                    true,
                                    null,
                                    null,
                                    "acumulado"
                                  )}
                                </div>
                              </StyledTdContent>
                            </StyledTd>
                            <StyledTd
                              style={
                                isAcumulado === true
                                  ? { display: "none" }
                                  : {
                                      color: determinarEstiloDoTexto(
                                        +variacaoAcumuladaFilha
                                      )?.color,
                                    }
                              }
                            >
                              <StyledTdContent>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  {variacaoAcumuladaFilha !== "-" ? (
                                    variacao === "%" ? (
                                      variacaoAcumuladaFilha + "%"
                                    ) : (
                                      <div>
                                        {formatarValor(
                                          variacaoAcumuladaFilha,
                                          "moeda"
                                        )}
                                        {
                                          determinarEstiloDoTexto(
                                            +variacaoAcumuladaFilha
                                          )?.icon
                                        }
                                      </div>
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </StyledTdContent>
                            </StyledTd>
                          </StyledTr>

                          {contasExpandidas[contaFilha?.codigo_id] &&
                            contasFilhasV2.map((contaNeta) => {
                              if (!contaNeta) return null;

                              const valoresMensaisNeta =
                                loadCombos?.mensal?.find(
                                  (m) => m?.codigo_id === contaNeta?.codigo_id
                                );
                              const valoresAcumuladosNeta =
                                loadCombos?.acumulado?.find(
                                  (a) => a?.codigo_id === contaNeta?.codigo_id
                                );

                              //////////////////////////////////////////////////////////////// netas

                              const variacaoMensalNeta = valoresMensaisNeta
                                ? indexarIPCA === true
                                  ? calcularVariacao(
                                      +valoresMensaisNeta?.value?.atual,
                                      +valoresMensaisNeta?.valueCorrigido
                                        ?.anterior
                                    ).toFixed(2)
                                  : calcularVariacao(
                                      +valoresMensaisNeta?.value?.atual,
                                      +valoresMensaisNeta?.value?.anterior
                                    ).toFixed(2)
                                : "-";
                              const variacaoAcumuladaNeta =
                                valoresAcumuladosNeta
                                  ? indexarIPCA === true
                                    ? calcularVariacao(
                                        +valoresAcumuladosNeta?.value?.atual,
                                        +valoresAcumuladosNeta?.valueCorrigido
                                          ?.anterior
                                      ).toFixed(2)
                                    : calcularVariacao(
                                        +valoresAcumuladosNeta?.value?.atual,
                                        +valoresAcumuladosNeta?.value?.anterior
                                      ).toFixed(2)
                                  : "-";

                              const variacaoVerticalMensalNeta =
                                valoresMensaisNeta
                                  ? indexarIPCA === true
                                    ? calcularVariacao(
                                        +valoresMensaisNeta?.value?.atual,
                                        +variacaoVertical?.mensal
                                          .valoresCorrigidosMensais?.atual,
                                        "vertical"
                                      ).toFixed(2)
                                    : calcularVariacao(
                                        +valoresMensaisNeta?.value?.atual,
                                        +variacaoVertical?.mensal.valoresMensais
                                          ?.atual,
                                        "vertical"
                                      ).toFixed(2)
                                  : "-";

                              const variacaoVerticalMensalAnteriorNeta =
                                valoresMensaisNeta
                                  ? indexarIPCA
                                    ? calcularVariacao(
                                        +valoresMensaisNeta?.valueCorrigido
                                          .anterior,
                                        +variacaoVertical?.mensal
                                          .valoresCorrigidosMensais?.anterior,
                                        "vertical"
                                      ).toFixed(2)
                                    : calcularVariacao(
                                        +valoresMensaisNeta?.value.anterior,
                                        +variacaoVertical?.mensal.valoresMensais
                                          ?.anterior,
                                        "vertical"
                                      ).toFixed(2)
                                  : "-";

                              const variacaoVerticalAcumuladoNeta =
                                valoresAcumuladosNeta
                                  ? calcularVariacao(
                                      +valoresAcumuladosNeta?.value?.atual,
                                      +variacaoVertical?.acumulado
                                        .valoresAcumulados?.atual,
                                      "vertical"
                                    ).toFixed(2)
                                  : "-";

                              const variacaoVerticalAcumuladoAnteriorNeta =
                                valoresAcumuladosNeta
                                  ? indexarIPCA
                                    ? calcularVariacao(
                                        +valoresAcumuladosNeta?.valueCorrigido
                                          .anterior,
                                        +variacaoVertical?.acumulado
                                          .valoresCorrigidosAcumulados
                                          ?.anterior,
                                        "vertical"
                                      ).toFixed(2)
                                    : calcularVariacao(
                                        +valoresAcumuladosNeta?.value.anterior,
                                        +variacaoVertical?.acumulado
                                          .valoresAcumulados?.anterior,
                                        "vertical"
                                      ).toFixed(2)
                                  : "-";

                              const ContaNetaNome =
                                contaNeta?.codigo_id === contaNeta?.descricao
                                  ? contaNeta.descricao
                                  : `● ${contaNeta?.codigo_id} - ${contaNeta?.descricao}`;

                              // aqui possivelmente a próxima
                              // const contasFilhasV3 = loadCombos?.contas.filter(
                              //   (c) => c.contasuperior === contaNeta.codigo_id
                              // );
                              // aqui caso a gente precise das contas netas
                              // const temContasFilhasV3 =
                              //   contasFilhasV3 && contasFilhasV3.length > 0;

                              return (
                                <React.Fragment key={contaNeta.codigo_id}>
                                  <StyledTr
                                    selectedRow={
                                      variacaoVertical?.codigo_id ===
                                      contaNeta?.codigo_id
                                    }
                                    key={contaNeta?.codigo_id}
                                  >
                                    <StyledTd>{ContaNetaNome}</StyledTd>
                                    <StyledTd
                                      onClick={() =>
                                        openRazao({
                                          ano: selectedYear - 1,
                                          cookies: cookies,
                                          dispatch: dispatch,
                                          mes: selectedMonth,
                                          codigo: contaNeta.codigo_id,
                                          unidadeId: JSON.stringify(
                                            selectedCombo.comp_combos
                                          ),
                                          setAnexosRazao: setAnexos,
                                          setModalShow: setModalShow,
                                          setRazaoData: setRazaoData,
                                          setRazaoMemory: setRazaoMemory,
                                          valor:
                                            valoresMensaisNeta?.value.anterior,
                                          conta: contaFilha.descricao,
                                          mesFinal: selectedMonth,
                                          type: "combos",
                                          notify: notify,
                                        })
                                      }
                                    >
                                      <StyledTdContent>
                                        {valoresMensaisNeta
                                          ? ` ${formatarValor(
                                              indexarIPCA
                                                ? valoresMensaisNeta
                                                    .valueCorrigido.anterior
                                                : valoresMensaisNeta.value
                                                    .anterior,
                                              "moeda"
                                            )}`
                                          : "R$ -"}{" "}
                                        {isVariacaoVerticalVisible && (
                                          <div
                                            style={{
                                              color: determinarEstiloDoTexto(
                                                +variacaoVerticalMensalAnteriorNeta
                                              )?.color,
                                            }}
                                          >
                                            {variacao +
                                              " " +
                                              formatarValor(
                                                variacaoVerticalMensalAnteriorNeta,
                                                "numero"
                                              )}
                                            {
                                              determinarEstiloDoTexto(
                                                +variacaoVerticalMensalAnteriorNeta
                                              )?.icon
                                            }
                                          </div>
                                        )}
                                        <div>
                                          {valoresMensaisNeta?.comentarios &&
                                          valoresMensaisNeta.comentarios.antigos
                                            .length === 0 &&
                                          valoresMensaisNeta.comentarios.novos
                                            .length === 0 ? (
                                            ""
                                          ) : (
                                            <RiMessage2Fill
                                              style={{
                                                color:
                                                  valoresMensaisNeta?.comentarios?.antigos?.find(
                                                    (pergunta_id) =>
                                                      pergunta_id.drerazaocomentario_id !=
                                                      null
                                                  )
                                                    ? "green"
                                                    : valoresMensaisNeta?.comentarios.antigos.find(
                                                        (destinatario) =>
                                                          destinatario
                                                            .destinatarios
                                                            .length > 0
                                                      )
                                                    ? "red"
                                                    : "blue",
                                              }}
                                            />
                                          )}{" "}
                                        </div>
                                      </StyledTdContent>
                                    </StyledTd>
                                    <StyledTd
                                      onClick={() =>
                                        openRazao({
                                          ano: selectedYear,
                                          cookies: cookies,
                                          dispatch: dispatch,
                                          mes: selectedMonth,
                                          codigo: contaNeta.codigo_id,
                                          unidadeId: JSON.stringify(
                                            selectedCombo.comp_combos
                                          ),
                                          setAnexosRazao: setAnexos,
                                          setModalShow: setModalShow,
                                          setRazaoData: setRazaoData,
                                          setRazaoMemory: setRazaoMemory,
                                          valor:
                                            valoresMensaisNeta?.value.atual,
                                          conta: contaFilha.descricao,
                                          mesFinal: selectedMonth,
                                          type: "combos",
                                          notify: notify,
                                        })
                                      }
                                    >
                                      <StyledTdContent>
                                        {valoresMensaisNeta
                                          ? ` ${formatarValor(
                                              valoresMensaisNeta.valueCorrigido
                                                .atual,
                                              "moeda"
                                            )}`
                                          : "R$ -"}{" "}
                                        {isVariacaoVerticalVisible && (
                                          <div
                                            style={{
                                              color: determinarEstiloDoTexto(
                                                +variacaoVerticalMensalNeta
                                              )?.color,
                                            }}
                                          >
                                            {variacao +
                                              " " +
                                              formatarValor(
                                                variacaoVerticalMensalNeta,
                                                "numero"
                                              )}
                                            {
                                              determinarEstiloDoTexto(
                                                +variacaoVerticalMensalNeta
                                              )?.icon
                                            }
                                          </div>
                                        )}
                                        <div>
                                          {valoresMensaisNeta?.comentarios &&
                                          valoresMensaisNeta.comentarios.antigos
                                            .length === 0 &&
                                          valoresMensaisNeta.comentarios.novos
                                            .length === 0 ? (
                                            ""
                                          ) : (
                                            <RiMessage2Fill
                                              style={{
                                                color:
                                                  valoresMensaisNeta?.comentarios?.novos?.find(
                                                    (pergunta_id) =>
                                                      pergunta_id.drerazaocomentario_id !=
                                                      null
                                                  )
                                                    ? "green"
                                                    : valoresMensaisNeta?.comentarios.novos.find(
                                                        (destinatario) =>
                                                          destinatario
                                                            .destinatarios
                                                            .length > 0
                                                      )
                                                    ? "red"
                                                    : "blue",
                                              }}
                                            />
                                          )}{" "}
                                        </div>
                                      </StyledTdContent>
                                    </StyledTd>
                                    <StyledTd
                                      style={{
                                        color: determinarEstiloDoTexto(
                                          +variacaoMensalNeta
                                        )?.color,
                                      }}
                                    >
                                      <StyledTdContent>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 10,
                                            alignItems: "center",
                                          }}
                                        >
                                          {variacaoMensalNeta !== "-"
                                            ? variacao === "%"
                                              ? variacaoMensalNeta + "%"
                                              : formatarValor(
                                                  +variacaoMensalNeta,
                                                  "moeda"
                                                )
                                            : "-"}
                                          {
                                            determinarEstiloDoTexto(
                                              +variacaoMensalNeta
                                            )?.icon
                                          }
                                        </div>
                                      </StyledTdContent>
                                    </StyledTd>
                                    <StyledTd
                                      style={
                                        isAcumulado === true
                                          ? { display: "none" }
                                          : {}
                                      }
                                      onClick={() =>
                                        openRazao({
                                          ano: selectedYear - 1,
                                          cookies: cookies,
                                          dispatch: dispatch,
                                          mes: "01",
                                          codigo: contaNeta.codigo_id,
                                          unidadeId: JSON.stringify(
                                            selectedCombo.comp_combos
                                          ),
                                          setAnexosRazao: setAnexos,
                                          setModalShow: setModalShow,
                                          setRazaoData: setRazaoData,
                                          setRazaoMemory: setRazaoMemory,
                                          valor:
                                            valoresAcumuladosNeta?.value
                                              .anterior,
                                          conta: contaFilha.descricao,
                                          mesFinal: selectedMonth,
                                          type: "combos",
                                          notify: notify,
                                        })
                                      }
                                    >
                                      <StyledTdContent>
                                        {valoresAcumuladosNeta
                                          ? ` ${formatarValor(
                                              indexarIPCA
                                                ? valoresAcumuladosNeta
                                                    .valueCorrigido.anterior
                                                : valoresAcumuladosNeta.value
                                                    .anterior,
                                              "moeda"
                                            )}`
                                          : "R$ -"}{" "}
                                        {isVariacaoVerticalVisible && (
                                          <div
                                            style={{
                                              color: determinarEstiloDoTexto(
                                                +variacaoVerticalAcumuladoAnteriorNeta
                                              )?.color,
                                            }}
                                          >
                                            {variacao +
                                              " " +
                                              formatarValor(
                                                variacaoVerticalAcumuladoAnteriorNeta,
                                                "numero"
                                              )}
                                            {
                                              determinarEstiloDoTexto(
                                                +variacaoVerticalAcumuladoAnteriorNeta
                                              )?.icon
                                            }
                                          </div>
                                        )}
                                        <div>
                                          {valoresAcumuladosNeta?.comentarios &&
                                          valoresAcumuladosNeta.comentarios
                                            .antigos.length === 0 &&
                                          valoresAcumuladosNeta.comentarios
                                            .novos.length === 0 ? (
                                            ""
                                          ) : (
                                            <RiMessage2Fill
                                              style={{
                                                color:
                                                  valoresAcumuladosNeta?.comentarios?.antigos?.find(
                                                    (pergunta_id) =>
                                                      pergunta_id.drerazaocomentario_id !=
                                                      null
                                                  )
                                                    ? "green"
                                                    : valoresAcumuladosNeta?.comentarios.antigos.find(
                                                        (destinatario) =>
                                                          destinatario
                                                            .destinatarios
                                                            .length > 0
                                                      )
                                                    ? "red"
                                                    : "blue",
                                              }}
                                            />
                                          )}{" "}
                                        </div>
                                      </StyledTdContent>
                                    </StyledTd>
                                    <StyledTd
                                      style={
                                        isAcumulado === true
                                          ? { display: "none" }
                                          : {}
                                      }
                                      onClick={() =>
                                        openRazao({
                                          ano: selectedYear,
                                          cookies: cookies,
                                          dispatch: dispatch,
                                          mes: "01",
                                          codigo: contaNeta.codigo_id,
                                          unidadeId: JSON.stringify(
                                            selectedCombo.comp_combos
                                          ),
                                          setAnexosRazao: setAnexos,
                                          setModalShow: setModalShow,
                                          setRazaoData: setRazaoData,
                                          setRazaoMemory: setRazaoMemory,
                                          valor:
                                            valoresAcumuladosNeta?.value.atual,
                                          conta: contaFilha.descricao,
                                          mesFinal: selectedMonth,
                                          type: "combos",
                                          notify: notify,
                                        })
                                      }
                                    >
                                      <StyledTdContent>
                                        {valoresAcumuladosNeta
                                          ? ` ${formatarValor(
                                              valoresAcumuladosNeta
                                                .valueCorrigido.atual,
                                              "moeda"
                                            )}`
                                          : "R$ -"}{" "}
                                        {isVariacaoVerticalVisible && (
                                          <div
                                            style={{
                                              color: determinarEstiloDoTexto(
                                                +variacaoVerticalAcumuladoNeta
                                              )?.color,
                                            }}
                                          >
                                            {variacao +
                                              " " +
                                              formatarValor(
                                                variacaoVerticalAcumuladoNeta,
                                                "numero"
                                              )}
                                            {
                                              determinarEstiloDoTexto(
                                                +variacaoVerticalAcumuladoNeta
                                              )?.icon
                                            }
                                          </div>
                                        )}
                                        <div>
                                          {valoresAcumuladosNeta?.comentarios &&
                                          valoresAcumuladosNeta.comentarios
                                            .antigos.length === 0 &&
                                          valoresAcumuladosNeta.comentarios
                                            .novos.length === 0 ? (
                                            ""
                                          ) : (
                                            <RiMessage2Fill
                                              style={{
                                                color:
                                                  valoresAcumuladosNeta?.comentarios?.novos?.find(
                                                    (pergunta_id) =>
                                                      pergunta_id.drerazaocomentario_id !=
                                                      null
                                                  )
                                                    ? "green"
                                                    : valoresAcumuladosNeta?.comentarios.novos.find(
                                                        (destinatario) =>
                                                          destinatario
                                                            .destinatarios
                                                            .length > 0
                                                      )
                                                    ? "red"
                                                    : "blue",
                                              }}
                                            />
                                          )}{" "}
                                        </div>
                                      </StyledTdContent>
                                    </StyledTd>
                                    <StyledTd
                                      style={
                                        isAcumulado === true
                                          ? { display: "none" }
                                          : {
                                              color: determinarEstiloDoTexto(
                                                +variacaoAcumuladaNeta
                                              )?.color,
                                            }
                                      }
                                    >
                                      <StyledTdContent>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 10,
                                            alignItems: "center",
                                          }}
                                        >
                                          {variacaoAcumuladaNeta !== "-" ? (
                                            variacao === "%" ? (
                                              variacaoAcumuladaNeta + "%"
                                            ) : (
                                              <div>
                                                {formatarValor(
                                                  variacaoAcumuladaNeta,
                                                  "moeda"
                                                )}
                                                {
                                                  determinarEstiloDoTexto(
                                                    +variacaoAcumuladaNeta
                                                  )?.icon
                                                }
                                              </div>
                                            )
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      </StyledTdContent>
                                    </StyledTd>
                                  </StyledTr>

                                  {/* {contasExpandidas[contaNeta.codigo_id] &&
                                    contasFilhasV3.map((contaFilhaV3) => {
                                      // Aqui pode seguir o mesmo padrão  para renderizar contas mais abaixo na hierarquia
                                    })} */}
                                </React.Fragment>
                              );
                            })}
                        </>
                      );
                    })}
                </>
              );
            })}
        </StyledTbody>
        <ModalVariacaoVertical
          onConfirmar={() => handleConfirmar(contaSelecionada)}
          onHide={() => setModalVariacaoOpen(false)}
          show={modalVariacaoOpen}
          children={
            <SelectContas
              contaSelecionada={contaSelecionada}
              setContaSelecionada={setContaSelecionada}
              unidade={loadCombos}
            />
          }
        />
      </StyledTable>

      <Fab
        icon={<AiOutlinePlus />}
        alwaysShowTitle={true}
        mainButtonStyles={{ backgroundColor: "#17a2b8" }}
        style={{
          bottom: 0,
          right: 0,
          zoom: "80%",
          margin: "20px",
          zIndex: 100,
        }}
      >
        {isVariacaoVerticalVisible ? (
          <Action
            text={"Ocultar Variação Vertical"}
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setIsVariacaoVerticalVisible(false);
              setVariacaoVertical(null);
            }}
          >
            X
          </Action>
        ) : null}

        {indexarIPCA === true ? (
          <Action
            text={"Ocultar IPCA"}
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setIndexarIPCA(false);
            }}
          >
            X
          </Action>
        ) : null}

        <Action
          text="Variação"
          style={{ backgroundColor: "blue" }}
          onClick={() => setVariacao(variacao === "R$" ? "%" : "R$")}
        >
          {variacao === "R$" ? "%" : "R$"}
        </Action>

        <Action
          text={"Selecionar Unidades"}
          style={{ backgroundColor: "blue" }}
          onClick={openModal}
        >
          <LuHotel />
        </Action>

        <Action
          text={"Variação Vertical"}
          style={{ backgroundColor: "blue" }}
          onClick={() => setModalVariacaoOpen(true)}
        >
          <HiMiniArrowsUpDown />
        </Action>

        <Action
          text={indexarIPCA === true ? "" : "Indexar IPCA"}
          style={{
            display: indexarIPCA === true ? "none" : "flex",
            backgroundColor: "blue",
          }}
          onClick={() => {
            setIndexarIPCA(true);
          }}
        >
          <AiOutlineFileText />
        </Action>
        {/* <Action
          text={"Exportar para execel"}
          style={{ backgroundColor: "green", color: "white" }}
          onClick={() => {
            selectedCombo !== null && notify("Escolha um combo para começar!");
          }}
        >
          <RiFileExcel2Line />
        </Action> */}
      </Fab>
      <ModalSelectCombo
        show={isOpen}
        onHide={closeModal}
        handleComboChange={handleComboChange}
        opcoesAgrupadas={opcoesAgrupadas}
        opcoesCarregadas={opcoesCarregadas}
        selectedCombo={selectedCombo}
        onClose={closeModal}
        onSubmit={fetchData}
        children={
          <StyledDiv>
            <Select
              style={{ cursor: "pointer" }}
              fullWidth
              onChange={handleMonthChange}
              value={selectedMonth}
            >
              <option value={"YTD"}>YTD</option>
              {[
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
              ].map((month, index) => (
                <option
                  style={{ cursor: "pointer" }}
                  key={"mes" + index}
                  value={index + 1}
                >
                  {month}
                </option>
              ))}
            </Select>
            <Select
              style={{ cursor: "pointer" }}
              fullWidth
              onChange={handleYearChange}
              value={selectedYear}
            >
              {Array(new Date().getFullYear() - 2018)
                .fill(1)
                .map((_, idx) => {
                  const year = new Date().getFullYear() - idx;
                  return (
                    <option
                      style={{ cursor: "pointer" }}
                      key={"ano" + idx}
                      value={year}
                    >
                      {year}
                    </option>
                  );
                })}
            </Select>
          </StyledDiv>
        }
      />
      <ModalRazaoAllContas
        offset={offset}
        fetchModalMesesData={fetchModalMesesData}
        selectedMonth={selectedMonth}
        open={modalRazaoAllMonths}
        onClose={() => {
          setModalRazaoAllMonths(false);
          setRazaoData([]);
          setOffset(0);
        }}
        onClickButton={() => {
          setModalRazaoAllMonths(false);
          setRazaoData([]);
          setOffset(0);
        }}
        title={titleSelectedCombo}
        isSearchEmpty={isSearchEmpty}
        isLoading={isLoading}
        searchInput={
          <FormControl
            type="search"
            id="searchInput"
            placeholder="Buscar registro"
            aria-label="searchRazaoBar"
            aria-describedby="basic-addon1"
            onChange={(event) => {
              handleSearchChange(event?.target?.value);
              setOffset(0);
            }}
            onBlur={() => {
              setRazaoData([]);
              fetchModalMesesData(eventKeyDown);
            }}
            onKeyDown={(event: any) => {
              handleKeyDown(event);
            }}
          />
        }
        setRazaoData={setRazaoData}
        anexosRazao={anexos}
        comentarios={comentarios}
        notify={notify}
        razaoData={razaoData}
        setModalNovoComentarios={setModalNovoComentarios}
        setModalComentariosShow={setModalComentariosShow}
        setNivelContaAtual={setNivelContaAtual}
        totalSaldo={totalSaldo}
        modalShow={modalShow[2]}
      />
      <ModalRazao
        open={modalShow[0]}
        onClose={() => setModalShow([0])}
        onClickButton={() => setModalShow([0])}
        title={modalShow[1]}
        searchInput={
          <FormControl
            type="search"
            placeholder="Buscar registro"
            aria-label="searchRazaoBar"
            aria-describedby="basic-addon1"
            value={searchRazaoText}
            onChange={(e) => {
              setSearchRazaoText(e.target.value);
            }}
            onBlur={(e: { target: { value: any } }) => {
              filtraRazao(e.target.value);
            }}
          />
        }
        setRazaoData={setRazaoData}
        anexosRazao={anexos}
        comentarios={comentarios}
        notify={notify}
        razaoData={razaoData}
        setModalNovoComentarios={setModalNovoComentarios}
        setModalComentariosShow={setModalComentariosShow}
        setNivelContaAtual={setNivelContaAtual}
        modalShow={modalShow[2]}
      />
      <ModalComentarios
        comentarios={comentarios}
        anexos={anexos}
        destinatarios={destinatarios}
        modalComentariosShow={modalComentariosShow}
        modalShow={modalShow}
        notify={notify}
        onClickButton={() =>
          setModalComentariosShow([false, null, { id: null }])
        }
        onClose={() => setModalComentariosShow([false, null, { id: null }])}
        open={modalComentariosShow[0]}
        razaoData={razaoData}
        setModalNovoComentarios={setModalNovoComentarios}
        setModalRespostaVisible={setModalRespostaVisible}
        setNivelContaAtual={setNivelContaAtual}
        title="Comentários"
      />
      <ModalNovoComentario
        anoConsulta={selectedYear}
        loadData={() => {}}
        title="Adicionar Comentário"
        onClose={() => setModalNovoComentarios([false])}
        open={modalNovoComentarios[0]}
        modalComentariosShow={modalComentariosShow}
        modalNovoComentarios={modalNovoComentarios}
        nivelContaAtual={nivelContaAtual}
        onClickButton={() => {
          setModalNovoComentarios([false]);
          setToolipText("Adicionar anexo");
        }}
        selected={selectedMonth}
        setToolipText={setToolipText}
        tooltipText={tooltipText}
        notify={notify}
        id=""
      />
      <ModalRespostas
        anoConsulta={selectedYear.toString()}
        selected={selectedMonth}
        loadData={() => {}}
        setToolipText={setToolipText}
        tooltipText={tooltipText}
        modalRespostaVisible={modalRespostaVisible}
        notify={notify}
        open={modalRespostaVisible[0]}
        onClose={() =>
          setModalRespostaVisible([false, { nome: "", comentario: "" }])
        }
        onClickButton={() => {
          setModalRespostaVisible([false, { nome: "", comentario: "" }]);
          setToolipText("Adicionar anexo");
        }}
        title="Adicione Resposta"
        modalComentariosShow={modalComentariosShow}
        nivelContaAtual={nivelContaAtual}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        style={{ zIndex: 1000000 }}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </LayoutContainer>
  );
}
