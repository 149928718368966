import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper
} from '@material-ui/core'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../services/api'
import { Col, Container, Form, Row, Button, FormControl } from 'react-bootstrap';
import { useCookies } from 'react-cookie';

function QuadroPessoal() {
  var meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

  const [linhasCC, setLinhasCC] = useState([])
  const [cookies] = useCookies([]);

  // eslint-disable-next-line
  useEffect(() => { getCC() }, [])

  const handleClickLimpar = () => {
    meses.map(mes => {
      return linhasCC.map(cc => {
        return document.getElementById(cc.centrodecusto + '-' + mes).value = 0
      })
    })
  }

  const notify = ($msg) => toast($msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  async function getCC() {
    await api.get('/centrosdecustospessoal', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
      .then(res => setLinhasCC(res.data))
  }

  const handleClickSalvar = async () => {
    var myObj = []
    var timestamp = new Date()
    var isValid = false
    timestamp = timestamp.toLocaleString('pt-br')
    timestamp = timestamp.substring(6, 10) + '-' + timestamp.substring(3, 5) + '-' + timestamp.substring(0, 2)

    // eslint-disable-next-line
    meses.map((mes, idx) => {
      var totalCheck = 0
      // eslint-disable-next-line
      linhasCC.map(cc => {
        if (cc.centrodecusto !== 'Total') {
          totalCheck += parseFloat(document.getElementById(cc.centrodecusto + '-' + mes).value)
        } else {
          if (totalCheck === parseFloat(document.getElementById(cc.centrodecusto + '-' + mes).value) && totalCheck !== 0) {
            totalCheck = 0
            isValid = true
            myObj.push({
              unidade_id: document.getElementById('unidadeSelected').value,
              usuario_id: cookies.usuario.id,
              ano: document.getElementById('anoQP').value,
              mes: (parseFloat(idx) + 1),
              timestamp,

              recepcao: parseFloat(document.getElementById('Recepção-' + mes).value),
              governanca: parseFloat(document.getElementById('Governança-' + mes).value),
              manutencao: parseFloat(document.getElementById('Manutenção-' + mes).value),
              gerencia: parseFloat(document.getElementById('Gerencia-' + mes).value),
              restaurante: parseFloat(document.getElementById('Restaurante-' + mes).value),
              portaria: parseFloat(document.getElementById('Portaria-' + mes).value),
              afastados: parseFloat(document.getElementById('Afastados-' + mes).value),
              total: parseFloat(document.getElementById('Total-' + mes).value),
              almoxarifado: parseFloat(document.getElementById('Almoxarifado-' + mes).value),
              compras: parseFloat(document.getElementById('Compras-' + mes).value),
              controladoria: parseFloat(document.getElementById('Controladoria-' + mes).value),
              cozinha: parseFloat(document.getElementById('Cozinha-' + mes).value),
              eventos: parseFloat(document.getElementById('Eventos-' + mes).value),
              limpeza: parseFloat(document.getElementById('Limpeza-' + mes).value),
              marketing: parseFloat(document.getElementById('Marketing-' + mes).value),
              tecnologiadainformacao: parseFloat(document.getElementById('Tecnologia da Informação-' + mes).value),
              vendas: parseFloat(document.getElementById('Vendas-' + mes).value)
            })
          } else if (totalCheck !== 0) {
            // console.log('Invalido')
            // console.log('TT: ', parseFloat(document.getElementById(cc.centrodecusto + '-' + mes).value))
            notify("Total de " + mes + " inválido, por favor confira os valores!")
            // console.log('TTC: ', totalCheck)
          }
        }
      })
    })
    if (isValid) {
      await api.post('/funcionarios/budget', {
        obj: myObj
      }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
        notify("Salvo com sucesso!")
        handleClickLimpar();
      })
    }
  }

  return (
    <>
      <Container fluid>
        <Row><h3>Quadro de pessoal</h3></Row>
        <Row md={3} style={{ marginBottom: '15px' }}>
          <Col>
            <Form.Control id="unidadeSelected" size="sm" as="select" placeholder="Unidade">
              <option value='-1'>Escolha a unidade</option>
              {JSON.parse(sessionStorage.getItem('unidades')).map(unidade => { return (<option value={unidade.id} >{unidade.unidade}</option>) })}
            </Form.Control>
          </Col>
          <Col>
            <FormControl
              defaultValue={new Date().getFullYear() + 1}
              size='sm'
              placeholder="Ano quadro de pessoal"
              id='anoQP'
              type="number"
            />
          </Col>
        </Row>

        <Row>
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Centro de Custo</TableCell>
                  {meses.map((mes) => (
                    <TableCell style={{ width: '7.5%' }} key={mes}>{mes}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {linhasCC.map((cc) => (
                  <TableRow key={cc.centrodecusto}>
                    <TableCell>{cc.centrodecusto}</TableCell>
                    {meses.map((mes) => (
                      <TableCell key={mes}>
                        <TextField
                          id={cc.centrodecusto + '-' + mes}
                          variant='outlined'
                          type='number'
                          style={{ minWidth: '75px' }}
                          defaultValue={0}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>

        <Row style={{ marginBottom: '10px', marginTop: '10px', textAlign: 'right' }}>
          <Col>
            <Button style={{ marginRight: '5px' }} variant='outline-danger' onClick={handleClickLimpar}>
              Limpar
            </Button>
            <Button variant='primary' onClick={handleClickSalvar} color='primary'>
              Salvar
            </Button>
          </Col>
        </Row>
      </Container>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover />
    </>);
}

export default QuadroPessoal;