import React from 'react';
import { useCookies } from 'react-cookie';
import readXlsxFile from 'read-excel-file'
import XMLParser from 'react-xml-parser';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// redux
import { useDispatch } from 'react-redux'

import api from '../../../../../services/api'



export default function TabOperaCloud() {


    const dispatch = useDispatch()
    const [cookies] = useCookies();
    let usuarioId = cookies.usuario.id

    const notify = ($msg, $time = 5000) => toast($msg, {
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    async function handlerInputFile(file) {
        if (file.target.name === "CMR") {
            dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })

            for (let i = 0; i < file.target.files.length; i++) {
                await new Promise(resolve => setTimeout(resolve, 250))
                var preview4 = URL.createObjectURL(file.target.files[i])
                var nome = file.target.files[i].name
                if (file.target.files[i].type === 'text/plain') {
                    axios.get(preview4, {
                        "Content-Type": "application/txt; charset=utf-8"
                    }).then(async (response) => {
                        var contcmObj = []
                        response.data = response.data.replace(/\t/gi, ',')
                        response.data = response.data.replace(/\r/gi, '')
                        var contcm = response.data.split('\n')
                        contcm.pop()

                        contcm.map(async registro => {
                            var registroObj = registro.split(',')
                            registroObj.pop()
                            if (registro.length) {
                                var id = registroObj[0] + registroObj[2].substring(6, 10) + registroObj[2].substring(3, 5) + registroObj[2].substring(0, 2) + registroObj[1]
                                contcmObj.push({
                                    id,
                                    unidade_id: registroObj[0],
                                    usuario_id: usuarioId,
                                    codigocontabilidade_id: registroObj[1],
                                    data: registroObj[2].substring(6, 10) + '-' + registroObj[2].substring(3, 5) + '-' + registroObj[2].substring(0, 2),
                                    valor: registroObj[3],
                                })
                            }
                        })

                        await api.post('/registroscontabilidade', {
                            contabilidade: contcmObj
                        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                        if ((i + 1) === file.target.files.length) {
                            document.getElementsByName('CMR')[0].value = null
                            notify("CONTCM - ok")
                        }
                    });
                } else {
                    try {
                        axios.get(preview4, {
                            "Content-Type": "application/xml; charset=utf-8"
                            // eslint-disable-next-line
                        }).then(async response => {
                            var xml = new XMLParser().parseFromString(response.data)  // Assume xmlText contains the example XML
                            if (xml.getElementsByTagName("MANAGER_REPORT").length) {
                                var unidadeDoc = xml.getElementsByTagName('RESORT')[0].value
                                var year = xml.getElementsByTagName('HEADING_1')[0].value
                                var uhs = xml.getElementsByTagName('FORMATTED_AMOUNT')[0].value
                                var month = xml.getElementsByTagName('FORMATTED_AMOUNT')[2].value
                                var currentUnidadeData = JSON.parse(sessionStorage.getItem('unidades')).find(unidade => unidade.id === unidadeDoc)
                                var plussUhs = 0
                                if (currentUnidadeData.operacloud !== null) {
                                    var now = new Date(currentUnidadeData.operacloud);
                                    var start = new Date(now.getFullYear(), 0, 0);
                                    var diff = now - start;
                                    var oneDay = 1000 * 60 * 60 * 24;
                                    var doy = Math.floor(diff / oneDay);
                                    plussUhs = parseInt(doy) * parseInt(currentUnidadeData.uhs)
                                    month = parseFloat(month) + plussUhs
                                }
                                var timeyear = new Date(year).getTime()
                                var dataFinal = new Date((timeyear + (month / uhs) * 24 * 60 * 60 * 1000))
                                var ano = dataFinal.getFullYear()
                                var mes = (dataFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
                                var dia = dataFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
                                var manageReportObj = []
                                if (!isNaN(parseInt(year)) && parseInt(uhs) !== 0) {
                                    xml.getElementsByTagName('G_MASTER_VALUE').map(info => {
                                        if (!info.children[0].value.includes('001')) {
                                            if (info.getElementsByTagName('FORMATTED_AMOUNT')[0]) {
                                                info.getElementsByTagName('FORMATTED_AMOUNT')[0].value = info.getElementsByTagName('FORMATTED_AMOUNT')[0].value.replace(/,/g, '')
                                                if (isNaN(parseFloat(info.getElementsByTagName('FORMATTED_AMOUNT')[0].value))) {
                                                    info.getElementsByTagName('FORMATTED_AMOUNT')[0].value = 0
                                                }
                                                var id = unidadeDoc + ano + mes + dia + info.children[1].value

                                                manageReportObj.push({
                                                    id,
                                                    unidade_id: unidadeDoc,
                                                    usuario_id: usuarioId,
                                                    codigo_id: info.children[1].value,
                                                    data: ano + '-' + mes + '-' + dia,
                                                    valor: parseFloat(info.getElementsByTagName('FORMATTED_AMOUNT')[0].value),
                                                })
                                            }
                                        }
                                    })
                                    await api.post('/registros', { managerReport: manageReportObj }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                                    URL.revokeObjectURL(file.target.files[i])

                                    if ((i + 1) === file.target.files.length) {
                                        notify("Manager report ok")
                                        document.getElementsByName('CMR')[0].value = null
                                    }
                                } else {
                                    notify("Manager report invalido!")
                                    notify(nome)
                                    document.getElementsByName('CMR')[0].value = null
                                }
                            } else if (xml.getElementsByTagName("HISTORY_FORECAST").length) {
                                var forecastobj = []

                                xml.getElementsByTagName("G_CONSIDERED_DATE").map(registro => {
                                    var data = registro.getElementsByTagName("CHAR_CONSIDERED_DATE")[0].value
                                    data = '20' + data.substring(6, 8) + '-' + data.substring(3, 5) + '-' + data.substring(0, 2)
                                    forecastobj.push({
                                        data,
                                        usuario_id: cookies.usuario.id,
                                        unidade_id: registro.getElementsByTagName("INVENTORY_ROOMS")[0].value,
                                        REVENUE: parseFloat(registro.getElementsByTagName("REVENUE")[0].value),
                                        NO_ROOMS: parseFloat(registro.getElementsByTagName("NO_ROOMS")[0].value),
                                        IND_DEDUCT_ROOMS: parseFloat(registro.getElementsByTagName("IND_DEDUCT_ROOMS")[0].value),
                                        IND_NON_DEDUCT_ROOMS: parseFloat(registro.getElementsByTagName("IND_NON_DEDUCT_ROOMS")[0].value),
                                        GRP_DEDUCT_ROOMS: parseFloat(registro.getElementsByTagName("GRP_DEDUCT_ROOMS")[0].value),
                                        GRP_NON_DEDUCT_ROOMS: parseFloat(registro.getElementsByTagName("GRP_NON_DEDUCT_ROOMS")[0].value),
                                        NO_PERSONS: parseFloat(registro.getElementsByTagName("NO_PERSONS")[0].value),
                                        ARRIVAL_ROOMS: parseFloat(registro.getElementsByTagName("ARRIVAL_ROOMS")[0].value),
                                        DEPARTURE_ROOMS: parseFloat(registro.getElementsByTagName("DEPARTURE_ROOMS")[0].value),
                                        COMPLIMENTARY_ROOMS: parseFloat(registro.getElementsByTagName("COMPLIMENTARY_ROOMS")[0].value),
                                        HOUSE_USE_ROOMS: parseFloat(registro.getElementsByTagName("HOUSE_USE_ROOMS")[0].value),
                                        DAY_USE_ROOMS: parseFloat(registro.getElementsByTagName("DAY_USE_ROOMS")[0].value),
                                        NO_SHOW_ROOMS: parseFloat(registro.getElementsByTagName("NO_SHOW_ROOMS")[0].value),
                                        IND_DEDUCT_REVENUE: parseFloat(registro.getElementsByTagName("IND_DEDUCT_REVENUE")[0].value),
                                        IND_NON_DEDUCT_REVENUE: parseFloat(registro.getElementsByTagName("IND_NON_DEDUCT_REVENUE")[0].value),
                                        GRP_NON_DEDUCT_REVENUE: parseFloat(registro.getElementsByTagName("GRP_NON_DEDUCT_REVENUE")[0].value),
                                        GRP_DEDUCT_REVENUE: parseFloat(registro.getElementsByTagName("GRP_DEDUCT_REVENUE")[0].value),
                                        OWNER_ROOMS: parseFloat(registro.getElementsByTagName("OWNER_ROOMS")[0].value),
                                        FF_ROOMS: parseFloat(registro.getElementsByTagName("FF_ROOMS")[0].value),
                                        CF_OOO_ROOMS: parseFloat(registro.getElementsByTagName("CF_OOO_ROOMS")[0].value),
                                        CF_CALC_OCC_ROOMS: parseFloat(registro.getElementsByTagName("CF_CALC_OCC_ROOMS")[0].value),
                                        CF_AVERAGE_ROOM_RATE: parseFloat(registro.getElementsByTagName("CF_AVERAGE_ROOM_RATE")[0].value),
                                        CF_OCCUPANCY: parseFloat(registro.getElementsByTagName("CF_OCCUPANCY")[0].value),
                                        CF_IND_DED_REV: parseFloat(registro.getElementsByTagName("CF_IND_DED_REV")[0].value),
                                        CF_IND_NON_DED_REV: parseFloat(registro.getElementsByTagName("CF_IND_NON_DED_REV")[0].value),
                                        CF_BLK_DED_REV: parseFloat(registro.getElementsByTagName("CF_BLK_DED_REV")[0].value),
                                        CF_BLK_NON_DED_REV: parseFloat(registro.getElementsByTagName("CF_BLK_NON_DED_REV")[0].value)
                                    })
                                })
                                await api.post(`/registrosforecastcontroladoria`, {
                                    forecastcontroladoria: forecastobj
                                }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                            }
                        })
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
            document.getElementsByName('CMR')[0].value = null
            dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        } else if (file.target.name === "statistics" && file.target.files[0].type === 'text/xml') {
            // Deprecated
            dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
            for (let i = 0; i < file.target.files.length; i++) {
                var preview2 = URL.createObjectURL(file.target.files[i])

                axios.get(preview2, {
                    "Content-Type": "application/xml; charset=utf-8"
                }).then(async response => {
                    var xml = new XMLParser().parseFromString(response.data)
                    var timestamp = new Date()
                    timestamp = timestamp.toLocaleString('pt-br')
                    timestamp = timestamp.substring(6, 10) + '-' + timestamp.substring(3, 5) + '-' + timestamp.substring(0, 2)
                    xml.getElementsByTagName('DAY').map(async registroDia => {
                        var statisticsObj = []
                        var dataRegistro = registroDia.getElementsByTagName("CHAR_BUSINESS_DATE")[0].value
                        dataRegistro = '20' + dataRegistro.substring(6, 8) + '-' + dataRegistro.substring(3, 5) + '-' + dataRegistro.substring(0, 2)
                        registroDia.getElementsByTagName("MARKET").map(registroMkt => {
                            var mktCode = registroMkt.getElementsByTagName("MARKET_CODE")[0].value
                            var id = registroDia.getElementsByTagName('RESORT')[0].value + dataRegistro + mktCode + timestamp
                            statisticsObj.push({
                                id: id,
                                data: dataRegistro,
                                mktcode: mktCode,
                                numeroquartos: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "NO_DEFINITE_ROOMS").value),
                                taxaocupacao: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "PER_OCC").value),
                                diariamedia: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "GET_ARR").value),
                                receitahospedagem: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "REVENUE").value),
                                receitaaeb: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "FB_REV").value),
                                outrasreceitas: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "OTHER_REV").value),
                                numerohospedes: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "IN_GUEST").value),
                                taxamultiocupacao: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "MULTI_OCC_PER").value),
                                singleocupacao: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "OCC_SINGLE").value),
                                multiocupacao: parseFloat(registroMkt.getElementsByTagName('DETAIL')[0].children.find(arr => arr.name === "DOUBLE_OCC").value),
                                unidade_id: registroDia.getElementsByTagName('RESORT')[0].value,
                                usuario_id: usuarioId,
                                datacriacao: timestamp,
                            })
                        })

                        await new Promise(resolve => setTimeout(resolve, 500))

                        await api.post('/registrosstatistics', {
                            statistics: statisticsObj
                        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                    })
                    if ((i + 1) === file.target.files.length) {
                        document.getElementsByName('statistics')[0].value = null
                        notify("Statistics - ok")
                        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
                    }
                })
            }
        }
    }

    const divStyle = {
        width: '100%',
        maxWidth: '600px',
        padding: '10px',
        borderBottom: '1px solid #eee',
    };

    const inputStyle = {
        cursor: 'pointer',
        padding: '8px 10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        outline: 'none',
        width: '100%',
        maxWidth: '600px',
        marginBottom: '5px',
    };

    const inputStyleMultiple = {
        ...inputStyle,
        marginBottom: '0',
    };

    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                padding: '30px',
                border: '1px dashed #ccc',
                borderRadius: '10px',
                marginTop: '20px',
                backgroundColor: '#f9f9f9',
                margin: '20px auto',
            }}>
                <div style={divStyle}>
                    <label
                        style={{
                            display: "block",
                            margin: "8px 0",
                            fontWeight: "bold",
                        }}
                    >
                        Opera Cloud:{" "}
                    </label>
                    <input id="multipleInput1" type="file" multiple name="CMR" onChange={e => handlerInputFile(e)} accept='.txt, .xml' style={inputStyleMultiple} />
                    <small style={{ textAlign: 'center', marginTop: '10px' }}>Manager Report (STA01128) - Contabilidade(Contcm)</small>
                </div>
            </div>
        </>
    )
}

