import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { AiOutlineFund } from "react-icons/ai";

interface Props {
  onClick: () => void;
  id: string;
}

export const ComparativeButton: React.FC<Props> = ({ onClick, id }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>Comparar coluna?</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={popover}>
      <Button
        onClick={onClick}
        style={{ float: "right" }}
        id={id}
        variant="outline-primary"
        size="sm"
      >
        <AiOutlineFund />
      </Button>
    </OverlayTrigger>
  );
};
