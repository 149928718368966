import { makeStyles } from '@material-ui/core/styles';
import { Avatar, List, ListItemButton, ListItemText, Rating, TextField, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { BiTargetLock } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { IoCloudUpload } from "react-icons/io5";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Matrix from '../../../components/Matrix';

import api from '../../../services/api';
import { FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tabs } from '@material-ui/core';

import 'dayjs/locale/pt-br'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from '../styles';

function PDIColaborador(props) {

    const location = useLocation();
    const classes = useStyles();
    const history = useHistory()

    const [cookies] = useCookies([]);
    const [colabAtual, setColabAtual] = useState({ nome: '', email: '', cpf: '', celular: '', sexo: [], unidade: [] })
    const [pdi, setPDI] = useState([])
    const [avaliacaoColab, setAvaliacaoColab] = useState({})
    const [valueTab, setValueTab] = useState(0);
    const [valueTab1, setValueTab1] = useState(0);
    const [valueTabModal, setValueTabModal] = useState(0);
    const [modalRespostaVisible, setModalRespostaVisible] = useState({ isVisible: false })
    const [step, setStep] = useState(1)
    const [avaliacaoId, setAvaliacaoId] = useState(null)
    const [dominioTecnicoEditado, setDominioTecnicoEditado] = useState(false)
    const [avaliacoesAnteriores, setAvaliacoesAnteriores] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = avaliacoesAnteriores.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(avaliacoesAnteriores.length / itemsPerPage);

    const [modalConcluido, setModalConcluido] = useState({ isVisible: false })

    const handleChangeTab = (event, newValue) => { if (newValue === 2) { setDominioTecnicoEditado(true); } setValueTab(newValue); };
    const handleChangeTab1 = (event, newValue) => { setValueTab1(newValue); };
    const handleChangeTabModal = (event, newValue) => { setValueTabModal(newValue); };
    var colabID = location?.state?.colab


    useEffect(() => { }, [props.location.search]);

    const notify = ($msg, $time = 1000) => toast($msg, {
        theme: "light",
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(props.location.search);
        const aiValue = searchParams.get('ai');
        setAvaliacaoId(aiValue)
        loadColabData(colabID, aiValue)
    }, [props.location.search])

    const loadPDI = async (unidade, departamento_id, cargo_id, colaborador_cpf, avaliacao, avaliacaoDiscertativa, avaliacaoIdAtual) => {
        if (colabID) {
            var pdiData = await api.get('/rh/pdi/perguntas?includeInativos=false&unidade_id=' + unidade[0].id + '&departamento_id=' + departamento_id + '&cargo_id=' + cargo_id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        } else {
            var pdiData = await api.get('/rh/pdi/perguntas?includeInativos=false&unidade_id=' + unidade[0].id + '&departamento_id=' + departamento_id + '&cargo_id=' + cargo_id, { headers: { 'authorization': `BearerAV ${avaliacaoId}` } })
        }

        setPDI(pdiData.data.map(p => {
            var valorAtual = '0'

            if (avaliacao !== undefined) {
                var avaliacaoPergunta = avaliacao.find(a => a.pergunta_id === p.id)
                if (avaliacaoPergunta !== undefined) { valorAtual = avaliacaoPergunta.nota }
            }

            var avaliador_id = !!avaliacaoIdAtual ? "Auto Avaliação" : cookies.usuario.id
            return { ...p, value: valorAtual, avaliador_id, colaborador_id: colabID, colaborador_cpf: colaborador_cpf }
        }))

        setAvaliacaoColab({
            valuePotencial: avaliacaoDiscertativa?.find(d => d.pergunta === 'valuePotencial')?.texto || '',
            potencialTransicao: avaliacaoDiscertativa?.find(d => d.pergunta === 'potencialTransicao')?.texto || '',
            pontosFortes: avaliacaoDiscertativa?.find(d => d.pergunta === 'pontosFortes')?.texto || '',
            pontosMelhorar: avaliacaoDiscertativa?.find(d => d.pergunta === 'pontosMelhorar')?.texto || '',
            acoesDesenvolvimento: avaliacaoDiscertativa?.find(d => d.pergunta === 'acoesDesenvolvimento')?.texto || '',
            comentariosComite: avaliacaoDiscertativa?.find(d => d.pergunta === 'comentariosComite')?.texto || '',
            proxima_avaliacao: avaliacaoDiscertativa?.find(d => d.pergunta === 'proxima_avaliacao')?.texto || '',
        })
    }

    async function loadAvaliacoesAnteriores(cpf, cargo, unidade) {
        if (!colabID) return
        var avaliacoes = await api.get('/rh/pdi/avaliacoes?cpf=' + cpf + '&cargo=' + cargo + '&unidade=' + unidade[0].id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setAvaliacoesAnteriores(avaliacoes.data)
    }

    const loadColabData = async (colabID, avaliacao_id) => {
        if ((colabID === undefined || parseInt(colabID) === -1) && avaliacao_id === undefined) return history.goBack()
        var colabAtual = colabID || -1
        if (colabID) {
            var colabData = await api.get('/rh/colaborador/atual/' + colabAtual + '?includeAvaliacao=true&avaliacao_id=' + avaliacao_id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        } else {
            var colabData = await api.get('/rh/colaborador/atual/' + colabAtual + '?includeAvaliacao=true&avaliacao_id=' + avaliacao_id, { headers: { 'authorization': `BearerAV ${avaliacaoId}` } })

            if (colabData.data?.autoAvaliacao === false) return setModalConcluido({ ...modalConcluido, isVisible: true })
        }
        loadPDI(colabData.data[0].unidade, colabData.data[0].departamento_id, colabData.data[0].cargo_id, colabData.data[0].cpf, colabData.data[0].avaliacao, colabData.data[0].avaliacaoDiscertativa, avaliacao_id)
        loadAvaliacoesAnteriores(colabData.data[0].cpf, colabData.data[0].cargo_id, colabData.data[0].unidade)

        return setColabAtual(colabData.data[0])
    }

    const handleSave = async () => {
        if (pdi.find(item => item.value === '0' && item.number.split('.').length > 1)) return notify('Preencha todos os campos')
        if (colabID) {
            await api.post('/rh/pdi/avaliacao', { pdi, avaliacaoColab, avaliacaoId }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        } else {
            await api.post('/rh/pdi/avaliacao', { pdi, avaliacaoColab, avaliacaoId }, { headers: { 'authorization': `BearerAV ${avaliacaoId}` } })
            return setModalConcluido({ ...modalConcluido, isVisible: true })
        }

        notify('Avaliação salva com sucesso')
        return history.goBack()
    }

    const formatarSalario = (salario) => {
        var salarioString = salario.toString()
        var salarioFormatado = 'R$ '
        var i = 0
        for (i = 0; i < salarioString.length; i++) {
            if (i === salarioString.length - 2) {
                salarioFormatado += ','
            }
            if (i === salarioString.length - 5) {
                salarioFormatado += '.'
            }
            if (i === salarioString.length - 8) {
                salarioFormatado += '.'
            }
            salarioFormatado += salarioString[i]
        }
        return salarioFormatado
    }

    const handleAvaliacao = (id, value) => {
        var avaliacao = pdi
        var index = avaliacao.findIndex(a => a.id === id)
        if (index === -1) {
            avaliacao.push({ id: id, value: value })
        } else {
            avaliacao[index].value = value + ''
        }
        setPDI(avaliacao)
    }

    var scale = [{ min: 1, max: 2.6, value: 1 }, { min: 2.6, max: 3.3, value: 2 }, { min: 3.3, max: 6, value: 3 }]
    var quadrante = [{ base: 1, altura: 1, quadrante: 1 }, { base: 2, altura: 1, quadrante: 3 }, { base: 3, altura: 1, quadrante: 6 }, { base: 1, altura: 2, quadrante: 2 }, { base: 2, altura: 2, quadrante: 5 }, { base: 3, altura: 2, quadrante: 8 }, { base: 1, altura: 3, quadrante: 4 }, { base: 2, altura: 3, quadrante: 7 }, { base: 3, altura: 3, quadrante: 9 }]
    var quadranteAtual = quadrante?.find(q => q.base === scale?.find(s => s.min <= (pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.reduce((a, b) => a += parseFloat(b?.value), 0) / pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.length) && s?.max > (pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.reduce((a, b) => a += parseFloat(b?.value), 0) / pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.length))?.value && q?.altura === scale?.find(s => s?.min <= (pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.reduce((a, b) => a += parseFloat(b?.value), 0) / pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.length) && s.max > (pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.reduce((a, b) => a += parseFloat(b?.value), 0) / pdi?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.length))?.value)?.quadrante || 0

    async function getAvaliacaoRespostas(avaliacaoID) {
        if (!colabID) return
        var avaliacao = await api.get('/rh/pdi/avaliacao/' + avaliacaoID, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setModalRespostaVisible({
            isVisible: true, avaliacao: avaliacao.data.avaliacao, avaliacaoDiscertativa: {
                valuePotencial: avaliacao.data?.avaliacaoDiscertativa?.find(d => d.pergunta === 'valuePotencial')?.texto || '',
                potencialTransicao: avaliacao.data?.avaliacaoDiscertativa?.find(d => d.pergunta === 'potencialTransicao')?.texto || '',
                pontosFortes: avaliacao.data?.avaliacaoDiscertativa?.find(d => d.pergunta === 'pontosFortes')?.texto || '',
                pontosMelhorar: avaliacao.data?.avaliacaoDiscertativa?.find(d => d.pergunta === 'pontosMelhorar')?.texto || '',
                acoesDesenvolvimento: avaliacao.data?.avaliacaoDiscertativa?.find(d => d.pergunta === 'acoesDesenvolvimento')?.texto || '',
                comentariosComite: avaliacao.data?.avaliacaoDiscertativa?.find(d => d.pergunta === 'comentariosComite')?.texto || '',
                proxima_avaliacao: avaliacao.data?.avaliacaoDiscertativa?.find(d => d.pergunta === 'proxima_avaliacao')?.texto || '',
            }, quadranteAtual: quadrante?.find(q => q.base === scale?.find(s => s.min <= (avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.reduce((a, b) => a += parseFloat(b?.value), 0) / avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.length) && s?.max > (avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.reduce((a, b) => a += parseFloat(b?.value), 0) / avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Competencias Organizacionais')?.length))?.value && q?.altura === scale?.find(s => s?.min <= (avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.reduce((a, b) => a += parseFloat(b?.value), 0) / avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.length) && s.max > (avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.reduce((a, b) => a += parseFloat(b?.value), 0) / avaliacao.data.avaliacao?.filter(p => p?.number?.split('.')?.length > 1 && p?.quadrante === 'Dominio Tecnico')?.length))?.value)?.quadrante || 0
        })
    }

    const isMobile = useMediaQuery('(max-width:680px)');

    return (
        <>
            <Container
                fluid
                className={classes.card}
                key={pdi.codigo_avaliacao}
            >
                <div className={classes.titleContainer}>
                    <div style={{ alignSelf: "baseline" }} className={classes.icon}>
                        <BiTargetLock />
                    </div>
                    <div className={classes.text}>
                        Plano de Desenvolvimento {colabAtual.nome}
                    </div>
                </div>
                <Col className={classes.contentContainer} hidden={step !== 1}>
                    <Col md='7' >
                        <Tabs
                            value={valueTab1}
                            onChange={handleChangeTab1}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            style={{
                                backgroundColor: '#f4f4f4',
                                borderRadius: '5px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                            }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: '#119e91',
                                    height: '3px',
                                }
                            }}
                        >
                            <Tab style={{ textTransform: 'none', color: '#119e91' }} label="Perfil Colaborador" />
                            <Tab style={{ textTransform: 'none', color: '#119e91' }} disabled={avaliacaoId !== null} label="Avaliações" />
                        </Tabs>
                        <Row style={{ padding: '20px' }} hidden={valueTab1 !== 0} >
                            <Col md={6} className={classes.photoCol}>
                                <Avatar style={isMobile ? { width: '60%', height: 'auto', maxWidth: '70%' } : { width: '300px', height: 'auto', maxWidth: '70%' }} id="profile_picture" variant='rounded' src={colabAtual.profile_url ? colabAtual.profile_url : ''} />
                            </Col>
                            <Col md style={{ alignSelf: 'center' }}>
                                <Row>
                                    <Col>
                                        <TextField
                                            className={classes.input}
                                            size="small"
                                            label="Data de Nascimento"
                                            value={colabAtual.nascimento ? colabAtual.nascimento : ''}
                                            variant="outlined"
                                            disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            className={classes.input}
                                            size="small"
                                            label="Unidade"
                                            value={colabAtual.unidade_txt ? colabAtual.unidade_txt : ''}
                                            variant="outlined"
                                            disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            className={classes.input}
                                            size="small"
                                            label="Admissão"
                                            value={colabAtual.admissao ? colabAtual.admissao.substring(8, 10) + '/' + colabAtual.admissao.substring(5, 7) + '/' + colabAtual.admissao.substring(0, 4) : ''}
                                            variant="outlined"
                                            disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            className={classes.input}
                                            size="small"
                                            label="Lider"
                                            value={colabAtual.chefia_txt ? colabAtual.chefia_txt : ''}
                                            variant="outlined"
                                            disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            className={classes.input}
                                            size="small"
                                            label="Departamento"
                                            value={colabAtual.departamento_txt ? colabAtual.departamento_txt : ''}
                                            variant="outlined"
                                            disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            className={classes.input}
                                            size="small"
                                            label="Cargo"
                                            value={colabAtual.cargo_txt ? colabAtual.cargo_txt : ''}
                                            variant="outlined"
                                            disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            className={classes.input}
                                            size="small"
                                            label="Salario"
                                            value={colabAtual.salario ? formatarSalario(colabAtual.salario) : ''}
                                            variant="outlined"
                                            disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {avaliacaoId === null
                                            ? <TextField
                                                id="standard-Nome"
                                                label="Comentário"
                                                multiline
                                                size="small"
                                                maxRows={3}
                                                minRows={3}
                                                variant="outlined"
                                                className={classes.input}
                                                value={colabAtual.comentario ? colabAtual.comentario : ''}
                                                disabled
                                            />
                                            : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row hidden={valueTab1 !== 1} >
                            <Col>
                                <div style={{ overflowX: 'auto' }}>
                                    <Table hover striped>
                                        <thead>
                                            <tr>
                                                <th>Avaliador</th>
                                                <th>Cargo</th>
                                                <th>Data Avaliação</th>
                                                <th>Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems.map(avaliacaoAnterior => (
                                                <tr key={avaliacaoAnterior.codigo_avaliacao}>
                                                    <td>{avaliacaoAnterior.avaliador || 'Auto Avaliação'}</td>
                                                    <td>{avaliacaoAnterior.cargo || ''}</td>
                                                    <td>{avaliacaoAnterior.data || ''}</td>
                                                    <td><Button size='sm' variant='secondary' onClick={() => getAvaliacaoRespostas(avaliacaoAnterior.codigo_avaliacao)}>Visualizar</Button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: 20, paddingBottom: 20, paddingTop: 20 }} className="pagination">
                                    {[...Array(totalPages).keys()].map(number => (
                                        <Button
                                            key={number + 1}
                                            onClick={() => setCurrentPage(number + 1)}
                                            disabled={currentPage === number + 1}
                                            style={{ fontWeight: currentPage === number + 1 ? 'bold' : 'normal' }}>
                                            {number + 1}
                                        </Button>
                                    ))}
                                </div>

                            </Col>
                        </Row>
                        <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            style={{
                                backgroundColor: '#f4f4f4',
                                borderRadius: '5px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                            }}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: '#119e91',
                                    height: '3px',
                                }
                            }}
                        >
                            <Tab style={{ textTransform: 'none', color: '#119e91' }} label="Informações ao Avaliador" />
                            <Tab style={{ textTransform: 'none', color: '#119e91' }} label="Competencias Organizacionais" />
                            <Tab style={{ textTransform: 'none', color: '#119e91' }} label="Dominio Tecnico" />
                        </Tabs>
                        <Table hidden={valueTab !== 0} borderless style={{ width: '100%', backgroundColor: '#f4f4f4', borderRadius: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
                            <tr>
                                <th colSpan={2} style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                    <h4 style={{ margin: '0', color: '#119e91', fontWeight: 'bold' }}>Instruções</h4>
                                </th>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    <ul style={{ margin: '0', paddingLeft: '20px' }}>
                                        <li>Preencha as 3 guias antes de salvar a avaliação.</li>
                                        <li>Na primeira guia utilize notas de 1 a 5 conforme descrito na sessão "Legenda de Avaliação das competências" abaixo.</li>
                                        <li>Na segunda guia escolha em qual nivel o colaborador se encontra.</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2} style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                    <h4 style={{ margin: '0', color: '#119e91', fontWeight: 'bold' }}>LEGENDA DE AVALIAÇÃO DAS COMPETÊNCIAS</h4>
                                </th>
                            </tr>
                            <tr md="2" style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    GRAU
                                </td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    INDICADORES DE AVALIAÇÃO DAS COMPETÊNCIAS
                                </td>
                            </tr>
                            <tr style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    1
                                </td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    Gaps significativos. Comporta-se abaixo do esperado para sua função/posição.
                                </td>
                            </tr>
                            <tr style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    2
                                </td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    Em desenvolvimento. Eventualmente comporta-se conforme esperado para sua posição/função.
                                </td>
                            </tr>
                            <tr style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    3
                                </td>
                                <td>
                                    Atende as expectativas. Atuação e comportamento adequados a sua função/posição.
                                </td>
                            </tr>
                            <tr style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    4
                                </td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    Supera as expectativas. Frequentemente surpreende com comportamentos acima do esperado para sua função.
                                </td>
                            </tr>
                            <tr style={{ padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', backgroundColor: '#e8e8e8' }}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    5
                                </td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    Referência Interna. É exemplo para os demais e sempre comporta-se acima das expectativas para sua função.
                                </td>
                            </tr>
                        </Table>
                        <List hidden={valueTab !== 1} subheader={<h4 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Competencias Organizacionais</h4>} style={{ padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                            {pdi.filter(p => p.number.split('.').length === 1 && p.quadrante === 'Competencias Organizacionais').map((pergunta) => (
                                <li key={`section-${pergunta.id}`} style={{ marginBottom: '15px' }}>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <h6 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px' }}>
                                            {pergunta.number + ' - ' + pergunta.pergunta}
                                        </h6>
                                        {pdi.filter(p2 => p2.number.split('.')[0] === pergunta.number && p2.number.split('.').length > 1 && p2.quadrante === 'Competencias Organizacionais').map((item, idx, arrat) => (
                                            item.type === 'multipla_escolha'
                                                ? <ListItemButton key={`item-${item.id}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', marginBottom: '5px' }}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} />
                                                    <Rating edge="end" defaultValue={parseFloat(item.value)} onChange={(evt, value) => handleAvaliacao(item.id, value)} />
                                                </ListItemButton>
                                                : <ListItemButton key={`item-${item.id}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', marginBottom: '5px' }}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} secondary={
                                                        <TextField
                                                            id={`item-${item.id}`}
                                                            multiline
                                                            size="small"
                                                            defaultValue={item.value}
                                                            maxRows={5}
                                                            minRows={5}
                                                            variant="outlined"
                                                            className={classes.input}
                                                            onChange={(e,) => handleAvaliacao(item.id, e.target.value)}
                                                        />} />
                                                </ListItemButton>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>

                        <List hidden={valueTab !== 2} subheader={<h4 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Dominio Tecnico</h4>} style={{ padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                            {pdi.filter(p => p.number.split('.').length === 1 && p.quadrante === 'Dominio Tecnico').map((pergunta) => (
                                <li key={`section-${pergunta.id}`} style={{ marginBottom: '15px' }}>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <h6 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px' }}>
                                            {pergunta.number + ' - ' + pergunta.pergunta}
                                        </h6>
                                        {pdi.filter(p2 => p2.number.split('.')[0] === pergunta.number && p2.number.split('.').length > 1 && p2.quadrante === 'Dominio Tecnico').map((item, idx, arrat) => (
                                            item.type === 'multipla_escolha'
                                                ? <ListItemButton key={`item-${item.id}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', marginBottom: '5px' }}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} />
                                                    <Rating edge="end" defaultValue={parseFloat(item.value)} onChange={(evt, value) => handleAvaliacao(item.id, value)} />
                                                </ListItemButton>
                                                : <ListItemButton key={`item-${item.id}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', marginBottom: '5px' }}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} secondary={
                                                        <TextField
                                                            id={`item-${item.id}`}
                                                            multiline
                                                            size="small"
                                                            defaultValue={item.value}
                                                            maxRows={5}
                                                            minRows={5}
                                                            variant="outlined"
                                                            className={classes.input}
                                                            onChange={(e,) => handleAvaliacao(item.id, e.target.value)}
                                                        />} />
                                                </ListItemButton>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>
                    </Col>
                </Col>
                <Row hidden={step !== 2} key={'tab-2'} style={{ width: '100%', margin: ' 50px 0 0 0', height: 'auto' }}>
                    <Col md='6' style={{ padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                        <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Matriz 9 Box</h3>
                        <Matrix
                            vertical={pdi.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Dominio Tecnico').reduce((a, b) => a += parseFloat(b.value), 0) / pdi.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Dominio Tecnico').length}
                            horizontal={pdi.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Competencias Organizacionais').reduce((a, b) => a += parseFloat(b.value), 0) / pdi.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Competencias Organizacionais').length}
                        />
                        <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Proxima Avaliação em:</h3>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker key={'tab-2-date'} format="DD/MM/YYYY" defaultValue={dayjs(avaliacaoColab?.proxima_avaliacao) || dayjs(new Date())} onChange={(e) => setAvaliacaoColab({ ...avaliacaoColab, proximaAvaliacao: e.format("DD/MM/YYYY"), proximaAvaliacaoDB: e.format("YYYY-MM-DD"), proxima_avaliacao: e })} className={classes.input} />
                        </LocalizationProvider>
                        {[5, 7, 8, 9].includes(quadranteAtual)
                            ? <FormControl key={'tab-2-formcontrol'} className={classes.input}>
                                <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Avaliação de Potencial</h3>
                                <RadioGroup
                                    key={'tab-2-radiogroup'}
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={avaliacaoColab.valuePotencial}
                                    onChange={(vp) => setAvaliacaoColab({ ...avaliacaoColab, valuePotencial: vp.target.value })}
                                >
                                    <FormControlLabel value="0" control={<Radio />} label="Potencial de domínio (está na função correta) " />
                                    <FormControlLabel value="1" control={<Radio />} label="Potencial de transição (crescimento em até 1 ano)" />
                                    <FormControlLabel value="2" control={<Radio />} label="Potencial de crescimento (pronto agora para realizar trabalhos mais desafiadores)" />
                                </RadioGroup>
                            </FormControl>
                            : null}
                    </Col>
                    <Col style={{ padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                        {[5, 7, 8, 9].includes(quadranteAtual) && [1, 2].includes(parseInt(avaliacaoColab.valuePotencial || 0))
                            ? <FormControl className={classes.input}>
                                <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Citar para quais posições identificou potencial:</h3>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    defaultValue={avaliacaoColab.potencialTransicao || ''}
                                    className={classes.input}
                                    rows={4}
                                    onBlur={(e) => setAvaliacaoColab({ ...avaliacaoColab, potencialTransicao: e.target.value })}
                                />
                            </FormControl>
                            : null}
                        <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Pontos fortes do colaborador:</h3>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            defaultValue={avaliacaoColab.pontosFortes || ''}
                            className={classes.input}
                            rows={4}
                            onBlur={(e) => setAvaliacaoColab({ ...avaliacaoColab, pontosFortes: e.target.value })}
                        />
                        <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Pontos a melhorar do colaborador:</h3>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            className={classes.input}
                            rows={4}
                            defaultValue={avaliacaoColab.pontosMelhorar || ''}
                            onBlur={(e) => setAvaliacaoColab({ ...avaliacaoColab, pontosMelhorar: e.target.value })}
                        />
                        <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Ações para o desenvolvimento do colaborador:</h3>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            className={classes.input}
                            rows={4}
                            defaultValue={avaliacaoColab.acoesDesenvolvimento || ''}
                            onBlur={(e) => setAvaliacaoColab({ ...avaliacaoColab, acoesDesenvolvimento: e.target.value })}
                        />
                        <h3 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Comentários do comitê avaliador:</h3>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            className={classes.input}
                            defaultValue={avaliacaoColab.comentariosComite || ''}
                            rows={4}
                            onBlur={(e) => setAvaliacaoColab({ ...avaliacaoColab, comentariosComite: e.target.value })}
                        />
                    </Col>
                </Row>
                <Col md style={{ width: '100%', alignSelf: 'center', display: 'flex', justifyContent: 'flex-end', padding: 20 }}>
                    {avaliacaoId === null &&
                        <Button size='sm' variant="outline-secondary" style={{ margin: '0 10px', width: isMobile ? '100%' : 'auto' }} onClick={() => step === 1 ? history.goBack() : setStep(step - 1)}>
                            Voltar
                        </Button>
                    }
                    <Button onClick={() =>
                        dominioTecnicoEditado === true
                            ? pdi.find(item => item.value === '0' && item.number.split('.').length > 1)
                                ? notify('Preencha todos os campos')
                                : step === 2 || avaliacaoId !== null
                                    ? handleSave()
                                    : setStep(step + 1)
                            : handleChangeTab(null, 2)}
                        style={{ width: isMobile ? '100%' : 'auto' }}>
                        {step === 2 || avaliacaoId !== null ? 'Salvar' : 'Avançar'}
                    </Button>
                </Col>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="light"
                />

                <Modal
                    show={modalConcluido.isVisible}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title><Row> <Col style={{ flexGrow: 0, alignSelf: 'center' }}><IoCloudUpload color="green" /></Col><Col>Auto avaliação preenchida com sucesso!</Col></Row></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Em breve entraremos em contato para agendar a avaliação com o seu gestor.
                    </Modal.Body>
                </Modal>

                <Modal
                    show={modalRespostaVisible.isVisible}
                    onHide={() => { setModalRespostaVisible({ isVisible: false }); setValueTabModal(0) }}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalRespostaVisible?.avaliacao?.[0]?.avaliador === 'Auto Avaliação' ? 'Auto Avaliação' : 'Avaliação por ' + modalRespostaVisible?.avaliacao?.[0]?.avaliador}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            value={valueTabModal}
                            onChange={handleChangeTabModal}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            style={{ marginBottom: '10px' }}
                        >
                            <Tab label="Competencias Organizacionais" />
                            <Tab label="Dominio Tecnico" />
                            {modalRespostaVisible?.avaliacao?.[0]?.avaliador === 'Auto Avaliação'
                                ? null
                                : <Tab label="Matriz 9 Box" />
                            }
                            {modalRespostaVisible?.avaliacao?.[0]?.avaliador === 'Auto Avaliação'
                                ? null
                                : <Tab label="Descritivas" />
                            }
                        </Tabs>
                        <List hidden={valueTabModal !== 0} subheader={<h4>Competencias Organizacionais</h4>}>
                            {modalRespostaVisible?.avaliacao?.filter(p => p.number.split('.').length === 1 && p.quadrante === 'Competencias Organizacionais').map((pergunta) => (
                                <li key={`section-${pergunta.id}`}>
                                    <ul>
                                        <h6>{pergunta.number + ' - ' + pergunta.pergunta}</h6>
                                        {modalRespostaVisible?.avaliacao?.filter(p2 => p2.number.split('.')[0] === pergunta.number && p2.number.split('.').length > 1 && p2.quadrante === 'Competencias Organizacionais').map((item, idx, arrat) => (
                                            item.type === 'multipla_escolha'
                                                ? <ListItemButton key={`item-${item.id}`}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} />
                                                    <Rating readOnly edge="end" defaultValue={parseFloat(item.value)} />
                                                </ListItemButton>
                                                : <ListItemButton key={`item-${item.id}`}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} secondary={
                                                        <TextField
                                                            id={`item-${item.id}`}
                                                            multiline
                                                            size="small"
                                                            defaultValue={item.value}
                                                            maxRows={5}
                                                            minRows={5}
                                                            variant="outlined"
                                                            className={classes.input}
                                                            readOnly
                                                        />} />
                                                </ListItemButton>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>

                        <List hidden={valueTabModal !== 1} subheader={<h4>Dominio Tecnico</h4>}>
                            {modalRespostaVisible?.avaliacao?.filter(p => p.number.split('.').length === 1 && p.quadrante === 'Dominio Tecnico').map((pergunta) => (
                                <li key={`section-${pergunta.id}`}>
                                    <ul>
                                        <h6>{pergunta.number + ' - ' + pergunta.pergunta}</h6>
                                        {modalRespostaVisible?.avaliacao?.filter(p2 => p2.number.split('.')[0] === pergunta.number && p2.number.split('.').length > 1 && p2.quadrante === 'Dominio Tecnico').map((item, idx, arrat) => (
                                            item.type === 'multipla_escolha'
                                                ? <ListItemButton key={`item-${item.id}`}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} />
                                                    <Rating edge="end" readOnly defaultValue={parseFloat(item.value)} />
                                                </ListItemButton>
                                                : <ListItemButton key={`item-${item.id}`}>
                                                    <ListItemText primary={item.number + ' - ' + item.pergunta} secondary={
                                                        <TextField
                                                            id={`item-${item.id}`}
                                                            multiline
                                                            size="small"
                                                            defaultValue={item.value}
                                                            maxRows={5}
                                                            minRows={5}
                                                            variant="outlined"
                                                            className={classes.input}
                                                            readOnly
                                                        />} />
                                                </ListItemButton>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>

                        <Col hidden={valueTabModal !== 2} >
                            <h3>Matriz 9 Box</h3>
                            <Matrix
                                vertical={modalRespostaVisible?.avaliacao?.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Dominio Tecnico').reduce((a, b) => a += parseFloat(b.value), 0) / modalRespostaVisible?.avaliacao?.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Dominio Tecnico').length}
                                horizontal={modalRespostaVisible?.avaliacao?.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Competencias Organizacionais').reduce((a, b) => a += parseFloat(b.value), 0) / modalRespostaVisible?.avaliacao?.filter(p => p.number.split('.').length > 1 && p.quadrante === 'Competencias Organizacionais').length}
                            />
                            <h3>Proxima Avaliação em:</h3>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker format="DD/MM/YYYY" defaultValue={dayjs(modalRespostaVisible?.avaliacaoDiscertativa?.proxima_avaliacao) || dayjs(new Date())} readOnly className={classes.input} />
                            </LocalizationProvider>
                            {[5, 7, 8, 9].includes(modalRespostaVisible?.quadranteAtual)
                                ? <FormControl className={classes.input}>
                                    <h3>Avaliação de Potencial</h3>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        defaultValue={modalRespostaVisible?.avaliacaoDiscertativa?.valuePotencial || 0}
                                        readOnly
                                    >
                                        <FormControlLabel value="0" control={<Radio />} label="Potencial de domínio (está na função correta) " />
                                        <FormControlLabel value="1" control={<Radio />} label="Potencial de transição (crescimento em até 1 ano)" />
                                        <FormControlLabel value="2" control={<Radio />} label="Potencial de crescimento (pronto agora para realizar trabalhos mais desafiadores)" />
                                    </RadioGroup>
                                </FormControl>
                                : null}
                        </Col>
                        <Col hidden={valueTabModal !== 3} style={{ width: '100%', overflow: 'auto' }}>
                            {[5, 7, 8, 9].includes(modalRespostaVisible?.quadranteAtual) && [1, 2].includes(parseInt(modalRespostaVisible?.avaliacaoDiscertativa?.valuePotencial || 0))
                                ? <FormControl className={classes.input}>
                                    <h3>Citar para quais posições identificou potencial:</h3>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        value={modalRespostaVisible?.avaliacaoDiscertativa?.potencialTransicao || ''}
                                        className={classes.input}
                                        rows={4}
                                        readOnly
                                    />
                                </FormControl>
                                : null}
                            <h3>Pontos fortes do colaborador:</h3>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                value={modalRespostaVisible?.avaliacaoDiscertativa?.pontosFortes || ''}
                                className={classes.input}
                                rows={4}
                                readOnly
                            />
                            <h3>Pontos a melhorar do colaborador:</h3>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                className={classes.input}
                                rows={4}
                                value={modalRespostaVisible?.avaliacaoDiscertativa?.pontosMelhorar || ''}
                                readOnly
                            />
                            <h3>Ações para o desenvolvimento do colaborador:</h3>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                className={classes.input}
                                rows={4}
                                value={modalRespostaVisible?.avaliacaoDiscertativa?.acoesDesenvolvimento || ''}
                                readOnly
                            />
                            <h3>Comentários do comitê avaliador:</h3>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                className={classes.input}
                                value={modalRespostaVisible?.avaliacaoDiscertativa?.comentariosComite || ''}
                                rows={4}
                                readOnly
                            />
                        </Col>
                    </Modal.Body>
                </Modal>
            </Container >
        </>
    );
}

export default PDIColaborador;