import styled from "styled-components";

export const LayoutContainer = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 3px 3px 10px gray;
  height: auto;
  width: 95vw;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    width: 95vw;
    margin: 5vh auto;
  }
`;
export const VariacaoText = styled.span<{ value: number | string }>`
  color: ${({ value }) =>
    +value > 0 ? "green" : +value < 0 ? "red" : "#FFB400"};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    background-color: #e8e8e8;
  }

  .icon {
    font-size: 2em;
    color: #119e91;
  }

  .text {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 8px;
  }
`;
export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledTableHeader = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledThead = styled.thead`
  background-color: #119e91;
  color: white;
  position: sticky;
  top: 0;
`;

export const StyledTrColums = styled.tr`
  background-color: #119e91;
  color: white;
`;

export const StyledThHeader = styled.th`
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #ddd;
  border-right: 1px solid #eee;
`;

export const StyledTh = styled.th`
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
  border-right: 1px solid #eee;
`;

export const StyledTd = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
`;

export const StyledTdContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  cursor: pointer;
`;

export const StyledTr = styled.tr<{
  selectedRow?: boolean;
  destaque?: boolean;
}>`
  font-size: 14px;
  background-color: ${({ selectedRow, destaque }) =>
    selectedRow ? "#98C7DB" : destaque ? "lightgray" : "white"};
  color: ${({ selectedRow }) => (selectedRow ? "white" : "black")};
  font-weight: ${({ selectedRow, destaque }) =>
    selectedRow ? "bold" : destaque ? "bold" : "400"};
  &:hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
`;

export const StyledTbody = styled.tbody``;

export const StyledDiv = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: row;
  justify-content: space-between;
`;
