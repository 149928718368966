import React, { useEffect, useRef, useState } from "react";
import {
  AvatarContainer,
  AvatarImage,
  ButtonContainer,
  CancelButton,
  Container,
  EditButton,
  EditButtonPassword,
  EditIcon,
  IconContainer,
  ProfileContainer,
  ProfileForm,
  SaveButton,
  Tittle,
  TittleContainer,
} from "./styles.ts";
import Input from "../../components/inputs/input.tsx";
import { useCookies } from "react-cookie";
import api from "../../services/api";
import { toast } from "react-toastify";
import sha256 from "crypto-js/sha256";
import icon from "../../assets/user.png";
import { TbUser } from "react-icons/tb";
import { UserProfileContext } from "../../contexts/userProfile.jsx";

const UserProfile: React.FC = () => {
  const [cookies, setCookie] = useCookies(["usuario"]);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const { updateProfile } = React.useContext(UserProfileContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    position: "",
    foto_url: "",
  });
  const [formPassword, setFormPassword] = useState({
    senha: "",
  });
  const [formNewPassword, setFormNewPassword] = useState({
    novaSenha: "",
    confirmacaoSenha: "",
  });
  const handleCancelPasswordChangeClick = () => {
    setIsChangingPassword(false);
  };
  const handleEditPasswordClick = () => {
    setIsEditingPassword(true);
  };
  const handleCancelPasswordClick = () => {
    setIsEditingPassword(false);
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleConfirmClick = async () => {
    try {
      const updateFormPassword = new FormData();
      updateFormPassword.append("senha", formPassword.senha);
      const response = await api.post(
        `/usuario/profile/senha?id=${cookies.usuario.id}&password=${sha256(
          formPassword.senha
        ).toString()}`,
        null,
        {
          headers: {
            authorization: `Bearer ${cookies.usuario.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setIsChangingPassword(true);
        toast.success("Senha correta!");
      }
    } catch (error) {
      console.error("Senha incorreta:", error);
      toast.error("Senha incorreta!");
    }
  };

  const handleSaveClick = async () => {
    try {
      const updateFormData = new FormData();
      updateFormData.append("nome", formData.name);
      updateFormData.append("email", formData.email);
      updateFormData.append("file", formData.foto_url);

      const response = await api.put(
        `/usuario/profile?id=${cookies.usuario.id}`,
        updateFormData,
        {
          headers: {
            authorization: `Bearer ${cookies.usuario.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Perfil atualizado com sucesso!");
        updateProfile(response.data);
      }
    } catch (error) {
      console.error("Erro ao atualizar perfil:", error);
      toast.error("Erro ao atualizar perfil.");
    }
  };

  const handleChangeClick = async () => {
    if (formNewPassword.novaSenha.length < 8) {
      toast.error("A senha deve ter pelo menos 8 caracteres.");
      return;
    }

    if (formNewPassword.novaSenha !== formNewPassword.confirmacaoSenha) {
      toast.error("As senhas não coincidem.");
      return;
    }
    try {
      const response = await api.put(
        `/usuario/profile/senha`,
        {
          id: cookies.usuario.id,
          newpassword: sha256(formNewPassword.novaSenha).toString(),
        },
        {
          headers: {
            authorization: `Bearer ${cookies.usuario.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setIsChangingPassword(true);
        toast.success("Senha alterada com sucesso!");
        window.location.reload();
      }
    } catch (error) {
      console.error("Senha em formato incorreto:", error);
      toast.error("Senha em formato incorreto!");
    }
  };

  const handleChange = (value, type, formType) => {
    const updatedFormData = {
      ...formData,
      [type]: value,
    };
    const updatedFormPassword = {
      ...formPassword,
      [type]: value,
    };
    const updateFormNewPassword = {
      ...formNewPassword,
      [type]: value,
    };

    if (formType === "formData") {
      setFormData({ ...formData, [type]: value });
    } else if (formType === "formPassword") {
      setFormPassword({ ...formPassword, [type]: value });
    } else if (formType === "formNewPassword") {
      setFormNewPassword({ ...formNewPassword, [type]: value });

      setFormNewPassword(updateFormNewPassword);
      setFormPassword(updatedFormPassword);
      setFormData(updatedFormData);
    }
  };

  useEffect(() => {
    const carregarDadosUser = async () => {
      try {
        const response = await api.get(
          `/usuario/profile?id=${cookies.usuario.id}`,
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );
        const userData = response.data[0];
        setFormData({
          name: userData.nome,
          email: userData.email,
          position: userData.cargo,
          foto_url: userData.foto_url,
        });
        setAvatarUrl(userData.foto_url);
      } catch (error) {
        console.error("Erro ao carregar módulos:", error);
      }
    };

    carregarDadosUser();
  }, []);

  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = URL.createObjectURL(e.target.files[0]);
      setAvatarUrl(img);
      handleChange(img, "foto_url", "formData");
      setFormData({ ...formData, foto_url: e.target.files[0] });
    }
  };

  const handleEditIconClick = () => {
    if (!isEditing) return;
    fileInputRef?.current?.click();
  };

  return (
    <Container>
      <TittleContainer>
        <IconContainer style={{ alignSelf: "baseline" }}>
          <TbUser />
        </IconContainer>
        <Tittle>Perfil de {formData.name} </Tittle>
      </TittleContainer>
      <ProfileContainer>
        <ProfileForm>
          {isEditingPassword ? (
            isChangingPassword ? (
              <>
                <Input
                  key={"novaSenha"}
                  type="password"
                  value={formNewPassword.novaSenha}
                  onChange={(e) =>
                    handleChange(e.target.value, "novaSenha", "formNewPassword")
                  }
                  label="Digite sua nova senha"
                  name="password"
                />
                <Input
                  key={"confirmacaoSenha"}
                  type="password"
                  value={formNewPassword.confirmacaoSenha}
                  onChange={(e) =>
                    handleChange(
                      e.target.value,
                      "confirmacaoSenha",
                      "formNewPassword"
                    )
                  }
                  label="Confirme sua nova senha"
                  name="confirmPassword"
                />
              </>
            ) : (
              <>
                <Input
                  key={"3"}
                  type="password"
                  value={formPassword.senha}
                  onChange={(e) =>
                    handleChange(e.target.value, "senha", "formPassword")
                  }
                  label="Confirme sua senha"
                  name="password"
                />
              </>
            )
          ) : (
            <>
              <AvatarContainer onClick={handleEditIconClick}>
                <AvatarImage src={avatarUrl || icon} alt="Profile" />
                <EditIcon />
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </AvatarContainer>
              <Input
                key={"name"}
                type="text"
                value={formData.name}
                onChange={(e) =>
                  handleChange(e.target.value, "name", "formData")
                }
                label="Nome"
                name="name"
                disabled={!isEditing}
              />
              <Input
                key={"email"}
                type="email"
                value={formData.email}
                onChange={() => {}}
                label="E-mail"
                name="email"
                disabled
              />
              <Input
                type="text"
                value={formData.position}
                onChange={() => {}}
                label="Cargo"
                name="position"
                disabled
              />
            </>
          )}

          {isEditing ? (
            <ButtonContainer>
              <CancelButton type="button" onClick={handleCancelClick}>
                Cancelar
              </CancelButton>
              <SaveButton type="button" onClick={handleSaveClick}>
                Salvar
              </SaveButton>
            </ButtonContainer>
          ) : isEditingPassword ? (
            isChangingPassword ? (
              <ButtonContainer>
                <CancelButton
                  type="button"
                  onClick={handleCancelPasswordChangeClick}
                >
                  Cancelar
                </CancelButton>
                <SaveButton type="button" onClick={handleChangeClick}>
                  Confirmar
                </SaveButton>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <CancelButton type="button" onClick={handleCancelPasswordClick}>
                  Cancelar
                </CancelButton>
                <SaveButton type="button" onClick={handleConfirmClick}>
                  Confirmar
                </SaveButton>
              </ButtonContainer>
            )
          ) : (
            <ButtonContainer>
              <EditButton type="button" onClick={handleEditClick}>
                Editar Perfil
              </EditButton>
              <EditButtonPassword
                type="button"
                onClick={handleEditPasswordClick}
              >
                Editar Senha
              </EditButtonPassword>
            </ButtonContainer>
          )}
        </ProfileForm>
      </ProfileContainer>
    </Container>
  );
};

export default UserProfile;
