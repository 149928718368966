import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { GrDocument, GrUpload } from "react-icons/gr";
import ModalGenerico from "../modalgenerico.tsx";
import styled from "styled-components";
import api from "../../../services/api.js";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const FileUploadLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 2px dashed #d0d0d0;
  padding: 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`;

interface ModalContratosProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
}

const ModalAdicionarNFS: React.FC<ModalContratosProps> = ({
  open,
  onClose,
  title,
  onClickButton,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [cookies] = useCookies(["usuario"]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
    }
  };

  const renderFileNames = () => {
    if (selectedFiles.length > 0) {
      return selectedFiles.map((file, index) => (
        <li key={index}>{file.name}</li>
      ));
    }
    return <li>Nenhum arquivo selecionado.</li>;
  };

  const handleSubmit = async () => {
    setIsUploading(true);
    setIsInputDisabled(true);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("file", file);
    });

    try {
      const response = await api.post(
        "/faturamento/petrobras/historico",
        formData,
        {
          headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
        }
      );
      if (response.status === 200) {
        toast.success("Arquivo enviado com sucesso!");
        setSelectedFiles([]);
      }
    } catch (error) {
      toast.error("Erro ao enviar o arquivo!");
      console.error("Erro ao enviar arquivos!", error);
    } finally {
      setIsUploading(false);
      setIsInputDisabled(false);
    }
  };

  const carregandoUpload = isUploading ? (
    <div style={{ padding: "1px 0" }}>
      <Spinner size="sm" animation="border" />
    </div>
  ) : (
    "Enviar"
  );

  useEffect(() => {
    if (!open) {
      setSelectedFiles([]);
      setIsInputDisabled(false);
      setIsUploading(false);
    }
  }, [open]);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setSelectedFiles(Array.from(event.dataTransfer.files));
      event.dataTransfer.clearData();
    }
  };

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      footerButtonSubmit={carregandoUpload}
      onClickButtonSubmit={handleSubmit}
      title={title}
      showSearchIcon={false}
      buttonContent={<GrDocument />}
      buttonSubmitDisabled={selectedFiles.length === 0 || isInputDisabled}
      dialogContent={
        <>
          <UploadContainer>
            <FileUploadLabel
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              htmlFor="file-upload"
            >
              <GrUpload />
              <span>
                Carregue seus arquivos <strong>NFS-e</strong> aqui
              </span>
            </FileUploadLabel>
            <input
              id="file-upload"
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              multiple
              style={{ display: "none" }}
              disabled={isInputDisabled}
            />
            {renderFileNames()}
          </UploadContainer>
        </>
      }
    />
  );
};

export default ModalAdicionarNFS;
