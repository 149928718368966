import React, { useEffect, useState } from "react";
import { RiMenuAddFill } from "react-icons/ri";
import Select from "react-select";
import ModalGenerico from "../modalgenerico.tsx";
import { BsPeople } from "react-icons/bs";
import api from "../../../services/api";
import { Table } from "react-bootstrap";
import { useCookies } from "react-cookie";

interface ModalToplineShowProps {
  open: boolean;
  onClose: () => void;
  onClickButton: () => void;
  notify: (string) => void;
  selected: any;
  anoConsulta: any;
  setToplineLines: any;
  setShowModalLinhas: any;
}

const ModalToplineShow: React.FC<ModalToplineShowProps> = ({
  open,
  onClose,
  onClickButton,
  notify,
  selected,
  anoConsulta,
  setToplineLines,
  setShowModalLinhas,
}) => {
  const [cookies] = useCookies(["usuario"]);
  const [codigosShow, setCodigosShow] = useState([]);
  const [codigos, setCodigos] = useState([]);
  useEffect(() => {
    loadCodigos();
  }, []);

  async function loadCodigos() {
    try {
      const myCodigos = await api.get("/codigosregistros", {
        headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
      });
      setCodigos(
        myCodigos.data
          .filter((c) => c.descricao !== "")
          .sort()
          .reduce(
            (acc, cv) =>
              (acc = [...acc, { label: cv.descricao, value: cv.id }]),
            []
          )
      );
    } catch (error) {
      console.error("Erro ao carregar codigos: " + error);
      notify("Erro ao carregar codigos!");
    }
  }

  function buildOperacoes(obj) {
    var operacoes = [
      {
        value: "sum",
        label: "Soma",
      },
      {
        value: "avg",
        label: "Média",
      },
      {
        value: "min",
        label: "Minimo",
      },
      {
        value: "max",
        label: "Máximo",
      },
    ];
    return (
      <Select
        menuPosition="fixed"
        name="colors"
        defaultValue={operacoes.filter((d) => d.value === obj.operacoes)}
        options={operacoes}
        closeMenuOnSelect={true}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Escolha o calculo..."
        onChange={(a) => (obj.operacoes = a.value)}
      />
    );
  }

  function buildFormatos(obj) {
    var formats = [
      {
        value: "currency",
        label: "Reais",
        format: (val) =>
          val.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
      },
      {
        value: "percentage",
        label: "Percentual",
        format: (val) =>
          val.toLocaleString("pt-BR", {
            style: "percent",
            maximumFractionDigits: 2,
          }),
      },
      {
        value: "abs",
        label: "Sem formatação",
        format: (val) => val.toFixed(2),
      },
      {
        value: "pessoas",
        label: "Pessoas",
        format: (val) => (
          <>
            {val.toFixed(2)} <BsPeople />
          </>
        ),
      },
    ];

    return (
      <Select
        menuPosition="fixed"
        name="colors"
        options={formats}
        defaultValue={formats.filter((d) => d.value === obj.formatValue)}
        closeMenuOnSelect={true}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Escolha o formato..."
        onChange={(a) => {
          obj.formato = a.format;
          obj.formatValue = a.value;
        }}
      />
    );
  }

  async function adicionaLinhasTopline() {
    if (selected.length === 1) {
      if (codigosShow.length > 0) {
        try {
          var toplineRes = await api.post(
            "/topline/orcadorealizado/" +
            selected[0].value +
            "/" +
            anoConsulta +
            "?usuario_id=" +
            cookies.usuario.id,
            {
              codigosShow,
            },
            {
              headers: {
                authorization: `Bearer ${cookies.usuario.accessToken}`,
              },
            }
          );
          toplineRes.data.map(
            (r) =>
            (r.formato = codigosShow?.find(
              (c) => c.value === r.value
            )?.formato)
          );
          setToplineLines(toplineRes.data);
          setShowModalLinhas([false]);
        } catch (error) {
          console.error(
            "Erro ao carregar topline orcado x realizado: " + error
          );
          console.error(codigosShow);
          notify("Erro ao carregar dados do topline!");
          setShowModalLinhas([false]);
        }
      } else {
        notify("Escolha pelo menos uma linha para adicionar!");
      }
    } else {
      notify("A desenvolver, somente um combo é permitido!");
    }
  }

  return (
    <ModalGenerico
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={"Topline"}
      showSearchIcon={false}
      buttonContent={<RiMenuAddFill />}
      footerButtonSubmit="Analisar"
      onClickButtonSubmit={() => adicionaLinhasTopline()}
      dialogContent={
        <>
          <Select
            isMulti
            name="colors"
            options={codigos}
            defaultValue={codigosShow}
            closeMenuOnSelect={false}
            menuPosition="fixed"
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Escolha as linhas a adicionar..."
            onChange={setCodigosShow}
          />
          {codigosShow.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Operação</th>
                  <th>Formato</th>
                </tr>
              </thead>
              <tbody>
                {codigosShow?.map((codigo, idx) => {
                  return (
                    <tr key={"codigo" + idx}>
                      <td>{codigo?.label}</td>
                      <td style={{ width: "25%" }}>{buildOperacoes(codigo)}</td>
                      <td style={{ width: "25%" }}>{buildFormatos(codigo)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </>
      }
    />
  );
};

export default ModalToplineShow;
