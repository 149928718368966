import React, { useState, useEffect } from "react";
import { GrDocument } from "react-icons/gr";
import Select from "react-select";
import api from "../../../../services/api.js";
import ModalGenerico from "../../modalgenerico.tsx";
import { useCookies } from "react-cookie";
import QuestionSection from "./questionSection.jsx";
import { Row } from "../../styles.ts";
import { FiChevronsRight, FiChevronsDown } from "react-icons/fi";
import { useMediaQuery, Tabs, Tab, Box } from "@material-ui/core";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: "#119e91",
      },
    },
  },
});

interface ModalHerancaProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
  unidadesSelectedBusca?: any;
  cargosSelectedBusca: any;
  cargosSelectedDestino: any;
  inativos: any;
  handleToggle: any;
  loadPerguntas: any;
  setLoadPerguntas: any;
  perguntas?: any;
  setPerguntas: any;
  classes: any;
  unidades: any;
  setUnidadesSelectedBusca: any;
  cargos: any;
  setCargosSelectedBusca: any;
  setCargosSelectedDestino: any;
  setUnidadesSelectedDestino: any;
  unidadesSelectedDestino: any;
  typePergunta: string;
}

const ModalHerancaPerguntas: React.FC<ModalHerancaProps> = ({
  open,
  onClose,
  title,
  onClickButton,
  unidadesSelectedBusca,
  unidadesSelectedDestino,
  cargosSelectedBusca,
  cargosSelectedDestino,
  inativos,
  handleToggle,
  loadPerguntas,
  setLoadPerguntas,
  perguntas,
  setPerguntas,
  classes,
  unidades,
  setUnidadesSelectedBusca,
  setUnidadesSelectedDestino,
  cargos,
  setCargosSelectedBusca,
  setCargosSelectedDestino,
  typePergunta,
}) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [cookies] = useCookies(["usuario"]);
  const [isAvancar, setIsAvancar] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const isMobile = useMediaQuery("(max-width: 680px)");
  const resetModalState = () => {
    setPerguntas([]);
    setChecked([]);
    setSelectedItems([]);
    setIsAvancar(true);
  };
  const getPerguntas = async () => {
    const perguntas = await api.get(
      "/rh/pdi/perguntas?unidade_id=" +
        unidadesSelectedBusca?.value +
        "&cargo_id=" +
        cargosSelectedBusca?.id +
        "&includeInativos=false",
      { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
    );
    setChecked([]);
    if (inativos) {
      setChecked(
        perguntas?.data
          .filter((p) => p.is_active)
          .map((p) => p.quadrante + "-" + p.number)
      );
    }
    return setPerguntas(perguntas.data);
  };

  const verificaPerguntasExistentesDestino = async () => {
    if (!cargosSelectedDestino || !cargosSelectedDestino.value) {
      toast.error("Selecione um cargo de destino!");
      return;
    }

    try {
      const response = await api.get(
        `/rh/pdi/perguntas?unidade_id=${unidadesSelectedDestino?.value}&cargo_id=${cargosSelectedDestino.value}&includeInativos=${inativos}`,
        {
          headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
        }
      );

      if (response.data && response.data.length > 0) {
        toast.error("O cargo de destino já possui perguntas.");
        setIsAvancar(true);
      } else {
        getPerguntas();
        setIsAvancar(false);
      }
    } catch (error) {
      console.error("Erro ao verificar o cargo de destino:", error);
      toast.error("Ocorreu um erro, por favor tente novamente!");
    }
  };

  const handleEnviar = async () => {
    if (
      !perguntas ||
      !unidadesSelectedDestino ||
      !cargosSelectedDestino ||
      checked.length === 0
    ) {
      return alert(
        "Preencha todos os campos e selecione pelo menos uma pergunta!"
      );
    }
    const promises = checked.map((perguntaSelecionada: any) => {
      return api.post(
        "/rh/pdi/pergunta",
        {
          ordem: perguntaSelecionada.ordem,
          pergunta: perguntaSelecionada.pergunta,
          unidadesIds: [unidadesSelectedDestino.value],
          cargosIds: [cargosSelectedDestino.value],
          type: typePergunta,
          quadrante: perguntaSelecionada.quadrante,
        },
        {
          headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
        }
      );
    });

    try {
      toast.success("Todas as perguntas foram adicionadas com sucesso!");

      await getPerguntas();
    } catch (error) {
      console.error("Erro ao salvar as perguntas:", error);
      toast.error("Erro ao salvar as perguntas, tente novamente.");
    }
  };

  const handleCheckChange = (item, isChecked) => {
    setSelectedItems((current: any) => {
      const isSelected = current.some(
        (selectedItem) => selectedItem.id === item.id
      );

      if (isChecked !== undefined) {
        if (isChecked && !isSelected) {
          const newSelectedItems = [...current, item];
          setChecked(newSelectedItems);
          return newSelectedItems;
        } else if (!isChecked && isSelected) {
          const newSelectedItems = current.filter(
            (selectedItem) => selectedItem.id !== item.id
          );
          return newSelectedItems;
        }
        return current;
      } else {
        if (isSelected) {
          let newSelectedItems = current.filter(
            (selectedItem) => selectedItem.id !== item.id
          );

          // Verificar se o item desselecionado é um "filho"
          if (item.ordem.includes(".")) {
            const ordemPrefix = item.ordem.split(".")[0];

            // Verifica se ainda existem filhos desse "pai" selecionados
            const hasOtherSelectedChildren = newSelectedItems.some(
              (selectedItem) => selectedItem.ordem.startsWith(ordemPrefix + ".")
            );

            // Se não houver mais filhos selecionados, também remove o "pai"
            if (!hasOtherSelectedChildren) {
              newSelectedItems = newSelectedItems.filter(
                (selectedItem) =>
                  !(
                    selectedItem.ordem === ordemPrefix ||
                    selectedItem.ordem.startsWith(ordemPrefix + ".")
                  )
              );
            }
          }
          return newSelectedItems;
        } else {
          let newSelectedItems = [...current, item];
          perguntas.map((pergunta) => {
            if (
              newSelectedItems.find(
                (a) => a.id === pergunta.id && a.pergunta === pergunta.pergunta
              )
            ) {
              return null;
            }

            const p = perguntas.find((p) => p.id === item.id);

            if (
              pergunta.number === p.number?.split(".")[0] &&
              pergunta.quadrante === p.quadrante
            ) {
              newSelectedItems = [
                ...newSelectedItems,
                {
                  id: pergunta.id,
                  pergunta: pergunta.pergunta,
                  ordem: pergunta.number,
                  quadrante: pergunta.quadrante,
                },
              ];
            }
          });

          const indicePorId = new Map<string, number>();
          perguntas.forEach((item, index) => {
            indicePorId.set(item.id, index);
          });

          newSelectedItems.sort((a, b) => {
            const indiceA = indicePorId.get(a.id) || 0;
            const indiceB = indicePorId.get(b.id) || 0;
            return indiceA - indiceB;
          });

          setChecked(newSelectedItems);
          return newSelectedItems;
        }
      }
    });
  };

  //não estamos usando mas podemos usar no futuro
  const handleOrderUp = (currentItem, previousItem) => {
    if (!previousItem) return;
    setPerguntas((perguntas) => {
      const index = perguntas.findIndex((item) => item.id === currentItem.id);
      const prevIndex = perguntas.findIndex(
        (item) => item.id === previousItem.id
      );

      if (index > 0) {
        const newPerguntas = [...perguntas];
        [newPerguntas[index], newPerguntas[prevIndex]] = [
          newPerguntas[prevIndex],
          newPerguntas[index],
        ];
        return newPerguntas;
      }
      return perguntas;
    });
  };

  //não estamos usando mas podemos usar no futuro
  const handleOrderDown = (currentItem, nextItem) => {
    if (!nextItem) return;
    setPerguntas((perguntas) => {
      const index = perguntas.findIndex((item) => item.id === currentItem.id);
      const nextIndex = perguntas.findIndex((item) => item.id === nextItem.id);

      if (index < perguntas.length - 1) {
        const newPerguntas = [...perguntas];
        [newPerguntas[index], newPerguntas[nextIndex]] = [
          newPerguntas[nextIndex],
          newPerguntas[index],
        ];
        return newPerguntas;
      }
      return perguntas;
    });
  };

  useEffect(() => {
    if (loadPerguntas) {
      getPerguntas();
      setLoadPerguntas(false);
    }
  }, [loadPerguntas, setLoadPerguntas]);

  useEffect(() => {
    if (!open) {
      setUnidadesSelectedBusca(null);
      setCargosSelectedBusca(null);
      setUnidadesSelectedDestino(null);
      setCargosSelectedDestino(null);
      setPerguntas([]);
      setChecked([]);
      setIsAvancar(true);
    }
  }, [open]);

  const handleAvancar = () => {
    verificaPerguntasExistentesDestino();
    setIsAvancar(false);
  };

  const handleUnidadesSelectedBuscaChange = (selectedOption) => {
    setUnidadesSelectedBusca(selectedOption);
    resetModalState();
  };

  const handleCargosSelectedBuscaChange = (selectedOption) => {
    setCargosSelectedBusca(selectedOption);
    resetModalState();
  };

  const setCargosSelectedDestinoChange = (selectedOption) => {
    setCargosSelectedDestino(selectedOption);
    resetModalState();
  };

  const handleUnidadesSelectedDestinoChange = (selectedOption) => {
    setUnidadesSelectedDestino(selectedOption);
    resetModalState();
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      showSearchIcon={false}
      footerButtonSubmit={isAvancar ? "Buscar" : "Enviar"}
      onClickButtonSubmit={isAvancar ? handleAvancar : handleEnviar}
      buttonContent={<GrDocument />}
      dialogContent={
        <>
          <Row>
            <div style={{ width: "100%" }}>
              <Select
                className={classes.input}
                options={unidades}
                placeholder="Escolha a Unidade..."
                closeMenuOnSelect={true}
                onChange={handleUnidadesSelectedBuscaChange}
                value={unidadesSelectedBusca}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              <Select
                className={classes.input}
                options={cargos}
                placeholder="Escolha o cargo..."
                closeMenuOnSelect={true}
                onChange={handleCargosSelectedBuscaChange}
                value={cargosSelectedBusca}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </div>

            {isMobile ? (
              <FiChevronsDown style={{ width: "100px", height: "30px" }} />
            ) : (
              <FiChevronsRight style={{ width: "100px", height: "30px" }} />
            )}

            <div style={{ width: "100%" }}>
              <Select
                className={classes.input}
                options={unidades}
                placeholder="Escolha a Unidade..."
                closeMenuOnSelect={true}
                onChange={handleUnidadesSelectedDestinoChange}
                value={unidadesSelectedDestino}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              <Select
                className={classes.input}
                options={cargos}
                placeholder="Escolha o cargo..."
                closeMenuOnSelect={true}
                onChange={setCargosSelectedDestinoChange}
                value={cargosSelectedDestino}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </div>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ThemeProvider theme={theme}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="simple tabs example"
                TabIndicatorProps={{
                  style: { backgroundColor: "green !important" },
                }}
              >
                <Tab label="Competências Organizacionais" />
                <Tab label="Domínio Técnico" />
              </Tabs>
            </ThemeProvider>
          </div>

          <TabPanel value={tabValue} index={0}>
            <QuestionSection
              perguntas={perguntas}
              quadrante="Competencias Organizacionais"
              onToggle={handleToggle}
              onMoveUp={handleOrderUp}
              onMoveDown={handleOrderDown}
              handleCheckChange={handleCheckChange}
              selectedItems={selectedItems}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <QuestionSection
              perguntas={perguntas}
              quadrante="Dominio Tecnico"
              onToggle={handleToggle}
              onMoveUp={handleOrderUp}
              onMoveDown={handleOrderDown}
              handleCheckChange={handleCheckChange}
              selectedItems={selectedItems}
            />
          </TabPanel>
        </>
      }
    />
  );
};

export default ModalHerancaPerguntas;
