import React, { useState } from "react";
import ModalGenerico from "../modalgenerico.tsx";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { RiAddCircleLine } from "react-icons/ri";
import { GoArchive } from "react-icons/go";
import api from "../../../services/api.js";
import { useCookies } from "react-cookie";

interface ModalRespostasProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
  searchInput?: React.ReactNode;
  modalRespostaVisible?: any;
  tooltipText?: string;
  setToolipText: any;
  id: string;
  nivelContaAtual: any;
  modalComentariosShow: any;
  notify: (string) => void;
  selected: any[];
  anoConsulta: string;
  loadData: () => void;
  PermiteComentarioOrcamento?: boolean;
}

const ModalRespostas: React.FC<ModalRespostasProps> = ({
  open,
  onClose,
  title,
  onClickButton,
  modalRespostaVisible,
  tooltipText = "Adicionar Anexo",
  setToolipText,
  id = "AnexoAtual",
  nivelContaAtual,
  modalComentariosShow,
  notify,
  selected,
  anoConsulta,
  loadData,
  PermiteComentarioOrcamento = false,
}) => {
  const [cookies] = useCookies(["usuario"]);
  const [isComentario, setIsComentario] = useState(false);
  const NovaResposta = document.getElementById("Nova-resposta") ?? "";
  const Unidades = sessionStorage.getItem("unidades") ?? "";
  let resposta = (NovaResposta as HTMLInputElement)?.value;
  const MYFiles = document.getElementById("anexoAtual") ?? "";
  let myFiles = (MYFiles as HTMLInputElement)?.files;
  let myFilesValue = (MYFiles as HTMLInputElement)?.value;

  async function adicionaRespostaGeral(contaOrigem, registroRazao) {
    if (modalComentariosShow[4] === "orcado" && !PermiteComentarioOrcamento)
      return notify(
        "Comentario sobre o orçamento são permitidos apenas durante a elaboração!"
      );
    if (contaOrigem === "sintetica") {
      if (resposta) return notify("Resposta vazia!");
      JSON.parse(Unidades)
        .filter((u) =>
          selected
            .reduce((acc, cv) => (acc = [...acc, cv.value]), [])
            .includes(u.combo_id)
        )
        ?.map(async (d) => {
          var response = await api.post(
            "/createRespostasGeral",
            {
              registrodrerazao_id: registroRazao.codigodre_id,
              comentario: resposta,
              usuario_id: cookies?.usuario?.id,
              unidade_id: d.id,
              grupo: registroRazao.grupo,
              codigodre_id: registroRazao.codigodre_id,
              mes: parseFloat(modalComentariosShow[3]),
              ano: anoConsulta,
              drerazaocomentario_id: registroRazao.id,
              origem:
                modalComentariosShow[4] === "orcado" ? "orcado" : "realizado",
              pagina: "/dre/orcadorealizado",
            },
            {
              headers: {
                authorization: `Bearer ${cookies?.usuario.accessToken}`,
              },
            }
          );

          var myFiles = myFilesValue;
          if (myFiles.length) {
            for (let i = 0; i < myFiles.length; i++) {
              const dados = new FormData();

              dados.append("file", myFiles[i]);
              dados.append(
                "drerazaocomentario_id",
                response.data.comentario.id
              );

              await api.post("/files", dados, {
                headers: {
                  authorization: `Bearer ${cookies.usuario.accessToken}`,
                },
              });
            }
            myFilesValue = "";
            setToolipText("Adicionar anexo");
          }

          notify(d.label + " " + response.data.mensagem);
        });
      resposta = "";
      loadData();
    } else if (contaOrigem === "analitica") {
      if (resposta) return notify("Resposta vazia!");
      var response = await api.post(
        "/createRespostas",
        {
          registrodrerazao_id: registroRazao.registrodrerazao_id,
          comentario: resposta,
          usuario_id: cookies?.usuario.id,
          unidade_id: registroRazao.unidade_id,
          codigodre_id: registroRazao.codigodre_id,
          origem: modalComentariosShow[4] === "orcado" ? "orcado" : "realizado",
          mes: parseFloat(registroRazao.mes),
          ano: anoConsulta,
          drerazaocomentario_id: registroRazao.id,
          pagina: "/dre/orcadorealizado",
        },
        {
          headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
        }
      );

      var myFiles = myFilesValue;
      if (myFiles.length) {
        for (let i = 0; i < myFiles.length; i++) {
          const dados = new FormData();

          dados.append("file", myFiles[i]);
          dados.append("drerazaocomentario_id", response.data.id);

          await api.post("/files", dados, {
            headers: {
              authorization: `Bearer ${cookies.usuario.accessToken}`,
            },
          });
        }
        myFiles = "";
        setToolipText("Adicionar anexo");
      }

      notify(response.data.mensagem);
      resposta = "";
      loadData();
    }
  }

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      showSearchIcon={false}
      buttonContent={<GoArchive />}
      buttonContentOptional={
        <Button
          size="sm"
          style={{ marginLeft: "8px" }}
          variant={isComentario ? "primary" : "success"}
          onClick={() => {
            setIsComentario(!isComentario);
          }}
        >
          {isComentario ? "Faça uma pergunta!" : "Faça um comentário!"}
        </Button>
      }
      dialogContent={
        <>
          <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
            <Col>
              <h5>
                {modalRespostaVisible?.[1]?.nome +
                  " pergunta: " +
                  modalRespostaVisible?.[1]?.comentario}
              </h5>
            </Col>
          </Row>
          <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
            <Col>
              <InputGroup>
                <FormControl
                  placeholder="Resposta"
                  aria-label="Resposta"
                  id={"Nova-resposta"}
                />
                <OverlayTrigger
                  overlay={
                    <Tooltip id={"tooltipRespostas"} style={{ zIndex: 100000 }}>
                      {tooltipText}
                    </Tooltip>
                  }
                >
                  <Button
                    variant={
                      tooltipText !== "Adicionar anexo"
                        ? "success"
                        : "outline-secondary"
                    }
                    style={{
                      borderRadius: 0,
                      outline: "none",
                      boxShadow: "none",
                    }}
                    id={"buttonSave-resposta"}
                    onClick={() => {
                      document?.getElementById(id)?.click();
                    }}
                  >
                    <IoDocumentAttachOutline />
                  </Button>
                </OverlayTrigger>
                <input
                  type="file"
                  hidden
                  id="anexoAtual"
                  onChange={(e) => {
                    e.target.files != null && e?.target?.files[0]
                      ? setToolipText(e?.target?.files[0].name)
                      : setToolipText("Adicionar anexo");
                  }}
                />
                <Button
                  variant="outline-secondary"
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    outline: "none",
                  }}
                  id={"buttonSave-resposta"}
                  onClick={() =>
                    adicionaRespostaGeral(
                      nivelContaAtual,
                      modalRespostaVisible[1]
                    )
                  }
                >
                  <RiAddCircleLine /> Responder
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default ModalRespostas;
