import React from 'react';
import './Matrix.css';

const Matrix = (props) => {
    // Dados da matriz 3x3
    const horizontal = props.horizontal || 1;
    const vertical = props.vertical || 1;

    var scale = [{ min: 1, max: 2.6, value: 1 }, { min: 2.6, max: 3.3, value: 2 }, { min: 3.3, max: 6, value: 3 }]

    const matrixData = [
        ['1 - Baixa Entrega e Baixa Performance', '2 - Média Entrega e Baixa Performance ', '4 - Alta Entrega e Baixa Performance'],
        ['3 - Baixa Entrega e Média Performance ', '5 - Média Entrega e Média Performance ', '7 - Alta Entrega e Média Performance'],
        ['6 - Baixa Entrega e Alta Performance ', '8 - Média Entrega e Alta Performance ', '9 - Alta Entrega e Alta Performance']
    ];

    return (
        <div className="matrix">
            {matrixData.map((row, rowIndex) => (
                <div key={rowIndex} className="rowMatrix">
                    {row.map((cell, cellIndex) => (
                        <div key={cellIndex} className="cellMatrix" style={(rowIndex + 1) === scale.find(s => s.min <= horizontal && s.max > horizontal)?.value && (cellIndex + 1) === scale.find(s => s.min <= vertical && s.max > vertical)?.value ? { background: '#119e91', color: 'white' } : null}>
                            {cell}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Matrix;