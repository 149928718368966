import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie';
import { UserProfileProvider } from './contexts/userProfile';

import Routes from './routes'

import store from './components/store'

function App() {

  return (
    <UserProfileProvider>
      <CookiesProvider>
        <Provider store={store}>
          <Routes />
        </Provider>
      </CookiesProvider>
    </UserProfileProvider>
  );
}

export default App;
