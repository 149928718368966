import React, { useState } from "react";
import ModalGenerico from "../../modalgenerico.tsx";
import { GrDocument } from "react-icons/gr";
import { useDispatch } from "react-redux";
import readXlsxFile from "read-excel-file";
import api from "../../../../services/api.js";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Tabs, Tab, Box } from "@material-ui/core";
import TabAdmin from "./Admin/TabAdmin.js";
import TabOperaCloud from "./Admin/TabOperaCloud.js";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: "#119e91",
      },
    },
  },
});

interface ModalContratosProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
}

const ModalAdicionarTopline: React.FC<ModalContratosProps> = ({
  open,
  onClose,
  title,
  onClickButton,
}) => {
  const [cookies] = useCookies();
  const [anoSelecionado2, setAnoSelecionado2] = useState(
    new Date().getFullYear()
  );
  const [tabValue, setTabValue] = useState(0);

  const dispatch = useDispatch();

  const notify = ($msg, $time = 5000) =>
    toast($msg, {
      position: "bottom-right",
      autoClose: $time,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  async function handlerInputFileBudgetTopline(file) {
    var myData = false;
    dispatch({
      type: "OPEN_LOADING",
      text: "Enviando dados...",
      isLoading: true,
    });

    await readXlsxFile(file.target.files[0], { getSheets: true }).then(
      (sheets) => {
        sheets.map(async (mySheet) => {
          await readXlsxFile(file.target.files[0], {
            sheet: mySheet.name,
          }).then(async (res) => {
            var topLineData = [];
            res.map((linha) => {
              if (linha[0]) {
                if (
                  typeof linha[0] === "string" &&
                  (linha[0].includes("Diff %") || linha[0].includes("Variação"))
                ) {
                  myData = false;
                }
                if (typeof linha[0] === "string" && (linha[0].includes("Orçamento") || myData)) {
                  myData = true;
                  for (var i = 1; i <= 12; i++) {
                    if (linha[i]) {
                      topLineData.push({
                        id:
                          linha[0] +
                          "-" +
                          mySheet.name +
                          "-" +
                          anoSelecionado2 +
                          "-" +
                          i,
                        unidade_id: mySheet.name,
                        codigo_id: linha[0],
                        ano: anoSelecionado2,
                        lastupdatedat: new Date().toISOString(),
                        valor: linha[i],
                        usuario_id: cookies.usuario.id,
                        mes: i,
                      });
                    }
                  }
                }
              }
            });
            await api
              .post(
                "/toplinebudget",
                { topLineData },
                {
                  headers: {
                    authorization: `Bearer ${cookies.usuario.accessToken}`,
                  },
                }
              )
              .then((res) => notify(mySheet.name + " - " + res.data));
          });
        });
        document.getElementsByName("budgetTopline")[0].value = null;
        dispatch({ type: "CLOSE_LOADING", text: "", isLoading: false });
      }
    );
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      showSearchIcon={false}
      buttonContent={<GrDocument />}
      dialogContent={
        <>
          <ThemeProvider theme={theme}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
            >
              <Tab label="Topline Budget" />
              <Tab label="Patrimonial" />
              <Tab label="Opera Cloud" />
            </Tabs>
          </ThemeProvider>
          {tabValue === 0 && (
            <Box p={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                  padding: "20px",
                  border: "1px dashed #ccc",
                  borderRadius: "10px",
                  marginTop: "20px",
                  backgroundColor: "#f9f9f9",
                  maxWidth: "1000px",
                  margin: "20px auto",
                }}
              >
                <div>
                  <label
                    style={{
                      display: "block",
                      margin: "8px 0",
                      fontWeight: "bold",
                    }}
                  >
                    Topline budget:{" "}
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <input
                    type="number"
                    placeholder="Ano do dre"
                    onChange={(e) =>
                      setAnoSelecionado2(parseInt(e.target.value))
                    }
                    defaultValue={new Date().getFullYear() + 1}
                    style={{
                      flexGrow: 1,
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      margin: "8px 0",
                    }}
                  />
                  <input
                    type="file"
                    name="budgetTopline"
                    onChange={(e) => handlerInputFileBudgetTopline(e)}
                    accept=".xlsx"
                    style={{
                      flexGrow: 1,
                      padding: 20,
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      cursor: "pointer",
                      width: "90%",
                    }}
                  />
                  <label style={{ margin: "8px 0" }}>(Arquivo em xlsx)</label>
                </div>
              </div>
            </Box>
          )}
          {tabValue === 1 && <Box p={3}>{TabAdmin}</Box>}
          {tabValue === 2 && <Box p={3}>{TabOperaCloud}</Box>}
        </>
      }
    />
  );
};

export default ModalAdicionarTopline;
