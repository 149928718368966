/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Button, Card, Col, Form, FormControl, InputGroup, Modal, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useCookies } from 'react-cookie';

//GetData
import axios from 'axios';
import XMLParser from 'react-xml-parser';
import readXlsxFile from 'read-excel-file';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../services/api';

// redux
import { useDispatch } from 'react-redux';

import './styles.css';

// Componentes
import TransferList from '../../components/TransferList';

function Registros() {

  const dispatch = useDispatch()

  const [cookies] = useCookies();
  const [unidadeSelecionada, setUnidadeSelecionada] = useState('')
  const [unidadeSelecionadaMulti, setUnidadeSelecionadaMulti] = useState([])
  const [anoSelecionado, setAnoSelecionado] = useState(parseInt(new Date().getFullYear()))
  const [anoSelecionado2, setAnoSelecionado2] = useState(parseInt(new Date().getFullYear()))
  const [adminAllow, setAdminAllow] = useState(false)
  const [modalSNData, setModalSNData] = useState({ isVisible: false })
  const [configuracoes, setConfiguracoes] = useState([])

  let usuarioId = cookies.usuario.id

  // eslint-disable-next-line
  useEffect(() => { loadData() }, [])

  const notify = ($msg, $time = 5000) => toast($msg, {
    position: "bottom-right",
    autoClose: $time,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  async function handlerInputFileRazao(file, isfinal) {
    if (!file.target.value.includes('xml')) {
      notify("Arquivo inválido!")
      document.getElementsByName('razaoexpresso')[0].value = null
      return
    }
    dispatch({ type: 'OPEN_LOADING', text: "Enviado dados...", isLoading: true })
    for (let i = 0; i < file.target.files.length; i++) {
      var preview5 = URL.createObjectURL(file.target.files[i])
      axios.get(preview5, {
        "Content-Type": "application/xml; charset=utf-8"
      }).then(async response => {
        var razaoObj = []
        var xml = new XMLParser().parseFromString(response.data)  // Assume xmlText contains the example XML
        var sheet = xml.getElementsByTagName("Table")[0]
        var codigodre_id = null
        var codigodre_idAtual = null
        var data = null
        var lancamento = null
        var centrodecusto = null
        var intinterrogacao = null
        var atpj = null
        var mod = null
        var historico = null
        var debito = null
        var credito = null
        var saldo = null
        var start = false
        var month = null
        var year = null
        var linhasExcluidas = []
        var empresa = sheet.getElementsByTagName('Data')[0].value
        sheet.getElementsByTagName('Row').map(async linha => {
          if (linha.children.length) {
            if (linha.children[0].getElementsByTagName('Data')[0].value.includes('Conta ') && linha.children.length === 3) {
              if (linha.children[1].getElementsByTagName('Data')[0]) {
                start = true
                codigodre_id = linha.children[1].getElementsByTagName('Data')[0].value
              }
            } else {
              if (linha.children[0].getElementsByTagName('Data')[0].value.substring(0, 5) === (anoSelecionado + '-')) {
                if (linha.children[1]) {
                  if (linha.children[1].getElementsByTagName('Data')[0].value !== '0' && linha.children.length === 11) {
                    if (lancamento === null) {
                      codigodre_idAtual = codigodre_id
                      data = linha.children[0].getElementsByTagName('Data')[0].value
                      lancamento = linha.children[1].getElementsByTagName('Data')[0].value
                      intinterrogacao = linha.children[2].getElementsByTagName('Data')[0].value + linha.children[3].getElementsByTagName('Data')[0].value
                      centrodecusto = linha.children[4].getElementsByTagName('Data')[0].value
                      atpj = linha.children[5].getElementsByTagName('Data')[0].value
                      mod = linha.children[6].getElementsByTagName('Data')[0].value
                      historico = linha.children[7].getElementsByTagName('Data')[0].value
                      debito = linha.children[8].getElementsByTagName('Data')[0].value
                      credito = linha.children[9].getElementsByTagName('Data')[0].value
                      saldo = linha.children[10].getElementsByTagName('Data')[0].value
                    } else {
                      var gruposaldo = saldo.substring(saldo.length, saldo.length - 1)
                      saldo = saldo.replace(/\./g, "")
                      saldo = saldo.replace(",", ".")
                      month = data.substring(5, 7)
                      year = data.substring(0, 4)
                      razaoObj.push({
                        id: unidadeSelecionada + data.substring(0, 10) + codigodre_id + lancamento + intinterrogacao,
                        unidade_id: unidadeSelecionada,
                        usuario_id: usuarioId,
                        codigodre_id: codigodre_idAtual,
                        data: data,
                        lancamento: lancamento,
                        centrodecusto_id: centrodecusto,
                        intinterrogacao: intinterrogacao,
                        atpj: atpj,
                        mod: mod,
                        historico: historico,
                        debito: parseFloat(debito),
                        credito: parseFloat(credito),
                        saldo: parseFloat(saldo),
                        gruposaldo: gruposaldo,
                        isfinal
                      })

                      codigodre_idAtual = codigodre_id
                      data = linha.children[0].getElementsByTagName('Data')[0].value
                      lancamento = linha.children[1].getElementsByTagName('Data')[0].value
                      intinterrogacao = linha.children[2].getElementsByTagName('Data')[0].value + linha.children[3].getElementsByTagName('Data')[0].value
                      centrodecusto = linha.children[4].getElementsByTagName('Data')[0].value
                      atpj = linha.children[5].getElementsByTagName('Data')[0].value
                      mod = linha.children[6].getElementsByTagName('Data')[0].value
                      historico = linha.children[7].getElementsByTagName('Data')[0].value
                      debito = linha.children[8].getElementsByTagName('Data')[0].value
                      credito = linha.children[9].getElementsByTagName('Data')[0].value
                      saldo = linha.children[10].getElementsByTagName('Data')[0].value
                    }
                  } else {
                    // aqui sai o saldo anterior de cada conta
                  }
                }
              } else if (linha.children.length === 1) {
                // aqui sai o total se o length for 3
                var conteudo = linha.children[0].getElementsByTagName('Data')[0].value
                if (!start) {
                  linhasExcluidas.push(conteudo)
                } else if (!conteudo.includes(linhasExcluidas[0]) && !conteudo.includes(linhasExcluidas[1]) && !conteudo.includes(linhasExcluidas[2])) {
                  historico = historico + " " + conteudo
                }
              }
            }
          }
        })
        var gruposaldo = saldo.substring(saldo.length, saldo.length - 1)
        razaoObj.push({
          id: unidadeSelecionada + data.substring(0, 10) + codigodre_id + lancamento + intinterrogacao,
          unidade_id: unidadeSelecionada,
          usuario_id: usuarioId,
          codigodre_id: codigodre_idAtual,
          data: data,
          lancamento: lancamento,
          centrodecusto_id: centrodecusto,
          intinterrogacao: intinterrogacao,
          atpj: atpj,
          mod: mod,
          historico: historico,
          debito: parseFloat(debito),
          credito: parseFloat(credito),
          saldo: parseFloat(saldo),
          gruposaldo: gruposaldo,
          isfinal
        })
        var res = await api.post('/registrosrazao', {
          razaoEx: razaoObj,
          month,
          year,
          unidade_id: unidadeSelecionada,
          empresa
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        notify("Razão - " + res.data.mensagem)

        document.getElementsByName('razaoexpresso')[0].value = null
        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
      });
    }
  }

  async function handlerInputFilePlanoDeContasHotel(file) {
    var planoDeContas = []
    if (!!!JSON.parse(sessionStorage.getItem('unidades')).find(d => d.unidade_id === unidadeSelecionada)?.organizacao_id) return notify("Escolha uma unidade e tente novamente!")
    var orgId = JSON.parse(sessionStorage.getItem('unidades')).find(d => d.unidade_id === unidadeSelecionada)?.organizacao_id
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        res.splice(0, 1)
        res.map(linha => {
          if (!isNaN(parseFloat(linha[0]))) {
            var codigo_id = (linha[0] + '').replaceAll('.', '')
            planoDeContas.push({
              id: orgId + '-' + codigo_id,
              codigo: codigo_id,
              descricao: linha[6],
              organizacao_id: orgId,
              sinal: linha[21] === 'D' ? 'DÉBITO' : 'CRÉDITO',
              codigodre_id: codigo_id
            })
          }
        })

        await api.post('/planoDeContas', {
          plano: planoDeContas
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        document.getElementsByName('planoDeContasHotel')[0].value = null
        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
      })
  }

  async function handlerInputFileDeParaGourmetHotel(file) {
    var depara = []
    var orgId = 2
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        // res.splice(0, 1)
        // res.map(linha => {
        //   if (!isNaN(parseFloat(linha[0]))) {
        //     var codigo_id = (linha[1] + '').replace(/-/g, '')
        //     if (linha[1].split("  -  ")[0]) {
        //       depara.push({
        //         orgId,
        //         hotel: linha[1].split("  -  ")[0],
        //         gourmet: linha[0] + '',
        //       })
        //     }
        //   }
        // })

        res.map(linha => {
          if (linha[1] !== null) {
            depara.push({
              orgId,
              hotel: linha[0],
              gourmet: linha[1] + '',
            })
          }
        })

        await api.put('/atualizaPlano', {
          depara: depara
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
          document.getElementsByName('deParaGourmetHotel')[0].value = null
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        })

      })

  }

  async function handlerInputFilePlanoDeContasGourmet(file) {
    var planoDeContas = []
    var orgId = 2
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        res.splice(0, 1)
        res.map(linha => {
          if (!isNaN(parseFloat(linha[0]))) {
            var codigo_id = (linha[1] + '').replace(/-/g, '')
            planoDeContas.push({
              id: orgId + '-' + codigo_id,
              codigo: codigo_id,
              descricao: linha[2],
              organizacao_id: orgId,
              sinal: linha[5],
            })
          }
        })

        await api.post('/planoDeContas', {
          plano: planoDeContas
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
          document.getElementsByName('planoDeContasGourmet')[0].value = null
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        })

      })
  }

  async function handlerRazaoGourmet(file) {
    var razaoObj = []
    var month = 0
    var year = 0

    if (!file.target.value.includes('xlsx')) {
      notify("Arquivo inválido!")
      document.getElementsByName('dre')[0].value = null
      return
    }
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    await readXlsxFile(file.target.files[0])
      .then(async (rows) => {
        var count = 0
        rows.map((linha, idx) => {
          if (rows.length === idx + 1) console.error(linha)
          try {
            if (linha[8] !== 'SALDO ANTERIOR' && !isNaN(parseFloat(linha[0]))) {
              month = (linha[2].getMonth() + 1)
              year = linha[2].getFullYear()
              razaoObj.push({
                id: unidadeSelecionada + linha[2].getFullYear() + '-' + (linha[2].getMonth() + 1) + '-' + linha[2].getDate() + linha[0] + linha[10] + linha[9] + (count++),
                unidade_id: unidadeSelecionada,
                usuario_id: usuarioId,
                codigodre_id: linha[1],
                data: linha[2].getFullYear() + '-' + (linha[2].getMonth() + 1) + '-' + linha[2].getDate(),
                lancamento: linha[10],
                centrodecusto_id: linha[12],
                intinterrogacao: null,
                atpj: null,
                mod: null,
                historico: linha[8] + ' - ' + linha[9],
                debito: linha[5],
                credito: linha[6],
                saldo: linha[7],
                gruposaldo: linha[5] !== 0 && linha[6] === 0 ? 'D' : linha[5] === 0 && linha[6] !== 0 ? 'C' : 'ERROR!!'
              })
            }
          } catch (error) {
            console.log(error)
          }
        })
      })

    var res = await api.post('/registrosrazao', {
      razaoEx: razaoObj,
      month,
      year,
      unidade_id: unidadeSelecionada
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    notify("Razão - " + res.data.mensagem)

    document.getElementsByName('razaoGourmet')[0].value = null
    dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
  }

  async function handlerInputFileDREGourmet(file) {
    var dreData = []
    if (!file.target.value.includes('xlsx')) {
      notify("Arquivo inválido!")
      document.getElementsByName('dre')[0].value = null
      return
    }
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        res.map(linha => {
          if (!isNaN(parseFloat(linha[1]))) {
            if (linha[5] !== 0 && linha[5] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-01-01" + linha[1] + linha[5],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-01-01',
                valor: linha[4] === 'DÉBITO' ? (linha[5] * (-1)) : linha[5]
              })
            }
            if (linha[6] !== 0 && linha[6] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-02-01" + linha[1] + linha[6],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-02-01',
                valor: linha[4] === 'DÉBITO' ? (linha[6] * (-1)) : linha[6]
              })
            }
            if (linha[7] !== 0 && linha[7] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-03-01" + linha[1] + linha[7],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-03-01',
                valor: linha[4] === 'DÉBITO' ? (linha[7] * (-1)) : linha[7]
              })
            }
            if (linha[8] !== 0 && linha[8] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-04-01" + linha[1] + linha[8],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-04-01',
                valor: linha[4] === 'DÉBITO' ? (linha[8] * (-1)) : linha[8]
              })
            }
            if (linha[9] !== 0 && linha[9] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-05-01" + linha[1] + linha[9],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-05-01',
                valor: linha[4] === 'DÉBITO' ? (linha[9] * (-1)) : linha[9]
              })
            }
            if (linha[10] !== 0 && linha[10] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-06-01" + linha[1] + linha[10],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-06-01',
                valor: linha[4] === 'DÉBITO' ? (linha[10] * (-1)) : linha[10]
              })
            }
            if (linha[11] !== 0 && linha[11] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-07-01" + linha[1] + linha[11],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-07-01',
                valor: linha[4] === 'DÉBITO' ? (linha[11] * (-1)) : linha[11]
              })
            }
            if (linha[12] !== 0 && linha[12] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-08-01" + linha[1] + linha[12],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-08-01',
                valor: linha[4] === 'DÉBITO' ? (linha[12] * (-1)) : linha[12]
              })
            }
            if (linha[13] !== 0 && linha[13] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-09-01" + linha[1] + linha[13],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-09-01',
                valor: linha[4] === 'DÉBITO' ? (linha[13] * (-1)) : linha[13]
              })
            }
            if (linha[14] !== 0 && linha[14] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-10-01" + linha[1] + linha[14],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-10-01',
                valor: linha[4] === 'DÉBITO' ? (linha[14] * (-1)) : linha[14]
              })
            }
            if (linha[15] !== 0 && linha[15] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-11-01" + linha[1] + linha[15],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-11-01',
                valor: linha[4] === 'DÉBITO' ? (linha[15] * (-1)) : linha[15]
              })
            }
            if (linha[16] !== 0 && linha[16] !== null) {
              dreData.push({
                id: unidadeSelecionada + anoSelecionado + "-12-01" + linha[1] + linha[16],
                usuario_id: cookies.usuario.id,
                codigodre_id: linha[1],
                unidade_id: unidadeSelecionada,
                data: anoSelecionado + '-12-01',
                valor: linha[4] === 'DÉBITO' ? (linha[16] * (-1)) : linha[16]
              })
            }
            document.getElementsByName('dreGourmet')[0].value = null
          }
        })

        await api.post('/dreGourmet', {
          dre: dreData
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res2 => {
          notify("DRE - " + res2.data.mensagem)
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        })
      })
  }

  async function handlerInputFileDRE(file, isFinal) {
    var dreData = []
    var empresa = ''
    var mesAtual = 1
    if (unidadeSelecionada === '' || unidadeSelecionada === '-1') {
      return notify("Escolha a unidade!")
    }
    if (!file.target.value.includes('xlsx')) {
      notify("Arquivo inválido!")
      document.getElementsByName('dre')[0].value = null
      return
    }

    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        res.map((linha, idx) => {
          var objAtual = {}
          if (idx === 1) empresa = linha[3]
          if (linha[0]) {
            linha = linha.filter(function (e) { return e !== null });
            if (linha[1] === 'Página') return
            linha.pop()
            if (parseInt(linha[0].split("  -  ")[0])) {
              objAtual.codigoDre_id = linha[0].split("  -  ")[0]
            } else {
              if (linha[0].includes('FEES') && linha[0].includes('FRANQUIA')) {
                objAtual.codigoDre_id = 'FEES DE FRANQUIA'
              } else if (linha[0].includes('FEES') && linha[0].includes('GESTAO')) {
                objAtual.codigoDre_id = 'FEES DE GESTAO'
              } else {
                objAtual.codigoDre_id = linha[0]
              }
            }
            objAtual.usuario_id = usuarioId
            objAtual.unidade_id = unidadeSelecionada
            objAtual.ano = anoSelecionado
            if (linha.length >= 2) {
              objAtual.janeiro = linha[1]
            }
            if (linha.length >= 3) {
              objAtual.fevereiro = linha[2]
            }
            if (linha.length >= 4) {
              objAtual.marco = linha[3]
            }
            if (linha.length >= 5) {
              objAtual.abril = linha[4]
            }
            if (linha.length >= 6) {
              objAtual.maio = linha[5]
            }
            if (linha.length >= 7) {
              objAtual.junho = linha[6]
            }
            if (linha.length >= 8) {
              objAtual.julho = linha[7]
            }
            if (linha.length >= 9) {
              objAtual.agosto = linha[8]
            }
            if (linha.length >= 10) {
              objAtual.setembro = linha[9]
            }
            if (linha.length >= 11) {
              objAtual.outubro = linha[10]
            }
            if (linha.length >= 12) {
              objAtual.novembro = linha[11]
            }
            if (linha.length >= 13) {
              objAtual.dezembro = linha[12]
            }
            dreData.push(objAtual)
            mesAtual = linha.length - 1
          }
        })
        try {
          await api.post('/dre', {
            dre: dreData,
            isFinal: isFinal,
            mesAtual,
            empresa,
            unidade_id: unidadeSelecionada,
          }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res2 => {
            document.getElementsByName('dre')[0].value = null
            notify("DRE - " + res2.data.mensagem)
            dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
          })
        } catch (error) {
          console.log("Erro ao carregar DRE: " + error)
          document.getElementsByName('dre')[0].value = null
          notify("Erro ao carregar DRE, contacte o suporte!")
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        }
      })
  }

  function modalSN() {
    return (<Modal show={modalSNData.isVisible}>
      <Modal.Header>
        <Modal.Title>{modalSNData.title}</Modal.Title>
      </Modal.Header>

      {modalSNData.fn === 1
        ? <Modal.Footer>
          <Button size='sm' variant='outline-primary' onClick={() => { setModalSNData({ ...modalSNData, isVisible: false }); handlerInputFileDRE(modalSNData.e, false) }}>Não</Button>
          <Button size='sm' onClick={() => { setModalSNData({ ...modalSNData, isVisible: false }); handlerInputFileDRE(modalSNData.e, true) }}>Sim</Button>
        </Modal.Footer>
        : <Modal.Footer>
          <Button size='sm' variant='outline-primary' onClick={() => { setModalSNData({ ...modalSNData, isVisible: false }); handlerInputFileRazao(modalSNData.e, false) }}>Não</Button>
          <Button size='sm' onClick={() => { setModalSNData({ ...modalSNData, isVisible: false }); handlerInputFileRazao(modalSNData.e, true) }}>Sim</Button>
        </Modal.Footer>
      }
    </Modal>)
  }

  async function handlerInputFileBudgetTopline(file) {
    var myData = false
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })

    await readXlsxFile(file.target.files[0], { getSheets: true }).then((sheets) => {
      sheets.map(async mySheet => {
        await readXlsxFile(file.target.files[0], { sheet: mySheet.name })
          .then(async res => {
            var topLineData = []
            res.map(linha => {
              if (linha[0]) {
                if (linha[0].includes("Diff %") || linha[0].includes("Variação")) {
                  myData = false
                }
                if (linha[0].includes('Orçamento') || myData) {
                  myData = true
                  for (var i = 1; i <= 12; i++) {
                    if (linha[i]) {
                      topLineData.push({
                        id: linha[0] + '-' + mySheet.name + '-' + anoSelecionado2 + '-' + i,
                        unidade_id: mySheet.name,
                        codigo_id: linha[0],
                        ano: anoSelecionado2,
                        lastupdatedat: new Date().toISOString(),
                        valor: linha[i],
                        usuario_id: cookies.usuario.id,
                        mes: i
                      })
                    }
                  }
                }
              }
            })
            await api.post('/toplinebudget', { topLineData }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
              .then(res => notify(mySheet.name + ' - ' + res.data))
          })
      })
      document.getElementsByName('budgetTopline')[0].value = null
      dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
    })
  }

  // async function handleNColab(e) {
  //   e.preventDefault()

  //   var total = parseInt(e.target.formColabAlmoxarifado.value) +
  //     parseInt(e.target.formColabCompras.value) +
  //     parseInt(e.target.formColabControladoria.value) +
  //     parseInt(e.target.formColabCozinha.value) +
  //     parseInt(e.target.formColabEventos.value) +
  //     parseInt(e.target.formColabLimpeza.value) +
  //     parseInt(e.target.formColabMarketing.value) +
  //     parseInt(e.target.formColabTI.value) +
  //     parseInt(e.target.formColabVendas.value) +
  //     parseInt(e.target.formColabRecepcao.value) +
  //     parseInt(e.target.formColabGovernanca.value) +
  //     parseInt(e.target.formColabManutencao.value) +
  //     parseInt(e.target.formColabGerencia.value) +
  //     parseInt(e.target.formColabRestaurante.value) +
  //     parseInt(e.target.formColabPortaria.value) +
  //     parseInt(e.target.formColabAfastados.value)

  //   if (e.target.formColabUnidade.value === '-1') {
  //     return notify("Escolha uma unidade")
  //   }
  //   if (total !== parseInt(e.target.formColabTotal.value)) {
  //     return notify("Numero de colaboradores inconsistente, por favor conferir valores!")
  //   }

  //   var timestamp = new Date()
  //   timestamp = timestamp.toLocaleString('pt-br')
  //   timestamp = timestamp.substring(6, 10) + '-' + timestamp.substring(3, 5) + '-' + timestamp.substring(0, 2)

  //   await api.post('/funcionarios', {
  //     unidade_id: e.target.formColabUnidade.value + '',
  //     timestamp,
  //     usuario_id: cookies.usuario.id,
  //     almoxarifado: parseInt(e.target.formColabAlmoxarifado.value),
  //     compras: parseInt(e.target.formColabCompras.value),
  //     controladoria: parseInt(e.target.formColabControladoria.value),
  //     cozinha: parseInt(e.target.formColabCozinha.value),
  //     eventos: parseInt(e.target.formColabEventos.value),
  //     limpeza: parseInt(e.target.formColabLimpeza.value),
  //     marketing: parseInt(e.target.formColabMarketing.value),
  //     tecnologiadainformacao: parseInt(e.target.formColabTI.value),
  //     vendas: parseInt(e.target.formColabVendas.value),
  //     recepcao: parseInt(e.target.formColabRecepcao.value),
  //     governanca: parseInt(e.target.formColabGovernanca.value),
  //     manutencao: parseInt(e.target.formColabManutencao.value),
  //     gerencia: parseInt(e.target.formColabGerencia.value),
  //     restaurante: parseInt(e.target.formColabRestaurante.value),
  //     portaria: parseInt(e.target.formColabPortaria.value),
  //     afastados: parseInt(e.target.formColabAfastados.value),
  //     total: parseInt(e.target.formColabTotal.value),
  //     mes: e.target.formColabMonth.value.substring(5, 7),
  //     ano: e.target.formColabMonth.value.substring(0, 4),
  //   }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => { res.data === 'ok' ? notify("Salvo com sucesso!") : notify("Erro ao salvar, tente novamente mais tarde!") })

  //   e.target.reset()
  // }

  async function handlerInputFilePlanoDeContasDominio(file) {
    if (unidadeSelecionada === '' || unidadeSelecionada === '-1') {
      document.getElementsByName('planoDeContasDominio')[0].value = null
      return notify("Escolha a unidade!")
    }
    if (!file.target.value.includes('xlsx')) {
      notify("Arquivo inválido!")
      document.getElementsByName('planoDeContasDominio')[0].value = null
      return
    }
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    var planoDeContas = []
    var orgId = JSON.parse(sessionStorage.getItem('unidades'))?.find(d => d.id === unidadeSelecionada)?.organizacao_id
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        res.splice(0, 1)
        res.map(linha => {
          if (!isNaN(parseFloat(linha[0]))) {
            linha = linha.filter(function (e) { return e !== null });
            // console.log(linha)
            var codigo_id = (linha[1] + '').replace(/-/g, '')
            planoDeContas.push({
              id: orgId + '-' + codigo_id,
              codigo: codigo_id,
              descricao: linha[2],
              organizacao_id: orgId,
              codigodre_id: codigo_id,
              sinal: linha[5] > 0 ? 'DÉBITO' : 'CRÉDITO',
            })
          }
        })

        await api.post('/planoDeContas', {
          plano: planoDeContas
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
          document.getElementsByName('planoDeContasDominio')[0].value = null
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
          if (res.status !== 200) return notify("Erro ao cadastrar plano de contas!")
          return notify('Plano de contas cadastrado com sucesso!')
        })

      })
  }

  async function handlerInputFileDREDominio(file) {
    var dreData = []
    var empresa = ''
    if (unidadeSelecionada === '' || unidadeSelecionada === '-1') {
      document.getElementsByName('dreDominio')[0].value = null
      return notify("Escolha a unidade!")
    }
    if (!file.target.value.includes('xlsx')) {
      notify("Arquivo inválido!")
      document.getElementsByName('dreDominio')[0].value = null
      return
    }
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        res.map((linha, idx) => {
          if (idx === 0) empresa = linha[5]
          var objAtual = {}
          if (!isNaN(parseFloat(linha[0]))) {
            linha = linha.filter(function (e) { return e !== null });
            objAtual.codigoDre_id = linha[1]
            objAtual.usuario_id = usuarioId
            objAtual.unidade_id = unidadeSelecionada
            objAtual.ano = anoSelecionado
            linha.pop()

            if (adminAllow) {
              if (linha.length >= 4) {
                objAtual.janeiro = linha[3]
              }
              if (linha.length >= 5) {
                objAtual.fevereiro = linha[4]
              }
              if (linha.length >= 6) {
                objAtual.marco = linha[5]
              }
              if (linha.length >= 7) {
                objAtual.abril = linha[6]
              }
              if (linha.length >= 8) {
                objAtual.maio = linha[7]
              }
              if (linha.length >= 9) {
                objAtual.junho = linha[8]
              }
              if (linha.length >= 10) {
                objAtual.julho = linha[9]
              }
              if (linha.length >= 11) {
                objAtual.agosto = linha[10]
              }
              if (linha.length >= 12) {
                objAtual.setembro = linha[11]
              }
              if (linha.length >= 13) {
                objAtual.outubro = linha[12]
              }
              if (linha.length >= 14) {
                objAtual.novembro = linha[13]
              }
              if (linha.length >= 15) {
                objAtual.dezembro = linha[14]
              }
              dreData.push(objAtual)
            } else {
              if (linha.length === 4) {
                objAtual.janeiro = linha[3]
              }
              if (linha.length === 5) {
                objAtual.fevereiro = linha[4]
              }
              if (linha.length === 6) {
                objAtual.marco = linha[5]
              }
              if (linha.length === 7) {
                objAtual.abril = linha[6]
              }
              if (linha.length === 8) {
                objAtual.maio = linha[7]
              }
              if (linha.length === 9) {
                objAtual.junho = linha[8]
              }
              if (linha.length === 10) {
                objAtual.julho = linha[9]
              }
              if (linha.length === 11) {
                objAtual.agosto = linha[10]
              }
              if (linha.length === 12) {
                objAtual.setembro = linha[11]
              }
              if (linha.length === 13) {
                objAtual.outubro = linha[12]
              }
              if (linha.length === 14) {
                objAtual.novembro = linha[13]
              }
              if (linha.length === 15) {
                objAtual.dezembro = linha[14]
              }
              dreData.push(objAtual)
            }

          }
        })

        try {
          await api.post('/dre', {
            dre: dreData,
            empresa,
            unidade_id: unidadeSelecionada,
          }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res2 => {
            document.getElementsByName('dreDominio')[0].value = null
            notify("DRE - " + res2.data.mensagem)
            dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
          })
        } catch (error) {
          console.log("Erro ao carregar DRE: " + error)
          document.getElementsByName('dreDominio')[0].value = null
          notify("Erro ao carregar DRE, contacte o suporte!")
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        }
      })
  }

  async function handlerInputFileRazaoDominio(file) {
    if (unidadeSelecionada === '' || unidadeSelecionada === '-1') {
      document.getElementsByName('razaodominio')[0].value = null
      return notify("Escolha a unidade!")
    }
    if (!file.target.value.includes('xlsx')) {
      notify("Arquivo inválido!")
      document.getElementsByName('razaodominio')[0].value = null
      return
    }
    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
    var empresa = ''
    var razaoObj = []
    var month = new Date().getMonth()
    var year = new Date().getFullYear()
    await readXlsxFile(file.target.files[0])
      .then(async res => {
        // console.log(res)
        var contaAtual = ''
        res.map((linha, idx) => {
          if (idx === 0) empresa = linha[7]
          if (idx === 4 && linha[0] !== 'RAZÃO') return notify("Arquvio enviado incorreto, por favor insira um razão do sistema Dominio!")
          if (linha[0] === 'Conta:') contaAtual = linha[9]
          if (typeof linha[0] !== 'object' || linha[0] === null) return

          razaoObj.push({
            id: unidadeSelecionada + linha[0].getFullYear() + '-' + (linha[0].getMonth() + 1) + '-' + linha[0].getDate() + empresa + contaAtual + linha[5] + idx,
            unidade_id: unidadeSelecionada,
            usuario_id: usuarioId,
            codigodre_id: contaAtual,
            data: linha[0].getFullYear() + '-' + (linha[0].getMonth() + 1) + '-' + linha[0].getDate(),
            lancamento: null,
            centrodecusto_id: null,
            intinterrogacao: null,
            atpj: null,
            mod: null,
            historico: linha[5],
            debito: linha[18] === null ? 0 : linha[18],
            credito: linha[20] === null ? 0 : linha[20],
            saldo: linha[28],
            gruposaldo: !isNaN(linha[18]) && linha[20] === null ? 'D' : linha[18] === null && !isNaN(linha[20]) !== 0 ? 'C' : 'ERROR!!'
          })
        })
      })

    var res = await api.post('/registrosrazao', {
      razaoEx: razaoObj,
      month,
      year,
      unidade_id: unidadeSelecionada,
      empresa
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    notify("Razão - " + res.data.mensagem)

    document.getElementsByName('razaodominio')[0].value = null
    dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })

  }

  async function handlerSalvarConfiguracoes() {
    if (!configuracoes.find(c => c.editado)) return notify("Nenhuma configuração foi alterada!")
    configuracoes.map(async c => {
      if (c.editado) {
        c.editado = false
        try {
          await api.post('/configuracoes', {
            id: c.id,
            value: c.value,
          }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            .then(res => {
              // console.log(res)
              if (res.status !== 200) return notify("Erro ao salvar, tente novamente mais tarde!")
              return notify("Salvo com sucesso!")
            })
          // console.log(configuracoes)
        } catch (error) {
          console.log(error)
          notify("Erro ao salvar, tente novamente mais tarde!")
        }

      }
    })
  }

  async function loadData() {

    var organizacao_id = [...new Set(JSON.parse(sessionStorage.getItem('unidades')).reduce((acc, cv) => acc = [...acc, cv.organizacao_id], []))]

    const response = await api.get('/configuracoes/' + JSON.stringify(organizacao_id), { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    if (response.status !== 200) return notify("Erro ao carregar configurações!")

    setConfiguracoes(response.data)
  }

  async function handlerInputFileRazaoFortes(file) {
    if (!file.target.value.includes('xml')) {
      notify("Arquivo inválido!")
      document.getElementsByName('razaoexpresso')[0].value = null
      return
    }
    // dispatch({ type: 'OPEN_LOADING', text: "Enviado dados...", isLoading: true })
    for (let i = 0; i < file.target.files.length; i++) {
      var preview5 = URL.createObjectURL(file.target.files[i])
      axios.get(preview5, {
        "Content-Type": "application/xml; charset=utf-8"
      }).then(async response => {
        var razaoObj = []
        var xml = new XMLParser().parseFromString(response.data)  // Assume xmlText contains the example XML
        var sheet = xml.getElementsByTagName("Table")[0]
        var codigodre_id = null
        var codigodre_idAtual = null
        var data = null
        var lancamento = null
        var centrodecusto = null
        var intinterrogacao = null
        var atpj = null
        var mod = null
        var historico = null
        var debito = null
        var credito = null
        var saldo = null
        var start = false
        var month = null
        var year = null
        var linhasExcluidas = []
        var empresa = sheet.getElementsByTagName('Data')[0].value
        sheet.getElementsByTagName('Row').map(async linha => {
          if (linha.children.length) {
            if (linha.children[0].getElementsByTagName('Data')[0].value.includes('Conta: ')) {
              if (linha.children[0].getElementsByTagName('Data')[0]) {
                start = true
                codigodre_id = linha.children[0].getElementsByTagName('Data')[0].value.split(' ')[1]
              }
            } else {
              if (linha.children[0].getElementsByTagName('Data')[0].value.substring(0, 5) === (anoSelecionado + '-')) {
                if (linha.children[1]) {
                  if (linha.children[1].getElementsByTagName('Data')[0].value !== '0' && linha.children.length === 11) {
                    if (lancamento === null) {
                      codigodre_idAtual = codigodre_id
                      data = linha.children[0].getElementsByTagName('Data')[0].value
                      lancamento = linha.children[1].getElementsByTagName('Data')[0].value
                      intinterrogacao = linha.children[2].getElementsByTagName('Data')[0].value
                      centrodecusto = linha.children[6].getElementsByTagName('Data')[0].value
                      atpj = linha.children[7].getElementsByTagName('Data')[0].value
                      mod = linha.children[5].getElementsByTagName('Data')[0].value
                      historico = linha.children[3].getElementsByTagName('Data')[0].value
                      debito = linha.children[8].getElementsByTagName('Data')[0].value
                      credito = linha.children[9].getElementsByTagName('Data')[0].value
                      saldo = linha.children[10].getElementsByTagName('Data')[0].value
                    } else {
                      var gruposaldo = saldo.substring(saldo.length, saldo.length - 1)
                      saldo = saldo.replace(/\./g, "")
                      saldo = saldo.replace(",", ".")
                      month = data.substring(5, 7)
                      year = data.substring(0, 4)
                      razaoObj.push({
                        id: unidadeSelecionada + data.substring(0, 10) + codigodre_id + lancamento + intinterrogacao,
                        unidade_id: unidadeSelecionada,
                        usuario_id: usuarioId,
                        codigodre_id: codigodre_idAtual,
                        data: data,
                        lancamento: lancamento,
                        centrodecusto_id: centrodecusto,
                        intinterrogacao: intinterrogacao,
                        atpj: atpj,
                        mod: mod,
                        historico: historico,
                        debito: parseFloat(debito),
                        credito: parseFloat(credito),
                        saldo: parseFloat(saldo),
                        gruposaldo: gruposaldo,
                      })

                      codigodre_idAtual = codigodre_id
                      data = linha.children[0].getElementsByTagName('Data')[0].value
                      lancamento = linha.children[1].getElementsByTagName('Data')[0].value
                      intinterrogacao = linha.children[2].getElementsByTagName('Data')[0].value
                      centrodecusto = linha.children[6].getElementsByTagName('Data')[0].value
                      atpj = linha.children[7].getElementsByTagName('Data')[0].value
                      mod = linha.children[5].getElementsByTagName('Data')[0].value
                      historico = linha.children[3].getElementsByTagName('Data')[0].value
                      debito = linha.children[8].getElementsByTagName('Data')[0].value
                      credito = linha.children[9].getElementsByTagName('Data')[0].value
                      saldo = linha.children[10].getElementsByTagName('Data')[0].value
                    }
                  } else {
                    // aqui sai o saldo anterior de cada conta
                  }
                }
              } else if (linha.children.length === 1) {
                // aqui sai o total se o length for 3
                var conteudo = linha.children[0].getElementsByTagName('Data')[0].value
                if (!start) {
                  console.log(conteudo)
                  linhasExcluidas.push(conteudo)
                } else if (!conteudo.includes(linhasExcluidas[0]) && !conteudo.includes(linhasExcluidas[1]) && !conteudo.includes(linhasExcluidas[2])) {
                  historico = historico + " " + conteudo
                }
              }
            }
          }
        })
        var gruposaldo = saldo.substring(saldo.length, saldo.length - 1)
        razaoObj.push({
          id: unidadeSelecionada + data.substring(0, 10) + codigodre_id + lancamento + intinterrogacao,
          unidade_id: unidadeSelecionada,
          usuario_id: usuarioId,
          codigodre_id: codigodre_idAtual,
          data: data,
          lancamento: lancamento,
          centrodecusto_id: centrodecusto,
          intinterrogacao: intinterrogacao,
          atpj: atpj,
          mod: mod,
          historico: historico,
          debito: parseFloat(debito),
          credito: parseFloat(credito),
          saldo: parseFloat(saldo),
          gruposaldo: gruposaldo,
        })

        await api.post('/registrosrazao', {
          razaoEx: razaoObj,
          month,
          year,
          unidade_id: unidadeSelecionada,
          empresa
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

      })
    }
  }

  async function importarArquivosTotvs() {
    if (window.confirm('Todos os fechamentos serão importados como prévia, deseja continuar?')) {
      var user = window.confirm('Deseja receber um email quando a importação for concluída?') ? cookies.usuario.email : ''
      await api.get('/sftp/bup/getfiles/razao?email=' + user, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  async function handleVersaoFinal() {
    const email = window.confirm('Deseja enviar email para os responsáveis dessas unidades?');
    setIsLoading(true);

    try {
      for (const unidade of unidadeSelecionadaMulti) {
        const unidade_id = unidade.split(' {')[1].replace('}', '');
        const res = await api.post('/dre/update/versao', {
          unidade_id,
          email
        }, {
          headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` }
        });

        window.alert(res.data.mensagem + ' ' + unidade.split(' {')[0]);
      }
    } catch (error) {
      console.error('Erro ao atualizar versão:', error);

    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="registros-container">
      <div style={{ background: 'white' }} className="registros-body">
        <div className="registros-nomeHotel">Adicione os documentos:</div>
        <div className="registros-file-container">
          <Tabs defaultActiveKey="Contabilidade" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="Contabilidade" title="Fechamento">
              <Tab.Container defaultActiveKey="first" id="uncontrolled-tab-example" className="mb-3">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">TOTVS</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Dominio</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link disabled={JSON.parse(sessionStorage.getItem('permissoes')).find(permissao => permissao.id === 13) ? false : true} eventKey="third">Everest</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link disabled={cookies.usuario.permissao === 0 ? false : true} eventKey="fourth">Fortes</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Accordion defaultActiveKey="0">
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <h3>Plugin bUP</h3>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div>
                                  <Button onClick={() => importarArquivosTotvs()} variant='primary'>Importar arquivos tovs</Button>
                                  <Badge style={{
                                    position: 'relative',
                                    top: '-20px',
                                    right: '20px'
                                  }} variant="warning">Teste</Badge>
                                </div>
                                <div style={{ margin: '20px 0' }}>
                                  <h4>Atualização para versão final:</h4>
                                  <p>Escolha as unidades</p>
                                  <TransferList onChange={setUnidadeSelecionadaMulti}
                                    options={JSON.parse(sessionStorage.getItem('unidades')).filter(d => d.type === 'Hotel')?.reduce((acc, cv) => acc = [...acc, cv.unidade + ' {' + cv.id + '}'], [])}
                                    selected={() => { return unidadeSelecionadaMulti }} />
                                </div>
                                <>
                                  {isLoading ? (
                                    <Button variant='primary' disabled>
                                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                      <span className='sr-only'>Carregando...</span>
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => unidadeSelecionadaMulti.length ? handleVersaoFinal() : window.alert('Selecione as unidades que deseja definir como versão final!')}
                                      variant='primary'
                                    >
                                      Atualizar versão
                                    </Button>
                                  )}
                                </>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <h3>Manual</h3>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <div className='registros-file-dropzone'>
                                  <select onChange={(e) => setUnidadeSelecionada(e.target.value)}>
                                    <option value='-1'>Escolha a unidade</option>
                                    {JSON.parse(sessionStorage.getItem('unidades')).filter(d => d.type === 'Hotel').map(unidade => (<option key={unidade.id} value={unidade.id}>{unidade.unidade}</option>))}
                                  </select>
                                  <div className="registros-input-label">
                                    <input type='number' placeholder='Ano do dre' onChange={(e) => setAnoSelecionado(parseInt(e.target.value))} defaultValue={new Date().getFullYear()} />
                                  </div>
                                </div>
                                <div className='registros-file-dropzone'>
                                  <div>
                                    <label className="registros-label">DRE: </label>
                                  </div>
                                  <div className="registros-input-label">
                                    <input className="registros-file-input" type="file" name="dre" onChange={e => setModalSNData({ ...modalSNData, e, isVisible: true, title: "É a versão final do DRE?", fn: 1 })} accept='.xlsx' />
                                    <label className="registros-sublabel">(Arquivo em xlsx) </label>
                                  </div>
                                </div>
                                <div className='registros-file-dropzone'>
                                  <label className="registros-label">Razão expresso: </label>
                                  <div className="registros-input-label">
                                    <input className="registros-file-input" type="file" multiple name="razaoexpresso" onChange={e => setModalSNData({ ...modalSNData, e, isVisible: true, title: "É a versão final do Razão?", fn: 2 })} accept='.xml' />
                                    <label className="registros-sublabel">(Arquivo em xml) </label></div>
                                </div>
                                {cookies.usuario.permissao === 0
                                  ? <div className='registros-file-dropzone'>
                                    <div>
                                      <label className="registros-label">Plano de Contas: </label>
                                    </div>
                                    <div className="registros-input-label">
                                      <input className="registros-file-input" type="file" name="planoDeContasHotel" onChange={e => handlerInputFilePlanoDeContasHotel(e)} accept='.xlsx' />
                                      <label className="registros-sublabel">(Arquivo em xlsx) </label>
                                    </div>
                                  </div>
                                  : null}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <h1>Dominio</h1>
                        <div className='registros-file-dropzone' style={{ gridTemplateColumns: '1fr 1fr 1fr', columnGap: '15px' }}>
                          <select onChange={(e) => setUnidadeSelecionada(e.target.value)}>
                            <option value='-1'>Escolha a unidade</option>
                            {JSON.parse(sessionStorage.getItem('unidades')).filter(d => d.type === 'Escritório').map(unidade => (<option key={unidade.id} value={unidade.id}>{unidade.unidade}</option>))}
                          </select>
                          <InputGroup size='sm' hidden={cookies.usuario.permissao !== 0} onClick={() => setAdminAllow(!adminAllow)}>
                            <InputGroup.Prepend>
                              <InputGroup.Checkbox checked={adminAllow} />
                            </InputGroup.Prepend>
                            <FormControl disabled value='Subir todo o dre?' />
                          </InputGroup>
                          <input type='number' placeholder='Ano do dre' onChange={(e) => setAnoSelecionado(parseInt(e.target.value))} defaultValue={new Date().getFullYear()} />
                        </div>
                        <div className='registros-file-dropzone'>
                          <div>
                            <label className="registros-label">DRE: </label>
                          </div>
                          <div className="registros-input-label">
                            <input className="registros-file-input" type="file" name="dreDominio" onChange={e => handlerInputFileDREDominio(e)} accept='.xlsx' />
                            <label className="registros-sublabel">(Arquivo em xlsx) </label>
                          </div>
                        </div>
                        <div className='registros-file-dropzone'>
                          <label className="registros-label">Razão: </label>
                          <div className="registros-input-label">
                            <input className="registros-file-input" type="file" multiple name="razaodominio" onChange={e => handlerInputFileRazaoDominio(e)} accept='.xlsx' />
                            <label className="registros-sublabel">(Arquivo em xlsx) </label></div>
                        </div>
                        {cookies.usuario.permissao === 0
                          ? <div className='registros-file-dropzone'>
                            <div>
                              <label className="registros-label">Plano de Contas: </label>
                            </div>
                            <div className="registros-input-label">
                              <input className="registros-file-input" type="file" name="planoDeContasDominio" onChange={e => handlerInputFilePlanoDeContasDominio(e)} accept='.xlsx' />
                              <label className="registros-sublabel">(Arquivo em xlsx) </label>
                            </div>
                          </div>
                          : null}
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <h1>Everest</h1>
                        <div className='registros-file-dropzone'>
                          <select onChange={(e) => setUnidadeSelecionada(e.target.value)}>
                            <option value='-1'>Escolha a unidade</option>
                            {JSON.parse(sessionStorage.getItem('unidades')).filter(d => d.type === 'Restaurante').map(unidade => (<option key={unidade.id} value={unidade.id}>{unidade.unidade}</option>))}
                          </select>
                          <div className="registros-input-label">
                            <input type='number' placeholder='Ano do dre' onChange={(e) => setAnoSelecionado(parseInt(e.target.value))} defaultValue={new Date().getFullYear()} />
                          </div>
                        </div>
                        <div className='registros-file-dropzone'>
                          <div>
                            <label className="registros-label">DRE Gourmet: </label>
                          </div>
                          <div className="registros-input-label">
                            <input className="registros-file-input" type="file" name="dreGourmet" onChange={e => handlerInputFileDREGourmet(e)} accept='.xlsx' />
                            <label className="registros-sublabel">(Arquivo em xlsx) </label>
                          </div>
                        </div>
                        <div className='registros-file-dropzone'>
                          <div>
                            <label className="registros-label">Razão Gourmet: </label>
                          </div>
                          <div className="registros-input-label">
                            <input className="registros-file-input" type="file" name="razaoGourmet" onChange={e => handlerRazaoGourmet(e)} accept='.xlsx' />
                            <label className="registros-sublabel">(Arquivo em xlsx) </label>
                          </div>
                        </div>
                        {cookies.usuario.permissao === 0
                          ? <div className='registros-file-dropzone'>
                            <div>
                              <label className="registros-label">Plano de Contas Gourmet: </label>
                            </div>
                            <div className="registros-input-label">
                              <input className="registros-file-input" type="file" name="planoDeContasGourmet" onChange={e => handlerInputFilePlanoDeContasGourmet(e)} accept='.xlsx' />
                              <label className="registros-sublabel">(Arquivo em xlsx) </label>
                            </div>
                          </div>
                          : null}
                        {cookies.usuario.permissao === 0
                          ? <div className='registros-file-dropzone'>
                            <div>
                              <label className="registros-label">De Para Gourmet-Hotel: </label>
                            </div>
                            <div className="registros-input-label">
                              <input className="registros-file-input" type="file" name="deParaGourmetHotel" onChange={e => handlerInputFileDeParaGourmetHotel(e)} accept='.xlsx' />
                              <label className="registros-sublabel">(Arquivo em xlsx) </label>
                            </div>
                          </div>
                          : null}
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <h1>Forts</h1>
                        <div className='registros-file-dropzone'>
                          <select onChange={(e) => setUnidadeSelecionada(e.target.value)}>
                            <option value='-1'>Escolha a unidade</option>
                            {JSON.parse(sessionStorage.getItem('unidades')).map(unidade => (<option key={unidade.id} value={unidade.id}>{unidade.unidade}</option>))}
                          </select>
                          <div className="registros-input-label">
                            <input type='number' placeholder='Ano do dre' onChange={(e) => setAnoSelecionado(parseInt(e.target.value))} defaultValue={new Date().getFullYear()} />
                          </div>
                        </div>
                        {/* <div className='registros-file-dropzone'>
                          <div>
                            <label className="registros-label">DRE: </label>
                          </div>
                          <div className="registros-input-label">
                            <input className="registros-file-input" type="file" name="dre" onChange={e => setModalSNData({ ...modalSNData, e, isVisible: true, title: "É a versão final do DRE?", fn: 1 })} accept='.xlsx' />
                            <label className="registros-sublabel">(Arquivo em xlsx) </label>
                          </div>
                        </div> */}
                        <div className='registros-file-dropzone'>
                          <label className="registros-label">Razão expresso: </label>
                          <div className="registros-input-label">
                            <input className="registros-file-input" type="file" multiple name="razaoexpresso" onChange={e => handlerInputFileRazaoFortes(e)} accept='.xml' />
                            <label className="registros-sublabel">(Arquivo em xml) </label></div>
                        </div>
                        {/* {cookies.usuario.permissao === 0
                          ? <div className='registros-file-dropzone'>
                            <div>
                              <label className="registros-label">Plano de Contas: </label>
                            </div>
                            <div className="registros-input-label">
                              <input className="registros-file-input" type="file" name="planoDeContasHotel" onChange={e => handlerInputFilePlanoDeContasHotel(e)} accept='.xlsx' />
                              <label className="registros-sublabel">(Arquivo em xlsx) </label>
                            </div>
                          </div>
                          : null} */}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Tab>
            <Tab eventKey="Configuracoes" title="Configurações" disabled={cookies.usuario.rights.includes('d') ? false : true}>
              <Row>
                {configuracoes.map((configuracao, idx) => <Col key={'config' + idx} md='6' lg='4'>
                  <Form.Group>
                    <Form.Label style={{ float: 'left' }}>{configuracao.label}:</Form.Label>
                    <Form.Control
                      id={configuracao.organizacao_id + '-' + configuracao.label}
                      defaultValue={configuracao.value}
                      onChange={(e) => setConfiguracoes([...configuracoes.map(c => c.label === configuracao.label ? { ...c, value: e.target.value, editado: true } : { ...c })])}
                    />
                  </Form.Group>
                </Col>)}
              </Row>
              <Row style={{ justifyContent: 'end' }}>
                <Button variant="primary" style={{ float: 'right' }} disabled={configuracoes.length === 0} onClick={() => handlerSalvarConfiguracoes()}>Salvar</Button>
              </Row>
            </Tab>
          </Tabs>
        </div>
        {modalSN()}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        style={{ zIndex: 1000000 }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover />
    </div >
  );
}

export default Registros;