import {
  Avatar,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Zoom,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { AiOutlineAccountBook, AiOutlineMenu } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  BsBuilding,
  BsChevronDown,
  BsChevronUp,
  BsGear,
  BsCalendar,
} from "react-icons/bs";
import {
  GrBriefcase,
  GrRestaurant,
  GrUpdate,
  GrDocumentUpdate,
} from "react-icons/gr";
import { HiOutlineLogout } from "react-icons/hi";
import {
  RiAdminLine,
  RiCalendarCheckLine,
  RiDashboardLine,
  RiHandHeartLine,
  RiNotification2Line,
  RiTableLine,
} from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { MdErrorOutline } from "react-icons/md";
import { GoFileSymlinkFile } from "react-icons/go";
import { useLocation } from "react-router-dom";
import "./styles.css";
import { TbReportMoney, TbUser } from "react-icons/tb";
import { TfiArchive } from "react-icons/tfi";
import { TextField, InputAdornment } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { MdAttachMoney } from "react-icons/md";
import { LuArchiveRestore } from "react-icons/lu";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { TbMailForward } from "react-icons/tb";
import DarkModeToggle from "../dark-reader/index.tsx";
import { UserProfileContext } from "../../contexts/userProfile.jsx";

// imagens
import logo from "../../assets/analyticsicon.png";
import api from "../../services/api.js";

export function MenuItens({
  setOpenFaturamento,
  setOpenNFS,
  setIsOpenDRE,
  setIsOpenAdiocionarTopline,
  setIsOpenLog,
  budgetModal,
  setBudgetModal,
  open,
  setOpen,
  cadUsu,
  setCadUsu,
  notificacoes,
  setNotificacoes,
  inconsistenciasInvestidores,
  selected,
  setSelected,
  setPatrimoniaisAD,
  setPatrimoniaisFR,
  setpatrimoniaisExcel,
  setpatrimoniaisCloseAll,
  setDatasFaltantes,
}) {
  const [cookies, removeCookie] = useCookies(["usuario"]);
  const history = useHistory();
  const [controladoria, setControladoria] = useState({
    controladoria: false,
  });

  const [gourmet, setGourmet] = useState({
    gourmet: false,
  });
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { profileData } = React.useContext(UserProfileContext);

  function openPage(url, params) {
    setOpen(false);
    setControladoria({
      controladoria: false,
      usuario: false,
      revenue: false,
      faturamento: false,
    });
    setGourmet({ gourmet: false });
    setpatrimoniaisCloseAll();
    setSelected([]);
    if (params) {
      history.push(url, params);
    } else {
      history.push(url);
    }
  }
  function logout() {
    removeCookie("usuario");
    history.replace("/");
  }

  const useStyles = makeStyles((theme) => ({
    small: {
      width: theme.spacing(3) * 0.8,
      height: theme.spacing(3) * 0.8,
    },
    spinnerGrowSm: {
      // width: '0.5rem',
      // height: '0.5rem',
      width: "0.4rem",
      height: "0.4rem",
      position: "absolute",
      top: "0px",
      // right: '10px',
      right: "8px",
    },
    spinnerGrowSmOpen: {
      float: "right",
      // marginRight: ' 10px',
      marginRight: " 8px",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1) * 0.8,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    noPadding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }));

  const isMobile = useMediaQuery("(max-width: 680px)");
  const classes = useStyles();
  const location = useLocation();
  const isActive = (paths) =>
    paths.includes(location.pathname) ? "menu-itens-dom" : "";
  const [searchQuery, setSearchQuery] = useState("");
  function normalizeString(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, " ")
      .trim()
      .toLowerCase();
  }

  function matchesAnyItem(items) {
    return items.some((item) =>
      normalizeString(item).includes(normalizeString(searchQuery))
    );
  }

  const updateUserTheme = async (darkMode) => {
    try {
      await api.post(
        "/usuarios/theme",
        {
          id: cookies.usuario.id,
          darkmode: darkMode,
        },
        {
          headers: {
            authorization: `Bearer ${cookies.usuario.accessToken}`,
          },
        }
      );
      setIsDarkMode(darkMode);
    } catch (err) {
      console.error("Erro ao atualizar o tema do usuário:", err);
    }
  };

  useEffect(() => {
    const fetchUserTheme = async () => {
      try {
        const response = await api.get(
          `/usuarios/theme/${cookies.usuario.id}`,
          {
            headers: {
              authorization: `Bearer ${cookies.usuario.accessToken}`,
            },
          }
        );
        const { darkmode } = response.data;
        setIsDarkMode(darkmode);
      } catch (err) {
        console.error("Erro ao buscar o tema do usuário:", err);
      }
    };

    if (cookies.usuario.id) {
      fetchUserTheme();
    }
  }, [cookies.usuario, isDarkMode]);

  const settingAnnualInfo = () => {
    const settings = {
      graphTool: false,
      hideMonths: true,
      hideOrcado: false,
      hideRealizado: false,
    };

    localStorage.setItem("DREOrcadoXRealizado", JSON.stringify(settings));

    openPage("/dre/orcadorealizado");
  };

  const settingMonthInfo = () => {
    const settings = {
      graphTool: false,
      hideMonths: false,
      hideOrcado: true,
      hideRealizado: false,
    };

    localStorage.setItem("DREOrcadoXRealizado", JSON.stringify(settings));

    openPage("/dre/orcadorealizado");
  };

  return (
    <>
      {isMobile ? null : (
        <div
          className={classes.toolbar}
          style={{ justifyContent: "space-between", padding: "0 4px" }}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <div>
              <img
                src={logo}
                alt={"Analytics Icon"}
                className="analytics-header-logo"
              />
              Analytics
            </div>
          ) : null}
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <BiDotsVerticalRounded /> : <AiOutlineMenu />}
          </IconButton>
        </div>
      )}

      <List
        style={{ paddingTop: 0, paddingBottom: 0 }}
        className={!controladoria.usuario ? isActive("/perfil") : ""}
      >
        <ListItem
          alignItems="center"
          style={{ paddingTop: 0, paddingBottom: 0 }}
          button
          key={"Controladoria"}
          onClick={() =>
            setControladoria({
              ...controladoria,
              usuario: !controladoria.usuario,
            })
          }
        >
          <ListItemIcon>
            <Avatar
              className={classes.small}
              src={profileData?.foto_url || cookies.usuario.foto_url}
            />
            {controladoria.usuario ||
            !notificacoes?.filter((d) => d.visualizado === false)?.length >
              0 ? (
              ""
            ) : (
              <Spinner
                className={classes.spinnerGrowSm}
                size="sm"
                animation="grow"
                variant="danger"
              />
            )}
          </ListItemIcon>
          <ListItemText>{cookies?.usuario?.nome}</ListItemText>
        </ListItem>
        <Collapse in={controladoria.usuario}>
          {open ? (
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              key={"Notificacoes"}
              onClick={() => {
                setOpen(false);
                setNotificacoes();
              }}
            >
              <ListItemIcon>
                {open &&
                  (notificacoes?.some((d) => !d.visualizado) ? (
                    <>
                      <RiNotification2Line style={{ float: "right" }} />
                      <Spinner
                        className={classes.spinnerGrowSm}
                        size="sm"
                        animation="grow"
                        variant="danger"
                      />
                    </>
                  ) : (
                    <RiNotification2Line style={{ float: "right" }} />
                  ))}
              </ListItemIcon>
              <ListItemText>Notificações</ListItemText>
            </ListItem>
          ) : (
            <Tooltip
              title="Notificacoes"
              TransitionComponent={Zoom}
              placement="right"
            >
              <ListItem
                style={{ paddingTop: 0, paddingBottom: 0 }}
                button
                key={"Notificacoes"}
                onClick={() => {
                  setOpen(false);
                  setNotificacoes();
                }}
              >
                <ListItemIcon>
                  {notificacoes?.some((d) => !d.visualizado) ? (
                    <>
                      <RiNotification2Line style={{ float: "right" }} />
                      <Spinner
                        className={classes.spinnerGrowSm}
                        size="sm"
                        animation="grow"
                        variant="danger"
                      />
                    </>
                  ) : (
                    <RiNotification2Line style={{ float: "right" }} />
                  )}
                </ListItemIcon>
                <ListItemText>Notificações</ListItemText>
              </ListItem>
            </Tooltip>
          )}
          <List
            style={{ paddingTop: 0, paddingBottom: 0 }}
            className={classes.noPadding && isActive("/perfil")}
          >
            {open ? (
              <ListItem
                style={{ paddingTop: 0, paddingBottom: 0 }}
                button
                key={"Perfil de Usuário"}
                onClick={() => {
                  openPage("/perfil");
                }}
              >
                <ListItemIcon>
                  {" "}
                  <TbUser />{" "}
                </ListItemIcon>
                <ListItemText>Perfil de Usuário</ListItemText>
              </ListItem>
            ) : (
              <Tooltip
                title="Perfil de Usuário"
                TransitionComponent={Zoom}
                placement="right"
              >
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"Perfil de Usuário"}
                  onClick={() => {
                    openPage("/perfil");
                  }}
                >
                  <ListItemIcon>
                    <TbUser />
                  </ListItemIcon>
                  <ListItemText>Perfil de Usuário</ListItemText>
                </ListItem>
              </Tooltip>
            )}
          </List>
        </Collapse>
      </List>
      <List
        style={{ paddingTop: 0, paddingBottom: 0 }}
        className={classes.noPadding && isActive("/home")}
      >
        {open ? (
          <ListItem
            style={{ paddingTop: 0, paddingBottom: 0 }}
            id="Dashboard"
            button
            key={"Dashboard"}
            onClick={() => openPage("/home")}
          >
            <ListItemIcon>
              <RiDashboardLine />
            </ListItemIcon>
            <ListItemText>Dashboard </ListItemText>
          </ListItem>
        ) : (
          <Tooltip
            title="Dashboard"
            TransitionComponent={Zoom}
            placement="right"
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              id="Dashboard"
              button
              key={"Dashboard"}
              onClick={() => openPage("/home")}
            >
              <ListItemIcon>
                <RiDashboardLine />
              </ListItemIcon>
              <ListItemText>Dashboard </ListItemText>
            </ListItem>
          </Tooltip>
        )}
      </List>

      {open ? (
        <TextField
          size="small"
          variant="outlined"
          placeholder="Pesquisar..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => setOpen(!open)}>
                  <CiSearch />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Tooltip title="Pesquisar..." placement="right">
          <IconButton
            style={{ padding: "8px" }}
            size="small"
            onClick={() => setOpen(!open)}
          >
            <CiSearch />
          </IconButton>
        </Tooltip>
      )}

      {sessionStorage.getItem("permissoes") ? (
        JSON.parse(sessionStorage.getItem("permissoes")).find((permissao) =>
          [5, 21, 6, 7, 3, 4, 18, 24, 25, 26, 2, 22, 23, 16, 31].includes(
            permissao.id
          )
        ) ? (
          <>
            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={
                !controladoria.topline
                  ? isActive([
                      "/comparativoanual",
                      "/comparativomensal",
                      "/comparativoperformance",
                    ])
                  : ""
              }
            >
              {sessionStorage.getItem("permissoes") ? (
                JSON.parse(sessionStorage.getItem("permissoes")).find(
                  (permissao) =>
                    permissao.id === 5 ||
                    permissao.id === 21 ||
                    permissao.id === 6 ||
                    permissao.id === 7
                ) ? (
                  open ? (
                    <ListItem
                      className={isActive("/comparativoanual")}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      button
                      key={"Topline"}
                      onClick={() =>
                        setControladoria({ topline: !controladoria.topline })
                      }
                    >
                      <ListItemIcon>
                        <RiTableLine />
                      </ListItemIcon>
                      <ListItemText>Topline</ListItemText>
                      {controladoria.topline ? (
                        <BsChevronUp />
                      ) : (
                        <BsChevronDown />
                      )}
                    </ListItem>
                  ) : (
                    <Tooltip
                      title="Topline"
                      TransitionComponent={Zoom}
                      placement="right"
                    >
                      <ListItem
                        className={isActive("/comparativoanual")}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        key={"Topline"}
                        onClick={() =>
                          setControladoria({ topline: !controladoria.topline })
                        }
                      >
                        <ListItemIcon>
                          <RiTableLine />
                        </ListItemIcon>
                        <ListItemText>Topline</ListItemText>
                        {controladoria.topline ? (
                          <BsChevronUp />
                        ) : (
                          <BsChevronDown />
                        )}
                      </ListItem>
                    </Tooltip>
                  )
                ) : null
              ) : null}
              <Collapse
                in={
                  controladoria.topline ||
                  (searchQuery &&
                    matchesAnyItem([
                      "Topline anual",
                      "Topline mensal",
                      "Topline comparativo",
                    ]))
                }
              >
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/comparativoanual")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 5
                    ) &&
                    (!searchQuery ||
                      normalizeString("Topline anual").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"ToplineAnual"}
                          onClick={() => {
                            openPage("/comparativoanual");
                          }}
                        >
                          <ListItemIcon>TA</ListItemIcon>
                          <ListItemText>Topline anual</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Topline Anual"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"ToplineAnual"}
                            onClick={() => {
                              openPage("/comparativoanual");
                            }}
                          >
                            <ListItemIcon>TA</ListItemIcon>
                            <ListItemText>Topline anual</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={
                    classes.noPadding && isActive("/comparativomensal")
                  }
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 21
                    ) &&
                    (!searchQuery ||
                      normalizeString("Topline mensal").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"ToplineMensal"}
                          onClick={() => {
                            openPage("/comparativomensal");
                          }}
                        >
                          <ListItemIcon>TM</ListItemIcon>
                          <ListItemText>Topline mensal</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Topline Mensal"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"ToplineMensal"}
                            onClick={() => {
                              openPage("/comparativomensal");
                            }}
                          >
                            <ListItemIcon>TM</ListItemIcon>
                            <ListItemText>Topline mensal</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={
                    classes.noPadding && isActive("/comparativoperformance")
                  }
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 7
                    ) &&
                    (!searchQuery ||
                      normalizeString("Topline comparativo").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Toplinecomparativo"}
                          onClick={() => {
                            openPage("/comparativoperformance");
                          }}
                        >
                          <ListItemIcon>TC</ListItemIcon>
                          <ListItemText>Topline comparativo</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Topline Comparativo"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Toplinecomparativo"}
                            onClick={() => {
                              openPage("/comparativoperformance");
                            }}
                          >
                            <ListItemIcon>TC</ListItemIcon>
                            <ListItemText>Topline comparativo</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>

            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={
                !controladoria.dres
                  ? isActive([
                      "/dre/orcadorealizado",
                      "/drecomparativo",
                      "/dremensal",
                    ])
                  : ""
              }
            >
              {sessionStorage.getItem("permissoes") ? (
                JSON.parse(sessionStorage.getItem("permissoes")).find(
                  (permissao) =>
                    permissao.id === 3 ||
                    permissao.id === 4 ||
                    permissao.id === 18 ||
                    permissao.id === 29
                ) ? (
                  open ? (
                    <ListItem
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      button
                      key={"DREs"}
                      onClick={() =>
                        setControladoria({ dres: !controladoria.dres })
                      }
                    >
                      <ListItemIcon>
                        <AiOutlineAccountBook />
                      </ListItemIcon>
                      <ListItemText>DREs </ListItemText>
                      {controladoria.dres ? <BsChevronUp /> : <BsChevronDown />}
                    </ListItem>
                  ) : (
                    <Tooltip
                      title="DREs"
                      TransitionComponent={Zoom}
                      placement="right"
                    >
                      <ListItem
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        key={"DREs"}
                        onClick={() =>
                          setControladoria({ dres: !controladoria.dres })
                        }
                      >
                        <ListItemIcon>
                          <AiOutlineAccountBook />
                        </ListItemIcon>
                        <ListItemText>DREs </ListItemText>
                        {controladoria.dres ? (
                          <BsChevronUp />
                        ) : (
                          <BsChevronDown />
                        )}
                      </ListItem>
                    </Tooltip>
                  )
                ) : null
              ) : null}

              <Collapse
                in={
                  controladoria.dres ||
                  (searchQuery &&
                    matchesAnyItem([
                      "DRE Orçado X Realizado",
                      "DRE mensal",
                      "DRE comparativo",
                      "DRE Realizado X Realizado",
                    ]))
                }
              >
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={
                    classes.noPadding && isActive("/dre/orcadorealizado")
                  }
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 29
                    ) &&
                    (!searchQuery ||
                      normalizeString("DRE Orçado X Realizado").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: "10px",
                          }}
                          button
                          key={"DREOrcadoXRealizado"}
                          onClick={settingAnnualInfo}
                        >
                          <ListItemIcon>DOR</ListItemIcon>
                          <ListItemText
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: "5px",
                            }}
                          >
                            DRE Orçado X Realizado
                          </ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="DRE Orçado X Realizado"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: "10px",
                            }}
                            button
                            key={"DREOrcadoXRealizado"}
                            onClick={settingAnnualInfo}
                          >
                            <ListItemIcon>DOR</ListItemIcon>
                            <ListItemText>DRE Orçado X Realizado</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                {sessionStorage.getItem("permissoes") ? (
                  JSON.parse(sessionStorage.getItem("permissoes")).find(
                    (permissao) => permissao.id === 45
                  ) &&
                  (!searchQuery ||
                    normalizeString("DRE Realizado X Realizado").includes(
                      normalizeString(searchQuery)
                    )) ? (
                    open ? (
                      <ListItem
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: "10px",
                        }}
                        button
                        key={"DRERealizadoXRealizado"}
                        onClick={() => {
                          openPage("/dre/realizado-x-realizado");
                        }}
                      >
                        <ListItemIcon>DRR</ListItemIcon>
                        <ListItemText
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: "5px",
                          }}
                        >
                          DRE Realizado X Realizado
                        </ListItemText>
                      </ListItem>
                    ) : (
                      <Tooltip
                        title="DRE Realizado X Realizado"
                        TransitionComponent={Zoom}
                        placement="right"
                      >
                        <ListItem
                          style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: "10px",
                          }}
                          button
                          key={"DRERealizadoXRealizado"}
                          onClick={() => {
                            openPage("/dre/realizado-x-realizado");
                          }}
                        >
                          <ListItemIcon>DRR</ListItemIcon>
                          <ListItemText>DRE Realizado X Realizado</ListItemText>
                        </ListItem>
                      </Tooltip>
                    )
                  ) : null
                ) : null}
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/dremensal")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 4
                    ) &&
                    (!searchQuery ||
                      normalizeString("DRE mensal").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"DREMensal"}
                          onClick={() => {
                            openPage("/dremensal");
                          }}
                        >
                          <ListItemIcon>DM</ListItemIcon>
                          <ListItemText>DRE mensal</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="DRE Mensal"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"DREMensal"}
                            onClick={() => {
                              openPage("/dremensal");
                            }}
                          >
                            <ListItemIcon>DM</ListItemIcon>
                            <ListItemText>DRE mensal</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/drecomparativo")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 18
                    ) &&
                    (!searchQuery ||
                      normalizeString("DRE comparativo").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"DREComparativo"}
                          onClick={() => {
                            openPage("/drecomparativo");
                          }}
                        >
                          <ListItemIcon>DC</ListItemIcon>
                          <ListItemText>DRE comparativo</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="DRE Comparativo"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"DREComparativo"}
                            onClick={() => {
                              openPage("/drecomparativo");
                            }}
                          >
                            <ListItemIcon>DC</ListItemIcon>
                            <ListItemText>DRE comparativo</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>

            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
              {open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"FCs"}
                  onClick={() => alert("Em breve Fluxo de caixa")}
                >
                  <ListItemIcon>
                    <TbReportMoney />
                  </ListItemIcon>
                  <ListItemText>FCs </ListItemText>
                  {controladoria.FCs ? <BsChevronUp /> : <BsChevronDown />}
                </ListItem>
              ) : (
                <Tooltip
                  title="FCs"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"FCs"}
                    onClick={() => alert("Em breve Fluxo de caixa")}
                  >
                    <ListItemIcon>
                      <TbReportMoney />
                    </ListItemIcon>
                    <ListItemText>FCs </ListItemText>
                    {controladoria.FCs ? <BsChevronUp /> : <BsChevronDown />}
                  </ListItem>
                </Tooltip>
              )}
            </List>

            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={
                !controladoria.budget
                  ? isActive([
                      "/budget",
                      "/gestaocontratos",
                      "/premissas",
                      "/quadrodepessoal",
                      "/resumobudget",
                    ])
                  : ""
              }
            >
              {sessionStorage.getItem("permissoes") ? (
                JSON.parse(sessionStorage.getItem("permissoes")).find(
                  (permissao) =>
                    permissao.id === 24 ||
                    permissao.id === 25 ||
                    permissao.id === 26
                ) ? (
                  open ? (
                    <ListItem
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      button
                      key={"Budget"}
                      onClick={() =>
                        setControladoria({ budget: !controladoria.budget })
                      }
                    >
                      <ListItemIcon>
                        <GrBriefcase />
                      </ListItemIcon>
                      <ListItemText>Budget</ListItemText>
                      {controladoria.budget ? (
                        <BsChevronUp />
                      ) : (
                        <BsChevronDown />
                      )}
                    </ListItem>
                  ) : (
                    <Tooltip
                      title="Budget"
                      TransitionComponent={Zoom}
                      placement="right"
                    >
                      <ListItem
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        key={"Budget"}
                        onClick={() =>
                          setControladoria({ budget: !controladoria.budget })
                        }
                      >
                        <ListItemIcon>
                          <GrBriefcase />
                        </ListItemIcon>
                        <ListItemText>Budget</ListItemText>
                        {controladoria.budget ? (
                          <BsChevronUp />
                        ) : (
                          <BsChevronDown />
                        )}
                      </ListItem>
                    </Tooltip>
                  )
                ) : null
              ) : null}

              <Collapse
                in={
                  controladoria.budget ||
                  (searchQuery &&
                    matchesAnyItem([
                      "Budget Anual",
                      "Gestão de Contratos",
                      "Definição de Premissas",
                      "Definição de Premissas",
                      "Quadro de Pessoal",
                      "Resumo Budget",
                    ]))
                }
              >
                {sessionStorage.getItem("permissoes") ? (
                  JSON.parse(sessionStorage.getItem("permissoes")).find(
                    (permissao) => permissao.id === 24
                  ) &&
                  (!searchQuery ||
                    normalizeString("Budget Anual").includes(
                      normalizeString(searchQuery)
                    )) ? (
                    open ? (
                      <ListItem
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        onClick={() => {
                          setOpen(false);
                          setBudgetModal({
                            ...budgetModal,
                            modalVisible: true,
                          });
                        }}
                      >
                        <ListItemIcon>BA</ListItemIcon>
                        <ListItemText>Budget Anual</ListItemText>
                      </ListItem>
                    ) : (
                      <Tooltip
                        title={"Budget Anual"}
                        TransitionComponent={Zoom}
                        placement="right"
                      >
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          onClick={() => {
                            setOpen(false);
                            setBudgetModal({
                              ...budgetModal,
                              modalVisible: true,
                            });
                          }}
                        >
                          <ListItemIcon>BA</ListItemIcon>
                          <ListItemText>Budget Anual</ListItemText>
                        </ListItem>
                      </Tooltip>
                    )
                  ) : null
                ) : null}
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/gestaocontratos")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 25
                    ) &&
                    (!searchQuery ||
                      normalizeString("Gestão de Contratos").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          onClick={() => {
                            openPage("/gestaocontratos");
                          }}
                        >
                          <ListItemIcon>GC</ListItemIcon>
                          <ListItemText>Gestão de Contratos</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title={"Gestão de Contratos"}
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            onClick={() => {
                              openPage("/gestaocontratos");
                            }}
                          >
                            <ListItemIcon>GC</ListItemIcon>
                            <ListItemText>Gestão de Contratos</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/premissas")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 26
                    ) &&
                    (!searchQuery ||
                      normalizeString("Definição de Premissas").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          onClick={() => {
                            openPage("/premissas");
                          }}
                        >
                          <ListItemIcon>DP</ListItemIcon>
                          <ListItemText>Definição de Premissas</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title={"Definição de Premissas"}
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            onClick={() => {
                              openPage("/premissas");
                            }}
                          >
                            <ListItemIcon>DP</ListItemIcon>
                            <ListItemText>Definição de Premissas</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/quadrodepessoal")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 27
                    ) &&
                    (!searchQuery ||
                      normalizeString("Quadro de Pessoal").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          onClick={() => {
                            openPage("/quadrodepessoal");
                          }}
                        >
                          <ListItemIcon>QP</ListItemIcon>
                          <ListItemText>Quadro de Pessoal</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title={"Quadro de Pessoal"}
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            onClick={() => {
                              openPage("/quadrodepessoal");
                            }}
                          >
                            <ListItemIcon>QP</ListItemIcon>
                            <ListItemText>Quadro de Pessoal</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/resumobudget")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 40
                    ) &&
                    (!searchQuery ||
                      normalizeString("Resumo Budget").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          onClick={() => {
                            openPage("/resumobudget");
                          }}
                        >
                          <ListItemIcon>RE</ListItemIcon>
                          <ListItemText>Resumo Budget</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title={"Resumo Budget"}
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            onClick={() => {
                              openPage("/resumobudget");
                            }}
                          >
                            <ListItemIcon>RE</ListItemIcon>
                            <ListItemText>Resumo Budget</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>

            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={!controladoria.forecast ? isActive(["/forecast"]) : ""}
            >
              {sessionStorage.getItem("permissoes") ? (
                JSON.parse(sessionStorage.getItem("permissoes")).find(
                  (permissao) => permissao.id === 19
                ) ? (
                  open ? (
                    <ListItem
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      button
                      key={"Forecast"}
                      onClick={() =>
                        setControladoria({
                          forecast: !controladoria.forecast,
                        })
                      }
                    >
                      <ListItemIcon>
                        <RiCalendarCheckLine />
                      </ListItemIcon>
                      <ListItemText>Forecast</ListItemText>
                      {controladoria.forecast ? (
                        <BsChevronUp />
                      ) : (
                        <BsChevronDown />
                      )}
                    </ListItem>
                  ) : (
                    <Tooltip
                      title="Forecast"
                      TransitionComponent={Zoom}
                      placement="right"
                    >
                      <ListItem
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        key={"Forecast"}
                        onClick={() =>
                          setControladoria({
                            forecast: !controladoria.forecast,
                          })
                        }
                      >
                        <ListItemIcon>
                          <RiCalendarCheckLine />
                        </ListItemIcon>
                        <ListItemText>Forecast</ListItemText>
                        {controladoria.forecast ? (
                          <BsChevronUp />
                        ) : (
                          <BsChevronDown />
                        )}
                      </ListItem>
                    </Tooltip>
                  )
                ) : null
              ) : null}
              <Collapse
                in={
                  controladoria.forecast ||
                  (searchQuery && matchesAnyItem(["Forecast Unificado"]))
                }
              >
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/forecast")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 19
                    ) &&
                    (!searchQuery ||
                      normalizeString("Forecast Unificado").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"SitebUPHotels"}
                          onClick={() => {
                            openPage("/forecast");
                          }}
                        >
                          <ListItemIcon>FU</ListItemIcon>
                          <ListItemText>Forecast Unificado</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Forecast Unificado"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"SitebUPHotels"}
                            onClick={() => {
                              openPage("/forecast");
                            }}
                          >
                            <ListItemIcon>FU</ListItemIcon>
                            <ListItemText>Forecast Unificado</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>
          </>
        ) : null
      ) : null}

      {JSON.parse(sessionStorage.getItem("permissoes"))?.find(
        (permissao) =>
          permissao?.id === 30 || permissao?.id === 32 || permissao?.id === 33
      ) ? (
        <>
          <List
            style={{ paddingTop: 0, paddingBottom: 0 }}
            className={
              !controladoria.patrimoniais
                ? isActive([
                    "/patrimoniais/alugueisdividendos",
                    "/patrimoniais/acionistas",
                    "/patrimoniais/fundodereserva",
                  ])
                : ""
            }
          >
            {open ? (
              <ListItem
                style={{ paddingTop: 0, paddingBottom: 0 }}
                button
                key={"Patrimoniais"}
                onClick={() =>
                  setControladoria({
                    patrimoniais: !controladoria.patrimoniais,
                  })
                }
              >
                <ListItemIcon>
                  <BsBuilding />
                </ListItemIcon>
                <ListItemText>Patrimoniais</ListItemText>
                {controladoria.patrimoniais ? (
                  <BsChevronUp />
                ) : (
                  <BsChevronDown />
                )}
              </ListItem>
            ) : (
              <Tooltip
                title="Patrimoniais"
                TransitionComponent={Zoom}
                placement="right"
              >
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"Patrimoniais"}
                  onClick={() =>
                    setControladoria({
                      patrimoniais: !controladoria.patrimoniais,
                    })
                  }
                >
                  <ListItemIcon>
                    <BsBuilding />
                  </ListItemIcon>
                  <ListItemText>Patrimoniais</ListItemText>
                  {controladoria.patrimoniais ? (
                    <BsChevronUp />
                  ) : (
                    <BsChevronDown />
                  )}
                </ListItem>
              </Tooltip>
            )}
            <Collapse
              in={
                controladoria.patrimoniais ||
                (searchQuery &&
                  matchesAnyItem([
                    "Alugueis/Dividendos",
                    "Acionistas Edit",
                    "Fundo de Reserva",
                    "Invest. Orçado x Realizado",
                  ]))
              }
            >
              <List
                style={{ paddingTop: 0, paddingBottom: 0 }}
                className={
                  classes.noPadding &&
                  isActive("/patrimoniais/alugueisdividendos")
                }
              >
                {sessionStorage.getItem("permissoes") ? (
                  JSON.parse(sessionStorage.getItem("permissoes")).find(
                    (permissao) => permissao.id === 30
                  ) &&
                  (!searchQuery ||
                    normalizeString("Alugueis/Dividendos").includes(
                      normalizeString(searchQuery)
                    )) ? (
                    open ? (
                      <ListItem
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        key={"PatrimoniaisAluguel"}
                        onClick={setPatrimoniaisAD}
                      >
                        <ListItemIcon>AD</ListItemIcon>
                        <ListItemText>Alugueis/Dividendos</ListItemText>
                      </ListItem>
                    ) : (
                      <Tooltip
                        title="Alugueis/Dividendos"
                        TransitionComponent={Zoom}
                        placement="right"
                      >
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"PatrimoniaisAluguel"}
                          onClick={setPatrimoniaisAD}
                        >
                          <ListItemIcon>AD</ListItemIcon>
                          <ListItemText>Alugueis/Dividendos</ListItemText>
                        </ListItem>
                      </Tooltip>
                    )
                  ) : null
                ) : null}
              </List>
              <List
                style={{ paddingTop: 0, paddingBottom: 0 }}
                className={
                  classes.noPadding && isActive("/patrimoniais/acionistas")
                }
              >
                {sessionStorage.getItem("permissoes") ? (
                  JSON.parse(sessionStorage.getItem("permissoes")).find(
                    (permissao) => permissao.id === 34
                  ) &&
                  (!searchQuery ||
                    normalizeString("Acionistas Edit").includes(
                      normalizeString(searchQuery)
                    )) ? (
                    open ? (
                      <ListItem
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        key={"AcionistasEdit"}
                        onClick={() => openPage("/patrimoniais/acionistas")}
                      >
                        <ListItemIcon>AE</ListItemIcon>
                        <ListItemText>Acionistas Edit</ListItemText>
                      </ListItem>
                    ) : (
                      <Tooltip
                        title="Acionistas Edit"
                        TransitionComponent={Zoom}
                        placement="right"
                      >
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"AcionistasEdit"}
                          onClick={() => openPage("/patrimoniais/acionistas")}
                        >
                          <ListItemIcon>AE</ListItemIcon>
                          <ListItemText>Acionistas Edit</ListItemText>
                        </ListItem>
                      </Tooltip>
                    )
                  ) : null
                ) : null}
              </List>
              <List
                style={{ paddingTop: 0, paddingBottom: 0 }}
                className={
                  classes.noPadding && isActive("/patrimoniais/fundodereserva")
                }
              >
                {sessionStorage.getItem("permissoes") ? (
                  JSON.parse(sessionStorage.getItem("permissoes")).find(
                    (permissao) => permissao.id === 33
                  ) &&
                  (!searchQuery ||
                    normalizeString("Fundo de Reserva").includes(
                      normalizeString(searchQuery)
                    )) ? (
                    open ? (
                      <ListItem
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        button
                        key={"movimentacaoFundoDeReserva"}
                        onClick={setPatrimoniaisFR}
                      >
                        <ListItemIcon>FR</ListItemIcon>
                        <ListItemText>Fundo de Reserva</ListItemText>
                      </ListItem>
                    ) : (
                      <Tooltip
                        title="Fundo de Reserva"
                        TransitionComponent={Zoom}
                        placement="right"
                      >
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"movimentacaoFundoDeReserva"}
                          onClick={setPatrimoniaisFR}
                        >
                          <ListItemIcon>FR</ListItemIcon>
                          <ListItemText>Fundo de Reserva</ListItemText>
                        </ListItem>
                      </Tooltip>
                    )
                  ) : null
                ) : null}
              </List>
              {sessionStorage.getItem("permissoes") ? (
                JSON.parse(sessionStorage.getItem("permissoes")).find(
                  (permissao) => permissao.id === 32
                ) &&
                (!searchQuery ||
                  normalizeString("Invest. Orçado x Realizado").includes(
                    normalizeString(searchQuery)
                  )) ? (
                  open ? (
                    <ListItem
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: "10px",
                      }}
                      button
                      key={"PatrimoniaisInvestidores"}
                      onClick={setpatrimoniaisExcel}
                    >
                      <ListItemIcon>IOR</ListItemIcon>
                      <ListItemText
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: "5px",
                        }}
                      >
                        Invest. Orçado x Realizado
                      </ListItemText>
                    </ListItem>
                  ) : (
                    <Tooltip
                      title="Investidores Orçado x Realizado"
                      TransitionComponent={Zoom}
                      placement="right"
                    >
                      <ListItem
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: "10px",
                        }}
                        button
                        key={"PatrimoniaisInvestidores"}
                        onClick={setpatrimoniaisExcel}
                      >
                        <ListItemIcon>IOR</ListItemIcon>
                        <ListItemText>Invest. Orçado x Realizado</ListItemText>
                      </ListItem>
                    </Tooltip>
                  )
                ) : null
              ) : null}
            </Collapse>
          </List>
        </>
      ) : null}

      {sessionStorage.getItem("permissoes") ? (
        JSON.parse(sessionStorage.getItem("permissoes")).find(
          (permissao) =>
            permissao.id === 35 || permissao.id === 36 || permissao.id === 37
        ) ? (
          <>
            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={
                !controladoria.rh
                  ? isActive(["/rh/colaboradores", "/rh/pdi", "/rh/cargos"])
                  : ""
              }
            >
              {open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"Recursos Humanos"}
                  onClick={() => setControladoria({ rh: !controladoria.rh })}
                >
                  <ListItemIcon>
                    <RiHandHeartLine />
                  </ListItemIcon>
                  <ListItemText>Recursos Humanos</ListItemText>
                  {controladoria.rh ? <BsChevronUp /> : <BsChevronDown />}
                </ListItem>
              ) : (
                <Tooltip
                  title="Recursos Humanos"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"Recursos Humanos"}
                    onClick={() => setControladoria({ rh: !controladoria.rh })}
                  >
                    <ListItemIcon>
                      <RiHandHeartLine />
                    </ListItemIcon>
                    <ListItemText>Recursos Humanos</ListItemText>
                    {controladoria.rh ? <BsChevronUp /> : <BsChevronDown />}
                  </ListItem>
                </Tooltip>
              )}
              <Collapse
                in={
                  controladoria.rh ||
                  (searchQuery &&
                    matchesAnyItem(["Colaboradores", "PDI", "Cargos"]))
                }
              >
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/rh/colaboradores")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 36
                    ) &&
                    (!searchQuery ||
                      normalizeString("Colaboradores").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Colaboradores"}
                          onClick={() => {
                            openPage("/rh/colaboradores");
                          }}
                        >
                          <ListItemIcon>Col</ListItemIcon>
                          <ListItemText>Colaboradores</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Colaboradores"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Colaboradores"}
                            onClick={() => {
                              openPage("/rh/colaboradores");
                            }}
                          >
                            <ListItemIcon>Col</ListItemIcon>
                            <ListItemText>Colaboradores</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/rh/pdi")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 39
                    ) &&
                    (!searchQuery ||
                      normalizeString("PDI").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"PDI"}
                          onClick={() => {
                            openPage("/rh/pdi");
                          }}
                        >
                          <ListItemIcon>PDI</ListItemIcon>
                          <ListItemText>PDI</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="PDI"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"PDI"}
                            onClick={() => {
                              openPage("/rh/pdi");
                            }}
                          >
                            <ListItemIcon>PDI</ListItemIcon>
                            <ListItemText>PDI</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/rh/cargos")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 37
                    ) &&
                    (!searchQuery ||
                      normalizeString("Cargos").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Cargos"}
                          onClick={() => {
                            openPage("/rh/cargos");
                          }}
                        >
                          <ListItemIcon>Ca</ListItemIcon>
                          <ListItemText>Cargos</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Cargos"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Cargos"}
                            onClick={() => {
                              openPage("/rh/cargos");
                            }}
                          >
                            <ListItemIcon>Ca</ListItemIcon>
                            <ListItemText>Cargos</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>
          </>
        ) : null
      ) : null}

      {sessionStorage.getItem("permissoes") ? (
        JSON.parse(sessionStorage.getItem("permissoes")).find(
          (permissao) =>
            permissao.id === 14 || permissao.id === 15 || permissao.id === 17
        ) ? (
          <>
            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={
                !gourmet.gourmet
                  ? isActive([
                      "/registrogourmetdiario",
                      "/registrogourmetmensal",
                      "/gourmetcontrole",
                    ])
                  : ""
              }
            >
              {open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"Gourmet"}
                  onClick={() => setGourmet({ gourmet: !gourmet.gourmet })}
                >
                  <ListItemIcon>
                    <GrRestaurant />
                  </ListItemIcon>
                  <ListItemText>Gourmet</ListItemText>
                  {gourmet.gourmet ? <BsChevronUp /> : <BsChevronDown />}
                </ListItem>
              ) : (
                <Tooltip
                  title="Gourmet"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"Gourmet"}
                    onClick={() => setGourmet({ gourmet: !gourmet.gourmet })}
                  >
                    <ListItemIcon>
                      <GrRestaurant />
                    </ListItemIcon>
                    <ListItemText>Gourmet</ListItemText>
                    {gourmet.gourmet ? <BsChevronUp /> : <BsChevronDown />}
                  </ListItem>
                </Tooltip>
              )}
              <Collapse
                in={
                  gourmet.gourmet ||
                  (searchQuery &&
                    matchesAnyItem([
                      "Input Diário",
                      "Input Mensal",
                      "Controle de Registros",
                    ]))
                }
              >
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={
                    classes.noPadding && isActive("/registrogourmetdiario")
                  }
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 14
                    ) &&
                    (!searchQuery ||
                      normalizeString("Input Diário").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Input Diário"}
                          onClick={() => {
                            openPage("/registrogourmetdiario");
                          }}
                        >
                          <ListItemIcon>ID</ListItemIcon>
                          <ListItemText>Input Diário</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Input diário"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Input Diário"}
                            onClick={() => {
                              openPage("/registrogourmetdiario");
                            }}
                          >
                            <ListItemIcon>ID</ListItemIcon>
                            <ListItemText>Input Diário</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={
                    classes.noPadding && isActive("/registrogourmetmensal")
                  }
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 15
                    ) &&
                    (!searchQuery ||
                      normalizeString("Input Mensal").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Input Mensal"}
                          onClick={() => {
                            openPage("/registrogourmetmensal");
                          }}
                        >
                          <ListItemIcon>IM</ListItemIcon>
                          <ListItemText>Input Mensal</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Input mensal"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Input Mensal"}
                            onClick={() => {
                              openPage("/registrogourmetmensal");
                            }}
                          >
                            <ListItemIcon>IM</ListItemIcon>
                            <ListItemText>Input Mensal</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/gourmetcontrole")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 17
                    ) &&
                    (!searchQuery ||
                      normalizeString("Controle de Registros").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"ControleRegistros"}
                          onClick={() => {
                            openPage("/gourmetcontrole");
                          }}
                        >
                          <ListItemIcon>CR</ListItemIcon>
                          <ListItemText>Controle de Registros</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Controle de Registros"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"ControleRegistros"}
                            onClick={() => {
                              openPage("/gourmetcontrole");
                            }}
                          >
                            <ListItemIcon>CR</ListItemIcon>
                            <ListItemText>Controle de Registros</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>
          </>
        ) : null
      ) : null}

      {sessionStorage.getItem("permissoes") ? (
        JSON.parse(sessionStorage.getItem("permissoes")).find(
          (permissao) => permissao.id === 8
        ) ? (
          <>
            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={
                !controladoria.revenue ? isActive("/revenuemanager") : ""
              }
            >
              {open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"Revenue"}
                  onClick={() =>
                    setControladoria({ revenue: !controladoria.revenue })
                  }
                >
                  <ListItemIcon>
                    <BsCalendar />
                  </ListItemIcon>
                  <ListItemText>Revenue </ListItemText>
                  {controladoria.revenue ? <BsChevronUp /> : <BsChevronDown />}
                </ListItem>
              ) : (
                <Tooltip
                  title="Revenue"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"Revenue"}
                    onClick={() =>
                      setControladoria({ revenue: !controladoria.revenue })
                    }
                  >
                    <ListItemIcon>
                      <BsCalendar />
                    </ListItemIcon>
                    <ListItemText>Revenue</ListItemText>
                  </ListItem>
                </Tooltip>
              )}
              <Collapse
                in={
                  controladoria.revenue ||
                  (searchQuery && matchesAnyItem(["Revenue Manager"]))
                }
              >
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/revenuemanager")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 8
                    ) &&
                    (!searchQuery ||
                      normalizeString("Revenue Manager").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"RevenueManager"}
                          onClick={() => {
                            openPage("/revenuemanager");
                          }}
                        >
                          <ListItemIcon>
                            <BsCalendar />
                          </ListItemIcon>
                          <ListItemText>Revenue Manager</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Revenue Manager"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"RevenueManager"}
                            onClick={() => {
                              openPage("/revenuemanager");
                            }}
                          >
                            <ListItemIcon>
                              <BsCalendar />
                            </ListItemIcon>
                            <ListItemText>Revenue Manager</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>
          </>
        ) : null
      ) : null}

      {sessionStorage.getItem("permissoes") ? (
        JSON.parse(sessionStorage.getItem("permissoes")).find(
          (permissao) =>
            permissao.id === 42 || permissao.id === 43 || permissao.id === 44
        ) ? (
          <>
            <List
              style={{ paddingTop: 0, paddingBottom: 0 }}
              className={
                !controladoria.faturamento ? isActive("/buscar-cartas") : ""
              }
            >
              {open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"Faturamento"}
                  onClick={() =>
                    setControladoria({
                      faturamento: !controladoria.faturamento,
                    })
                  }
                >
                  <ListItemIcon>
                    <MdAttachMoney />
                  </ListItemIcon>
                  <ListItemText>Faturamento</ListItemText>
                  {controladoria.faturamento ? (
                    <BsChevronUp />
                  ) : (
                    <BsChevronDown />
                  )}
                </ListItem>
              ) : (
                <Tooltip
                  title="Faturamento"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"Faturamento"}
                    onClick={() =>
                      setControladoria({
                        faturamento: !controladoria.faturamento,
                      })
                    }
                  >
                    <ListItemIcon>
                      <MdAttachMoney />
                    </ListItemIcon>
                    <ListItemText>Faturamento</ListItemText>
                  </ListItem>
                </Tooltip>
              )}
              <Collapse
                in={
                  controladoria.faturamento ||
                  (searchQuery && matchesAnyItem(["Carregar NFS-e"]))
                }
              >
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 43
                    ) &&
                    (!searchQuery ||
                      normalizeString("Carregar NFS-e").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Carregar NFS-e"}
                          onClick={() => {
                            setOpenNFS(true);
                          }}
                        >
                          <ListItemIcon>
                            <LuArchiveRestore />
                          </ListItemIcon>
                          <ListItemText>Carregar NFS-e</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Carregar NFS-e"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Carregar NFS-e"}
                            onClick={() => {
                              setOpenNFS(true);
                            }}
                          >
                            <ListItemIcon>
                              <LuArchiveRestore />
                            </ListItemIcon>
                            <ListItemText>Carregar NFS-e</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 42
                    ) &&
                    (!searchQuery ||
                      normalizeString("Faturas Petrobras").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Faturas Petrobras"}
                          onClick={() => {
                            setOpenFaturamento(true);
                          }}
                        >
                          <ListItemIcon>
                            <FaMoneyBillTransfer />
                          </ListItemIcon>
                          <ListItemText>Faturas Petrobras</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Faturas Petrobras"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Faturas Petrobras"}
                            onClick={() => setOpenFaturamento(true)}
                          >
                            <ListItemIcon>
                              <FaMoneyBillTransfer />
                            </ListItemIcon>
                            <ListItemText>Faturas Petrobras</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
                <List
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={classes.noPadding && isActive("/buscar-cartas")}
                >
                  {sessionStorage.getItem("permissoes") ? (
                    JSON.parse(sessionStorage.getItem("permissoes")).find(
                      (permissao) => permissao.id === 44
                    ) &&
                    (!searchQuery ||
                      normalizeString("Buscar Cartas").includes(
                        normalizeString(searchQuery)
                      )) ? (
                      open ? (
                        <ListItem
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          button
                          key={"Buscar Cartas"}
                          onClick={() => {
                            openPage("/buscar-cartas");
                          }}
                        >
                          <ListItemIcon>
                            <TbMailForward />
                          </ListItemIcon>
                          <ListItemText>Buscar Cartas</ListItemText>
                        </ListItem>
                      ) : (
                        <Tooltip
                          title="Buscar Cartas"
                          TransitionComponent={Zoom}
                          placement="right"
                        >
                          <ListItem
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            button
                            key={"Buscar Cartas"}
                            onClick={() => {
                              openPage("/buscar-cartas");
                            }}
                          >
                            <ListItemIcon>
                              <TbMailForward />
                            </ListItemIcon>
                            <ListItemText>Buscar Cartas</ListItemText>
                          </ListItem>
                        </Tooltip>
                      )
                    ) : null
                  ) : null}
                </List>
              </Collapse>
            </List>
          </>
        ) : null
      ) : null}

      <div style={{ height: "100%" }}></div>

      <List
        style={{ paddingTop: 0, paddingBottom: 0 }}
        className={classes.noPadding}
      >
        {open ? (
          <ListItem
            style={{ paddingTop: 0, paddingBottom: 0 }}
            button
            key={"trocar-cor-do-tema"}
            onClick={() => updateUserTheme(!isDarkMode)}
          >
            <ListItemIcon>
              <DarkModeToggle
                isDarkMode={isDarkMode}
                setIsDarkMode={updateUserTheme}
              />
            </ListItemIcon>
            <ListItemText primary={"Trocar cor do tema"} />
          </ListItem>
        ) : (
          <Tooltip title="Mudar cor do tema" placement="right">
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              key={"MudarCor"}
            >
              <ListItemIcon>
                <DarkModeToggle
                  isDarkMode={isDarkMode}
                  setIsDarkMode={updateUserTheme}
                />
              </ListItemIcon>
              <ListItemText>Mudar cor do tema</ListItemText>
            </ListItem>
          </Tooltip>
        )}
      </List>

      <List
        style={{ paddingTop: 0, paddingBottom: 0 }}
        className={
          !controladoria.painelAdm ? isActive(["/adicionar-atualizacoes"]) : ""
        }
      >
        <Collapse in={controladoria.painelAdm}>
          <List
            style={{ paddingTop: 0, paddingBottom: 0 }}
            className={classes.noPadding && isActive("/adicionar-atualizacoes")}
          >
            {sessionStorage.getItem("permissoes") ? (
              JSON.parse(sessionStorage.getItem("permissoes")).find(
                (permissao) => permissao.id === 1
              ) ? (
                open ? (
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"adicionarAtualizacoes"}
                    onClick={() => {
                      openPage("/adicionar-atualizacoes");
                    }}
                  >
                    <ListItemIcon>
                      {" "}
                      <GrDocumentUpdate />
                    </ListItemIcon>
                    <ListItemText primary={"Atualizações do sistema"} />
                  </ListItem>
                ) : (
                  <Tooltip title="Atualizações do sistema" placement="right">
                    <ListItem
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      button
                      key={"adicionarAtualizacoes"}
                      onClick={() => {
                        openPage("/adicionar-atualizacoes");
                      }}
                    >
                      <ListItemIcon>
                        <GrDocumentUpdate />
                      </ListItemIcon>
                      <ListItemText>Adicionar Atualizações</ListItemText>
                    </ListItem>
                  </Tooltip>
                )
              ) : null
            ) : null}
          </List>
          {sessionStorage.getItem("permissoes") ? (
            JSON.parse(sessionStorage.getItem("permissoes")).find(
              (permissao) => permissao.id === 1
            ) ? (
              open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"CadastroDRE"}
                  onClick={() => setIsOpenDRE(true)}
                >
                  <ListItemIcon>
                    {" "}
                    <TfiArchive />
                  </ListItemIcon>
                  <ListItemText primary={"Cadastro DRE"} />
                </ListItem>
              ) : (
                <Tooltip title="Cadastro DRE" placement="right">
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"CadastroDRE"}
                    onClick={() => setIsOpenDRE(true)}
                  >
                    <ListItemIcon>
                      <TfiArchive />
                    </ListItemIcon>
                    <ListItemText>Cadastro DRE</ListItemText>
                  </ListItem>
                </Tooltip>
              )
            ) : null
          ) : null}
          {sessionStorage.getItem("permissoes") ? (
            JSON.parse(sessionStorage.getItem("permissoes")).find(
              (permissao) => permissao.id === 1
            ) ? (
              open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"LogdeErros"}
                  onClick={() => setIsOpenLog(true)}
                >
                  <ListItemIcon>
                    {" "}
                    <MdErrorOutline />
                  </ListItemIcon>
                  <ListItemText primary={"Log de Erros"} />
                </ListItem>
              ) : (
                <Tooltip title="Log de Erros" placement="right">
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"LogdeErros"}
                    onClick={() => setIsOpenLog(true)}
                  >
                    <ListItemIcon>
                      <MdErrorOutline />
                    </ListItemIcon>
                    <ListItemText>Log de Erros</ListItemText>
                  </ListItem>
                </Tooltip>
              )
            ) : null
          ) : null}

          {sessionStorage.getItem("permissoes") ? (
            JSON.parse(sessionStorage.getItem("permissoes")).find(
              (permissao) => permissao.id === 1
            ) ? (
              open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"AdicionarTopline"}
                  onClick={() => setIsOpenAdiocionarTopline(true)}
                >
                  <ListItemIcon>
                    {" "}
                    <GoFileSymlinkFile />
                  </ListItemIcon>
                  <ListItemText primary={"Adicionar Dados ADM"} />
                </ListItem>
              ) : (
                <Tooltip title="Adicionar Dados ADM" placement="right">
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"Adicionar Dados ADM"}
                    onClick={() => setIsOpenAdiocionarTopline(true)}
                  >
                    <ListItemIcon>
                      <GoFileSymlinkFile />
                    </ListItemIcon>
                    <ListItemText>Adicionar Dados ADM</ListItemText>
                  </ListItem>
                </Tooltip>
              )
            ) : null
          ) : null}
        </Collapse>
        {cookies?.usuario?.permissao === 1 ? (
          open ? (
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              key={"PainelAdm"}
              onClick={() =>
                setControladoria({ painelAdm: !controladoria.painelAdm })
              }
            >
              <ListItemIcon>
                <RiAdminLine className={classes.small} />
              </ListItemIcon>
              <ListItemText primary={"Painel de Administrador"} />
            </ListItem>
          ) : (
            <Tooltip
              title="Painel de Administrador"
              TransitionComponent={Zoom}
              placement="right"
            >
              <ListItem
                style={{ paddingTop: 0, paddingBottom: 0 }}
                button
                key={"PainelAdm"}
                onClick={() =>
                  setControladoria({ painelAdm: !controladoria.painelAdm })
                }
              >
                <ListItemIcon>
                  <RiAdminLine className={classes.small} />
                </ListItemIcon>
                <ListItemText primary={"Painel de Administrador"} />
              </ListItem>
            </Tooltip>
          )
        ) : null}
      </List>

      <List
        style={{ paddingTop: 0, paddingBottom: 0 }}
        className={classes.noPadding && isActive("/atualizacoes")}
      >
        {open ? (
          <ListItem
            style={{ paddingTop: 0, paddingBottom: 0 }}
            button
            key={"verAtualizacoes"}
            onClick={() => {
              openPage("/atualizacoes");
            }}
          >
            <ListItemIcon>
              <GrUpdate />
            </ListItemIcon>
            <ListItemText primary={"Atualizações do sistema"} />
            <Spinner
              className={classes.spinnerGrowSm}
              size="sm"
              animation="grow"
              variant="success"
            />
          </ListItem>
        ) : (
          <Tooltip
            title="Atualizações do sistema"
            TransitionComponent={Zoom}
            placement="right"
          >
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              key={"verAtualizacoes"}
              onClick={() => {
                openPage("/atualizacoes");
              }}
            >
              <ListItemIcon>
                <GrUpdate />
              </ListItemIcon>
              <ListItemText primary={"Atualizações do sistema"} />
              <Spinner
                className={classes.spinnerGrowSm}
                size="sm"
                animation="grow"
                variant="success"
              />
            </ListItem>
          </Tooltip>
        )}
      </List>

      <List style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Collapse in={controladoria.configuracao}>
          {sessionStorage.getItem("permissoes") ? (
            JSON.parse(sessionStorage.getItem("permissoes")).find(
              (permissao) => permissao.id === 1
            ) ? (
              open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"verificaFaltantes"}
                  onClick={() => {
                    setOpen(false);
                    setDatasFaltantes({ visible: true, ta: true });
                  }}
                >
                  <ListItemIcon>VP</ListItemIcon>
                  <ListItemText primary={"Verifica Pendencias"} />
                </ListItem>
              ) : (
                <Tooltip
                  title="Verifica Pendencias"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"verificaFaltantes"}
                    onClick={() => {
                      setOpen(false);
                      setDatasFaltantes({ visible: true, ta: true });
                    }}
                  >
                    <ListItemIcon>VP</ListItemIcon>
                    <ListItemText primary={"Verifica Pendencias"} />
                  </ListItem>
                </Tooltip>
              )
            ) : null
          ) : null}
          <List
            style={{ paddingTop: 0, paddingBottom: 0 }}
            className={classes.noPadding && isActive("/registros")}
          >
            {sessionStorage.getItem("permissoes") ? (
              JSON.parse(sessionStorage.getItem("permissoes")).find(
                (permissao) => permissao.id === 1
              ) ? (
                open ? (
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"adicionaDados"}
                    onClick={() => {
                      openPage("/registros", { unidadeId: "xxxx" });
                    }}
                  >
                    <ListItemIcon>AD</ListItemIcon>
                    <ListItemText primary={"Adicionar Dados"} />
                  </ListItem>
                ) : (
                  <Tooltip
                    title="Adicionar Dados"
                    TransitionComponent={Zoom}
                    placement="right"
                  >
                    <ListItem
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      button
                      key={"adicionaDados"}
                      onClick={() => {
                        openPage("/registros", { unidadeId: "xxxx" });
                      }}
                    >
                      <ListItemIcon>AD</ListItemIcon>
                      <ListItemText primary={"Adicionar Dados"} />
                    </ListItem>
                  </Tooltip>
                )
              ) : null
            ) : null}
          </List>

          {sessionStorage.getItem("permissoes") ? (
            JSON.parse(sessionStorage.getItem("permissoes")).find(
              (permissao) => permissao.id === 10
            ) ? (
              open ? (
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  button
                  key={"adicionaUsuario"}
                  onClick={() => {
                    setOpen(false);
                    setCadUsu(false);
                  }}
                >
                  <ListItemIcon>AU</ListItemIcon>
                  <ListItemText primary={"Adicionar Usuário"} />
                </ListItem>
              ) : (
                <Tooltip
                  title="Adicionar Usuário"
                  TransitionComponent={Zoom}
                  placement="right"
                >
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"adicionaUsuario"}
                    onClick={() => {
                      setOpen(false);
                      setCadUsu(false);
                    }}
                  >
                    <ListItemIcon>AU</ListItemIcon>
                    <ListItemText primary={"Adicionar Usuário"} />
                  </ListItem>
                </Tooltip>
              )
            ) : null
          ) : null}
          <List
            style={{ paddingTop: 0, paddingBottom: 0 }}
            className={classes.noPadding && isActive("/editarpermissoes")}
          >
            {sessionStorage.getItem("permissoes") ? (
              JSON.parse(sessionStorage.getItem("permissoes")).find(
                (permissao) => permissao.id === 20
              ) ? (
                open ? (
                  <ListItem
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    button
                    key={"editarPermissoes"}
                    onClick={() => {
                      openPage("/editarpermissoes");
                    }}
                  >
                    <ListItemIcon>EP</ListItemIcon>
                    <ListItemText primary={"Editar permissões"} />
                  </ListItem>
                ) : (
                  <Tooltip
                    title="Editar Permissões"
                    TransitionComponent={Zoom}
                    placement="right"
                  >
                    <ListItem
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      button
                      key={"editarPermissoes"}
                      onClick={() => {
                        openPage("/editarpermissoes");
                      }}
                    >
                      <ListItemIcon>EP</ListItemIcon>
                      <ListItemText primary={"Editar permissões"} />
                    </ListItem>
                  </Tooltip>
                )
              ) : null
            ) : null}
          </List>
        </Collapse>
        {sessionStorage.getItem("permissoes") ? (
          JSON.parse(sessionStorage.getItem("permissoes")).find(
            (permissao) =>
              permissao.id === 1 || permissao.id === 10 || permissao.id === 20
          ) ? (
            open ? (
              <ListItem
                style={{ paddingTop: 0, paddingBottom: 0, backgroud: "blue" }}
                button
                key={"Options"}
                onClick={() =>
                  setControladoria({
                    configuracao: !controladoria.configuracao,
                  })
                }
              >
                <ListItemIcon>
                  <BsGear />
                </ListItemIcon>
                <ListItemText primary={"Configurações"} />
              </ListItem>
            ) : (
              <Tooltip
                title="Configurações"
                TransitionComponent={Zoom}
                placement="right"
              >
                <ListItem
                  style={{ paddingTop: 0, paddingBottom: 0, backgroud: "blue" }}
                  button
                  key={"Options"}
                  onClick={() =>
                    setControladoria({
                      configuracao: !controladoria.configuracao,
                    })
                  }
                >
                  <ListItemIcon>
                    <BsGear />
                  </ListItemIcon>
                  <ListItemText primary={"Configurações"} />
                </ListItem>
              </Tooltip>
            )
          ) : null
        ) : null}

        {open ? (
          <ListItem
            style={{ paddingTop: 0, paddingBottom: 0 }}
            button
            key={"logout"}
            onClick={logout}
          >
            <ListItemIcon>
              <HiOutlineLogout />
            </ListItemIcon>
            <ListItemText primary={"Sair"} />
          </ListItem>
        ) : (
          <Tooltip title="Sair" TransitionComponent={Zoom} placement="right">
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              key={"logout"}
              onClick={logout}
            >
              <ListItemIcon>
                <HiOutlineLogout />
              </ListItemIcon>
              <ListItemText primary={"Sair"} />
            </ListItem>
          </Tooltip>
        )}
      </List>
    </>
  );
}
