/* eslint-disable */
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';



// icons

// grafico
import ReactApexChart from "react-apexcharts";

// API
import api from '../../services/api';

// Styles

import { AiOutlinePlus } from "react-icons/ai";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { LuHotel } from "react-icons/lu";
import { Action, Fab } from "react-tiny-fab";
import ModalSelectCombo from "../../components/modals/models/modalToplineDash.tsx";
import ToplineTicker from '../../components/topline-ticker/index.tsx';
import { useCombos } from '../../contexts/useCombo.js';
import { useUnidades } from '../../contexts/useUnidades.js';
import './styles.css';
import Card from "./styles.ts";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';



export default function Analytics() {

    const [cookies] = useCookies([]);
    const [unidades, setUnidades] = useState([])
    const [linhas, setLinhas] = useState([])
    const [linhasdre, setLinhasDre] = useState([])
    const [carouselMontado, setCarouselMontado] = useState(null)
    const [budgetDashboard, setBudgetDashboard] = useState([])
    const [opcoesCarregadas, setOpcoesCarregadas] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const [selectedCombo, setSelectedCombo] = useState();
    const [carregando, setCarregando] = useState({ carregando: true, carregandoRL: true, carregandoOTP: true });
    const handleComboChange = (event, value) => {
        setSelectedCombo(value);
    };

    const loadRestaurante = false
    const combos = useCombos(loadRestaurante);
    const isMobile = window.innerWidth < 720

    const comboUnidades = useUnidades()

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            fontSize: 15,
        },
    }));

    const CustomTooltip = () => {
        return (
            <LightTooltip
                placement="right-start"
                title={
                    <>
                        <div style={{ fontWeight: 'bold' }} color="inherit">Mês Atual:</div>
                        <ul style={{ paddingLeft: "20px", margin: "10px 0" }}>
                            <li>Realização: Verifique se o percentual de realização do último fechamento carregado atingiu ou superou a meta.</li>
                            <li>Acima de 100%: Parabéns! Você superou o esperado!</li>
                            <li>Abaixo de 100%: Vamos analisar juntos como podemos alcançar a meta no próximo mês.</li>
                        </ul>
                        <div style={{ fontWeight: 'bold' }} color="inherit">SPIT:</div>
                        <ul style={{ paddingLeft: "20px", margin: "10px 0" }}>
                            <li>Acompanhamento: Monitore o percentual do orçamento de janeiro até o mês atual.</li>
                            <li>Essa análise permite identificar tendências e ajustar estratégias para o sucesso anual.</li>
                        </ul>
                        <div style={{ fontWeight: 'bold' }} color="inherit">Ano:</div>
                        <ul style={{ paddingLeft: "20px", margin: "10px 0" }}>
                            <li>Visão Geral: Avalie o percentual do orçamento anual realizado até o momento.</li>
                            <li>Essa visão abrangente permite acompanhar o progresso geral e tomar decisões estratégicas.</li>
                        </ul>
                    </>
                }

            >
                <div style={{ width: '20px' }}>
                    <IoIosInformationCircleOutline />
                </div>
            </LightTooltip >
        );
    };

    useEffect(() => {
        if (combos?.length > 0) {
            setOpcoesCarregadas(true);
            setSelectedCombo(combos[0]);
        }

    }, [combos]);

    useEffect(() => {
        if (!opcoesCarregadas) return;
        var array = [];
        if (myUnidadesMemory) {
            JSON.parse(myUnidadesMemory).map(unidade => {
                array.push({ label: unidade.unidade, value: unidade.id, combo_id: unidade.combo_id });
            });
        }
        setUnidades(array);
        linha(array);
        linhasDre(array);
        budgetDashboardData();

    }, [myUnidadesMemory, opcoesCarregadas]);

    const opcoesCombos =
        combos?.map(({ ...rest }) => ({
            categoria: "Combos",
            tipo: "combo",
            ...rest
        })) || [opcoesCombos[0]];



    const opcoesAgrupadas = [...opcoesCombos];

    var myUnidadesMemory = sessionStorage.getItem('unidadesMemory')



    async function linha(unidades) {

        var unidadesConsultar = []

        if (!JSON.parse(sessionStorage.getItem('permissoes')).find(permissao => permissao.id === 12)) {
            return
        }
        unidades.map(async unidade => {
            unidadesConsultar.push(unidade.value)
        })

        var yesterday = new Date()
        var today = new Date()
        var lastMonth = new Date()
        var nextMonth = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        lastMonth.setDate(lastMonth.getDate() - 31)
        nextMonth.setDate(nextMonth.getDate() + 30)

        var ini = yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (yesterday.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
        var fini = lastMonth.getFullYear() + "-" + (lastMonth.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (lastMonth.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
        var uni = today.getFullYear() + "-" + (today.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (today.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
        var funi = nextMonth.getFullYear() + "-" + (nextMonth.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (nextMonth.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })

        var codigosLista = ['ADR_ROOM', 'OCC_PERC', 'ROOM_REVENUE', 'OTHER_REVENUE', 'FOOD_BEV_REVENUE']

        var resTopline = await api.get(`/dashboard/topline/${unidadesConsultar}/${codigosLista}/${fini}/${ini}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        var linhataxaocupacao = []
        var linhadiariamedia = []
        var linhareceitahospedagem = []
        var linhaallrevcomp = []

        resTopline.data.map(registro => {
            switch (registro.codigo_id) {
                case "OCC_PERC":
                    linhataxaocupacao.push({
                        codigo_id: "OCC_PERC",
                        data: registro.data.substring(8, 10) + '/' + registro.data.substring(5, 7),
                        taxaocupacao: (registro.valor),
                        unidade_id: registro.unidade_id,
                        unidade: registro.unidade,
                        dataFull: registro.data
                    })
                    break;
                case "ADR_ROOM":
                    linhadiariamedia.push({
                        codigo_id: "ADR_ROOM",
                        data: registro.data.substring(8, 10) + '/' + registro.data.substring(5, 7),
                        diariamedia: (registro.valor),
                        unidade_id: registro.unidade_id,
                        unidade: registro.unidade,
                        dataFull: registro.data
                    })
                    break;
                default:
                    linhaallrevcomp.push({
                        codigo_id: registro.codigo_id,
                        data: registro.data.substring(8, 10) + '/' + registro.data.substring(5, 7),
                        valor: (registro.valor),
                        unidade_id: registro.unidade_id,
                        unidade: registro.unidade,
                        dataFull: registro.data
                    })
                    break;
            }
        })

        var resForecast = await api.get(`/forecastGraficos/${unidadesConsultar}/${funi}/${uni}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        resForecast.data.map(registro2 => {
            linhataxaocupacao.push({
                codigo_id: "OCC_PERC",
                data: registro2.data.substring(8, 10) + '/' + registro2.data.substring(5, 7),
                taxaocupacao: (registro2.CF_OCCUPANCY),
                unidade_id: registro2.unidade_id,
                unidade: registro2.unidade,
                color: '#685',
                dataFull: registro2.data
            })

            linhadiariamedia.push({
                codigo_id: "ADR_ROOM",
                data: registro2.data.substring(8, 10) + '/' + registro2.data.substring(5, 7),
                diariamedia: (registro2.CF_AVERAGE_ROOM_RATE),
                unidade_id: registro2.unidade_id,
                unidade: registro2.unidade,
                color: '#685',
                dataFull: registro2.data
            })

            linhareceitahospedagem.push({
                codigo_id: "ROOM_REVENUE",
                data: registro2.data.substring(8, 10) + '/' + registro2.data.substring(5, 7),
                receitahospedagem: (registro2.REVENUE),
                unidade_id: registro2.unidade_id,
                unidade: registro2.unidade,
                color: '#685',
                dataFull: registro2.data
            })
        })

        setLinhas([linhataxaocupacao, linhadiariamedia, linhaallrevcomp])
        setCarregando(prevState => ({
            ...prevState,
            carregando: false,
        }));
    }

    async function linhasDre(unidades) {

        var unidadesConsultar = []

        if (!JSON.parse(sessionStorage.getItem('permissoes')).find(permissao => permissao.id === 12)) {
            return
        }
        unidades.map(async unidade => {
            unidadesConsultar.push(unidade.value)
        })
        var yesterday = new Date()
        var today = new Date()
        var lastMonth = new Date()
        var nextMonth = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        lastMonth.setDate(lastMonth.getDate() - 31)
        nextMonth.setDate(nextMonth.getDate() + 30)

        var codigosListaDRE = ['RBO 1 - RESULTADO BRUTO OPERACIONAL', 'RECEITA LIQUIDA', 'TOTAL DE CUSTOS E DESPESAS OPERACIONAIS']
        var resControladoria = await api.get(`/dashboard/controladoria/${unidadesConsultar}/${codigosListaDRE}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        var linhareceitaliquida = []
        var linhatotaldecustosedespesas = []
        var linharesultadooperacionalbruto = []

        resControladoria.data.map(registro => {
            registro.data = registro.data.substring(5, 7)
            switch (registro.codigodre_id) {
                case "RECEITA LIQUIDA":
                    registro.receitaliquida = registro.valor
                    registro.color = 'positivo'
                    linhareceitaliquida.push(registro)
                    break;
                case "TOTAL DE CUSTOS E DESPESAS OPERACIONAIS":
                    registro.totaldecustosedespesas = Math.abs(registro.valor)
                    registro.color = 'positivo'
                    linhatotaldecustosedespesas.push(registro)
                    break;
                case 'RBO 1 - RESULTADO BRUTO OPERACIONAL':
                    registro.linharesultadooperacionalbruto = registro.valor
                    registro.color = 'positivo'
                    linharesultadooperacionalbruto.push(registro)
                    break;
                default:
                    break;
            }
        })

        var combos = [...new Set(unidades.reduce((acc, atual) => acc = [...acc, atual.combo_id], []))]
        combos?.map(combo => {
            if (combo) {
                for (let i = 1; i <= 12; i++) {
                    if (linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i).length > 0) {
                        var pos = parseInt(linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].data) - (parseInt(new Date().getMonth())) + 12
                        linhareceitaliquida.push({
                            codigodre_id: "RECEITA LIQUIDA",
                            color: "positivo",
                            combo: linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo,
                            combo_id: linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo_id,
                            data: linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].data,
                            receitaliquida: linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i).reduce((acc, atual) => { return acc + atual.receitaliquida }, 0),
                            unidade: "Total " + linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo,
                            unidade_id: linhareceitaliquida.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].unidade_id,
                            ordem: pos > 12 ? pos - 12 : pos
                        })
                    }
                    if (linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i).length > 0) {
                        var pos = parseInt(linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].data) - (parseInt(new Date().getMonth())) + 12
                        linhatotaldecustosedespesas.push({
                            codigodre_id: "Total custos + despesas",
                            color: "positivo",
                            combo: linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo,
                            combo_id: linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo_id,
                            data: linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].data,
                            totaldecustosedespesas: linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i).reduce((acc, atual) => { return acc + atual.totaldecustosedespesas }, 0),
                            unidade: "Total " + linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo,
                            unidade_id: linhatotaldecustosedespesas.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].unidade_id,
                            ordem: pos > 12 ? pos - 12 : pos
                        })
                    }
                    if (linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i).length > 0) {
                        var pos = parseInt(linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].data) - (parseInt(new Date().getMonth())) + 12
                        linharesultadooperacionalbruto.push({
                            codigodre_id: 'RBO 1 - RESULTADO BRUTO OPERACIONAL',
                            color: "positivo",
                            combo: linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo,
                            combo_id: linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo_id,
                            data: linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].data,
                            linharesultadooperacionalbruto: linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i).reduce((acc, atual) => { return acc + atual.linharesultadooperacionalbruto }, 0),
                            unidade: "Total " + linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].combo,
                            unidade_id: linharesultadooperacionalbruto.filter(d => d.combo_id === combo && parseInt(d.data) === i)[0].unidade_id,
                            ordem: pos > 12 ? pos - 12 : pos
                        })
                    }
                }
            }
        })
        setLinhasDre([linhareceitaliquida, linhatotaldecustosedespesas, linharesultadooperacionalbruto])
        setCarregando(prevState => ({
            ...prevState,
            carregandoRL: false
        }));
    }
    async function budgetDashboardData() {
        var resBudget = await api.get('/dashboard/budget?usuario_id=' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setBudgetDashboard(resBudget.data)
        setCarregando(prevState => ({
            ...prevState,
            carregandoOTP: false
        }));
    }

    function toplineDash() {
        if (linhas.length > 0 && linhasdre.length > 0 && carouselMontado === null) {

            const dadosFiltrados = linhas[2]?.filter(d => selectedCombo?.comp_combos?.includes(d.unidade_id));
            const categoriesXaxis = [...new Set(dadosFiltrados.map(d => d.data))];

            var seriesCR = [];
            selectedCombo?.comp_combos?.forEach(unidadeId => {
                ['ROOM_REVENUE', 'FOOD_BEV_REVENUE', 'OTHER_REVENUE'].forEach(codigo => {
                    const Data = dadosFiltrados.filter(d => d.unidade_id === unidadeId && d.codigo_id === codigo).reduce((acc, cv) => acc = [...acc, cv.valor], [])
                    seriesCR.push({
                        name: `${codigo} - ${comboUnidades.find(b => unidadeId === b.unidade_id).unidade}`,
                        data: Data,
                        group: unidadeId,
                        type: "column"
                    });
                });
            });

            var stateCR = {
                series: seriesCR,
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        events: {
                            mounted: function (chartContext) {
                                chartContext.zoomX(this.isMobile ? 20 : 10, this.isMobile ? 30 : 10);
                            }
                        },

                        toolbar: {
                            autoSelected: 'pan',
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 0,
                        }
                    },
                    xaxis: {
                        categories: categoriesXaxis,
                        labels: {
                            hideOverlappingLabels: true,
                            rotate: 0,
                            showDuplicate: false
                        },
                    },
                    yaxis: {
                        labels: {
                            show: isMobile ? false : true,
                            formatter: (val) => {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 0
                    },
                    fill: {
                        opacity: 1
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    tooltip: {
                        shared: true,
                        intersect: false,
                        y: {
                            formatter: undefined,
                            title: {
                                formatter: (seriesName) => { return seriesName },
                            },
                        },
                    }
                }
            };

            const agruparDadosPorUnidade = (dados) => {
                return dados.reduce((acc, d) => {
                    if (!acc[d.unidade_id]) {
                        acc[d.unidade_id] = [];
                    }
                    acc[d.unidade_id].push(d);
                    return acc;
                }, {});
            };
            const dadosAgrupadosOcupacao = agruparDadosPorUnidade(linhas[0].filter(d => selectedCombo?.comp_combos?.includes(d.unidade_id)));
            const dadosAgrupadosDiaria = agruparDadosPorUnidade(linhas[1].filter(d => selectedCombo?.comp_combos?.includes(d.unidade_id)));
            var series = [];
            Object.keys(dadosAgrupadosOcupacao).forEach(unidadeId => {
                series.push({
                    name: `Taxa de ocupação - Unidade ${comboUnidades.find(b => unidadeId === b.unidade_id).unidade}`,
                    type: 'column',
                    data: dadosAgrupadosOcupacao[unidadeId].map(d => d.taxaocupacao)
                });
            });
            Object.keys(dadosAgrupadosDiaria).forEach(unidadeId => {
                series.push({
                    name: `Diária média - Unidade ${comboUnidades.find(b => unidadeId === b.unidade_id).unidade}`,
                    type: 'line',
                    data: dadosAgrupadosDiaria[unidadeId].map(d => d.diariamedia)
                });
            });

            var stateADROCC = {
                series: series,
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        events: {
                            mounted: function (chartContext) {
                                chartContext.zoomX(16, 46)
                            }
                        },

                        toolbar: {
                            autoSelected: 'pan',
                        },
                    },
                    colors: ['#0097e6', '#f1c40f', '#0E25F5', '#F44C00'],
                    stroke: {
                        width: [0, 4, 2, 2]
                    },
                    dataLabels: {
                        enabled: false,
                        enabledOnSeries: [1]
                    },
                    labels: [...new Set([...linhas[1].filter(d => selectedCombo?.comp_combos?.includes(d.unidade_id)).reduce((acc, cv) => acc = [...acc, cv.data], []), ...linhas[0].filter(d => selectedCombo?.comp_combos?.includes(d.unidade_id)).reduce((acc, cv) => acc = [...acc, cv.data], [])])],
                    xaxis: {
                        categories: [...new Set([...linhas[1].filter(d => selectedCombo?.comp_combos?.includes(d.unidade_id)).reduce((acc, cv) => acc = [...acc, cv.data], []), ...linhas[0].filter(d => selectedCombo?.comp_combos?.includes(d.unidade_id)).reduce((acc, cv) => acc = [...acc, cv.data], [])])],
                        labels: {
                            hideOverlappingLabels: true,
                            rotate: 0,
                        }
                    },
                    annotations: {
                        xaxis: [
                            {
                                x: new Date().toLocaleDateString().substr(0, 5),
                                borderColor: '#775DD0',
                                label: {
                                    style: {
                                        color: 'rgb(17, 158, 144) ',
                                    },
                                    text: 'Hoje'
                                }
                            }
                        ]
                    },
                    yaxis: [{
                        title: {
                            text: isMobile ? '' : 'Ocupação',
                        },
                        labels: {
                            show: isMobile ? false : true,
                            formatter: (val) => {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'decimal' }) + ' %'
                            }
                        }

                    }, {
                        opposite: true,
                        title: {
                            text: isMobile ? '' : 'Diária média'
                        },
                        labels: {
                            show: isMobile ? false : true,
                            formatter: (val) => {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    }]
                },
            }

            return (
                <div style={{ paddingTop: isMobile ? 0 : 20, paddingBottom: isMobile ? 0 : 20, borderRadius: '20px' }} key={'dashboard-' + selectedCombo?.value} fluid>
                    {selectedCombo && (
                        <>
                            {JSON.parse(sessionStorage.getItem('permissoes')).find(permissao => permissao.id === 28)
                                ? <>
                                    <h5 style={{ color: '#56565' }}>Topline - {selectedCombo.label}</h5>
                                    <div>

                                        <ToplineTicker value={selectedCombo?.value} label={selectedCombo?.label} />

                                    </div>
                                    <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Col sm={12} md={6} lg={6} xl={6}>
                                            <Card>
                                                <Card.Header>Diária média (R$) x Taxa de Ocupação (%)</Card.Header>
                                                <Card.Body >
                                                    <ReactApexChart options={stateADROCC.options} series={stateADROCC.series} type="line" height={350} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={6}>
                                            <Card>
                                                <Card.Header>Composição das Receitas (R$)</Card.Header>
                                                <Card.Body  >
                                                    <ReactApexChart options={stateCR.options} series={stateCR.series} type="line" height={350} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                                : null
                            }
                        </>
                    )
                    }
                </div>
            )
        }
        return (carouselMontado)
    }


    function stateRL() {
        if (linhas.length > 0 && linhasdre.length > 0 && carouselMontado === null) {

            var stateRBO1 = {
                series: [{
                    name: 'RBO 1',
                    data: linhasdre[2].filter(d => (d.combo_id === selectedCombo?.value && d.combo_id !== null) && d.unidade.toLowerCase().includes('total')).sort((a, b) => {
                        if (a.ordem > b.ordem) return 1
                        if (a.ordem < b.ordem) return -1
                        return 0
                    }).reduce((acc, cv) => acc = [...acc, cv.linharesultadooperacionalbruto], [])
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: isMobile ? false : true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: linhasdre[2].filter(d => (d.combo_id === selectedCombo?.value && d.combo_id !== null) && d.unidade.toLowerCase().includes('total')).sort((a, b) => {
                            if (a.ordem > b.ordem) return 1
                            if (a.ordem < b.ordem) return -1
                            return 0
                        }).reduce((acc, cv) => acc = [...acc, cv.data], []),
                        title: {
                            text: 'Meses'
                        },
                    },
                    yaxis: {
                        title: {
                            text: isMobile ? '' : 'Reais (R$)'
                        },
                        labels: {
                            show: isMobile ? false : true,
                            formatter: (val) => {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    }
                },
            }

            var stateRL = {
                series: [{
                    name: 'Receita Liquida (R$)',
                    data: linhasdre[0].filter(d => ((d.combo_id === selectedCombo?.value && d.combo_id !== null)) && d.unidade.toLowerCase().includes('total')).sort((a, b) => {
                        if (a.ordem > b.ordem) return 1
                        if (a.ordem < b.ordem) return -1
                        return 0
                    }).reduce((acc, cv) => acc = [...acc, cv.receitaliquida], [])
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: linhasdre[0].filter(d => (d.combo_id === selectedCombo?.value && d.combo_id !== null) && d.unidade.toLowerCase().includes('total')).sort((a, b) => {
                            if (a.ordem > b.ordem) return 1
                            if (a.ordem < b.ordem) return -1
                            return 0
                        }).reduce((acc, cv) => acc = [...acc, cv.data], []),
                        title: {
                            text: 'Meses'
                        },
                    },
                    yaxis: {
                        title: {
                            text: isMobile ? '' : 'Reais (R$)'
                        },
                        labels: {
                            show: isMobile ? false : true,
                            formatter: (val) => {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    }
                },
            }

            var stateTCD = {
                series: [{
                    name: 'Total de custos e despesas (R$)',
                    data: linhasdre[1].filter(d => (d.combo_id === selectedCombo?.value && d.combo_id !== null) && d.unidade.toLowerCase().includes('total')).sort((a, b) => {
                        if (a.ordem > b.ordem) return 1
                        if (a.ordem < b.ordem) return -1
                        return 0
                    }).reduce((acc, cv) => acc = [...acc, cv.totaldecustosedespesas], [])
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: linhasdre[1].filter(d => (d.combo_id === selectedCombo?.value && d.combo_id !== null) && d.unidade.toLowerCase().includes('total')).sort((a, b) => {
                            if (a.ordem > b.ordem) return 1
                            if (a.ordem < b.ordem) return -1
                            return 0
                        }).reduce((acc, cv) => acc = [...acc, cv.data], []),
                        title: {
                            text: 'Meses'
                        },
                    },
                    yaxis: {
                        title: {
                            text: isMobile ? '' : 'Reais (R$)'
                        },
                        labels: {
                            show: isMobile ? false : true,
                            formatter: (val) => {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return parseFloat(val).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }
                    }
                },
            }
            return (


                <>
                    {
                        JSON.parse(sessionStorage.getItem('permissoes')).find(permissao => permissao.id === 12)
                            ? <>
                                <h5 style={{ color: '#565656' }}>DRE - {selectedCombo.label}</h5>
                                <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                                    <Col sm={12} md={6} lg={6} xl={4}>
                                        <Card>
                                            <Card.Header>Receita Liquida (R$) </Card.Header>
                                            <Card.Body>
                                                <ReactApexChart options={stateRL.options} series={stateRL.series} type="bar" height={350} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={12} md={6} lg={4} xl={4}>
                                        <Card>
                                            <Card.Header>Total custos + despesas (R$)</Card.Header>
                                            <Card.Body >
                                                <ReactApexChart options={stateTCD.options} series={stateTCD.series} type="bar" height={350} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={12} md={6} lg={4} xl={4}>
                                        <Card>
                                            <Card.Header>RBO 1 - RESULTADO BRUTO OPERACIONAL (R$)</Card.Header>
                                            <Card.Body>
                                                <ReactApexChart options={stateRBO1.options} series={stateRBO1.series} type="bar" height={350} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }
                </>



            )
        }

    }

    function budgetOTP() {

        return (
            <>

                {
                    JSON.parse(sessionStorage.getItem('permissoes')).find(permissao => permissao.id === 13)
                        ? <>
                            <h5 style={{ color: '#56565' }}>Budget - {selectedCombo.label}</h5>
                            <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                                {[...new Set(budgetDashboard.reduce((acc, cv) => acc = [...acc, cv.codigo_id], []))].map((codigo, idx2) => {
                                    var atualdataopt = {
                                        series: budgetDashboard.filter(d => parseFloat(d.combo_id) === parseFloat(selectedCombo?.value) && d.codigo_id === codigo).reduce((acc, cv) => acc = [...acc, Math.abs(cv.valor)], []),
                                        options: {
                                            chart: {
                                                height: 390,
                                                type: 'radialBar',
                                            },
                                            plotOptions: {
                                                radialBar: {
                                                    offsetY: 0,
                                                    startAngle: 0,
                                                    endAngle: 270,
                                                    hollow: {
                                                        margin: 5,
                                                        size: '30%',
                                                        background: 'transparent',
                                                        image: undefined,
                                                    },
                                                    dataLabels: {
                                                        name: {
                                                            show: true,
                                                        },
                                                        value: {
                                                            show: true,
                                                            formatter: function (val) {
                                                                return parseFloat(val).toFixed(2) + ' %';
                                                            },
                                                        }
                                                    }
                                                }
                                            },
                                            colors: budgetDashboard.filter(d => parseFloat(d.combo_id) === parseFloat(selectedCombo?.value) && d.codigo_id === codigo).reduce((acc, cv) => acc = [...acc, cv.color], []),
                                            labels: budgetDashboard.filter(d => parseFloat(d.combo_id) === parseFloat(selectedCombo?.value) && d.codigo_id === codigo).reduce((acc, cv) => acc = [...acc, cv.tipo], []),
                                            legend: {
                                                show: true,
                                                floating: true,
                                                fontSize: '13px',
                                                position: 'left',
                                                offsetX: -30,
                                                offsetY: 15,
                                                labels: {
                                                    useSeriesColors: true,
                                                },
                                                markers: {
                                                    size: 0
                                                },
                                                formatter: function (seriesName, opts) {
                                                    var multiplicador = 1
                                                    if (opts.w.globals.colors[opts.seriesIndex] === "#FF0000") multiplicador = -1
                                                    return seriesName + ":  " + (parseFloat(opts.w.globals.series[opts.seriesIndex]) * multiplicador).toFixed(2) + " %";
                                                },
                                                itemMargin: {
                                                    vertical: 3
                                                }
                                            },

                                        },


                                    }
                                    return (
                                        <Col key={'2' + idx2} sm={12} md={6} lg={6} xl={4}>
                                            {carregando.carregandoOTP ? <Skeleton width={500} height={500} /> :
                                                <Card>
                                                    <Card.Header>
                                                        {codigo}
                                                        < CustomTooltip />
                                                    </Card.Header>
                                                    <Card.Body style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Row>
                                                            <Col>

                                                                <ReactApexChart options={atualdataopt.options} series={atualdataopt.series} type="radialBar" height={390} />
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            }
                                        </Col>
                                    )
                                })}
                            </Row>
                        </>
                        : null
                }
            </>
        )
    }


    const ShowSkeleton = () => {
        return (
            <div>
                <Skeleton variant="text" width={100} />
                <Card style={{ padding: 30, borderRadius: 12 }}>
                    <Skeleton variant="rect" height={300} />
                </Card>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', gap: 20, }}>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%", }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%" }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                </div>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', gap: 20, }}>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%", }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%" }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%" }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                </div>
            </div>
        );
    }

    const ShowSkeletonRL = () => {
        return (
            <div>
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', gap: 20, }}>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%", }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%" }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                    <Card style={{ borderRadius: 12, width: isMobile ? "100%" : "50%" }}>
                        <Card.Header>
                            <Skeleton variant="text" height={30} width={100} />
                        </Card.Header>
                        <Skeleton variant="rect" height={300} />
                    </Card>
                </div>
            </div>
        );
    }



    return (
        <div style={{ paddingRight: isMobile ? 1 : 20, paddingLeft: isMobile ? 1 : 20, overflowX: 'hidden' }}>

            <ModalSelectCombo show={isOpen} onHide={closeModal} handleComboChange={handleComboChange} opcoesAgrupadas={opcoesAgrupadas} opcoesCarregadas={opcoesCarregadas} selectedCombo={selectedCombo} />
            {carregando.carregando ? ShowSkeleton() : toplineDash()}
            {carregando.carregandoOTP ? ShowSkeleton() : budgetOTP()}
            {carregando.carregandoOTP ? ShowSkeletonRL() : stateRL()}
            <Fab

                icon={<AiOutlinePlus />}
                alwaysShowTitle={true}
                mainButtonStyles={{ backgroundColor: "#17a2b8" }}
                style={{ bottom: 0, right: 0, zoom: "80%", margin: "20px", zIndex: 100 }}
            >
                <Action
                    text={"Selecionar Unidade"}
                    style={{ backgroundColor: "blue" }}
                    onClick={openModal}
                >
                    <LuHotel />
                </Action>
            </Fab>
        </div>
    );
}