import React from "react";
import ModalGenerico from "../modalgenerico.tsx";
import { BiChart } from "react-icons/bi";
import ReactApexChart from "react-apexcharts";


const ModalContratos = ({
  open,
  onClose,
  title,
  onClickButton,
  checkboxData,
}) => {
  if (checkboxData.length === 0) {
    return '';
  }
  var dadosTratados = [];

  checkboxData.map((linhaX) => {
    Object.keys(linhaX.dataFormated)
      .filter((d) => d !== "total" && d !== "comparativo")
      .map((month) => {
        dadosTratados.push({
          month: month,
          code: linhaX.descricao,
          valor: linhaX.dataFormated[month].realizado,
          tipo: "realizado",
        });
      });
  });

  checkboxData.map((linhaX) => {
    Object.keys(linhaX.dataFormated)
      .filter((d) => d !== "total" && d !== "comparativo")
      .map((month) => {
        dadosTratados.push({
          month: month,
          code: linhaX.descricao,
          valor: linhaX.dataFormated[month].orcado,
          tipo: "orcado",
        });
      });
  });

  checkboxData.reduce((acc, cv) =>
    Object.keys(cv.dataFormated).filter(
      (d) => d !== "total" && d !== "comparativo"
    )
  );

  var stateRealizado = {
    series: checkboxData.map((linhaX) => {
      let data = [];
      Object.keys(linhaX.dataFormated)
        .filter((key) => key !== "total" && key !== "comparativo")
        .map((month) => {
          const value = linhaX.dataFormated[month].realizado;
          if (value === undefined) return;
          data.push(value);
        });

      return {
        name: linhaX.descricao,
        data: data,
      };
    }),
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      colors: ["#0097e6", "#f1c40f"],
      stroke: {
        width: [4, 4],
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [0],
      },
      xaxis: {
        categories: checkboxData.reduce((acc, cv) =>
          Object.keys(cv.dataFormated).filter(
            (d) => d !== "total" && d !== "comparativo"
          )
        ),
        labels: {
          hideOverlappingLabels: true,
          rotate: 0,
        },
      },
      yaxis: [
        {
          labels: {
            formatter: (val) => {
              return parseFloat(val).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
      ],
    },
  };

  var stateOrcado = {
    series: checkboxData.map((linhaX) => {
      let data = [];
      Object.keys(linhaX.dataFormated)
        .filter((key) => key !== "total" && key !== "comparativo")
        .map((month) => {
          const value = linhaX.dataFormated[month].orcado;
          if (value === undefined) return;
          data.push(value);
        });

      return {
        name: linhaX.descricao,
        data: data,
      };
    }),
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      colors: ["#0097e6", "#f1c40f"],
      stroke: {
        width: [4, 4],
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [0],
      },
      xaxis: {
        categories: checkboxData.reduce((acc, cv) =>
          Object.keys(cv.dataFormated).filter(
            (d) => d !== "total" && d !== "comparativo"
          )
        ),
        labels: {
          hideOverlappingLabels: true,
          rotate: 0,
        },
      },
      yaxis: [
        {
          labels: {
            formatter: (val) => {
              return parseFloat(val).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        },
      ],
    },
  };

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      showSearchIcon={false}
      buttonContent={<BiChart />}
      dialogContent={
        <>
          <h2>
            <b>Realizado</b>
          </h2>
          <ReactApexChart
            options={stateRealizado.options}
            series={stateRealizado.series}
            type="line"
            height={350}
          />
          <h2>
            <b>Orçado</b>
          </h2>
          <ReactApexChart
            options={stateOrcado.options}
            series={stateOrcado.series}
            type="line"
            height={350}
          />
        </>
      }
    />
  );
};

export default ModalContratos;
