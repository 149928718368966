const initial_state = {
    patrimoniais: [],
    patrimonialAtual: [],
};

export default function patrimoniais(state = initial_state, action) {
    switch (action.type) {
        case 'SET_PATRIMONIAIS':
            return { ...state, patrimoniais: action.patrimoniais };
            
        case 'SET_PATRIMONIAL_ATUAL':
            return {...state, patrimonialAtual: action.patrimonialAtual};
    
        default:
            return state;
    }
}