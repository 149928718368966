import styled from 'styled-components';

export const Desktop = styled.div`
display: flex;
flex-direction: column;
@media (max-width: 768px) {
  display: none;
  }
`;

export const Mobile = styled.div`
display: none;
@media (max-width: 768px) {
  display: flex;
  flex-direction: column;
  }
`;

export const AuthLy = styled.div`
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const TittleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f0f0;
  padding: 8px; 
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    background-color: #e8e8e8;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 4px;
  }
`;

export const Tittle = styled.div`
display: flex;
  align-items: center;
 font-size: 1.2em;
  font-weight: bold;
  color: #333;
`;

export const IconContainer = styled.div`
  font-size: 2em;
  color: #119e91;
  display: flex;
  align-items: center;
`;

export const TableContainer = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto; 

  @media (max-width: 768px) {
  
  }
`;

export const TableCell = styled.div`
  display: table-cell;
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const TableHeader = styled.div`
  display: table-header-group;
  color: white;
`;

export const TableRow = styled.div`
  display: table-row;
  &:nth-child(even) {
    background-color: #f1f1f1;
  }
  &:nth-child(even):hover {
    background-color: #f2f2f2;
  }
  &:nth-child(odd) {
     background-color: #fff; 
   }
   &:nth-child(odd):hover {
    background-color:    #D9D9E4;
  }
`;

export const TableHeaderCell = styled(TableCell)`
  font-weight: bold; 
  background-color: #119e91; 
  color: white; 
`;

export const TableCellPickUp = styled(TableCell)`
  background-color: #e6f7ff; 
  font-weight: bold; 
`;

export const TableCellDiferenca = styled(TableCell)<{ color: string }>`
  color: ${props => props.color};
  font-weight: bold;
`;
