export const dashboardData = [
    {
        "titulo":"Receita de Hospedagem",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
    {
        "titulo":"Receita de aeb",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
    {
        "titulo":"To",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
    {
        "titulo":"Total Rooms In Hotel",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
    {
        "titulo":"To",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
    {
        "titulo":"ADR",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
    {
        "titulo":"RevPar",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
    {
        "titulo":"TrevPar",
        "valor": 1530652.35,
        "valor_format": "R$ 1.530.652,35",
        "cor_valor_format": "#008000",
        "variacao_periodo_anterior": "12%",
        "cor_variacao_periodo_anterior":"#Ff0000",
        "tooltip_variacao_text":"A cor é referente a xyz",
        "variacao_atingir_budget": "85%",
        "cor_variacao_atingir_budget":"#000",
        "tooltip_variacao_atingir_budget":"Valor referente a xyz"
    },
]
  