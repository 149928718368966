import React from "react";
import { Button, Table } from "react-bootstrap";
import ModalGenerico from "../modalgenerico.tsx";
import { GrDocument } from "react-icons/gr";
import { IoAttach } from "react-icons/io5";

interface ModalContratosProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
  contratos: any;
  modalContratosShow: any;
}

const ModalContratos: React.FC<ModalContratosProps> = ({
  open,
  onClose,
  title,
  onClickButton,
  contratos,
  modalContratosShow,
}) => {
  const Unidades = sessionStorage.getItem("unidades") ?? "";

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      showSearchIcon={false}
      buttonContent={<GrDocument />}
      dialogContent={
        <>
          <Table size="sm">
            <thead>
              <tr>
                <th style={{ width: "30%" }}>Fornecedor</th>
                <th style={{ width: "30%" }}>Descrição</th>
                <th style={{ width: "20%" }}>Unidade</th>
                <th style={{ width: "10%" }}>Valor</th>
                <th style={{ textAlign: "center", width: "10%" }}>Contrato</th>
              </tr>
            </thead>
            <tbody>
              {contratos
                ?.filter(
                  (contrato) =>
                    contrato.planodecontas_id === modalContratosShow[2]
                )
                .map((contrato, idx) => {
                  return (
                    <tr key={"contrato2" + idx}>
                      <td style={{ width: "30%" }}>{contrato.fornecedor}</td>
                      <td style={{ width: "30%" }}>{contrato.descricao}</td>
                      <td style={{ width: "20%" }}>
                        {
                          JSON.parse(Unidades)?.find(
                            (unidade) => unidade.id === contrato.unidade_id
                          ).unidade
                        }
                      </td>
                      <td style={{ width: "10%" }}>
                        {contrato.valor.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td style={{ textAlign: "center", width: "10%" }}>
                        <Button
                          onClick={() => window.open(contrato.linkcontrato)}
                          variant="outline-primary"
                          size="sm"
                        >
                          <IoAttach />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Table size="sm">
            <tbody>
              <tr>
                <th style={{ textAlign: "right", width: "70%" }}>Total:</th>
                <td style={{ textAlign: "center" }}>
                  {contratos
                    ?.filter(
                      (contrato) =>
                        contrato.planodecontas_id === modalContratosShow[2]
                    )
                    .reduce((acc, cv) => (acc += cv.valor), 0)
                    .toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      }
    />
  );
};

export default ModalContratos;
