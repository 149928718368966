type Formato = "numero" | "moeda" | "porcentagem";

export const formatarValor = (
  valor: number | string,
  formato: Formato,
  incluirSimboloMoeda: boolean = true
): string => {
  let valorNumerico = typeof valor === "string" ? parseFloat(valor) : valor;
  if (isNaN(valorNumerico)) {
    return "R$ 0,00";
  }
  switch (formato) {
    case "numero":
      return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(valorNumerico);
    case "moeda":
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        currencyDisplay: incluirSimboloMoeda ? "symbol" : "code",
      }).format(valorNumerico);
    case "porcentagem":
      valorNumerico = valorNumerico * 100;
      if (isNaN(valorNumerico) || !isFinite(valorNumerico)) {
        return "0%";
      }
      return new Intl.NumberFormat("pt-BR", {
        style: "percent",
        maximumFractionDigits: 2,
      }).format(valorNumerico / 100);
    default:
      return valor.toString();
  }
};
