import React, { useState } from "react";
import ModalGenerico from "../modalgenerico.tsx";
import { GoArchive } from "react-icons/go";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import {
  BiCheckCircle,
  BiCommentAdd,
  BiCommentDetail,
  BiError,
} from "react-icons/bi";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Badge, Button } from "@material-ui/core";
import { IoAttach } from "react-icons/io5";
import { equal } from "mathjs";
import api from "../../../services/api.js";
import { useCookies } from "react-cookie";

interface ModalRealizadoOrcadoProps {
  open: boolean;
  onClose: () => void;
  title: string;
  razaoData: any;
  anexosRazao: any;
  setModalComentariosShow: (
    args: [boolean, string, any, string, string]
  ) => void;
  setNivelContaAtual: (analytics: string) => void;
  comentarios: any[];
  setModalNovoComentarios: (args: [boolean, string, any]) => void;
  modalShow: any[];
  notify: (message: any) => void;
  onClickButton: () => void;
  searchInput?: React.ReactNode;
  setRazaoData: (any) => void;
}

const ModalRazao: React.FC<ModalRealizadoOrcadoProps> = ({
  open,
  onClose,
  title,
  razaoData,
  anexosRazao,
  setModalComentariosShow,
  setNivelContaAtual,
  comentarios,
  setModalNovoComentarios,
  modalShow,
  notify,
  onClickButton,
  searchInput,
  setRazaoData,
}) => {
  const [cookies] = useCookies(["usuario"]);
  const [contaIdAnexoAtual, setContaIdAnexoAtual] = useState("");
  const [anexosAtuais, setAnexosAtuais] = useState(anexosRazao);

  function orderByCol(col, elementId) {
    const element = document.getElementById(elementId);
    const elementSpan = document?.getElementById(elementId)?.children[0];

    const toggleArrow = (arrowClass: string, hidden: boolean) => {
      const arrow = Array.from(elementSpan?.children || []).find((d) =>
        d.classList.contains(arrowClass)
      ) as HTMLElement | undefined;
      if (arrow) arrow.hidden = hidden;
    };

    if (!elementSpan?.classList.contains("sortActive")) {
      var atualSort = document.getElementsByClassName("sortActive")[0];
      atualSort.classList.remove("sortActive");
      Array.from(atualSort.children).forEach((e) => {
        if (e instanceof HTMLElement) {
          e.hidden = true;
        }
      });
      elementSpan?.classList.add("sortActive");
    }

    if (element) {
      if (element.ariaSort === "desc") {
        element.ariaSort = "asc";
        toggleArrow("ArrowUp", true);
        toggleArrow("ArrowDown", false);
      } else if (element.ariaSort === "asc") {
        toggleArrow("ArrowUp", true);
        toggleArrow("ArrowDown", true);
        element.ariaSort = "";
      } else {
        element.ariaSort = "desc";
        toggleArrow("ArrowUp", false);
        toggleArrow("ArrowDown", true);
      }
    }
    if (col === "valor") {
      setRazaoData([
        ...razaoData.map(
          (cv) =>
            (cv.valor =
              cv.gruposaldo === "D"
                ? cv.credito * -1 + cv.debito
                : cv.credito + cv.debito * -1)
        ),
      ]);
    }
    setRazaoData([
      ...razaoData.sort((a, b) => {
        if (a[col] > b[col]) return 1 * (element?.ariaSort === "asc" ? 1 : -1);
        if (a[col] < b[col]) return -1 * (element?.ariaSort === "asc" ? 1 : -1);
        return 0;
      }),
    ]);
  }

  async function adicionaAnexoRazao() {
    let myFiles;
    const element = document.getElementById("anexoAtualRazao");
    if (element instanceof HTMLInputElement && element.type === "file") {
      myFiles = element?.files;
    }
    if (myFiles?.length > 0) {
      for (let i = 0; i < myFiles?.length; i++) {
        const dados = new FormData();
        dados.append("file", myFiles[i]);
        dados.append("drerazao_id", contaIdAnexoAtual);
        try {
          await api
            .post("/filesRazao", dados, {
              headers: {
                authorization: `Bearer ${cookies?.usuario.accessToken}`,
              },
            })
            .then((res) => {
              notify("Anexo carregado com sucesso!");
              setAnexosAtuais([
                ...anexosAtuais,
                { ...res.data, drerazao_id: contaIdAnexoAtual },
              ]);
            });
        } catch (error) {
          notify("Erro ao carregar anexo, tente novamente mais tarde!");
          console.error("Erro ao carregar anexo razão: " + error);
        }
      }
      const anexos = document.getElementById("anexoAtualRazao");
      if (anexos instanceof HTMLInputElement) {
        anexos.value = "";
      }
    }
  }

  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={title}
      searchInput={searchInput}
      showSearchIcon={true}
      buttonContent={<GoArchive />}
      dialogContent={
        <>
          <Table size="sm">
            <thead>
              <tr>
                <th
                  style={{
                    width: "13%",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                  id="unidade_idParent"
                  onClick={() => orderByCol("unidade_id", "unidade_idParent")}
                >
                  Unidade
                  <span id="unidade_idSort">
                    <span className="ArrowDown" hidden={true}>
                      <BsArrowDown />
                    </span>
                    <span className="ArrowUp" hidden={true}>
                      <BsArrowUp />
                    </span>
                  </span>
                </th>
                <th
                  style={{
                    width: "10%",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                  id="dataParent"
                  onClick={() => orderByCol("data", "dataParent")}
                >
                  Data
                  <span id="dataSort" className="sortActive">
                    <span className="ArrowDown" hidden={false}>
                      <BsArrowDown />
                    </span>
                    <span className="ArrowUp" hidden={true}>
                      <BsArrowUp />
                    </span>
                  </span>
                </th>
                <th
                  style={{
                    width: "10%",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                  id="centrodecusto_idParent"
                  onClick={() =>
                    orderByCol("centrodecusto_id", "centrodecusto_idParent")
                  }
                >
                  C. Custo
                  <span id="centrodecusto_idSort">
                    <span className="ArrowDown" hidden={true}>
                      <BsArrowDown />
                    </span>
                    <span className="ArrowUp" hidden={true}>
                      <BsArrowUp />
                    </span>
                  </span>
                </th>
                <th
                  id="historicoParent"
                  style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                  onClick={() => orderByCol("historico", "historicoParent")}
                >
                  Histórico
                  <span id="historicoSort">
                    <span className="ArrowDown" hidden={true}>
                      <BsArrowDown />
                    </span>
                    <span className="ArrowUp" hidden={true}>
                      <BsArrowUp />
                    </span>
                  </span>
                </th>
                <th
                  id="valorParent"
                  style={{
                    width: "10%",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                  onClick={() => orderByCol("valor", "valorParent")}
                >
                  Valor
                  <span id="valorSort">
                    <span className="ArrowDown" hidden={true}>
                      <BsArrowDown />
                    </span>
                    <span className="ArrowUp" hidden={true}>
                      <BsArrowUp />
                    </span>
                  </span>
                </th>
                <th
                  colSpan={3}
                  style={{
                    width: "15%",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    cursor: "default",
                  }}
                >
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {razaoData.map((registro, idx) => {
                return (
                  <tr key={"r" + idx}>
                    <td>
                      {(() => {
                        const unidadesStr = sessionStorage.getItem("unidades");
                        if (!unidadesStr) return null;

                        const unidades = JSON.parse(unidadesStr);
                        const unidadeEncontrada = unidades?.find(
                          (d) => d.id === registro.unidade_id
                        );

                        return unidadeEncontrada
                          ? unidadeEncontrada.unidade
                          : null;
                      })()}
                    </td>
                    <td>
                      {registro.data
                        .toLocaleString("pt-BR")
                        .substring(0, 10)
                        .substring(8, 10) +
                        "/" +
                        registro.data
                          .toLocaleString("pt-BR")
                          .substring(0, 10)
                          .substring(5, 7) +
                        "/" +
                        registro.data
                          .toLocaleString("pt-BR")
                          .substring(0, 10)
                          .substring(0, 4)}
                    </td>
                    <td>{registro.centrodecusto_id}</td>
                    <td>{registro.historico}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {registro.debito === 0
                        ? registro.credito.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }) + " C"
                        : registro.debito.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }) + " D"}
                    </td>
                    <td
                      key={
                        anexosAtuais?.find(
                          (anexo) => anexo.drerazao_id === registro.id
                        )?.drerazao_id
                      }
                      style={{ textAlign: "center" }}
                    >
                      <OverlayTrigger
                        key={
                          anexosAtuais?.find(
                            (anexo) => anexo.drerazao_id === registro.id
                          )?.drerazao_id
                        }
                        overlay={
                          <Tooltip
                            id={idx.toString()}
                            style={{ zIndex: 100000 }}
                          >
                            {anexosAtuais?.find(
                              (anexo) => anexo.drerazao_id === registro.id
                            )
                              ? "Abrir Anexo"
                              : "Adicionar Anexo"}
                          </Tooltip>
                        }
                      >
                        <Button
                          key={
                            anexosAtuais?.find(
                              (anexo) => anexo.drerazao_id === registro.id
                            )?.drerazao_id
                          }
                          style={{
                            minWidth: "auto",
                            width: "40px",
                            height: "40px",
                            padding: "0px",
                            backgroundColor: anexosAtuais?.find(
                              (anexo) => anexo.drerazao_id === registro.id
                            )
                              ? "#3498db"
                              : "lightblue",
                          }}
                          onClick={() => {
                            anexosAtuais?.find(
                              (anexo) => anexo.drerazao_id === registro.id
                            )
                              ? window.open(
                                  anexosAtuais.find(
                                    (anexo) => anexo.drerazao_id === registro.id
                                  ).url
                                )
                              : setContaIdAnexoAtual(registro.id);
                            document.getElementById("anexoAtualRazao")?.click();
                          }}
                          variant="contained"
                          size="small"
                        >
                          <IoAttach />
                        </Button>
                      </OverlayTrigger>
                      <input
                        type="file"
                        hidden
                        id="anexoAtualRazao"
                        onChange={(e) => adicionaAnexoRazao()}
                      />
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            id={idx.toString()}
                            style={{ zIndex: 100000 }}
                          >
                            Ver comentarios!
                          </Tooltip>
                        }
                      >
                        <Button
                          style={{
                            minWidth: "auto",
                            width: "40px",
                            height: "40px",
                            padding: "0px",
                            marginRight: "10px",
                            marginLeft: "10px",
                            borderColor: "yellow",
                            backgroundColor: "gold",
                          }}
                          size="large"
                          variant="outlined"
                          id={"buttonComentarios-" + registro.id}
                          onClick={() => {
                            setModalComentariosShow([
                              true,
                              registro.historico,
                              registro,
                              registro.data
                                .toLocaleString("pt-BR")
                                .substring(0, 10)
                                .substring(5, 7),
                              "realizado",
                            ]);
                            setNivelContaAtual("analitica");
                          }}
                        >
                          <BiCommentDetail />
                          {comentarios.filter(
                            (d) =>
                              d.unidade_id === registro.unidade_id &&
                              registro.id === d.registrodrerazao_id &&
                              d.origem === "realizado"
                          ).length ? (
                            <Badge className="myOwnBadge" variant="standard">
                              {
                                comentarios.filter(
                                  (d) =>
                                    d.unidade_id === registro.unidade_id &&
                                    registro.id === d.registrodrerazao_id &&
                                    d.origem === "realizado"
                                ).length
                              }
                            </Badge>
                          ) : null}
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            id={idx.toString()}
                            style={{ zIndex: 100000 }}
                          >
                            Adicionar comentarios!
                          </Tooltip>
                        }
                      >
                        <Button
                          style={{
                            minWidth: "auto",
                            width: "40px",
                            height: "40px",
                            padding: "0px",
                            backgroundColor: "lightGreen",
                          }}
                          size="small"
                          id={"buttonAddComentarios-" + registro.id}
                          onClick={() => {
                            setNivelContaAtual("analitica");
                            setModalNovoComentarios([
                              true,
                              registro.historico,
                              registro,
                            ]);
                          }}
                        >
                          <BiCommentAdd />
                        </Button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <>
            {razaoData.length > 0 ? (
              <Table size="sm">
                <tbody>
                  <tr>
                    <td style={{ width: "70%" }}>
                      {equal(
                        modalShow,
                        razaoData.reduce(
                          (acc, cv) => (acc += cv.credito - cv.debito),
                          0
                        )
                      ) ? (
                        <b>TUDO EM ORDEM!</b>
                      ) : (
                        <b>ERRO!! Valores inconsistentes.</b>
                      )}
                    </td>
                    <th style={{ width: "10%", textAlign: "right" }}>TOTAL:</th>
                    <td style={{ width: "15%" }}>
                      {razaoData
                        .reduce((acc, cv) => (acc += cv.credito - cv.debito), 0)
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                    </td>
                    {equal(
                      modalShow,
                      razaoData.reduce(
                        (acc, cv) => (acc += cv.credito - cv.debito),
                        0
                      )
                    ) ? (
                      <td style={{ width: "5%", textAlign: "center" }}>
                        <BiCheckCircle color={"green"} />
                      </td>
                    ) : (
                      <td style={{ width: "5%", textAlign: "center" }}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              id={`tooltip-top`}
                              style={{ zIndex: 10000 }}
                            >
                              Valor esperado{" "}
                              {modalShow?.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </Tooltip>
                          }
                        >
                          <BiError color={"red"} />
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            ) : null}
          </>
        </>
      }
    />
  );
};

export default ModalRazao;
