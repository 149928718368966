import React from "react";
import ApexCharts from "react-apexcharts";
import { mockData } from "./mock.ts";

const ChartComponent = ({
  chartType,
  metric,
  height = "350",
  width = "350",
}) => {
  const formatValueForChart = (value) => {
    if (typeof value === "string") {
      if (value.includes("R$")) {
        return Number(value.replace(/[R$\.,]/g, "").replace(",", ".")) / 100;
      } else if (value.includes("%")) {
        return Number(value.replace("%", "")) / 100;
      }
    }
    return Number(value);
  };
  const processData = () => {
    if (chartType === "pie") {
      return mockData.map((hotel) => {
        let value = hotel.realizado[metric] || hotel.orcado[metric];
        return formatValueForChart(value);
      });
    } else {
      return mockData.map((hotel) => {
        let value = hotel.realizado[metric] || hotel.orcado[metric];
        return {
          x: hotel.nome,
          y: formatValueForChart(value),
        };
      });
    }
  };

  const chartData = processData();

  let series;
  let labels = [""];

  if (chartType === "pie") {
    series = chartData;
    labels = mockData.map((hotel) => hotel.nome);
  } else {
    series = [{ name: metric, data: chartData }];
  }

  const options = {
    chart: {
      type: chartType,
      height: height,
      width: width,
    },
    labels: chartType === "pie" ? labels : "",
  };

  return (
    <ApexCharts
      options={options}
      height={height}
      width={width}
      series={series}
      type={chartType}
    />
  );
};

export default ChartComponent;
