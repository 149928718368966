import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import DialogActions from "@material-ui/core/DialogActions";
import { StyledHeaderContainer } from "./styles.ts";
interface ModalGenericoProps {
  open: boolean;
  onClose: () => void;
  title: string;
  buttonContent?: React.ReactNode;
  dialogContent: React.ReactNode;
  buttonHeaderType: "contained" | "outlined" | "text" | undefined;
  width?: "lg" | "sm" | "md" | "xl" | "xs";
  isButtonHeaderDisabled?: boolean;
  footerButtonSubmit?: string | any;
  footerButtonClose?: string;
  onClickButton: () => void;
  onClickButtonSubmit?: () => void;
  searchInput?: React.ReactNode;
  buttonContentOptional?: React.ReactNode;
  showSearchIcon?: boolean;
  display?: string;
  buttonSubmitDisabled?: any;
}

const ModalGenerico: React.FC<ModalGenericoProps> = ({
  open,
  onClose,
  title,
  buttonHeaderType,
  buttonContent,
  dialogContent,
  isButtonHeaderDisabled,
  footerButtonClose = "Fechar",
  onClickButton,
  onClickButtonSubmit,
  searchInput,
  buttonContentOptional,
  showSearchIcon = false,
  footerButtonSubmit,
  width = "lg",
  display = "flex",
  buttonSubmitDisabled,
}) => {
  return (
    <Dialog
      style={{ height: "auto" }}
      fullWidth
      maxWidth={width}
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <StyledHeaderContainer>
          <div style={{ display: "flex", width: "100%", gap: 20 }}>
            <Button
              variant={buttonHeaderType}
              disabled={isButtonHeaderDisabled}
              color="primary"
              style={{
                backgroundColor: "#119E91",
                minWidth: "auto",
                width: "40px",
                height: "40px",
                padding: "0px",
                color: "white",
                display: display,
              }}
            >
              {buttonContent}
            </Button>
            {title}
          </div>
          {buttonContentOptional}
          {showSearchIcon && (
            <div
              style={{
                backgroundColor: "#f5f5f5",
                display: "flex",
                gap: "10px",
                borderRadius: "8px",
                alignItems: "center",
                padding: "5px",
                border: "1px solid",
              }}
            >
              <SearchIcon />
              {searchInput}
            </div>
          )}
        </StyledHeaderContainer>
      </DialogTitle>
      <DialogContent dividers={true}>{dialogContent}</DialogContent>
      <DialogActions>
        {footerButtonClose && (
          <Button color="secondary" variant="contained" onClick={onClickButton}>
            {footerButtonClose}
          </Button>
        )}
        {footerButtonSubmit && (
          <Button
            color="primary"
            variant="contained"
            onClick={onClickButtonSubmit}
            disabled={buttonSubmitDisabled}
          >
            {footerButtonSubmit}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalGenerico;
