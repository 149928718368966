import styled from "styled-components";

const Cards = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: 1rem 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 1rem;
`;

const CardBody = styled.div`
  padding: 1.25rem;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

type Card = typeof Cards & {
  Header: typeof CardHeader;
  Body: typeof CardBody;
};

const Card = Cards as Card;
Card.Header = CardHeader;
Card.Body = CardBody;

export default Card;
