import React, { useState } from "react";
import { FiDownload } from "react-icons/fi";
import api from "../../services/api";
import { useCookies } from "react-cookie";
import * as S from "./styles.ts";
import { Tooltip } from "@material-ui/core";
import JSZip from "jszip";
import { saveAs } from "file-saver";

interface File {
  link: string;
  filename: string;
}

const HistoricoDeCartas: React.FC = () => {
  const [cnpj, setCnpj] = useState<string>("");
  const [nfse, setNfse] = useState<string>("");
  const [files, setFiles] = useState<File[]>([]);
  const [cookies] = useCookies(["usuario"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<any>(5);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = files.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(files.length / itemsPerPage);

  const fetchHistorico = async () => {
    try {
      const response = await api.get(`/faturamento/petrobras/historico`, {
        params: { cnpj, nfse },
        headers: { Authorization: `Bearer ${cookies.usuario.accessToken}` },
      });
      if (Array.isArray(response.data.data)) {
        setFiles(response.data.data);
        setCurrentPage(1);
      } else {
        setFiles([]);
      }
    } catch (error) {
      console.error("Erro ao buscar histórico", error);
      setFiles([]);
    }
  };

  const handleCnpjChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCnpj(value);
  };

  const handleNfseChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNfse(value);
  };

  const handleDownloadClick = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  const downloadAllVisibleFiles = async () => {
    const zip = new JSZip();

    for (const file of currentItems) {
      const response = await fetch(file.link);
      const blob = await response.blob();
      zip.file(file.filename, blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "arquivos.zip");
    });
  };

  const numPagesToShow = 3;

  const pagination = () => {
    let pages: any = [];
    const startPage = Math.max(currentPage - numPagesToShow, 1);
    const endPage = Math.min(currentPage + numPagesToShow, totalPages);

    if (startPage > 1) {
      pages.push(
        <S.PageItem key={1} onClick={() => setCurrentPage(1)}>
          1
        </S.PageItem>
      );
      if (startPage > 2) {
        pages.push(<S.PageItem key="left-ellipsis">...</S.PageItem>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <S.PageItem
          key={i}
          onClick={() => setCurrentPage(i)}
          isActive={i === currentPage}
        >
          {i}
        </S.PageItem>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<S.PageItem key="right-ellipsis">...</S.PageItem>);
      }
      pages.push(
        <S.PageItem key={totalPages} onClick={() => setCurrentPage(totalPages)}>
          {totalPages}
        </S.PageItem>
      );
    }

    return pages;
  };

  return (
    <S.Container>
      <S.InputContainer>
        <S.Input
          type="text"
          placeholder="CNPJ"
          value={cnpj}
          onChange={handleCnpjChange}
        />
        <S.Input
          type="text"
          placeholder="NFSe"
          value={nfse}
          onChange={handleNfseChange}
        />
        <S.Button onClick={fetchHistorico}>Buscar Histórico</S.Button>
      </S.InputContainer>
      {files.length > 0 ? (
        <S.Table>
          <thead>
            <tr>
              <S.Th>
                <span>Arquivos para download</span>
                <S.ControlContainer>
                  <S.ItemsPerPageSelector
                    onChange={(e) => setItemsPerPage(e?.target?.value)}
                  >
                    {[5, 10, 15, 20, 30, 40, 50].map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                    <option value={files.length}>Todos: {files.length}</option>
                  </S.ItemsPerPageSelector>
                  <S.DownloadAllButton onClick={downloadAllVisibleFiles}>
                    Baixar Todos
                  </S.DownloadAllButton>
                </S.ControlContainer>
              </S.Th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((file, index) => (
              <S.Tr key={index}>
                <S.Td>
                  <Tooltip
                    title={`Baixar Arquivo ${file.filename}`}
                    placement="right"
                  >
                    <S.DownloadIconLink
                      onClick={() =>
                        handleDownloadClick(file.link, file.filename)
                      }
                    >
                      <FiDownload />
                    </S.DownloadIconLink>
                  </Tooltip>
                  <Tooltip
                    title={`Visualizar Arquivo ${file.filename}`}
                    placement="right"
                  >
                    <S.FileNameLink
                      href={file.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.filename}
                    </S.FileNameLink>
                  </Tooltip>
                </S.Td>
              </S.Tr>
            ))}
          </tbody>
          <S.PaginationContainer>{pagination()}</S.PaginationContainer>
        </S.Table>
      ) : (
        <p>Nenhum arquivo encontrado.</p>
      )}
    </S.Container>
  );
};

export default HistoricoDeCartas;
