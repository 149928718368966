import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { useCookies } from "react-cookie";
import "./styles.ts";
import Input from "../../components/inputs/input.tsx";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  StyledButtonContainer,
  StyledContainer,
  StyledContentContainer,
  StyledData,
  StyledTitle,
  StyledTypeButtonContainer,
  StyledTypeContainer,
} from "./styles.ts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autocomplete, TextField } from "@mui/material";

interface NovaAtualizacaoProps {
  mensagem: string;
  detalhes: {
    listaDetalhes: {
      modulo: string;
      informacoes: { tipo: string; informacao: string }[];
    }[];
  };
}

function adicionarIdAoNomeDaImagem(
  novaAtualizacao: NovaAtualizacaoProps
): NovaAtualizacaoProps {
  const novaAtualizacaoComId = { ...novaAtualizacao };
  novaAtualizacaoComId.detalhes.listaDetalhes.forEach((detalhe) => {
    detalhe.informacoes.forEach((info) => {
      if (info.tipo === "imagem" || info.tipo === "video") {
        const idUnico = gerarIdUnico();
        const extensao = info.informacao.split(".").pop();
        const nomeBase = info.informacao.replace(/\.[^/.]+$/, "");
        info.informacao = `${nomeBase}-${idUnico}.${extensao}`;
      }
    });
  });

  return novaAtualizacaoComId;
}

const notify = ($msg) =>
  toast($msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

function gerarIdUnico() {
  return Math.random().toString(36).substr(2, 9);
}

function atualizarNomesArquivos(
  novaAtualizacao: NovaAtualizacaoProps,
  arquivos: File[]
): File[] {
  const novosArquivos: File[] = [];
  let arquivoIndex = 0;

  novaAtualizacao.detalhes.listaDetalhes.forEach((detalhe) => {
    detalhe.informacoes.forEach((info) => {
      if (info.tipo === "imagem" || info.tipo === "video") {
        const arquivoAtual = arquivos[arquivoIndex];
        if (arquivoAtual) {
          const novoNome = info.informacao.split("/").pop() || ""; // Assume que info.informacao é uma URL
          const novoArquivo = new File([arquivoAtual], novoNome, {
            type: arquivoAtual.type,
          });
          novosArquivos.push(novoArquivo);
          arquivoIndex++;
        }
      }
    });
  });

  return novosArquivos;
}

const AdicionarAtualizacoesDoSistema = () => {
  const [cookies] = useCookies(["usuario"]);
  const data = new FormData();
  const [filesArr, setFilesArr] = useState<File[]>([]);
  const history = useHistory();
  const [modulosOptions, setModulosOptions] = useState([]);

  const openPage = (path) => {
    history.push(path);
  };
  const [inputFields, setInputFields] = useState([
    { modulo: "", informacoes: [] },
  ]);
  const [inputFieldMensagem, setInputFieldMensagem] = useState({
    mensagem: "",
  });

  const addNewField = () => {
    setInputFields([...inputFields, { modulo: "", informacoes: [] }]);
  };

  const addNewInfo = (index, tipo) => {
    const updatedFields = inputFields.map((field, i) => {
      if (i === index) {
        return {
          ...field,
          informacoes: [...field.informacoes, { tipo, informacao: "" }],
        };
      }
      return field;
    });
    setInputFields(updatedFields);
  };

  const removeField = (index) => {
    const newFields = inputFields.filter((_, i) => i !== index);
    setInputFields(newFields);
  };

  const handleInputFields = (index, infoIndex, e) => {
    const updatedFields = inputFields.map((field, i) => {
      if (i === index) {
        // Tratando a atualização do campo de módulo com um select
        if (infoIndex === null) {
          return { ...field, modulo: e.target.value };
        } else {
          // Tratando a atualização dos outros campos
          const updatedInfos = [...field.informacoes];
          if (e.target.type === "file") {
            setFilesArr([...filesArr, e.target.files[0]]);
            updatedInfos[infoIndex] = {
              ...updatedInfos[infoIndex],
              informacao: e.target.files[0].name,
            };
          } else {
            updatedInfos[infoIndex] = {
              ...updatedInfos[infoIndex],
              informacao: e.target.value,
            };
          }
          return { ...field, informacoes: updatedInfos };
        }
      }
      return field;
    });

    setInputFields(updatedFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let novaAtualizacao: NovaAtualizacaoProps = {
        mensagem: inputFieldMensagem.mensagem,
        detalhes: { listaDetalhes: inputFields },
      };
      novaAtualizacao = adicionarIdAoNomeDaImagem(novaAtualizacao);
      data.append("novaAtualizacao", JSON.stringify(novaAtualizacao));
      const newFilesNames = atualizarNomesArquivos(novaAtualizacao, filesArr);
      newFilesNames.forEach((element) => {
        data.append("files", element);
      });
      const response = await api.post("/realeasenotes", data, {
        headers: {
          Authorization: `Bearer ${cookies.usuario.accessToken}`,
        },
      });
      notify("Atualização adicionada com sucesso!");
      openPage("/atualizacoes");
      setFilesArr([]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const carregarModulos = async () => {
      try {
        const response = await api.get("/conteudos", {
          headers: {
            Authorization: `Bearer ${cookies.usuario.accessToken}`,
          },
        });
        setModulosOptions(response.data);
      } catch (error) {
        console.error("Erro ao carregar módulos:", error);
      }
    };

    carregarModulos();
  }, []);

  return (
    <StyledContainer>
      <StyledTitle>Adicionar Atualização do Sistema</StyledTitle>
      <StyledTypeContainer>
        <StyledData>Mensagem:</StyledData>
        <Input
          type="textArea"
          label="Mensagem"
          name="mensagem"
          value={inputFieldMensagem.mensagem}
          onChange={(e) => setInputFieldMensagem({ mensagem: e.target.value })}
        />
      </StyledTypeContainer>
      {inputFields?.map((field, index) => (
        <StyledContentContainer key={index}>
          <StyledTypeContainer>
            <StyledData>Módulo:</StyledData>
            <Autocomplete
              value={
                field?.modulo
                  ? modulosOptions.find(
                    (option) => option.paginaNome === field.modulo
                  )
                  : null
              }
              onChange={(event, newValue) => {
                handleInputFields(index, null, {
                  target: { value: newValue?.paginaNome },
                });
              }}
              options={modulosOptions}
              getOptionLabel={(option) => option.paginaNome}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Módulo"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </StyledTypeContainer>
          <StyledTypeButtonContainer>
            <Button
              variant="outlined"
              onClick={() => addNewInfo(index, "texto")}
            >
              Adicionar Texto
            </Button>
            <Button
              variant="outlined"
              onClick={() => addNewInfo(index, "imagem")}
            >
              Adicionar Imagem
            </Button>
            <Button
              variant="outlined"
              onClick={() => addNewInfo(index, "video")}
            >
              Adicionar Vídeo
            </Button>
            {field.informacoes.map((info, infoIndex) => (
              <div key={infoIndex}>
                {info.tipo === "texto" && (
                  <Input
                    label="Texto"
                    type="textArea"
                    value={info.informacao}
                    onChange={(e) => handleInputFields(index, infoIndex, e)}
                  />
                )}
                {info.tipo === "imagem" && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleInputFields(index, infoIndex, e)}
                  />
                )}
                {info.tipo === "video" && (
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleInputFields(index, infoIndex, e)}
                  />
                )}
              </div>
            ))}
          </StyledTypeButtonContainer>
          <StyledButtonContainer>
            <Button
              onClick={() => removeField(index)}
              variant="contained"
              color="secondary"
            >
              Remover Módulo
            </Button>
          </StyledButtonContainer>
        </StyledContentContainer>
      ))}
      <StyledButtonContainer>
        <Button onClick={addNewField} variant="contained" color="primary">
          Adicionar Módulo
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Adicionar Atualização
        </Button>
      </StyledButtonContainer>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        style={{ zIndex: 1000000 }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </StyledContainer>
  );
};

export default AdicionarAtualizacoesDoSistema;
