/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap'
import { HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi'
import { useCookies } from 'react-cookie';

import api from '../../services/api'

import './styles.css';

export default function EditaPermissoes() {

    const [cargos, setCargos] = useState([])
    const [conteudos, setConteudos] = useState([])
    const [permissaoConteudo, setPermissaoConteudo] = useState([])
    const [cargoSelected, setCargoSelected] = useState([])
    const [refresh, setrefresh] = useState({ cargos: false, paginas: false })
    const [cookies] = useCookies();

    useEffect(() => {
        loadcargos()
    }, [refresh.cargos])

    useEffect(() => {
        loadconteudos()
    }, [refresh.paginas])

    async function loadcargos() {
        await api.get(`/cargos/${cookies.usuario.permissao}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => setCargos(res.data));
    }

    async function loadconteudos() {
        await api.get('/conteudos', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => setConteudos(res.data));
    }

    async function handleCheckbox(conteudo_id) {
        if (permissaoConteudo.find(d => d.conteudo_id === conteudo_id)) {
            await api.delete(`/permissoes/${cargoSelected}/${conteudo_id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => setrefresh({ ...refresh, paginas: !refresh.paginas }))
        } else {
            await api.post(`/permissoes`, {
                cargo_id: cargoSelected,
                conteudo_id: conteudo_id
            }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => setrefresh({ ...refresh, paginas: !refresh.paginas }))
        }
    }

    async function handleCargo(id) {
        setCargoSelected(id)
        const permissoesCargo = await api.get(`/permissoes/${id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setPermissaoConteudo(permissoesCargo.data)
    }

    async function handlenovocargo() {
        await api.post('/cargos', {
            cargo: document.getElementById('novoCargoId').value,
            usuario_id: cookies.usuario.id
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
            setCargos([...cargos, ...res.data.cargos])
            document.getElementById('novoCargoId').value = null
        })
    }
    async function deletecargo(id) {
        await api.delete(`/cargos/${id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => setrefresh({ ...refresh, cargos: !refresh.cargos }))
    }

    return (
        <div className="analytics-container">
            <Container fluid>
                <Row xs={1} md={3} lg={3}>
                    <Col>
                        <div className='form-label col-form-label'>Cargos:</div>
                        {cargos.map(cargo => {
                            // if (cargo.id === 0) {
                            //     return;
                            // }
                            return (
                                <InputGroup className="mb-3">
                                    <Button className='form-control' onClick={() => handleCargo(cargo.id)} style={{ backgroundColor: "white", color: "#495057", display: 'inline-flex', border: 'gray' }}>{cargo.cargo}</Button>
                                    <Button onClick={() => deletecargo(cargo.id)} variant="danger">
                                        <HiOutlineTrash />
                                    </Button>
                                </InputGroup>)
                        })}
                        <div className='form-label col-form-label'>Novo cargo:</div>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Novo Cargo"
                                style={{ backgroundColor: "white" }}
                                id='novoCargoId'
                            />
                            <Button variant="primary" onClick={handlenovocargo}>
                                <HiOutlinePlus />
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col>
                        <div className='form-label col-form-label'>Paginas:</div>
                        {conteudos.map((conteudo, idx) => {
                            return (
                                <InputGroup id={idx} className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Checkbox
                                            checked={permissaoConteudo.find(d => d.conteudo_id === conteudo.id)}
                                            onChange={(e) => { handleCheckbox(conteudo.id); }}
                                        />
                                    </InputGroup.Prepend>
                                    <FormControl disabled style={{ backgroundColor: "white" }} value={conteudo.paginaNome} />
                                </InputGroup>
                            )
                        })}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}