import React from "react";
import DreRealizadoXRealizadoWeb from "./web/index.tsx";
import { Desktop } from "../../revenueManager/styles.ts";
import { Mobile } from "./styles.ts";
import DreRealizadoXRealizadoMobile from "./mobile/index.tsx";

const DreRealizadoXRealizado: React.FC = () => {
  return (
    <>
      <Desktop>
        <DreRealizadoXRealizadoWeb />
      </Desktop>
    </>
  );
};

export default DreRealizadoXRealizado;
