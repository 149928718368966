import { Checkbox, ListItemText } from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import React from "react";

const QuestionItem = ({ item, onCheckChange, isChecked }) => {
  const handleChange = () => {
    onCheckChange({
      id: item.id,
      pergunta: item.pergunta,
      ordem: item.number,
      quadrante: item.quadrante,
    });
  };

  return (
    <ListItemButton
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
        borderBottom: "1px solid #ddd",
        backgroundColor: "#fff",
        marginBottom: "5px",
      }}
    >
      <ListItemText
        primary={item.number + " - " + item.pergunta}
        style={{ flex: 1 }}
      />

      <Checkbox
        edge="start"
        checked={isChecked}
        onChange={handleChange}
        inputProps={{ "aria-labelledby": "checkbox-list-label-" + item.id }}
        style={{ color: isChecked ? "#119e91" : undefined }}
      />
    </ListItemButton>
  );
};

export default QuestionItem;
