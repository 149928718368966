import React, { useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface Props {
  onClick: () => void;
  id: string;
}

export const HiddenButton: React.FC<Props> = ({ onClick, id }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>Ocultar coluna?</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={popover}>
      <Button
        onClick={onClick}
        style={{ float: "right" }}
        id={id}
        variant="outline-primary"
        size="sm"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovering ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
      </Button>
    </OverlayTrigger>
  );
};
