import api from "../../../../services/api";

interface openRazaoType {
  codigo: number | string;
  unidadeId: number | string;
  type: any;
  valor: number | string;
  cookies: any;
  mes: number | string;
  ano: number | string;
  mesFinal: number | string;
  dispatch: any;
  conta: any;
  setRazaoData: (any) => void;
  setAnexosRazao: (any) => void;
  setModalShow: (any) => void;
  notify: (any) => void;
  setRazaoMemory: (any) => void;
}

export async function openRazao({
  codigo,
  unidadeId,
  type = "combos",
  valor,
  cookies,
  mes,
  ano,
  mesFinal,
  dispatch,
  setRazaoData,
  setAnexosRazao,
  setModalShow,
  setRazaoMemory,
  conta,
  notify,
}: openRazaoType) {
  dispatch({
    type: "OPEN_LOADING",
    text: "Buscando registros...",
    isLoading: true,
  });

  if (type === "combos") {
    await api
      .get(
        `/registrorazaomes/${unidadeId}/${codigo}/${mes}/${ano}/${mesFinal}?usuario_id=${cookies.usuario.id}`,
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      )
      .then((res) => {
        if (res.data.razao.length) {
          setRazaoData(res.data.razao);
          setRazaoMemory(res.data.razao);
          setAnexosRazao(res.data.anexosRazao);
          setModalShow([true, conta, valor]);
          dispatch({ type: "CLOSE_LOADING", text: "", isLoading: false });
        } else {
          dispatch({ type: "CLOSE_LOADING", text: "", isLoading: false });
          notify("Nenhum lançamento registrado!");
        }
      });
  } else {
    await api
      .get(
        `/registrorazaomes/${unidadeId}/${codigo}/${mes}/${ano}/${mesFinal}?usuario_id=${cookies.usuario.id}`,
        { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
      )
      .then((res) => {
        if (res.data.razao.length) {
          setRazaoData(res.data.razao);
          setAnexosRazao(res.data.anexosRazao);
          setModalShow([true, conta.descricao, valor]);
          dispatch({ type: "CLOSE_LOADING", text: "", isLoading: false });
        } else {
          dispatch({ type: "CLOSE_LOADING", text: "", isLoading: false });
          notify("Nenhum lançamento registrado!");
        }
      });
  }
}

export default openRazao;
