/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { Container, Row, Col, Button, Modal, InputGroup, FormControl } from 'react-bootstrap'
import {MultiSelect} from "react-multi-select-component";
import DatePicker from "react-datepicker";

import { useCookies } from 'react-cookie';
// Icons
import { BiFilterAlt } from 'react-icons/bi'

// api
import api from '../../services/api'

// import { Container } from './styles';

// redux
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: '10px'
    },
    container: {
        maxHeight: '80vh',
    }
});

function Forecast() {

    const [cookies] = useCookies();
    const dispatch = useDispatch()
    const [filtro, setFiltro] = useState([
        { id: 'unidade', hidden: false },
        { id: 'data', hidden: false },
        { id: 'NO_ROOMS', hidden: false },
        { id: 'ARRIVAL_ROOMS', hidden: false },
        { id: 'COMPLIMENTARY_ROOMS', hidden: false },
        { id: 'HOUSE_USE_ROOMS', hidden: false },
        { id: 'IND_DEDUCT_ROOMS', hidden: true },
        { id: 'IND_NON_DEDUCT_ROOMS', hidden: true },
        { id: 'GRP_DEDUCT_ROOMS', hidden: true },
        { id: 'GRP_NON_DEDUCT_ROOMS', hidden: true },
        { id: 'CF_OCCUPANCY', hidden: false },
        { id: 'REVENUE', hidden: false },
        { id: 'IND_DEDUCT_REVENUE', hidden: true },
        { id: 'IND_NON_DEDUCT_REVENUE', hidden: true },
        { id: 'GRP_DEDUCT_REVENUE', hidden: true },
        { id: 'GRP_NON_DEDUCT_REVENUE', hidden: true },
        { id: 'CF_AVERAGE_ROOM_RATE', hidden: false },
        { id: 'DEPARTURE_ROOMS', hidden: false },
        { id: 'DAY_USE_ROOMS', hidden: false },
        { id: 'NO_SHOW_ROOMS', hidden: true },
        { id: 'CF_OOO_ROOMS', hidden: false },
        { id: 'CF_CALC_INV_ROOMS', hidden: true },
        { id: 'NO_PERSONS', hidden: false },
    ])
    const [filtroMemory] = useState([
        { id: 'unidade', hidden: false },
        { id: 'data', hidden: false },
        { id: 'NO_ROOMS', hidden: false },
        { id: 'ARRIVAL_ROOMS', hidden: false },
        { id: 'COMPLIMENTARY_ROOMS', hidden: false },
        { id: 'HOUSE_USE_ROOMS', hidden: false },
        { id: 'IND_DEDUCT_ROOMS', hidden: true },
        { id: 'IND_NON_DEDUCT_ROOMS', hidden: true },
        { id: 'GRP_DEDUCT_ROOMS', hidden: true },
        { id: 'GRP_NON_DEDUCT_ROOMS', hidden: true },
        { id: 'CF_OCCUPANCY', hidden: false },
        { id: 'REVENUE', hidden: false },
        { id: 'IND_DEDUCT_REVENUE', hidden: true },
        { id: 'IND_NON_DEDUCT_REVENUE', hidden: true },
        { id: 'GRP_DEDUCT_REVENUE', hidden: true },
        { id: 'GRP_NON_DEDUCT_REVENUE', hidden: true },
        { id: 'CF_AVERAGE_ROOM_RATE', hidden: false },
        { id: 'DEPARTURE_ROOMS', hidden: false },
        { id: 'DAY_USE_ROOMS', hidden: false },
        { id: 'NO_SHOW_ROOMS', hidden: true },
        { id: 'CF_OOO_ROOMS', hidden: false },
        { id: 'CF_CALC_INV_ROOMS', hidden: true },
        { id: 'NO_PERSONS', hidden: false },
    ])

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(11);
    const [rows, setRows] = useState([])
    const [rowsMemory, setRowsMemory] = useState([])
    const [unidades, setUnidades] = useState([])
    const [MSLoading, setMSLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [modalFilterShow, setModalFilterShow] = useState([false]);
    const [datasPassadas, setDatasPassadas] = useState({ datasPassadas: false })
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const columns = [
        { id: 'unidade', label: 'Unidade', minWidth: 200, },
        { id: 'data', label: 'Data', minWidth: 70, format: (value) => new Date(value).toLocaleDateString('pt-br'), },
        { id: 'NO_ROOMS', label: 'Total OCC.', minWidth: 70, align: 'right', },
        { id: 'ARRIVAL_ROOMS', label: 'Arr. Rooms', minWidth: 70, align: 'right', },
        { id: 'COMPLIMENTARY_ROOMS', label: 'Comp. Rooms', minWidth: 70, align: 'right', },
        { id: 'HOUSE_USE_ROOMS', label: 'House Use', minWidth: 70, align: 'right', },
        { id: 'IND_DEDUCT_ROOMS', label: 'Deduct Indv.', minWidth: 70, align: 'right', },
        { id: 'IND_NON_DEDUCT_ROOMS', label: 'Non-Ded Indv.', minWidth: 70, align: 'right', },
        { id: 'GRP_DEDUCT_ROOMS', label: 'Deduct Group.', minWidth: 70, align: 'right', },
        { id: 'GRP_NON_DEDUCT_ROOMS', label: 'Non-Ded Group.', minWidth: 70, align: 'right', },
        { id: 'CF_OCCUPANCY', label: 'Occ. %', minWidth: 70, align: 'right', format: (value) => (parseFloat(value) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 }), },
        { id: 'REVENUE', label: 'Room Revenue', minWidth: 70, align: 'right', format: (value) => parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), },
        { id: 'IND_DEDUCT_REVENUE', label: 'Ded. Ind. Revenue', minWidth: 70, align: 'right', format: (value) => parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), },
        { id: 'IND_NON_DEDUCT_REVENUE', label: 'Non-Ded Ind. Revenue', minWidth: 70, align: 'right', format: (value) => parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), },
        { id: 'GRP_DEDUCT_REVENUE', label: 'Ded. GRP. Revenue', minWidth: 70, align: 'right', format: (value) => parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), },
        { id: 'GRP_NON_DEDUCT_REVENUE', label: 'Non-Ded. GRP. Revenue', minWidth: 70, align: 'right', format: (value) => parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), },
        { id: 'CF_AVERAGE_ROOM_RATE', label: 'Average Rate', minWidth: 70, align: 'right', format: (value) => parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), },
        { id: 'DEPARTURE_ROOMS', label: 'Dep. Rooms', minWidth: 70, align: 'right', },
        { id: 'DAY_USE_ROOMS', label: 'Day Use Rooms', minWidth: 70, align: 'right', },
        { id: 'NO_SHOW_ROOMS', label: 'No Show Rooms', minWidth: 70, align: 'right', },
        { id: 'CF_OOO_ROOMS', label: 'OOO Rooms', minWidth: 70, align: 'right', },
        { id: 'CF_CALC_INV_ROOMS', label: 'Inventory Rooms', minWidth: 70, align: 'right', },
        { id: 'NO_PERSONS', label: 'Adl. & Chl.', minWidth: 70, align: 'right', },
    ];

    useEffect(() => {
        filtraUnidades()
    }, [selected])

    useEffect(() => {
        var array = []
        JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
            array.push({ label: unidade.unidade, value: unidade.id })
        })
        setMSLoading(false)
        setUnidades(array)
        getData()
    }, [MSLoading, sessionStorage.getItem('unidadesMemory')])

    useEffect(() => {
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function filtraUnidades() {
        setPage(0);
        if (selected.length) {
            let myFiltro = []
            if (!datasPassadas.datasPassadas) {
                rowsMemory.filter(d => new Date(d.data) >= new Date()).reduce((acc, valAtual) => {
                    if (selected.find(d => d.value === valAtual.unidade_id)) {
                        return myFiltro.push(valAtual)
                    }
                })
            } else {
                rowsMemory.reduce((acc, valAtual) => {
                    if (selected.find(d => d.value === valAtual.unidade_id)) {
                        return myFiltro.push(valAtual)
                    }
                })
            }
            if (startDate && endDate) {
                setRows(myFiltro.filter(d => new Date(d.data).getTime() >= startDate.getTime() && new Date(d.data).getTime() <= endDate.getTime()))
            } else {
                setRows(myFiltro)
            }
        } else {
            if (datasPassadas.datasPassadas) {
                setRows(rowsMemory)
            } else if (startDate && endDate) {
                setRows(rowsMemory.filter(d => new Date(d.data).getTime() >= startDate.getTime() && new Date(d.data).getTime() <= endDate.getTime()))
            } else {
                setRows(rowsMemory.filter(d => new Date(d.data) >= new Date()))
            }
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function getData() {
        dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: true })
        var unidadesConsultar = []

        unidades.map(async unidade => {
            unidadesConsultar.push(unidade.value)
        })
        const allData = await api.get(`/registrosforecastcontroladoria/${cookies.usuario.id}`,{headers:{'authorization': `Bearer ${cookies.usuario.accessToken}`}})
        setRows(allData.data.filter(d => new Date(d.data) >= new Date()))
        setRowsMemory(allData.data)
        dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: false })
    }

    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setDatasPassadas({ ...datasPassadas, datasPassadas: false });
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setPage(0)
            setRows(rows.filter(d => new Date(d.data).getTime() >= start.getTime() && new Date(d.data).getTime() <= end.getTime()))
        }
    };

    function filtraPassados() {
        setDatasPassadas({ ...datasPassadas, datasPassadas: !datasPassadas.datasPassadas });
        setStartDate(null);
        setEndDate(null);
        if (!datasPassadas.datasPassadas) {
            if (selected.length) {
                let myFiltro = []
                rowsMemory.reduce((acc, valAtual) => {
                    if (selected.find(d => d.value === valAtual.unidade_id)) {
                        return myFiltro.push(valAtual)
                    }
                })
                setRows(myFiltro)
            } else {
                setRows(rowsMemory)
            }
        } else {
            if (selected.length) {
                let myFiltro = []
                rowsMemory.filter(d => new Date(d.data) >= new Date()).reduce((acc, valAtual) => {
                    if (selected.find(d => d.value === valAtual.unidade_id)) {
                        return myFiltro.push(valAtual)
                    }
                })
                setRows(myFiltro)
            } else {
                setRows(rowsMemory.filter(d => new Date(d.data) >= new Date()))
            }
        }
    }

    return (
        <>
            <Container fluid>
                <Row style={{ height: '100%' }}>
                    <Col style={{ zIndex: 100, alignSelf: 'center' }}>
                        <MultiSelect
                            options={unidades}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Select"
                            className="MultiSelect"
                            isLoading={MSLoading}
                            overrideStrings={{
                                "allItemsAreSelected": "Todos as unidades selecionadas.",
                                "clearSearch": "Limpar busca",
                                "noOptions": "Sem opções",
                                "search": "Buscar",
                                "selectAll": "Selecionar Todas",
                                "selectSomeItems": "Escolha as unidades..."
                            }}
                        />
                    </Col>
                    <Col style={{ textAlign: 'right', alignSelf: 'center' }}><Button variant="dark" onClick={() => setModalFilterShow([true])}><BiFilterAlt /></Button></Col>
                </Row>
            </Container>
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        hidden={filtro ? filtro.find(d => d.id === column.id).hidden : null}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} hidden={filtro ? filtro.find(d => d.id === column.id).hidden : null}>
                                                    {column.format ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[11, 33, 110]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Modal
                show={modalFilterShow[0]}
                // show={true}
                onHide={() => setModalFilterShow([false])}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='lg'
                scrollable
            >
                <Modal.Header closeButton style={{ gap: '15px' }}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="danger" style={{ marginRight: '8px' }} disabled><BiFilterAlt /></Button> Filtro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row><Col>Diversos:</Col></Row>
                    <Row sm={1} md={2} lg={2} xl={2} >
                        <Col>
                            <InputGroup style={{ marginBottom: '10px' }}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={datasPassadas.datasPassadas} onChange={() => {
                                    filtraPassados()
                                }} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent' }} aria-label="Text input with checkbox" value={'Mostrar Datas passadas?'} disabled />
                            </InputGroup>
                        </Col>
                        <Col style={{ zIndex: 100, }}>
                            Periodo:
                            <DatePicker
                                selected={startDate}
                                onChange={onChangeDate}
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                shouldCloseOnSelect={false}
                                selectsRange
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </Col>
                    </Row>
                    <Row><Col>Colunas:</Col></Row>
                    <Row sm={1} md={2} lg={2} xl={3} >
                        {columns.map(column => {
                            return (
                                <Col>
                                    <InputGroup style={{ marginBottom: '10px' }}>
                                        <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.find(d => d.id === column.id).hidden} onChange={() => setFiltro([...filtro,
                                        filtro[filtro.findIndex(d => d.id === column.id)].hidden = !filtro[filtro.findIndex(d => d.id === column.id)].hidden
                                        ])} />
                                        <FormControl style={{ border: 'none', backgroundColor: 'transparent' }} aria-label="Text input with checkbox" value={column.label} disabled />
                                    </InputGroup>
                                </Col>)
                        })}
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    <Button size='sm' variant='link' onClick={() => {
                        setFiltro(filtroMemory);
                        setRows(rowsMemory.filter(d => new Date(d.data) >= new Date()));
                        setDatasPassadas({ ...datasPassadas, datasPassadas: false });
                        setStartDate(null);
                        setEndDate(null);
                        setSelected([])
                        setPage(0)
                    }}>Resetar Filtros</Button>
                    <Button size='sm' onClick={() => setModalFilterShow([false])}>Filtrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Forecast;