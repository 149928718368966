import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { Action, Fab } from "react-tiny-fab";

import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { Col, Container, Modal, Row } from 'react-bootstrap';

import { makeStyles } from '@material-ui/core/styles';

import { BiTargetLock } from 'react-icons/bi';
import { FormControlLabel, IconButton, ListItemButton, Skeleton, Switch, TextField } from '@mui/material';

import api from '../../../services/api';
import { useCookies } from 'react-cookie';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Tab, Tabs } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { TbPencilQuestion } from "react-icons/tb";
import { BsPatchQuestion } from "react-icons/bs";
import { TbDevicesQuestion } from "react-icons/tb";
import ModalHerancaPerguntas from '../../../components/modals/models/modalHerancaPerguntas/modalHerancaPerguntas.tsx'


const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#e8   e8e8',
        }
    },
    icon: {
        fontSize: '2em',
        color: '#119e91',
    },
    text: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        color: '#333',
    },
    small: {
        width: 'auto',
        height: 'auto',
        maxWidth: '70%',
        maxHeight: '90%',
    },
    iconSpace: {
        background: 'yellow',
        width: '60px',
        height: '60px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleSpace: {
        // margin: '-30px 30px',
        fontSize: '25px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        gap: '15px',
        zIndex: 10,
        position: 'fixed',
        left: '90px',
    },
    iconSpace2: {
        background: 'yellow',
        width: '60px',
        height: '60px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleSpace2: {
        margin: '-30px 30px',
        fontSize: '25px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        gap: '15px',
        zIndex: 10,
    },
    photoCol: {
        display: 'flex',
        justifyContent: 'center',
        height: '80vh',
        alignItems: 'center',
    },
    input: {
        marginTop: '10px !important',
        marginBottom: '10px !important',
        width: '100%',
    },
    card: {
        background: 'white',
        display: 'flex',
        flexWrap: 'wrap',
        padding: 0,
        margin: 'calc(5vh - 10px) 0',
        borderRadius: '8px',
        boxShadow: '3px 3px 10px gray',
        height: 'auto',
        width: '96vw',
    },
    fabPos: {
        position: 'fixed !important',
        bottom: '16px',
        right: '16px',
    },
    subtitle: {
        fontSize: 'small',
        color: 'gray',
        fontWeight: 'normal',
        fontStyle: 'italic',
    },
}));

const sortArr = (arr, col) => { return arr.sort((a, b) => a[col] < b[col] ? -1 : a[col] > b[col] ? 1 : 0) }

function PDI() {
    const [cookies] = useCookies([]);
    const classes = useStyles();
    const [open, toggleOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [unidades, setUnidades] = useState([])
    const [unidadesModal, setUnidadesModal] = useState([])
    const [unidadesSelected, setUnidadesSelected] = useState([])
    // const [departamentosSelected, setDepartamentosSelected] = useState([])
    const [cargosSelected, setCargosSelected] = useState([])
    const [unidadesSelectedBusca, setUnidadesSelectedBusca] = useState([])
    const [unidadesSelectedBuscaModal, setUnidadesSelectedBuscaModal] = useState([])
    const [unidadesSelectedBuscaDestino, setUnidadesSelectedBuscaDestino] = useState([])
    // const [departamentosSelectedBusca, setDepartamentosSelectedBusca] = useState([])
    const [cargosSelectedBusca, setCargosSelectedBusca] = useState([])
    const [cargosSelectedBuscaModal, setCargosSelectedBuscaModal] = useState([])
    const [cargosSelectedBuscaDestino, setCargosSelectedBuscaDestino] = useState([])
    const [typePergunta,] = useState('multipla_escolha')
    const [quadrantePergunta, setQuadrantePergunta] = useState('Competencias Organizacionais')
    const [checked, setChecked] = useState([]);
    const [inativos, setInativos] = useState(false)
    const [perguntas, setPerguntas] = useState([])
    const [perguntasModal, setPerguntasModal] = useState([])
    // const [departamentos, setDepartamentos] = useState([])
    const [cargos, setCargos] = useState([])
    const [valueTab, setValueTab] = useState(0);
    const [loadPerguntas, setLoadPerguntas] = useState(false);

    const handleChangeTab = (event, newValue) => { setValueTab(newValue); };
    const handleClose = () => { toggleOpen(false); };
    const handleOpen = () => { toggleOpen(true); };

    useEffect(() => {

        var filtrarUnidades = sortArr(JSON.parse(sessionStorage.getItem('unidades')), 'unidade')?.reduce((acc, cv) => acc = [...acc, { label: cv.unidade, value: cv.unidade_id }], [])

        setUnidades(filtrarUnidades)
        setUnidadesSelectedBusca(filtrarUnidades[0])
        setUnidadesSelectedBuscaModal(filtrarUnidades[0])
        setUnidadesSelectedBuscaDestino(filtrarUnidades[0])
        setUnidadesModal(filtrarUnidades)
        preload()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = (value, id) => async () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        await api.put('/rh/pdi/pergunta/status', { id }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        await handleBuscarPerguntas()

        setChecked(newChecked);
    };

    const preload = async () => {

        var cargos = await api.get('/rh/cargos/' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        // var departamentos = await api.get('/rh/departamentos/' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        // setDepartamentos(departamentos.data.reduce((acc, cv) => acc = [...acc, { label: cv.departamento, value: cv.id, id: cv.id }], []))
        setCargos(cargos.data.reduce((acc, cv) => acc = [...acc, { label: cv.cargo, value: cv.id, id: cv.id }], []))

    }

    const handleNovaPergunta = async () => {
        const ordem = document.getElementById('nova_pergunta_ordem').value
        const pergunta = document.getElementById('nova_pergunta_pergunta').value
        document.getElementById('nova_pergunta_pergunta').value = ''
        const unidadesIds = unidadesSelected.reduce((acc, cv) => acc = [...acc, cv.value], [])
        const cargosIds = cargosSelected.reduce((acc, cv) => acc = [...acc, cv.value], [])
        if (!ordem || !pergunta || !unidadesIds.length || !cargosIds.length) return alert('Preencha todos os campos!')

        const response = await api.post('/rh/pdi/pergunta', { ordem, pergunta, unidadesIds, cargosIds, type: typePergunta, quadrante: quadrantePergunta }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            .then(async (response) => {
                await handleBuscarPerguntas()
            })
    }

    const handleBuscarPerguntas = async () => {
        var perguntas = await api.get('/rh/pdi/perguntas?unidade_id=' + unidadesSelectedBusca.value + '&cargo_id=' + cargosSelectedBusca?.id + '&includeInativos=' + inativos, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setChecked([])
        if (inativos) setChecked(perguntas.data.filter(p => !p.is_active).map(p => p.quadrante + '-' + p.number))
        return setPerguntas(perguntas.data) && setPerguntasModal(perguntas.data)

    }

    const handleOrder = async (type, item, item2) => {
        const number = item.number
        const number2 = item2.number
        const id = item.id
        const id2 = item2.id

        await api.put('/rh/pdi/pergunta/ordem', { type, number, number2, id, id2 }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        await handleBuscarPerguntas()
    }
    const matches = useMediaQuery('(max-width:680px)');


    return (
        <>

            <Container
                fluid
                className={classes.card}
            >
                <div className={classes.titleContainer}>
                    <div style={{ alignSelf: "baseline" }} className={classes.icon}>
                        <BiTargetLock />
                    </div>
                    <div className={classes.text}>
                        Plano de Desenvolvimento Individual
                    </div>
                </div>
                <Row style={{ width: '100%', margin: ' 50px 0 0 0' }}>
                    <Col md={3}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <Select
                                className={classes.input}
                                options={unidades}
                                placeholder="Escolha a Unidade..."
                                closeMenuOnSelect={true}
                                onChange={setUnidadesSelectedBusca}
                                value={unidadesSelectedBusca}
                            />
                            {/* <Select
                                className={classes.input}
                                options={departamentos}
                                placeholder="Escolha o departamento..."
                                closeMenuOnSelect={true}
                                onChange={setDepartamentosSelectedBusca}
                                isClearable={true}
                                value={departamentosSelectedBusca}
                            /> */}
                            <Select
                                className={classes.input}
                                options={cargos}
                                placeholder="Escolha o cargo..."
                                closeMenuOnSelect={true}
                                onChange={setCargosSelectedBusca}
                                isClearable={true}
                                value={cargosSelectedBusca}
                            />
                            <FormControlLabel control={<Switch />} onClick={() => setInativos(!inativos)} label="Inativos" />
                            <button style={matches ? { width: "100%", backgroundColor: '#119e91' } : { width: "40%", backgroundColor: '#119e91' }} className="btn btn-primary" onClick={handleBuscarPerguntas}>Buscar</button>
                        </div>
                    </Col>
                    <Col style={{ width: '100%', overflow: 'auto', height: '80vh' }}>
                        {perguntas.length > 0
                            ? <>
                                <Tabs
                                    value={valueTab}
                                    onChange={handleChangeTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    style={{
                                        backgroundColor: '#f4f4f4',
                                        borderRadius: '5px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                                    }}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: '#119e91',
                                            height: '3px',
                                        }
                                    }}
                                >
                                    <Tab label="Competencias Organizacionais" style={{ textTransform: 'none', color: '#119e91' }} />
                                    <Tab label="Dominio Tecnico" style={{ textTransform: 'none', color: '#119e91' }} />
                                </Tabs>
                                <List hidden={valueTab !== 0} style={{ padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                                    {perguntas.filter(p => p.number.split('.').length === 1 && p.quadrante === 'Competencias Organizacionais').map((pergunta) => (
                                        <li key={`section-${pergunta.id}`} style={{ marginBottom: '15px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                <h6 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>
                                                    {pergunta.number + ' - ' + pergunta.pergunta}
                                                </h6>
                                                {perguntas.filter(p2 => p2.number.split('.')[0] === pergunta.number && p2.number.split('.').length > 1 && p2.quadrante === 'Competencias Organizacionais').map((item, idx, arrat) => (
                                                    <ListItemButton key={`item-${item.id}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', marginBottom: '5px' }}>
                                                        <ListItemText primary={item.number + ' - ' + item.pergunta} style={{ flex: 1 }} />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Switch
                                                                edge="end"
                                                                onChange={handleToggle(item.quadrante + '-' + item.number, item.id)}
                                                                checked={checked.indexOf(item.quadrante + '-' + item.number) !== -1}
                                                                inputProps={{
                                                                    'aria-labelledby': 'switch-list-label-' + item.number,
                                                                }}
                                                                style={{ marginRight: '10px' }}
                                                            />
                                                            <IconButton
                                                                disabled={idx === arrat.length - 1}
                                                                edge="end"
                                                                aria-label="move down"
                                                                onClick={() => handleOrder('down', item, arrat[idx + 1])}
                                                                style={{ color: idx === arrat.length - 1 ? '#ddd' : '#000' }}
                                                            >
                                                                <BsChevronDown />
                                                            </IconButton>
                                                            <IconButton
                                                                disabled={idx === 0}
                                                                edge="end"
                                                                aria-label="move up"
                                                                onClick={() => handleOrder('up', item, arrat[idx - 1])}
                                                                style={{ color: idx === 0 ? '#ddd' : '#000' }}
                                                            >
                                                                <BsChevronUp />
                                                            </IconButton>
                                                        </div>
                                                    </ListItemButton>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </List>

                                <List hidden={valueTab !== 1} style={{ padding: '10px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
                                    {perguntas.filter(p => p.number.split('.').length === 1 && p.quadrante === 'Dominio Tecnico').map((pergunta) => (
                                        <li key={`section-${pergunta.id}`} style={{ marginBottom: '15px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                <h6 style={{ color: '#119e91', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>
                                                    {pergunta.number + ' - ' + pergunta.pergunta}
                                                </h6>
                                                {perguntas.filter(p2 => p2.number.split('.')[0] === pergunta.number && p2.number.split('.').length > 1 && p2.quadrante === 'Dominio Tecnico').map((item, idx, arrat) => (
                                                    <ListItemButton key={`item-${item.id}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', marginBottom: '5px' }}>
                                                        <ListItemText primary={item.number + ' - ' + item.pergunta} style={{ flex: 1 }} />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Switch
                                                                edge="end"
                                                                onChange={handleToggle(item.quadrante + '-' + item.number, item.id)}
                                                                checked={checked.indexOf(item.quadrante + '-' + item.number) !== -1}
                                                                inputProps={{
                                                                    'aria-labelledby': 'switch-list-label-' + item.number,
                                                                }}
                                                                style={{ marginRight: '10px' }}
                                                            />
                                                            <IconButton
                                                                disabled={idx === arrat.length - 1}
                                                                edge="end"
                                                                aria-label="comments"
                                                                onClick={() => handleOrder('down', item, arrat[idx + 1])}
                                                                style={{ color: idx === arrat.length - 1 ? '#ddd' : '#000' }}
                                                            >
                                                                <BsChevronDown />
                                                            </IconButton>
                                                            <IconButton
                                                                disabled={idx === 0}
                                                                edge="end"
                                                                aria-label="comments"
                                                                onClick={() => handleOrder('up', item, arrat[idx - 1])}
                                                                style={{ color: idx === 0 ? '#ddd' : '#000' }}
                                                            >
                                                                <BsChevronUp />
                                                            </IconButton>
                                                        </div>
                                                    </ListItemButton>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </List>
                            </>
                            : <>
                                <Skeleton animation="wave" width={'50%'} />
                                <Skeleton animation="wave" width={'40%'} />
                                <Skeleton animation="wave" width={'35%'} />
                                <Skeleton animation="wave" width={'55%'} />
                                <Skeleton animation="wave" width={'45%'} />
                                <Skeleton animation="wave" width={'46%'} />
                                <Skeleton animation="wave" width={'50%'} />
                                <Skeleton animation="wave" width={'50%'} />
                                <Skeleton animation="wave" width={'30%'} />
                                <Skeleton animation="wave" width={'35%'} />
                                <Skeleton animation="wave" width={'36%'} />
                            </>
                        }

                    </Col>
                </Row>
                <Fab
                    icon={<TbPencilQuestion />}
                    alwaysShowTitle={true}
                    mainButtonStyles={{ backgroundColor: "#17a2b8" }}
                    style={{
                        bottom: 0,
                        right: 0,
                        zoom: "80%",
                        margin: "40px",
                        zIndex: 100,
                    }}
                >


                    <Action
                        text='Nova Pergunta'
                        style={{ backgroundColor: "blue" }}
                        onClick={handleOpen}

                    >
                        <BsPatchQuestion />
                    </Action>



                    <Action
                        text='Herdar Perguntas'
                        style={{ backgroundColor: "blue" }}
                        onClick={() => setOpenModal(true)}
                    >
                        <TbDevicesQuestion />
                    </Action>

                </Fab>
                <Modal
                    show={open}
                    onHide={handleClose}
                    size='md'
                    backdrop="static"
                >
                    <Modal.Header>
                        <Modal.Title>Nova Pergunta</Modal.Title>
                        <Modal.Title className={classes.subtitle}>A primeira inserção de cada grupo será o titulo!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TextField className={classes.input} fullWidth id="nova_pergunta_ordem" label="Ordem" variant="outlined" type="number" />
                        <TextField
                            id="nova_pergunta_pergunta"
                            minRows={3}
                            maxRows={5}
                            multiline
                            label="Pergunta"
                            variant="outlined"
                            fullWidth
                        />
                        <Select
                            className={classes.input}
                            options={[{ label: 'Competencias Organizacionais', value: 'Competencias Organizacionais' }, { label: 'Dominio Tecnico', value: 'Dominio Tecnico' }]}
                            closeMenuOnSelect={true}
                            placeholder="Quadrante..."
                            onChange={(e) => { setQuadrantePergunta(e.value) }}
                            defaultValue={{ label: 'Competencias Organizacionais', value: 'Competencias Organizacionais' }}
                        />
                        <Select
                            className={classes.input}
                            options={[{ label: 'Selecionar todas', value: "SELECTALL" }, ...unidades]}
                            isMulti
                            placeholder="Escolha a(s) Unidade(s)..."
                            closeMenuOnSelect={false}
                            onChange={(e) => { if (e.find(d => d.value === 'SELECTALL')) { setUnidadesSelected(unidades) } else { setUnidadesSelected(e) } }}
                        />
                        {/* <Select
                            className={classes.input}
                            options={[{ label: 'Selecionar todos', value: "SELECTALL" }, ...departamentos]}
                            isMulti
                            placeholder="Escolha o(s) Departamento(s)..."
                            closeMenuOnSelect={false}
                            onChange={(e) => { if (e.find(d => d.value === 'SELECTALL')) { setDepartamentosSelected(departamentos) } else { setDepartamentosSelected(e) } }}
                        /> */}
                        <Select
                            className={classes.input}
                            options={[{ label: 'Selecionar todos', value: "SELECTALL" }, ...cargos]}
                            isMulti
                            placeholder="Escolha o(s) Cargo(s)..."
                            closeMenuOnSelect={false}
                            onChange={(e) => { if (e.find(d => d.value === 'SELECTALL')) { setCargosSelected(cargos) } else { setCargosSelected(e) } }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-secondary" onClick={handleClose}>Cancelar</button>
                        <button className="btn btn-primary" onClick={handleNovaPergunta}>Salvar</button>
                    </Modal.Footer>
                </Modal>
                <ModalHerancaPerguntas
                    setUnidadesSelectedDestino={setUnidadesSelectedBuscaDestino} unidadesSelectedDestino={unidadesSelectedBuscaDestino}
                    setCargosSelectedDestino={setCargosSelectedBuscaDestino} cargosSelectedDestino={cargosSelectedBuscaDestino} typePergunta={typePergunta}
                    unidadesSelectedBusca={unidadesSelectedBuscaModal} perguntas={perguntasModal} setPerguntas={setPerguntasModal} classes={classes} setCargosSelectedBusca={setCargosSelectedBuscaModal}
                    setUnidadesSelectedBusca={setUnidadesSelectedBuscaModal} unidades={unidadesModal} cargos={cargos} loadPerguntas={loadPerguntas} setLoadPerguntas={setLoadPerguntas}
                    cargosSelectedBusca={cargosSelectedBuscaModal} inativos={inativos} title='Perguntas Anteriores' open={openModal}
                    onClickButton={() => setOpenModal(false)} onClose={() => setOpenModal(false)} handleToggle={handleToggle}
                />
            </Container >
        </>
    );

}

export default PDI;