// HotelSelectorModal.tsx
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ModalGenerico from "../modalgenerico.tsx";

interface ModalSelectComboProps {
  show: boolean;
  onHide: () => void;
  opcoesCarregadas: any;
  handleComboChange: any;
  selectedCombo: any;
  opcoesAgrupadas: any;
  children: any;
  onSubmit: () => void;
  onClose: () => void;
}

const ModalSelectCombo: React.FC<ModalSelectComboProps> = ({
  show,
  onHide,
  opcoesCarregadas,
  handleComboChange,
  selectedCombo,
  opcoesAgrupadas,
  children,
  onClose,
  onSubmit,
}) => {
  return (
    <ModalGenerico
      open={show}
      onClose={onHide}
      buttonHeaderType="outlined"
      title="Selecionar Combo"
      footerButtonSubmit="Confirmar"
      footerButtonClose="Cancelar"
      onClickButton={onClose}
      onClickButtonSubmit={onSubmit}
      width="xs"
      display="none"
      dialogContent={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {opcoesCarregadas ? (
            <Autocomplete
              onChange={handleComboChange}
              value={selectedCombo}
              options={opcoesAgrupadas.sort(
                (a, b) => -b.categoria.localeCompare(a.categoria)
              )}
              groupBy={(option) => option.categoria}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Escolha uma opção"
                  variant="standard"
                  placeholder="Selecione uma opção..."
                  sx={{
                    "& .MuiInputBase-input": {
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    },
                    width: "100%",
                    maxWidth: 430,
                  }}
                />
              )}
              sx={{
                marginTop: 2,
                marginLeft: 0,
                marginBottom: 2,
                width: "auto",
              }}
            />
          ) : (
            <div>Carregando opções...</div>
          )}
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>{children}</div>
        </div>
      }
    />
  );
};

export default ModalSelectCombo;
