import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  LayoutContainer,
  StyledContainer,
  StyledFatherContainer,
  StyledTableCell,
  StyledTableContainer,
  TitleContainer,
} from "./styles.ts";
import { BiTargetLock } from "react-icons/bi";
import api from "../../services/api.js";
import { useCookies } from "react-cookie";

const ResumoBudget: React.FC = () => {
  const [cookies] = useCookies(["usuario"]);

  const [resumoBudget, setResumoBudget] = useState<{
    formatedData: {
      title: string;
      values: {
        label: string;
        value: number;
      }[];
    }[];
  }>();

  useEffect(() => {
    async function loadResumoBudget() {
      try {
        const response = await api.get(
          `/budget/resumo?usuario_id=${cookies.usuario.id}`,
          {
            headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
          }
        );

        setResumoBudget(response.data);
      } catch (error) {
        console.error("Erro ao carregar combos!", error);
      }
    }

    loadResumoBudget();
  }, []);

  return (
    <LayoutContainer>
      <TitleContainer>
        <div className="icon">
          <BiTargetLock />
        </div>
        <div className="text">Resumo Budget</div>
      </TitleContainer>
      <StyledFatherContainer>
        <StyledContainer>
          {resumoBudget?.formatedData?.map((data, index) => (
            <StyledTableContainer component={Paper} key={index}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={2}>{data?.title}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.values.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          ))}
        </StyledContainer>
      </StyledFatherContainer>
    </LayoutContainer>
  );
};

export default ResumoBudget;
