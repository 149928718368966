import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { useCookies } from 'react-cookie';
import { BiBookmarkPlus, BiSearchAlt } from 'react-icons/bi'
import { IoDocumentAttachOutline } from 'react-icons/io5'
import { RiFileEditLine } from 'react-icons/ri'
import Select from 'react-select';

import {
    Box, Toolbar, Typography, Paper, TableContainer
} from '@material-ui/core';

import { AiOutlineFileAdd, AiOutlineFilter, AiOutlinePlus, AiOutlinePlusCircle } from 'react-icons/ai'
import { Button, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Action, Fab } from 'react-tiny-fab';

const notify = ($msg) => toast($msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
});

const headCells = [
    {
        id: 'unidade_id',
        numeric: true,
        disablePadding: false,
        label: 'Unidade',
    },
    {
        id: 'fornecedor',
        numeric: false,
        disablePadding: false,
        label: 'Fornecedor',
    },
    // {
    //     id: 'descricao',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Descrição',
    // },
    // {
    //     id: 'inicio',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Início',
    // },
    {
        id: 'aniversario',
        numeric: true,
        disablePadding: false,
        label: 'Aniversário',
    },
    {
        id: 'valor',
        numeric: true,
        disablePadding: false,
        label: 'Valor',
    },
    // {
    //     id: 'indicereajuste_id',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Reajuste',
    // },
    {
        id: 'ativo',
        numeric: true,
        center: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'acao',
        numeric: true,
        disablePadding: false,
        center: true,
        label: 'Ações',
    },
];

function GestaoContratos() {
    // Ordenação de colunas
    const [order, setOrder] = useState('asc');
    const [contasDre, setContasDre] = useState([])
    const [cookies] = useCookies([]);
    const [contratos, setContratos] = useState({})
    const [editaModalShow, setEditaModalShow] = useState({ modalVisivel: false, isUpdate: false })
    const [selectedFilter, setSelectedFilter] = useState({ modalVisivel: false, selectedOptAtivo: [{ label: 'Ativo', value: true }] })
    const [unidades] = useState(JSON.parse(sessionStorage.getItem('unidadesMemory')).reduce((acc, cv) => acc = [...acc, { label: cv.unidade, value: cv.id }], []))
    const [contasOPT, setContasOPT] = useState([])

    // eslint-disable-next-line
    useEffect(() => { loadData() }, [])

    function updateFilterContratos() {
        var contratosFiltradas = contratos?.contratosMemory
        if (selectedFilter?.selectedOptIndices?.length > 0) contratosFiltradas = contratosFiltradas.filter(d => selectedFilter?.selectedOptIndices?.reduce((acc, cv) => acc = [...acc, cv?.value], [])?.includes(d?.indicereajuste_id))
        if (selectedFilter?.selectedOptUnidades?.length > 0) contratosFiltradas = contratosFiltradas.filter(d => selectedFilter?.selectedOptUnidades?.reduce((acc, cv) => acc = [...acc, cv?.value], [])?.includes(d?.unidade_id) || selectedFilter?.selectedOptUnidades?.reduce((acc, cv) => acc = [...acc, cv?.value], [])?.includes(d?.combo_id))
        if (selectedFilter?.selectedOptAtivo?.length > 0) contratosFiltradas = contratosFiltradas.filter(d => selectedFilter?.selectedOptAtivo?.reduce((acc, cv) => acc = [...acc, cv?.value], [])?.includes(d?.ativo))

        setContratos({ ...contratos, contratos: contratosFiltradas })
    }

    function orderTable(e, coluna) {
        const opt = coluna === 'valor' ? { numeric: true } : {};

        const sortedContratos = [...contratos.contratos].sort((a, b) => {
            const compareResult = (a[coluna] + '').localeCompare(
                '' + b[coluna],
                undefined,
                { ...opt, sensitivity: 'base', ignorePunctuation: true }
            );

            return compareResult * (order === 'asc' ? 1 : -1);
        });

        setContratos({ ...contratos, contratos: sortedContratos });
        setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    async function loadData() {
        var myUnidades = JSON.parse(sessionStorage.getItem('unidades')).reduce((acc, cv) => acc += "'" + cv.id + "',", '')
        myUnidades = myUnidades.substring(0, (myUnidades.length - 1))

        var allContratosReturn = await api.get(`/contratos/${myUnidades}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        setContratos({ ...contratos, contratos: allContratosReturn.data.contratos.filter(d => d?.ativo === true), contratosMemory: allContratosReturn.data.contratos })
        setSelectedFilter({ ...selectedFilter, indicesOptArr: [...allContratosReturn.data.indices, { label: "Negociado", value: '0' }], fornecedorOptionArr: allContratosReturn.data.fornecedor })

        await api.get('/estruturadre', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
            setContasDre(res.data)
            filtraContasDre(res.data)
        })
    }

    function EnhancedTableHead() {
        return (
            <thead>
                <tr>
                    <td width={'5%'}></td>
                    {headCells.map((headCell) => (
                        <td
                            key={headCell.id}
                            align={headCell.right ? 'right' : headCell.center ? 'center' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            id={headCell.id}
                            onClick={(e) => { orderTable(e, headCell.id) }}
                        >
                            {headCell.label}
                        </td>
                    ))}
                </tr>
            </thead>
        );
    }

    function findTextContrato(textIncomming) {
        var textToFind = textIncomming
        updateFilterContratos()

        if (textToFind === '' || textToFind === undefined) { return updateFilterContratos() }

        setContratos({
            ...contratos, contratos: contratos?.contratos?.filter(e =>
                e?.fornecedor?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                e?.unidade?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                e?.descricao?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                e?.inicio?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                e?.aniversario?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                e?.encerramento?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                e?.planodecontas_id?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                e?.indicereajuste_id?.toLowerCase().includes(textToFind?.toLowerCase()) ||
                ('' + e?.valor)?.toLowerCase().includes(textToFind?.toLowerCase())
            )
        })
    }

    async function handleAtualizaContrato() {
        var hasError = false
        if (editaModalShow?.contrato?.fornecedor === '' || editaModalShow?.contrato?.fornecedor === undefined) { notify('Fornecedor Invalido!'); hasError = true }
        if (editaModalShow?.contrato?.descricao === '' || editaModalShow?.contrato?.descricao === undefined) { notify("Descrição invalida!"); hasError = true }
        if (editaModalShow?.contrato?.inicio === '' || editaModalShow?.contrato?.inicio === undefined) { notify("Escolha uma data de início!"); hasError = true }
        if (editaModalShow?.contrato?.aniversario === '' || editaModalShow?.contrato?.aniversario === undefined) { notify("Escolha uma data de aniversário"); hasError = true }
        if (editaModalShow?.contrato?.valor === '0' || editaModalShow?.contrato?.valor === '' || editaModalShow?.contrato?.valor === undefined) { notify("Valor de contrato inválido!"); hasError = true }
        if (editaModalShow?.contrato?.indicereajuste_id === '' || editaModalShow?.contrato?.indicereajuste_id === undefined || editaModalShow?.contrato?.indicereajuste_id === null) { notify("Escolha o indice de reajuste do contrato!"); hasError = true }
        if (editaModalShow?.contrato?.unidade_id === '' || editaModalShow?.contrato?.unidade_id === undefined) { notify("Escolha uma unidade!"); hasError = true }
        if (editaModalShow?.contrato?.planodecontas_id === '' || editaModalShow?.contrato?.planodecontas_id === undefined) { notify("Escolha a conta relacionada!"); hasError = true }
        if (hasError) return console.error("Has error")

        const dados = new FormData()

        if (editaModalShow?.contrato?.contratoFile) {
            dados.append('file', editaModalShow?.contrato?.contratoFile)
            dados.append('originalname', editaModalShow?.contrato?.contratoFile.name)
            dados.append('fornecedor', editaModalShow?.contrato?.fornecedor)
            dados.append('descricao', editaModalShow?.contrato?.descricao)
            dados.append('inicio', editaModalShow?.contrato?.inicio)
            dados.append('aniversario', editaModalShow?.contrato?.aniversario)
            dados.append('valor', parseFloat(editaModalShow?.contrato?.valor))
            dados.append('indicereajuste_id', editaModalShow?.contrato?.indicereajuste_id)
            dados.append('unidade_id', editaModalShow?.contrato?.unidade_id)
            dados.append('organizacao_id', JSON.parse(sessionStorage.getItem('unidades')).find(uni => uni.id === editaModalShow?.contrato?.unidade_id).organizacao_id)
            dados.append('planodecontas_id', editaModalShow?.contrato?.planodecontas_id)
            dados.append('alteradopor', cookies.usuario.id)
            dados.append('isnegociado', editaModalShow?.contrato?.isNegociado)
            dados.append('id', editaModalShow?.contrato?.id)

            await api.put('/contratos/file', dados, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
                if (res.data.includes('Erro')) {
                    notify("Erro ao adicionar contrato!")
                } else {
                    setEditaModalShow({ modalVisivel: false, isUpdate: false });
                    loadData()
                    notify("Contrato adicionado com sucesso!")
                }
            })
        } else {
            await api.put('/contratos', {
                fornecedor: editaModalShow?.contrato?.fornecedor,
                descricao: editaModalShow?.contrato?.descricao,
                inicio: editaModalShow?.contrato?.inicio,
                aniversario: editaModalShow?.contrato?.aniversario,
                indicereajuste_id: editaModalShow?.contrato?.indicereajuste_id,
                unidade_id: editaModalShow?.contrato?.unidade_id,
                organizacao_id: JSON.parse(sessionStorage.getItem('unidades')).find(uni => uni.id === editaModalShow?.contrato?.unidade_id).organizacao_id,
                alteradopor: cookies.usuario.id,
                valor: parseFloat(editaModalShow?.contrato?.valor),
                id: editaModalShow?.contrato?.id,
                planodecontas_id: editaModalShow?.contrato?.planodecontas_id,
                isnegociado: editaModalShow?.contrato?.isNegociado,
            }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
                if (res.data.includes('Erro')) {
                    notify("Erro ao adicionar contrato!")
                } else {
                    setEditaModalShow({ modalVisivel: false, isUpdate: false });
                    loadData()
                    notify("Contrato adicionado com sucesso!")
                }
            })
        }
    }

    async function handleNovoContrato() {
        var hasError = false
        if (editaModalShow?.contrato?.fornecedor === '' || editaModalShow?.contrato?.fornecedor === undefined) { notify('Fornecedor Invalido!'); hasError = true }
        if (editaModalShow?.contrato?.descricao === '' || editaModalShow?.contrato?.descricao === undefined) { notify("Descrição invalida!"); hasError = true }
        if (editaModalShow?.contrato?.inicio === '' || editaModalShow?.contrato?.inicio === undefined) { notify("Escolha uma data de início!"); hasError = true }
        if (editaModalShow?.contrato?.aniversario === '' || editaModalShow?.contrato?.aniversario === undefined) { notify("Escolha uma data de aniversário"); hasError = true }
        if (editaModalShow?.contrato?.valor === '0' || editaModalShow?.contrato?.valor === '' || editaModalShow?.contrato?.valor === undefined) { notify("Valor de contrato inválido!"); hasError = true }
        if (editaModalShow?.contrato?.indicereajuste_id === '' || editaModalShow?.contrato?.indicereajuste_id === undefined || editaModalShow?.contrato?.indicereajuste_id === null) { notify("Escolha o indice de reajuste do contrato!"); hasError = true }
        if (editaModalShow?.contrato?.unidade_id === '' || editaModalShow?.contrato?.unidade_id === undefined) { notify("Escolha uma unidade!"); hasError = true }
        if (editaModalShow?.contrato?.planodecontas_id === '' || editaModalShow?.contrato?.planodecontas_id === undefined) { notify("Escolha a conta relacionada!"); hasError = true }
        if (editaModalShow?.contrato?.contratoFile === null || editaModalShow?.contrato?.contratoFile === undefined) { notify("Anexe o contrato!"); hasError = true }
        if (hasError) return console.error("Has error")

        const dados = new FormData()

        dados.append('file', editaModalShow?.contrato?.contratoFile)
        dados.append('fornecedor', editaModalShow?.contrato?.fornecedor)
        dados.append('descricao', editaModalShow?.contrato?.descricao)
        dados.append('inicio', editaModalShow?.contrato?.inicio)
        dados.append('aniversario', editaModalShow?.contrato?.aniversario)
        dados.append('valor', parseFloat(editaModalShow?.contrato?.valor))
        dados.append('indicereajuste_id', editaModalShow?.contrato?.indicereajuste_id)
        dados.append('unidade_id', editaModalShow?.contrato?.unidade_id)
        dados.append('organizacao_id', JSON.parse(sessionStorage.getItem('unidades')).find(uni => uni.id === editaModalShow?.contrato?.unidade_id).organizacao_id)
        dados.append('planodecontas_id', editaModalShow?.contrato?.planodecontas_id)
        dados.append('alteradopor', cookies.usuario.id)
        dados.append('originalname', editaModalShow?.contrato?.contratoFile?.name)
        dados.append('isnegociado', editaModalShow?.contrato?.isNegociado)

        await api.post('/contratos', dados, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
            if (res.data.includes('Erro')) {
                notify("Erro ao adicionar contrato!")
            } else {
                setEditaModalShow({ modalVisivel: false, isUpdate: false });
                loadData()
                notify("Contrato adicionado com sucesso!")
            }
        })


    }

    async function alteraStatusContrato(idContrato, state) {
        try {
            await api.put('/statuscontrato/' + idContrato + '/' + cookies.usuario.id + '/' + state, {}, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => {
                    setEditaModalShow({ ...editaModalShow, modalVisivel: false, isUpdate: false })
                    loadData()
                    notify('Contrato atualizado com sucesso!')
                })
        } catch (error) {
            notify("Erro ao atualizar o status do contrato!")
        }
    }

    function filtraContasDre(contas) {
        var optGrp = contas?.filter(conta => conta.composicao)
        var final = optGrp.reduce((acc, cv) => acc = [...acc, {
            label: cv.descricao, options:
                contas.filter(d => cv.composicao.includes(d.codigo_id) && d.composicao === null)?.reduce((acc, cv) => acc = [...acc, { value: cv.codigo_id, label: cv.codigo_id === cv.descricao ? cv.descricao : cv.codigo_id + ' - ' + cv.descricao }], [])
        }], [])

        setContasOPT(final)
    }

    function hideShowMoreDetails(id) {
        if (document.getElementById('moreDetails-' + id).style.display === 'none') {
            document.getElementById('moreDetails-' + id).style.display = ''
            document.getElementById('mainDetails-' + id).firstChild.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>'
        } else {
            document.getElementById('moreDetails-' + id).style.display = 'none'
            document.getElementById('mainDetails-' + id).firstChild.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>'
        }

    }

    return (<Box sx={{ width: '100%' }}>
        <Paper style={{ marginBottom: '50px' }} sx={{ width: '100%', mb: 2 }}>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
                style={{ placeContent: "space-between" }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Contratos
                </Typography>
                <InputGroup size="sm" style={{ maxWidth: '300px' }}>
                    <Form.Control size="sm" placeholder="Buscar Contrato" onChange={(e) => { setSelectedFilter({ ...selectedFilter, textToFind: e.target.value }); findTextContrato(e.target.value) }} />
                    <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2"><BiSearchAlt /></InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Toolbar>
            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                >
                    <EnhancedTableHead />
                    <tbody>
                        {contratos?.contratos?.map((contrato, idx) => {
                            return (
                                <>
                                    <tr
                                        tabIndex={contrato.id}
                                        key={idx + '-' + contrato.id}
                                        id={'mainDetails-' + contrato.id}
                                        onClick={() => hideShowMoreDetails(contrato.id)}
                                    >
                                        <td><AiOutlinePlusCircle /></td>
                                        <td align="left">{contrato.unidade}</td>
                                        <td align="left">{contrato.fornecedor}</td>
                                        <td align="left">{contrato.aniversarioformated}</td>
                                        <td align="left">{contrato.valor ? contrato.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</td>
                                        <td style={contrato.ativo ? { fontSize: 'x-large', textAlign: 'center', color: 'green' } : { fontSize: 'x-large', textAlign: 'center' }}>{contrato.ativo ? <BiBookmarkPlus /> : contrato.encerramento} </td>
                                        <td style={{ fontSize: 'x-large', textAlign: 'center', display: 'flex', justifyContent: 'space-evenly' }}>
                                            <Button variant="info" onClick={(e) => { e.stopPropagation(); window.open(contrato.linkcontrato) }} ><IoDocumentAttachOutline /></Button>
                                            <Button variant="warning" onClick={(e) => { e.stopPropagation(); filtraContasDre(contasDre?.filter(d => (d.organizacao_id + '') === ('' + JSON.parse(sessionStorage.getItem('unidades')).find(uni => uni.id === contrato.unidade_id).organizacao_id))); setEditaModalShow({ modalVisivel: true, contrato: { ...contrato, isNegociado: !isNaN(contrato.indicereajuste_id) ? true : false }, isUpdate: true }) }} ><RiFileEditLine /></Button>
                                        </td>
                                    </tr>
                                    <tr key={'md' + contrato.id} id={'moreDetails-' + contrato.id} style={{ display: 'none' }}>
                                        <td colSpan={3} align="left"><strong>Descrição:</strong> {contrato.descricao}</td>
                                        <td colSpan={2} align="left"><strong>Inicio:</strong> {contrato.inicioformated}</td>
                                        <td colSpan={2} align="left"><strong>Indice de Reajuste:</strong> {!isNaN(parseFloat(contrato.indicereajuste_id)) ? parseFloat(contrato.indicereajuste_id).toFixed(2) + ' %' : contrato.indicereajuste_id}</td>
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </Table>
            </TableContainer>
        </Paper>

        <Fab
            icon={<AiOutlinePlus />}
            alwaysShowTitle={true}
            mainButtonStyles={{ backgroundColor: '#17a2b8' }}
            style={{ bottom: 0, right: 0, zoom: '80%', margin: '20px', zIndex: 100 }}
        >
            {/* Bottom */}

            <Action
                text={<span style={{ fontSize: 'large' }}>Filtro</span>}
                style={{ backgroundColor: 'blue' }}
                onClick={() => { setSelectedFilter({ ...selectedFilter, modalVisivel: true }) }}
            >
                <AiOutlineFilter />
            </Action>

            <Action
                text={<span style={{ fontSize: 'large' }}>Adicionar Contrato</span>}
                style={{ backgroundColor: 'blue' }}
                onClick={() => { setEditaModalShow({ modalVisivel: true, isUpdate: false }) }}
            >
                <AiOutlineFileAdd />
            </Action>

            {/* Top */}
        </Fab>
        <Modal
            size="lg"
            show={editaModalShow.modalVisivel}
            onHide={() => { setEditaModalShow({ ...editaModalShow, modalVisivel: false, isUpdate: false }) }}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {editaModalShow?.isUpdate ? "Atualizar contrato " + editaModalShow?.contrato?.fornecedor : "Adicionar Contrato"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id='formularioNovoContrato'>
                    <Form.Group as={Row} >
                        <Col>
                            <Form.Label>Fornecedor:</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Fornecedor" onBlur={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, fornecedor: e.target.value } })} defaultValue={editaModalShow?.contrato?.fornecedor} />
                        </Col>
                        <Col>
                            <Form.Label >Descrição:</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Descrição" onBlur={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, descricao: e.target.value } })} defaultValue={editaModalShow?.contrato?.descricao} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Col>
                            <Form.Label>Início:</Form.Label>
                            <Form.Control size="sm" type="date" placeholder="Início" onBlur={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, inicio: e.target.value.substring(0, 10) } })} defaultValue={editaModalShow?.contrato?.inicio?.substring(0, 10)} />
                        </Col>
                        <Col>
                            <Form.Label>Aniversário:</Form.Label>
                            <Form.Control size="sm" type="date" placeholder="Aniversário" onBlur={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, aniversario: e.target.value.substring(0, 10) } })} defaultValue={editaModalShow?.contrato?.aniversario?.substring(0, 10)} />
                        </Col>
                        <Col>
                            <Form.Label>Valor atual:</Form.Label>
                            <Form.Control size="sm" type="number" placeholder="Valor do contrato R$" onBlur={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, valor: e.target.value } })} defaultValue={editaModalShow?.contrato?.valor} />
                        </Col>
                        <Col>
                            <Form.Label>Indice de Reajuste:</Form.Label>
                            <Form.Control size="sm" as="select" placeholder="Unidade" onChange={(e) => e.target.value === 'Negociado' ? setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, indicereajuste_id: undefined, isNegociado: true } }) : setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, indicereajuste_id: e.target.value, isNegociado: false } })}>
                                <option value='-1'>Escolha o indice</option>
                                {selectedFilter?.indicesOptArr?.map(indice => { if (editaModalShow?.contrato?.indicereajuste_id === indice.label) { return (<option value={indice.label} selected>{indice.label}</option>) } else if (!isNaN(editaModalShow?.contrato?.indicereajuste_id) && indice.label === 'Negociado') { return (<option value={indice.label} selected>{indice.label}</option>) } else { return (<option value={indice.label}>{indice.label}</option>) } })}
                            </Form.Control>
                        </Col>
                    </Form.Group >
                    <Form.Group as={Row}  >
                        <Col hidden={!editaModalShow?.contrato?.isNegociado}>
                            <Form.Label>% de reajuste:</Form.Label>
                            <InputGroup >
                                <Form.Control type="number" placeholder="Indice" onBlur={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, indicereajuste_id: e.target.value } })} defaultValue={editaModalShow?.contrato?.indicereajuste_id} />
                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col style={{ zIndex: '5' }}>
                            <Form.Label>Unidade:</Form.Label>
                            <Select
                                options={unidades}
                                closeMenuOnSelect={true}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                    setEditaModalShow({
                                        ...editaModalShow,
                                        contrato: {
                                            ...editaModalShow.contrato,
                                            unidade_id: e.value,
                                            unidade: e.label
                                        }
                                    }); filtraContasDre(contasDre?.filter(d => ('' + d.organizacao_id) === ('' + JSON.parse(sessionStorage.getItem('unidades')).find(uni => uni.id === e.value).organizacao_id)));
                                }}
                                value={unidades.find(u => u.value === editaModalShow?.contrato?.unidade_id)}
                            />
                        </Col>

                        <Col style={{ zIndex: '5' }}>
                            <Form.Label>Conta relacionada:</Form.Label>
                            <Select
                                options={contasOPT}
                                closeMenuOnSelect={true}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, planodecontas_id: e.value } })}
                                value={contasOPT.reduce((acc, cv) => [...acc, ...cv.options], []).find(d =>
                                    d.value === editaModalShow?.contrato?.planodecontas_id
                                )}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}  >
                        <Col>
                            <Form.Label>Contrato:</Form.Label>
                            <Form.File
                                id="custom-file-translate-scss"
                                onChange={(e) => setEditaModalShow({ ...editaModalShow, contrato: { ...editaModalShow.contrato, contratoFile: e.target.files[0], originalname: e.target?.files[0]?.name } })}
                                label={editaModalShow?.contrato?.originalname ? editaModalShow?.contrato?.originalname : 'Contrato'}
                                data-browse="Adicionar contrato"
                                custom
                            />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {editaModalShow?.isUpdate ? <Button size='sm' variant={editaModalShow?.contrato?.ativo ? 'outline-danger' : 'outline-success'} onClick={() => alteraStatusContrato(editaModalShow?.contrato?.id, !editaModalShow?.contrato?.ativo)}>{editaModalShow?.contrato?.ativo ? "Desativar" : "Reativar"}</Button> : null}
                <Button size='sm' onClick={editaModalShow?.isUpdate ? () => handleAtualizaContrato() : () => handleNovoContrato()}>{editaModalShow?.isUpdate ? "Atualizar" : "Adicionar"}</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={selectedFilter.modalVisivel} backdrop='static'>
            <Modal.Header>
                <Modal.Title>Filtro Contratos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <label>Escolha as Unidades: </label>
                        <Select
                            isMulti
                            name="colors"
                            options={unidades}
                            closeMenuOnSelect={false}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => { setSelectedFilter({ ...selectedFilter, selectedOptUnidades: e }) }}
                            value={selectedFilter?.selectedOptUnidades}

                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label> Status do contrato: </label>
                        <Select
                            isMulti
                            name="colors"
                            options={[{ label: 'Ativo', value: true }, { label: "Inativo", value: false }]}
                            closeMenuOnSelect={false}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => { setSelectedFilter({ ...selectedFilter, selectedOptAtivo: e }) }}
                            value={selectedFilter?.selectedOptAtivo}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label> Indices de reajuste: </label>
                        <Select
                            isMulti
                            name="colors"
                            options={selectedFilter?.indicesOptArr}
                            closeMenuOnSelect={false}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => { setSelectedFilter({ ...selectedFilter, selectedOptIndices: e }) }}
                            value={selectedFilter?.selectedOptIndices}
                        />
                    </Col>
                </Row>
                {/* <Row>
                        <Col>
                            <label> Fornecedores </label>
                            <Select
                                isMulti
                                name="colors"
                                options={selectedFilter.fornecedorOptionArr}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => { setSelectedFilter({ ...selectedFilter, selectedOptFornecedor: e }) }}
                                value={selectedFilter?.selectedOptFornecedor}
                            />
                        </Col>
                    </Row> */}
            </Modal.Body>
            <Modal.Footer>
                <Button size='sm' onClick={() => { updateFilterContratos(); setSelectedFilter({ ...selectedFilter, modalVisivel: false }) }}>Filtrar</Button>
            </Modal.Footer>
        </Modal >

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={true}
            style={{ zIndex: 1000000 }}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover />
    </Box>)
}

export default GestaoContratos;