const initial_state = {
    unidadesCarregadas: [],
    unidadesMemory: [],
};

export default function unidades(state = initial_state, action) {
    switch (action.type) {
        case 'CARREGAR_UNIDADES':
            return { ...state, unidadesCarregadas: action.unidades, unidadesMemory: action.unidades };
        default:
            return state;
    }
}