import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { RiNotification2Line } from "react-icons/ri";
import ModalGenerico from "../modalgenerico.tsx";

interface ModalContratosProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
  inconsistenciasInvestidores: any;
}

const ModalCadastroDre: React.FC<ModalContratosProps> = ({
  open,
  onClose,
  onClickButton,
  inconsistenciasInvestidores,
}) => {
  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={"Minhas notificações!"}
      showSearchIcon={false}
      buttonContent={<RiNotification2Line />}
      dialogContent={
        <>
          {inconsistenciasInvestidores && (
            <Container style={{ color: "black", margin: "10px 0" }}>
              {inconsistenciasInvestidores?.map((inconsistenciaAtual, idx) => {
                return (
                  <Row key={idx} style={{ color: "black" }}>
                    <Col>Cadatro DRE</Col>
                    <Col xs={10}>{inconsistenciaAtual.msg}</Col>
                  </Row>
                );
              })}
            </Container>
          )}
        </>
      }
    />
  );
};

export default ModalCadastroDre;
