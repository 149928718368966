import styled from "styled-components";

export const InputContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1920px;
  margin: auto;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
`;

export const Input = styled.input`
  margin: 10px 0;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
`;

export const Button = styled.button`
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.2s, transform 0.1s;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0 8px;
  border-radius: 6px;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #ffffff;
  border-radius: 6px;
`;

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 5px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const PageItem = styled.button<{
  isActive?: boolean;
}>`
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: ${({ isActive }) => (isActive ? "#007bff" : "white")};
  color: ${({ isActive }) => (isActive ? "white" : "black")};
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
    color: white;
  }
`;

export const DownloadIconLink = styled.a`
  margin-right: 5px;
  color: black;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const FileNameLink = styled.a`
  color: black;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export const ItemsPerPageSelector = styled.select`
  padding: 10px;
`;

export const DownloadAllButton = styled.button`
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const Th = styled.th`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
`;

export const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
