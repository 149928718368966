import React from "react";
import ModalGenerico from "../../../../components/modals/modalgenerico.tsx";

interface ModalVariacaoVerticalProps {
  show: boolean;
  onHide: () => void;
  onConfirmar: () => void;
}

const ModalVariacaoVertical: React.FC<ModalVariacaoVerticalProps> = ({
  show,
  onHide,
  children,
  onConfirmar,
}) => {
  return (
    <ModalGenerico
      open={show}
      onClose={onHide}
      buttonHeaderType="outlined"
      footerButtonSubmit="Confirmar"
      onClickButtonSubmit={onConfirmar}
      title="Selecionar Conta"
      footerButtonClose="Cancelar"
      onClickButton={onHide}
      width="xs"
      display="none"
      dialogContent={<>{children}</>}
    />
  );
};

export default ModalVariacaoVertical;
