import React from "react";
import { GrDocument } from "react-icons/gr";
import ModalGenerico from "../modalgenerico.tsx";
import { RiNotification2Line } from "react-icons/ri";
import { Container } from "@material-ui/core";
import { Button, Col, Row } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";

interface ModalContratosProps {
  open: boolean;
  onClose: () => void;
  onClickButton: () => void;
  notificacoes: any;
  unidades: any;
  setNotificacaoModal: (any) => void;
  vizualizaNotificacoes: (any) => void;
  deletanotificacao: (any) => void;
  openPage: (any) => void;
}

const ModalNotificacoes: React.FC<ModalContratosProps> = ({
  open,
  onClose,
  onClickButton,
  notificacoes,
  unidades,
  setNotificacaoModal,
  vizualizaNotificacoes,
  openPage,
  deletanotificacao,
}) => {
  return (
    <ModalGenerico
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      open={open}
      onClose={onClose}
      onClickButton={onClickButton}
      title={"Minhas notificações!"}
      showSearchIcon={false}
      buttonContent={<RiNotification2Line />}
      dialogContent={
        <>
          {notificacoes.map((notificacaoAtual, idx) => {
            return (
              <Row
                key={idx}
                style={{
                  color: notificacaoAtual.visualizado ? "darkgray" : "black",
                }}
              >
                <Col xs={4}>
                  {unidades.find((d) => d.value === notificacaoAtual.unidade_id)
                    ? unidades.find(
                        (d) => d.value === notificacaoAtual.unidade_id
                      ).label
                    : ""}
                </Col>
                <Col xs={6}>{notificacaoAtual.mensagem}</Col>
                <Col style={{ textAlign: "center" }}>
                  <Button
                    size="sm"
                    style={{ marginRight: 5 }}
                    variant={
                      notificacaoAtual.visualizado ? "secondary" : "primary"
                    }
                    onClick={() => {
                      vizualizaNotificacoes(notificacaoAtual.id);
                      setNotificacaoModal(false);
                      openPage(notificacaoAtual.pagina);
                    }}
                  >
                    Ver
                  </Button>
                  <Button
                    size="sm"
                    style={{ marginLeft: 5 }}
                    variant="danger"
                    onClick={() => {
                      deletanotificacao(notificacaoAtual.id);
                    }}
                  >
                    <AiOutlineDelete />
                  </Button>
                </Col>
              </Row>
            );
          })}
        </>
      }
    />
  );
};

export default ModalNotificacoes;
