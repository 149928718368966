import React, { useState } from "react";
import { mockData } from "./mock.ts";
import {
  TableContainer,
  TableHeader,
  TableCell,
  TableRow,
  TableHeaderCell,
  TableCellPickUp,
  TableCellDiferenca,
  AuthLy,
  IconContainer,
  TittleContainer,
  Tittle,
} from "./styles.ts";
import { Action, Fab } from "react-tiny-fab";
import { BiFilterAlt, BiTargetLock } from "react-icons/bi";
import { BsEyeSlash, BsFillBarChartFill } from "react-icons/bs";
import SwitchModalRevenue, {
  initialColumnVisibility,
} from "../../components/modals/models/modalMostrarEsconderRevenue.tsx";
import ChartComponent from "./chart.tsx";
import ChartModal from "../../components/modals/models/chartConstructorModal.tsx";

const RevenueManangerDesktop: React.FC = () => {
  const [modalMostrarEsconder, setModalMostrarEsconder] = useState([false]);
  const [columnVisibility, setColumnVisibility] = useState(
    initialColumnVisibility
  );
  const [showChartModal, setShowChartModal] = useState(false);
  const [chartType, setChartType] = useState("bar");
  const [metric, setMetric] = useState("taxaOcupacao");
  const [isChartVisible, setIsChartVisible] = useState(false);

  const handleChartUpdate = (newChartType, newMetric) => {
    setIsChartVisible(true);
    setChartType(newChartType);
    setMetric(newMetric);
  };

  return (
    <AuthLy>
      <TittleContainer>
        <IconContainer style={{ alignSelf: "baseline" }}>
          <BiTargetLock />
        </IconContainer>
        <Tittle>Revenue Manager</Tittle>
      </TittleContainer>
      <TableContainer>
        <TableHeader>
          <TableHeaderCell>Relatório Mensal - Hoteis Rede</TableHeaderCell>
          {columnVisibility["Apts Ocupados"] && (
            <TableHeaderCell>Apts Ocupados</TableHeaderCell>
          )}
          {columnVisibility["Taxa de Ocupação"] && (
            <TableHeaderCell>Taxa de Ocupação</TableHeaderCell>
          )}
          {columnVisibility["Acumulado Anterior"] && (
            <TableHeaderCell>Acumulado Anterior</TableHeaderCell>
          )}
          {columnVisibility["Diária Média"] && (
            <TableHeaderCell>Diária Média</TableHeaderCell>
          )}
          {columnVisibility["RevPar"] && (
            <TableHeaderCell>RevPar</TableHeaderCell>
          )}
          {columnVisibility["Receita"] && (
            <TableHeaderCell>Receita</TableHeaderCell>
          )}
          {columnVisibility["Ranking"] && (
            <TableHeaderCell>Ranking</TableHeaderCell>
          )}
          {columnVisibility["Pick-up"] && (
            <TableHeaderCell>Pick-up</TableHeaderCell>
          )}
          {columnVisibility["Apts Ocupados Budget"] && (
            <TableHeaderCell>Apts Ocupados Budget</TableHeaderCell>
          )}
          {columnVisibility["Taxa de Ocupação Budget"] && (
            <TableHeaderCell>Taxa de Ocupação Budget</TableHeaderCell>
          )}
          {columnVisibility["Acumulado Anterior Budget"] && (
            <TableHeaderCell>Acumulado Anterior Budget</TableHeaderCell>
          )}
          {columnVisibility["Diária Média Budget"] && (
            <TableHeaderCell>Diária Média Budget</TableHeaderCell>
          )}
          {columnVisibility["RevPar Budget"] && (
            <TableHeaderCell>RevPar Budget</TableHeaderCell>
          )}
          {columnVisibility["Receita Budget"] && (
            <TableHeaderCell>Receita Budget</TableHeaderCell>
          )}
          {columnVisibility["Ranking"] && (
            <TableHeaderCell>Ranking</TableHeaderCell>
          )}
          {columnVisibility["Diferença Budget"] && (
            <TableHeaderCell>Diferença Budget</TableHeaderCell>
          )}
        </TableHeader>
        {mockData.map((hotel, index) => (
          <TableRow key={index}>
            <TableCell>{hotel.nome}</TableCell>
            {columnVisibility["Apts Ocupados"] && (
              <TableCell>{hotel.realizado.aptsOcupados}</TableCell>
            )}
            {columnVisibility["Taxa de Ocupação"] && (
              <TableCell>{hotel.realizado.taxaOcupacao}</TableCell>
            )}
            {columnVisibility["Acumulado Anterior"] && (
              <TableCell>{hotel.realizado.taxaOcupacaoAnterior}</TableCell>
            )}
            {columnVisibility["Diária Média"] && (
              <TableCell>{hotel.realizado.diariaMedia}</TableCell>
            )}
            {columnVisibility["RevPar"] && (
              <TableCell>{hotel.realizado.revPar}</TableCell>
            )}
            {columnVisibility["Receita"] && (
              <TableCell>{hotel.realizado.receita}</TableCell>
            )}
            {columnVisibility["Ranking"] && (
              <TableCell>{hotel.realizado.ranking}</TableCell>
            )}
            {columnVisibility["Pick-up"] && (
              <TableCellPickUp>{hotel.pickUp}</TableCellPickUp>
            )}
            {columnVisibility["Apts Ocupados Budget"] && (
              <TableCell>{hotel.orcado.aptsOcupados}</TableCell>
            )}
            {columnVisibility["Taxa de Ocupação Budget"] && (
              <TableCell>{hotel.orcado.taxaOcupacao}</TableCell>
            )}
            {columnVisibility["Acumulado Anterior Budget"] && (
              <TableCell>{hotel.orcado.taxaOcupacaoAnterior}</TableCell>
            )}
            {columnVisibility["Diária Média Budget"] && (
              <TableCell>{hotel.orcado.diariaMedia}</TableCell>
            )}
            {columnVisibility["RevPar Budget"] && (
              <TableCell>{hotel.orcado.revPar}</TableCell>
            )}
            {columnVisibility["Receita Budget"] && (
              <TableCell>{hotel.orcado.receita}</TableCell>
            )}
            {columnVisibility["Ranking"] && (
              <TableCell>{hotel.orcado.ranking}</TableCell>
            )}
            {columnVisibility["Diferença Budget"] && (
              <TableCellDiferenca color={hotel.corDiferenca}>
                {hotel.diferencaBudget}
              </TableCellDiferenca>
            )}
          </TableRow>
        ))}
        <Fab
          icon={<BiFilterAlt />}
          alwaysShowTitle={true}
          mainButtonStyles={{ backgroundColor: "#17a2b8" }}
          style={{
            bottom: 0,
            right: 0,
            zoom: "80%",
            margin: "20px",
            zIndex: 100,
          }}
        >
          <Action
            text="Ocultar informações "
            style={{ backgroundColor: "blue" }}
            onClick={() => {
              setModalMostrarEsconder([true]);
            }}
          >
            <BsEyeSlash />
          </Action>
          <Action
            text="Construir Gráfico"
            style={{ backgroundColor: "blue" }}
            onClick={() => {
              setShowChartModal(true);
            }}
          >
            <BsFillBarChartFill />
          </Action>
        </Fab>
      </TableContainer>
      {isChartVisible && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 50,
          }}
        >
          <Tittle>
            Gráfico de {chartType} sobre {metric}
          </Tittle>
          <div
            style={{
              height: "50%",
              width: "100%",
              maxWidth: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "enter",
            }}
          >
            <ChartComponent width="800" chartType={chartType} metric={metric} />
          </div>
        </div>
      )}
      <SwitchModalRevenue
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        modalMostrarEsconder={modalMostrarEsconder}
        setModalMostrarEsconder={setModalMostrarEsconder}
      />
      <ChartModal
        show={showChartModal}
        onHide={() => setShowChartModal(false)}
        onSelectType={setChartType}
        onSelectMetric={setMetric}
        onConfirm={handleChartUpdate}
      />
    </AuthLy>
  );
};

export default RevenueManangerDesktop;
