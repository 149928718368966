/* eslint-disable */
import { all, create } from 'mathjs';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import api from '../../../services/api';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { AiFillEye, AiOutlineNotification, AiOutlineSave } from 'react-icons/ai';
import { BiComment, BiCommentAdd, BiError, BiFilterAlt } from 'react-icons/bi';
import { BsPersonAdd } from 'react-icons/bs';
import { FaPencilRuler, FaSignature } from 'react-icons/fa';
import { GrDocument } from 'react-icons/gr';
import { HiSwitchVertical } from 'react-icons/hi';
import { IoAttach } from 'react-icons/io5';
import { RiFileExcel2Line, RiMessage2Fill, RiMessage2Line, RiQuestionAnswerLine, RiQuestionnaireLine } from 'react-icons/ri';
import { ComparativeButton } from '../../../components/buttons/premissasbuttons/comparativebutton.tsx';
import { HiddenButton } from '../../../components/buttons/premissasbuttons/hiddenbutton.tsx';
import { HoverButton } from '../../../components/buttons/premissasbuttons/hoverbutton.tsx';
import ModalRazao from '../../../components/modals/models/modalRazao.tsx';
import ModalRespostas from '../../../components/modals/models/modalRespostas.tsx';
import SelectModal from '../../../components/modals/models/selectmodal.tsx';
import { SwitchModal } from '../../../components/modals/models/switchmodal.tsx';
import VariacaoVerticalModal from '../../../components/modals/models/variacaomodal.tsx';
import ModalNovoComentario from '../../../components/modals/models/modalNovoComentario.tsx';
import ModalComentarios from '../../../components/modals/models/modalComentarios.tsx';
import ModalContratos from '../../../components/modals/models/modalContratos.tsx';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { formatTopLine } from './format/topline';

// redux
import { useDispatch } from 'react-redux';

import './styles.css';

function Budget() {

  var minhasUnidades = JSON.parse(sessionStorage.getItem('unidades'))
  var location = useLocation();
  const config = {}
  const math = create(all, config)

  //alteração de cores 
  const [currentMonth, setCurrentMonth] = useState("");
  const getBackgroundColor = (mesAtual) => {
    if (mesAtual >= currentMonth && currentMonth !== 12) {
      return "#EFE0FF";
    } else {
      return "lightyellow";
    }
  }

  const dispatch = useDispatch()
  const [cookies] = useCookies();
  const [budgetData, setBudgetData] = useState([])
  const [budgetDataMemory, setBudgetDataMemory] = useState([])
  const [is_editable, setIsEditable] = useState(false)
  const [topLineBudgetData, setTopLineBudgetData] = useState([])
  const [selectedCombo, setSelectedCombo] = useState(location.state.combo_id)
  const [showPremissas, setShowPremissas] = useState([false, []])
  const [editaBudgetAtualObj, setEditaBudgetAtualObj] = useState({ modalVisivel: false })
  const [mesEdita, setMesEdita] = useState('01')
  const [mesEditaFinal, setMesEditaFinal] = useState('01')
  const [colabCC, setColabCC] = useState([])

  const [razaoData, setRazaoData] = useState([])
  const [razaoDataMemory, setRazaoDataMemory] = useState([])
  const [anexosRazao, setAnexosRazao] = useState([])
  const [modalShow, setModalShow] = useState([false]);
  const [searchRazaoText, setSearchRazaoText] = useState('')
  const [nivelContaAtual, setNivelContaAtual] = useState(null)

  const [modalComentariosShow, setModalComentariosShow] = useState([false, null, { id: '' }]);
  const [modalNovoComentarios, setModalNovoComentarios] = useState([false]);
  const [modalRespostaVisible, setModalRespostaVisible] = useState([false, { nome: '', comentario: '' }])
  const [tabEdicaoBudget, setTabEdicaoBudget] = useState("Periodo")
  const [isComentario, setIsComentario] = useState([false])
  const [tooltipText, setToolipText] = useState('Adicionar anexo')
  const [checked, setChecked] = useState(false)
  const [myUsers, setMyUsers] = useState(null)
  const [selectedUser, setSelectedUser] = useState([]);
  const [tipoVar, setTipoVar] = useState(true)
  const [anoBudget, setAnoBudget] = useState(new Date().getFullYear())
  const [importarValores, setImportarValores] = useState({ ano: 2022 })

  const [modalContratosShow, setModalContratosShow] = useState([false, [], [], []])

  // Filtros
  const [variacaoVertical, setVariacaoVertical] = useState(false)
  const [modalAssinatura, setModalAssinatura] = useState({ modalVisivel: false, modalErroVisivel: false })
  const [modalNotificacao, setModalNotificacao] = useState({ modalVisivel: false })
  const [baseVertical, setBaseVertical] = useState({ id: null })
  const [ocultaQuadroPessoal, setOcultaQuadroPessoal] = useState({ isHiddenLine: true, modalVisivel: false, exibicaoPessoal: 'total' })
  const [hiddenLines, setHiddenLines] = useState({})


  //Modal tipo hidden
  const [hiddenColumns, setHiddenColumns] = useState({});
  const [optionsYears, setOptionsYears] = React.useState([]);
  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const [isSwitchModalOpen, setSwitchModalOpen] = useState(false);
  const [selectOptions, setSelectOptions] = React.useState([]);
  const [numeratorValue, setNumeratorValue] = React.useState({ ano: '' });
  const [denominatorValue, setDenominatorValue] = React.useState({ ano: '' });
  const [novaTabela, setNovaTabela] = React.useState([]);
  const [novaTopline, setNovaTopline] = React.useState([]);
  const [funcaoExecutada, setfuncaoExecutada] = React.useState(false);

  const pessoalOpt = ['total', 'cargos', 'departamentos']
  var colorList = ['#eabef5', '#ffeaa7', '#fab1a0', '#74b9ff', '#55efc4']
  var meses = {
    Janeiro: '01', Fevereiro: '02', Marco: '03', Abril: '04', Maio: '05', Junho: '06',
    Julho: '07', Agosto: '08', Setembro: '09', Outubro: '10', Novembro: '11', Dezembro: '12'
  }

  useEffect(() => {
    setSelectedCombo(location.state.combo_id)
    loadData(parseInt(location.state.combo_id))
    loadTopline(parseInt(location.state.combo_id))
    loadColaboradores(parseInt(location.state.combo_id))
    loadMyUsers()
    // loadDepartamentos()
    // eslint-disable-next-line
  }, [location.state.combo_id])

  const handleEqualKeyDown = (formula, linhaId, contrato) => {
    let valor, valorCalculado;
    if (formula.includes('=')) {
      valor = editaBudgetAtualObj?.linha?.[linhaId];
      var expressaoAtualizada = formula.replaceAll('=', valor);
      valorCalculado = math.evaluate(expressaoAtualizada)
    }
    else {
      valorCalculado = math.evaluate(formula) + contrato;
    }
    return valorCalculado
  };

  function calculoAnosBudgetData(budget, conta, anoBudget, contaProp, tipoConta) {
    let dataBudget = {};
    if (!!!anoBudget) return;

    [anoBudget - 2, anoBudget - 1, anoBudget].forEach(ano => {
      if (ano === anoBudget) {

        Object.keys(meses).forEach((mes, idx) => {
          dataBudget[`anoBudget${mes}Formated`] = filterAndReduce(budget?.budget, conta.codigo_id, ano, idx + 1, 'codigo_id').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          dataBudget[`anoBudget${mes}`] = filterAndReduce(budget?.budget, conta.codigo_id, ano, idx + 1, 'codigo_id');
        });

        dataBudget[`anoBudgetTotalFormated`] = filterAndReduce(budget?.budget, conta.codigo_id, ano, null, 'codigo_id').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        dataBudget[`anoBudgetTotal`] = filterAndReduce(budget?.budget, conta.codigo_id, ano, null, 'codigo_id');

        dataBudget[`anoBudgetMediaFormated`] = formatNanValue(filterAndReduce(budget?.budget, conta.codigo_id, ano, null, 'codigo_id') / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        dataBudget[`anoBudgetMedia`] = formatNanValue(filterAndReduce(budget?.budget, conta.codigo_id, ano, null, 'codigo_id') / 12);
      } else {

        Object.keys(meses).forEach((mes, idx) => {
          dataBudget[`anoAnterior${anoBudget - ano}${mes}Formated`] = filterAndReduce(budget?.dadosPassados, conta[contaProp], ano, idx + 1, tipoConta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          dataBudget[`anoAnterior${anoBudget - ano}${mes}`] = filterAndReduce(budget?.dadosPassados, conta[contaProp], ano, idx + 1, tipoConta);

          dataBudget[`budgetAnterior${anoBudget - ano}${mes}Formated`] = filterAndReduce(budget?.orcamentosPassados, conta[contaProp], ano, idx + 1, tipoConta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          dataBudget[`budgetAnterior${anoBudget - ano}${mes}`] = filterAndReduce(budget?.orcamentosPassados, conta[contaProp], ano, idx + 1, tipoConta);
        })

        dataBudget[`anoAnterior${anoBudget - ano}TotalFormated`] = filterAndReduce(budget?.dadosPassados, conta[contaProp], ano, null, tipoConta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        dataBudget[`anoAnterior${anoBudget - ano}MediaFormated`] = (filterAndReduce(budget?.dadosPassados, conta[contaProp], ano, null, tipoConta) / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        dataBudget[`anoAnterior${anoBudget - ano}Total`] = filterAndReduce(budget?.dadosPassados, conta[contaProp], ano, null, tipoConta);
        dataBudget[`anoAnterior${anoBudget - ano}Media`] = (filterAndReduce(budget?.dadosPassados, conta[contaProp], ano, null, tipoConta) / 12);

        dataBudget[`budgetAnterior${anoBudget - ano}TotalFormated`] = filterAndReduce(budget?.orcamentosPassados, conta[contaProp], ano, null, tipoConta).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        dataBudget[`budgetAnterior${anoBudget - ano}MediaFormated`] = (filterAndReduce(budget?.orcamentosPassados, conta[contaProp], ano, null, tipoConta) / 12).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        dataBudget[`budgetAnterior${anoBudget - ano}Total`] = filterAndReduce(budget?.orcamentosPassados, conta[contaProp], ano, null, tipoConta);
        dataBudget[`budgetAnterior${anoBudget - ano}Media`] = (filterAndReduce(budget?.orcamentosPassados, conta[contaProp], ano, null, tipoConta) / 12);
      }
    });
    return dataBudget;
  }

  function filterAndReduce(data, codigo, ano, mes, tipoConta) {
    const filteredData = data?.filter(registro =>
      codigo.includes(registro[tipoConta]) && registro.ano === ano && (mes ? registro.mes === mes : true)
    );
    const reducedValue = filteredData?.reduce((acc, cv) => acc += cv.valor, 0) || 0;
    return reducedValue;
  }

  function anosAnterioresFormated(codigo, unidade, topline, anoBudget) {
    let formattedData = {};
    if (!!!anoBudget) return
    [anoBudget - 2, anoBudget - 1].forEach(ano => {

      Object.keys(meses).forEach((mes, idx) => {
        formattedData[`anoAnterior${anoBudget - ano}${mes}Formated`] = mesFormated(codigo, idx + 1, ano, unidade, topline, 'mesFormat');
        formattedData[`anoAnterior${anoBudget - ano}${mes}`] = mesFormated(codigo, idx + 1, ano, unidade, topline, 'mes');
      })

      formattedData[`anoAnterior${anoBudget - ano}TotalFormated`] = anoFormated(codigo, ano, unidade, topline, 'totalFormat');
      formattedData[`anoAnterior${anoBudget - ano}MediaFormated`] = anoFormated(codigo, ano, unidade, topline, 'mediaFormat');
      formattedData[`anoAnterior${anoBudget - ano}Total`] = anoFormated(codigo, ano, unidade, topline, 'total');
      formattedData[`anoAnterior${anoBudget - ano}Media`] = (codigo, ano, unidade, topline, 'media');
    });
    return formattedData;
  }

  function budgetAnterioresFormated(codigo, unidade, topline, anoBudget) {
    let formattedData = {};
    if (!!!anoBudget) return
    [anoBudget - 2, anoBudget - 1].forEach(ano => {

      Object.keys(meses).forEach((mes, idx) => {
        formattedData[`anoBudget${mes}Formated`] = mesFormated(codigo, idx + 1, anoBudget, unidade, topline, 'mesFormat');
        formattedData[`anoBudget${mes}`] = mesFormated(codigo, idx + 1, anoBudget, unidade, topline, 'mes');
        formattedData[`budgetAnterior${anoBudget - ano}${mes}`] = mesFormated(codigo, idx + 1, ano, unidade, topline, 'mes');
        formattedData[`budgetAnterior${anoBudget - ano}${mes}Formated`] = mesFormated(codigo, idx + 1, ano, unidade, topline, 'mesFormat');
      });
      formattedData[`anoBudgetTotalFormated`] = anoFormated(codigo, anoBudget, unidade, topline, 'totalFormat');
      formattedData[`anoBudgetMediaFormated`] = (anoFormated(codigo, anoBudget, unidade, topline, 'mediaFormat'));
      formattedData[`anoBudgetTotal`] = anoFormated(codigo, anoBudget, unidade, topline, 'total');
      formattedData[`anoBudgetMedia`] = (anoFormated(codigo, anoBudget, unidade, topline, 'media'));
      formattedData[`budgetAnterior${anoBudget - ano}Total`] = anoFormated(codigo, ano, unidade, topline, 'total');
      formattedData[`budgetAnterior${anoBudget - ano}Media`] = formatNanValue(anoFormated(codigo, ano, unidade, topline, 'media'));
      formattedData[`budgetAnterior${anoBudget - ano}TotalFormated`] = anoFormated(codigo, ano, unidade, topline, 'totalFormat');
      formattedData[`budgetAnterior${anoBudget - ano}MediaFormated`] = formatNanValue(anoFormated(codigo, ano, unidade, topline, 'mediaFormat'));
    });
    return formattedData;
  }

  function formatNanValue(value) {
    if (isNaN(value) && typeof value === 'number') {
      return (0)
    }
    else if (typeof value === 'string' && value?.includes('NaN')) {
      return (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return value
  }

  function mesFormated(codigo, mes, ano, unidade, topline, func) {
    return formatTopLine.find(formatModel => formatModel.codigo_id === codigo)[func](codigo, mes, ano, unidade, topline);
  }

  function anoFormated(codigo, ano, unidade, topline, func) {
    return formatTopLine.find(formatModel => formatModel.codigo_id === codigo)[func](codigo, ano, unidade, topline);
  }

  // calculando o budget
  function calcularBudget(linha) {
    let soma = 0;
    for (let mes of Object.keys(meses)) {
      soma += parseFloat(linha['anoBudget' + mes]);
    }

    const media = soma / 12;

    return {
      anoBudgetTotal: soma,
      anoBudgetMedia: media,
      anoBudgetTotalFormated: linha.format(soma),
      anoBudgetMediaFormated: linha.format(media)
    };
  }

  // Usando a função para construir as opções
  function buildSelectOptions(anoAtual) {
    const anos = [anoAtual - 2, anoAtual - 1, anoAtual];

    const options = anos.reduce((acc, cv) => {
      if (cv === anoAtual) {
        return [...acc, { value: { ano: cv, tipo: 'orcado' }, label: `Comparar ${cv} orçado?` }];
      } else {
        return [
          ...acc,
          { value: { ano: cv, tipo: 'orcado' }, label: `Comparar ${cv} orçado?` },
          { value: { ano: cv, tipo: 'realizado' }, label: `Comparar ${cv} realizado?` }
        ];
      }
    }, []);

    var arraySelects = [];
    Array(2).fill(1).map((el, idx) => {
      arraySelects.push({
        label: idx === 0 ? `Numerador` : 'Denominador',
        options,
        isMulti: false,
        onSelect: (value) => {
          if (idx === 0) {
            setNumeratorValue(value);
          } else {
            setDenominatorValue(value);
          }
        }
      });
    });
    setSelectOptions(arraySelects);
  }

  function getCodigo(anoAtual, tipo) {
    var budgetDataKey = ''
    var tipoTraducao = tipo === 'orcado' ? 'budget' : 'ano'
    switch (anoAtual) {
      case parseInt(anoBudget): budgetDataKey = 'anoBudget' + 'Media'; break;
      case anoBudget - 1: budgetDataKey = tipoTraducao + 'Anterior1' + 'Media'; break;
      case anoBudget - 2: budgetDataKey = tipoTraducao + 'Anterior2' + 'Media'; break;
      default: break;
    }
    return budgetDataKey
  }
  function calculoVariacaoVerticalMedia(valor, codigoAtual) {
    var codigo = getCodigo(codigoAtual.ano, codigoAtual.tipo);
    var base = budgetData.find(d => d.codigo_id === baseVertical.id);
    if (base) {
      var baseValor = base[codigo];
      if (valor.codigo_id !== baseVertical.id && baseValor !== 0) {
        return (valor[codigo] / baseValor).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 });
      }
    }
  }

  function calculoVariacaoVerticalNovaVariacao(codigo) {
    var linhaBase = novaTabela.find(linha => linha.codigo_id === baseVertical.id);
    var linhaAtual = novaTabela.find(linha => linha.codigo_id === codigo);
    if (linhaBase && linhaAtual && baseVertical.id !== codigo) {
      var variacao = (linhaAtual.resultNumber / linhaBase.resultNumber);
      return variacao.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 });
    } else {
      return '';
    }
  }

  function atualizaObjetosPerguntasRespostasComentarios(novoAnexo, novoComentario, selectedUser) {
    setBudgetDataMemory(prevState => ({
      ...prevState,
      comentariosBudgetInfo: {
        ...prevState.comentariosBudgetInfo,
        anexos: novoAnexo === undefined ? [...prevState.comentariosBudgetInfo.anexos] : [novoAnexo, ...prevState.comentariosBudgetInfo.anexos],
        comentarios: [
          novoComentario, ...prevState.comentariosBudgetInfo.comentarios],
        destinatarios: selectedUser === undefined ? [...prevState.comentariosBudgetInfo.destinatarios] : [...selectedUser, ...prevState.comentariosBudgetInfo.destinatarios]
      }
    }));
  }

  function variacaoMedia() {
    var novaTabelaData = budgetData.map(linha => {
      var numeradorAtual = linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)];
      var denominadorAtual = linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)];
      var result = calculoVariacaoMedia(denominadorAtual, numeradorAtual);
      var numerador = (numeradorAtual);
      var denominador = (denominadorAtual);
      var numeradorrbo = calcularNumerador(linha, 'RECEITA LIQUIDA');
      var denominadorrbo = calcularDenominador(linha, 'RECEITA LIQUIDA');
      var resultrbo = calculoVariacaoMedia(denominadorrbo, numeradorrbo);
      var variacaoVerticalNumerador = calculoVariacaoVerticalMedia(linha, numeratorValue);
      var variacaoVerticalDenominador = calculoVariacaoVerticalMedia(linha, denominatorValue);
      var resultNumber = calculoVariacaoMedia(denominadorAtual, numeradorAtual, false);
      return {
        result,
        resultNumber,
        denominador,
        numerador,
        numeradorrbo,
        denominadorrbo,
        resultrbo,
        variacaoVerticalNumerador,
        variacaoVerticalDenominador,
        codigo_id: linha.codigo_id,
      };
    });

    var novaTopline = topLineBudgetData.map(linha => {
      var numeradorAtual = linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)];
      var denominadorAtual = linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)];
      var result = calculoVariacaoMedia(denominadorAtual, numeradorAtual);
      var numerador = parseFloat(numeradorAtual);
      var denominador = parseFloat(denominadorAtual);
      return {
        result,
        unidade_id: linha.unidade_id,
        codigo_id: linha.codigo_id,
        numerador,
        denominador,

      };
    });
    setNovaTopline(novaTopline);
    setNovaTabela(novaTabelaData);
  }

  function calcularNumerador(linha, codigo_id) {
    const valor = parseFloat(linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)]);
    const valorReferencia = parseFloat(budgetData.find(d => d.codigo_id === codigo_id)[getCodigo(numeratorValue.ano, numeratorValue.tipo)]);
    return valor / valorReferencia;
  }

  function calcularDenominador(linha, codigo_id) {
    const valor = parseFloat(linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)]);
    const valorReferencia = parseFloat(budgetData.find(d => d.codigo_id === codigo_id)[getCodigo(denominatorValue.ano, denominatorValue.tipo)]);
    return valor / valorReferencia;
  }

  function mostrarColuna() {
    variacaoMedia();
    setfuncaoExecutada(true);
  }

  function esconderColuna() {
    variacaoMedia();
    setfuncaoExecutada(false);
  }

  function handleOpenSelectModal() {
    setSelectModalOpen(true);
  }
  function handleCloseSelectModal() {
    setSelectModalOpen(false);
  }

  function handleOpenSwitchModal() {
    setSwitchModalOpen(true);
  }

  function handleCloseSwitchModal() {
    setSwitchModalOpen(false);
  }

  function builtOptionsYear(anoAtual) {
    const anos = [{ label: anoAtual - 2 }, { label: anoAtual - 1 }]
    var options = []
    anos.map((b) => {
      options.push({ onToggle: () => handleToggle(b.label, 'orcado'), checked: false, label: "Ocultar " + b.label + ' orçado' + '?' });
      options.push({ onToggle: () => handleToggle(b.label, 'realizado'), checked: false, label: "Ocultar " + b.label + ' realizado' + '?' });
    });
    setOptionsYears(options)
  }

  // Função de dar toggle nas colunas/anos 
  const handleToggle = (ano, tipo) => {
    const key = `${ano}-${tipo}`;
    setHiddenColumns(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
    setOptionsYears(prevOptions => {

      return prevOptions.map(option => {
        if (option.label.includes(`${ano}`) && option.label.includes(tipo.replaceAll('c', 'ç'))) {
          return {
            ...option,
            checked: !option.checked
          };
        }
        return option;
      });
    });
  };

  const handleExibicaoPessoal = () => {
    const currentIndex = pessoalOpt.indexOf(ocultaQuadroPessoal.exibicaoPessoal);
    const nextIndex = (currentIndex + 1) % pessoalOpt.length;
    setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, exibicaoPessoal: pessoalOpt[nextIndex] });
  }

  const handleOcultarLinhas = (codigo_id) => {
    setHiddenLines(hiddenLines => ({ ...hiddenLines, [codigo_id]: !hiddenLines[codigo_id] }))
  }

  async function loadMyUsers() {
    if (!myUsers) {
      var res = await api.get('/usuarios', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
      var myArr = []
      res.data.reduce((acc, cv) => { if (cv.id !== cookies.usuario.id) { myArr.push({ label: cv.nome, value: cv.id }) } }, 0)
      setMyUsers(myArr)
    }
  }

  function isNanResult(line) {
    const denominatorData = budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA');
    const denominator = denominatorData ? denominatorData.anoBudgetMedia : 0;
    const calculation = denominator !== 0 ? parseFloat(line.anoBudgetMedia) / denominator : '';
    const color = isNaN(calculation) ? '#000' : 'white';
    return color
  }


  function calculoVariacaoVertical(valor, item) {
    var itemSemFormat = item.replace('Formated', '')
    var base = budgetData.find(d => d.codigo_id === baseVertical.id)
    if (base) {
      var baseValor = base[itemSemFormat]
      if (valor.codigo_id !== baseVertical.id && baseValor !== 0) {
        return (valor[itemSemFormat] / baseValor).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
      }
    }
  }


  function handleBase(id) {
    if (id) {
      if (baseVertical.id) {
        document.getElementById('linha-' + baseVertical.id).classList.remove('selectedVertical')
        document.getElementById('linha-' + id).classList.add('selectedVertical')
        setBaseVertical({ id })
      } else {
        document.getElementById('linha-' + id).classList.add('selectedVertical')
        setBaseVertical({ id })
      }
    } else {
      setBaseVertical({ id })
    }
  }

  function clearVariacao() {
    if (baseVertical.id) {
      document.getElementById('linha-' + baseVertical.id).classList.remove('selectedVertical')
      setBaseVertical({ id: null })
      setVariacaoVertical(false)
    }
  }

  const notify = ($msg) => toast($msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  function calcDataTopline(topline, combo) {

    var toplineCalc = []
    JSON.parse(sessionStorage.getItem('unidades'))?.filter(unids => unids.combo_id === combo && unids.uhs !== null).map((unidadeAtual, idx) => {
      formatTopLine.map(codigoLinha => {
        toplineCalc.push({
          color: colorList[idx],
          unidade_id: unidadeAtual.id,
          codigo_id: codigoLinha.codigo_id,
          descricao: codigoLinha.descricao,
          ...anosAnterioresFormated(codigoLinha.codigo_id, unidadeAtual.id, topline.toplineRealizado, topline.anoBudget),
          ...budgetAnterioresFormated(codigoLinha.codigo_id, unidadeAtual.id, topline.toplineOrcado, topline.anoBudget),
          format: codigoLinha.format

        })
      })
    })
    setTopLineBudgetData(toplineCalc)
  }

  function calcDataBudget(budget, combo, status) {
    const newContasDre = budget?.contasDre.map(conta => {
      const newConta = { ...conta };
      newConta.isAliquota = false
      newConta.isContrato = false
      newConta.format = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

      if (newConta.composicao !== null) {
        return {
          ...newConta,
          ...calculoAnosBudgetData(budget, newConta, budget.anoBudget, 'composicao', 'codigo_id')
        };
      } else {
        return {
          ...newConta,
          ...calculoAnosBudgetData(budget, newConta, budget.anoBudget, 'codigo_id', 'codigodre_id')
        };

      }
    })

    // if status 0 gerar budget if status 1 recarregar budget
    if (budget.contratos.length > 0 && status === 1) { reloadContratos({ ...budget, contasDre: newContasDre }); } else { getContratos({ ...budget, contasDre: newContasDre }) }
    if (budget.aliquotas.length > 0) calculaAliquotas({ ...budget, contasDre: newContasDre })

    calculaContalvlUp(newContasDre)
    setBudgetData(newContasDre)
    setBudgetDataMemory({ ...budget, contasDre: newContasDre })

    if (minhasUnidades.find(d => d.combo_id === combo && d.id.includes('R')) && minhasUnidades.find(d => d.combo_id === combo && d.id.includes('H'))) {
      fixConsolidado({ ...budget, contasDre: newContasDre })
    }
  }

  function calculaContalvlUp(budget) {
    const mesesArray = [...Object.keys(meses), 'Total', 'Media'];


    budget?.filter(conta => conta.composicao !== null).map(conta => {
      mesesArray.forEach(mes => {
        const prop = `anoBudget${mes}`;
        const valor = budget?.filter(contas => conta.composicao.includes(contas.codigo_id))
          .reduce((acc, cv) => acc += cv[prop], 0);
        conta[prop] = valor;
        conta[`${prop}Formated`] = conta.format(valor);
      });
    });
  }

  function calculaAliquotas(budget) {
    budget?.aliquotas.map(aliquota => {
      var conta = budget?.contasDre.find(conta => conta.codigo_id === aliquota.codigo_id);
      let anoBudgetTotal = 0;

      Object.keys(meses).forEach(mes => {
        const valor = budget?.contasDre?.filter(conta => aliquota.contasReferencia.includes(conta.codigo_id))
          .reduce((acc, cv) => acc += cv[`anoBudget${mes}`], 0) * (((aliquota.percReferencia) * (-1)) / 100);
        conta[`anoBudget${mes}`] = valor;
        conta[`anoBudget${mes}Formated`] = conta.format(valor);
        anoBudgetTotal += valor;
      });

      const anoBudgetMedia = anoBudgetTotal / Object.keys(meses).length;
      conta.anoBudgetTotal = anoBudgetTotal;
      conta.anoBudgetMedia = anoBudgetMedia;
      conta.anoBudgetTotalFormated = conta.format(anoBudgetTotal);
      conta.anoBudgetMediaFormated = conta.format(anoBudgetMedia);

      conta.isAliquota = true
      conta.aliquota = {
        ...aliquota,
        texto: "Aliquota de " + conta.descricao + " é " + aliquota.percReferencia + "% de " + budget?.contasDre?.filter(conta => aliquota.contasReferencia.includes(conta.codigo_id)).reduce((acc, cv) => acc += cv.descricao + ', ', '')
      }
    })
    calculaContalvlUp(budget.contasDre)
  }

  //valido apenas até junho de 22
  function fixConsolidado(budget) {
    budget?.contasDre.map(linha => {
      if ((linha.codigo_id === '320101' || linha.codigo_id === 'RECEITA DE ALIMENTOS E BEBIDAS' || linha.codigo_id === 'RECEITA  BRUTA' || linha.codigo_id === 'RECEITA LIQUIDA')) {
        linha.anoAnterior2Janeiro += budget?.dadosPassadosConsolidacao?.filter(data => data.mes === 1 && data.ano === 2022).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Fevereiro += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 2).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Marco += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 3).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Abril += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 4).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Maio += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 5).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Junho += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 6).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Julho += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 7).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Agosto += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 8).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Setembro += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 9).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Outubro += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 10).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Novembro += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 11).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Dezembro += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 12).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Total += budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Media = (linha.anoAnterior2Total / 12)

        linha.anoAnterior2JaneiroFormated = linha.format(linha.anoAnterior2Janeiro)
        linha.anoAnterior2FevereiroFormated = linha.format(linha.anoAnterior2Fevereiro)
        linha.anoAnterior2MarcoFormated = linha.format(linha.anoAnterior2Marco)
        linha.anoAnterior2AbrilFormated = linha.format(linha.anoAnterior2Abril)
        linha.anoAnterior2MaioFormated = linha.format(linha.anoAnterior2Maio)
        linha.anoAnterior2JunhoFormated = linha.format(linha.anoAnterior2Junho)
        linha.anoAnterior2JulhoFormated = linha.format(linha.anoAnterior2Julho)
        linha.anoAnterior2AgostoFormated = linha.format(linha.anoAnterior2Agosto)
        linha.anoAnterior2SetembroFormated = linha.format(linha.anoAnterior2Setembro)
        linha.anoAnterior2OutubroFormated = linha.format(linha.anoAnterior2Outubro)
        linha.anoAnterior2NovembroFormated = linha.format(linha.anoAnterior2Novembro)
        linha.anoAnterior2DezembroFormated = linha.format(linha.anoAnterior2Dezembro)
        linha.anoAnterior2TotalFormated = linha.format(linha.anoAnterior2Total)
        linha.anoAnterior2MediaFormated = linha.format(linha.anoAnterior2Media)

      } else if ((linha.codigo_id === '410101' || linha.codigo_id === 'CUSTOS DE ALIMENTOS E BEBIDAS' || linha.codigo_id === 'TOTAL DOS CUSTOS' || linha.codigo_id === 'TOTAL DE CUSTOS E DESPESAS OPERACIONAIS')) {
        linha.anoAnterior2Janeiro -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 1).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Fevereiro -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 2).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Marco -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 3).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Abril -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 4).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Maio -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 5).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Junho -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 6).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Julho -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 7).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Agosto -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 8).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Setembro -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 9).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Outubro -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 10).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Novembro -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 11).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Dezembro -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022 && data.mes === 12).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Total -= budget?.dadosPassadosConsolidacao?.filter(data => data.ano === 2022).reduce((acc, cv) => acc += cv.valor, 0)
        linha.anoAnterior2Media = (linha.anoAnterior2Total / 12)

        linha.anoAnterior2JaneiroFormated = linha.format(linha.anoAnterior2Janeiro)
        linha.anoAnterior2FevereiroFormated = linha.format(linha.anoAnterior2Fevereiro)
        linha.anoAnterior2MarcoFormated = linha.format(linha.anoAnterior2Marco)
        linha.anoAnterior2AbrilFormated = linha.format(linha.anoAnterior2Abril)
        linha.anoAnterior2MaioFormated = linha.format(linha.anoAnterior2Maio)
        linha.anoAnterior2JunhoFormated = linha.format(linha.anoAnterior2Junho)
        linha.anoAnterior2JulhoFormated = linha.format(linha.anoAnterior2Julho)
        linha.anoAnterior2AgostoFormated = linha.format(linha.anoAnterior2Agosto)
        linha.anoAnterior2SetembroFormated = linha.format(linha.anoAnterior2Setembro)
        linha.anoAnterior2OutubroFormated = linha.format(linha.anoAnterior2Outubro)
        linha.anoAnterior2NovembroFormated = linha.format(linha.anoAnterior2Novembro)
        linha.anoAnterior2DezembroFormated = linha.format(linha.anoAnterior2Dezembro)
        linha.anoAnterior2TotalFormated = linha.format(linha.anoAnterior2Total)
        linha.anoAnterior2MediaFormated = linha.format(linha.anoAnterior2Media)
      }
    })
  }

  async function loadColaboradores(combo) {
    try {
      var resColabsCC = await api.get('/funcionarios_realizado_budget?combo_id=' + combo, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
      setColabCC(resColabsCC.data)
    } catch (error) {
      console.error('Erro ao pegar numero de colaboradores!' + error)
      notify('Erro ao pegar numero de colaboradores!')
    }
  }

  async function loadTopline(combo) {
    try {
      await api.get('/toplineBudget/' + combo, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        .then(async resTopLine => {
          if (resTopLine.data) calcDataTopline(resTopLine.data, combo)
        })
    }
    catch (error) {
      console.error('Erro ao pegar topline passado!' + error)
      dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: false })
      notify('Erro ao pegar topline passado!')
    }
  }

  async function loadData(combo) {
    dispatch({ type: 'OPEN_LOADING', text: "Carregando budget...", isLoading: true })

    if (document.getElementById('BHano1').getAttribute('colspan') !== '3') {
      document.getElementById('Hano1').click()
    }
    if (document.getElementById('Hano1').getAttribute('colspan') !== '3') {
      document.getElementById('Hano1').click()
    }
    if (document.getElementById('Hano2').getAttribute('colspan') !== '3' && tipoVar === true) {
      document.getElementById('Hano2').click()
    }
    if (document.getElementById('BHano2').getAttribute('colspan') !== '3') {
      document.getElementById('Hano2').click()
    }
    if (document.getElementById('Hano3').getAttribute('colspan') !== '3') {
      document.getElementById('Hano3').click()
    }

    try {
      await api.get(`/buildBudget/${combo}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        .then(async resPastData => {
          buildSelectOptions(resPastData.data.anoBudget)
          builtOptionsYear(resPastData.data.anoBudget)
          setCurrentMonth(resPastData.data.maxMonth[0].max)
          setIsEditable(resPastData.data.is_editable.is_editable)
          setBudgetDataMemory(resPastData.data)
          setAnoBudget(resPastData.data.anoBudget)
          calcDataBudget(resPastData.data, combo, resPastData.data.status)
          dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: false })
          if (resPastData.data.status === 0) salvarBudget()
        })
    } catch (error2) {
      console.error('Erro ao pegar os dados do Budget! ' + error2)
      dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: false })
      notify('Erro ao pegar os dados do Budget!')
    }
  }

  function hideShowLines(classname, e, headerId) {
    handleOcultarLinhas(classname)
    handleOcultarLinhas(headerId)
    var arr = [...document.getElementsByClassName(classname)];
    if (e) {
      arr.map(linha => linha.classList.value.includes('linha-zerada') ? null : linha.hidden = !linha.hidden)
      if (!arr[0].hidden) {
        e.target.colSpan += 12

      } else {
        e.target.colSpan -= 12

      }
    } else {
      arr.map(linha => linha.classList.value.includes('linha-zerada') ? null : linha.hidden = !linha.hidden)
    }
  }

  function filtraRazao(searchRazaoText) {
    const filteredRazao = razaoDataMemory?.filter(registro => {
      let registroLowercase = (registro.historico).toLowerCase();

      let searchTermLowercase = searchRazaoText.toLowerCase();

      return registroLowercase.indexOf(searchTermLowercase) > -1 ||
        registro.centrodecusto_id.includes(searchRazaoText) ||
        registro.data.includes(searchRazaoText) ||
        (registro.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' C').includes(searchRazaoText) ||
        (registro.debito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' D').includes(searchRazaoText);
    });
    setRazaoData(filteredRazao)
  }

  async function openRazao(codigo, mes, ano, origem, valor) {
    dispatch({ type: 'OPEN_LOADING', text: "Buscando registros...", isLoading: true })

    var unidades = JSON.parse(sessionStorage.getItem('unidades'))?.filter(unids => unids.combo_id === selectedCombo).reduce((acc, cv) => acc = [...acc, cv.id], [])
    unidades = JSON.stringify(unidades)

    await api.get(`/registrorazaomes/${unidades}/${codigo}/${mes}/${ano}/${mes}?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
      .then(res => {
        if (res.data.razao.length) {
          setRazaoData(res.data.razao)
          setRazaoDataMemory(res.data.razao)
          setAnexosRazao(res.data.anexosRazao)
          setModalShow([true, budgetData.find(d => d.codigo_id === codigo).descricao, ano, mes, origem, valor])
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        } else {
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
          notify("Nenhum lançamento registrado!")
        }
      })
  }

  //todo
  function calculoVariacaoMedia(denominador, numerador, formated) {
    var valor = (numerador - denominador) / denominador
    if (!isFinite(valor)) return '-'
    if (isNaN(valor)) return '-'
    if (formated === false) return valor
    return (valor).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
  }

  async function reloadContratos(budgetDataIn) {
    budgetDataIn.contratos.map(contrato => {
      var conta = budgetDataIn.contasDre.find(linha => linha.codigo_id === contrato.planodecontas_id)
      conta.contrato = budgetDataIn.contratos?.filter(contrato => contrato.planodecontas_id === conta.codigo_id)
      conta.isContrato = true
    })
  }

  function getContratos(budgetDataIn) {
    budgetDataIn.contratos.map(contrato => {
      var conta = budgetDataIn.contasDre.find(linha => linha.codigo_id === contrato.planodecontas_id);
      var aniversarioMes = parseInt(contrato.aniversario.substring(5, 7));
      conta.contrato = budgetDataIn.contratos?.filter(contrato => contrato.planodecontas_id === conta.codigo_id);
      conta.isContrato = true;
      let total = 0;

      Object.keys(meses).forEach((mes, i) => {
        const mesIndex = i + 1;
        if (aniversarioMes <= mesIndex) {
          const valorCalculado = (Math.abs((contrato.valor * ((parseFloat(contrato.indicereajuste_id) / 100) + 1))) * -1);
          conta[`anoBudget${mes}`] += valorCalculado;
          conta[`contrato${mes}`] = conta[`anoBudget${mes}`];
        } else {
          const valorCalculado = (Math.abs(contrato.valor) * -1);
          conta[`anoBudget${mes}`] += valorCalculado;
          conta[`contrato${mes}`] = conta[`anoBudget${mes}`];
        }
        conta[`anoBudget${mes}Formated`] = conta.format(conta[`anoBudget${mes}`]);
        conta[`contrato${mes}Formated`] = conta.format(conta[`contrato${mes}`]);
        total += conta[`anoBudget${mes}`];
      });
      conta.anoBudgetTotal = total;
      conta.anoBudgetMedia = total / 12;
      conta.anoBudgetTotalFormated = conta.format(conta.anoBudgetTotal);
      conta.anoBudgetMediaFormated = conta.format(conta.anoBudgetMedia);
    });

    calculaContalvlUp(budgetDataIn.contasDre);
  }


  async function salvarBudget() {
    var myBudgetData = []
    budgetData.map(linha => {
      Object.keys(meses).forEach((mes, idx) =>
        myBudgetData.push({
          combo_id: selectedCombo,
          codigodre_id: linha.codigo_id,
          ano: anoBudget,
          valor: linha['anoBudget' + mes],
          usuario_id: cookies.usuario.id,
          mes: idx + 1,
        })
      )
    })

    try {
      await api.post('/budgethoteis', {
        budgetData: myBudgetData
      }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
        // dispatch({ type: 'OPEN_LOADING', text: "Carregando budget...", isLoading: false })
        notify("Salvo com sucesso!")
      })

    } catch (err) {
      console.error("Erro ao salvar o budget: " + err)
      notify("Erro ao salvar o budget!")
    }

  }

  async function updateBudgetImportarValores() {
    var contaImportarValores = importarValores?.conta?.codigo_id
    var anoImportarValores = importarValores?.ano?.codigo_id
    var tipoImportarValores = importarValores?.realizadoOrcado?.codigo_id
    var taxaImportarValores = document.getElementById('taxaImportarValores').value
    var typeConta = importarValores?.conta?.type

    if (typeConta === 'topline') {
      var unidadeOp = importarValores?.unidade
    }
    var linha = editaBudgetAtualObj.linha

    if (!contaImportarValores || !anoImportarValores || !tipoImportarValores || (typeConta === 'topline' && !unidadeOp)) {
      if (!contaImportarValores) notify('Selecione uma conta!');
      if (!anoImportarValores) notify('Selecione um ano!');
      if (!tipoImportarValores) notify('Selecione um tipo!');
      if (typeConta === 'topline' && !unidadeOp) notify('Selecione uma unidade!');
      return
    }

    var objKey = (anoBudget - anoImportarValores) === 0 ? 'anoBudget' : (tipoImportarValores === 'orcado' ? 'budgetAnterior' : 'anoAnterior') + (anoBudget - anoImportarValores)
    var contaOrigem = typeConta === 'topline' ? topLineBudgetData.filter(linha => linha.codigo_id === contaImportarValores) : budgetData.filter(linha => linha.codigo_id === contaImportarValores)
    let total = 0;

    for (let mes of Object.keys(meses)) {
      var taxa = taxaImportarValores.includes('%') ? taxaImportarValores.replace(/[^0-9,.-]/g, '').replace(',', '.') / 100 : taxaImportarValores.replace(/[^0-9,.-]/g, '').replace(',', '.')
      var valorAtualiza = 0
      switch (unidadeOp?.codigo_id) {
        case 'soma':
          valorAtualiza = contaOrigem.reduce((acc, cv) => acc += cv[objKey + mes], 0) * taxa
          linha['anoBudget' + mes] = valorAtualiza
          linha['anoBudget' + mes + 'Formated'] = linha.format(valorAtualiza)
          break;
        case 'media':
          valorAtualiza = (contaOrigem.reduce((acc, cv) => acc += cv[objKey + mes], 0) / contaOrigem.length) * taxa
          linha['anoBudget' + mes] = valorAtualiza
          linha['anoBudget' + mes + 'Formated'] = linha.format(valorAtualiza)
          break;
        default:
          if (typeConta === 'topline') {
            valorAtualiza = contaOrigem.find(linha => linha.unidade_id === unidadeOp.codigo_id)[objKey + mes] * taxa
            linha['anoBudget' + mes] = valorAtualiza
            linha['anoBudget' + mes + 'Formated'] = linha.format(valorAtualiza)
            break;
          } else {
            valorAtualiza = contaOrigem[0][objKey + mes] * taxa
            linha['anoBudget' + mes] = valorAtualiza
            linha['anoBudget' + mes + 'Formated'] = linha.format(valorAtualiza)
            break;
          }
      }
      total += linha['anoBudget' + mes];
    }

    linha.anoBudgetTotal = total;
    linha.anoBudgetTotalFormated = linha.format(linha.anoBudgetTotal);

    linha.anoBudgetMedia = total / 12;
    linha.anoBudgetMediaFormated = linha.format(linha.anoBudgetMedia);

    try {
      await api.post('/budgethoteis/linha', {
        linha,
        combo_id: selectedCombo,
        usuario_id: cookies.usuario.id
      }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        .then(res => {
          notify("Salvo com sucesso!")
          calculaContalvlUp(budgetData)
        })

    } catch (err) {
      console.error("Erro ao salvar o budget: " + err)
      notify("Erro ao salvar o budget!")
    }

    setImportarValores({})
    document.getElementById('taxaImportarValores').value = null
    setEditaBudgetAtualObj({ modalVisivel: false })
  }

  function updateBudgetMesAMes(linha, valor, isContrato) {
    let somaTotal = 0;
    if (isContrato === true) {
      if (valor === true) {
        //sintetica

        Object.keys(meses).forEach(mes => {
          const budgetMes = parseFloat(
            budgetData?.filter(linhasComp =>
              linha.composicao.includes(linhasComp.codigo_id)
            ).reduce((acc, cv) =>
              acc += cv[`anoBudget${mes}`], 0
            )
          );

          linha[`anoBudget${mes}Formated`] = linha.format(budgetMes);
          linha[`anoBudget${mes}`] = budgetMes;
        });

        const somaAnoBudget = Object.keys(meses).reduce((soma, mes) =>
          soma + parseFloat(linha[`anoBudget${mes}`]), 0
        );
        const mediaAnoBudget = somaAnoBudget / 12;

        Object.keys(meses).forEach(mes => {
          somaAnoBudget += parseFloat(linha[`anoBudget${mes}`]);
        });

        linha.anoBudgetTotal = somaAnoBudget;
        linha.anoBudgetMedia = mediaAnoBudget;
        linha.anoBudgetTotalFormated = linha.format(somaAnoBudget) + '';
        linha.anoBudgetMediaFormated = linha.format(mediaAnoBudget) + '';

        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetMesAMes(proximaLinha, true, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      } else {
        //analitica         
        Object.keys(meses).forEach(mes => {
          const valorInput = parseFloat(document.getElementById(`novoValorBudgetMesAMes`).value);
          if (!isNaN(valorInput)) {

            const valorAjustado = linha.contrato.reduce((acc, contrato) => {
              const mesAniversario = parseInt(contrato.aniversario.substring(5, 7));
              const indiceAjuste = 1 + (parseFloat(contrato.indicereajuste_id) / 100);
              return acc - (contrato.valor * (mesAniversario <= Object.keys(meses).indexOf(mes) + 1 ? indiceAjuste : 1));
            }, valorInput);

            linha[`anoBudget${mes}`] = valorAjustado;
            linha[`anoBudget${mes}Formated`] = linha.format(valorAjustado);
          }
        });

        Object.keys(meses).forEach(mes => {
          const valorMes = parseFloat(linha[`anoBudget${mes}`]);

          if (!isNaN(valorMes)) {
            somaTotal += valorMes;
            contadorMesesValidos++;
          }
        });

        linha.anoBudgetMedia = somaTotal / contadorMesesValidos;
        linha.anoBudgetMediaFormated = linha.format(linha.anoBudgetMedia);
        linha.anoBudgetTotal = somaTotal;
        linha.anoBudgetTotalFormated = linha.format(somaTotal);

        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetMesAMes(proximaLinha, true, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      }
    } else {
      if (valor === true) {
        //sintetica
        Object.keys(meses).forEach(mes => {
          const campoBudget = `anoBudget${mes}`
          const valor = parseFloat(budgetData?.filter(linhasComp => linha.composicao.includes(linhasComp.codigo_id)).reduce((acc, cv) => acc += cv[campoBudget], 0));
          linha[campoBudget] = valor
          linha[`${campoBudget}Formated`] = linha.format(valor);

        })

        Object.keys(meses).forEach(mes => {
          const campoBudget = `anoBudget${mes}`
          somaTotal += parseFloat(linha[campoBudget])
        });

        linha.anoBudgetTotal = somaTotal
        linha.anoBudgetMedia = somaTotal / Object.keys(meses).length;

        linha.anoBudgetTotalFormated = linha.format(linha.anoBudgetTotal)
        linha.anoBudgetMediaFormated = linha.format(linha.anoBudgetMedia)

        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetMesAMes(proximaLinha, true, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      } else {
        //analitica
        Object.keys(meses).forEach(mes => {
          const elementoInput = document.getElementById(`novoValorBudgetMesAMes${mes}`)
          if (elementoInput && !isNaN(parseFloat(elementoInput.value))) {
            const valor = parseFloat(elementoInput.value)
            linha[`anoBudget${mes}`] = valor
            linha[`anoBudget${mes}Formated`] = linha.format(valor)
          }
        })

        Object.keys(meses).forEach(mes => {
          const campoBudget = `anoBudget${mes}`
          somaTotal += parseFloat(linha[campoBudget])
        });

        linha.anoBudgetTotal = somaTotal
        linha.anoBudgetMedia = somaTotal / Object.keys(meses).length;

        linha.anoBudgetTotalFormated = linha.format(linha.anoBudgetTotal)
        linha.anoBudgetMediaFormated = linha.format(linha.anoBudgetMedia)


        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetMesAMes(proximaLinha, true, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      }
    }
  }

  function updateBudgetPeriodo(linha, valor, qtd, isContrato) {
    // Func (linha que esta somando, se valor for true então é uma conta analitica se for false é conta sintetica,
    // qtd é o novo valor a ser somado)
    if (isContrato === true) {
      if (qtd === undefined || qtd === '') {
        qtd = 0
      }
      if (valor === true) {
        if (parseInt(mesEditaFinal) - parseInt(mesEdita) < 0) {
          return notify("Mes final maior que o inicial!")
        }
        var valorSoma = 0
        for (let i = parseInt(mesEdita); i <= parseInt(mesEditaFinal); i++) {
          valorSoma = budgetData?.filter(linhasComp => linha.composicao.includes(linhasComp.codigo_id)).reduce((acc, cv) => acc += cv['anoBudget' + Object.keys(meses)[i - 1]], 0)
          linha['anoBudget' + Object.keys(meses)[i - 1] + 'Formated'] = linha.format(parseFloat(valorSoma))
          linha['anoBudget' + Object.keys(meses)[i - 1]] = parseFloat(valorSoma)
        }

        linha.anoBudgetTotal = calcularBudget(linha).anoBudgetTotal
        linha.anoBudgetMedia = calcularBudget(linha).anoBudgetMedia
        linha.anoBudgetTotalFormated = calcularBudget(linha).anoBudgetTotalFormated
        linha.anoBudgetMediaFormated = calcularBudget(linha).anoBudgetMediaFormated


        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetPeriodo(proximaLinha, true, null, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      } else {
        if (Number(mesEditaFinal) - Number(mesEdita) < 0) return notify("Mes final maior que o inicial!")
        for (let i = Number(mesEdita); i <= Number(mesEditaFinal); i++) {

          var valorContrato = linha.contrato.reduce((acc, cv) => {
            if (Number(cv.aniversario.substring(5, 7)) <= Number(i)) {
              return acc -= (cv.valor * (1 + (parseFloat(cv.indicereajuste_id) / 100)))
            } else {
              return acc -= cv.valor
            }
          }, 0)

          linha['anoBudget' + Object.keys(meses)[i - 1] + 'Formated'] = linha.format(handleEqualKeyDown(qtd, 'anoBudget' + Object.keys(meses)[i - 1], valorContrato))
          linha['anoBudget' + Object.keys(meses)[i - 1]] = handleEqualKeyDown(qtd, 'anoBudget' + Object.keys(meses)[i - 1], valorContrato)

        }

        linha.anoBudgetTotal = calcularBudget(linha).anoBudgetTotal
        linha.anoBudgetMedia = calcularBudget(linha).anoBudgetMedia
        linha.anoBudgetTotalFormated = calcularBudget(linha).anoBudgetTotalFormated
        linha.anoBudgetMediaFormated = calcularBudget(linha).anoBudgetMediaFormated


        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetPeriodo(proximaLinha, true, qtd, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      }

    } else {
      if (qtd === undefined || qtd === '') qtd = 0
      if (valor) {
        if (parseInt(mesEditaFinal) - parseInt(mesEdita) < 0) return notify("Mes final maior que o inicial!")
        var valorSoma = 0
        for (let i = parseInt(mesEdita); i <= parseInt(mesEditaFinal); i++) {

          valorSoma = budgetData?.filter(linhasComp => linha.composicao.includes(linhasComp.codigo_id)).reduce((acc, cv) => acc += cv['anoBudget' + Object.keys(meses)[i - 1]], 0)
          linha['anoBudget' + Object.keys(meses)[i - 1] + 'Formated'] = linha.format(parseFloat(valorSoma))
          linha['anoBudget' + Object.keys(meses)[i - 1]] = parseFloat(valorSoma)
        }

        linha.anoBudgetTotal = calcularBudget(linha).anoBudgetTotal
        linha.anoBudgetMedia = calcularBudget(linha).anoBudgetMedia
        linha.anoBudgetTotalFormated = calcularBudget(linha).anoBudgetTotalFormated
        linha.anoBudgetMediaFormated = calcularBudget(linha).anoBudgetMediaFormated


        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetPeriodo(proximaLinha, true, null, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      } else {
        if (parseInt(mesEditaFinal) - parseInt(mesEdita) < 0) return notify("Mes final maior que o inicial!")
        for (let i = parseInt(mesEdita); i <= parseInt(mesEditaFinal); i++) {

          linha['anoBudget' + Object.keys(meses)[i - 1] + 'Formated'] = linha.format(handleEqualKeyDown(qtd, 'anoBudget' + Object.keys(meses)[i - 1], 0))
          linha['anoBudget' + Object.keys(meses)[i - 1]] = handleEqualKeyDown(qtd, 'anoBudget' + Object.keys(meses)[i - 1], 0)
        }

        linha.anoBudgetTotal = calcularBudget(linha).anoBudgetTotal
        linha.anoBudgetMedia = calcularBudget(linha).anoBudgetMedia
        linha.anoBudgetTotalFormated = calcularBudget(linha).anoBudgetTotalFormated
        linha.anoBudgetMediaFormated = calcularBudget(linha).anoBudgetMediaFormated


        if (linha.contasuperior) {
          var proximaLinha = budgetData.find(superior => superior.codigo_id === linha.contasuperior)
          updateBudgetPeriodo(proximaLinha, true, qtd, isContrato)
        } else {
          calculaAliquotas(budgetDataMemory)
          setEditaBudgetAtualObj({ modalVisivel: false })
          salvarBudget()
          notify("Atualizado!")
        }
      }
    }
  }

  async function updatePessoalMesAMes() {
    var newData = await api.post('/funcionarios/budget/elaboracao/MAM', {
      combo_id: selectedCombo,
      usuario_id: cookies.usuario.id,
      ocultaQuadroPessoal,
      ano: anoBudget,
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
    await loadColaboradores(selectedCombo)
    notify(newData.msg)
  }

  async function updatePessoalPeriodo() {
    var newData = await api.post('/funcionarios/budget/elaboracao/periodo', {
      mesInicial: mesEdita,
      mesFinal: mesEditaFinal,
      combo_id: selectedCombo,
      type: ocultaQuadroPessoal.exibicaoPessoal,
      categoria: ocultaQuadroPessoal?.selecionados,
      usuario_id: cookies.usuario.id,
      valor: parseFloat(ocultaQuadroPessoal.novoValorColab),
      ano: anoBudget,
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
    notify(newData.msg)
    await loadColaboradores(selectedCombo)
  }

  async function salvarToplineMesAMes(id) {
    var linha = editaBudgetAtualObj?.linha
    var somaTotal = 0

    for (let [idx, mes] of Object.keys(meses).entries()) {
      if (parseFloat(document.getElementById(id + mes).value) || parseFloat(document.getElementById(id + mes).value) === 0) {
        try {
          await api.post('/budget/topline/mesames', {
            unidade_id: editaBudgetAtualObj.linha.unidade_id,
            codigo_id: editaBudgetAtualObj?.linha?.codigo_id,
            valor: parseFloat(document.getElementById(id + mes).value),
            usuario_id: cookies.usuario.id,
            mes: idx + 1,
          }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

          somaTotal++

          linha['anoBudget' + mes + 'Formated'] = linha.format(parseFloat(document.getElementById(id + mes).value))
          linha['anoBudget' + mes] = parseFloat(document.getElementById(id + mes).value)
        } catch (error) {
          console.error(error)
          return notify("Erro ao salvar topline, entre em contato com o suporte e tente novamente mais tarde!")
        }
      }
    }

    Object.keys(meses).forEach(mes => {
      const campoBudget = `anoBudget${mes}`
      somaTotal += parseFloat(linha[campoBudget])
    });

    linha.anoBudgetTotal = somaTotal
    linha.anoBudgetMedia = somaTotal / Object.keys(meses).length;

    linha.anoBudgetTotalFormated = linha.format(linha.anoBudgetTotal)
    linha.anoBudgetMediaFormated = linha.format(linha.anoBudgetMedia)
    variacaoMedia()

    notify("Topline salvo!")

  }

  async function salvarToplineLinha(valor) {
    try {
      await api.post('/budget/topline/linha', {
        unidade_id: editaBudgetAtualObj.linha.unidade_id,
        codigo_id: editaBudgetAtualObj?.linha?.codigo_id,
        valor: parseFloat(valor),
        usuario_id: cookies.usuario.id,
        mesinicial: mesEdita,
        mesfinal: mesEditaFinal,
      }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
      notify("Topline salvo!")
    } catch (error) {

      console.error(error)
      notify("Erro ao salvar topline, entre em contato com o suporte e tente novamente mais tarde!")
    }

    var linha = editaBudgetAtualObj?.linha

    for (let i = parseInt(mesEdita); i <= parseInt(mesEditaFinal); i++) {
      linha['anoBudget' + Object.keys(meses)[i - 1] + 'Formated'] = linha.format(parseFloat(valor))
      linha['anoBudget' + Object.keys(meses)[i - 1]] = parseFloat(valor)
    }

    Object.keys(meses).forEach(mes => {
      const campoBudget = `anoBudget${mes}`
      somaTotal += parseFloat(linha[campoBudget])
    });

    linha.anoBudgetTotal = somaTotal
    linha.anoBudgetMedia = somaTotal / Object.keys(meses).length;

    linha.anoBudgetTotalFormated = linha.format(linha.anoBudgetTotal)
    linha.anoBudgetMediaFormated = linha.format(linha.anoBudgetMedia)
    variacaoMedia()
  }

  function getPremissas() {
    try {
      api.get('/premissas/' + anoBudget + '/' + selectedCombo, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        .then(res => {
          setShowPremissas([true, res.data])
        })
    } catch (error) {
      notify("Erro ao pegar premissa!")
    }
  }

  async function exportDataExcel() {
    var returnExport = await api.post('/export/excel/budget/padrao', {
      topline: topLineBudgetData,
      budgetData,
      combo_nome: location.state.combo.label,
      combo_id: selectedCombo
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    if (returnExport.data.status !== 200) return notify("Erro ao gerar arquivo, para mais informações entre em contato com o suporte.")

    window.open(returnExport.data.url, 'blank')

    notify('Arquivo gerado com sucesso.')
  }

  function verificaStatusIconSintetica(conta, mes, ano, origem) {
    var perguntasId = budgetDataMemory?.comentariosBudgetInfo?.destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
    var respostasId = budgetDataMemory?.comentariosBudgetInfo?.comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
    respostasId = [...new Set(respostasId)]

    if (!conta.destaque) {
      if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
        return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
      } else if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
        return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
      } else if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
        return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
      } else if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
        return (<RiMessage2Line style={{ marginLeft: '5px' }} color='red' />)
      } else if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
        return (<RiMessage2Line style={{ marginLeft: '5px' }} color='green' />)
      } else if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
        return (<RiMessage2Line style={{ marginLeft: '5px' }} color='blue' />)
      }
    }
  }

  function verificaStatusIconAnalitica(conta, mes, ano, origem) {
    var perguntasId = budgetDataMemory?.comentariosBudgetInfo?.destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
    var respostasId = budgetDataMemory?.comentariosBudgetInfo?.comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
    respostasId = [...new Set(respostasId)]

    if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
      return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
    } else if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
      return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
    } else if (budgetDataMemory?.comentariosBudgetInfo?.comentarios.filter(d => d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && parseFloat(d.ano) === ano && (d.origem) === origem).length > 0) {
      return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
    }
  }


  async function assinarBudget() {
    try {
      const assinaturaReturn = await api.post('/budget/assinatura/', {
        is_editable: false,
        combo_id: selectedCombo,
        usuario_id: cookies.usuario.id,
      }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

      setModalAssinatura({ modalVisivel: false })

      if (assinaturaReturn.data?.status === 400) {
        setModalAssinatura({ ...modalAssinatura, modalErroVisivel: true, erros: assinaturaReturn.data.erros })
        return notify("Erro ao assinar budget")
      }

      setIsEditable(false)
      return notify("Budget assinado com sucesso.")

    } catch (error) {
      console.error(error)
      notify("Erro ao assinar budget!")
    }
  }

  async function liberarEdicao() {
    try {
      const statusReturn = await api.post('/budget/status/?status=true', {
        is_editable: false,
        combo_id: selectedCombo,
        usuario_id: cookies.usuario.id,
      }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

      if (statusReturn.status !== 200) return notify("Erro ao liberar edição.")
      return notify("Edição Liberada, aguarde as alterações do gerente.")
    } catch (err) {
      console.error(err)
      notify("Erro ao liberar edicao!")
    }
  }

  async function enviarEmailGenerico(assunto, body, destinatarios) {

    try {
      const notificacaoReturn = await api.post('/sendemail', {
        destinatarios: destinatarios,
        messageBody: body,
        subject: assunto
      }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

      if (notificacaoReturn.data?.status === 400) {
        setModalAssinatura({ ...modalAssinatura, modalErroVisivel: true, erros: notificacaoReturn.data.erros })
        return notify("Erro ao notificar gerencia!")
      }

      setModalNotificacao({ modalVisivel: false })

      setIsEditable(false)
      notify("Notificacao enviada com sucesso.")

      return liberarEdicao()

    } catch (error) {
      console.error(error)
      notify("Erro ao notificar gerencia!")
    }

  }

  return (

    <Container fluid>
      <Table key={selectedCombo} id='teste' style={{ background: 'white', borderCollapse: 'separate', borderSpacing: 0, fontSize: 'small', marginBottom: '3rem' }} hover size='sm'>
        <thead key={selectedCombo + 'thead'} className='stickyHeader'>
          <tr>
            <th key={'primeiroTh'} style={{ textAlign: 'center', background: 'white' }} className='stickyCollumns' colSpan='100'>
              <div style={{ display: 'flex', padding: '20px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                <Button style={{ float: 'left' }} variant="outline-primary" size='sm' id="premissasButton" onClick={getPremissas}>Premissas</Button>
                Budget {anoBudget} {location.state.combo.label}
                <div style={{ display: 'flex', gap: '10px' }}>
                  <ComparativeButton onClick={() => {
                    handleOpenSelectModal(true);
                    esconderColuna();
                  }} />
                  <HiddenButton onClick={() => handleOpenSwitchModal(true)} id="openModalButton" />
                  <SelectModal
                    show={isSelectModalOpen}
                    onHide={() => handleCloseSelectModal(false)}
                    title="Comparativo dos anos"
                    primaryButtonLabel="Comparar"
                    onPrimaryButtonClick={() => {
                      mostrarColuna();
                      handleCloseSelectModal();
                    }}
                    select={selectOptions}
                  />

                  <SwitchModal title={'Exibição Históricos'} switches={optionsYears} show={isSwitchModalOpen} onHide={() => handleCloseSwitchModal(false)} />
                  <HoverButton onClick={salvarBudget} id="salvarButton" popoverTxt={'Salvar'} icon={<AiOutlineSave />} />
                  <VariacaoVerticalModal
                    show={variacaoVertical}
                    onHide={() => setVariacaoVertical(false)}
                    handleBase={handleBase}
                    clearVariacao={clearVariacao}
                    budgetData={budgetData}
                    baseVertical={baseVertical}
                  />
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th className='stickyCollumns' rowSpan='2' style={{ zIndex: '1', background: 'white', verticalAlign: 'middle' }}>Contas</th>
            {!hiddenColumns[`${anoBudget - 2}-orcado`] && (
              <th id='BHano1' onClick={(e) => hideShowLines('budgetAnterior2', e)} style={{ borderRight: '2px solid #cdcdcd', background: 'lightblue' }} colSpan='2'>{anoBudget - 2} Orçado</th>
            )}
            {!hiddenColumns[`${anoBudget - 2}-realizado`] && (
              <th id='Hano1' onClick={(e) => hideShowLines('anoAnterior2', e)} style={{ borderRight: '2px solid #cdcdcd', background: 'lightyellow' }} colSpan={tipoVar === true ? '2' : '3'}>{anoBudget - 2} Realizado</th>
            )}
            {!hiddenColumns[`${anoBudget - 1}-orcado`] && (
              <th id='BHano2' onClick={(e) => hideShowLines('budgetAnterior1', e)} style={{ borderRight: '2px solid #cdcdcd', background: 'lightblue' }} colSpan={tipoVar !== true ? '2' : '3'}>{anoBudget - 1} Orçado</th>
            )}
            {!hiddenColumns[`${anoBudget - 1}-realizado`] && (
              <th id='Hano2' onClick={(e) => hideShowLines('anoAnterior1', e)} style={{ borderRight: '2px solid #cdcdcd', background: 'lightyellow' }} colSpan='3'>{anoBudget - 1} Realizado {'+ Forecast'}</th>
            )}
            {!hiddenColumns[`${anoBudget}-orcado`] && (
              <th id='Hano3' onClick={(e) => hideShowLines('anoBudget', e)} style={{ borderRight: '2px solid #cdcdcd', background: 'lightblue' }} colSpan='3'>{anoBudget} Orçado</th>
            )}
            <th
              key={'varicacaoPersonalizadaHeader'}
              colSpan='3'
              hidden={!funcaoExecutada}
              style={{ borderRight: '2px solid #cdcdcd', background: '#EEFFC7' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}> Variação <HiddenButton onClick={esconderColuna} /> </div>
            </th>
          </tr>
          <tr>

            {Array(2).fill(1).map((grupo, idxg) => {
              const ordenacaoAnos = anoBudget - 2 + idxg

              return (
                <>
                  {!hiddenColumns[`${ordenacaoAnos}-orcado`] && (
                    <>
                      {Object.keys(meses).map((el, idx) => {
                        return (<th key={'meses' + idxg + idx} className={'budgetAnterior' + (2 - idxg)} hidden={true} style={{ background: 'lightblue' }}>{idx === 2 ? el.replaceAll('c', 'ç') : el}</th>)
                      })}
                      <th key={'mediath-' + idxg} style={{ borderLeft: '2px solid #cdcdcd', background: 'lightblue' }}>Média</th>
                      <th key={'totalth-' + idxg} style={{ borderRight: '2px solid #cdcdcd', background: 'lightblue' }}>Total</th>
                      {tipoVar === true
                        ? 2 - idxg === 2
                          ? null
                          : <th key={'tipoVar1-' + idxg} className={'ColVar'} style={{ borderRight: '2px solid #cdcdcd', background: 'lightblue' }}>
                            &#916; <sup>{(anoBudget - 2) + idxg}</sup>/<sub>{(anoBudget - 3) + idxg}</sub>
                          </th>
                        : null}
                    </>
                  )
                  }
                  {!hiddenColumns[`${ordenacaoAnos}-realizado`] && (
                    <>
                      {Object.keys(meses).map((el, idx) => {
                        const backgroundColor = idxg === 1 ? getBackgroundColor(idx - 1) : 'lightyellow';
                        return (
                          <th key={'meses2-' + idxg + idx} className={'anoAnterior' + (2 - idxg)} hidden={true} style={{ background: backgroundColor }}>{idx === 2 ? el.replaceAll('c', 'ç') : el}</th>
                        )
                      })}
                      <th key={'media2th-' + idxg} style={{ borderLeft: '2px solid #cdcdcd', background: idxg === 1 ? getBackgroundColor(currentMonth) : 'lightyellow' }}>Média</th>
                      <th key={'total2th-' + idxg} style={{ borderRight: '2px solid #cdcdcd', background: idxg === 1 ? getBackgroundColor(currentMonth) : 'lightyellow' }}>Total</th>
                      {tipoVar === true
                        ? 2 - idxg === 2
                          ? null
                          : <th key={'tipoVar2-' + idxg} className={'ColVar'} style={{ borderRight: '2px solid #cdcdcd', background: idxg === 1 && getBackgroundColor(currentMonth) }}>
                            &#916; <sup>{(anoBudget - 2) + idxg}</sup>/<sub>{(anoBudget - 3) + idxg}</sub>
                          </th>

                        : <th key={'tipoVar3-' + idxg} className={'ColVar'} style={{ borderRight: '2px solid #cdcdcd', background: idxg === 1 ? getBackgroundColor(currentMonth) : 'lightyellow' }}>
                          &#916; <sup>Realizado</sup>/<sub>Orçado</sub>
                        </th>}
                    </>
                  )
                  }
                </>
              )
            })}
            {Object.keys(meses).map((el, idx) => {
              return (
                <th key={'mesesBudget-' + idx} className='anoBudget' hidden={true} style={{ background: 'lightblue' }}>{idx === 2 ? el.replaceAll('c', 'ç') : el}</th>
              )
            })}
            {!hiddenColumns[`${anoBudget}-orcado`] && (
              <th key={'media3th-'} style={{ borderLeft: '2px solid #cdcdcd', background: 'lightblue' }}>Média</th>)}
            {!hiddenColumns[`${anoBudget}-orcado`] && (
              <th key={'total3th-'} style={{ background: 'lightblue' }}>Total</th>)}
            {!hiddenColumns[`${anoBudget}-orcado`] && (
              tipoVar === true
                ? <th key={'tipoVar4-'} className='ColVar' style={{ borderRight: '2px solid #cdcdcd', background: 'lightblue' }}>&#916; <sup>{anoBudget}</sup>/<sub>{anoBudget - 1} Realizado</sub></th>
                : <th key={'tipoVar5-'} className='ColVar' style={{ borderRight: '2px solid #cdcdcd', background: 'lightblue' }}>&#916; <sup>{anoBudget}</sup>/<sub>{anoBudget - 1} Orçado</sub></th>
            )}
            <th hidden={!funcaoExecutada} style={{ borderLeft: '2px solid #cdcdcd', background: '#EEFFC7' }}>Média {denominatorValue.ano} {denominatorValue.tipo === 'orcado' ? 'Orçado' : 'Realizado'}</th>
            <th hidden={!funcaoExecutada} style={{ borderLeft: '2px solid #cdcdcd', background: '#EEFFC7' }}>Média {numeratorValue.ano} {numeratorValue.tipo === 'orcado' ? 'Orçado' : 'Realizado'}</th>
            <th hidden={!funcaoExecutada} style={{ borderRight: '2px solid #cdcdcd', background: '#EEFFC7' }}>&#916; <sup>{numeratorValue.ano} {numeratorValue.tipo === 'orcado' ? 'Orçado' : 'Realizado'}</sup>/<sub>{denominatorValue.ano} {denominatorValue.tipo === 'orcado' ? 'Orçado' : 'Realizado'}</sub></th>
          </tr>
        </thead>
        <tbody>
          {JSON.parse(sessionStorage.getItem('unidades'))?.filter(unids => unids.combo_id === selectedCombo && unids.uhs !== null).map(unidadeAtualTL => {
            return (
              <>
                <tr key={'title-tl' + unidadeAtualTL.id + '-unidade'} onClick={(e) => hideShowLines('topline-' + unidadeAtualTL.id)} style={topLineBudgetData.find(linhas => linhas.unidade_id === unidadeAtualTL.id) ? { fontWeight: 'bold', cursor: 'pointer', background: topLineBudgetData.find(linhas => linhas.unidade_id === unidadeAtualTL.id).color } : null}>
                  <td style={{ background: 'inherit' }} className='stickyCollumns'>Topline {unidadeAtualTL.unidade}</td>
                  <td colSpan='100'></td>
                </tr>
                {topLineBudgetData?.filter(linhas => linhas.unidade_id === unidadeAtualTL.id).map((linha) => {
                  return (
                    <tr key={'body-tl' + unidadeAtualTL.id + '-' + linha.descricao} className={'topline-' + unidadeAtualTL.id}
                      style={{ background: linha.color, whiteSpace: 'nowrap' }}
                      hidden={true}
                    >

                      <td className='stickyCollumns' >{linha.descricao}</td>
                      {!hiddenColumns[`${anoBudget - 2}-orcado`] && (
                        <>
                          {Object.keys(meses).map(mes =>
                            <td className='budgetAnterior2' hidden={true}>{linha[`budgetAnterior2${mes}Formated`]}</td>
                          )}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{linha.budgetAnterior2MediaFormated}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{linha.budgetAnterior2TotalFormated}</td>
                        </>)}
                      {!hiddenColumns[`${anoBudget - 2}-realizado`] && (
                        <>
                          {Object.keys(meses).map(mes =>
                            <td className='anoAnterior2' hidden={true}>{linha[`anoAnterior2${mes}Formated`]}</td>
                          )}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{linha.anoAnterior2MediaFormated}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{linha.anoAnterior2TotalFormated}</td>
                          {tipoVar === true
                            ? null
                            : <td className='ColVar2' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.budgetAnterior2Media, linha.anoAnterior2Media)}</td>}
                        </>
                      )}
                      {!hiddenColumns[`${anoBudget - 1}-orcado`] && (
                        <>
                          {Object.keys(meses).map(mes =>
                            <td className='budgetAnterior1' hidden={true}>{linha[`budgetAnterior1${mes}Formated`]}</td>
                          )}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{linha.budgetAnterior1MediaFormated}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{linha.budgetAnterior1TotalFormated}</td>
                          {tipoVar === true
                            ? <td className='ColVar2' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.budgetAnterior2Media, linha.budgetAnterior1Media)}</td>
                            : null}
                        </>
                      )}
                      {!hiddenColumns[`${anoBudget - 1}-realizado`] && (
                        <>
                          {Object.keys(meses).map(mes =>
                            <td className='anoAnterior1' hidden={true}>{linha[`anoAnterior1${mes}Formated`]}</td>
                          )}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{linha.anoAnterior1MediaFormated}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{linha.anoAnterior1TotalFormated}</td>
                          {tipoVar === true
                            ? <td className='ColVar2' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.anoAnterior2Media, linha.anoAnterior1Media)}</td>
                            : <td className='ColVar2' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.budgetAnterior1Media, linha.anoAnterior1Media)}</td>}
                        </>
                      )}
                      <>
                        {Object.entries(meses).map(([mesNome, mesNumero]) => (
                          <td
                            key={unidadeAtualTL.id + '-' + mesNome}
                            className='anoBudget'
                            onClick={() => {
                              setMesEdita(mesNumero);
                              setMesEditaFinal(mesNumero);
                              setEditaBudgetAtualObj({
                                modalVisivel: true,
                                linha: linha,
                                mes: mesNumero,
                                type: 'topline'
                              });
                            }}
                            hidden={true}
                          >
                            {linha[`anoBudget${mesNome}Formated`]}
                          </td>
                        ))}
                      </>
                      <td style={{ borderLeft: '2px solid #cdcdcd' }}>{linha.anoBudgetMediaFormated}</td>
                      <td style={{ borderRight: '2px solid #cdcdcd' }}>{linha.anoBudgetTotalFormated}</td>
                      {tipoVar === true
                        ? <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.anoAnterior1Media, linha.anoBudgetMedia)}</td>
                        : <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.budgetAnterior1Media, linha.anoBudgetMedia)}</td>}

                      {novaTopline?.find(novaLinhaVariacao => novaLinhaVariacao.unidade_id === unidadeAtualTL.id && novaLinhaVariacao.codigo_id === linha.codigo_id)?.denominador === undefined ? null :
                        <td style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}>{linha.format(novaTopline?.find(novaLinhaVariacao => novaLinhaVariacao.unidade_id === unidadeAtualTL.id && novaLinhaVariacao.codigo_id === linha.codigo_id)?.denominador)}</td>
                      }
                      {novaTopline?.find(novaLinhaVariacao => novaLinhaVariacao.unidade_id === unidadeAtualTL.id && novaLinhaVariacao.codigo_id === linha.codigo_id)?.numerador === undefined ? null :
                        <td style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}>{linha.format(novaTopline?.find(novaLinhaVariacao => novaLinhaVariacao.unidade_id === unidadeAtualTL.id && novaLinhaVariacao.codigo_id === linha.codigo_id)?.numerador)}</td>
                      }
                      {novaTopline?.find(novaLinhaVariacao => novaLinhaVariacao.unidade_id === unidadeAtualTL.id && novaLinhaVariacao.codigo_id === linha.codigo_id)?.result === undefined ? null :
                        <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}>{novaTopline?.find(novaLinhaVariacao => novaLinhaVariacao.unidade_id === unidadeAtualTL.id && novaLinhaVariacao.codigo_id === linha.codigo_id)?.result}</td>
                      }
                    </tr>
                  )
                })}
              </>
            )
          })}
          {budgetData.map((linha, coluna) => {
            if (linha.codigo_id === 'GASTOS COM PESSOAL') {
              var classe = 'linha-contasuperior-' + linha.contasuperior
              return (
                <>
                  <tr key={'qptitle-tl' + linha.codigo_id} onClick={(e) => { e.preventDefault(); handleExibicaoPessoal() }} hidden={true} className='linha-contasuperior-GASTOS COM PESSOAL' style={{ fontWeight: 'bold', background: '#74b9ff', whiteSpace: 'nowrap', cursor: 'pointer' }}>
                    <td style={{ background: 'inherit' }} className='stickyCollumns'>Quadro de pessoal - {ocultaQuadroPessoal.exibicaoPessoal}</td>
                    <td colSpan='100'></td>
                  </tr>
                  {colabCC[ocultaQuadroPessoal.exibicaoPessoal]?.map((centroC, idx) => {
                    return (
                      <tr
                        key={centroC.label + idx}
                        hidden={ocultaQuadroPessoal.isHiddenLine}
                        style={{ fontWeight: 'bold', background: '#74b9ff', whiteSpace: 'nowrap' }}
                        className={'linha-contasuperior-GASTOS COM PESSOAL linha-centroC-' + centroC.label}
                      >
                        <td style={{ fontWeight: 'bold', background: 'inherit', whiteSpace: 'nowrap' }}
                          className='stickyCollumns'>{centroC.label}</td>
                        {Array(2).fill(1).map((el, idx) => {
                          return (
                            <>
                              {!hiddenColumns[`${anoBudget - 2 + idx}-orcado`] && (
                                <>
                                  {
                                    centroC?.[anoBudget - 2 + idx]?.orcado?.map((el, orcadoIdx) => <td key={centroC.label + idx + '-orcado-' + idx + orcadoIdx} hidden={orcadoIdx <= 11 ? !hiddenLines['budgetAnterior' + (2 - idx)] : false} >{parseFloat(el.toFixed(2))}</td>)
                                  }
                                  {tipoVar === true
                                    ? 2 - idx === 2
                                      ? null
                                      : <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(centroC?.[anoBudget - 2]?.orcado[12], centroC?.[anoBudget - 1]?.orcado[12])}</td>
                                    : null
                                  }
                                </>)}
                              {!hiddenColumns[`${anoBudget - 2 + idx}-realizado`] && (
                                <>{
                                  centroC?.[anoBudget - 2 + idx]?.realizado?.map((el, realizadoIdx) => <td key={centroC.label + idx + '-realizado-' + idx + realizadoIdx} hidden={realizadoIdx <= 11 ? !hiddenLines['anoAnterior' + (2 - idx)] : false} >{parseFloat(el.toFixed(2))}</td>)
                                }
                                  {tipoVar === true
                                    ? 2 - idx === 2
                                      ? null
                                      : <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(centroC?.[anoBudget - 2]?.realizado[12], centroC?.[anoBudget - 1]?.realizado[12])}</td>
                                    : 2 - idx === 2
                                      ? <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(centroC?.[anoBudget - 2]?.orcado[12], centroC?.[anoBudget - 2]?.realizado[12])}</td>
                                      : <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(centroC?.[anoBudget - 1]?.orcado[12], centroC?.[anoBudget - 1]?.realizado[12])}</td>
                                  }
                                </>)}
                            </>
                          )
                        })}
                        {Array(14).fill(1).map((el, idx) => {
                          var mesAtualFormat = '00' + idx
                          mesAtualFormat = mesAtualFormat.substring(mesAtualFormat.length - 2, mesAtualFormat.length)
                          return (<td
                            onClick={() => { setMesEdita(mesAtualFormat); setMesEditaFinal(mesAtualFormat); setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, modalVisivel: true, mes: mesAtualFormat }) }} hidden={idx > 11 ? false : !hiddenLines['anoBudget']}>{centroC?.[anoBudget]?.orcado?.[idx]}</td>)
                        })}
                        {tipoVar === true
                          ? <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.anoAnterior1Media, linha.anoBudgetMedia)}</td>
                          : <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia(linha.budgetAnterior1Media, linha.anoBudgetMedia)}</td>}

                        {linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)] === undefined && linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)] === undefined ? null :
                          <td style={{ borderRight: '2px solid #cdcdcd', background: '#74b9ff' }} colSpan={3} >
                          </td>
                        }
                      </tr>
                    )
                  })}
                  <tr
                    id={'linha-' + linha.codigo_id}
                    style={linha.destaque ? { background: 'lightgray', whiteSpace: 'nowrap', fontWeight: 'bold' } : !linha.destaque && linha.composicao ? { fontWeight: 'bold', whiteSpace: 'nowrap', background: 'white' } : { whiteSpace: 'nowrap', background: 'white' }}
                    hidden={linha.nivel === 2 ? true : false}
                    className={classe}
                  >

                    <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null}
                      className='stickyCollumns' style={!linha.destaque && linha.composicao ? { cursor: 'pointer', background: 'inherit' } : { background: 'inherit' }}>{linha.isContrato ? <GrDocument /> : null}{linha.composicao ? ' ' + linha.descricao : linha.isContrato ? ' ' + linha.codigo_id + ' - ' + linha.descricao : '• ' + linha.codigo_id + ' - ' + linha.descricao}</td>
                    {!hiddenColumns[`${anoBudget - 2}-orcado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='budgetAnterior2' onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'orcado']); setNivelContaAtual('sintetica') }} onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : !linha.destaque ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : null} hidden={true}>{linha['budgetAnterior2' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 2), 'orcado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 2), 'orcado')}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior2' + Object.keys(meses)[idx] + 'Formated')}</div></td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.budgetAnterior2MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior2MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.budgetAnterior2TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior2TotalFormated')}</div></td>
                      </>
                    )}
                    {!hiddenColumns[`${anoBudget - 2}-realizado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='anoAnterior2' onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'realizado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'realizado']); setNivelContaAtual('sintetica') }} onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : !linha.destaque ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'realizado']); setNivelContaAtual('analitica') } : null} hidden={true}>{linha['anoAnterior2' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 2), 'realizado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 2), 'realizado')}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior2' + Object.keys(meses)[idx] + 'Formated')}</div></td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.anoAnterior2MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior2MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.anoAnterior2TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior2TotalFormated')}</div></td>
                        {tipoVar === true
                          ? null
                          : <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior2Media, linha.anoAnterior2Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1MediaFormated')}</div></td>}
                      </>
                    )}
                    {!hiddenColumns[`${anoBudget - 1}-orcado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='budgetAnterior1' onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'orcado']); setNivelContaAtual('sintetica') }} onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : !linha.composicao ? () => openRazao(linha.codigo_id, (idx + 1), (anoBudget - 1), 'orcado', linha['budgetAnterior1' + Object.keys(meses)[idx]]) : null} hidden={true}>{linha['budgetAnterior1' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 1), 'orcado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 1), 'orcado')}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1' + Object.keys(meses)[idx] + 'Formated')}</div></td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.budgetAnterior1MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.budgetAnterior1TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1TotalFormated')}</div></td>
                        {tipoVar === true
                          ? <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior2Media, linha.budgetAnterior1Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1MediaFormated')}</div></td>
                          : null}
                      </>
                    )}
                    {!hiddenColumns[`${anoBudget - 1}-realizado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='anoAnterior1'
                              onContextMenu={linha.composicao === null ?
                                (e) => {
                                  e.preventDefault();
                                  setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'realizado']);
                                  setNivelContaAtual('analitica');
                                } : linha.destaque === true ? null :
                                  (e) => {
                                    e.preventDefault();
                                    setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'realizado']);
                                    setNivelContaAtual('sintetica');
                                  }}
                              onClick={!linha.destaque && linha.composicao ?
                                () => {
                                  setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine });
                                  hideShowLines('linha-contasuperior-' + linha.codigo_id);
                                } :
                                !linha.composicao ?
                                  () => openRazao(linha.codigo_id, (idx + 1), (anoBudget - 1), 'realizado', linha['anoAnterior1' + Object.keys(meses)[idx] + 'Formated']) : null}
                              hidden={true}>
                              {linha['anoAnterior1' + Object.keys(meses)[idx] + 'Formated']}
                              {linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 1), 'realizado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 1), 'realizado')}
                              <div className="valorTaxaVertical" hidden={variacaoVertical}>
                                {calculoVariacaoVertical(linha, 'anoAnterior1' + Object.keys(meses)[idx] + 'Formated')}
                              </div>
                            </td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.anoAnterior1MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.anoAnterior1TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1TotalFormated')}</div></td>
                        {tipoVar === true
                          ? <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.anoAnterior2Media, linha.anoAnterior1Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1MediaFormated')}</div></td>
                          : <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior1Media, linha.anoAnterior1Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1MediaFormated')}</div></td>}
                      </>
                    )}
                    {Array(12).fill(1).map((el, idx) => {
                      var mesSTR = ('0' + (idx + 1)).slice(-2)
                      return (
                        <td className={'anoBudget anoBudget-' + (idx + 1)} onClick={linha.destaque ? null : linha.isAliquota === true ? () => { notify('Aliquota definida pela controladoria!'); notify(linha.aliquota.texto) } : linha.composicao !== null ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : e => { setMesEdita(mesSTR); setMesEditaFinal(mesSTR); if (!is_editable) { notify("Edição encerrada!") } else { setEditaBudgetAtualObj({ modalVisivel: true, linha: linha, mes: mesSTR, type: 'dre' }) } }} onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), anoBudget, (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), anoBudget, (idx + 1), 'orcado']); setNivelContaAtual('sintetica') }} hidden={true}>{linha['anoBudget' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), anoBudget, 'orcado') : verificaStatusIconAnalitica(linha, (idx + 1), anoBudget, 'orcado')}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudget' + Object.keys(meses)[idx] + 'Formated')}</div></td>
                      )
                    })}
                    <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} id={'media-budget' + linha.codigo_id} >{linha.anoBudgetMediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetMediaFormated')}</div></td>
                    <td onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} id={'total-budget' + linha.codigo_id} >{linha.anoBudgetTotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetTotalFormated')}</div></td>
                    {tipoVar === true
                      ? <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.anoAnterior1Media, linha.anoBudgetMedia)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetMediaFormated')}</div></td>
                      : <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, isHiddenLine: !ocultaQuadroPessoal.isHiddenLine }); hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior1Media, linha.anoBudgetMedia)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetMediaFormated')}</div></td>}
                    {linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)] === undefined ? null :
                      <td hidden={!funcaoExecutada} key={coluna}>
                        {linha.format(linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)])}
                        <div className="valorTaxaVertical" hidden={variacaoVertical}>
                          {calculoVariacaoVerticalMedia(linha, { ano: denominatorValue.ano, tipo: denominatorValue.tipo })}
                        </div>
                      </td>
                    }
                    {linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)] === undefined ? null :
                      <>
                        <td hidden={!funcaoExecutada} key={coluna}>
                          {linha.format(linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)])}
                          <div className="valorTaxaVertical" hidden={variacaoVertical}>
                            {calculoVariacaoVerticalMedia(linha, { ano: numeratorValue.ano, tipo: numeratorValue.tipo })}
                          </div>
                        </td>
                        <td style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}> <div style={{ display: 'grid', justifyContent: 'center' }}>{novaTabela?.[coluna]?.result}
                        </div>
                          <div className="valorTaxaVertical" hidden={variacaoVertical}>
                            {calculoVariacaoVerticalNovaVariacao(linha.codigo_id)}
                          </div>
                        </td>
                      </>
                    }
                  </tr>
                </>
              )
            } else {
              var classe = 'linha-contasuperior-' + linha.contasuperior
              return (
                <>
                  <tr
                    id={'linha-' + linha.codigo_id}
                    style={linha.destaque ? { background: 'lightgray', whiteSpace: 'nowrap', fontWeight: 'bold' } : !linha.destaque && linha.composicao ? { fontWeight: 'bold', whiteSpace: 'nowrap', background: 'white' } : { whiteSpace: 'nowrap', background: 'white' }}
                    hidden={linha.nivel === 2 ? true : false}
                    className={classe}
                  >
                    <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null}
                      className='stickyCollumns' style={!linha.destaque && linha.composicao ? { cursor: 'pointer', background: 'inherit' } : { background: 'inherit' }}>{linha.isContrato ? <GrDocument /> : null}{linha.composicao ? ' ' + linha.descricao : linha.isContrato ? ' ' + linha.codigo_id + ' - ' + linha.descricao : '• ' + linha.codigo_id + ' - ' + linha.descricao}</td>
                    {!hiddenColumns[`${anoBudget - 2}-orcado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='budgetAnterior2'
                              onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'orcado']); setNivelContaAtual('sintetica') }}
                              onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : !linha.destaque ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : null}
                              hidden={true}>
                              {linha['budgetAnterior2' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 2), 'orcado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 2), 'orcado')}
                              <div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior2' + Object.keys(meses)[idx] + 'Formated')}</div>
                            </td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.budgetAnterior2MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior2MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.budgetAnterior2TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior2TotalFormated')}</div></td>
                      </>)}
                    {!hiddenColumns[`${anoBudget - 2}-realizado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='anoAnterior2' onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'realizado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 2), (idx + 1), 'realizado']); setNivelContaAtual('sintetica') }} onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : !linha.composicao ? () => openRazao(linha.codigo_id, (idx + 1), (anoBudget - 2), 'realizado', linha['anoAnterior2' + Object.keys(meses)[idx] + 'Formated']) : null} hidden={true}>{linha['anoAnterior2' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 2), 'realizado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 2), 'realizado')}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior2' + Object.keys(meses)[idx] + 'Formated')}</div></td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.anoAnterior2MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior2MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.anoAnterior2TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior2TotalFormated')}</div></td>
                        {tipoVar === true
                          ? null
                          : <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior2Media, linha.anoAnterior2Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1MediaFormated')}</div></td>}
                      </>)}
                    {!hiddenColumns[`${anoBudget - 1}-orcado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='budgetAnterior1'
                              onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'orcado']); setNivelContaAtual('sintetica') }}
                              onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : !linha.destaque ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : null}
                              hidden={true}>
                              {linha['budgetAnterior1' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 1), 'orcado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 1), 'orcado')}
                              <div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1' + Object.keys(meses)[idx] + 'Formated')}</div>
                            </td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.budgetAnterior1MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.budgetAnterior1TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1TotalFormated')}</div></td>
                        {tipoVar === true
                          ? <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior2Media, linha.budgetAnterior1Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'budgetAnterior1MediaFormated')}</div></td>
                          : null}
                      </>)}
                    {!hiddenColumns[`${anoBudget - 1}-realizado`] && (
                      <>
                        {Array(12).fill(1).map((el, idx) => {
                          return (
                            <td className='anoAnterior1' onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'realizado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), (anoBudget - 1), (idx + 1), 'realizado']); setNivelContaAtual('sintetica') }} onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : !linha.composicao ? () => openRazao(linha.codigo_id, (idx + 1), (anoBudget - 1), 'realizado', linha['anoAnterior1' + Object.keys(meses)[idx]]) : null} hidden={true}>{linha['anoAnterior1' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), (anoBudget - 1), 'realizado') : verificaStatusIconAnalitica(linha, (idx + 1), (anoBudget - 1), 'realizado')}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1' + Object.keys(meses)[idx] + 'Formated')}</div></td>
                          )
                        })}
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{linha.anoAnterior1MediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1MediaFormated')}</div></td>
                        <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} >{linha.anoAnterior1TotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1TotalFormated')}</div></td>
                        {tipoVar === true
                          ? <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.anoAnterior2Media, linha.anoAnterior1Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1MediaFormated')}</div></td>
                          : <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior1Media, linha.anoAnterior1Media)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoAnterior1MediaFormated')}</div></td>}
                      </>)}
                    {Array(12).fill(1).map((el, idx) => {
                      var mesSTR = ('0' + (idx + 1)).slice(-2)
                      return (
                        <td className={'anoBudget anoBudget-' + (idx + 1)} onClick={linha.destaque ? null : linha.isAliquota === true ? () => { notify('Aliquota definida pela controladoria!'); notify(linha.aliquota.texto) } : linha.composicao !== null ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : e => { setMesEdita(mesSTR); setMesEditaFinal(mesSTR); if (!is_editable) { notify("Edição encerrada!") } else { setEditaBudgetAtualObj({ modalVisivel: true, linha: linha, mes: mesSTR, type: 'dre' }) } }} onContextMenu={linha.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), anoBudget, (idx + 1), 'orcado']); setNivelContaAtual('analitica') } : linha.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, linha.descricao, linha, (idx + 1), anoBudget, (idx + 1), 'orcado']); setNivelContaAtual('sintetica') }} hidden={true}>{linha['anoBudget' + Object.keys(meses)[idx] + 'Formated']}{linha.composicao ? verificaStatusIconSintetica(linha, (idx + 1), anoBudget, 'orcado') : verificaStatusIconAnalitica(linha, (idx + 1), anoBudget, 'orcado')}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudget' + Object.keys(meses)[idx] + 'Formated')}</div></td>
                      )
                    })}
                    <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} id={'media-budget' + linha.codigo_id} >{linha.anoBudgetMediaFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetMediaFormated')}</div></td>
                    <td onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderRight: '2px solid #cdcdcd' }} id={'total-budget' + linha.codigo_id} >{linha.anoBudgetTotalFormated}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetTotalFormated')}</div></td>

                    {tipoVar === true
                      ? <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.anoAnterior1Media, linha.anoBudgetMedia)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetMediaFormated')}</div></td>
                      : <td className='ColVar' onClick={!linha.destaque && linha.composicao ? () => { hideShowLines('linha-contasuperior-' + linha.codigo_id) } : null} style={{ borderLeft: '2px solid #cdcdcd' }} >{calculoVariacaoMedia(linha.budgetAnterior1Media, linha.anoBudgetMedia)}<div className="valorTaxaVertical" hidden={variacaoVertical}>{calculoVariacaoVertical(linha, 'anoBudgetMediaFormated')}</div></td>}

                    {linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)] === undefined ? null :
                      <td hidden={!funcaoExecutada} key={coluna}>
                        {linha.format(linha[getCodigo(denominatorValue.ano, denominatorValue.tipo)])}
                        <div className="valorTaxaVertical" hidden={variacaoVertical}>
                          {calculoVariacaoVerticalMedia(linha, { ano: denominatorValue.ano, tipo: denominatorValue.tipo })}
                        </div>

                      </td>
                    }
                    {linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)] === undefined ? null :
                      <>
                        <td hidden={!funcaoExecutada} key={coluna}>
                          {linha.format(linha[getCodigo(numeratorValue.ano, numeratorValue.tipo)])}
                          <div className="valorTaxaVertical" hidden={variacaoVertical}>
                            {calculoVariacaoVerticalMedia(linha, { ano: numeratorValue.ano, tipo: numeratorValue.tipo })}
                          </div>
                        </td>
                        <td style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}> <div style={{ display: 'grid', justifyContent: 'center' }}>{novaTabela?.[coluna]?.result}
                        </div>
                          <div className="valorTaxaVertical" hidden={variacaoVertical}>
                            {calculoVariacaoVerticalNovaVariacao(linha.codigo_id)}
                          </div>
                        </td>
                      </>
                    }
                  </tr>
                  {linha.codigo_id === 'RBO 1 - RESULTADO BRUTO OPERACIONAL'
                    ? <tr
                      id={'linha-variacaoRBO'}
                      style={{
                        fontWeight: 'bold',
                        color: isNanResult(linha),
                        backgroundColor: isNaN((parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia)))
                          ? 'white'
                          : ((parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia))) <= 0.2
                            ? '#eb4d4b'
                            : ((parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia))) <= 0.3
                              ? '#E3B200'
                              : ((parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia))) <= 0.35
                                ? '#6ab04c'
                                : '#278FF0'
                      }}
                    >
                      <td className='stickyCollumns' style={{ background: 'inherit' }}>RBO %</td>
                      {!hiddenColumns[`${anoBudget - 2}-orcado`] && (
                        <>
                          {Array(12).fill(1).map((el, idx) => {
                            return (
                              <td className='budgetAnterior2' hidden={true}>{isNaN((parseFloat(linha['budgetAnterior2' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['budgetAnterior2' + Object.keys(meses)[idx]]))) ? '-' : (parseFloat(linha['budgetAnterior2' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['budgetAnterior2' + Object.keys(meses)[idx]])).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                            )
                          })}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.budgetAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior2Media))) ? '-' : (parseFloat(linha.budgetAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior2Media)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.budgetAnterior2Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior2Total))) ? '-' : (parseFloat(linha.budgetAnterior2Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior2Total)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                        </>)}
                      {!hiddenColumns[`${anoBudget - 2}-realizado`] && (
                        <>
                          {Array(12).fill(1).map((el, idx) => {
                            return (
                              <td className='anoAnterior2' hidden={true}>{isNaN((parseFloat(linha['anoAnterior2' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['anoAnterior2' + Object.keys(meses)[idx]]))) ? '-' : (parseFloat(linha['anoAnterior2' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['anoAnterior2' + Object.keys(meses)[idx]])).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                            )
                          })}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.anoAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior2Media))) ? '-' : (parseFloat(linha.anoAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior2Media)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.anoAnterior2Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior2Total))) ? '-' : (parseFloat(linha.anoAnterior2Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior2Total)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                          {tipoVar === true
                            ? null
                            : <td className='ColVar' style={{ borderLeft: '2px solid #cdcdcd' }}>{calculoVariacaoMedia((parseFloat(linha.budgetAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior2Media)), (parseFloat(linha.anoAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior2Media)))}</td>}
                        </>)}
                      {!hiddenColumns[`${anoBudget - 1}-orcado`] && (
                        <>
                          {Array(12).fill(1).map((el, idx) => {
                            return (
                              <td className='budgetAnterior1' hidden={true}>{isNaN((parseFloat(linha['budgetAnterior1' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['budgetAnterior1' + Object.keys(meses)[idx]]))) ? '-' : (parseFloat(linha['budgetAnterior1' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['budgetAnterior1' + Object.keys(meses)[idx]])).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                            )
                          })}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.budgetAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior1Media))) ? '-' : (parseFloat(linha.budgetAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior1Media)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.budgetAnterior1Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior1Total))) ? '-' : (parseFloat(linha.budgetAnterior1Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior1Total)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                          {tipoVar === true
                            ? <td className='ColVar' style={{ borderLeft: '2px solid #cdcdcd' }}>{calculoVariacaoMedia((parseFloat(linha.budgetAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior2Media)), (parseFloat(linha.budgetAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior1Media)))}</td>
                            : null}
                        </>)}
                      {!hiddenColumns[`${anoBudget - 1}-realizado`] && (
                        <>
                          {Array(12).fill(1).map((el, idx) => {
                            return (
                              <td className='anoAnterior1' hidden={true}>{isNaN((parseFloat(linha['anoAnterior1' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['anoAnterior1' + Object.keys(meses)[idx]]))) ? '-' : (parseFloat(linha['anoAnterior1' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['anoAnterior1' + Object.keys(meses)[idx]])).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                            )
                          })}
                          <td style={{ borderLeft: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.anoAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior1Media))) ? '-' : (parseFloat(linha.anoAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior1Media)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                          <td style={{ borderRight: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.anoAnterior1Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior1Total))) ? '-' : (parseFloat(linha.anoAnterior1Total) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior1Total)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                          {tipoVar === true
                            ? <td className='ColVar' style={{ borderLeft: '2px solid #cdcdcd' }}>{calculoVariacaoMedia((parseFloat(linha.anoAnterior2Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior2Media)), (parseFloat(linha.anoAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior1Media)))}</td>
                            : <td className='ColVar' style={{ borderLeft: '2px solid #cdcdcd' }}>{calculoVariacaoMedia((parseFloat(linha.budgetAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior1Media)), (parseFloat(linha.anoAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior1Media)))}</td>}
                        </>)}

                      {Array(12).fill(1).map((el, idx) => {
                        return (
                          <td className='anoBudget' hidden={true}>{isNaN((parseFloat(linha['anoBudget' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['anoBudget' + Object.keys(meses)[idx]]))) ? '-' : (parseFloat(linha['anoBudget' + Object.keys(meses)[idx]]) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA')['anoBudget' + Object.keys(meses)[idx]])).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                        )
                      })}
                      <td style={{ borderLeft: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia))) ? '-' : (parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                      <td style={{ borderRight: '2px solid #cdcdcd' }}>{isNaN((parseFloat(linha.anoBudgetTotal) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetTotal))) ? '-' : (parseFloat(linha.anoBudgetTotal) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetTotal)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                      {tipoVar === true
                        ? <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia((parseFloat(linha.anoAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoAnterior1Media)), (parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia)))}</td>
                        : <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }}>{calculoVariacaoMedia((parseFloat(linha.budgetAnterior1Media) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').budgetAnterior1Media)), (parseFloat(linha.anoBudgetMedia) / (budgetData.find(d => d.codigo_id === 'RECEITA LIQUIDA').anoBudgetMedia)))}</td>}
                      {novaTabela?.[coluna]?.denominador === undefined ? null :
                        <td style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}>
                          {novaTabela?.[coluna]?.denominadorrbo.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}
                        </td>
                      }
                      {novaTabela?.[coluna]?.numerador === undefined ? null :
                        <td style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}>
                          {novaTabela?.[coluna]?.numeradorrbo.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}
                        </td>
                      }
                      {novaTabela?.[coluna]?.result === undefined ? null :
                        <td className='ColVar' style={{ borderRight: '2px solid #cdcdcd' }} hidden={!funcaoExecutada}>
                          {novaTabela?.[coluna]?.resultrbo.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}
                        </td>
                      }
                    </tr>
                    : null}
                </>
              )
            }
          })}

        </tbody>
      </Table>

      <Fab
        icon={<BiFilterAlt />}
        alwaysShowTitle={true}
        mainButtonStyles={{ backgroundColor: '#17a2b8' }}
        style={{ bottom: 0, right: 0, zoom: '80%', margin: '20px', zIndex: 100 }}
      >
        {is_editable === true
          ? <Action
            text={<span style={{ fontSize: 'large' }}>Concluir edição</span>}
            style={{ backgroundColor: 'blue' }}
            onClick={() => { if (selectedCombo !== null) { setModalAssinatura({ modalVisivel: true }); } else { notify("Escolha um combo para começar!") } }}
          >
            <FaSignature />
          </Action>
          : null
        }
        {is_editable !== true && (cookies.usuario.permissao === 1 || cookies.usuario.permissao === 0 || cookies.usuario.permissao === 9)
          ? <Action
            text={<span style={{ fontSize: 'large' }}>Notificar Gerente</span>}
            style={{ backgroundColor: 'blue' }}
            onClick={() => { if (selectedCombo !== null) { setModalNotificacao({ modalVisivel: true }); } else { notify("Escolha um combo para começar!") } }}
          >
            <AiOutlineNotification />
          </Action>
          : null
        }

        <Action
          text={<span style={{ fontSize: 'large' }}>Abrir variação vertical</span>}
          style={{ backgroundColor: 'blue' }}
          onClick={() => { if (selectedCombo !== null) { setVariacaoVertical(true); } else { notify("Escolha um combo para começar!") } }}
        >
          <HiSwitchVertical />
        </Action>

        <Action
          text={<span style={{ fontSize: 'large' }}>{tipoVar === true ? 'Mostrar variação orçado' : 'Mostrar variação Anual'}</span>}
          style={{ backgroundColor: 'blue' }}
          onClick={() => { setTipoVar(!tipoVar) }}
        >
          <AiFillEye />
        </Action>

        <Action
          text={<span style={{ fontSize: 'large' }}>Exportar para execel</span>}
          style={{ backgroundColor: 'green', color: 'white' }}
          onClick={() => { selectedCombo !== null ? exportDataExcel() : notify("Escolha um combo para começar!") }}
        >
          <RiFileExcel2Line />
        </Action>
      </Fab>

      <Modal scrollable show={modalNotificacao.modalVisivel} onHide={() => { setModalNotificacao({ modalVisivel: false }) }}>
        <Modal.Header closeButton style={{ alignItens: 'center' }}>
          <Button variant="warning" disabled><BiError /></Button>
          <Modal.Title>Enviar notificação para o gerente da undiade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Assunto:</Form.Label>
          <FormControl
            size='sm'
            placeholder='Assunto do Email'
            id={'assuntoNotificacaoGerente'}
          />
          <Form.Label>Escreva a mensagem:</Form.Label>
          <FormControl
            size='sm'
            as='textarea'
            placeholder='Corpo do Email'
            id={'bodyNotificacaoGerente'}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant='primary' onClick={() => {
            enviarEmailGenerico(
              document.getElementById('assuntoNotificacaoGerente').value,
              document.getElementById('bodyNotificacaoGerente').value,
              minhasUnidades.filter(d => d.combo_id === location.state.combo_id).reduce((acc, cv) => acc = [...new Set([...acc, cv.responsavel_id])], []))
          }}>Enviar</Button>
        </Modal.Footer>
      </Modal>

      <Modal scrollable show={modalAssinatura.modalErroVisivel} onHide={() => { setModalAssinatura({ modalErroVisivel: false, modalVisivel: false }) }}>
        <Modal.Header closeButton>
          <Modal.Title><Button variant="warning" disabled><BiError /></Button> Erro(s) ao enviar emails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            {modalAssinatura?.erros?.map(erro => {
              return (
                <li>
                  <p><b>Nome:</b> {erro.nome} </p>
                  <p><b>Email:</b> {erro.destinatario} </p>
                  <p><b>Erro:</b> {erro.erro} </p>
                </li>)
            })}
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant='primary' onClick={() => { setModalAssinatura({ modalErroVisivel: false, modalVisivel: false }) }}>Ciente</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalAssinatura.modalVisivel} onHide={() => { setModalAssinatura({ modalVisivel: false }) }}>
        <Modal.Header closeButton>
          <Modal.Title>Deseja assinar o budget?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Após realizar a assinatura, não será possivel editar o budget.</p>
          <p>Um email para a validação do budget será enviado à diretoria.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' variant='primary' onClick={() => { assinarBudget() }}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <Dialog fullWidth maxWidth='md' backdrop='static' scrollable open={editaBudgetAtualObj?.modalVisivel && is_editable} onClose={() => setEditaBudgetAtualObj({ modalVisivel: false })}>
        <DialogTitle closeButton> <Button variant="info" disabled><FaPencilRuler /></Button> {editaBudgetAtualObj?.linha?.descricao} </DialogTitle>
        <DialogContent>
          <Tabs defaultActiveKey={tabEdicaoBudget} id="tabEdicaoBudget" onSelect={(d) => setTabEdicaoBudget(d)}>
            <Tab style={{ padding: '10px' }} eventKey="Periodo" title="Periodo">
              <Row>
                <Col>
                  <Form.Label>Início:</Form.Label>
                  <Form.Group controlId="formInicio">
                    <select name="month" id="month" defaultValue={editaBudgetAtualObj?.mes} onChange={e => setMesEdita(e.target.value)}>
                      <option value="Mes">Início</option>
                      {Object.entries(meses).map(([nome, valor]) => (
                        <option key={valor} value={valor}>{valor === '03' ? nome.replaceAll('c', 'ç') : nome}</option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Fim:</Form.Label>
                  <Form.Group controlId="formFim">
                    <select name="month" id="month" defaultValue={editaBudgetAtualObj?.mes} onChange={e => setMesEditaFinal(e.target.value)}>
                      <option value="Mes">Fim</option>
                      {Object.entries(meses).map(([nome, valor]) => (
                        <option key={valor} value={valor}>{valor === '03' ? nome.replaceAll('c', 'ç') : nome}</option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
              </Row>
              {editaBudgetAtualObj?.linha?.isContrato === true
                ? <Row>
                  <Col>
                    Contrato(s) lançado(s) nesta conta:
                    <Button size='sm' variant='link' onClick={() => setModalContratosShow([true, editaBudgetAtualObj?.linha?.descricao, editaBudgetAtualObj?.linha?.contrato])}>Ver</Button>
                  </Col>
                </Row>
                : null}
              <Row>
                <Col>
                  <Form.Label>Novo valor:</Form.Label>
                  <InputGroup size='sm' className="mb-3">
                    {editaBudgetAtualObj?.linha?.isContrato === true
                      ? <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">{'Valor contratos +'}</InputGroup.Text>
                      </InputGroup.Prepend>
                      : null
                    }
                    <FormControl
                      // type='number'
                      placeholder='Valor'
                      id='novoValorBudget'
                      onChange={(e) => e.target.value = e.target.value.replaceAll(',', '.')}
                    />
                  </InputGroup>
                  {editaBudgetAtualObj?.linha?.isContrato === true
                    ? <p>
                      <small style={{ fontSize: 'small', color: 'gray' }}>O valor dos contratos serão somados automaticamente ao valor acima!</small>
                    </p>
                    : null}
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Form.Label>Comentario:</Form.Label>
                  <FormControl
                    size='sm'
                    as='textarea'
                    placeholder='Comentário'
                    id={'novoValorBudgetMesAMesComentario'}
                  />
                </Col>
              </Row> */}
            </Tab>
            <Tab eventKey="Mês a Mês" title="Mês a Mês">
              {editaBudgetAtualObj?.linha?.isContrato === true
                ? <Row>
                  <Col>
                    Contrato(s) lançado(s) nesta conta:
                    <Button size='sm' variant='link' onClick={() => setModalContratosShow([true, editaBudgetAtualObj?.linha?.descricao, editaBudgetAtualObj?.linha?.contrato])}>Ver</Button>
                  </Col>
                </Row>
                : null}
              <Row md={3} sm={1}>
                {Object.entries(meses).map(([mes, idx]) => {
                  return (
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>{idx === '03' ? mes.replace('c', 'ç') : mes}:</Form.Label>
                          <InputGroup size='sm' className="mb-4">
                            {editaBudgetAtualObj?.linha?.isContrato === true
                              ? <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">{editaBudgetAtualObj?.linha?.contrato.reduce((acc, cv) => { if (parseInt(cv.aniversario.substring(5, 7)) <= parseInt(idx + 1)) { return acc -= (cv.valor * (1 + (parseFloat(cv.indicereajuste_id) / 100))) } else { return acc -= cv.valor } }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' +'}</InputGroup.Text>
                              </InputGroup.Prepend>
                              : null
                            }
                            <FormControl
                              placeholder='Valor'
                              id={'novoValorBudgetMesAMes' + mes}
                              defaultValue={editaBudgetAtualObj?.linha?.['anoBudget' + mes]?.toFixed(2)}
                              onChange={(e) => e.target.value = e.target.value.replaceAll(',', '.')}
                              onBlur={(e) => {
                                if (e.target.value === '') { e.target.value = 0 }
                                e.target.value = e.target.value.replace(/[A-Za-z\u00C0-\u00FF]/gi, '')
                                if (isNaN(e.target.value.slice(-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                                e.target.value = math.evaluate(e.target.value);
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  )
                })}
              </Row>

              {editaBudgetAtualObj?.linha?.isContrato === true
                ? <Row> <Col><p>
                  <small style={{ fontSize: 'small', color: 'gray' }}>O valor dos contratos serão somados automaticamente ao valor acima!</small>
                </p></Col>
                </Row>
                : null}
            </Tab>
            <Tab eventKey="Importar valores" title="Importar valores">
              <Container>
                <Row md={2} >
                  <Col style={{ margin: '15px 0' }}>
                    <Autocomplete
                      options={[...budgetData?.reduce((acc, cv) => acc = [...acc, { codigo_id: cv.codigo_id, descricao: cv.descricao, type: 'dre' }], []), ...topLineBudgetData?.reduce((acc, cv) => acc = [...acc, { codigo_id: cv.codigo_id, descricao: cv.descricao, type: 'topline', unidade_id: cv.unidade_id }], [])].reduce((acc, obj) => {
                        if (!acc.some(o => o.codigo_id === obj.codigo_id)) {
                          acc.push(obj);
                        }
                        return acc;
                      }, [])}
                      getOptionLabel={(option) => option.codigo_id === option.descricao ? option.descricao : option.codigo_id + ' - ' + option.descricao}
                      id='contaImportarValores'
                      size="small"
                      style={{ width: '100%' }}
                      onChange={(e, value) => setImportarValores({ ...importarValores, conta: value })}
                      renderInput={(params) => (
                        <TextField {...params} label="Conta" variant="outlined" />
                      )}
                    />
                  </Col>
                  {importarValores.conta?.type === 'topline'
                    ? <Col style={{ margin: '15px 0' }}>
                      <Autocomplete
                        options={[...new Set(JSON.parse(sessionStorage.getItem('unidades'))?.filter(unids => unids.combo_id === selectedCombo && unids.uhs !== null).reduce((acc, cv) => acc = [...acc, { codigo_id: cv.id, descricao: cv.id }], [])), { codigo_id: 'soma', descricao: 'Soma' }, { codigo_id: 'media', descricao: 'Média' }]}
                        getOptionLabel={(option) => option.descricao}
                        id='contaImportarValores'
                        size="small"
                        style={{ width: '100%' }}
                        onChange={(e, value) => setImportarValores({ ...importarValores, unidade: value })}
                        renderInput={(params) => (
                          <TextField {...params} label="Unidade" variant="outlined" />
                        )}
                      />
                    </Col>
                    : null}
                  <Col style={{ margin: '15px 0' }}>
                    <Autocomplete
                      options={[Array(3).fill(1).map((el, idx) => { return { codigo_id: (anoBudget - idx) + '', descricao: (anoBudget - idx) + '' } })].flat()}
                      getOptionLabel={(option) => option.descricao}
                      id='anoImportarValores'
                      size="small"
                      style={{ width: '100%' }}
                      onChange={(e, value) => setImportarValores({ ...importarValores, ano: value })}
                      renderInput={(params) => (
                        <TextField {...params} label="Ano" variant="outlined" />
                      )}
                    />
                  </Col>
                  <Col style={{ margin: '15px 0' }}>

                    <Autocomplete
                      options={importarValores?.ano?.codigo_id == anoBudget ? [{ codigo_id: 'orcado', descricao: 'Orçado' }] :
                        [{ codigo_id: 'orcado', descricao: 'Orçado' }, { codigo_id: 'realizado', descricao: importarValores?.ano?.codigo_id == anoBudget - 1 ? 'Realizado + Forecast' : 'Realizado' }]}
                      getOptionLabel={(option) => option.descricao}
                      id='tipoImportarValores'
                      size="small"
                      style={{ width: '100%' }}
                      onChange={(e, value) => setImportarValores({ ...importarValores, realizadoOrcado: value })}
                      renderInput={(params) => (
                        <TextField {...params} label="Orçado / Realizado" variant="outlined" />
                      )}
                    />
                  </Col>
                  <Col style={{ margin: '15px 0' }}>
                    <TextField
                      id='taxaImportarValores'
                      style={{ width: '100%' }}
                      size="small"
                      value={importarValores.taxaAtualizacao}
                      onChange={(e) => setImportarValores({ ...importarValores, taxaAtualizacao: e.target.value })}
                      label="Multiplicador"
                      variant="outlined"
                    />
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>
        </DialogContent>
        <DialogActions>
          <Button variant='outline-danger' onClick={() => setEditaBudgetAtualObj({ modalVisivel: false })}>Cancelar</Button>
          {tabEdicaoBudget === 'Periodo'
            ? <Button onClick={() => {
              editaBudgetAtualObj?.type === 'dre'
                ? updateBudgetPeriodo(editaBudgetAtualObj?.linha, false, document.getElementById('novoValorBudget').value, editaBudgetAtualObj?.linha?.isContrato)
                : salvarToplineLinha(document.getElementById('novoValorBudget').value);
              variacaoMedia();
            }}>Atualizar</Button>
            : tabEdicaoBudget === 'Mês a Mês'
              ? <Button onClick={() => {
                editaBudgetAtualObj?.type === 'dre'
                  ? updateBudgetMesAMes(editaBudgetAtualObj?.linha, false, editaBudgetAtualObj?.linha?.isContrato)
                  : salvarToplineMesAMes('novoValorBudgetMesAMes');
                variacaoMedia();
              }}>Atualizar</Button>
              : tabEdicaoBudget === 'Importar valores'
                ? <Button onClick={() => {
                  updateBudgetImportarValores();
                  variacaoMedia();
                }}>Atualizar</Button>
                : null}
        </DialogActions>
      </Dialog>
      <Modal
        show={modalContratosShow[0]}
        onHide={() => setModalContratosShow([false, [], []])}
        size="xl"
        style={{ background: '#2020206e' }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable>
        <Modal.Header closeButton style={{ gap: '15px' }}>
          <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="info" style={{ marginRight: '8px' }} disabled><GrDocument /></Button> Contratos: {modalContratosShow[1]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table hover size="sm">
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Fornecedor</th>
                <th style={{ width: '30%' }}>Descrição</th>
                <th style={{ width: '20%' }} >Unidade</th>
                <th style={{ width: '10%' }} >Valor</th>
                <th style={{ width: '10%' }} >Reajuste</th>
                <th style={{ width: '10%' }} >Mês de Reajuste</th>
                <th style={{ textAlign: 'center', width: '10%' }}>Contrato</th>
              </tr>
            </thead>
            <tbody>
              {modalContratosShow[2].map(contrato => {
                return (<tr>
                  <td style={{ width: '20%' }}>{contrato.fornecedor}</td>
                  <td style={{ width: '30%' }}>{contrato.descricao}</td>
                  <td style={{ width: '10%' }} >{JSON.parse(sessionStorage.getItem('unidades')).find(unidade => unidade.id === contrato.unidade_id).unidade}</td>
                  <td style={{ width: '10%' }} >{contrato.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                  <td style={{ width: '10%' }}>{isNaN(contrato.indicereajuste_id) ? contrato.indicereajuste_id : contrato.indicereajuste_id + ' %'}</td>
                  <td style={{ width: '10%' }}>{contrato.aniversario.substring(5, 7)}</td>
                  <td style={{ textAlign: 'center', width: '10%' }}><Button onClick={() => window.open(contrato.linkcontrato)} variant='outline-primary' size='sm'><IoAttach /></Button></td>
                </tr>)
              })}
            </tbody>
          </Table>
          <Table size="sm">
            <tbody>
              <tr>
                <th style={{ textAlign: 'right', width: '70%' }}>Total:</th>
                <td style={{ textAlign: 'center' }}>{modalContratosShow[2].reduce((acc, cv) => acc += cv.valor, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal backdrop='static' size='lg' scrollable show={showPremissas[0]} onHide={() => setShowPremissas([false, []])}>
        <Modal.Header closeButton>
          <Modal.Title><Button variant="warning" disabled><FaPencilRuler /></Button> Premissas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>Unidade</Col>
            <Col>Premissas</Col>
          </Row>
          {showPremissas[1].map(premissa =>
            <Row>
              <Col>{premissa.unidade}</Col>
              <Col>{premissa.premissa}</Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>

      <ModalContratos contratos={budgetDataMemory?.contratos} modalContratosShow={modalContratosShow} onClickButton={() => setModalContratosShow([false, null, null])} open={modalContratosShow[0]} onClose={() => setModalContratosShow([false, null, null])} title='Contratos'
      />

      <ModalComentarios anexos={budgetDataMemory?.comentariosBudgetInfo?.anexos} comentarios={budgetDataMemory?.comentariosBudgetInfo?.comentarios}
        destinatarios={budgetDataMemory?.comentariosBudgetInfo?.destinatarios} modalComentariosShow={modalComentariosShow} modalShow={modalShow} notify={notify} onClickButton={() => setModalComentariosShow([false, null, { id: null }])} onClose={() => setModalComentariosShow([false, null, { id: null }])}
        open={modalComentariosShow[0]} razaoData={razaoData} setModalNovoComentarios={setModalNovoComentarios} setModalRespostaVisible={setModalRespostaVisible}
        setNivelContaAtual={setNivelContaAtual} title='Comentarios' />

      <ModalNovoComentario
        anoConsulta={modalRespostaVisible[1].ano || modalRespostaVisible[3]}
        loadData={loadData}
        title='Adicionar Comentário'
        onClose={() => setModalNovoComentarios([false])}
        open={modalNovoComentarios[0]}
        modalComentariosShow={modalComentariosShow}
        modalNovoComentarios={modalNovoComentarios}
        nivelContaAtual={nivelContaAtual}
        onClickButton={() => { setModalNovoComentarios([false]); setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
        selected={[{ value: selectedCombo }]}
        setToolipText={setToolipText} tooltipText={tooltipText}
        notify={notify}
      />

      <Dialog fullWidth maxWidth='md' backdrop='static' open={ocultaQuadroPessoal.modalVisivel && is_editable} onClose={() => setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, modalVisivel: false })}>
        <DialogTitle closeButton> <Button variant="info" disabled><BsPersonAdd /></Button> Atualizar quadro de pessoal por {ocultaQuadroPessoal.exibicaoPessoal} </DialogTitle>
        <DialogContent>
          <Tabs defaultActiveKey={tabEdicaoBudget} id="tabEdicaoBudget" onSelect={(d) => setTabEdicaoBudget(d)}>
            <Tab style={{ padding: '10px' }} eventKey="Periodo" title="Periodo">
              {ocultaQuadroPessoal.exibicaoPessoal !== 'total'
                ? <Row>
                  <Col>
                    <Select
                      key={ocultaQuadroPessoal.exibicaoPessoal + 'Periodo'}
                      captureMenuScroll
                      autoFocus
                      closeMenuOnSelect
                      placeholder={'Escolha os ' + ocultaQuadroPessoal.exibicaoPessoal}
                      isMulti
                      name="colors"
                      options={colabCC[ocultaQuadroPessoal.exibicaoPessoal]?.reduce((acc, cv) => acc = [...new Set([...acc, { value: cv.value, label: cv.label }])], [])}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={ocultaQuadroPessoal.selecionados}
                      onChange={(e) => { setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, selecionados: e }) }}
                    />
                  </Col>
                </Row>
                : null}
              <Row>
                <Col>
                  <Form.Label>Início:</Form.Label>
                  <Form.Group controlId="formInicio">
                    <select name="month" id="month" defaultValue={editaBudgetAtualObj?.mes} onChange={e => setMesEdita(e.target.value)}>
                      <option value="Mes">Início</option>
                      {Object.entries(meses).map(([nome, valor]) => (
                        <option key={valor} value={valor}>{valor === '03' ? nome.replaceAll('c', 'ç') : nome}</option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Fim:</Form.Label>
                  <Form.Group controlId="formFim">
                    <select name="month" id="month" defaultValue={editaBudgetAtualObj?.mes} onChange={e => setMesEditaFinal(e.target.value)}>
                      <option value="Mes">Fim</option>
                      {Object.entries(meses).map(([nome, valor]) => (
                        <option key={valor} value={valor}>{valor === '03' ? nome.replaceAll('c', 'ç') : nome}</option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Novo valor:</Form.Label>
                  <InputGroup size='sm' className="mb-3">
                    <FormControl
                      placeholder='Numero de colaboradores'
                      id='novoValorColab'
                      onChange={(e) => e.target.value = e.target.value.replaceAll(',', '.')}
                      onBlur={(e) => {
                        if (e.target.value === '') { e.target.value = 0 }
                        e.target.value = e.target.value.replace(/[A-Za-z\u00C0-\u00FF]/gi, '')
                        if (isNaN(e.target.value.slice(-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                        e.target.value = math.evaluate(e.target.value);
                        setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, novoValorColab: e.target.value })
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Tab>
            <Tab style={{ padding: '10px' }} eventKey="Mês a Mês" title="Mês a Mês">
              {ocultaQuadroPessoal.exibicaoPessoal !== 'total'
                ? <Row>
                  <Col>
                    <Select
                      key={ocultaQuadroPessoal.exibicaoPessoal + 'Mês a Mês'}
                      captureMenuScroll
                      autoFocus
                      closeMenuOnSelect
                      placeholder={'Escolha os ' + ocultaQuadroPessoal.exibicaoPessoal}
                      isMulti
                      options={colabCC[ocultaQuadroPessoal.exibicaoPessoal]?.reduce((acc, cv) => acc = [...new Set([...acc, { value: cv.value, label: cv.label }])], [])}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={ocultaQuadroPessoal.selecionados}
                      onChange={(e) => setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, selecionados: e })}
                    />
                  </Col>
                </Row>
                : null}
              <Row md={3} sm={1}>

                {Object.entries(meses).map(([mes, idx]) => {
                  return (
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>{idx === '03' ? mes.replaceAll('c', 'ç') : mes}:</Form.Label>
                          <InputGroup size='sm' className="mb-4">
                            <FormControl
                              placeholder='Valor'
                              id={'novoValorColabMesAMes' + mes}
                              onChange={(e) => e.target.value = e.target.value.replaceAll(',', '.')}
                              onBlur={(e) => {
                                if (e.target.value === '') { e.target.value = 0 }
                                e.target.value = e.target.value.replace(/[A-Za-z\u00C0-\u00FF]/gi, '')
                                if (isNaN(e.target.value.slice(-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                                e.target.value = math.evaluate(e.target.value);
                                setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, ['novoValorColabMesAMes' + mes]: e.target.value })
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  )
                })}
              </Row>
            </Tab>
          </Tabs>
        </DialogContent>
        <DialogActions>
          <Button variant='outline-danger' onClick={() => setOcultaQuadroPessoal({ ...ocultaQuadroPessoal, modalVisivel: false })}>Cancelar</Button>
          {tabEdicaoBudget === 'Periodo'
            ? <Button onClick={() => {
              updatePessoalPeriodo()
            }}>Atualizar</Button>
            : tabEdicaoBudget === 'Mês a Mês'
              ? <Button onClick={() => {
                updatePessoalMesAMes()
              }}>Atualizar</Button>
              : null}
        </DialogActions>
      </Dialog>

      <ModalRazao
        loadData={loadData}
        open={modalShow[0]}
        onClose={() =>
          setModalShow([0])
        }
        onClickButton={() =>
          setModalShow([0])
        }
        title={modalShow[1]}
        searchInput={
          <FormControl
            type="search"
            placeholder="Buscar registro"
            aria-label="searchRazaoBar"
            aria-describedby="basic-addon1"
            value={searchRazaoText}
            onChange={e => { setSearchRazaoText(e.target.value) }}
            onBlur={e => { filtraRazao(e.target.value); }}
          />
        }
        setRazaoData={setRazaoData}
        anexosRazao={anexosRazao}
        comentarios={budgetDataMemory?.comentariosBudgetInfo?.comentarios}
        notify={notify}
        razaoData={razaoData}
        setModalNovoComentarios={setModalNovoComentarios}
        setModalComentariosShow={setModalComentariosShow}
        setNivelContaAtual={setNivelContaAtual}
        modalShow={modalShow[5]}
      />
      <ModalRespostas
        anoConsulta={modalRespostaVisible[1].ano || modalRespostaVisible[3]}
        PermiteComentarioOrcamento={true}
        selected={[{ value: selectedCombo }]}
        loadData={loadData}
        setToolipText={setToolipText}
        tooltipText={tooltipText}
        modalRespostaVisible={modalRespostaVisible}
        notify={notify}
        open={modalRespostaVisible[0]}
        onClose={() => setModalRespostaVisible([false, { nome: '', comentario: '' }])}
        onClickButton={() => { setModalRespostaVisible([false, { nome: '', comentario: '' }]);; setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
        title='Adicione Resposta'
        modalComentariosShow={[, , , modalComentariosShow[3], modalComentariosShow[6]]}
        nivelContaAtual={nivelContaAtual} />

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        style={{ zIndex: 1000000 }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover />
    </Container >

  );
}

export default Budget;
