import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Col, Container, Dropdown, DropdownButton, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import { TableCell, TableRow } from '@material-ui/core';

import { Avatar, TextField } from '@material-ui/core';
import TablePagination from '@mui/material/TablePagination';

import { useCookies } from 'react-cookie';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { BiSearchAlt } from 'react-icons/bi';
import { BsFillPersonPlusFill, BsPersonAdd } from 'react-icons/bs';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useMediaQuery } from '@material-ui/core';
import { Autocomplete, SpeedDial, SpeedDialAction, SpeedDialIcon, createFilterOptions } from '@mui/material';
import api from '../../../services/api';
import { useStyles } from '../styles';


const actions = [
    { icon: <BsPersonAdd />, name: 'ADM', action: () => document.getElementById('fileColabAdm').click(), adm: true },
    { icon: <BsPersonAdd />, name: 'Ativos', action: () => document.getElementById('fileColab').click(), adm: false },
    // { icon: <BsPersonDown />, name: 'Afastados', action: () => alert('Afastados'), adm: false },
    // { icon: <BsPersonDash />, name: 'Demitidos', action: () => alert('Demitidos'), adm: false },
    // { icon: <ShareIcon />, name: 'Share', action: () => alert('Share') },
];

function Colaboradores() {
    const classes = useStyles();
    const history = useHistory()
    const [cookies] = useCookies([]);
    const [colaboradores, setColaboradores] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [colabTotal, setColabTotal] = React.useState(10);
    const [search, setSearch] = useState('')
    const [searchDb, setSearchDb] = useState(false)
    const [dialogValue, setDialogValue] = useState({ motivo: '', colabId: -1 });
    const [open, toggleOpen] = useState(false);
    const [motivosDesligamentos, setMotivosDesligamentos] = useState([]);
    const [enviarWhatsappPDI, setEnviarWhatsappPDI] = useState({ isVisible: false, celular: '', link: '', nome: '' });
    const filter = createFilterOptions();

    const [openSD, setOpenSD] = useState(false);
    const handleOpenSD = () => setOpenSD(true);
    const handleCloseSD = () => setOpenSD(false);

    let typingTimer;

    useEffect(() => {
        getColaboradores()
        getMotivosDesligamentos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, searchDb])

    async function getMotivosDesligamentos() {
        const response = await api.get('/rh/motivosdemissao/' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setMotivosDesligamentos(response.data)
    }
    async function getColaboradores() {
        const response = await api.get('/rh/colaborador/list/' + cookies.usuario.id + `?page=${page}&rowsPerPage=${rowsPerPage}&search=${search}`
            , { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setColaboradores(response.data.colaboradores)
        setColabTotal(response.data.colabTotal)
    }

    const handleClose = () => {
        setDialogValue({ motivo: '', colabId: -1 });
        toggleOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filterColaborador = (encontrar) => {
        setSearch(encontrar)
        clearTimeout(typingTimer);

        typingTimer = setTimeout(() => {
            setSearchDb(!searchDb);
        }, 1000);
    }

    const exportExcel = () => {
    }

    const editColaborador = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        history.push('/rh/colaboradores/edit', { colab: id })
    }

    const inicialPDI = (id, e) => {
        e.preventDefault()
        e.stopPropagation()
        history.push('/rh/pdi/colaborador', { colab: id })
    }

    async function solicitarPDI(colabId, e, nome, colab) {
        e.stopPropagation();
        if (!window.confirm('Deseja solicitar auto avaliação para o colaborador ' + (nome.split(' ')[0] || nome) + '?')) return
        var resp = await api.post('/rh/pdi/avaliacao/solicitacao/' + cookies.usuario.id, { colabId }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        if (resp.status !== 200) return notify("Erro ao enviar solicitação")

        colab.status_auto_avaliacao_txt = 'Solicitado'

        notify("Enviado Com sucesso")

        var celular = colab?.celular?.replace(/\D/g, '')
        if (celular?.length > 11) celular = celular.substring(2, celular.length)

        setEnviarWhatsappPDI({ isVisible: true, celular: celular, link: resp?.data?.link, nome: colab.nome })

        console.log(`Solicitando PDI para o colaborador com ID ${colabId}`);
    }

    const notify = ($msg, $time = 1000) => toast($msg, {
        theme: "light",
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const createColabRow = (colab) => {
        return (
            <TableRow className={classes.tableRow} style={{ cursor: 'pointer' }} onClick={(e) => cookies.usuario.rights.includes("d") ? editColaborador(e, colab.id) : null}>
                <TableCell className={classes.tableCell} ><Avatar className={classes.smallColab} src={colab.profile_url} /></TableCell>
                <TableCell className={classes.tableCell}>
                    <Container fluid>
                        <Row>{colab.nome}</Row>
                        <Row className={classes.breakAll}>{colab.email}</Row>
                        <Row>{colab.idade}</Row>
                    </Container>
                </TableCell  >
                <TableCell className={classes.tableCell} >
                    <Container fluid>
                        <Row>{colab.unidade}</Row>
                        <Row>{colab.departamento}</Row>
                        <Row>{colab.cargo}</Row>
                    </Container>
                </TableCell>
                <TableCell className={classes.tableCell} >
                    <Container fluid>
                        <Row>Lider: {colab?.lider}</Row>
                        <Row>Auto Avaliacao: {colab?.status_auto_avaliacao_txt}</Row>
                        <Row>Ultimo PDI: {colab?.ultima_avaliacao_data || '-'}</Row>
                    </Container>
                </TableCell>
                <TableCell className={classes.tableCell}>
                    <Container fluid>
                        <Row>Admissão: {colab.admissaoformated}</Row>
                        <Row>Ferias: - </Row>
                    </Container>
                </TableCell>
                <TableCell className={classes.tableCell} >
                    <DropdownButton hidden={!cookies.usuario.rights.includes("d")} size="sm" variant="secondary" onClick={(e) => e.stopPropagation()} id="dropdown-basic-button" title="Ações">
                        <Dropdown.Item onClick={(e) => editColaborador(e, colab.id)}>
                            Editar Colaborador
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={(e) => solicitarPDI(colab.cpf, e, colab.nome, colab)}>
                            Auto Avaliação
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(e) => inicialPDI(colab.id, e)}>
                            PDI
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={(e) => { e.stopPropagation(); setDialogValue({ ...dialogValue, colabId: colab.id }); toggleOpen(!open) }}>
                            Desligar
                        </Dropdown.Item>
                    </DropdownButton>
                    {/* <Container fluid style={{ padding: '0' }}>
                        <ButtonGroup size="md" className="mb-3">
                            <Button style={{ display: 'flex', alignItems: 'center' }}
                                onClick={(e) => solicitarPDI(colab.id, e)}
                                variant="outline-dark" > <div style={{ padding: '0 10px 0 0' }}><AiOutlineFileAdd /> </div>
                                Auto Avaliação</Button>
                            <Button style={{ display: 'flex', alignItems: 'center' }}
                                onClick={(e) => inicialPDI(colab.id, e)}
                                variant="outline-dark" > <div style={{ padding: '0 10px 0 0' }}><AiOutlineAim /> </div>
                                PDI</Button>
                            <Button
                                style={{ display: 'flex', alignItems: 'center' }}
                                onClick={(e) => { e.stopPropagation(); setDialogValue({ ...dialogValue, colabId: colab.id }); toggleOpen(!open) }}
                                variant="outline-dark" >
                                <div style={{ padding: '0 10px 0 0' }}><BsPersonDash /> </div>
                                Desligar</Button>
                 </ButtonGroup>
            </Container>*/}
                </TableCell >
            </TableRow>

        )
    }

    const handleFileColab = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        await api.post('/rh/colaborador/import/' + cookies.usuario.id, formData, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            .then(res => {
                document.getElementById('fileColab').value = null
                setColaboradores([...colaboradores, ...res.data])
            })
    }

    const handleFileColabAdm = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        await api.post('/adm/rh/colaborador/import/' + cookies.usuario.id, formData, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            .then(res => {
                document.getElementById('fileColabAdm').value = null
                if (res.data.error) {
                    alert(res.data.error)
                } else {
                    setColaboradores(res.data)
                }
            })
    }

    const demitirColaborador = async (event) => {
        await api.put('/rh/colaborador/demitir', { ...dialogValue, usuario_id: cookies.usuario.id }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            .then(res => {
                setColaboradores(colaboradores.filter(d => d.id !== dialogValue.colabId))
            })
        handleClose();
    };

    const handleNewMotivoDesligamento = async (motivo) => {
        await api.post('/rh/motivosdemissao', { motivo: motivo, usuario_id: cookies.usuario.id }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            .then(res => {
                setMotivosDesligamentos([...motivosDesligamentos, ...res.data])
                setDialogValue({ ...dialogValue, motivo: res.data.motivo, codigo: res.data.codigo, organizacao_id: res.data.organizacao_id });
            })
    }

    const isMobile = useMediaQuery('(max-width: 767px)');


    return (
        <Container fluid>
            <Row style={{
                margin: '0',
                background: 'white',
                padding: '10px',
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-end',
            }}>
                <Row style={{
                    margin: '0',
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'flex-end',
                    alignItems: 'center',
                    marginBottom: isMobile ? '10px' : '0',
                }}>
                    {cookies.usuario.rights.includes("d")
                        ? <>
                            <Button style={{ margin: ' 0 10px', display: 'flex', alignItems: 'center' }} variant="outline-info" size='sm' onClick={(e) => editColaborador(e, -1)}>
                                <div style={{ padding: '0 10px 0 0' }}><BsFillPersonPlusFill /></div>
                                Contratar Colaborador
                            </Button>
                            <input type="file" id="fileColab" onChange={(e) => handleFileColab(e)} style={{ display: 'none' }} />
                            {cookies.usuario.permissao === 0
                                ? (<>
                                    <input type="file" id="fileColabAdm" onChange={(e) => handleFileColabAdm(e)} style={{ display: 'none' }} />
                                </>)
                                : null
                            }
                        </>
                        : null}
                </Row>
                <Col style={{
                    margin: '0',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isMobile ? 'center' : 'flex-end',
                    alignItems: 'center',
                }}>
                    <Button style={{ margin: ' 0 10px', display: 'flex', alignItems: 'center' }} variant="success" size='sm' onClick={() => exportExcel()}>
                        <div style={{ padding: '0 10px 0 0' }}><AiOutlineFileExcel /></div>
                        Excel
                    </Button>
                    <InputGroup style={{ maxWidth: '300px' }}>
                        <Form.Control placeholder="Buscar Colaborador" onChange={(e) => { filterColaborador(e.target.value) }} />
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2"><BiSearchAlt /></InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            <Table style={{ background: 'white', marginBottom: 0 }} striped hover>
                <tbody>
                    {colaboradores.map((colab) => createColabRow(colab))}
                </tbody>
            </Table>

            <Row style={{ margin: '0 0 50px 0', background: 'white', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TablePagination
                        hidden={search !== ''}
                        component="div"
                        count={parseInt(colabTotal)}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Col>
            </Row>

            {cookies.usuario.rights.includes("d")
                ? <SpeedDial
                    ariaLabel="SpeedDial tooltip example"
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16
                    }}
                    icon={<SpeedDialIcon />}
                    onClose={handleCloseSD}
                    onOpen={handleOpenSD}
                    open={openSD}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            hidden={action.adm && cookies.usuario.permissao !== 0}
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipOpen
                            onClick={action.action}
                        />
                    ))}
                </SpeedDial>
                : null
            }

            <Modal
                show={enviarWhatsappPDI.isVisible}
                onHide={() => setEnviarWhatsappPDI({ ...enviarWhatsappPDI, isVisible: false })}
                size='md'
                scrollable='false'
            >
                <Modal.Header>
                    <Modal.Title>Enviar solicitação por whatsapp?</Modal.Title>
                </Modal.Header>
                {!!!enviarWhatsappPDI.celular ?
                    <Modal.Body>
                        <Row style={{ margin: '10px 0' }}>
                            <Col>
                                Celular do colaborador(a) {enviarWhatsappPDI.nome} não cadastrado no sistema
                            </Col>
                        </Row>
                    </Modal.Body>
                    : null}
                <Modal.Footer>
                    <Button onClick={() => setEnviarWhatsappPDI({ ...enviarWhatsappPDI, isVisible: false })} variant="outline-secondary">Cancelar</Button>
                    {!!!enviarWhatsappPDI.celular ? null : <Button onClick={() => window.open(`https://api.whatsapp.com/send?phone=55${enviarWhatsappPDI.celular}&text=Olá, tudo bem? Você poderia responder a auto avaliação de desempenho que enviamos para você através do link? ${enviarWhatsappPDI.link} `, '_blank')} variant="outline-success">Enviar</Button>}
                </Modal.Footer>
            </Modal>

            <Modal
                show={open}
                onHide={handleClose}
                size='md'
                scrollable='false'
            >
                <Modal.Header>
                    <Modal.Title>Motivo do desligamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ margin: '10px 0' }}>
                        <Col>
                            <Autocomplete
                                autoFocus
                                disablePortal
                                options={motivosDesligamentos}
                                className={classes.input}
                                disableClearable
                                onChange={(event, newValue) => {
                                    if (newValue && newValue.inputValue) {
                                        handleNewMotivoDesligamento(newValue.inputValue);
                                    } else {
                                        setDialogValue({ ...dialogValue, motivo: newValue.motivo, codigo: newValue.codigo, organizacao_id: newValue.organizacao_id });
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            motivo: `Add "${params.inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return "Add " + option.inputValue;
                                    }
                                    // Regular option
                                    return option.codigo + ' - ' + option.motivo;
                                }}
                                id="combo-box-demo"
                                renderInput={(params) => <TextField variant="outlined" required {...params} label="Código desligamento" />}
                                variant="outlined"
                            />
                        </Col>
                    </Row>
                    <Row style={{ margin: '10px 0' }}>
                        <Col>
                            <TextField
                                id="outlined-multiline-static"
                                label="Descrição"
                                multiline
                                rows={4}
                                fullWidth
                                value={dialogValue.motivo}
                                onChange={(e) => { setDialogValue({ ...dialogValue, motivo: e.target.value }) }}
                                margin="normal"
                                className={classes.input}
                                variant="outlined"
                            />
                        </Col>
                    </Row>
                    <Row style={{ margin: '10px 0' }}>
                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button style={{ margin: '0 5px' }} size='sm' variant="outline-secondary" onClick={handleClose}>Cancelar</Button>
                            <Button style={{ margin: '0 5px' }} size='sm' onClick={demitirColaborador} type="submit">Salvar</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
            />

        </Container>
    );
}

export default Colaboradores;