/* eslint-disable */
import {
    Drawer
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';
import packageInfo from '../../../package.json';

import sha256 from 'crypto-js/sha256';
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalNotificacoes from '../../components/modals/models/modalNotificacoes.tsx'
import ModalCadastroDre from "../../components/modals/models/modalCadatroDRE.tsx"
import ModalLogErros from '../../components/modals/models/modalLogError.tsx';
import ModalAdicionarTopline from '../../components/modals/models/modalAdicionarTopline/modalAdicionarTopline.tsx';
import ModalAdicionarNFS from '../../components/modals/models/modalAdicionarNFS.tsx'
import ModalAdicionarFaturamento from '../../components/modals/models/modalAdicionarFaturamento.tsx'


import api from '../../services/api';
// imagens

// Styles
import { useMediaQuery } from '@material-ui/core';
import { MobileAppBar } from '../../components/app-bar/mobile-menu';
import './Drawer.css';

import { MenuItens } from '../../components/app-bar/menu-itens';


const drawerWidth = (300);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: (theme.zIndex.drawer + 1),
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: (36 * 0.8),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    small: {
        width: (theme.spacing(3) * 0.8),
        height: (theme.spacing(3) * 0.8),
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: (2 * 0.8),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: ((theme.spacing(7) + 1) * 0.8),
        [theme.breakpoints.up('sm')]: {
            width: ((theme.spacing(7) + 1) * 0.8),
        },
    },
    spinnerGrowSm: {
        // width: '0.5rem',
        // height: '0.5rem',
        width: '0.4rem',
        height: '0.4rem',
        position: 'absolute',
        top: '0px',
        // right: '10px',
        right: '8px',
    },
    spinnerGrowSmOpen: {
        float: 'right',
        // marginRight: ' 10px',
        marginRight: ' 8px',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: (theme.spacing(0, 1) * 0.8),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: (theme.spacing(3) * 0.8),
    },
    badgeDangerMine: {
        position: 'initial',
    },
}));

export default function MiniDrawer() {
    const matches = useMediaQuery('(max-width:680px)');

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [datasFaltantes, setDatasFaltantes] = useState({ visible: false })
    const [gourmet, setGourmet] = useState({
        gourmet: false,
    })
    const [isOpenAdiocionarTopline, setIsOpenAdiocionarTopline] = useState(false);
    const [patrimoniais, setPatrimoniais] = useState({
        patrimoniais: false,
        patrimoniaisExcel: false,
        patrimoniaisExtratoFundoReserva: false,
        patrimoniaisList: [],
        disbaeld: true,
        historicFiles: [],
    })
    const [historicFiles, setHistoricFiles] = useState({})
    const [selected, setSelected] = useState([])
    const [selectedPatrimonailExcel, setSelectedpatrimonialExcel] = useState([])
    const [controladoria, setControladoria] = useState({
        controladoria: false,
    })
    const [notificacaoModal, setNotificacaoModal] = useState(false)
    const [budgetModal, setBudgetModal] = useState({ modalVisible: false })

    const history = useHistory()
    var meses = ["", "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    // const searchBar = useSelector(state => state.searchBar)

    // const [searchUnidadeText, setSearchUnidadeText] = useState('')
    const [cadUsu, setCadUsu] = useState(true)
    const [emailUser, setEmailUser] = useState('')
    const [nomeUser, setNomeUser] = useState('')
    const [unidadesSelected, setUnidadesSelected] = useState([])
    const [unidades, setUnidades] = useState([])
    const [MSLoading, setMSLoading] = useState(true);
    const [notificacoes, setNotificacoes] = useState([])
    const [allPatrimoniais, setAllPatrimoniais] = useState([])
    const [inconsistenciasInvestidores, setInconsistenciasInvestidores] = useState([])

    const [cookies, , removeCookie] = useCookies([]);

    const [isOpenLog, setIsOpenLog] = useState(false);
    const [isOpenDRE, setIsOpenDRE] = useState(false);
    const [openNFS, setOpenNFS] = useState(false);
    const [openFaturamento, setOpenFaturamento] = useState(false);

    useEffect(() => {
        loaddata()
        loadErros()
    }, []);


    async function loadErros() {
        if (![0, 9].includes(cookies.usuario.permissao)) return
        var inconsistenciasInvestidores = await api.get('/auto/dre/investidor/erros/1', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setInconsistenciasInvestidores(inconsistenciasInvestidores.data)
    }

    async function loadCombos() {
        try {
            var resCombos = await api.get(`/combos?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            var meusCombos = [...new Set(JSON.parse(sessionStorage.getItem('unidadesMemory')).reduce((acc, cv) => acc = [...acc, cv.combo_id], []))].sort()
            setBudgetModal({ ...budgetModal, combos: resCombos.data.filter(d => meusCombos.includes(d.id)).reduce((acc, cv) => acc = [...acc, { label: cv.combo, value: cv.id, ...cv }], []) })
        } catch (error) {
            notify("Erro ao carregar combos!")
        }
    }

    async function loadNotificacoes() {
        try {
            var resNotificacoes = await api.get(`/notificacoes/${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            setNotificacoes(resNotificacoes.data)
        } catch (error) {
            console.error(error)
            notify("Erro ao carregar notificacoes!")
        }
    }

    async function loaddata() {
        if (cookies.currentVersion != packageInfo.version) { window.location.pathname = '/' }
        if (cookies.usuario.inativo === true) {
            notify('Usuário inativo!')
            return window.location.pathname = '/'
        }
        if (cookies.usuario) {
            var resUnidades = await api.get(`/unidades/${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            var array = []
            sessionStorage.setItem('unidades', JSON.stringify(resUnidades.data))
            sessionStorage.setItem('unidadesMemory', JSON.stringify(resUnidades.data))
            sessionStorage.setItem('unidadesCarregadas', JSON.stringify(resUnidades.data))

            JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
                return array.push({ label: unidade.unidade, value: unidade.id })
            })
            setMSLoading(false)
            setUnidades(array)
            var resCargos = await api.get(`/permissaoCargos/${cookies.usuario.permissao}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            sessionStorage.setItem('permissoesCargos', JSON.stringify(resCargos.data))
            loadpermissoes()
            loadNotificacoes()
            loadCombos()
            loadHistoricFilesPatrimoniais()
            loadPatrimoniaisList()
            loadPatrimoniaisExcel()
        }
    }

    async function loadPatrimoniaisExcel() {
        const responsePatrimoniais = await api.get('/patrimoniais/unidades/' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } });

        setAllPatrimoniais(responsePatrimoniais.data);
    }

    async function loadpermissoes() {
        var resPermissoes = await api.get(`/permissoes/${cookies.usuario.permissao}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        sessionStorage.setItem('permissoes', JSON.stringify(resPermissoes.data))
    }

    async function loadPatrimoniaisList() {
        var resPatrimoniais = await api.get(`/patrimoniais/all/${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setPatrimoniais({
            ...patrimoniais,
            patrimoniaisList: resPatrimoniais.data,
            disabled: false
        })
    }

    async function loadHistoricFilesPatrimoniais() {
        var resPatrimoniaisFiles = await api.get('/patrimoniais/aluguel/listfiles', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setHistoricFiles({ ...resPatrimoniaisFiles.data })
    }

    async function getDataFaltante(unidade_id) {
        const datasF = await api.get(`/verificacaomr/${unidade_id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setDatasFaltantes({ ...datasFaltantes, dias: datasF.data, ta: false })
    }

    async function deletanotificacao(id) {
        await api.delete(`/notificacoes/${id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        var notificacoesAtual = notificacoes.reduce((acc, cv) => cv.id !== id ? acc = [...acc, cv] : acc, [])
        setNotificacoes(notificacoesAtual)
    }

    async function addUserHandler(e) {
        e.preventDefault()
        var perfil = document.getElementById('perfilSelector').value
        var unidade = []
        unidadesSelected.reduce((acc, cv) => unidade.push(cv.value), '')
        if (perfil === "-1") {
            notify("Escolha um perfil!")
        } else if (unidade.length === 0) {
            notify("Escolha uma unidade")
        } else {
            var senhaRandom = Math.random()
            senhaRandom = parseInt(senhaRandom * 10000000000)
            var criptoPSWD = (sha256(senhaRandom + '').toString())
            try {
                await api.post('/usuarios', {
                    nome: nomeUser,
                    email: emailUser.toLowerCase(),
                    senha: criptoPSWD,
                    permissao: perfil,
                    unidade_id: unidade,
                    senhaEmail: senhaRandom,
                    organizacao_id: cookies.usuario.organizacao_id
                }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                notify("Usuário cadastrado com sucesso!")
                setCadUsu(true)
                setUnidadesSelected([])
                setEmailUser('')
                setNomeUser('')

            } catch (error) {
                notify("Ocorreu um erro como seu cadastro, tente novamente mais tarde!")
                setCadUsu(true)
            }
        }
    }

    const vizualizaNotificacoes = async (notificacaoId) => {
        await api.put('/notificacoes', {
            notificacoes: notificacaoId
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        loadNotificacoes()
    }

    function openPage(url, params) {
        setOpen(false)
        setControladoria({ controladoria: false })
        setGourmet({ gourmet: false })
        setPatrimoniais({ ...patrimoniais, patrimoniais: false, patrimoniaisExtratoFundoReserva: false })
        setSelected([])
        if (params) {
            history.push(url, params)
        } else {
            history.push(url)
        }
    }

    const notify = ($msg) => toast($msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    async function gerarOrcadoRealziadoInvestidores() {
        if (selectedPatrimonailExcel.length <= 0) return notify("Selecione uma patrimonial para gerar o relatório!");
        var data = document.getElementById('data').value;
        if (data === '') return notify("Selecione uma data para gerar o relatório!");

        const responseFile = await api.post('/export/excel/investidores', {
            patrimonial_id: selectedPatrimonailExcel.value,
            patrimonial_acao: selectedPatrimonailExcel?.acoes,
            ano: data.substring(0, 4),
            mes: data.substring(5, 7),
            usuario_id: cookies.usuario.id,
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        if (responseFile.status !== 200) return notify("Erro ao gerar relatório, entre em contato com o suporte!")
        window.open(responseFile.data.url);
    }

    function getDataStart() {
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = currentDate.getMonth();

        if (month === 0) {
            month = 12;
            year--;
        }

        var formattedMonth = month < 10 ? '0' + month : month.toString();

        return year + '-' + formattedMonth;
    }

    var dataStart = getDataStart();


    function openPatrimonial() {
        if (historicFiles.mesSelecionado && historicFiles.anoSelecionado && selected.value) {
            var labelFix = selected.label.replaceAll(' ', '').replaceAll('/', '_')
            var linkToOpen = historicFiles?.urls.find(d => d.includes(('/' + labelFix)) && d.includes(('/' + historicFiles.anoSelecionado.value)) && d.includes(('/' + historicFiles.mesSelecionado.value.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ''))))

            if (linkToOpen) {
                setPatrimoniais({ ...patrimoniais, patrimoniais: false })
                return window.open(linkToOpen, '_blank')
            } else {
                setHistoricFiles({ ...historicFiles, mesSelecionado: undefined, anoSelecionado: undefined })
                if (window.alert('Nenhum arquivo com esses parametros, abrindo mes atual')) return openPage('/patrimoniais/alugueisdividendos', { patrimonialObj: selected })
            }
        }

        if (!selected.value) return notify("Escolha uma patrimonial para continuar!")
        return openPage('/patrimoniais/alugueisdividendos', { patrimonialObj: selected })
    }

    const menuProps = {
        budgetModal,
        setBudgetModal,
        open,
        setOpen,
        cadUsu,
        inconsistenciasInvestidores,
        notificacoes,
        selected,
        setCadUsu,
        setPatrimoniaisAD: () => { setOpen(false); setPatrimoniais({ ...patrimoniais, patrimoniais: !patrimoniais.patrimoniais }) },
        setPatrimoniaisFR: () => { setOpen(false); setPatrimoniais({ ...patrimoniais, patrimoniaisExtratoFundoReserva: !patrimoniais.patrimoniaisExtratoFundoReserva }) },
        setpatrimoniaisExcel: () => { setOpen(false); setPatrimoniais({ ...patrimoniais, patrimoniaisExcel: !patrimoniais.patrimoniaisExcel }) },
        setpatrimoniaisCloseAll: () => { setOpen(false); setPatrimoniais({ ...patrimoniais, patrimoniais: false, patrimoniaisExtratoFundoReserva: false }) },
        setNotificacoes: () => { setOpen(false); setNotificacaoModal(!notificacaoModal) },
        setSelected,
        setDatasFaltantes,
        setIsOpenDRE: () => { setOpen(false); setIsOpenDRE(true) },
        setIsOpenLog: () => { setOpen(false); setIsOpenLog(true) },
        setOpenFaturamento: () => { setOpen(false); setOpenFaturamento(true) },
        setOpenNFS: () => { setOpen(false); setOpenNFS(true) },
        setIsOpenAdiocionarTopline: () => { setOpen(false); setIsOpenAdiocionarTopline(true) }
    };

    return (
        cookies?.usuario
            ? <>
                {matches ? <MobileAppBar open={open} setOpen={setOpen} >
                    <MenuItens {...menuProps} />
                </MobileAppBar> :
                    <Drawer
                        id='drawer'
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        <MenuItens {...menuProps} />
                    </Drawer>
                }

                <Modal show={datasFaltantes.visible} onHide={() => setDatasFaltantes({ ...datasFaltantes, visible: false })}>
                    <Modal.Header>
                        <div className="titulo">Consulta pendencias!</div>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Código da unidade: HXXXX"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                id='unidade_idFC'
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={() => { getDataFaltante(document.getElementById('unidade_idFC').value) }}>Consultar</Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <FormControl as="textarea" value={datasFaltantes.dias} aria-label="With textarea" hidden={datasFaltantes.ta} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ textAlign: 'right' }}>
                            <Button size="sm" onClick={() => setDatasFaltantes({ ...datasFaltantes, visible: false })} variant="primary" style={{ marginRight: '10px' }} type="button">
                                Fechar
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <ModalNotificacoes notificacoes={notificacoes} deletanotificacao={deletanotificacao} onClickButton={() => setNotificacaoModal(false)} onClose={() => setNotificacaoModal(false)} openPage={openPage} vizualizaNotificacoes={vizualizaNotificacoes} unidades={unidades} open={notificacaoModal} setNotificacaoModal={setNotificacaoModal} />

                <Modal show={patrimoniais.patrimoniais} onHide={() => setPatrimoniais({ ...patrimoniais, patrimoniais: !patrimoniais.patrimoniais })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Escolha a patrimonial a consultar!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Select
                                    name="colors"
                                    options={historicFiles?.anos?.reduce((acc, cv) => acc = [...acc, { label: cv, value: cv }], [])}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Escolha o Ano..."
                                    isClearable={true}
                                    onChange={(e) => setHistoricFiles({ ...historicFiles, anoSelecionado: e })}
                                />
                            </Col>
                            <Col>
                                <Select
                                    name="colors"
                                    options={meses?.slice(1, meses.length)?.reduce((acc, cv) => acc = [...acc, { label: cv, value: cv }], [])}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    isClearable={true}
                                    classNamePrefix="select"
                                    placeholder="Escolha o Mês..."
                                    onChange={(e) => setHistoricFiles({ ...historicFiles, mesSelecionado: e })}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    name="colors"
                                    options={patrimoniais.patrimoniaisList}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Escolha a patrimonial..."
                                    onChange={setSelected}
                                />
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ textAlign: 'right' }}>
                            <Button size="sm" onClick={() => setPatrimoniais({ ...patrimoniais, patrimoniais: !patrimoniais.patrimoniais })} variant="outline-danger" style={{ marginRight: '10px' }} type="button">
                                Fechar
                            </Button>
                            <Button size="sm" variant="primary" onClick={openPatrimonial}>Consultar</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal show={budgetModal.modalVisible} onHide={() => setBudgetModal({ ...budgetModal, modalVisible: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Escolha o combo para editar o budget!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Select
                            name="colors"
                            options={budgetModal.combos}
                            closeMenuOnSelect={true}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Escolha o combo..."
                            onChange={(e) => { setBudgetModal({ ...budgetModal, selected: e }) }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ textAlign: 'right' }}>
                            <Button size="sm" onClick={() => setBudgetModal({ ...budgetModal, modalVisible: false })} variant="outline-danger" style={{ marginRight: '10px' }} type="button">
                                Fechar
                            </Button>
                            <Button size="sm" variant="primary" onClick={() => {
                                if (budgetModal?.selected?.value) {
                                    setOpen(false);
                                    setControladoria({ controladoria: false });
                                    setGourmet({ gourmet: false });
                                    setBudgetModal({ ...budgetModal, modalVisible: false });
                                    openPage('/budget', { combo_id: budgetModal?.selected?.value, combo: budgetModal?.selected })
                                } else {
                                    notify("Escolha um combo para continuar!")
                                }
                            }}>Consultar</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal show={patrimoniais.patrimoniaisExcel} onHide={() => setPatrimoniais({ ...patrimoniais, patrimoniaisExcel: !patrimoniais.patrimoniaisExcel })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Gerar Comparativo Investidores</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ marginBottom: 5 }}>
                            <Col>
                                <Select
                                    name="colors"
                                    options={allPatrimoniais}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Escolha a patrimonial..."
                                    onChange={setSelectedpatrimonialExcel}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <input type="month" defaultValue={dataStart} name="data" id="data" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" size='sm' onClick={() => { gerarOrcadoRealziadoInvestidores(); }}>
                            Gerar Relatório
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={patrimoniais.patrimoniaisExtratoFundoReserva} onHide={() => setPatrimoniais({ ...patrimoniais, patrimoniaisExtratoFundoReserva: !patrimoniais.patrimoniaisExtratoFundoReserva })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Movimentação do Fundo de Reserva</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ marginBottom: 5 }}>
                            <Col>
                                <Select
                                    name="colors"
                                    options={allPatrimoniais}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Escolha a patrimonial..."
                                    onChange={setSelected}
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                        <Col>
                            <input type="month" defaultValue={dataStart} name="data" id="data" />
                        </Col>
                    </Row> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" size='sm' onClick={() => { if (selected.value) { openPage('/patrimoniais/fundodereserva', { patrimonialObj: selected }) } else { notify("Escolha uma patrimonial para continuar!") } }}>
                            Abrir
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={!cadUsu} onHide={() => setCadUsu(true)} >
                    <Modal.Header closeButton>
                        <Modal.Title>Novo usuario!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Insira as informações para cadastros:</p>
                        <Form noValidate onSubmit={addUserHandler}>
                            <Form.Group>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    onChange={e => setNomeUser(e.target.value)}
                                    placeholder="Nome" />
                            </Form.Group>
                            <Form.Group>
                                <InputGroup>
                                    <Form.Control
                                        size="sm"
                                        required
                                        type="email"
                                        onChange={e => {
                                            setEmailUser(e.target.value);
                                        }}
                                        placeholder="Email"
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    id="perfilSelector"
                                    custom
                                >
                                    <option value="-1">Escolha um perfil</option>
                                    {JSON.parse(sessionStorage.getItem('permissoesCargos'))
                                        ? JSON.parse(sessionStorage.getItem('permissoesCargos')).map((cargo, idx) => {
                                            if (cargo.id === 0) {
                                                return null;
                                            }
                                            return (<option key={idx} value={cargo.id}>{cargo.cargo}</option>)
                                        })
                                        : null
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                {/* <FormControlMI className={classes.formControl}> */}
                                <MultiSelect
                                    options={unidades}
                                    value={unidadesSelected}
                                    onChange={setUnidadesSelected}
                                    labelledBy="Select"
                                    className="MultiSelect"
                                    isLoading={MSLoading}
                                    overrideStrings={{
                                        "allItemsAreSelected": "Todos as unidades selecionadas.",
                                        "clearSearch": "Limpar busca",
                                        "noOptions": "Sem opções",
                                        "search": "Buscar",
                                        "selectAll": "Selecionar Todas",
                                        "selectSomeItems": "Escolha as unidades..."
                                    }}
                                />
                            </Form.Group>
                            {/* </FormControlMI> */}
                            {/* <Form.Group>
                                <Form.Label size="sm">Escolha a unidade:</Form.Label>
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    id="unidadeSelector"
                                    custom
                                    multiple
                                >
                                    {JSON.parse(sessionStorage.getItem('unidades'))
                                        ? JSON.parse(sessionStorage.getItem('unidades')).map((unidade, idx) => {
                                            return (<option ico key={idx} value={unidade.id}>{unidade.unidade}<>{()}</></option>)
                                        }) : null
                                    }
                                </Form.Control>
                            </Form.Group> */}
                            <div style={{ textAlign: 'center' }}>
                                <Button size="sm" onClick={() => { setCadUsu(true) }} variant="outline-secondary" style={{ marginRight: '10px' }} type="button">
                                    Fechar
                                </Button>
                                <Button size="sm" variant="primary" style={{ marginLeft: '10px' }} type="submit">
                                    Cadastrar
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                <ModalAdicionarNFS title='Adicionar NFS-e' open={openNFS} onClose={() => setOpenNFS(false)} onClickButton={() => setOpenNFS(false)} />
                <ModalAdicionarFaturamento title='Adicionar Faturamento' open={openFaturamento} onClose={() => setOpenFaturamento(false)} onClickButton={() => { setOpenFaturamento(false); }} />
                <ModalLogErros title='Log de Erros' open={isOpenLog} onClose={() => setIsOpenLog(false)} onClickButton={() => setIsOpenLog(false)} />
                <ModalCadastroDre open={isOpenDRE} onClose={() => setIsOpenDRE(false)} onClickButton={() => setIsOpenDRE(false)} inconsistenciasInvestidores={inconsistenciasInvestidores} />
                <ModalAdicionarTopline onClickButton={() => setIsOpenAdiocionarTopline(false)} onClose={() => setIsOpenAdiocionarTopline(false)} open={isOpenAdiocionarTopline} title='Adicionar Dados ADM' />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    style={{ zIndex: 1000000 }}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover />
            </>
            : null
    );
}
