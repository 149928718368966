import React, { useEffect } from "react";
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
  setFetchMethod,
} from "darkreader";
import { FaMoon, FaSun } from "react-icons/fa";

setFetchMethod(window.fetch);

const DarkModeToggle = ({ setIsDarkMode, isDarkMode }) => {
  const toggleDarkMode = () => {
    if (isDarkMode) {
      disableDarkMode();
    } else {
      enableDarkMode({
        brightness: 100,
        contrast: 90,
        sepia: 10,
      });
    }
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    if (isDarkMode) {
      enableDarkMode({
        brightness: 100,
        contrast: 90,
        sepia: 10,
      });
    } else {
      disableDarkMode();
    }
  }, [isDarkMode]);

  return (
    <div
      onClick={toggleDarkMode}
      style={{
        background: "none",
        border: "none",
        cursor: "pointer",
      }}
    >
      {isDarkMode ? (
        <FaSun size={"1em"} color="#FFA500" />
      ) : (
        <FaMoon size={"1em"} color="#4D5B6B" />
      )}
    </div>
  );
};

export default DarkModeToggle;
