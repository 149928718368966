import React, { useState, useEffect } from "react";
import ModalGenerico from "../modalgenerico.tsx";
import { MdErrorOutline } from "react-icons/md";
import api from "../../../services/api.js";
import { useCookies } from "react-cookie";
import { format } from "date-fns";
import { MenuItem, Select, Tab, Tabs } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";

interface LogErro {
  app_name: string;
  message: string;
  process_id: number;
  timestamp: string;
  type: string;
  tag: string;
}

interface ModalLogErrosProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickButton: () => void;
}

interface SearchInputProps {
  onSearch: (searchTerm: string) => void;
  searchTerm: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ onSearch, searchTerm }) => {
  return (
    <input
      type="text"
      placeholder="Buscar Logs..."
      value={searchTerm}
      onChange={(e) => onSearch(e.target.value)}
    />
  );
};

const ModalLogErros: React.FC<ModalLogErrosProps> = ({
  open,
  onClose,
  title,
  onClickButton,
}) => {
  const [logErros, setLogErros] = useState<LogErro[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<LogErro[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [cookies] = useCookies(["usuario"]);
  const [activeTab, setActiveTab] = useState(0);

  const CurrentDate = new Date();
  const formattedDate = format(CurrentDate, "dd/MM/yyyy");
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  const [dates, setDates] = useState([]);
  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "dd/MM/yyyy HH:mm:ss");
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(1);
  };

  const filteredByTab = () => {
    switch (activeTab) {
      case 1:
        return filteredLogs.filter((log) => log.type === "err");
      case 2:
        return filteredLogs.filter((log) => log.type === "out");
      default:
        return filteredLogs;
    }
  };
  const CarregarLogs = async () => {
    try {
      const response = await api.get(`/logs/open/file?data=${selectedDate}`, {
        headers: {
          Authorization: `Bearer ${cookies.usuario.accessToken}`,
        },
      });
      const allData = response?.data?.all || [];
      const combinedLogs = allData.map((log) => ({
        ...log,
        tag: log.type === "err" ? "Erro" : "Output",
      }));

      const dates = response?.data.dateOptions;
      setDates(dates);
      setLogErros(combinedLogs);
      setFilteredLogs(combinedLogs);
    } catch (error) {
      console.error("Erro ao carregar logs:", error);
    }
  };

  useEffect(() => {
    const filtered = logErros.filter((log) => {
      const logDate = formatDate(log.timestamp).split(" ")[0];
      return (
        log.message.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!selectedDate || logDate === selectedDate)
      );
    });
    setFilteredLogs(filtered);
  }, [searchTerm, selectedDate, logErros]);

  useEffect(() => {
    if (open && selectedDate) {
      CarregarLogs();
    } else {
      setSearchTerm("");
      setFilteredLogs(logErros);
    }
  }, [open, selectedDate]);

  useEffect(() => {
    const filtered = logErros.filter(
      (log) =>
        log.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(log.timestamp)?.includes(searchTerm)
    );
    setFilteredLogs(filtered);
  }, [searchTerm, logErros]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 10;

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredByTab().slice(indexOfFirstLog, indexOfLastLog);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const totalLogs = filteredByTab().length;
  const totalPages = Math.ceil(totalLogs / logsPerPage);

  return (
    <ModalGenerico
      open={open}
      onClose={() => {
        onClose();
        setSearchTerm("");
        setFilteredLogs(logErros);
      }}
      title={title}
      isButtonHeaderDisabled
      buttonHeaderType="contained"
      onClickButton={onClickButton}
      searchInput={
        <>
          <SearchInput onSearch={handleSearch} searchTerm={searchTerm} />
          <Select
            variant="standard"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e?.target?.value)}
            displayEmpty
          >
            <MenuItem value="">Data</MenuItem>
            {dates.map((date, index) => (
              <MenuItem key={index} value={date}>
                {date}
              </MenuItem>
            ))}
          </Select>
        </>
      }
      showSearchIcon={true}
      buttonContent={<MdErrorOutline />}
      dialogContent={
        <>
          <Tabs value={activeTab} onChange={handleChangeTab} centered>
            <Tab label="Todos" />
            <Tab label="Erro" />
            <Tab label="Output" />
          </Tabs>
          <div>
            {currentLogs.map((log, index) => (
              <li
                key={index}
                style={{
                  position: "relative",
                  padding: "10px",
                  marginBottom: "5px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  background: "#fff",
                }}
              >
                <span>[{log.tag}] </span>
                <p>{log.message}</p>
                <p>{formatDate(log.timestamp)}</p>
                <span
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                    padding: "2px 5px",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "0.8em",
                    background: log.tag === "Erro" ? "#ff0000" : "#ffbf00",
                    borderRadius: "3px",
                  }}
                >
                  {log.tag}
                </span>
              </li>
            ))}
            {filteredLogs.length === 0 && <p>Nenhum log encontrado.</p>}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePage}
            />
          </div>
        </>
      }
    />
  );
};

export default ModalLogErros;
