import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import {
    RiDeleteBin2Line,
    RiFileCopy2Line,
    RiFileEditLine,
} from "react-icons/ri";
import Select from "react-select";
import { useCookies } from "react-cookie";
import { AiOutlineFilter, AiOutlinePlus } from "react-icons/ai";
import { AiOutlineFileText, AiOutlinePercentage } from "react-icons/ai";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";

import api from "../../../services/api";

function DefinicaoPremissas() {
    const [premissas, setPremissas] = useState({
        premissas: [],
        premissasMemory: [],
    });
    const [editaPremissa, setEditaPremissas] = useState([false]);
    const [editaPremissaAliquota, setEditaPremissasAliquota] = useState({
        premissas: [],
        premissasMemory: [],
    });
    const [editaPremissaAtual, setEditaPremissasAtual] = useState("");
    const [editaPremissaAtualAliquota, setEditaPremissasAtualAliquota] =
        useState(null);
    const [unidades, setUnidades] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedAliquota, setSelectedAliquota] = useState([]);
    const [selectedContaDreAliquota, setSelectedContaDreAliquota] = useState([]);
    const [selectedBaseDeCalculoAliquota, setSelectedBaseDeCalculoAliquota] =
        useState([]);
    const [cookies] = useCookies([]);
    const [fab, setFab] = useState(true);
    const [contasDre, setContasDre] = useState({
        all: [],
        filtered: [],
        allData: [],
    });
    const [combos, setCombos] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({
        modalVisivel: false,
    });

    const [anoBudget, setAnoBudget] = useState(null);

    useEffect(() => {
        var array = [];
        JSON.parse(sessionStorage.getItem("unidadesMemory")).map((unidade) =>
            array.push({ label: unidade.unidade, value: unidade.id })
        );
        setUnidades(array);
        loadData();

        // eslint-disable-next-line
    }, [sessionStorage.getItem("unidadesMemory")]);

    useEffect(() => {
        const coluna = sessionStorage.getItem("ordenacaoColuna");
        const direcao = sessionStorage.getItem("ordenacaoDirecao");

        if (coluna && direcao) {
            const e = { target: { className: direcao } };
            orderTable(e, coluna);
        }
    }, []);

    const notify = ($msg, $time = 5000) => toast($msg, {
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined, closeButton: (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 20,
            }}>
                <a href="#" style={{ color: '#007bff' }} onClick={() => window.location.reload()}>Ver</a>
            </div>
        ),
    });

    async function loadData() {
        if (premissas?.premissasMemory.length > 0) var reload = true;
        try {
            await api
                .get(`/premissas/${cookies.usuario.id}`, {
                    headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
                })
                .then((res) => {
                    setAnoBudget(res.data.anoBudget);
                    res.data.premissas.map((premissa) => {
                        if (premissa.unidade_id === null) {
                            return (premissa.unidade = premissa.combo_nome);
                        } else {
                            return (premissa.unidade = JSON.parse(
                                sessionStorage.getItem("unidades")
                            ).find((d) => d.id === premissa.unidade_id).unidade);
                        }
                    });

                    setPremissas({
                        ...premissas,
                        premissas: res.data.premissas.filter(
                            (d) => d.anobudget + "" === res.data.anoBudget + ""
                        ),
                        premissasMemory: res.data.premissas,
                    });
                    // Aqui vem o valor filtrado por padrao do back  anoFiltro: res.data.anoBudget,
                    setSelectedFilter({
                        ...selectedFilter,
                        anoBudget: res.data.anoBudget,
                        anoFiltro: res.data.anoBudget,
                        optAnosBudget: buildOpt(res.data.anoBudget),
                        selectedOptAnos: [
                            {
                                label: parseInt(res.data.anoBudget),
                                value: parseInt(res.data.anoBudget),
                            },
                        ],
                    });
                    if (reload) updateFilterPremissas();
                });
        } catch (error) {
            console.error(error);
            notify("Erro ao carregar premissas!");
        }

        try {
            await api.get(`/combos?usuario_id=${cookies.usuario.id}`, {
                headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
            })
                .then((res) => {
                    // eslint-disable-next-line
                    var myCombos = res.data
                        .filter((d) =>
                            JSON.parse(sessionStorage.getItem("unidades")).find(
                                (f) => d.id == f.combo_id
                            )
                        )
                        .reduce(
                            (acc, cv) =>
                            (acc = [
                                ...acc,
                                {
                                    label: cv.combo,
                                    value: cv.id,
                                    organizacao_id: JSON.parse(
                                        sessionStorage.getItem("unidades")
                                    ).find((d) => d.combo_id == cv.id)?.organizacao_id,
                                },
                            ]),
                            []
                        );
                    setCombos(myCombos);
                });
        } catch (err) {
            console.error("Erro ao pegar combos: ", err);
            notify("Erro ao carregar combos!");
        }

        try {
            await api
                .get("/estruturadre", {
                    headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
                })
                .then((res) => {
                    var myContas = res.data.reduce((acc, cv) => {
                        if (cv.codigo_id === cv.descricao) {
                            return (acc = [
                                ...acc,
                                {
                                    label: cv.descricao,
                                    value: cv.codigo_id,
                                    destaque: true,
                                    organizacao_id: cv.organizacao_id,
                                    isAnalitica: cv.composicao !== null ? false : true,
                                },
                            ]);
                        } else {
                            return (acc = [
                                ...acc,
                                {
                                    label: cv.codigo_id + " - " + cv.descricao,
                                    value: cv.codigo_id,
                                    destaque: false,
                                    organizacao_id: cv.organizacao_id,
                                    isAnalitica: cv.composicao !== null ? false : true,
                                },
                            ]);
                        }
                    }, []);
                    setContasDre({
                        all: myContas,
                        filtered: myContas,
                        allData: res.data,
                    });
                });
        } catch (error) {
            console.error(error);
            notify("Erro ao carregar contas contabeis!");
        }
    }

    function createPremissa(premissa, anobudget, usuario_id) {
        var idsUnidadesSelecionadas = selected.reduce(
            (acc, cv) => (acc = [...acc, cv.value]),
            []
        );
        idsUnidadesSelecionadas.map(async (unidade) => {
            var org_id = JSON.parse(sessionStorage.getItem("unidades")).find(
                (org) => org.id === unidade
            ).organizacao_id;
            try {
                await api
                    .post(
                        "/premissas/geral",
                        {
                            premissa,
                            unidade_id: unidade,
                            organizacao_id: org_id,
                            anobudget,
                            usuario_id,
                        },
                        {
                            headers: {
                                authorization: `Bearer ${cookies.usuario.accessToken}`,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            const labelUnidadeAtual = selected.find((comboAtual) => comboAtual.value === unidade)
                            const newPremissa = {
                                id: res.data.id,
                                premissa,
                                unidade_id: unidade,
                                unidade: labelUnidadeAtual.label,
                                organizacao_id: org_id,
                                anobudget: parseInt(anoBudget),
                                usuario_id,
                                type: 'Instrução',
                                isImposto: false,
                            };

                            const newPremissaMemory = { ...newPremissa };

                            setPremissas(prevState => ({
                                ...prevState,
                                premissas: [...prevState.premissas, newPremissa],
                                premissasMemory: [...prevState.premissasMemory, newPremissaMemory]
                            }));

                            notify("Premissa criada com sucesso!");
                            setEditaPremissas([false]);
                        }

                    });

            } catch (error) {
                console.error(error);
                notify("Erro ao cadastrar premissa!");
            }
        });
    }

    async function atualizaPremissa(id, premissa, anobudget, usuario_id) {
        var idsUnidadesSelecionadas = selected.reduce(
            (acc, cv) => (acc = [...acc, cv.value]),
            []
        );
        idsUnidadesSelecionadas.map(async (unidade) => {
            var org_id = JSON.parse(sessionStorage.getItem("unidades")).find(
                (org) => org.id === unidade
            ).organizacao_id;
            try {
                await api
                    .put(
                        "/premissas/geral/" + id,
                        {
                            premissa,
                            unidade_id: unidade,
                            organizacao_id: org_id,
                            anobudget,
                            usuario_id,
                        },
                        {
                            headers: {
                                authorization: `Bearer ${cookies.usuario.accessToken}`,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            const updatedPremissas = premissas.premissas.map((premissaAtual) => {
                                if (premissaAtual.id === id && premissaAtual.type === 'Instruções') {
                                    return {
                                        ...premissaAtual,
                                        premissa: premissa
                                    };
                                }
                                return premissaAtual
                            });

                            const updatedPremissasMemory = premissas.premissasMemory.map((premissaAtualMemory) => {
                                if (premissaAtualMemory.id === id && premissaAtualMemory.type === 'Instruções') {
                                    return {
                                        ...premissaAtualMemory,
                                        premissa: premissa
                                    };
                                }
                                return premissaAtualMemory
                            });

                            setPremissas({
                                ...premissas,
                                premissas: updatedPremissas,
                                premissasMemory: updatedPremissasMemory

                            });

                            notify("Premissa atualizada com sucesso!");
                            setEditaPremissas([false]);
                        }
                    })
            } catch (error) {
                console.error(error);
                notify("Erro ao atualizar premissa!");
            }
        });
    }

    async function deletePremissa(id) {
        try {
            await api
                .delete(`/premissas/geral/${id}`, {
                    headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
                })
                .then((res) => {
                    if (res.status === 200) {
                        notify('Premissa excluida com sucesso!')
                        setPremissas({ ...premissas, premissas: premissas.premissas.filter(premissa => premissa.id !== id), premissasMemory: premissas.premissasMemory.filter(premissa => premissa.id !== id) })
                    } else {
                        notify('Erro ao excluir premissa!')
                    }
                });
        } catch (error) {
            console.error(error);
            notify("Erro ao apagar premissa!");
        }
    }

    function createPremissaAliquota(anobudget, usuario_id) {
        var contasReferencia = selectedBaseDeCalculoAliquota.reduce(
            (acc, cv) => (acc = [...acc, cv.value]),
            []
        );
        var idsUnidadesSelecionadas = selectedAliquota.reduce(

            (acc, cv) => (acc = [...acc, cv.value]),
            []
        );

        idsUnidadesSelecionadas.map(async (unidade) => {
            try {
                await api
                    .post(
                        "/premissas/imposto",
                        {
                            combo_id: unidade,
                            anobudget,
                            usuario_id,
                            codigo_id: selectedContaDreAliquota.value,
                            contasReferencia,
                            percReferencia: editaPremissaAtualAliquota,
                        },
                        {
                            headers: {
                                authorization: `Bearer ${cookies.usuario.accessToken}`,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            const codigoIdEnviado = selectedContaDreAliquota.value
                            const labelUnidadeAtual = selectedAliquota.find((comboAtual) => comboAtual.value === unidade)
                            const contaDreCorreta = contasDre.all.find((conta) => conta.value === codigoIdEnviado);
                            const labelSemCodigo = contaDreCorreta.label.split(" - ").slice(1).join(" - ")
                            const newPremissa = {
                                id: res.data.id,
                                combo_id: unidade,
                                unidade_id: null,
                                combo_nome: 'Combo ' + labelUnidadeAtual.label,
                                unidade: 'Combo ' + labelUnidadeAtual.label,
                                premissa: `Aliquota ${editaPremissaAtualAliquota}% de ${labelSemCodigo}`,
                                percReferencia: parseFloat(editaPremissaAtualAliquota),
                                codigo_id: selectedContaDreAliquota.value,
                                anobudget: parseInt(anoBudget),
                                contasReferencia,
                                type: 'Aliquota',
                                isimposto: true,
                            };

                            const newPremissaMemory = { ...newPremissa };


                            setPremissas(prevState => ({
                                ...prevState,
                                premissas: [...prevState.premissas, newPremissa],
                                premissasMemory: [...prevState.premissasMemory, newPremissaMemory]

                            }));

                            notify("Premissa criada com sucesso!");
                            setEditaPremissas([false]);


                        }

                    });

            } catch (error) {
                console.error(error);
                notify("Erro ao cadastrar aliquota!");
            }
        });
    }


    async function atualizaPremissaAliquota(id, anobudget, usuario_id) {
        var contasReferencia = selectedBaseDeCalculoAliquota.reduce(
            (acc, cv) => (acc = [...acc, cv.value]),
            []
        );
        var idsUnidadesSelecionadas = selectedAliquota.reduce(
            (acc, cv) => (acc = [...acc, cv.value]),
            []
        );
        idsUnidadesSelecionadas.map(async (unidade) => {
            try {
                await api
                    .put(
                        "/premissas/imposto/" + id,
                        {
                            combo_id: unidade,
                            anobudget,
                            usuario_id,
                            codigo_id: selectedContaDreAliquota[0].value,
                            contasReferencia,
                            percReferencia: editaPremissaAtualAliquota,
                        },
                        {
                            headers: {
                                authorization: `Bearer ${cookies.usuario.accessToken}`,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {

                            const codigoIdEnviado = selectedContaDreAliquota[0].value;
                            const contaDreCorreta = contasDre.all.find((conta) => conta.value === codigoIdEnviado);
                            const labelSemCodigo = contaDreCorreta.label.split(" - ").slice(1).join(" - ");

                            const updatedPremissasAliquotas = premissas.premissas.map((premissaAtual) => {


                                if (premissaAtual.id === id && premissaAtual.type === 'Aliquota') {
                                    if (contaDreCorreta) {

                                        return {
                                            ...premissaAtual,
                                            premissa: `Aliquota ${editaPremissaAtualAliquota}% de ${labelSemCodigo}`,
                                            percReferencia: editaPremissaAtualAliquota,
                                            contasReferencia

                                        };
                                    } else {
                                        console.error('contaDreCorreta não encontrada para o código ID:', codigoIdEnviado);
                                        return premissaAtual;
                                    }
                                }
                                return premissaAtual;
                            });

                            const updatedPremissasAliquotasMemory = premissas.premissasMemory.map((premissaAtualMemory) => {
                                if (premissaAtualMemory.id === id && premissaAtualMemory.type === 'Aliquota') {
                                    if (contaDreCorreta)
                                        return {
                                            ...premissaAtualMemory,
                                            premissa: `Aliquota ${editaPremissaAtualAliquota}% de ${labelSemCodigo}`,
                                            percReferencia: editaPremissaAtualAliquota,
                                            contasReferencia
                                        };
                                }
                                return premissaAtualMemory
                            });
                            setPremissas({
                                ...premissas,
                                premissas: updatedPremissasAliquotas,
                                premissasMemory: updatedPremissasAliquotasMemory
                            });
                            notify("Aliquota atualizada com sucesso!");
                            setEditaPremissasAliquota([false])
                        }
                    });
            } catch (error) {
                console.error(error);
                notify("Erro ao atualizar aliquota!");
            }
        });
    }

    async function deletePremissaAliquota(id) {
        try {
            await api
                .delete(`/premissas/imposto/${id}`, {
                    headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
                })
                .then((res) => {
                    if (res.status === 200) {
                        notify('Premissa excluida com sucesso!')
                        setPremissas({ ...premissas, premissas: premissas.premissas.filter(premissa => premissa.id !== id), premissasMemory: premissas.premissasMemory.filter(premissa => premissa.id !== id) })
                    } else {
                        notify('Erro ao excluir premissa!')
                    }
                });
        } catch (error) {
            console.error(error);
            notify("Erro ao apagar premissa!");
        }
    }

    function orderTable(e, coluna, maintainCurrentOrder = false, NovaPremissa) {
        // Removendo a classe sorted dos elementos que não correspondem à coluna atual
        [...document.getElementsByClassName("sorted")].forEach((element) => {
            if (!element.className.includes(coluna)) {
                element.className = "";
            }
        });

        // Determinando a nova direção da ordenação
        let newDirection;
        if (maintainCurrentOrder) {
            newDirection = e.target.className.includes("-desc") ? "-desc" : "-asc";
        } else {
            newDirection = e.target.className.includes("-desc") ? "-asc" : "-desc";
        }

        var arrayToSort = premissas.premissas;
        var arrayToSortMemory = premissas.premissasMemory;

        if (NovaPremissa) {
            arrayToSort = [...arrayToSort, NovaPremissa]
            arrayToSortMemory = [...arrayToSortMemory, NovaPremissa]
        }

        // Atualizando a classe do elemento alvo
        e.target.className = `sorted ${coluna}${newDirection}`;

        // Reordenando a lista de premissas
        arrayToSort.sort((a, b) => {
            if (a[coluna] > b[coluna]) return newDirection === "-desc" ? -1 : 1;
            if (a[coluna] < b[coluna]) return newDirection === "-desc" ? 1 : -1;
            return 0;
        });

        // Atualizando a sessionStorage
        sessionStorage.setItem("ordenacaoColuna", coluna);
        sessionStorage.setItem("ordenacaoDirecao", e.target.className);

        // Atualizando o estado das premissas
        setPremissas({
            ...premissas,
            premissas: arrayToSort,
            premissasMemory: arrayToSortMemory
        });
    }

    function updateFilterPremissas() {
        var premissasFiltradas = premissas.premissasMemory;
        if (selectedFilter?.selectedOptAnos?.length > 0)
            premissasFiltradas = premissasFiltradas.filter((d) =>
                selectedFilter?.selectedOptAnos
                    ?.reduce((acc, cv) => (acc = [...acc, cv?.value]), [])
                    ?.includes(d?.anobudget)
            );
        if (selectedFilter?.selectedOptUnidades?.length > 0)
            premissasFiltradas = premissasFiltradas.filter(
                (d) =>
                    selectedFilter?.selectedOptUnidades
                        ?.reduce((acc, cv) => (acc = [...acc, cv?.value]), [])
                        ?.includes(d?.unidade_id) ||
                    selectedFilter?.selectedOptUnidades
                        ?.reduce((acc, cv) => (acc = [...acc, cv?.value]), [])
                        ?.includes(d?.combo_id)
            );
        if (selectedFilter?.selectedOptTipo?.length > 0)
            premissasFiltradas = premissasFiltradas.filter((d) =>
                selectedFilter?.selectedOptTipo
                    ?.reduce((acc, cv) => (acc = [...acc, cv?.value]), [])
                    ?.includes(d?.type)
            );
        setPremissas({ ...premissas, premissas: premissasFiltradas });
    }

    function buildOpt(anoBudgetAtual) {
        var arr = [];
        for (let i = 2022; i <= parseInt(anoBudgetAtual); i++) {
            arr.push({ label: i, value: i });
        }
        return arr;
    }

    async function copyPremissa(id, isImposto) {
        var type = 1;
        if (isImposto) type = 2;
        try {
            var res = await api.post(
                `/premissas/copy/${id}/${type}`,
                {
                    anobudget: selectedFilter?.anoBudget,
                },
                { headers: { authorization: `Bearer ${cookies.usuario.accessToken}` } }
            );

            if (res.status === 200) {
                const copia = premissas.premissas.find(
                    (premissa) =>
                        premissa.id === id &&
                        premissa.type === (type === 1 ? "Instruções" : "Aliquota")
                );

                var novaPremissa = { ...copia };
                novaPremissa.anobudget = parseInt(anoBudget);
                novaPremissa.id = res.data.id;

                const coluna = sessionStorage.getItem("ordenacaoColuna");
                const direcao = sessionStorage.getItem("ordenacaoDirecao");
                if (coluna && direcao) {
                    const e = { target: { className: direcao } };
                    orderTable(e, coluna, true, novaPremissa);
                }

                notify("Premissa criada com sucesso");
            } else {
                throw new Error("Erro ao criar premissa");
            }
        } catch (error) {
            console.error("Erro ao duplicar premissas: " + error);
            notify("Erro ao duplicar premissa! Tente novamente mais tarde!");
        }
    }

    return (
        <Container fluid>
            <Table
                hover
                striped
                bordered
                size="sm"
                style={{ background: "white", marginBottom: "5rem" }}
            >
                <thead>
                    <tr>
                        <th onClick={(e) => orderTable(e, "unidade")}>Unidade</th>
                        <th onClick={(e) => orderTable(e, "premissa")} width="60%">
                            Premissa
                        </th>
                        <th onClick={(e) => orderTable(e, "anobudget")} width="10%">
                            Budget
                        </th>
                        <th
                            width="10%"
                            style={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {premissas?.premissas?.map((premissa, index) => {
                        return (
                            <tr key={index}>
                                <td style={{ verticalAlign: "middle" }}>
                                    {premissa.isimposto
                                        ? premissa.combo_nome
                                        : JSON.parse(sessionStorage.getItem("unidades")).find(
                                            (d) => d.id === premissa.unidade_id
                                        ).unidade}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>{premissa.premissa}</td>
                                <td style={{ verticalAlign: "middle" }}>
                                    {premissa.anobudget}
                                </td>
                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                    {/* eslint-disable-next-line */}
                                    {premissa.anobudget == selectedFilter?.anoBudget ? (
                                        <Button
                                            style={{ margin: " 0 5px " }}
                                            variant="warning"
                                            size="sm"
                                            onClick={
                                                premissa.isimposto === true
                                                    ? () => {
                                                        setEditaPremissasAliquota([true, premissa]);
                                                        setEditaPremissasAtualAliquota(
                                                            premissa.percReferencia
                                                        );
                                                        setSelectedContaDreAliquota(
                                                            contasDre?.all?.filter(
                                                                (conta) => conta.value === premissa.codigo_id
                                                            )
                                                        );
                                                        setSelectedBaseDeCalculoAliquota(
                                                            contasDre?.all?.filter((contas) =>
                                                                premissa.contasReferencia.includes(
                                                                    contas.value
                                                                )
                                                            )
                                                        );
                                                        setSelectedAliquota([
                                                            {
                                                                label: premissa.combo_nome,
                                                                value: premissa.combo_id,
                                                            },
                                                        ]);
                                                    }
                                                    : () => {
                                                        setEditaPremissas([true, premissa]);
                                                        setEditaPremissasAtual(premissa.premissa);
                                                        setSelected([
                                                            {
                                                                label: JSON.parse(
                                                                    sessionStorage.getItem("unidades")
                                                                ).find(
                                                                    (unidade) =>
                                                                        unidade.id === premissa.unidade_id
                                                                ).unidade,
                                                                value: premissa.unidade_id,
                                                            },
                                                        ]);
                                                    }
                                            }
                                        >
                                            <RiFileEditLine />
                                        </Button>
                                    ) : null}
                                    {/* eslint-disable-next-line */}
                                    {premissa.anobudget == selectedFilter?.anoBudget ? (
                                        <Button
                                            style={{ margin: " 0 5px " }}
                                            variant="danger"
                                            size="sm"
                                            onClick={
                                                premissa.isimposto === true
                                                    ? () => {
                                                        if (
                                                            window.confirm(
                                                                "Deseja realmente excluir essa premissa?"
                                                            )
                                                        )
                                                            deletePremissaAliquota(premissa.id);
                                                    }
                                                    : () => {
                                                        if (
                                                            window.confirm(
                                                                "Deseja realmente excluir essa premissa?"
                                                            )
                                                        )
                                                            deletePremissa(premissa.id);
                                                    }
                                            }
                                        >
                                            <RiDeleteBin2Line />
                                        </Button>
                                    ) : null}
                                    {/* eslint-disable-next-line */}
                                    {premissa.anobudget != selectedFilter?.anoBudget ? (
                                        <Button
                                            style={{ margin: " 0 5px " }}
                                            variant="info"
                                            size="sm"
                                            onClick={() =>
                                                copyPremissa(premissa.id, premissa.isimposto)
                                            }
                                        >
                                            <RiFileCopy2Line />
                                        </Button>
                                    ) : null}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Modal show={editaPremissa[0]} onHide={() => setEditaPremissas([false])}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editaPremissa.length <= 1
                            ? "Nova premissa budget " + selectedFilter?.anoBudget
                            : "Atualização de premissa"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Premissa:</Form.Label>
                            <Form.Control
                                id="formPremissa"
                                as="textarea"
                                rows={3}
                                onChange={(e) => setEditaPremissasAtual(e.target.value)}
                                value={editaPremissa.length < 1 ? null : editaPremissaAtual}
                                placeholder="Descreva a premissa"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Unidade(s):</Form.Label>
                            <Select
                                isMulti
                                name="colors"
                                options={unidades}
                                isDisabled={editaPremissa.length <= 1 ? false : true}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={setSelected}
                                value={selected}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editaPremissa.length <= 1 ? (
                        <Button
                            variant="primary"
                            onClick={() =>
                                createPremissa(
                                    document.getElementById("formPremissa").value,
                                    selectedFilter?.anoBudget,
                                    cookies.usuario.id
                                )
                            }
                        >
                            Criar
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() =>
                                atualizaPremissa(
                                    editaPremissa[1].id,
                                    document.getElementById("formPremissa").value,
                                    selectedFilter?.anoBudget,
                                    cookies.usuario.id
                                )
                            }
                        >
                            Atualizar
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal
                show={editaPremissaAliquota[0]}
                onHide={() => setEditaPremissasAliquota([false])}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editaPremissaAliquota.length <= 1
                            ? "Nova aliquota budget " + selectedFilter?.anoBudget
                            : "Atualização de aliquota"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Combos(s):</Form.Label>
                            <Select
                                isMulti
                                name="colors"
                                options={combos}
                                isDisabled={editaPremissaAliquota.length <= 1 ? false : true}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                // eslint-disable-next-line
                                onChange={(e) => {
                                    e = e.filter(
                                        (d) => d.organizacao_id === e[e.length - 1].organizacao_id
                                    );
                                    setContasDre({
                                        ...contasDre,
                                        filtered: contasDre.all.filter(
                                            (d) => d.organizacao_id == e[e.length - 1].organizacao_id
                                        ),
                                    });
                                    setSelectedAliquota(e);
                                }}
                                value={selectedAliquota}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Conta:</Form.Label>
                            <Select
                                name="colors"
                                options={contasDre?.filtered.filter(
                                    (d) => d.isAnalitica === true
                                )}
                                isDisabled={
                                    editaPremissaAliquota.length <= 1
                                        ? selectedAliquota.length <= 0
                                            ? true
                                            : false
                                        : true
                                }
                                closeMenuOnSelect={true}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={setSelectedContaDreAliquota}
                                value={selectedContaDreAliquota}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Base de Cálculo:</Form.Label>
                            <Select
                                isMulti
                                options={contasDre?.filtered}
                                isDisabled={selectedAliquota.length <= 0 ? true : false}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={setSelectedBaseDeCalculoAliquota}
                                value={selectedBaseDeCalculoAliquota}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Aliquota:</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    placeholder="Indice"
                                    onChange={(e) =>
                                        setEditaPremissasAtualAliquota(e.target.value)
                                    }
                                    defaultValue={
                                        editaPremissaAtualAliquota
                                            ? editaPremissaAtualAliquota
                                            : null
                                    }
                                />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editaPremissaAliquota.length <= 1 ? (
                        <Button
                            variant="primary"
                            onClick={() =>
                                createPremissaAliquota(
                                    selectedFilter?.anoBudget,
                                    cookies.usuario.id
                                )
                            }
                        >
                            Criar
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() =>
                                atualizaPremissaAliquota(
                                    editaPremissaAliquota[1].id,
                                    selectedFilter?.anoBudget,
                                    cookies.usuario.id
                                )
                            }
                        >
                            Atualizar
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Fab
                icon={<AiOutlinePlus />}
                alwaysShowTitle={true}
                mainButtonStyles={{ backgroundColor: "#17a2b8" }}
                style={{ bottom: 0, right: 0, zoom: "80%", margin: "20px", zIndex: 100 }}
            >
                {/* Bottom */}

                <Action
                    text={<span style={{ fontSize: "large" }}>Filtro</span>}
                    style={{ backgroundColor: "blue" }}
                    onClick={() => {
                        setSelectedFilter({ ...selectedFilter, modalVisivel: true });
                    }}
                >
                    <AiOutlineFilter />
                </Action>

                <Action
                    text={
                        <span style={{ fontSize: "large" }}>Adicionar Premissa Geral</span>
                    }
                    style={{ backgroundColor: "blue" }}
                    onClick={() => {
                        setEditaPremissasAtual("");
                        setSelected([]);
                        setFab(!fab);
                        setEditaPremissas([true]);
                    }}
                >
                    <AiOutlineFileText />
                </Action>

                <Action
                    text={<span style={{ fontSize: "large" }}>Adicionar Aliquotas</span>}
                    style={{ backgroundColor: "blue" }}
                    onClick={() => {
                        setEditaPremissasAtualAliquota("");
                        setSelectedAliquota([]);
                        setSelectedContaDreAliquota([]);
                        setSelectedBaseDeCalculoAliquota([]);
                        setFab(!fab);
                        setEditaPremissasAliquota([true]);
                    }}
                >
                    <AiOutlinePercentage />
                </Action>

                {/* Top */}
            </Fab>
            <Modal show={selectedFilter.modalVisivel} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Filtro premissas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <label> Escolha os anos das premissas: </label>
                            <Select
                                isMulti
                                name="colors"
                                options={selectedFilter?.optAnosBudget}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                    setSelectedFilter({ ...selectedFilter, selectedOptAnos: e });
                                }}
                                value={selectedFilter?.selectedOptAnos}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> Escolha as Unidades/Combos: </label>
                            <Select
                                isMulti
                                name="colors"
                                options={[
                                    { label: "Unidades", options: unidades },
                                    {
                                        label: "Combos",
                                        options: combos.reduce(
                                            (acc, cv) =>
                                                (acc = [...acc, { ...cv, label: "Combo " + cv.label }]),
                                            []
                                        ),
                                    },
                                ]}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                    setSelectedFilter({
                                        ...selectedFilter,
                                        selectedOptUnidades: e,
                                    });
                                }}
                                value={selectedFilter?.selectedOptUnidades}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> Escolha os tipos de premissas: </label>
                            <Select
                                isMulti
                                name="colors"
                                options={[
                                    ...new Set(
                                        premissas?.premissasMemory?.reduce(
                                            (acc, cv) => (acc = [...acc, cv.type]),
                                            []
                                        )
                                    ),
                                ].reduce(
                                    (acc, cv) => (acc = [...acc, { label: cv, value: cv }]),
                                    []
                                )}
                                closeMenuOnSelect={false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                    setSelectedFilter({ ...selectedFilter, selectedOptTipo: e });
                                }}
                                value={selectedFilter?.selectedOptTipo}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        size="sm"
                        onClick={() => {
                            updateFilterPremissas();
                            setSelectedFilter({ ...selectedFilter, modalVisivel: false });
                        }}
                    >
                        Filtrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                style={{ zIndex: 1000000 }}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
        </Container>
    );
}

export default DefinicaoPremissas;
