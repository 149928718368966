/* eslint-disable */
import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { useCookies } from 'react-cookie';
import { Badge, Button, Col, Form, FormControl, InputGroup, Modal, OverlayTrigger, Row, Table, Toast, Tooltip } from 'react-bootstrap';
import { BiCommentDetail, BiDotsVerticalRounded, BiSearchAlt, BiWorld } from 'react-icons/bi';
import { RiAlertFill, RiFileExcel2Line, RiUserStarLine } from 'react-icons/ri';
import { AiOutlineMinusCircle, AiOutlinePlusCircle, AiOutlineUserAdd } from 'react-icons/ai';
import Select from 'react-select';
import { Step, Stepper } from 'react-form-stepper';
import DatePicker from "react-datepicker";
import TransferList from '../../../components/TransferList';
import ModalTransferList from '../../../components/modals/models/modalTransferList.tsx';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox } from '@material-ui/core';

// import { Container } from './styles';

function ListaAcionistas() {

    const [cookies] = useCookies([]);
    const [listaAcionistas, setListaAcionistas] = useState({ step: 0 })
    var meses = ['Janeiro', 'Fevereiro', 'Marco', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    useEffect(() => {
        loadAcionsitas()
    }, [])

    async function loadAcionsitas() {
        var returnLista = await api.get('/patrimoniais/load/acionistas?usuario_id=' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        const codigosBancos = await api.get('/estruturas/codigosbancos', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setListaAcionistas({ step: 0, ...returnLista.data, bancosLista: codigosBancos.data })
    }

    function padronizaString(str) {
        return ('' + str).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])|\s/g, '').toLowerCase()
    }

    function filterAcionista(textToFind) {
        if (textToFind === '') return setListaAcionistas({ ...listaAcionistas, textToFind: null, acionistas: listaAcionistas.acionistasMemory })

        var strFix = (padronizaString(textToFind))

        var arrOutrasUndiades = listaAcionistas?.duplicados?.filter((cv) => padronizaString(cv.patrimonial).includes(strFix))?.reduce((acc, cv) => acc = [...acc, cv['cpf/cnpj']], [])

        document.getElementsByClassName('openLine')

        setListaAcionistas({
            ...listaAcionistas,
            textToFind: strFix,
            acionistas: listaAcionistas.acionistasMemory.filter(d =>
                padronizaString(d.nome).includes(strFix) ||
                padronizaString(d['cpf/cnpj']).includes(strFix) ||
                padronizaString(d.banco).includes(strFix) ||
                padronizaString(d.agencia).includes(strFix) ||
                padronizaString(d.conta).includes(strFix) ||
                padronizaString(d.destinatario).includes(strFix) ||
                padronizaString(d.copia).includes(strFix) ||
                padronizaString(d.codigobanco_id).includes(strFix) ||
                (d.isPix ? padronizaString('PIX').includes(strFix) : null) ||
                (d.isPJ ? padronizaString('CNPJ').includes(strFix) : null) ||
                (!d.isPJ ? padronizaString('CPF').includes(strFix) : null) ||
                padronizaString(d.patrimonial).includes(strFix) ||
                arrOutrasUndiades.includes(d['cpf/cnpj'])

            )
        })
    }

    function hideShow(id) {
        document.getElementById('moreInfo-plus-' + id).hidden = !document.getElementById('moreInfo-plus-' + id).hidden
        document.getElementById('moreInfo-minus-' + id).hidden = !document.getElementById('moreInfo-minus-' + id).hidden
        document.getElementById('moreInfo-' + id).hidden = !document.getElementById('moreInfo-' + id).hidden

        document.getElementById('moreInfo-' + id).classList.toggle("openLine")
        document.getElementById('moreInfo-plus-' + id).classList.toggle("openLine")
        document.getElementById('moreInfo-minus-' + id).classList.toggle("openLine")

        document.getElementById('moreInfo-' + id).classList.toggle("closeLine")
        document.getElementById('moreInfo-plus-' + id).classList.toggle("closeLine")
        document.getElementById('moreInfo-minus-' + id).classList.toggle("closeLine")
    }

    function sort(col, e) {
        if (e.target.classList.contains('asc')) {
            e.target.classList.add('desc')
            e.target.classList.remove('asc')
            setListaAcionistas({ ...listaAcionistas, acionistas: listaAcionistas.acionistas.sort((a, b) => a[col] < b[col] ? 1 : a[col] > b[col] ? -1 : 0) })
        } else {
            e.target.classList.add('asc')
            e.target.classList.remove('desc')
            setListaAcionistas({ ...listaAcionistas, acionistas: listaAcionistas.acionistas.sort((a, b) => a[col] < b[col] ? -1 : a[col] > b[col] ? 1 : 0) })
        }
    }

    function checkItemError(item, id) {
        if ([...new Set(listaAcionistas?.duplicados?.filter(d => d['cpf/cnpj'] === id).reduce((acc, cv) => acc = [...acc, cv[item]], []))].length > 1) return (<sup><RiAlertFill color='gold' /></sup>)
    }

    function verificaDigitoDocumento(documento) {
        var documentoFormated = documento.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9])/g, '');
        documentoFormated = documentoFormated.split('')
        if (documentoFormated.length === 11) {
            // é cpf
            var valor = 0
            documentoFormated.slice(0, 9).map((digitoAtual, idx) => {
                valor += (parseInt(digitoAtual) * (documentoFormated.length - idx - 1))
            })

            var primeiroDigitoVerificador = valor % 11 < 2 ? 0 : 11 - valor % 11

            if (primeiroDigitoVerificador == documentoFormated[9]) {
                var valorSegundo = 0

                documentoFormated.slice(0, 10).map((digitoAtual, idx) => {
                    valorSegundo += (parseInt(digitoAtual) * (documentoFormated.length - idx))
                })

                var segundoDigitoVerificador = valorSegundo % 11 < 2 ? 0 : 11 - valorSegundo % 11

                if (segundoDigitoVerificador == documentoFormated[10]) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else if (documentoFormated.length === 14) {
            // é cnpj
            var pesoAtual = 2
            var valor = 0

            documentoFormated.slice(0, 12).reverse().map((digitoAtual) => {
                valor += (digitoAtual * pesoAtual)
                if (pesoAtual === 9) pesoAtual = 1
                pesoAtual++
            })

            var primeiroDigitoVerificador = valor % 11 < 2 ? 0 : 11 - valor % 11

            if (primeiroDigitoVerificador == documentoFormated[12]) {
                var pesoAtualSegundo = 2
                var valorSegundo = 0
                documentoFormated.slice(0, 13).reverse().map((digitoAtual) => {
                    valorSegundo += (digitoAtual * pesoAtualSegundo)
                    if (pesoAtualSegundo === 9) pesoAtualSegundo = 1
                    pesoAtualSegundo++
                })

                var segundoDigitoVerificador = valorSegundo % 11 < 2 ? 0 : 11 - valorSegundo % 11

                if (segundoDigitoVerificador == documentoFormated[13]) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    var maskCPFCNPJ = (v) => {
        v = v.replace(/\D/g, "")

        if (v.length <= 11) {
            v = v.replace(/(\d{3})(\d)/, "$1.$2")
            v = v.replace(/(\d{3})(\d)/, "$1.$2")
            v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        } else {
            v = v.replace(/^(\d{2})(\d)/, "$1.$2")
            v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
            v = v.replace(/(\d{4})(\d)/, "$1-$2")
        }

        return v
    }

    async function atualziarCadastro() {
        if (!!!listaAcionistas?.editarInvestidor?.nome) return notify("Nome invalido!")
        if (!!!listaAcionistas?.editarInvestidor?.['cpf/cnpj']) return notify("Cpf/Cnpj invalido!")
        if (!!listaAcionistas?.editarInvestidor?.isPix && !!!listaAcionistas?.editarInvestidor?.tipoPix) return notify("Tipo Pix invalido!")
        if (!!listaAcionistas?.editarInvestidor?.isPix && !!!listaAcionistas?.editarInvestidor?.pix) return notify("Pix invalido!")
        if (!!listaAcionistas?.editarInvestidor?.reside_exterior && !!!listaAcionistas?.editarInvestidor?.pais_residencia) return notify("País de residencia invalido!")
        if (!!listaAcionistas?.editarInvestidor?.reside_exterior && !!!listaAcionistas?.editarInvestidor?.aliquota) return notify("Aliquota invalida!")
        if (!!!listaAcionistas?.editarInvestidor?.banco) return notify("Banco invalido!")
        if (!!!listaAcionistas?.editarInvestidor?.agencia) return notify("Agencia invalido!")
        if (!!!listaAcionistas?.editarInvestidor?.conta) return notify("Conta invalido!")
        if (!!!listaAcionistas?.editarInvestidor?.destinatario) return notify("Destinatario invalido!")

        var patrimoniaisAntigo = [listaAcionistas.editarInvestidor.patrimonial]
        patrimoniaisAntigo = [...patrimoniaisAntigo, ...listaAcionistas?.duplicados?.filter(d => d['cpf/cnpj'] === listaAcionistas.editarInvestidor['cpf/cnpj']).reduce((acc, cv) => acc = [...acc, cv.patrimonial], [])]
        patrimoniaisAntigo = [...new Set(patrimoniaisAntigo)]
        var patrimoniaisAtual = [...new Set(listaAcionistas?.editarInvestidor?.patrimonial_list.reduce((acc, cv) => acc = [...acc, cv.patrimonial], []))]

        if (!!!listaAcionistas?.isNovoAcionista) {
            // deve entrar se não for um novo acionista, para desativar eventuais patrimoniais antes existentes
            patrimoniaisAntigo.filter(d => !patrimoniaisAtual.includes(d)).map(async d => {
                await api.post('/patrimoniais/desativar/multiacionistas', {
                    'cpf/cnpj': listaAcionistas?.editarInvestidor?.['cpf/cnpj'],
                    patrimonial_id: listaAcionistas?.patrimoniais?.find(p => p.patrimonial === d)?.id,
                    usuario_id: cookies.usuario.id,
                }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            })
        }

        if (!!!listaAcionistas?.editarInvestidor?.patrimonial_list?.length) {
            setListaAcionistas({ ...listaAcionistas, isNovoAcionista: false, modalEditaInvestidor: false, editarInvestidor: undefined, step: 0, linkCartaAtual: undefined })
            loadAcionsitas()

            return notify("Nenhuma patrimonial associada!")
        }

        await api.post('/patrimoniais/create/multiacionistas', {
            investidor: listaAcionistas.editarInvestidor,
            usuario_id: cookies.usuario.id
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        setListaAcionistas({ ...listaAcionistas, isNovoAcionista: false, modalEditaInvestidor: false, editarInvestidor: undefined, step: 0, linkCartaAtual: undefined })
        loadAcionsitas()
    }

    async function abrirEditarInvestidor(acionista) {
        // var propriedades = await api.get('/patrimoniais/load/acionistas/propriedades?cpfcnpj=' + acionista['cpf/cnpj'], { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        // if (propriedades.status !== 200) return notify('Erro ao carregar propriedades!')
        setListaAcionistas({
            ...listaAcionistas, modalEditaInvestidor: true, step: 0, editarInvestidor: {
                ...acionista
                // , propriedades: propriedades?.data 
            }
        })
    }

    const notify = ($msg, $time = 5000, $id = null) => toast($msg, {
        toastId: $id,
        position: "top-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    function checkAcionistaError(acionistasDuplicados) {
        if (acionistasDuplicados.length === 0) return

        var hasError = [...[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.nome], []))],
        ...[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.agencia], []))],
        ...[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.conta], []))],
        ...[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.banco], []))],
        ...[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.destinatario], []))],
        ...[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.copia], []))],
        ...[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.pix], []))]]

        if (hasError.length <= 7) return

        return (
            <OverlayTrigger placement={'right'} overlay={
                <Toast >
                    <Toast.Header>
                        <strong className="mr-auto">Alerta</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <ul>
                            {[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.nome], []))].length > 1
                                ? <li>Nomes diferentes para o mesmo CPF/CNPJ</li>
                                : null}
                            {[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.agencia], []))].length > 1
                                ? <li>Agencias diferentes para o mesmo CPF/CNPJ</li>
                                : null}
                            {[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.conta], []))].length > 1
                                ? <li>Contas diferentes para o mesmo CPF/CNPJ</li>
                                : null}
                            {[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.banco], []))].length > 1
                                ? <li>Bancos diferentes para o mesmo CPF/CNPJ</li>
                                : null}
                            {[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.destinatario], []))].length > 1
                                ? <li>Destinatários diferentes para o mesmo CPF/CNPJ</li>
                                : null}
                            {[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.copia], []))].length > 1
                                ? <li>Cópias diferentes para o mesmo CPF/CNPJ</li>
                                : null}
                            {[...new Set(acionistasDuplicados.reduce((acc, cv) => acc = [...acc, cv.pix], []))].length > 1
                                ? <li>Pix diferentes para o mesmo CPF/CNPJ</li>
                                : null}
                        </ul>
                    </Toast.Body>
                </Toast>
            }>
                <RiAlertFill color='gold' />
            </OverlayTrigger>
        )
    }

    function checkAcionistasComentarios(acionista) {
        var comentarios = [...new Set([...listaAcionistas?.duplicados?.filter(d => d['cpf/cnpj'] === acionista['cpf/cnpj']).reduce((acc, cv) => acc = [...acc, cv.comentario], []), acionista?.comentario])]
        comentarios = comentarios.filter(d => d !== '')
        if (comentarios.length === 0) return

        return (<OverlayTrigger placement={'right'} overlay={
            <Toast>
                <Toast.Header>
                    <strong className="mr-auto">Comentário{comentarios.length > 1 ? 's' : ''}</strong>
                </Toast.Header>
                <Toast.Body>
                    <ul>
                        {comentarios.map((c, key) => {
                            return (<li key={key}>{c}</li>)
                        })}
                    </ul>
                </Toast.Body>
            </Toast>
        }>
            <BiCommentDetail color='green' />
        </OverlayTrigger>)
    }


    function checkAcionistasExterior(acionista) {
        if (!!!acionista?.reside_exterior) return

        return (<OverlayTrigger placement={'right'} overlay={
            <Tooltip>
                Reside em {acionista?.pais_residencia_nome}
            </Tooltip>
        }>
            <BiWorld color='blue' />
        </OverlayTrigger>)
    }

    async function gerarArquivo() {

        if (!!!listaAcionistas?.export?.colunasToFilter?.length) return notify("Nenhuma coluna selecionada!")

        var unidadeId = 1
        if (!!listaAcionistas?.export?.filtraUnidade && !!listaAcionistas?.export?.patrimonialToFilter) unidadeId = listaAcionistas?.export?.patrimonialToFilter

        var colunas = listaAcionistas?.export?.colunasToFilter.reduce((acc, cv) => acc += ('"' + cv.replace(' ', '"."') + '",'), '').slice(0, -1)

        var arquivoExport = await api.get('/patrimoniais/acionistas/email/' + unidadeId +
            '?filtraPorUnidade=' + !!listaAcionistas?.export?.filtraUnidade +
            '&orderBy=patrimonial,nome&columns=' + colunas)

        if (arquivoExport.status !== 200 || arquivoExport.data.status !== 200) return notify("Erro ao gerar arquivo, entre em contato com o suporte!")

        return window.open(arquivoExport.data.url, '_blank')
    }

    function handleSelectAll() {
        if (!!!listaAcionistas?.checkedAll) {
            var checked = {}
            listaAcionistas.acionistas.map(d => checked[d['cpf/cnpj']] = true)
            setListaAcionistas({ ...listaAcionistas, checkedAll: true, checked: checked })
        } else {
            setListaAcionistas({ ...listaAcionistas, checkedAll: false, checked: {} })
        }
    }

    function handleCheckBox(acionista) {
        var checked = { ...listaAcionistas?.checked }
        if (!!!checked[acionista?.['cpf/cnpj']]) {
            checked[acionista?.['cpf/cnpj']] = true
        } else {
            delete checked[acionista?.['cpf/cnpj']]
        }

        setListaAcionistas({ ...listaAcionistas, checked: checked })
    }
    console.log(listaAcionistas)

    async function handleGerarInforme() {
        var checked = listaAcionistas?.checked
        if (!!!checked || !Object?.keys(checked)?.length) return notify('Nenhum acionista selecionado!')

        var patrimonais_id = []

        listaAcionistas?.selecionadaMulti?.map((cv) => {
            const parts = cv.split(' } - ');
            const number = parts[0].replace('{ ', '').replace(' }', '');
            patrimonais_id.push(number)
        })

        var res = await api.post('/patrimoniais/acionistas/informe', {
            patrimoniais: patrimonais_id,
            ano: listaAcionistas?.anoSelected,
            acionistas: Object.keys(checked)
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        if (res.status !== 200) return notify('Erro ao gerar informe!')
        return notify('Informe gerado com sucesso!', 10000, 'sucesso')
    }

    const generateOptions = () => {
        if (listaAcionistas?.checked) {
            var patrimoniais_ids = [...listaAcionistas?.acionistas?.filter(acionista => Object.keys(listaAcionistas?.checked).includes(acionista['cpf/cnpj'])),
            ...listaAcionistas?.duplicados?.filter(acionista => Object.keys(listaAcionistas?.checked).includes(acionista['cpf/cnpj']))].reduce((acc, cv) => acc = [...acc, cv.patrimonial_id + ''], [])
            return listaAcionistas?.patrimoniais?.filter(patrimonial => [...new Set(patrimoniais_ids)].includes(patrimonial.id)).reduce((acc, cv) => acc = [...acc, '{ ' + cv.id + ' } - ' + cv.patrimonial], [])
        }
    }

    async function openInformeDeRendimentos() {
        if (!!!listaAcionistas?.informeDeRendimentos?.data) return notify('Informe a data!')
        if (!!!listaAcionistas?.informeDeRendimentos?.patrimonial) return notify('Informe a patrimonial!')

        var baseUrl = window.location.origin.split(':3000')[0] + ':3333'
        var urlIR = baseUrl + '/acionistas/informes/' + listaAcionistas?.informeDeRendimentos?.data.getFullYear() + '/' + listaAcionistas?.informeDeRendimentos?.patrimonial + '/' + listaAcionistas?.editarInvestidor['cpf/cnpj'].replaceAll('/', '_').replaceAll('.', '_').replaceAll('-', '_') + '_' + listaAcionistas?.editarInvestidor?.nome?.replaceAll(' ', '_')?.replaceAll('/', '') + '.pdf'

        fetch(urlIR, { method: 'HEAD' })
            .then(response => {
                if (response.ok) {
                    // File exists, open the window
                    window.open(urlIR, 'blank');
                } else {
                    // File does not exist, show an error message
                    console.error('File does not exist');
                    notify('Informe de rendimentos não encontrado!')
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            <Table size="sm" bordered hover striped responsive align="center" style={{ width: '98%', background: '#fff', fontSize: 'small', marginBottom: '50px' }}>
                <thead>
                    <tr>
                        <th style={{ fontSize: 'x-large', padding: '15px 20px', border: 'none' }} colSpan={10}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                Lista de Investidores
                                <InputGroup style={{ maxWidth: '300px' }}>
                                    <Form.Control placeholder="Buscar Acionista" onChange={(e) => { filterAcionista(e.target.value) }} />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2"><BiSearchAlt /></InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td colSpan={10} style={{ border: 'none', textAlign: 'right' }}>
                            <Button style={{ marginLeft: '5px', marginRight: '5px' }} variant='light' size='sm' onClick={() => setListaAcionistas({ ...listaAcionistas, selectIR: !listaAcionistas?.selectIR })}>Gerar Informe de Rendimentos</Button>
                            <Button style={{ marginLeft: '5px', marginRight: '5px' }} variant='light' size='sm' onClick={() => setListaAcionistas({ ...listaAcionistas, modalExportExcel: true })}>Export Excel</Button>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ background: '#04998b', color: 'white' }}>
                        {listaAcionistas?.selectIR && <th onClick={(e) => e.stopPropagation()} ><Checkbox checked={listaAcionistas?.checkedAll} onChange={() => handleSelectAll()} color="success" size="small" /></th>}
                        <th width='2%'></th>
                        <th width='4%'>Info</th>
                        <th width='30%' onClick={(e) => sort('nome', e)}>Nome</th>
                        <th width='20%' onClick={(e) => sort('cpf/cnpj', e)}>CPF/CNPJ</th>
                        <th width='40%' onClick={(e) => sort('patrimonial', e)}>Patrimonial</th>
                        <th width='4%'></th>
                    </tr>
                    {listaAcionistas?.acionistas?.map((acionista, idx) => {
                        var badges = [acionista.patrimonial]
                        badges = [...badges, ...listaAcionistas?.duplicados?.filter(d => d['cpf/cnpj'] === acionista['cpf/cnpj']).reduce((acc, cv) => acc = [...acc, cv.patrimonial], [])]

                        var colors = ['#f39c12', '#d35400', '#c0392b', '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#f1c40f', '#e67e22', '#e74c3c', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50']
                        return (
                            <>
                                <tr key={'info-' + acionista?.['cpf/cnpj']} onClick={() => hideShow(acionista['cpf/cnpj'])} style={idx % 2 ? { background: 'lightgray' } : { background: 'white' }}>
                                    {listaAcionistas?.selectIR && <td onClick={(e) => e.stopPropagation()}><Checkbox checked={!!listaAcionistas?.checked?.[acionista?.['cpf/cnpj']]} className={'checkBoxFinder'} onChange={() => handleCheckBox(acionista)} color="success" size="small" /></td>}
                                    <td id={'moreInfo-plus-' + acionista['cpf/cnpj']} className={'openLine'} style={{ cursor: 'pointer', textAlign: 'center', textAnchor: 'middle', verticalAlign: 'middle' }}><AiOutlinePlusCircle /></td>
                                    <td id={'moreInfo-minus-' + acionista['cpf/cnpj']} className={'closeLine'} hidden style={{ cursor: 'pointer', textAlign: 'center', textAnchor: 'middle', verticalAlign: 'middle' }}><AiOutlineMinusCircle /></td>
                                    <td style={{ textAlign: 'center', textAnchor: 'middle', verticalAlign: 'middle' }}>
                                        <div style={{ display: 'flex', placeContent: 'space-evenly' }}>
                                            {checkAcionistaError(listaAcionistas?.duplicados?.filter(d => d['cpf/cnpj'] === acionista['cpf/cnpj']))}
                                            {checkAcionistasComentarios(acionista)}
                                            {checkAcionistasExterior(acionista)}
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>{acionista?.nome}</td>
                                    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{acionista?.['cpf/cnpj']}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{[...new Set(badges)].map((b, idxC) => <Badge style={{ fontSize: 'small', color: '#fff', background: colors[parseInt(listaAcionistas?.patrimoniais?.find(patrimonialAtual => patrimonialAtual?.patrimonial === b).id) % 7] }}>{b}</Badge>)}</td>
                                    <td style={{ textAlign: 'center', textAnchor: 'middle', verticalAlign: 'middle' }}><span style={{ cursor: 'pointer', padding: '5px' }} onClick={(e) => { e.stopPropagation(); abrirEditarInvestidor(acionista) }}><BiDotsVerticalRounded /></span></td>
                                </tr>
                                <tr id={'moreInfo-' + acionista['cpf/cnpj']} hidden className={'closeLine'} >
                                    <td style={{ padding: 0 }} colSpan={listaAcionistas?.selectIR ? 7 : 6}>
                                        <Table size="sm" bordered hover responsive align="center" style={{ background: '#fff', fontSize: 'small', margin: 0 }}  >
                                            <thead>
                                                <tr style={{ background: '#04998b', color: 'white' }}>
                                                    <th width='16%' onClick={(e) => sort('banco', e)}>Banco</th>
                                                    <th width='12%' onClick={(e) => sort('agencia', e)}>Agencia</th>
                                                    <th width='12%' onClick={(e) => sort('conta', e)}>Conta</th>
                                                    <th width='30%' onClick={(e) => sort('destinatario', e)}>Email</th>
                                                    <th width='30%' onClick={(e) => sort('copia', e)}>Cópia</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle' }}>{acionista?.banco}</td>
                                                    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{acionista?.agencia}</td>
                                                    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{acionista?.conta}</td>
                                                    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{acionista?.destinatario}</td>
                                                    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{acionista?.copia}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>
            <Modal
                show={listaAcionistas.modalEditaInvestidor}
                onHide={() => setListaAcionistas({ ...listaAcionistas, modalEditaInvestidor: false, isNovoAcionista: false, editarInvestidor: undefined })}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop='static'
                size='lg'
            >
                <Modal.Header closeButton style={{ gap: '15px' }}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="success" style={{ marginRight: '8px' }} disabled><RiUserStarLine /></Button> {listaAcionistas.isNovoAcionista === true ? 'Novo' : 'Editar'} investidor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stepper style={{ padding: 0 }} activeStep={listaAcionistas.step} nonLinear>
                        <Step styleConfig={{ activeBgColor: '#04998b' }} onClick={() => setListaAcionistas({ ...listaAcionistas, step: 0 })} label='Informações Cadastrais' />
                        <Step styleConfig={{ activeBgColor: '#04998b' }} onClick={() => setListaAcionistas({ ...listaAcionistas, step: 1 })} label='Associação de Unidades' />
                        {/* <Step styleConfig={{ activeBgColor: '#04998b' }} onClick={() => setListaAcionistas({ ...listaAcionistas, step: 2 })} label='Numero de Ações' /> */}
                        {listaAcionistas.isNovoAcionista === true
                            ? null
                            : <Step styleConfig={{ activeBgColor: '#04998b' }} onClick={() => setListaAcionistas({ ...listaAcionistas, step: 2 })} label='Arquivos' />
                        }
                    </Stepper>
                    <div hidden={listaAcionistas?.step !== 0}>
                        <Row>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Nome Investidor{checkItemError("nome", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <FormControl
                                    placeholder="Nome"
                                    aria-label="Nome"
                                    defaultValue={listaAcionistas?.editarInvestidor?.nome}
                                    onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, nome: e.target.value } }) }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <Form.Label>É PJ?</Form.Label>
                                <Form.Control
                                    placeholder="isPJ"
                                    aria-label="isPJ"
                                    name='isPJ'
                                    disabled={listaAcionistas.isNovoAcionista === true ? false : true}
                                    type='checkbox'
                                    defaultChecked={listaAcionistas?.editarInvestidor?.isPJ}
                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, isPJ: e.target.checked } }) }}
                                />
                            </Col>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                {listaAcionistas?.editarInvestidor?.isPJ
                                    ? <>
                                        <Form.Label>CNPJ</Form.Label>
                                        <Form.Control
                                            placeholder="CNPJ"
                                            aria-label="CNPJ"
                                            name='cpf/cnpj'
                                            pattern='[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}'
                                            maxLength={18}
                                            required
                                            disabled={listaAcionistas.isNovoAcionista === true ? false : true}
                                            defaultValue={listaAcionistas?.editarInvestidor?.['cpf/cnpj']}
                                            onChange={(e) => { e.target.classList.remove('is-invalid'); e.target.value = maskCPFCNPJ(e.target.value) }}
                                            onBlur={(e) => {
                                                if (verificaDigitoDocumento(e.target.value)) {
                                                    setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, 'cpf/cnpj': e.target.value } })
                                                } else {
                                                    e.target.classList.add('is-invalid')
                                                    e.target.value = null
                                                    notify("Documento inválido!")
                                                }
                                            }}
                                        />
                                    </>
                                    : <>
                                        <Form.Label>CPF</Form.Label>
                                        <Form.Control
                                            placeholder="CPF"
                                            aria-label="CPF"
                                            name='cpf/cnpj'
                                            pattern='[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}'
                                            maxLength={14}
                                            disabled={listaAcionistas.isNovoAcionista === true ? false : true}
                                            required
                                            defaultValue={listaAcionistas?.editarInvestidor?.['cpf/cnpj']}
                                            onChange={(e) => { e.target.classList.remove('is-invalid'); e.target.value = maskCPFCNPJ(e.target.value) }}
                                            onBlur={(e) => {
                                                if (verificaDigitoDocumento(e.target.value)) {
                                                    setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, 'cpf/cnpj': e.target.value } })
                                                } else {
                                                    e.target.classList.add('is-invalid')
                                                    e.target.value = null
                                                    notify("Documento inválido!")
                                                }
                                            }}
                                        />
                                    </>}
                            </Col>
                            <Col md={3} style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <Form.Label>Reside no Exterior?</Form.Label>
                                <Form.Control
                                    placeholder="Reside no Exterior"
                                    aria-label="Reside no Exterior"
                                    name='Reside no Exterior'
                                    type='checkbox'
                                    defaultChecked={listaAcionistas?.editarInvestidor?.reside_exterior}
                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, reside_exterior: e.target.checked } }) }}
                                />
                            </Col>
                        </Row>
                        <Row hidden={!!!listaAcionistas?.editarInvestidor?.reside_exterior}>
                            <Col>
                                <Form.Label>País residencia</Form.Label>
                                <Select
                                    name="colors"
                                    options={listaAcionistas?.codigosPaises}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    defaultValue={listaAcionistas?.codigosPaises?.filter(d => d.value === listaAcionistas?.editarInvestidor?.pais_residencia)}
                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, pais_residencia: e.value, aliquota: e.aliquota } }) }}
                                />
                            </Col>
                            <Col>
                                <label>Aliquota IR</label>
                                <InputGroup>
                                    <FormControl
                                        disabled={!!!listaAcionistas?.editarInvestidor?.pais_residencia}
                                        placeholder="aliquota"
                                        type='number'
                                        aria-label="aliquota"
                                        value={listaAcionistas?.editarInvestidor?.aliquota}
                                        onChange={e => setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, aliquota: e.target.value } })}
                                        onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, aliquota: e.target.value } }) }}
                                    />
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col hidden={listaAcionistas?.editarInvestidor?.codigobanco_id !== null} md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Banco{checkItemError("banco", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <FormControl
                                    placeholder="Banco"
                                    aria-label="Banco"
                                    disabled
                                />
                            </Col>
                            <Col md={2} style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <Form.Label>É Pix?</Form.Label>
                                <Form.Control
                                    placeholder="isPix"
                                    aria-label="isPix"
                                    name='isPix'
                                    type='checkbox'
                                    defaultChecked={listaAcionistas?.editarInvestidor?.isPix}
                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, isPix: e.target.checked } }) }}
                                />
                            </Col>
                            <Col hidden={!listaAcionistas?.editarInvestidor?.isPix} md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <Form.Label>Tipo Pix</Form.Label>
                                <Select
                                    name="colors"
                                    options={[{ label: "Celular", value: "01" }, { label: "Email", value: "02" }, { label: "CPF/CNPJ", value: "03" }, { label: "Aleatória", value: "04" }]}
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    defaultValue={[{ label: "Celular", value: "01" }, { label: "Email", value: "02" }, { label: "CPF/CNPJ", value: "03" }, { label: "Aleatória", value: "04" }].filter(d => d.value === listaAcionistas?.editarInvestidor?.tipoPix)}
                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, tipoPix: e.value } }) }}
                                />
                            </Col>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label style={listaAcionistas?.editarInvestidor?.codigobanco_id === null ? { color: 'red' } : {}}>Banco{checkItemError("banco", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <Select
                                    name="colors"
                                    closeMenuOnSelect={true}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    options={listaAcionistas?.bancosLista?.reduce((acc, cv) => acc = [...acc, { label: cv.codigo + ' - ' + cv.banco, value: cv.codigo }], [])}
                                    defaultValue={listaAcionistas?.bancosLista?.reduce((acc, cv) => acc = [...acc, { label: cv.codigo + ' - ' + cv.banco, value: cv.codigo }], []).filter(d => d.value === listaAcionistas?.editarInvestidor?.codigobanco_id)}
                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, codigobanco_id: e.value, banco: e.label?.split(' - ')[1] } }) }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col hidden={!listaAcionistas?.editarInvestidor?.isPix} md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Chave Pix{checkItemError("pix", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <FormControl
                                    placeholder="Chave Pix"
                                    aria-label="Chave Pix"
                                    defaultValue={listaAcionistas?.editarInvestidor?.pix}
                                    onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, pix: e.target.value } }) }}
                                />
                            </Col>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Agência{checkItemError("agencia", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <FormControl
                                    placeholder="Agência"
                                    aria-label="Agência"
                                    defaultValue={listaAcionistas?.editarInvestidor?.agencia}
                                    onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, agencia: e.target.value } }) }}
                                />
                            </Col>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Conta Corrente{checkItemError("conta", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <FormControl
                                    placeholder="Conta corrente"
                                    aria-label="Conta corrente"
                                    defaultValue={listaAcionistas?.editarInvestidor?.conta}
                                    onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, conta: e.target.value } }) }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Destinatário{checkItemError("destinatario", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <FormControl
                                    placeholder="Email destinatário"
                                    aria-label="Email destinatário"
                                    type="email"
                                    defaultValue={listaAcionistas?.editarInvestidor?.destinatario}
                                    onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, destinatario: e.target.value } }) }}
                                />
                            </Col>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Com Cópia{checkItemError("copia", listaAcionistas?.editarInvestidor?.["cpf/cnpj"])}</label>
                                <FormControl
                                    placeholder="Email cópia"
                                    aria-label="Email cópia"
                                    type="email"
                                    defaultValue={listaAcionistas?.editarInvestidor?.copia}
                                    onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, copia: e.target.value } }) }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md style={{ marginBottom: '5px', marginTop: '5px' }} >
                                <label>Observações</label>
                                <FormControl
                                    placeholder="Comentário"
                                    aria-label="Comentário"
                                    type="textArea"
                                    defaultValue={listaAcionistas?.editarInvestidor?.comentario}
                                    onBlur={(e) => { setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas?.editarInvestidor, comentario: e.target.value } }) }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div hidden={listaAcionistas?.step !== 1}>
                        <TransferList
                            onChange={(opt) => {
                                var arrPatrimoniais = listaAcionistas.patrimoniais.filter(f => opt?.includes(f?.patrimonial))
                                var arrAcionista = [...listaAcionistas?.duplicados?.filter(acionistas => acionistas['cpf/cnpj'] === listaAcionistas?.editarInvestidor?.['cpf/cnpj'])]
                                if (arrAcionista?.length === 0) arrAcionista = [...listaAcionistas?.acionistas.filter(acionistas => acionistas['cpf/cnpj'] === listaAcionistas?.editarInvestidor?.['cpf/cnpj'])]

                                arrPatrimoniais = arrPatrimoniais.reduce((acc, cv) => acc = [
                                    ...acc, {
                                        ...cv, acoesAcionista: arrAcionista.find(patrimonial => cv.id + '' === patrimonial.patrimonial_id + '')
                                            ? arrAcionista.find(patrimonial => cv.id + '' === patrimonial.patrimonial_id + '').acoes
                                            : 0
                                    }
                                ], [])
                                setListaAcionistas({ ...listaAcionistas, editarInvestidor: { ...listaAcionistas.editarInvestidor, patrimonial_list: [...arrPatrimoniais] } });
                            }}
                            options={listaAcionistas?.patrimoniais?.reduce((acc, cv) => acc = [...acc, cv.patrimonial], [])}
                            selected={() => {
                                var badges = [listaAcionistas?.editarInvestidor?.patrimonial]
                                badges = [...badges, ...listaAcionistas?.duplicados?.filter(d => d?.['cpf/cnpj'] === listaAcionistas?.editarInvestidor?.['cpf/cnpj']).reduce((acc, cv) => acc = [...acc, cv.patrimonial], [])]
                                return [...new Set(badges)]
                            }} />
                    </div>
                    {/* <div hidden={listaAcionistas?.step !== 2}>
                        {acoesApartamentos()}
                    </div> */}
                    <div hidden={listaAcionistas?.step !== 2}>
                        <Row style={{ marginTop: '5px' }}>
                            <Col>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <h3>Cartas</h3>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <Row style={{ marginTop: '5px' }}>
                                            <Col>Mês:</Col>
                                            <Col>
                                                <DatePicker
                                                    onChange={(date) => { setListaAcionistas({ ...listaAcionistas, linkCartaAtual: { ...listaAcionistas?.linkCartaAtual, date } }) }}
                                                    selected={!!!listaAcionistas?.linkCartaAtual?.date ? null : listaAcionistas?.linkCartaAtual?.date}
                                                    dateFormat="MMM/yyyy"
                                                    placeholderText='Escolha o Mes/Ano'
                                                    locale="pt-BR"
                                                    showMonthYearPicker
                                                    showFullMonthYearPicker
                                                    showTwoColumnMonthYearPicker
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '5px' }}>
                                            <Col>Patrimonial:</Col>
                                            <Col>
                                                <Select
                                                    name="colors"
                                                    options={listaAcionistas?.editarInvestidor?.patrimonial_list?.reduce((acc, cv) => acc = [...acc, { label: cv.patrimonial, value: cv.id }], [])}
                                                    closeMenuOnSelect={true}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, linkCartaAtual: { ...listaAcionistas?.linkCartaAtual, patrimonial_id: e.value, patrimonial: e.label.replaceAll(' ', '_').replaceAll('/', '_') } }) }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button style={{ marginLeft: '5px' }} disabled={!!!listaAcionistas?.linkCartaAtual?.date || !!!listaAcionistas?.linkCartaAtual?.patrimonial} size='sm' variant="outline-primary" onClick={() => {
                                            window.open('http://analytics.buphotels.com.br:3333/cartas/acionistas/' + listaAcionistas?.linkCartaAtual?.date.getFullYear() + '/' + meses[listaAcionistas?.linkCartaAtual?.date.getMonth()] + '/' + listaAcionistas?.linkCartaAtual?.patrimonial + '/' + listaAcionistas?.editarInvestidor['cpf/cnpj'].replaceAll('/', '_').replaceAll('.', '_').replaceAll('-', '_') + listaAcionistas?.editarInvestidor?.nome?.replaceAll(' ', '_')?.replaceAll('/', '') + '.pdf', 'blank')
                                        }}>
                                            Abrir carta
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr />
                        <Row style={{ marginTop: '5px' }}>
                            <Col>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <h3>Informe de rendimentos</h3>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <Row style={{ marginTop: '5px' }}>
                                            <Col>Ano:</Col>
                                            <Col>
                                                <DatePicker
                                                    selected={listaAcionistas?.informeDeRendimentos?.data}
                                                    onChange={(date) => { setListaAcionistas({ ...listaAcionistas, informeDeRendimentos: { ...listaAcionistas?.informeDeRendimentos, data: date } }) }}
                                                    showYearPicker
                                                    maxDate={new Date(listaAcionistas?.anosInformeRendimentos?.[0]?.max + '-01-02')}
                                                    minDate={new Date(listaAcionistas?.anosInformeRendimentos?.[0]?.min + '-01-02')}
                                                    placeholderText='Escolha Ano'
                                                    dateFormat="yyyy"
                                                    yearItemNumber={4}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '5px' }}>
                                            <Col>Patrimonial:</Col>
                                            <Col>
                                                <Select
                                                    name="colors"
                                                    options={listaAcionistas?.editarInvestidor?.patrimonial_list?.reduce((acc, cv) => acc = [...acc, { label: cv.patrimonial, value: cv.id }], [])}
                                                    closeMenuOnSelect={true}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(e) => { setListaAcionistas({ ...listaAcionistas, informeDeRendimentos: { ...listaAcionistas?.informeDeRendimentos, patrimonial_id: e.value, patrimonial: e.label.replaceAll(' ', '_').replaceAll('/', '') } }) }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button style={{ marginLeft: '5px' }} disabled={!!!listaAcionistas?.informeDeRendimentos?.data || !!!listaAcionistas?.informeDeRendimentos?.patrimonial} size='sm' variant="outline-primary" onClick={() => {
                                            openInformeDeRendimentos()
                                        }}>
                                            Abrir Informe de rendimentos
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }}>
                    <Button hidden={listaAcionistas?.step === 0} variant='outline-primary' onClick={() => setListaAcionistas({ ...listaAcionistas, step: listaAcionistas.step - 1 <= 0 ? 0 : listaAcionistas.step - 1 })} style={{ marginLeft: '5px' }} size='sm' >
                        Voltar
                    </Button>
                    <div></div>
                    <Button hidden={listaAcionistas.isNovoAcionista === true ? listaAcionistas?.step === 1 : listaAcionistas?.step === 2} onClick={() => {
                        var maxPage = listaAcionistas.isNovoAcionista === true ? 1 : 2
                        setListaAcionistas({ ...listaAcionistas, step: listaAcionistas.step + 1 >= maxPage ? maxPage : listaAcionistas.step + 1 })
                    }} style={{ marginLeft: '5px' }} size='sm' >
                        Proximo
                    </Button>
                    <Button hidden={listaAcionistas.isNovoAcionista === true ? listaAcionistas?.step !== 1 : listaAcionistas?.step !== 2} style={{ marginLeft: '5px' }} size='sm' onClick={() => { if (window.confirm(listaAcionistas.isNovoAcionista === true ? "Deseja realmente criar este acionista em todas as patrimoniais associadas a ele? Não se esqueça de adicionar as ações depois da criação" : "Deseja realmente atualizar este acionista em todas as patrimoniais associadas a ele?")) { atualziarCadastro() } }}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
            <OverlayTrigger
                key={'left'}
                placement={'left'}
                overlay={
                    <Tooltip>
                        Adicionar Acionistas
                    </Tooltip>
                }
            >
                <Button style={{
                    borderRadius: '100%',
                    padding: '10px 14px',
                    position: 'fixed',
                    right: '20px',
                    bottom: '21px',
                }} onClick={() => setListaAcionistas({ ...listaAcionistas, modalEditaInvestidor: true, isNovoAcionista: true })}><AiOutlineUserAdd /></Button>
            </OverlayTrigger>

            {listaAcionistas?.selectIR && <Button style={{
                padding: '10px 14px',
                position: 'fixed',
                bottom: '21px',
                left: '50%',
                transform: 'translateX(-50%)',
            }} size='sm' onClick={() => setListaAcionistas({ ...listaAcionistas, modalTransferList: true })}>Gerar Informe de rendimentos</Button >}

            <Modal
                show={listaAcionistas.modalExportExcel}
                onHide={() => setListaAcionistas({ ...listaAcionistas, export: undefined, modalEditaInvestidor: false, modalExportExcel: false, isNovoAcionista: false, editarInvestidor: undefined })}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop='static'
                size='lg'
            >
                <Modal.Header closeButton style={{ gap: '15px' }}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="success" style={{ marginRight: '8px' }} disabled><RiFileExcel2Line /></Button> Exportar Para Excel
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h4>Escolha as colunas que deseja exportar</h4>
                            <TransferList
                                onChange={(opt) => {
                                    setListaAcionistas({ ...listaAcionistas, export: { ...listaAcionistas?.export, colunasToFilter: opt } })
                                }}
                                options={listaAcionistas?.colunas?.reduce((acc, cv) => acc = [...acc, cv.table + ' ' + cv.column_name], []).sort()}
                                selected={() => []}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '15px' }}>
                        <Col>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setListaAcionistas({ ...listaAcionistas, export: { ...listaAcionistas?.export, filtraUnidade: !!!listaAcionistas?.export?.filtraUnidade } })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!!listaAcionistas?.export?.filtraUnidade} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Filtrar por unidade?'} disabled />
                            </InputGroup>
                        </Col>
                        <Col hidden={!!!listaAcionistas?.export?.filtraUnidade}>
                            <Select
                                name="colors"
                                options={listaAcionistas?.patrimoniais?.reduce((acc, cv) => acc = [...acc, { label: cv.patrimonial, value: cv.id }], [])}
                                closeMenuOnSelect={true}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => { setListaAcionistas({ ...listaAcionistas, export: { ...listaAcionistas?.export, patrimonialToFilter: e.value } }) }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => gerarArquivo()} >Exportar</Button>
                </Modal.Footer>
            </Modal>
            <ModalTransferList
                show={listaAcionistas.modalTransferList}
                onHide={() => setListaAcionistas({ ...listaAcionistas, modalTransferList: false })}
                title='Escolha as patrimoniais que deseja gerar o informe:'
                key={'modalTransferList'}
                onPrimaryButtonClick={() => handleGerarInforme()}
                primaryButtonLabel='Gerar Informe'
                secondaryButtonLabel='Cancelar'
                options={generateOptions()}
                setSelecionadaMulti={(opt) => setListaAcionistas({ ...listaAcionistas, selecionadaMulti: opt })}
                selecionadaMulti={() => { return listaAcionistas?.selecionadaMulti ? listaAcionistas?.selecionadaMulti : [] }}
                anos={listaAcionistas?.anosInformeRendimentos}
                setAnoSelected={(opt) => { setListaAcionistas({ ...listaAcionistas, anoSelected: opt.target.value }) }}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                style={{ zIndex: 1000000, color: '#2c3e50' }}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover />
        </>
    )
}

export default ListaAcionistas;