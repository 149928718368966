import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/analyticsicon.png";

export function MobileAppBar({ children, open, setOpen }) {
  return (
    <div style={{ height: "100%,", top: 0, position: "sticky", zIndex: 1000 }}>
      <AppBar color="default" position="sticky" style={{ zIndex: 1000 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="default"
            aria-label="menu"
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography color="textPrimary" variant="h6">
            <div>
              <img
                src={logo}
                alt={"Analytics Icon"}
                className="analytics-header-logo"
              />
              Analytics
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        {children}
      </Drawer>
    </div>
  );
}
