import React, { FC } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";

interface VariacaoVerticalModalProps {
  show: boolean;
  onHide: () => void;
  handleBase: (value: string) => void;
  clearVariacao: () => void;
  budgetData: Array<any>;
  baseVertical: {
    id: string | null;
    primaryButtonLabel: string;
    outlineButtonLabel: string;
  };
}

const VariacaoVerticalModal: FC<VariacaoVerticalModalProps> = ({
  show,
  onHide,
  handleBase,
  clearVariacao,
  budgetData,
  baseVertical,
  primaryButtonLabel = "Confirmar",
  outlineButtonLabel = "Cancelar",
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Variação vertical</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Col}>
          <Form.Label>Conta para comparação:</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => handleBase(e.target.value)}
          >
            {baseVertical.id === null ? (
              <option value={null}>Selecione uma conta</option>
            ) : null}
            <optgroup label="Contas Sintéticas">
              {budgetData.length > 0
                ? budgetData
                  .filter((d) => d.composicao !== null)
                  .map((d) => (
                    <option key={'s-' + d.codigo_id} value={d.codigo_id}>{d.descricao}</option>
                  ))
                : null}
            </optgroup>
            <optgroup label="Contas Analíticas">
              {budgetData.length > 0
                ? budgetData
                  .filter((d) => d.composicao === null)
                  .map((d) => (
                    <option key={'a-' + d.codigo_id} value={d.codigo_id}>{d.descricao}</option>
                  ))
                : null}
            </optgroup>
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-danger" onClick={() => { clearVariacao(); onHide() }}>
          {outlineButtonLabel}
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            onHide();
          }}
        >
          {primaryButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VariacaoVerticalModal;
