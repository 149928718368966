import React from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { BiShowAlt } from "react-icons/bi";
import Switch from "react-switch";

interface SwitchProps {
  onToggle: () => void;
  checked: boolean;
  label: string;
}

interface Props {
  show: boolean;
  onHide: () => void;
  switches: SwitchProps[];
  title: string;
  type?: string;
  modalType?: string;
}

const SwitchModal: React.FC<Props> = ({
  show,
  onHide,
  switches,
  title,
  type,
  modalType,
}) => {
  if (type !== modalType) {
    return null;
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      style={{ background: "#2020206e" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ gap: "15px" }}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button variant="warning" style={{ marginRight: "8px" }} disabled>
            <BiShowAlt />
          </Button>{" "}
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {Array.isArray(switches) &&
            switches.map((switchProp, index) => (
              <Row key={index}>
                <Col style={{ display: "flex", margin: "10px 0" }}>
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={"#297abd"}
                    offColor={"#888"}
                    onChange={switchProp.onToggle}
                    checked={switchProp.checked}
                  />
                  &nbsp; {switchProp.label}
                </Col>
              </Row>
            ))}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { SwitchModal };
