/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useCookies } from 'react-cookie';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// API
import api from '../../../services/api';

// icons
import { GoArrowUp, GoArrowDown } from 'react-icons/go'

// redux
import { useDispatch } from 'react-redux'

export default function Anual() {

    const dispatch = useDispatch()
    const [cookies] = useCookies();

    const [coluna1, setColuna1] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [coluna2, setColuna2] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [coluna3, setColuna3] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [coluna4, setColuna4] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [coluna5, setColuna5] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [coluna6, setColuna6] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

    const [unidadesSelecionadas, setUnidadesSelecioandas] = useState([])

    const [unidades, setUnidades] = useState([])
    const [dataConsulta, setDataConsulta] = useState(new Date());
    const [dataConsultaFinal, setDataConsultaFinal] = useState(new Date());

    const [MSLoading, setMSLoading] = useState(true);
    const [selected, setSelected] = useState([]);


    useEffect(() => {
        var array = []
        JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
            array.push({ label: unidade.unidade, value: unidade.id })
        })
        setMSLoading(false)
        setUnidades(array)
    }, [MSLoading, sessionStorage.getItem('unidadesMemory')])

    function percentageFormat(inicial, final) {
        var value = (((inicial / final) - 1)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 });
        if (value === "NaN%" || value === '∞%' || ((inicial / final) - 1) === 0) {
            return <span className="analytics-stats-black">0%</span>;
        } else if (Math.sign(((inicial / final) - 1)) === 1) {
            return <span className="analytics-stats-green"><GoArrowUp className="analytics-up-icon" /> {value}</span>;
        } else {
            return <span className="analytics-stats-red"><GoArrowDown className="analytics-down-icon" /> {value}</span>;
        }
    }

    async function loadData() {
        var Coluna1 = ([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        var Coluna2 = ([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        var Coluna3 = ([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        var Coluna4 = ([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        var Coluna5 = ([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        var Coluna6 = ([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        setColuna1(Coluna1)
        var nomeUnidades = []

        var qtdUnidades = selected.length

        selected.map(unidade => {
            if (qtdUnidades === nomeUnidades.length + 1) {
                nomeUnidades.push(unidade.label)
            } else {
                nomeUnidades.push(unidade.label + ", ")
            }
        })
        setUnidadesSelecioandas(nomeUnidades)

        if (qtdUnidades > 0 && dataConsulta !== undefined && dataConsultaFinal !== undefined) {
            dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: true })
            dispatch({ type: 'SHOW_NOTIFY', msg: "Teste" })

            var ini = dataConsulta.getFullYear() + "-" + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (dataConsulta.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
            var fini = dataConsultaFinal.getFullYear() + "-" + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (dataConsultaFinal.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })

            // var codigos = ['127000', '100000', '128000', '129000', '82000', '81000', '70000', '10000']
            var codigos = ['FOOD_BEV_REVENUE', 'ROOM_REVENUE', 'OTHER_REVENUE', 'TOTAL_REVENUE', 'OCC_ROOMS', 'HOUSE_USE_ROOMS', 'DAYUSE_ROOMS', 'PHYSICAL_ROOMS']
            //taxa ocupação accor = (rooms occupied (82000) - houseuse rooms (81000) + day use rooms (70000))/ inventario total (10000)

            selected.map(async unidade => {
                await api.get(`/registrosCalculo/${codigos}/${unidade.value}/${ini}/${fini}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                    .then(res => {
                        var receitaHospedagemTotal_0 = 0;
                        var receitaaebTotal_0 = 0;
                        var outrasReceitasTotal_0 = 0;
                        var receitaTotal_0 = 0;
                        var taxaocupacao_0 = 0;
                        var uhsTotal_0 = 0;
                        var roomsOccupied_0 = 0
                        var houseUseRooms_0 = 0
                        var dayUseRooms_0 = 0

                        res.data.ano0.map(registro => {
                            switch (registro.codigo_id) {
                                case "ROOM_REVENUE":
                                    receitaHospedagemTotal_0 += registro.valor
                                    break;

                                case "FOOD_BEV_REVENUE":
                                    receitaaebTotal_0 += registro.valor
                                    break;

                                case "OTHER_REVENUE":
                                    outrasReceitasTotal_0 += registro.valor
                                    break;

                                case "TOTAL_REVENUE":
                                    receitaTotal_0 += registro.valor
                                    break;

                                case "PHYSICAL_ROOMS":
                                    uhsTotal_0 += registro.valor
                                    break;

                                case "OCC_ROOMS":
                                    roomsOccupied_0 += registro.valor
                                    break;

                                case 'HOUSE_USE_ROOMS':
                                    houseUseRooms_0 += registro.valor
                                    break;

                                case 'DAYUSE_ROOMS':
                                    dayUseRooms_0 += registro.valor
                                    break;

                                default:
                                    break;
                            }
                        })
                        taxaocupacao_0 = (roomsOccupied_0 - houseUseRooms_0 + dayUseRooms_0) / uhsTotal_0
                        var ADR_0 = 0
                        if (roomsOccupied_0 - houseUseRooms_0 + dayUseRooms_0) {
                            ADR_0 = (receitaHospedagemTotal_0 / (roomsOccupied_0 - houseUseRooms_0 + dayUseRooms_0)) / qtdUnidades
                        }
                        var revpar_0 = taxaocupacao_0 * ADR_0
                        var trevpar_0 = receitaTotal_0 / uhsTotal_0
                        Coluna1 = ([Coluna1[0] + receitaHospedagemTotal_0, Coluna1[1] + receitaaebTotal_0, Coluna1[2] + outrasReceitasTotal_0, Coluna1[3] + receitaTotal_0, Coluna1[4] + ADR_0, (taxaocupacao_0 / qtdUnidades) + Coluna1[5], revpar_0 + Coluna1[6], trevpar_0 + Coluna1[7]])
                        setColuna1(Coluna1)


                        var receitaHospedagemTotal_1 = 0;
                        var receitaaebTotal_1 = 0;
                        var outrasReceitasTotal_1 = 0;
                        var receitaTotal_1 = 0;
                        var taxaocupacao_1 = 0;
                        var uhsTotal_1 = 0;
                        var roomsOccupied_1 = 0
                        var houseUseRooms_1 = 0
                        var dayUseRooms_1 = 0

                        res.data.ano1.map(registro => {
                            switch (registro.codigo_id) {
                                case "ROOM_REVENUE":
                                    receitaHospedagemTotal_1 += registro.valor
                                    break;

                                case "FOOD_BEV_REVENUE":
                                    receitaaebTotal_1 += registro.valor
                                    break;

                                case "OTHER_REVENUE":
                                    outrasReceitasTotal_1 += registro.valor
                                    break;

                                case "TOTAL_REVENUE":
                                    receitaTotal_1 += registro.valor
                                    break;

                                case "PHYSICAL_ROOMS":
                                    uhsTotal_1 += registro.valor
                                    break;

                                case "OCC_ROOMS":
                                    roomsOccupied_1 += registro.valor
                                    break;

                                case 'HOUSE_USE_ROOMS':
                                    houseUseRooms_1 += registro.valor
                                    break;

                                case 'DAYUSE_ROOMS':
                                    dayUseRooms_1 += registro.valor
                                    break;

                                default:
                                    break;
                            }
                        })
                        taxaocupacao_1 = (roomsOccupied_1 - houseUseRooms_1 + dayUseRooms_1) / uhsTotal_1
                        var ADR_1 = 0
                        if (roomsOccupied_1 - houseUseRooms_1 + dayUseRooms_1) {
                            ADR_1 = (receitaHospedagemTotal_1 / (roomsOccupied_1 - houseUseRooms_1 + dayUseRooms_1)) / qtdUnidades
                        }
                        var revpar_1 = taxaocupacao_1 * ADR_1
                        var trevpar_1 = receitaTotal_1 / uhsTotal_1
                        Coluna2 = ([Coluna2[0] + receitaHospedagemTotal_1, Coluna2[1] + receitaaebTotal_1, Coluna2[2] + outrasReceitasTotal_1, Coluna2[3] + receitaTotal_1, ADR_1 + Coluna2[4], (taxaocupacao_1 / qtdUnidades) + Coluna2[5], revpar_1 + Coluna2[6], trevpar_1 + Coluna2[7]])
                        setColuna2(Coluna2)

                        var receitaHospedagemTotal_2 = 0;
                        var receitaaebTotal_2 = 0;
                        var outrasReceitasTotal_2 = 0;
                        var receitaTotal_2 = 0;
                        var taxaocupacao_2 = 0;
                        var uhsTotal_2 = 0;
                        var roomsOccupied_2 = 0
                        var houseUseRooms_2 = 0
                        var dayUseRooms_2 = 0

                        res.data.ano2.map(registro => {
                            switch (registro.codigo_id) {
                                case "ROOM_REVENUE":
                                    receitaHospedagemTotal_2 += registro.valor
                                    break;

                                case "FOOD_BEV_REVENUE":
                                    receitaaebTotal_2 += registro.valor
                                    break;

                                case "OTHER_REVENUE":
                                    outrasReceitasTotal_2 += registro.valor
                                    break;

                                case "TOTAL_REVENUE":
                                    receitaTotal_2 += registro.valor
                                    break;

                                case "PHYSICAL_ROOMS":
                                    uhsTotal_2 += registro.valor
                                    break;

                                case "OCC_ROOMS":
                                    roomsOccupied_2 += registro.valor
                                    break;

                                case 'HOUSE_USE_ROOMS':
                                    houseUseRooms_2 += registro.valor
                                    break;

                                case 'DAYUSE_ROOMS':
                                    dayUseRooms_2 += registro.valor
                                    break;

                                default:
                                    break;
                            }
                        })
                        taxaocupacao_2 = (roomsOccupied_2 - houseUseRooms_2 + dayUseRooms_2) / uhsTotal_2
                        var ADR_2 = 0
                        if (roomsOccupied_2 - houseUseRooms_2 + dayUseRooms_2) {
                            ADR_2 = (receitaHospedagemTotal_2 / (roomsOccupied_2 - houseUseRooms_2 + dayUseRooms_2)) / qtdUnidades
                        }
                        var revpar_2 = taxaocupacao_2 * ADR_2
                        var trevpar_2 = receitaTotal_2 / uhsTotal_2
                        Coluna3 = ([Coluna3[0] + receitaHospedagemTotal_2, Coluna3[1] + receitaaebTotal_2, Coluna3[2] + outrasReceitasTotal_2, Coluna3[3] + receitaTotal_2, ADR_2 + Coluna3[4], (taxaocupacao_2 / qtdUnidades) + Coluna3[5], revpar_2 + Coluna3[6], trevpar_2 + Coluna3[7]])
                        setColuna3(Coluna3)


                        var receitaHospedagemTotal_3 = 0;
                        var receitaaebTotal_3 = 0;
                        var outrasReceitasTotal_3 = 0;
                        var receitaTotal_3 = 0;
                        var taxaocupacao_3 = 0;
                        var uhsTotal_3 = 0;
                        var roomsOccupied_3 = 0
                        var houseUseRooms_3 = 0
                        var dayUseRooms_3 = 0

                        res.data.ano3.map(registro => {
                            switch (registro.codigo_id) {
                                case "ROOM_REVENUE":
                                    receitaHospedagemTotal_3 += registro.valor
                                    break;

                                case "FOOD_BEV_REVENUE":
                                    receitaaebTotal_3 += registro.valor
                                    break;

                                case "OTHER_REVENUE":
                                    outrasReceitasTotal_3 += registro.valor
                                    break;

                                case "TOTAL_REVENUE":
                                    receitaTotal_3 += registro.valor
                                    break;

                                case "PHYSICAL_ROOMS":
                                    uhsTotal_3 += registro.valor
                                    break;

                                case "OCC_ROOMS":
                                    roomsOccupied_3 += registro.valor
                                    break;

                                case 'HOUSE_USE_ROOMS':
                                    houseUseRooms_3 += registro.valor
                                    break;

                                case 'DAYUSE_ROOMS':
                                    dayUseRooms_3 += registro.valor
                                    break;


                                default:
                                    break;
                            }
                        })
                        taxaocupacao_3 = (roomsOccupied_3 - houseUseRooms_3 + dayUseRooms_3) / uhsTotal_3
                        var ADR_3 = 0
                        if (roomsOccupied_3 - houseUseRooms_3 + dayUseRooms_3) {
                            ADR_3 = (receitaHospedagemTotal_3 / (roomsOccupied_3 - houseUseRooms_3 + dayUseRooms_3)) / qtdUnidades
                        }
                        var revpar_3 = taxaocupacao_3 * ADR_3
                        var trevpar_3 = receitaTotal_3 / uhsTotal_3
                        Coluna4 = ([Coluna4[0] + receitaHospedagemTotal_3, Coluna4[1] + receitaaebTotal_3, Coluna4[2] + outrasReceitasTotal_3, Coluna4[3] + receitaTotal_3, ADR_3 + Coluna4[4], (taxaocupacao_3 / qtdUnidades) + Coluna4[5], revpar_3 + Coluna4[6], trevpar_3 + Coluna4[7]])
                        setColuna4(Coluna4)


                        var receitaHospedagemTotal_4 = 0;
                        var receitaaebTotal_4 = 0;
                        var outrasReceitasTotal_4 = 0;
                        var receitaTotal_4 = 0;
                        var taxaocupacao_4 = 0;
                        var uhsTotal_4 = 0;
                        var roomsOccupied_4 = 0
                        var houseUseRooms_4 = 0
                        var dayUseRooms_4 = 0

                        res.data.ano4.map(registro => {
                            switch (registro.codigo_id) {
                                case "ROOM_REVENUE":
                                    receitaHospedagemTotal_4 += registro.valor
                                    break;

                                case "FOOD_BEV_REVENUE":
                                    receitaaebTotal_4 += registro.valor
                                    break;

                                case "OTHER_REVENUE":
                                    outrasReceitasTotal_4 += registro.valor
                                    break;

                                case "TOTAL_REVENUE":
                                    receitaTotal_4 += registro.valor
                                    break;

                                case "PHYSICAL_ROOMS":
                                    uhsTotal_4 += registro.valor
                                    break;

                                case "OCC_ROOMS":
                                    roomsOccupied_4 += registro.valor
                                    break;

                                case 'HOUSE_USE_ROOMS':
                                    houseUseRooms_4 += registro.valor
                                    break;

                                case 'DAYUSE_ROOMS':
                                    dayUseRooms_4 += registro.valor
                                    break;


                                default:
                                    break;
                            }
                        })
                        taxaocupacao_4 = (roomsOccupied_4 - houseUseRooms_4 + dayUseRooms_4) / uhsTotal_4
                        var ADR_4 = 0
                        if (roomsOccupied_4 - houseUseRooms_4 + dayUseRooms_4) {
                            ADR_4 = (receitaHospedagemTotal_4 / (roomsOccupied_4 - houseUseRooms_4 + dayUseRooms_4)) / qtdUnidades
                        }
                        var revpar_4 = taxaocupacao_4 * ADR_4
                        var trevpar_4 = receitaTotal_4 / uhsTotal_4
                        Coluna5 = ([Coluna5[0] + receitaHospedagemTotal_4, Coluna5[1] + receitaaebTotal_4, Coluna5[2] + outrasReceitasTotal_4, Coluna5[3] + receitaTotal_4, ADR_4 + Coluna5[4], (taxaocupacao_4 / qtdUnidades) + Coluna5[5], revpar_4 + Coluna5[6], trevpar_4 + Coluna5[7]])
                        setColuna5(Coluna5)



                        var receitaHospedagemTotal_5 = 0;
                        var receitaaebTotal_5 = 0;
                        var outrasReceitasTotal_5 = 0;
                        var receitaTotal_5 = 0;
                        var taxaocupacao_5 = 0;
                        var uhsTotal_5 = 0;
                        var roomsOccupied_5 = 0
                        var houseUseRooms_5 = 0
                        var dayUseRooms_5 = 0

                        res.data.ano5.map(registro => {
                            switch (registro.codigo_id) {
                                case "ROOM_REVENUE":
                                    receitaHospedagemTotal_5 += registro.valor
                                    break;

                                case "FOOD_BEV_REVENUE":
                                    receitaaebTotal_5 += registro.valor
                                    break;

                                case "OTHER_REVENUE":
                                    outrasReceitasTotal_5 += registro.valor
                                    break;

                                case "TOTAL_REVENUE":
                                    receitaTotal_5 += registro.valor
                                    break;

                                case "PHYSICAL_ROOMS":
                                    uhsTotal_5 += registro.valor
                                    break;

                                case "OCC_ROOMS":
                                    roomsOccupied_5 += registro.valor
                                    break;

                                case 'HOUSE_USE_ROOMS':
                                    houseUseRooms_5 += registro.valor
                                    break;

                                case 'DAYUSE_ROOMS':
                                    dayUseRooms_5 += registro.valor
                                    break;


                                default:
                                    break;
                            }
                        })
                        taxaocupacao_5 = (roomsOccupied_5 - houseUseRooms_5 + dayUseRooms_5) / uhsTotal_5
                        var ADR_5 = 0;
                        if (roomsOccupied_5 - houseUseRooms_5 + dayUseRooms_5) {
                            ADR_5 = (receitaHospedagemTotal_5 / (roomsOccupied_5 - houseUseRooms_5 + dayUseRooms_5)) / qtdUnidades
                        }
                        var revpar_5 = taxaocupacao_5 * ADR_5
                        var trevpar_5 = receitaTotal_5 / uhsTotal_5
                        Coluna6 = ([Coluna6[0] + receitaHospedagemTotal_5, Coluna6[1] + receitaaebTotal_5, Coluna6[2] + outrasReceitasTotal_5, Coluna6[3] + receitaTotal_5, ADR_5 + Coluna6[4], (taxaocupacao_5 / qtdUnidades) + Coluna6[5], revpar_5 + Coluna6[6], trevpar_5 + Coluna6[7]])
                        setColuna6(Coluna6)


                    })

                await new Promise(resolve => setTimeout(resolve, 300))
                dispatch({ type: 'CLOSE_LOADING', isLoading: false })
            })
        }
    }


    return (
        <Container fluid>
            <Row className="rowSelector">
                <Col xs={3} md={3} xl={1}>Unidades:</Col>
                <Col xs={9} md={9} xl={4}>
                    <MultiSelect
                        options={unidades}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        className="MultiSelect"
                        isLoading={MSLoading}
                        overrideStrings={{
                            "allItemsAreSelected": "Todos as unidades selecionadas.",
                            "clearSearch": "Limpar busca",
                            "noOptions": "Sem opções",
                            "search": "Buscar",
                            "selectAll": "Selecionar Todas",
                            "selectSomeItems": "Escolha as unidades..."
                        }}
                    />
                </Col>
                <Col xs={3} md={2} xl={1}>Início:</Col>
                <Col xs={9} md={4} xl={2}>
                    <DatePicker
                        selected={dataConsulta}
                        onChange={date => setDataConsulta(date)}
                        selectsStart
                        dateFormat="dd/MM/yyyy"
                        startDate={dataConsulta}
                        endDate={dataConsultaFinal}
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </Col>
                <Col xs={3} md={2} xl={1}>Fim:</Col>
                <Col xs={9} md={4} xl={2}>
                    <DatePicker
                        selected={dataConsultaFinal}
                        onChange={date => setDataConsultaFinal(date)}
                        selectsEnd
                        startDate={dataConsulta}
                        endDate={dataConsultaFinal}
                        minDate={dataConsulta}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </Col>
                <Col xs={12} md={12} xl={1}>
                    <Button variant="secondary" onClick={() => loadData()}>Consultar</Button>
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover responsive className="analytics-table">
                    <thead>
                        <tr>
                            <td colSpan="11" className="analytics-table-title">
                                Análise comparativa anual periodo:
                                {dataConsulta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + dataConsulta.getFullYear() + " "}
                                até
                                {dataConsultaFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + dataConsultaFinal.getFullYear()}
                            </td>
                        </tr>
                        <tr><td colSpan="11" className="analytics-table-title">{unidadesSelecionadas}</td></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="analytics-table-subtitle">Valores</td>
                            <td className="analytics-table-subtitle">
                                {dataConsulta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getFullYear() - 4) + " "}
                                <div>até</div>
                                {dataConsultaFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getFullYear() - 4)}
                            </td>
                            <td className="analytics-table-subtitle">&Delta;{(dataConsulta.getFullYear() - 4)}-{(dataConsulta.getFullYear() - 5)}%</td>
                            <td className="analytics-table-subtitle">
                                {dataConsulta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getFullYear() - 3) + " "}
                                <div>até</div>
                                {dataConsultaFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getFullYear() - 3)}
                            </td>
                            <td className="analytics-table-subtitle">&Delta;{(dataConsulta.getFullYear() - 3)}-{(dataConsulta.getFullYear() - 4)} %</td>
                            <td className="analytics-table-subtitle">
                                {dataConsulta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getFullYear() - 2) + " "}
                                <div>até</div>
                                {dataConsultaFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getFullYear() - 2)}
                            </td>
                            <td className="analytics-table-subtitle">&Delta;{(dataConsulta.getFullYear() - 2)}-{(dataConsulta.getFullYear() - 3)} %</td>
                            <td className="analytics-table-subtitle">
                                {dataConsulta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getFullYear() - 1) + " "}
                                <div>até</div>
                                {dataConsultaFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getFullYear() - 1)}
                            </td>
                            <td className="analytics-table-subtitle">&Delta;{(dataConsulta.getFullYear() - 1)}-{(dataConsulta.getFullYear() - 2)} %</td>
                            <td className="analytics-table-subtitle">
                                {dataConsulta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getFullYear()) + " "}
                                <div>até</div>
                                {dataConsultaFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getFullYear())}
                            </td>
                            <td className="analytics-table-subtitle">&Delta;{(dataConsulta.getFullYear())}-{(dataConsulta.getFullYear() - 1)} %</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Taxa de Ocupação</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna5[5]) ? '0%' : coluna5[5].toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[5], coluna6[5])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna4[5]) ? '0%' : coluna4[5].toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[5], coluna5[5])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna3[5]) ? '0%' : coluna3[5].toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[5], coluna4[5])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna2[5]) ? '0%' : coluna2[5].toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[5], coluna3[5])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna1[5]) ? '0%' : coluna1[5].toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[5], coluna2[5])}</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Diária Média</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna5[4]) ? 'R$ 0,00' : coluna5[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[4], coluna6[4])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna4[4]) ? 'R$ 0,00' : coluna4[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[4], coluna5[4])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna3[4]) ? 'R$ 0,00' : coluna3[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[4], coluna4[4])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna2[4]) ? 'R$ 0,00' : coluna2[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[4], coluna3[4])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna1[4]) ? 'R$ 0,00' : coluna1[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[4], coluna2[4])}</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">RevPar</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna5[6]) ? 'R$ 0,00' : coluna5[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[6], coluna6[6])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna4[6]) ? 'R$ 0,00' : coluna4[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[6], coluna5[6])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna3[6]) ? 'R$ 0,00' : coluna3[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[6], coluna4[6])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna2[6]) ? 'R$ 0,00' : coluna2[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[6], coluna3[6])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna1[6]) ? 'R$ 0,00' : coluna1[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[6], coluna2[6])}</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">TRevPar</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna5[7]) ? 'R$ 0,00' : coluna5[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[7], coluna6[7])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna4[7]) ? 'R$ 0,00' : coluna4[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[7], coluna5[7])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna3[7]) ? 'R$ 0,00' : coluna3[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[7], coluna4[7])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna2[7]) ? 'R$ 0,00' : coluna2[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[7], coluna3[7])}</td>
                            <td className="analytics-table-subline">{
                                isNaN(coluna1[7]) ? 'R$ 0,00' : coluna1[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[7], coluna2[7])}</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Receita Hospedagem</td>
                            <td className="analytics-table-subline">{
                                coluna5[0] === undefined ? 'R$ 0,00' : coluna5[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[0], coluna6[0])}</td>
                            <td className="analytics-table-subline">{
                                coluna4[0] === undefined ? 'R$ 0,00' : coluna4[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[0], coluna5[0])}</td>
                            <td className="analytics-table-subline">{
                                coluna3[0] === undefined ? 'R$ 0,00' : coluna3[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[0], coluna4[0])}</td>
                            <td className="analytics-table-subline">{
                                coluna2[0] === undefined ? 'R$ 0,00' : coluna2[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[0], coluna3[0])}</td>
                            <td className="analytics-table-subline">{
                                coluna1[0] === undefined ? 'R$ 0,00' : coluna1[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[0], coluna2[0])}</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Receita A&B</td>
                            <td className="analytics-table-subline">{
                                coluna5[1] === undefined ? 'R$ 0,00' : coluna5[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[1], coluna6[1])}</td>
                            <td className="analytics-table-subline">{
                                coluna4[1] === undefined ? 'R$ 0,00' : coluna4[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[1], coluna5[1])}</td>
                            <td className="analytics-table-subline">{
                                coluna3[1] === undefined ? 'R$ 0,00' : coluna3[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[1], coluna4[1])}</td>
                            <td className="analytics-table-subline">{
                                coluna2[1] === undefined ? 'R$ 0,00' : coluna2[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[1], coluna3[1])}</td>
                            <td className="analytics-table-subline">{
                                coluna1[1] === undefined ? 'R$ 0,00' : coluna1[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[1], coluna2[1])}</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Outras Receitas</td>
                            <td className="analytics-table-subline">{
                                coluna5[2] === undefined ? 'R$ 0,00' : coluna5[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[2], coluna6[2])}</td>
                            <td className="analytics-table-subline">{
                                coluna4[2] === undefined ? 'R$ 0,00' : coluna4[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[2], coluna5[2])}</td>
                            <td className="analytics-table-subline">{
                                coluna3[2] === undefined ? 'R$ 0,00' : coluna3[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[2], coluna4[2])}</td>
                            <td className="analytics-table-subline">{
                                coluna2[2] === undefined ? 'R$ 0,00' : coluna2[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[2], coluna3[2])}</td>
                            <td className="analytics-table-subline">{
                                coluna1[2] === undefined ? 'R$ 0,00' : coluna1[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[2], coluna2[2])}</td>
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Receita Total</td>
                            <td className="analytics-table-subline">{
                                coluna5[3] === undefined ? 'R$ 0,00' : coluna5[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna5[3], coluna6[3])}</td>
                            <td className="analytics-table-subline">{
                                coluna4[3] === undefined ? 'R$ 0,00' : coluna4[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna4[3], coluna5[3])}</td>
                            <td className="analytics-table-subline">{
                                coluna3[3] === undefined ? 'R$ 0,00' : coluna3[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna3[3], coluna4[3])}</td>
                            <td className="analytics-table-subline">{
                                coluna2[3] === undefined ? 'R$ 0,00' : coluna2[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna2[3], coluna3[3])}</td>
                            <td className="analytics-table-subline">{
                                coluna1[3] === undefined ? 'R$ 0,00' : coluna1[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }</td>
                            <td className="analytics-table-subline">{percentageFormat(coluna1[3], coluna2[3])}</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    )
}