import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import packageInfo from "../package.json";

import AdicionarDados from "./pages/AdicionarDados";
import Analytics from "./pages/analytics";
import Login from "./pages/login";

import Anual from "./pages/Topline/Anual/comparativoanual";
import Performance from "./pages/Topline/Comparativo/comparativoperformance";
import Diario from "./pages/Topline/Diario/comparativodiario";
import Mensal from "./pages/Topline/Mensal/comparativomensal";

import AlugueisDividendos from "./pages/Patrimoniais/AlugueisDividendos/investidores";
import ExtratoFundoDeReservas from "./pages/Patrimoniais/ExtratoFundoDeReservas";
import ListaAcionistas from "./pages/Patrimoniais/ListaAcionistas/acionistas";

import DREComparativo from "./pages/DRE/Comparativo/drecomparativo";
import DREMensal from "./pages/DRE/Mensal/dremensal";
import DRERealizadoXOrcado from "./pages/DRE/RealizadoXOrcado/realizadoXOrcado";
import DreRealizadoXRealizado from "./pages/DRE/RealizadoXRealizado/index.tsx";

import EditarPermissoes from "./pages/editarPermissoes";
import Forecast from "./pages/forecast/Forecast";

import DefinicaoDePremissas from "./pages/Budget/DefinicaoPremissas";
import Elaboracao from "./pages/Budget/Elaboracao";
import GestaoContratos from "./pages/Budget/GestaoContratos";
import QuadroDePessoal from "./pages/Budget/QuadroPessoal";

import RevenueManager from "./pages/revenueManager/revenueManagerMain.tsx";

import RegistroDiarioGourmet from "./pages/gourmet/registroDiario";
import RegistroMensalGourmet from "./pages/gourmet/registroMensal";
import GourmetControle from "./pages/gourmetControle/gourmetControle";

import Cargos from "./pages/RecursosHumanos/Cargos";
import Colaboradores from "./pages/RecursosHumanos/Colaboradores";
import NovoColaborador from "./pages/RecursosHumanos/NovoColaborador";
import PDI from "./pages/RecursosHumanos/PDI";
import PDIColaborador from "./pages/RecursosHumanos/PDIColaborador";
import adicionarAtualizacoesDoSistema from "./pages/atualizacoes-do-sistema/adicionarAtualizacoes.tsx";
import AtualizacoesDoSistema from "./pages/atualizacoes-do-sistema/atualizacoes.tsx";
import ResumoBudget from "./pages/resumoBudget/index.tsx";
import UserProfile from "./pages/perfil-usuario/index.tsx";
import HistoricoDeCartas from "./pages/cartasPetrobras/index.tsx";

// imagens
import logo1 from "./assets/Icone_Gif.gif";

import { Box, Typography, useMediaQuery } from "@material-ui/core";
import Drawer from "./pages/drawer/Drawer";

export default function Routes() {
  const [cookies] = useCookies();
  const loading = useSelector((state) => state.loading);

  // const clearCacheData = async () => {
  //     var cache = await caches.keys()
  //     if (!!!cache.length) return console.log("cleaCacheData: No Data to Clear!")
  //     caches.keys().then((names) => {
  //         names.forEach((name) => {
  //             caches.delete(name);
  //         });
  //     });
  //     console.log("clearCacheData finished...")
  // };

  useEffect(() => {
    if (
      !cookies.usuario &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/rh/pdi/colaborador"
    ) {
      window.location.pathname = "/";
    }
    if (
      cookies.version !== packageInfo.info &&
      window.location.pathname !== "/rh/pdi/colaborador"
    ) {
      window.location.pathname = "/";
    }
  }, []);

  const isMobile = useMediaQuery("(max-width: 680px)");

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        {cookies?.usuario && window.location.pathname.match(/\/.*/) ? (
          <Drawer />
        ) : null}
        <div style={{ flexGrow: 1, padding: "10px" }}>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/registros" component={AdicionarDados} />
            <Route path="/editarpermissoes" component={EditarPermissoes} />
            <Route path="/atualizacoes" component={AtualizacoesDoSistema} />
            <Route
              path="/adicionar-atualizacoes"
              component={adicionarAtualizacoesDoSistema}
            />

            <Route path="/home" component={Analytics} />
            <Route path="/perfil" component={UserProfile} />

            <Route path="/comparativoanual" component={Anual} />
            <Route path="/comparativodiario" component={Diario} />
            <Route path="/comparativoperformance" component={Performance} />
            <Route path="/comparativomensal" component={Mensal} />

            {/* <Route path="/dashboardsite" component={DashboardSite} /> */}

            <Route
              exact={true}
              path="/patrimoniais/alugueisdividendos"
              component={AlugueisDividendos}
            />
            <Route
              exact={true}
              path="/patrimoniais/acionistas"
              component={ListaAcionistas}
            />
            <Route
              exact={true}
              path="/patrimoniais/fundodereserva"
              component={ExtratoFundoDeReservas}
            />

            <Route path="/dremensal" component={DREMensal} />
            <Route path="/drecomparativo" component={DREComparativo} />
            <Route
              exact={true}
              path="/dre/orcadorealizado"
              component={DRERealizadoXOrcado}
            />
            <Route
              path="/dre/realizado-x-realizado"
              component={DreRealizadoXRealizado}
            />

            <Route path="/forecast" component={Forecast} />

            <Route path="/budget" component={Elaboracao} />
            <Route path="/gestaocontratos" component={GestaoContratos} />
            <Route path="/premissas" component={DefinicaoDePremissas} />
            <Route path="/quadrodepessoal" component={QuadroDePessoal} />
            <Route path="/resumobudget" component={ResumoBudget} />

            <Route path="/revenuemanager" component={RevenueManager} />
            <Route path="/buscar-cartas" component={HistoricoDeCartas} />

            {/* graficos precisam ser atualizados para melhor usabilidade até lá ficam desativados */}
            {/* <Route path="/graficosmesporano" component={GraficosMensaisAnuais} />
                        <Route path="/graficosdiarios" component={GraficosDriarios} />
                        <Route path="/graficosmensais" component={GraficosMensais} /> */}

            <Route
              path="/registrogourmetdiario"
              component={RegistroDiarioGourmet}
            />
            <Route
              path="/registrogourmetmensal"
              component={RegistroMensalGourmet}
            />
            <Route path="/gourmetcontrole" component={GourmetControle} />

            <Route
              exact={true}
              path="/rh/colaboradores"
              component={Colaboradores}
            />
            <Route
              exact={true}
              path="/rh/colaboradores/edit"
              component={NovoColaborador}
            />
            <Route exact={true} path="/rh/cargos" component={Cargos} />
            <Route exact={true} path="/rh/pdi" component={PDI} />
            <Route
              exact={true}
              path="/rh/pdi/colaborador/:ai?"
              component={PDIColaborador}
            />
          </Switch>
        </div>
        {loading.isLoading ? (
          <div
            className="loading-bg"
            style={{
              zIndex: 1000000000,
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={logo1}
                alt={"..."}
                style={{ width: "200px" }}
                className="loading-logo"
              />
              <Typography variant="h6" style={{ marginTop: 20 }}>
                {loading.text}
              </Typography>
              <Box width="80%" mt={2}></Box>
            </Box>
          </div>
        ) : null}
      </div>
    </>
  );
}
