import React, { useEffect } from "react";
import { SwitchModal } from "./switchmodal.tsx";

interface ModalMostrarEsconderProps {
  setGraphTool: any;
  graphTool: any;
  exportTool: any;
  setExportTool: any;
  setHideMonths: any;
  hideMonths: any;
  hideInfo: any;
  setHideInfo: any;
  setModalMostrarEsconder: any;
  modalMostrarEsconder: any;
}

const ModalMostrarEsconder: React.FC<ModalMostrarEsconderProps> = ({
  setGraphTool,
  graphTool,
  exportTool,
  setExportTool,
  setHideMonths,
  hideMonths,
  hideInfo,
  setHideInfo,
  setModalMostrarEsconder,
  modalMostrarEsconder,
}) => {
  const settings = localStorage.getItem("DREOrcadoXRealizado");

  useEffect(() => {
    if (settings) {
      const parsedSettings = JSON.parse(settings);
      setGraphTool(parsedSettings.graphTool);
      setHideMonths(parsedSettings.hideMonths);
      setHideInfo({
        orcado: parsedSettings.hideOrcado,
        realizado: parsedSettings.hideRealizado,
      });
    }
  }, [settings]);

  const updateLocalStorage = () => {
    localStorage.setItem(
      "DREOrcadoXRealizado",
      JSON.stringify({
        graphTool,
        hideMonths,
        hideOrcado: hideInfo.orcado,
        hideRealizado: hideInfo.realizado,
      })
    );
  };

  const switches = [
    {
      onToggle: () => {
        setGraphTool(!graphTool);
        updateLocalStorage();
        if (exportTool === true) setExportTool(false);
      },
      checked: graphTool,
      label: "Criar gráficos",
    },
    {
      onToggle: () => {
        setHideMonths(!hideMonths);
        updateLocalStorage();
      },
      checked: !hideMonths,
      label: "Mostrar todos os meses",
    },
    {
      onToggle: () => {
        const newHideInfo = {
          ...hideInfo,
          orcado: !hideInfo.orcado,
          realizado:
            hideInfo.realizado && !hideInfo.orcado
              ? !hideInfo.realizado
              : hideInfo.realizado,
        };
        setHideInfo(newHideInfo);
        updateLocalStorage();
      },
      checked: !hideInfo.orcado,
      label: "Mostrar orçamento",
    },
    {
      onToggle: () => {
        const newHideInfo = {
          ...hideInfo,
          realizado: !hideInfo.realizado,
          orcado:
            !hideInfo.realizado && hideInfo.orcado
              ? !hideInfo.orcado
              : hideInfo.orcado,
        };
        setHideInfo(newHideInfo);
        updateLocalStorage();
      },
      checked: !hideInfo.realizado,
      label: "Mostrar realizado",
    },
  ];

  return (
    <SwitchModal
      title="Mais opções"
      onHide={() => setModalMostrarEsconder([false])}
      show={modalMostrarEsconder[0]}
      switches={switches}
    />
  );
};

export default ModalMostrarEsconder;
