import styled from "styled-components";
import { TableCell, TableContainer } from "@material-ui/core";

export const StyledFatherContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); // Padrão para telas grandes
  gap: 20px;
  width: 100%;
  padding: 20px;

  @media (max-width: 1280px) {
    // Para notebooks e telas um pouco maiores
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 960px) {
    // Para tablets e telas menores
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    // Para celular
    grid-template-columns: 1fr;
  }
`;

export const LayoutContainer = styled.div`
  background: white;

  margin: calc(5vh - 10px) 0;
  border-radius: 8px;
  box-shadow: 3px 3px 10px gray;
  height: auto;
  width: 97vw;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    background-color: #e8e8e8;
  }

  .icon {
    font-size: 2em;
    color: #119e91;
  }

  .text {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }
`;

export const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  background-color: #f4f4f4;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-width: calc(100vw);
`;
