import React, { useState, useEffect } from "react";
import "./styles.ts";
import api from "../../services/api";
import { useCookies } from "react-cookie";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { GrUpdate } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import {
  StyledContainer,
  StyledData,
  StyledDataStrong,
  StyledImageContainer,
  StyledList,
  StyledListItem,
  StyledText,
  StyledTitle,
  StyledVideoContainer,
} from "./styles.ts";

interface Informacao {
  tipo: "texto" | "imagem" | "video";
  informacao: string;
}
interface ModuloListaDetalhes {
  modulo: string;
  informacoes: Informacao[];
}
interface Atualizacao {
  id: string;
  mensagem: string;
  created_at: string;
  detalhes: {
    listaDetalhes: ModuloListaDetalhes[];
  };
}

const AtualizacoesDoSistema: React.FC = () => {
  const [atualizacoes, setAtualizacoes] = useState<Atualizacao[]>([]);
  const [expandido, setExpandido] = useState<string | null>(null);
  const [cookies] = useCookies(["usuario"]);
  const history = useHistory();

  const openPage = (path) => {
    history.push(path);
  };

  const isUserZero = cookies.usuario.permissao === 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/realeasenotes", {
          headers: {
            Authorization: `Bearer ${cookies.usuario.accessToken}`,
          },
        });

        setAtualizacoes(res.data.releaseNotes);
      } catch (error) {
        console.error("Erro ao buscar as notas de release:", error);
      }
    };
    fetchData();
  }, []);

  const toggleExpansao = (id: string) => {
    setExpandido(expandido === id ? null : id);
  };

  const renderizarDetalhe = (listaDetalhes: ModuloListaDetalhes) => {
    return (
      <StyledContainer key={listaDetalhes.modulo}>
        <StyledData>{listaDetalhes?.modulo}</StyledData>
        {listaDetalhes?.informacoes?.map((info, index) => {
          switch (info.tipo) {
            case "texto":
              return (
                <StyledText key={index}>&bull; {info.informacao}</StyledText>
              );
            case "imagem":
              return (
                <StyledImageContainer
                  key={index}
                  src={info.informacao}
                  alt="Imagem"
                />
              );
            case "video":
              return (
                <StyledVideoContainer key={index} controls>
                  <source src={info.informacao} type="video/mp4" />
                  Seu navegador não suporta vídeos.
                </StyledVideoContainer>
              );
            default:
              return null;
          }
        })}
      </StyledContainer>
    );
  };

  return (
    <StyledContainer>
      {isUserZero && (
        <div style={{ alignSelf: "end" }}>
          <Tooltip title="Atualizações do sistema" placement="right">
            <ListItem
              style={{ paddingTop: 0, paddingBottom: 0 }}
              button
              key={"Atualizações do sistema"}
              onClick={() => {
                openPage("/adicionar-atualizacoes");
              }}
            >
              <ListItemIcon>
                <GrUpdate />
              </ListItemIcon>
              <ListItemText>Adicionar Atualizações</ListItemText>
            </ListItem>
          </Tooltip>
        </div>
      )}
      <StyledTitle>Atualizações do Sistema</StyledTitle>

      <StyledList>
        {atualizacoes?.map((atualizacao) => (
          <StyledListItem
            key={atualizacao.id}
            className={`item ${
              expandido === atualizacao.id ? "expandido" : ""
            }`}
            onClick={(event) => {
              if (event.target === event.currentTarget) {
                toggleExpansao(atualizacao.id);
              }
            }}
          >
            <StyledDataStrong
              onClick={(event) => {
                if (event.target === event.currentTarget) {
                  toggleExpansao(atualizacao.id);
                }
              }}
              className="data"
            >
              {atualizacao.created_at}:
            </StyledDataStrong>{" "}
            {atualizacao.mensagem}
            <div className="detalhes">
              {expandido === atualizacao.id &&
                atualizacao.detalhes.listaDetalhes.map(renderizarDetalhe)}
            </div>
          </StyledListItem>
        ))}
      </StyledList>
    </StyledContainer>
  );
};

export default AtualizacoesDoSistema;
