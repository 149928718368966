const initial_state = {
    visible: false, 
    open: false
}

export default function searchBar(state = initial_state, action) {
    switch (action.type) {
        case 'OPENCLOSE_SEARCHBAR':
            return { visible: state.visible, open: action.isOpen };

        case 'SHOWHIDE_SEARCHBAR':
            return { visible: action.isVisible, open: state.open };

        default:
            return state;
    }
}