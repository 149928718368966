import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { InputAdornment } from "@mui/material";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  "@global": {
    "input::-ms-reveal, input::-ms-clear": {
      display: "none",
    },
  },
});

type InputProps = {
  type:
    | "textArea"
    | "text"
    | "numero"
    | "cpf"
    | "reais"
    | "celular"
    | "cnpj"
    | "data"
    | "cep"
    | "email"
    | "password"
    | "confirmPassword";
  value: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
};

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  label,
  name,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  const formatCurrency = (value) => {
    let numericValue = value.replace(/\D/g, "");
    if (!numericValue) return "";

    numericValue = (parseInt(numericValue) / 100).toFixed(2);
    return numericValue.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (type !== "reais") {
      setLocalValue(value);
    }
  }, [value, type]);

  const handleReaisChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setLocalValue(numericValue);
  };
  const handleReaisBlur = () => {
    const formattedValue = formatCurrency(localValue);
    setLocalValue(formattedValue);
    if (typeof onChange === "function") {
      onChange({ target: { value: formattedValue } });
    }
  };

  const handleEmailChange = (e) => {
    const lowerCaseValue = e.target.value.toLowerCase();
    if (typeof onChange === "function") {
      onChange({ ...e, target: { ...e.target, value: lowerCaseValue } });
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderInput = () => {
    switch (type) {
      case "numero":
        return (
          <TextField
            disabled={disabled}
            name={name}
            type="number"
            value={value}
            onChange={onChange}
            label={label}
            variant="outlined"
            fullWidth
            {...rest}
          />
        );
      case "email":
        return (
          <TextField
            disabled={disabled}
            name={name}
            type="email"
            value={value}
            onChange={handleEmailChange}
            label={label}
            variant="outlined"
            fullWidth
            {...rest}
          />
        );
      case "cpf":
        return (
          <InputMask
            disabled={disabled}
            mask="999.999.999-99"
            value={value}
            onChange={onChange}
          >
            {() => (
              <TextField
                disabled={disabled}
                name={name}
                label={label}
                variant="outlined"
                fullWidth
              />
            )}
          </InputMask>
        );
      case "reais":
        return (
          <TextField
            disabled={disabled}
            label="Valor em Reais"
            variant="outlined"
            fullWidth
            value={localValue}
            onChange={handleReaisChange}
            onBlur={handleReaisBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
          />
        );
      case "celular":
        return (
          <InputMask
            disabled={disabled}
            mask="(99) 99999-9999"
            value={value}
            onChange={onChange}
          >
            {() => (
              <TextField
                disabled={disabled}
                name={name}
                {...rest}
                label={label}
                variant="outlined"
                fullWidth
              />
            )}
          </InputMask>
        );
      case "cnpj":
        return (
          <InputMask
            disabled={disabled}
            mask="99.999.999/9999-99"
            value={value}
            onChange={onChange}
          >
            {() => (
              <TextField
                disabled={disabled}
                name={name}
                {...rest}
                label={label}
                variant="outlined"
                fullWidth
              />
            )}
          </InputMask>
        );
      case "data":
        return (
          <InputMask
            disabled={disabled}
            mask="99/99/9999"
            value={value}
            onChange={onChange}
          >
            {() => (
              <TextField
                disabled={disabled}
                {...rest}
                name={name}
                label={label}
                variant="outlined"
                fullWidth
              />
            )}
          </InputMask>
        );
      case "cep":
        return (
          <InputMask
            disabled={disabled}
            mask="99999-999"
            value={value}
            onChange={onChange}
          >
            {() => (
              <TextField
                disabled={disabled}
                label="CEP"
                variant="outlined"
                fullWidth
              />
            )}
          </InputMask>
        );
      case "textArea":
        return (
          <TextField
            type=""
            disabled={disabled}
            {...rest}
            multiline
            minRows={1}
            name={name}
            value={value}
            onChange={onChange}
            label={label}
            variant="outlined"
            fullWidth
          />
        );
      case "password":
      case "confirmPassword":
        return (
          <TextField
            type={showPassword ? "text" : "password"}
            name={name}
            label={label}
            variant="outlined"
            fullWidth
            value={value}
            onChange={onChange}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...rest}
          />
        );
      default:
        return (
          <TextField
            disabled={disabled}
            {...rest}
            name={name}
            type="text"
            value={value}
            onChange={onChange}
            label={label}
            variant="outlined"
            fullWidth
          />
        );
    }
  };

  return <div style={{ margin: "8px" }}>{renderInput()}</div>;
};

export default Input;
