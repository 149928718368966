import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

interface SelectContasProps {
  unidade: any;
  setContaSelecionada: any;
  contaSelecionada: any;
}

const animatedComponents = makeAnimated();

export const SelectContas: React.FC<SelectContasProps> = ({
  unidade,
  setContaSelecionada,
  contaSelecionada,
}) => {
  const [groupedOptions, setGroupedOptions] = useState<
    { label: string; options: any }[]
  >([]);

  useEffect(() => {
    const agruparContas = () => {
      const contasPai = unidade?.contas?.filter((conta) => conta.composicao);
      const contasFilhas = unidade?.contas?.filter(
        (conta) => !conta.composicao
      );

      const opcoesPai = contasPai.map((conta) => ({
        value: conta.codigo_id,
        label: conta.descricao,
      }));

      const opcoesFilhas = contasFilhas.map((conta) => {
        const label =
          conta?.codigo_id === conta?.descricao
            ? conta.descricao
            : `${conta.codigo_id} - ${conta.descricao}`;

        return {
          value: conta.codigo_id,
          label: label,
        };
      });

      return [
        {
          label: "Sintética",
          options: opcoesPai,
        },
        {
          label: "Analítica",
          options: opcoesFilhas,
        },
      ];
    };

    setGroupedOptions(agruparContas());
  }, [unidade]);

  const handleChange = (selectedOption) => {
    setContaSelecionada(selectedOption ? selectedOption.value : null);
  };

  return (
    <Select
      components={animatedComponents}
      options={groupedOptions}
      onChange={handleChange}
      value={groupedOptions
        .flatMap((group) => group.options)
        .find((option) => option.value === contaSelecionada)}
      isClearable
      isSearchable
      formatGroupLabel={(data) => (
        <div style={{ fontWeight: "bold" }}>{data.label}</div>
      )}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};
