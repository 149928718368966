/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, Col, Container, FormControl,
  Modal, OverlayTrigger, Popover, Row, Table
} from 'react-bootstrap';
import Select from 'react-select';

// Graficos
import ReactApexChart from "react-apexcharts";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import { useCookies } from 'react-cookie';

// API
import api from '../../../services/api';

// Styles
import './styles.css';

// redux
import { useDispatch } from 'react-redux';

import { AiOutlinePercentage } from 'react-icons/ai';
import { BiChart, BiExport, BiFilterAlt, BiInfoCircle, BiShowAlt } from 'react-icons/bi';
import { BsGraphUp } from 'react-icons/bs';
import { GrDocument } from 'react-icons/gr';
import { MdAttachMoney, } from 'react-icons/md';
import { RiFileExcel2Line, RiMenuAddFill, RiMessage2Fill, RiMessage2Line } from 'react-icons/ri';
import ModalComentarios from '../../../components/modals/models/modalComentarios.tsx';
import ModalContratos from '../../../components/modals/models/modalContratos.tsx';
import ModalMostrarEsconder from '../../../components/modals/models/modalMostrarEsconder.tsx';
import ModalNovoComentario from '../../../components/modals/models/modalNovoComentario.tsx';
import ModalRazao from '../../../components/modals/models/modalRazao.tsx';
import ModalRespostas from '../../../components/modals/models/modalRespostas.tsx';
import ModalToplineShow from '../../../components/modals/models/modalToplineShow.tsx';
import ModalGrafico from '../../../components/modals/models/modalGrafico.jsx';
import ModalRazaoAllContas from '../../../components/modals/models/modalRazaoAllContas.tsx'
import { FaChartLine } from "react-icons/fa6";
import { useCombos } from '../../../contexts/useCombo.js'
import { IoCalendarOutline } from "react-icons/io5";

export default function RealizadoXOrcado() {
  const dispatch = useDispatch()
  const [cookies] = useCookies([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [orcadoxrealizado, setOrcadoxrealizado] = useState([])
  const [orcadoxrealizadoModeloDre, setOrcadoxrealizadoModeloDre] = useState([])
  const [combos, setCombos] = useState([])
  const [anoConsulta, setAnoConsulta] = useState(null)
  const [selected, setSelected] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [openAllStatus, setOpenAllStatus] = useState(2)

  const [updateReturn, setUpdateReturn] = useState([])

  // Fab
  const [sticky] = useState({ position: 'sticky', top: 0 })
  const [variacao, setVariacao] = useState('real')
  const [hideMonths, setHideMonths] = useState(true)
  const [modalMostrarEsconder, setModalMostrarEsconder] = useState([false])
  const [hideInfo, setHideInfo] = useState({ orcado: false, realizado: false })
  const [showModalLinhas, setShowModalLinhas] = useState([false])

  // Razão
  const [razaoData, setRazaoData] = useState([])
  const [razaoDataMemory, setRazaoDataMemory] = useState([])
  const [anexosRazao, setAnexosRazao] = useState([])
  const [modalShow, setModalShow] = useState([false]);
  const [searchRazaoText, setSearchRazaoText] = useState('')
  const [nivelContaAtual, setNivelContaAtual] = useState(null)
  const [comentarios, setComentarios] = useState([])

  // Comentarios
  const [modalComentariosShow, setModalComentariosShow] = useState([false, null, { id: '' }, 0]);
  const [anexos, setAnexos] = useState([])
  const [modalNovoComentarios, setModalNovoComentarios] = useState([false]);
  const [destinatarios, setDestinatarios] = useState([])
  const [tooltipText, setToolipText] = useState('Adicionar anexo')

  // Respostas  
  const [modalRespostaVisible, setModalRespostaVisible] = useState([false, { nome: '', comentario: '' }])


  // Contratos
  const [contratos, setContratos] = useState([])
  const [modalContratosShow, setModalContratosShow] = useState([])

  // Topline
  const [toplineLines, setToplineLines] = useState([])

  // Graficos
  const [modalGrafico, setModalGrafico] = useState(false)
  const [graphTool, setGraphTool] = useState(false)
  const [exportTool, setExportTool] = useState(false)
  const [checkboxData, setCheckboxData] = useState([])
  const [filtroPesquisa, setFiltroPesquisa] = useState('');
  const [valorAnterior, setValorAnterior] = useState("");

  const [UnidadeAtualKey, setUnidadeAtualKey] = useState('')
  const [modalRazaoAllMonths, setModalRazaoAllMonths] = useState(false)
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [allMesesData, setAllMesesData] = useState();
  const [totalSaldo, setTotalSaldo] = useState();
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [eventKeyDown, setEventKeyDown] = useState();

  const loadRestaurante = false;
  const combo = useCombos(loadRestaurante);
  const matchedCombos = combo.filter(c => selected.some(s => s.value === c.id))
  const matchedCombosName = combo.filter(c => selected.some(s => s.label === c.label))
  const labelsJoined = matchedCombosName.map(c => c.label).join(' - ');
  const unidades = matchedCombos.reduce((acc, cur) => [...acc, ...cur.comp_combos], []);

  const loadInitialSettings = () => {
    const settings = JSON.parse(localStorage.getItem("DREOrcadoXRealizado"));
    return settings && settings.hideMonths;
  };

  const [isAnual, setIsAnual] = useState(loadInitialSettings());

  const settingAnnualInfo = () => {
    const settings = {
      graphTool: false,
      hideMonths: true,
      hideOrcado: false,
      hideRealizado: false,
    };
    localStorage.setItem("DREOrcadoXRealizado", JSON.stringify(settings));
    setIsAnual(true);
  };

  const settingMonthInfo = () => {
    const settings = {
      graphTool: false,
      hideMonths: false,
      hideOrcado: true,
      hideRealizado: false,
    };
    localStorage.setItem("DREOrcadoXRealizado", JSON.stringify(settings));
    setIsAnual(false);
  };


  const fetchModalMesesData = async (mesInicial, mesFinal) => {
    try {
      setIsLoading(true)
      const mes = mesFinal ? `${mesInicial}-${mesFinal}` : mesInicial;
      const anoConsultado = anoConsulta;
      const unidade = unidades
      const search = (document.getElementById('searchInput')?.value.trim().toLowerCase().replace(/,/g, '.') || "");

      const queryParams = new URLSearchParams({
        mes,
        anoConsultado,
        unidade,
        search,
        limit,
        offset,
        organizacao_id: JSON.stringify(cookies.usuario.organizacao_id)
      }).toString();

      const response = await api.get(`/razoes?${queryParams}`, {
        headers: {
          authorization: `Bearer ${cookies.usuario.accessToken}`,
        },
      });


      setModalRazaoAllMonths(true)
      setTotalSaldo(response.data.total)
      const newData = response.data.razaoData;

      if (newData.length) {
        setRazaoData(current => [...current, ...newData]);
        setOffset(currentOffset => currentOffset + limit);

      }

    } catch (err) {
      console.error("Erro ao buscar os dados:", err);
    }
    finally {
      setIsLoading(false)
    }
  };

  const handleSearchChange = (text) => {
    setIsSearchEmpty(text === "");
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      setRazaoData([]);
      fetchModalMesesData(eventKeyDown);

    }
  };

  useEffect(() => { loadCombos(); }, [])
  useEffect(() => { }, [refresh])


  async function loadCombos() {
    try {
      await api.get(`/combos?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } }).then(res => {
        var array = []
        var combosPermitidos = JSON.parse(sessionStorage.getItem('unidadesMemory')).reduce((acc, cv) => acc = [...acc, cv.combo_id], [])
        combosPermitidos = [...new Set(combosPermitidos)]
        res.data.filter(d => combosPermitidos.includes(d.id)).map(combo => {
          array.push({ label: combo.combo, value: combo.id })
        })
        setCombos(array)
      })
    } catch (error) {
      notify("Erro ao carregar combos!")
    }
  }

  function filtraRazao(searchRazaoText) {
    const filteredRazao = razaoDataMemory.filter(registro => {
      let registroLowercase = (registro.historico).toLowerCase();

      let searchTermLowercase = searchRazaoText.toLowerCase();

      return registroLowercase.indexOf(searchTermLowercase) > -1 ||
        registro.centrodecusto_id.includes(searchRazaoText) ||
        registro.data.includes(searchRazaoText) ||
        (registro.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' C').includes(searchRazaoText) ||
        (registro.debito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' D').includes(searchRazaoText);
    });
    setRazaoData(filteredRazao)
  }

  const notify = ($msg) => toast($msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  async function loadData() {
    dispatch({ type: 'OPEN_LOADING', text: "Comparando Orçado x Realizado...", isLoading: true })
    if (anoConsulta === null) {
      return notify("Escolha o ano que deseja consultar!")
    }
    if (!selected.length) {
      return notify("Escolha o combo que deseja consultar!")
    }
    setUnidadeAtualKey(selected.reduce((acc, cv) => acc += cv.value, ''))
    if (selected.length === 1) {
      try {
        await api.get('/dreorcadorealizado/' + selected[0].value + '/' + anoConsulta + '?usuario_id=' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
          .then(res => {
            setOrcadoxrealizado(res.data);
            setOrcadoxrealizadoModeloDre(res.data.modeloDre);
            setRefresh(!refresh)
            dispatch({ type: 'OPEN_LOADING', text: "Carregando budget...", isLoading: false })
          })
      } catch (error) {
        console.error("Erro ao carregar orcado x realizado: " + error)
        notify("Erro ao carregar dados, tente novamente mais tarde!")
      }

      var unidades = JSON.parse(sessionStorage.getItem('unidades')).filter(u => u.combo_id === selected[0].value).reduce((acc, cv) => acc += cv.id + ',', '')
      unidades = unidades.slice(0, -1)

      var unidadesRaw = JSON.parse(sessionStorage.getItem('unidades')).filter(u => u.combo_id === selected[0].value).reduce((acc, cv) => acc += "'" + cv.id + "',", '')
      unidadesRaw = unidadesRaw.slice(0, -1)

      try {
        const updateReturn = await api.post('/lastTimeUpdated', {
          unidades: JSON.parse(sessionStorage.getItem('unidades')).filter(u => u.combo_id === selected[0].value),
          related: ['dre', 'razao']
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        updateReturn.data.forEach(updt => {
          updt.updatedat = new Date(updt.updatedat).toLocaleDateString() + ' - ' + new Date(updt.updatedat).toLocaleTimeString()
        })

        setUpdateReturn(updateReturn.data)
      } catch (error) {
        console.error("Erro ao carregar orcado x realizado: " + error)
        notify("Erro ao carregar dados, tente novamente mais tarde!")
      }

      try {
        const contratosReturn = await api.get(`/contratos/ativos/${unidadesRaw}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setContratos(contratosReturn.data)
      } catch (error) {
        console.error("Erro ao carregar contratos: " + error)
        notify("Erro ao carregar contratos!")
      }

      try {
        const comentariosReturn = await api.get(`/getComentarios/${unidades}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setComentarios(comentariosReturn.data.comentarios)
        setDestinatarios(comentariosReturn.data.destinatarios)
        setAnexos(comentariosReturn.data.anexos)
      } catch (error) {
        console.error("Erro ao carregar comentarios: " + error)
      }
    } else {
      try {
        await api.get('/dreorcadorealizado/multi/' + selected.reduce((acc, cv) => acc += cv.value + ',', '') + '/' + anoConsulta + '?usuario_id=' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
          .then(res => {
            setOrcadoxrealizado(res.data);
            setOrcadoxrealizadoModeloDre(res.data.modeloDre);
            setRefresh(!refresh)
            dispatch({ type: 'OPEN_LOADING', text: "Carregando budget...", isLoading: false })
          })
      } catch (error) {
        console.error("Erro ao carregar orcado x realizado: " + error)
        notify("Erro ao carregar dados, tente novamente mais tarde!")
      }

      var unidades = JSON.parse(sessionStorage.getItem('unidades')).filter(u => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(u.combo_id)).reduce((acc, cv) => acc += cv.id + ',', '')
      unidades = unidades.slice(0, -1)

      var unidadesRaw = JSON.parse(sessionStorage.getItem('unidades')).filter(u => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(u.combo_id)).reduce((acc, cv) => acc += "'" + cv.id + "',", '')
      unidadesRaw = unidadesRaw.slice(0, -1)

      try {
        const updateReturn = await api.post('/lastTimeUpdated', {
          unidades: JSON.parse(sessionStorage.getItem('unidades')).filter(u => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(u.combo_id)),
          related: ['dre', 'razao']
        }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        updateReturn.data.forEach(updt => {
          updt.updatedat = new Date(updt.updatedat).toLocaleDateString() + ' - ' + new Date(updt.updatedat).toLocaleTimeString()
        })

        setUpdateReturn(updateReturn.data)
      } catch (error) {
        console.error("Erro ao carregar orcado x realizado: " + error)
        notify("Erro ao carregar dados, tente novamente mais tarde!")
      }

      try {
        const contratosReturn = await api.get(`/contratos/ativos/${unidadesRaw}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setContratos(contratosReturn.data)
      } catch (error) {
        console.error("Erro ao carregar contratos: " + error)
        notify("Erro ao carregar contratos!")
      }

      try {
        const comentariosReturn = await api.get(`/getComentarios/${unidades}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setComentarios(comentariosReturn.data.comentarios)
        setDestinatarios(comentariosReturn.data.destinatarios)
        setAnexos(comentariosReturn.data.anexos)
      } catch (error) {
        console.error("Erro ao carregar comentarios: " + error)
      }

      dispatch({ type: 'OPEN_LOADING', text: "Carregando budget...", isLoading: false })
    }
  }

  function hideShow(classname) {
    var arr = [...document.getElementsByClassName(classname)]
    arr.map(d => d.hidden = !d.hidden)
  }

  async function openRazao(codigo, mes, valor) {
    dispatch({ type: 'OPEN_LOADING', text: "Buscando registros...", isLoading: true })

    var unidades = JSON.parse(sessionStorage.getItem('unidades')).filter(u => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(u.combo_id)).reduce((acc, cv) => acc = [...acc, cv.unidade_id], [])
    unidades = JSON.stringify(unidades)

    const res = await api.get(`/registrorazaomes/${unidades}/${codigo}/${mes}/${anoConsulta}/${mes}?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
    if (res.data.razao.length) {
      setRazaoData(res.data.razao)
      setAnexosRazao(res.data.anexosRazao)
      setRazaoDataMemory(res.data.razao)
      setModalShow([true, orcadoxrealizadoModeloDre.find(d => d.codigo_id === codigo).descricao, valor])
      dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
    } else {
      dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
      notify("Nenhum lançamento registrado!")
    }
  }

  function calcSum(dados, id, lastMonth) {
    var sum = 0
    for (let i = 1; i <= lastMonth; i++) {
      sum += dados[id + '-' + i]
    }
    return sum
  }

  function openAll(stage) {
    if (openAllStatus + stage <= 3 && openAllStatus + stage > 0) {
      setOpenAllStatus(openAllStatus + stage)
    } else {
      return
    }
    var aux = (openAllStatus + stage)
    if (stage > 0) {
      if (aux === 3) {
        var arr = [...document.getElementsByClassName('openAll1'), ...document.getElementsByClassName('openAll2'), ...document.getElementsByClassName('openAll3')]
      } else if (aux === 2) {
        var arr = [...document.getElementsByClassName('openAll1'), ...document.getElementsByClassName('openAll2')]
      } else if (aux === 1) {
        var arr = [...document.getElementsByClassName('openAll1')]
      }
      arr.map(d => d.hidden = false)
    } else {
      if (aux === 2) {
        var arr = [...document.getElementsByClassName('openAll3')]
      } else if (aux === 1) {
        var arr = [...document.getElementsByClassName('openAll2'), ...document.getElementsByClassName('openAll3')]
      }
      arr.map(d => d.hidden = true)
    }
  }

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const openAllDebounced = debounce(openAll, 300);

  function handleCheckBox(linha) {
    if (checkboxData.find(d => d.id === linha.id)) {
      setCheckboxData(checkboxData.filter(d => d.id !== linha.id))
    } else {
      setCheckboxData([...checkboxData, linha])
    }
  }

  function handleCheckAll(e) {
    if (e.target.checked) {
      [...document.getElementsByClassName('checkboxORFinder')].map(d => d.checked = true)
      setCheckboxData(orcadoxrealizadoModeloDre)
    }
    if (!e.target.checked) {
      [...document.getElementsByClassName('checkboxORFinder')].map(d => d.checked = false)
      setCheckboxData([])
    }
  }

  function buildTable() {
    var body = []

    if (toplineLines.length > 0) {

      body = toplineLines.map((linha, idx) => (<tr key={UnidadeAtualKey + 'topline123' + idx} id={'Topline-' + linha.label} style={idx % 2 === 0 ? { fontSize: 'small', background: '#ccf', whiteSpace: 'nowrap', fontWeight: 'bold' } : { fontSize: 'small', background: '#ccf', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
        <td hidden={!graphTool && !exportTool}><input type="checkbox" onChange={(e) => handleCheckBox(e)} /></td>
        <td className='stickyCollumns' style={{ textAlign: 'left', background: 'inherit' }} >{linha.label}</td>
        {Array(12).fill(1).map((el, idx) => {
          const orcadoKey = 'orcado-' + (idx + 1);
          const realizadoKey = 'realizado-' + (idx + 1);
          const shouldHide = hideMonths && orcadoxrealizado.maxMonth !== (idx + 1);
          const calculoVariacao = variacao === 'real' || linha.formatValue === 'percentage' ? linha.formato(linha['realizado-' + (idx + 1)] - linha['orcado-' + (idx + 1)]) : linha['orcado-' + (idx + 1)] && linha['orcado-' + (idx + 1)] !== 0 ? ((((linha['realizado-' + (idx + 1)] / linha['orcado-' + (idx + 1)]) * 100) - 100) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 }) : '-';
          const shouldHideCedule = hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : orcadoxrealizado.maxMonth < (idx + 1) ? true : hideInfo.realizado;
          return (
            <>
              {linha[orcadoKey]
                ? <td key={UnidadeAtualKey + 'orcado1' + idx} hidden={shouldHide ? true : hideInfo.orcado}>{linha.formato(linha[orcadoKey])}</td>
                : <td key={UnidadeAtualKey + 'orcado2' + idx} hidden={shouldHide ? true : hideInfo.orcado}>-</td>
              }
              {linha[realizadoKey]
                ? <td key={UnidadeAtualKey + 'realizado1' + idx} hidden={shouldHideCedule}>{linha.formato(linha[realizadoKey])}</td>
                : <td key={UnidadeAtualKey + 'realizado2' + idx} hidden={shouldHideCedule}>-</td>
              }
              {!isNaN(linha[orcadoKey]) && !isNaN(linha[realizadoKey])
                ? <td key={UnidadeAtualKey + 'variacao1' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.orcado || hideInfo.realizado ? true : orcadoxrealizado.maxMonth < (idx + 1) ? true : false}>{calculoVariacao}</td>
                : null
              }
            </>)
        })}

        {/* Total */}

        <td hidden={hideInfo.orcado ? true : hideInfo.realizado ? true : false}>
          <span>{linha.formato(calcSum(linha, 'orcado', orcadoxrealizado.maxMonth))}</span>
        </td>
        <td hidden={hideInfo.orcado ? true : hideInfo.realizado ? false : true}>
          <span>{linha.formato(calcSum(linha, 'orcado', 12))}</span>
        </td>
        <td hidden={hideInfo.realizado}>{linha.formato(calcSum(linha, 'realizado', orcadoxrealizado.maxMonth))}</td>
        <td hidden={hideInfo.orcado || hideInfo.realizado ? true : variacao !== 'real' ? true : false}>
          <span>{linha.formato(calcSum(linha, 'realizado', orcadoxrealizado.maxMonth) - calcSum(linha, 'orcado', orcadoxrealizado.maxMonth))}</span>
        </td>
        <td hidden={hideInfo.orcado || hideInfo.realizado ? true : variacao === 'real' ? true : linha.formatValue === 'percentage' ? true : false}>
          <span>{((((calcSum(linha, 'realizado', orcadoxrealizado.maxMonth) / calcSum(linha, 'orcado', orcadoxrealizado.maxMonth)) * 100) - 100) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</span>
        </td>
        <td hidden={hideInfo.orcado || hideInfo.realizado ? true : variacao === 'real' ? true : linha.formatValue === 'percentage' ? false : true}>
          <span>{linha.formato(calcSum(linha, 'realizado', orcadoxrealizado.maxMonth) - calcSum(linha, 'orcado', orcadoxrealizado.maxMonth))}</span>
        </td>
        {/* Fim total */}
        {/* Comparativo Realizado */}
        <td hidden={hideInfo.realizado ? true : orcadoxrealizado.maxMonth < 2 ? true : false}>{linha?.formato(linha['realizado-' + (orcadoxrealizado.maxMonth - 1)])}</td>
        <td hidden={hideInfo.realizado ? true : orcadoxrealizado.maxMonth < 2 ? true : false}>{linha?.formato(linha['realizado-' + (orcadoxrealizado.maxMonth)])}</td>
        <td hidden={hideInfo.realizado ? true : orcadoxrealizado.maxMonth < 2 ? true : false}>
          <span hidden={variacao === 'real'}>
            {linha.formato((((calcSum(linha, 'realizado', parseFloat(orcadoxrealizado.maxMonth)) / calcSum(linha, 'realizado', parseFloat(orcadoxrealizado.maxMonth - 1))) * 100) - 100) / 100)}

          </span>
          <span hidden={variacao !== 'real'}>
            {linha.formato(calcSum(linha, 'realizado', parseFloat(orcadoxrealizado.maxMonth)) - calcSum(linha, 'realizado', parseFloat(orcadoxrealizado.maxMonth - 1)))}
          </span>
        </td>
        {/* Fim Comparativo Realziado */}
      </tr>
      )
      )
    }

    const filtroPesquisaLowerCase = filtroPesquisa.toLowerCase();

    const dadosParaRenderizar = orcadoxrealizadoModeloDre.length > 0 && filtroPesquisa ? orcadoxrealizadoModeloDre.filter(codigo => {
      const textoLinha = `${codigo?.codigo_id} - ${codigo?.descricao}`.toLowerCase();
      const contratoCorrespondente = contratos.find(contrato => contrato?.planodecontas_id === codigo?.codigo_id);
      const descricaoContrato = contratoCorrespondente ? contratoCorrespondente.descricao.toLowerCase() : "";
      return textoLinha.includes(filtroPesquisaLowerCase) || descricaoContrato.includes(filtroPesquisaLowerCase);
    }) : orcadoxrealizadoModeloDre;

    body = [...body, ...dadosParaRenderizar.map((codigo, idx) => {
      codigo.hidden = false
      if (codigo.nivel === 2) {
        codigo.hidden = true
      }

      return (
        <tr key={UnidadeAtualKey + 'ttl2' + idx} onClick={!codigo.destaque && codigo.composicao ? () => { hideShow(codigo.codigo_id) } : null}
          className={codigo.destaque
            ? codigo.contasuperior + ' openAll1'
            : codigo.composicao !== null
              ? codigo.contasuperior + ' openAll2'
              : codigo.composicao === null
                ? codigo.contasuperior + ' openAll3'
                : null} hidden={codigo.hidden}
          style={codigo.destaque ? { fontSize: 'small', background: '#ebeaea', whiteSpace: 'nowrap', fontWeight: 'bold' } : { background: '#fff', whiteSpace: 'nowrap', fontSize: 'small' }}>
          <td hidden={!graphTool && !exportTool} onClick={(e) => e.stopPropagation()} ><input className='checkboxORFinder' type='checkbox' onChange={() => handleCheckBox(codigo)} /></td>
          <td className='stickyCollumns' style={codigo.destaque ? { textAlign: 'left', background: 'inherit' } : { textAlign: 'left' }} onClick={contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? () => { setModalContratosShow([true, codigo.descricao, codigo.codigo_id]); } : null}>{contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? <GrDocument /> : null}{codigo.codigo_id === codigo.descricao ? codigo.codigo_id : contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? ' ' + codigo.codigo_id + ' - ' + codigo.descricao : '• ' + codigo.codigo_id + ' - ' + codigo.descricao}</td>

          {Array(12).fill(1).map((el, idx) => {
            var thisMonth = new Intl.DateTimeFormat('en', { month: "long" }).format(new Date((idx + 1).toString())) + ''
            return (
              <>
                <td key={UnidadeAtualKey + 'ttlm' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.orcado}
                  onContextMenu={codigo.composicao === null ? (e) => { e.preventDefault(); setModalComentariosShow([true, codigo.descricao, codigo, [(idx + 1)], 'orcado']); if (codigo.composicao === null) { setNivelContaAtual('analitica') } else { setNivelContaAtual('sintetica') } } : null}>
                  {codigo.dataFormated[thisMonth].orcado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  {codigo.composicao ? verificaStatusIconSintetica(codigo, (idx + 1), 'orcado') : verificaStatusIconAnalitica(codigo, (idx + 1), 'orcado')}
                </td>
                <td key={UnidadeAtualKey + 'ttlm2' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : orcadoxrealizado.maxMonth < (idx + 1) ? true : hideInfo.realizado}
                  onClick={codigo.composicao === null ? (e) => { e.preventDefault(); openRazao(codigo.codigo_id, (idx + 1), codigo.dataFormated[thisMonth].realizado) } : null}
                  onContextMenu={codigo.composicao === null ? (e) => { e.preventDefault(); openRazao(codigo.codigo_id, (idx + 1), codigo.dataFormated[thisMonth].realizado) } : codigo.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, codigo.descricao, codigo, [(idx + 1)], 'realizado']); setNivelContaAtual('sintetica') }}>
                  {codigo.dataFormated[thisMonth].realizado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  {codigo.composicao ? verificaStatusIconSintetica(codigo, (idx + 1), 'realizado') : verificaStatusIconAnalitica(codigo, (idx + 1), 'realizado')}</td>
                <td key={UnidadeAtualKey + 'ttlm3' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.orcado || hideInfo.realizado ? true : orcadoxrealizado.maxMonth < (idx + 1) ? true : variacao === 'real' ? false : true}>
                  <span style={codigo.dataFormated[thisMonth].variacaoReal > 0 ? { color: 'green' } : codigo.dataFormated[thisMonth].variacaoReal < 0 ? { color: 'red' } : null}>
                    {codigo.dataFormated[thisMonth].variacaoReal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  </span>
                </td>
                <td key={UnidadeAtualKey + 'ttlm4' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.orcado || hideInfo.realizado ? true : orcadoxrealizado.maxMonth < (idx + 1) ? true : variacao === 'real' ? true : false}>
                  <span style={codigo.dataFormated[thisMonth].variacaoReal > 0 ? { color: 'green' } : codigo.dataFormated[thisMonth].variacaoReal < 0 ? { color: 'red' } : null}>
                    {codigo.dataFormated[thisMonth].variacaoPorcentagem.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}
                  </span>
                </td>
              </>
            )
          })}


          <td hidden={hideInfo.orcado ? true : hideInfo.realizado ? true : false}>
            <span hidden={hideInfo.realizado}>{codigo.dataFormated.total.orcado.meses.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
          </td>
          <td hidden={hideInfo.orcado ? true : hideInfo.realizado ? false : true}>
            <span hidden={!hideInfo.realizado}>{codigo.dataFormated.total.orcado.ano.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
          </td>
          <td hidden={hideInfo.realizado}>{codigo.dataFormated.total.realizado.meses.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
          <td hidden={hideInfo.orcado || hideInfo.realizado ? true : variacao !== 'real'}>
            <span style={codigo.dataFormated.total.variacaoReal > 0 ? { color: 'green' } : codigo.dataFormated.total.variacaoReal < 0 ? { color: 'red' } : null}>{codigo.dataFormated.total.variacaoReal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
          </td>
          <td hidden={hideInfo.orcado || hideInfo.realizado ? true : variacao === 'real'}>
            <span style={codigo.dataFormated.total.variacaoReal > 0 ? { color: 'green' } : codigo.dataFormated.total.variacaoReal < 0 ? { color: 'red' } : null} >{codigo.dataFormated.total.variacaoPorcentagem.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</span>
          </td>

          {codigo.dataFormated.comparativo
            ? <>
              <td hidden={hideInfo.realizado ? true : orcadoxrealizado.maxMonth < 2 ? true : false}> {codigo.dataFormated.comparativo.mesPassado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
              <td hidden={hideInfo.realizado ? true : orcadoxrealizado.maxMonth < 2 ? true : false}>{codigo.dataFormated.comparativo.mes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
              <td hidden={hideInfo.realizado ? true : orcadoxrealizado.maxMonth < 2 ? true : variacao !== 'real'}>
                <span style={codigo.dataFormated.comparativo.variacaoReal > 0 ? { color: 'green' } : codigo.dataFormated.comparativo.variacaoReal < 0 ? { color: 'red' } : null}>
                  {codigo.dataFormated.comparativo.variacaoReal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </span>
              </td>
              <td hidden={hideInfo.realizado ? true : orcadoxrealizado.maxMonth < 2 ? true : variacao === 'real'}>
                <span style={codigo.dataFormated.comparativo.variacaoReal > 0 ? { color: 'green' } : codigo.dataFormated.comparativo.variacaoReal < 0 ? { color: 'red' } : null}>
                  {codigo.dataFormated.comparativo.variacaoPorcentagem.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}
                </span>
              </td>
            </>
            : null}

        </tr >)
    })]

    return body
  }

  function buildTableHeader() {
    return (
      <thead style={{ ...sticky, zIndex: 10 }}>
        <tr className="analytics-table-subtitle">
          <th hidden={!graphTool && !exportTool} className='stickyCollumns' style={{ background: 'inherit' }} rowSpan={2}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <BsGraphUp hidden={!graphTool} />
              <BiExport hidden={!exportTool} />
              <input type="checkbox" onChange={(e) => handleCheckAll(e)} />
            </div>
          </th>
          <th className='stickyCollumns' style={{ background: '#119e91', alignContent: "center" }} rowSpan={2}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              Conta


              <input
                type="text"
                placeholder="Pesquisar..."
                value={filtroPesquisa}
                onChange={(e) => {
                  const valorAtual = e.target.value;

                  if (valorAtual.length > 0) {
                    openAllDebounced(+1);
                  } else if (valorAnterior.length > 0 && valorAtual.length === 0) {
                    openAllDebounced(-1);
                  }
                  setValorAnterior(filtroPesquisa);
                  setFiltroPesquisa(valorAtual);
                }}

              />


            </div>
          </th>
          {Array(12).fill(1).map((el, idx) => {
            return (
              <th key={UnidadeAtualKey + idx + 'ttl'} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : !(orcadoxrealizado.maxMonth < (idx + 1) && hideInfo.orcado) ? false : true} colSpan={!(orcadoxrealizado.maxMonth < (idx + 1) && !hideInfo.orcado) ? !hideInfo.orcado && !hideInfo.realizado ? 3 : hideInfo.orcado && hideInfo.realizado ? 0 : 1 : 0
              }>{new Intl.DateTimeFormat('pt-br', { month: "long" }).format(new Date((idx + 1).toString()))}</th>
            )
          })}
          <th colSpan={!hideInfo.orcado ? !hideInfo.orcado && !hideInfo.realizado ? 3 : hideInfo.orcado && hideInfo.realizado ? 0 : 1 : 0}> Total</th>
          <th hidden={orcadoxrealizado.maxMonth < 2 || hideInfo.realizado ? true : false} colSpan={3}>Comparativo Realizado</th>
        </tr>
        <tr className="analytics-table-subtitle">
          {Array(12).fill(1).map((el, idx) => {
            return (
              <>
                <th key={UnidadeAtualKey + 'sub1' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.orcado ? true : false}>Orçado</th>
                <th key={UnidadeAtualKey + 'sub2' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.realizado ? true : orcadoxrealizado.maxMonth < (idx + 1) ? true : false}>Realizado <FaChartLine style={{ width: 20, cursor: "pointer" }} onClick={() => { fetchModalMesesData(orcadoxrealizado.maxMonth); setEventKeyDown(orcadoxrealizado.maxMonth) }} /></th>
                <th key={UnidadeAtualKey + 'sub3' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.orcado || hideInfo.realizado ? true : variacao === 'real' ? orcadoxrealizado.maxMonth < (idx + 1) ? true : false : true}>&#916; R$</th>
                <th key={UnidadeAtualKey + 'sub4' + idx} hidden={hideMonths && orcadoxrealizado.maxMonth !== (idx + 1) ? true : hideInfo.orcado || hideInfo.realizado ? true : variacao === 'real' ? true : orcadoxrealizado.maxMonth < (idx + 1) ? true : false}>&#916; %</th>
              </>)
          })}

          <th hidden={hideInfo.orcado ? true : false}>Orçado</th>
          <th hidden={hideInfo.realizado ? true : false}>Realizado <FaChartLine style={{ width: 20, cursor: "pointer" }} onClick={() => { fetchModalMesesData(1, orcadoxrealizado.maxMonth); setEventKeyDown(1, orcadoxrealizado.maxMonth) }} /></th>
          <th hidden={hideInfo.orcado || hideInfo.realizado ? true : variacao === 'real' ? false : true}>&#916; R$</th>
          <th hidden={hideInfo.orcado || hideInfo.realizado ? true : variacao === 'real' ? true : false}>&#916; %</th>

          <th hidden={orcadoxrealizado.maxMonth < 2 || hideInfo.realizado ? true : false}>Mês Anterior</th>
          <th hidden={orcadoxrealizado.maxMonth < 2 || hideInfo.realizado ? true : false}>Mês Atual</th>
          <th hidden={orcadoxrealizado.maxMonth < 2 || hideInfo.realizado ? true : variacao === 'real' ? false : true}>&#916; R$</th>
          <th hidden={orcadoxrealizado.maxMonth < 2 || hideInfo.realizado ? true : variacao === 'real' ? true : false}>&#916; %</th>

        </tr>
      </thead>
    )
  }

  function buildFab() {

    if (orcadoxrealizado.length !== 0) {
      return (<Fab
        icon={<BiFilterAlt />}
        alwaysShowTitle={true}
        mainButtonStyles={{ backgroundColor: '#17a2b8' }}
        style={{ bottom: 0, right: 0, zoom: '80%', margin: '20px', }}
      >
        {/* Bottom */}

        <Action
          text={<span style={{ fontSize: 'large' }}>Mais opções</span>}
          style={{ backgroundColor: '#007bff', color: 'white' }}
          onClick={() => { setModalMostrarEsconder([true]) }}
        >
          ...
        </Action>

        {isAnual ?
          <Action
            text={<span style={{ fontSize: 'large' }}>Mostrar DRE Mensal</span>}
            style={{ backgroundColor: '#007bff', color: 'white' }}
            onClick={settingMonthInfo}
          >
            <IoCalendarOutline />
          </Action>
          :
          <Action
            text={<span style={{ fontSize: 'large' }}>Mostrar DRE Orçado X Realizado</span>}
            style={{ backgroundColor: '#007bff', color: 'white' }}
            onClick={settingAnnualInfo}
          >
            <IoCalendarOutline />
          </Action>
        }

        <Action
          text={variacao !== 'real' ? <span style={{ fontSize: 'large' }}>Mostrar variação (R$)</span> : <span style={{ fontSize: 'large' }}>Mostrar variação (%)</span>}
          style={{ backgroundColor: '#007bff', color: 'white' }}
          onClick={() => { if (variacao === 'real') { setVariacao('porcentagem'); } else { setVariacao('real'); } }}
        >
          {variacao !== 'real' ? <MdAttachMoney /> : <AiOutlinePercentage />}
        </Action>

        <Action
          text={<span style={{ fontSize: 'large' }}>Adicionar linha</span>}
          style={{ backgroundColor: '#007bff', color: 'white' }}
          onClick={() => { setShowModalLinhas([true]) }}
        >
          <RiMenuAddFill />
        </Action>

        <Action
          text={<span style={{ fontSize: 'large' }}>Exportar para execel</span>}
          style={exportTool ? { backgroundColor: 'gray', color: 'white' } : { backgroundColor: 'green', color: 'white' }}
          onClick={() => { setExportTool(!exportTool); if (graphTool === true) setGraphTool(false) }}
        >
          <RiFileExcel2Line />
        </Action>
        {/* Top */}
      </Fab>
      )
    }
  }

  function verificaStatusIconSintetica(conta, mes, origem) {
    var perguntasId = destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
    var respostasId = comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
    respostasId = [...new Set(respostasId)]


    if (!conta.destaque) {
      if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
        return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
      } else if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
        return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
      } else if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
        return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
      } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
        return (<RiMessage2Line style={{ marginLeft: '5px' }} color='red' />)
      } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
        return (<RiMessage2Line style={{ marginLeft: '5px' }} color='green' />)
      } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
        return (<RiMessage2Line style={{ marginLeft: '5px' }} color='blue' />)
      }
    }
  }

  function verificaStatusIconAnalitica(conta, mes, origem) {
    var perguntasId = destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
    var respostasId = comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
    respostasId = [...new Set(respostasId)]

    if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
      return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
    } else if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
      return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
    } else if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && d.origem === origem).length > 0) {
      return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
    }
  }


  function buildFabGrafico() {
    if (graphTool) {
      return (
        <Button style={{ cursor: 'pointer', position: 'fixed', bottom: '5px', left: '45%', right: '45%' }} variant='dark' onClick={() => setModalGrafico([true])} > <BsGraphUp /> Abrir grafico </Button>
      )
    }
  }

  function buildFabExport() {
    if (exportTool) {
      return (
        <Button style={{ cursor: 'pointer', position: 'fixed', bottom: '5px', left: '45%', right: '45%' }} variant='dark' onClick={() => exportarLinhasSelecionadas()} > <BiExport /> Exportar Linhas</Button>
      )
    }
  }

  async function exportarLinhasSelecionadas() {
    if (checkboxData.length === 0) { return notify("Nenhuma linha selecionada!") }

    var returnExport = await api.post('/export/excel/dre/padrao', {
      linhas: checkboxData,
      combo_nome: selected.reduce((acc, cv) => acc += cv.label + "_", '')
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
    if (returnExport.data.status !== 200) return notify("Erro ao gerar arquivo, para mais informações entre em contato com o suporte.")

    window.open(returnExport.data.url, 'blank')

    notify('Arquivo gerado com sucesso.')
  }

  return (<div id='teste' className={"analytics-body analytics-container"}>
    <Container fluid
      className={"stickyHeader"} style={{ top: 'auto', zIndex: 11 }}
    >
      <Row className="rowSelector" >
        <Col xs={0} md={1} lg={1} xl={2}>
        </Col>
        <Col xs={12} md={6} lg={6} xl={4}>
          <Select
            name="colors"
            options={combos}
            closeMenuOnSelect={combos.length === 1 ? true : false}
            isMulti={true}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Escolha o combo..."
            onChange={setSelected}

          />
        </Col>
        <Col xs={4} md={1} lg={1} xl={1} >
          <select name="ano" id="ano" onChange={e => setAnoConsulta(e.target.value)} value={anoConsulta ?? "Ano"}>

            <option value="Ano">Ano</option>
            {Array(new Date().getFullYear() - 2018).fill(1).map((a, idx) => <option key={UnidadeAtualKey + 'ano' + idx} value={new Date().getFullYear() - idx}>{new Date().getFullYear() - idx}</option>)}
          </select>
        </Col>
        <Col xs={3} md={1} lg={1} xl={1}>
          <Button variant="secondary" onClick={() => loadData()}>Consultar</Button>
          <ModalRazao
            loadData={loadData}
            open={modalShow[0]}
            onClose={() =>
              setModalShow([0])
            }
            onClickButton={() =>
              setModalShow([0])
            }
            title={modalShow[1]}
            searchInput={
              <FormControl
                type="search"
                placeholder="Buscar registro"
                aria-label="searchRazaoBar"
                aria-describedby="basic-addon1"
                value={searchRazaoText}
                onChange={e => { setSearchRazaoText(e.target.value) }}
                onBlur={e => { filtraRazao(e.target.value); }}
              />
            }
            setRazaoData={setRazaoData}
            anexosRazao={anexosRazao}
            comentarios={comentarios}
            notify={notify}
            razaoData={razaoData}
            setModalNovoComentarios={setModalNovoComentarios}
            setModalComentariosShow={setModalComentariosShow}
            setNivelContaAtual={setNivelContaAtual}
            modalShow={modalShow[2]}
          />
          <ModalComentarios
            anexos={anexos}
            comentarios={comentarios}
            destinatarios={destinatarios}
            modalComentariosShow={modalComentariosShow}
            modalShow={modalShow}
            notify={notify}
            onClickButton={() => setModalComentariosShow([false, null, { id: null }, 0])}
            onClose={() => setModalComentariosShow([false, null, { id: null }, 0])}
            open={modalComentariosShow[0]}
            razaoData={razaoData}
            setModalNovoComentarios={setModalNovoComentarios}
            setModalRespostaVisible={setModalRespostaVisible}
            setNivelContaAtual={setNivelContaAtual}
            title='Comentarios'
            adicionarNovoComentario={modalComentariosShow[4] === 'realizado'} />
          <ModalNovoComentario
            anoConsulta={anoConsulta}
            loadData={loadData}
            title='Adicionar Comentário'
            onClose={() => setModalNovoComentarios([false])}
            open={modalNovoComentarios[0]}
            modalComentariosShow={modalComentariosShow}
            modalNovoComentarios={modalNovoComentarios}
            nivelContaAtual={nivelContaAtual}
            onClickButton={() => { setModalNovoComentarios([false]); setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
            selected={selected}
            setToolipText={setToolipText} tooltipText={tooltipText}
            notify={notify}

          />
          <ModalRespostas
            anoConsulta={anoConsulta}
            selected={selected}
            loadData={loadData}
            setToolipText={setToolipText}
            tooltipText={tooltipText}
            modalRespostaVisible={modalRespostaVisible}
            notify={notify}
            open={modalRespostaVisible[0]}
            onClose={() => setModalRespostaVisible([false, { nome: '', comentario: '' }])}
            onClickButton={() => { setModalRespostaVisible([false, { nome: '', comentario: '' }]);; setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
            title='Adicione Resposta'
            modalComentariosShow={modalComentariosShow}
            nivelContaAtual={nivelContaAtual} />
          <ModalContratos contratos={contratos} modalContratosShow={modalContratosShow} onClickButton={() => setModalContratosShow([false, null, null])} open={modalContratosShow[0]} onClose={() => setModalContratosShow([false, null, null])} title='Contratos'
          />
          <ModalMostrarEsconder exportTool={exportTool} graphTool={graphTool} hideInfo={hideInfo} hideMonths={hideMonths} modalMostrarEsconder={modalMostrarEsconder} setExportTool={setExportTool} setGraphTool={setGraphTool} setHideInfo={setHideInfo} setHideMonths={setHideMonths} setModalMostrarEsconder={setModalMostrarEsconder} />
          <ModalToplineShow anoConsulta={anoConsulta} selected={selected} setShowModalLinhas={setShowModalLinhas} setToplineLines={setToplineLines} notify={notify} onClickButton={() => setShowModalLinhas([false])} onClose={() => setShowModalLinhas([false])} open={showModalLinhas[0]} />
          <ModalGrafico onClickButton={() => setModalGrafico(false)} onClose={() => setModalGrafico(false)} checkboxData={checkboxData} open={modalGrafico} title='Modal Gráfico' />
        </Col>
        <Col xs={1} md={1} lg={1} xl={1}>

          {orcadoxrealizado.length === 0
            ? null
            : <OverlayTrigger placement="bottom"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Title as="h5">Ultima atualização:</Popover.Title>
                  <Popover.Content >
                    <p><b>DREs:</b></p>
                    <ul>
                      {updateReturn.filter(d => d.related === 'dre').map((update, idx) => <li key={UnidadeAtualKey + 'uppdre' + idx}>{update.unidade} - {update.updatedat}</li>)}
                    </ul>
                    <p><b>Razões:</b></p>
                    <ul>
                      {updateReturn.filter(d => d.related === 'razao').map((update, idx) => <li key={UnidadeAtualKey + 'upprazao' + idx}>{update.unidade} - {update.updatedat}</li>)}
                    </ul>
                  </Popover.Content>
                </Popover>
              }>
              <Button variant="light" size='sm' >
                <BiInfoCircle />
              </Button>
            </OverlayTrigger>
          }
        </Col>
      </Row>
    </Container>

    {orcadoxrealizado.length === 0
      ? <div>Escolha os dados que deseja consultar!</div>
      : <Table id='tableExport' bordered size='sm' style={{ marginBottom: '35px' }} className="analytics-table">
        {buildTableHeader()}
        <tbody>
          {buildTable(orcadoxrealizado)}
        </tbody>
      </Table>
    }

    {buildFab()}
    {buildFabGrafico()}
    {buildFabExport()}

    <ModalRazaoAllContas
      offset={offset}
      fetchModalMesesData={fetchModalMesesData}
      selectedMonth={orcadoxrealizado.maxMonth}
      open={modalRazaoAllMonths}
      onClose={() => { setModalRazaoAllMonths(false); setRazaoData([]); setOffset(0); }
      }
      onClickButton={() => { setModalRazaoAllMonths(false); setRazaoData([]); setOffset(0); }
      }
      title={labelsJoined}
      isSearchEmpty={isSearchEmpty}
      isLoading={isLoading}
      searchInput={
        <FormControl
          type="search"
          id='searchInput'
          placeholder="Buscar registro"
          aria-label="searchRazaoBar"
          aria-describedby="basic-addon1"
          onChange={(event) => { handleSearchChange(event?.target?.value); setOffset(0); }}
          onBlur={() => { setRazaoData([]); fetchModalMesesData(orcadoxrealizado.maxMonth); }}
          onKeyDown={(event) => { handleKeyDown(event) }}
        />
      }
      setRazaoData={setRazaoData}
      anexosRazao={anexosRazao}
      comentarios={comentarios}
      notify={notify}
      razaoData={razaoData}
      setModalNovoComentarios={setModalNovoComentarios}
      setModalComentariosShow={setModalComentariosShow}
      setNivelContaAtual={setNivelContaAtual}
      totalSaldo={totalSaldo}
      modalShow={modalShow[2]}
    />

    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      style={{ zIndex: 100000000 }}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover />
  </div>);
}