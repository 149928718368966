export const formatTopLine = [{
    codigo_id: 'FOOD_BEV_REVENUE',
    descricao: "Receita A&B",
    mes: (codigo, mes, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.mes === mes && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    mesFormat: (codigo, mes, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.mes === mes && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })),
    total: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    totalFormat: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })),
    media: (codigo, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0))),
    mediaFormat: (codigo, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    format: (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
},
{
    codigo_id: 'ROOM_REVENUE',
    descricao: "Receita Hospedagem",
    mes: (codigo, mes, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    mesFormat: (codigo, mes, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })),
    total: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    totalFormat: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })),
    media: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0)),
    mediaFormat: (codigo, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    format: (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}, {
    codigo_id: 'GUEST_IN_HOUSE',
    descricao: "Hospedes na Casa",
    mes: (codigo, mes, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    mesFormat: (codigo, mes, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)).toFixed(2) + ' GIH'),
    total: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    totalFormat: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0).toFixed(2) + ' GIH'),
    media: (codigo, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0))).toFixed(2),
    mediaFormat: (codigo, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0))).toFixed(2) + ' GIH',
    format: (valor) => valor.toFixed(2) + ' GIH'
}, {
    codigo_id: 'OCC_ROOMS',
    descricao: "Quartos Ocupados",
    mes: (codigo, mes, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    mesFormat: (codigo, mes, ano, unidadeId, toplineData) => (parseInt(toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)).toFixed(2) + ' UHs'),
    total: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    totalFormat: (codigo, ano, unidadeId, toplineData) => (parseInt(toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)).toFixed(2) + ' UHs'),
    media: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0)),
    mediaFormat: (codigo, ano, unidadeId, toplineData) => (parseInt(toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)) / (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => Math.max(acc, cv.mes), 0))).toFixed(2) + ' UHs',
    format: (valor) => valor?.toFixed(2) + ' UHs'
}, {
    codigo_id: 'OCC_PERC',
    descricao: "Taxa de Ocupação",
    mes: (codigo, mes, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    mesFormat: (codigo, mes, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano)?.reduce((acc, cv) => acc += cv.sum, 0))?.toFixed(2) + ' %'),
    total: (codigo, ano) => 0,
    totalFormat: (codigo, ano) => '-',
    media: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0) / toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).length),
    mediaFormat: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0) / toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).length).toFixed(2) + ' %',
    format: (valor) => valor?.toFixed(2) + ' %'
}, {
    codigo_id: 'ADR_ROOM',
    descricao: "Diária Média",
    mes: (codigo, mes, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)),
    mesFormat: (codigo, mes, ano, unidadeId, toplineData) => ((toplineData?.filter(dados => dados.codigo_id === codigo && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })),
    total: (codigo, ano) => 0,
    totalFormat: (codigo, ano) => '-',
    media: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0) / toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).length),
    mediaFormat: (codigo, ano, unidadeId, toplineData) => (toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0) / toplineData?.filter(dados => dados.codigo_id === codigo && dados.unidade_id === unidadeId && dados.ano === ano).length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    format: (valor) => valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
},
{
    codigo_id: 'RevPar',
    descricao: "RevPar",
    mes: (codigo, mes, ano, unidadeId, toplineData) => (((toplineData?.filter(dados => dados.codigo_id === 'OCC_PERC' && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0))) * (toplineData?.filter(dados => dados.codigo_id === 'ADR_ROOM' && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0))),
    mesFormat: (codigo, mes, ano, unidadeId, toplineData) => (((((toplineData?.filter(dados => dados.codigo_id === 'OCC_PERC' && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0))) * (toplineData?.filter(dados => dados.codigo_id === 'ADR_ROOM' && dados.mes === mes && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0))) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })),
    total: (codigo, ano) => 0,
    totalFormat: (codigo, ano) => '-',
    media: (codigo, ano, unidadeId, toplineData) => (((((toplineData?.filter(dados => dados.codigo_id === 'OCC_PERC' && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0))) * (toplineData?.filter(dados => dados.codigo_id === 'ADR_ROOM' && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)))) / 100) / toplineData?.filter(dados => dados.codigo_id === 'ADR_ROOM' && dados.unidade_id === unidadeId && dados.ano === ano).length,
    mediaFormat: (codigo, ano, unidadeId, toplineData) => ((((((toplineData?.filter(dados => dados.codigo_id === 'OCC_PERC' && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0))) * (toplineData?.filter(dados => dados.codigo_id === 'ADR_ROOM' && dados.unidade_id === unidadeId && dados.ano === ano).reduce((acc, cv) => acc += cv.sum, 0)))) / 100) / toplineData?.filter(dados => dados.codigo_id === 'ADR_ROOM' && dados.unidade_id === unidadeId && dados.ano === ano).length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    format: (valor) => valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}, {
    codigo_id: 'uhs',
    descricao: 'UHs disponiveis',
    mes: (codigo, mes, ano, unidadeId) => JSON.parse(sessionStorage.getItem('unidades')).find(unid => unid.id === unidadeId).uhs,
    mesFormat: (codigo, mes, ano, unidadeId) => (JSON.parse(sessionStorage.getItem('unidades')).find(unid => unid.id === unidadeId).uhs) + ' UHs',
    total: (codigo, ano, unidadeId) => ((JSON.parse(sessionStorage.getItem('unidades')).find(unid => unid.id === unidadeId).uhs) * 12),
    totalFormat: (codigo, ano, unidadeId) => ((JSON.parse(sessionStorage.getItem('unidades')).find(unid => unid.id === unidadeId).uhs) * 12) + ' UHs',
    media: (codigo, ano, unidadeId) => (JSON.parse(sessionStorage.getItem('unidades')).find(unid => unid.id === unidadeId).uhs),
    mediaFormat: (codigo, ano, unidadeId) => (JSON.parse(sessionStorage.getItem('unidades')).find(unid => unid.id === unidadeId).uhs) + ' UHs',
    format: (valor) => valor + ' UHs'
},]