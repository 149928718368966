import React from "react";
import { List, Checkbox } from "@material-ui/core";
import QuestionItem from "./questionItem.jsx";

const QuestionSection = ({
  perguntas,
  quadrante,
  onToggle,
  onMoveUp,
  onMoveDown,
  selectedItems,
  handleCheckChange,
}) => {
  return (
    <List
      style={{
        padding: "10px",
        backgroundColor: "#f4f4f4",
        borderRadius: "5px",
      }}
    >
      {perguntas
        ?.filter(
          (p) => p.number.split(".").length === 1 && p.quadrante === quadrante
        )
        .map((pergunta) => {
          const isParentChecked =
            selectedItems.some((item) => item.id === pergunta.id) ||
            perguntas.some(
              (p2) =>
                p2.number.startsWith(pergunta.number + ".") &&
                selectedItems.some((selectedItem) => selectedItem.id === p2.id)
            );
          return (
            <li key={`section-${pergunta.id}`} style={{ marginBottom: "15px" }}>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Checkbox
                    checked={isParentChecked}
                    onChange={(e) =>
                      handleCheckChange(pergunta, e.target.checked)
                    }
                    style={{ marginRight: "10px" }}
                    disabled
                  />
                  <h6 style={{ color: "#119e91", fontWeight: "bold" }}>
                    {pergunta.number + " - " + pergunta.pergunta}
                  </h6>
                </div>
                {perguntas
                  ?.filter(
                    (p2) =>
                      p2.number.split(".")[0] === pergunta.number &&
                      p2.number.split(".").length > 1 &&
                      p2.quadrante === quadrante
                  )
                  .map((item, idx, array) => (
                    <>
                      <QuestionItem
                        key={`item-${item.id}`}
                        item={item}
                        onToggle={onToggle}
                        onMoveUp={() => onMoveUp(item, array[idx - 1])}
                        onMoveDown={() => onMoveDown(item, array[idx + 1])}
                        isMoveUpDisabled={idx === 0}
                        isMoveDownDisabled={idx === array.length - 1}
                        perguntas={perguntas}
                        onCheckChange={handleCheckChange}
                        isChecked={selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )}
                      />
                    </>
                  ))}
              </ul>
            </li>
          );
        })}
    </List>
  );
};

export default QuestionSection;
