/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {MultiSelect} from "react-multi-select-component";
import { Table, Button, Col, Row, Container } from 'react-bootstrap'
import Switch from "react-switch";

import { useCookies } from 'react-cookie';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// API
import api from '../../../services/api';

// // icons
// import { GoArrowSmallUp, GoArrowSmallDown } from 'react-icons/go'

// redux
import { useDispatch } from 'react-redux'

export default function Diario() {

    const dispatch = useDispatch()
  const [cookies] = useCookies();

    const [unidadesSelecionadas, setUnidadesSelecioandas] = useState([])

    const [unidades, setUnidades] = useState([])
    const [dataConsulta, setDataConsulta] = useState();
    const [dataConsultaFinal, setDataConsultaFinal] = useState();

    const [alldays, setAllDays] = useState([])
    const [isHidden, setIsHidden] = useState(true)

    const [MSLoading, setMSLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true)

    var defaultTable = [<td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,
    <td className='analytics-table-subline'></td>,]
    const [ano0, setAno0] = useState([])
    const [ano1, setAno1] = useState([])
    const [ano2, setAno2] = useState([])
    const [checked, setChecked] = useState(false)


    // var codigosLista = '100000,127000,128000,129000,132000,152000'
    var codigosLista = 'ROOM_REVENUE,FOOD_BEV_REVENUE,OTHER_REVENUE,TOTAL_REVENUE,ADR_ROOM,OCC_PERC'

    const onChange = date => {
        setDataConsulta(date);
        setDataConsultaFinal(addDays(date, 29));
    };

    useEffect(() => {
        var array = []
        JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
            array.push({ label: unidade.unidade, value: unidade.id })
        })
        setMSLoading(false)
        setUnidades(array)
    }, [MSLoading, sessionStorage.getItem('unidadesMemory')])

    // function percentageFormat(inicial, final) {
    //     var value = (((inicial / final) - 1)).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 });
    //     if (value === "NaN%" || value === '∞%' || ((inicial / final) - 1) === 0) {
    //         return <span className="analytics-stats-black">0%</span>;
    //     } else if (Math.sign(((inicial / final) - 1)) === 1) {
    //         return <span className="analytics-stats-green"><GoArrowSmallUp className="analytics-up-icon" /> {value}</span>;
    //     } else {
    //         return <span className="analytics-stats-red"><GoArrowSmallDown className="analytics-down-icon" /> {value}</span>;
    //     }
    // }

    async function loadData(val) {
        dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: true })
        var unidades = "VAZIO"
        var nomeUnidades = ""
        selected.map(unidade => {
            if (unidades === "VAZIO") {
                unidades = unidade.value
                nomeUnidades = unidade.label
            } else {
                unidades = unidades + ',' + unidade.value
                nomeUnidades = nomeUnidades + ', ' + unidade.label
            }
        })
        setUnidadesSelecioandas(nomeUnidades)

        var ini = dataConsulta.getFullYear() + "-" + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (dataConsulta.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
        var fini = dataConsultaFinal.getFullYear() + "-" + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (dataConsultaFinal.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })

        setFinal(dataConsultaFinal)

        await api.get(`/registrosDiario/${unidades}/${codigosLista}/${ini}/${fini}/0`,{headers:{'authorization': `Bearer ${cookies.usuario.accessToken}`}})
            .then(res => {
                setAno0(res.data)
            })

        await api.get(`/registrosDiario/${unidades}/${codigosLista}/${ini}/${fini}/1`,{headers:{'authorization': `Bearer ${cookies.usuario.accessToken}`}})
            .then(res => {
                setAno1(res.data)
            })

        await api.get(`/registrosDiario/${unidades}/${codigosLista}/${ini}/${fini}/2`,{headers:{'authorization': `Bearer ${cookies.usuario.accessToken}`}})
            .then(res => {
                setAno2(res.data)
            })

        setIsDisabled(false)
        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
    }

    function setFinal(data) {
        var result = new Date(data);
        result.setDate(result.getDate() - 29);
        var alldays = []
        for (let i = 0; i <= 29; i++) {
            alldays.push(addDays(result, i))
        }
        setAllDays(alldays)
    }

    function addDays(date, days) {
        if (date == null || date === undefined) {
            return new Date()
        }
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    return (
        <Container fluid>
            <Row className="rowSelector">
                <Col xs={3} md={3} xl={1}>Unidades:</Col>
                <Col xs={9} md={9} xl={5}>
                    <MultiSelect
                        options={unidades}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        className="MultiSelect"
                        isLoading={MSLoading}
                        overrideStrings={{
                            "allItemsAreSelected": "Todos as unidades selecionadas.",
                            "clearSearch": "Limpar busca",
                            "noOptions": "Sem opções",
                            "search": "Buscar",
                            "selectAll": "Selecionar Todas",
                            "selectSomeItems": "Escolha as unidades..."
                        }}
                    />
                </Col>
                <Col xs={6} md={2} xl={1}>Preíodo:</Col>
                <Col xs={6} md={3} xl={2}>
                    <DatePicker
                        onChange={onChange}
                        placeholderText={dataConsulta === undefined || dataConsultaFinal === undefined || dataConsulta === null || dataConsultaFinal === null
                            ? "Selecione o periodo!"
                            : ((dataConsulta.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "/" + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "/" + dataConsulta.getFullYear()
                                + " ~ " +
                                (dataConsultaFinal.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "/" + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "/" + dataConsultaFinal.getFullYear())}
                        dateFormat="dd/MM/yyyy"
                        startDate={dataConsulta}
                        endDate={addDays(dataConsulta, 29)}
                        shouldCloseOnSelect={false}
                        maxDate={addDays(new Date(), -29)}
                        selectsStart
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </Col>
                <Col xs={12} md={5} xl={1}><Button variant="secondary" onClick={() => { loadData(true); setIsDisabled(false) }}>Consultar</Button></Col>
                <Col xs={6} md={1} xl={1}>
                    Quinzena
                </Col>
                <Col xs={6} md={1} xl={1}>
                    <Switch disabled={isDisabled} uncheckedIcon={<div className="analytics-switch">2ª</div>} checkedIcon={<div className="analytics-switch">1ª</div>} onColor={'#888'} onChange={() => { setIsHidden(!isHidden); setChecked(!checked) }} checked={checked} />
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover responsive className="analytics-table">
                    <thead>
                        <tr>
                            <td colSpan="17" className="analytics-table-title">
                                Análise comparativa diária ultimos 30 dias:
                            </td>
                        </tr>
                        <tr><td colSpan="17" className="analytics-table-title">{unidadesSelecionadas}</td></tr>
                    </thead>
                    <tbody>
                        {alldays.length !== 0
                            ? (<tr>
                                <td colSpan='2' className="analytics-table-subtitle"></td>
                                {alldays.map((date, idx) => {
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subtitle'>{(date.toLocaleString('pt-br')).substring(0, 5)}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subtitle'>{date.toLocaleString('pt-br').substring(0, 5)}</td>
                                    }
                                })}
                            </tr>)
                            : (<tr>
                                <td colSpan='2' className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                                <td className="analytics-table-subtitle"></td>
                            </tr>
                            )}
                        <tr>
                            <td rowSpan='3' className="analytics-table-subline">Taxa de Ocupação</td>
                            <td className="analytics-table-subline">2021</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano0.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "OCC_PERC")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toFixed(2) + ' %'
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2020</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano1.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "OCC_PERC")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toFixed(2) + ' %'
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2019</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano2.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "OCC_PERC")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toFixed(2) + ' %'
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td rowSpan='3' className="analytics-table-subline">Diária Média</td>
                            <td className="analytics-table-subline">2021</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano0.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "ADR_ROOM")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2020</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano1.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "ADR_ROOM")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2019</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano2.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "ADR_ROOM")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td rowSpan='3' className="analytics-table-subline">Receita Hospedagem</td>
                            <td className="analytics-table-subline">2021</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano0.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "ROOM_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2020</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano1.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "ROOM_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2019</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano2.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "ROOM_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td rowSpan='3' className="analytics-table-subline">Receita A&B</td>
                            <td className="analytics-table-subline">2021</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano0.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "FOOD_BEV_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2020</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano1.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "FOOD_BEV_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2019</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano2.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "FOOD_BEV_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td rowSpan='3' className="analytics-table-subline">Outras Receitas</td>
                            <td className="analytics-table-subline">2021</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano0.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "OTHER_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2020</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano1.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "OTHER_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2019</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano2.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "OTHER_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td rowSpan='3' className="analytics-table-subline">Receita Total</td>
                            <td className="analytics-table-subline">2021</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano0.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "TOTAL_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2020</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano1.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "TOTAL_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">2019</td>
                            {alldays.length !== 0
                                ? alldays.map((date, idx) => {
                                    let myObj = ano2.filter(d => new Date(d.data).getDate() === date.getDate() && new Date(d.data).getMonth() === date.getMonth() && d.codigo_id === "TOTAL_REVENUE")
                                    var value = 'ND'
                                    if (myObj) {
                                        if (myObj.length > 0) {
                                            value = (myObj.reduce((acc, cv) => (acc + cv.valor), 0) / selected.length).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                        }
                                    }
                                    if (idx >= 15) {
                                        return <td id={'subtitleDays-' + idx} hidden={isHidden} className='analytics-table-subline'>{value}</td>
                                    } else {
                                        return <td id={'subtitleDays-' + idx} hidden={!isHidden} className='analytics-table-subline'>{value}</td>
                                    }
                                })
                                : defaultTable.map(element => {
                                    return element;
                                })}
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    )
}