/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import Select from 'react-select';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// icons
import { BiFilterAlt, BiMinusCircle, BiPlusCircle } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import { GrDocument } from 'react-icons/gr';
import { MdChildCare } from 'react-icons/md';
import { VscPerson } from 'react-icons/vsc';

//Modais
import ModalComentarios from '../../../components/modals/models/modalComentarios.tsx';
import ModalContratos from '../../../components/modals/models/modalContratos.tsx';
import ModalNovoComentario from '../../../components/modals/models/modalNovoComentario.tsx';
import ModalRazao from '../../../components/modals/models/modalRazao.tsx';
import ModalRespostas from '../../../components/modals/models/modalRespostas.tsx';
import ModalRazaoAllContas from '../../../components/modals/models/modalRazaoAllContas.tsx'
import { FaChartLine } from "react-icons/fa6";


// API
import api from '../../../services/api';

// redux
import { RiMessage2Fill, RiMessage2Line } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

export default function DRE() {

    const dispatch = useDispatch()
    const [cookies] = useCookies([]);

    const [dreData2, setDreData2] = useState([])
    const [codigosDre, setCodigosDre] = useState([])
    const [registros, setRegistros] = useState([])

    const [consultado, setConsultado] = useState(true)
    const [modalFilterShow, setModalFilterShow] = useState([false]);

    const [unidades, setUnidades] = useState([])
    const [modalNovoComentarios, setModalNovoComentarios] = useState([false]);
    const [razaoData, setRazaoData] = useState([])
    const [comentarios, setComentarios] = useState([])
    const [comentariosSelecionados, setComentariosSelecionados] = useState([])
    const [destinatarios, setDestinatarios] = useState([])
    const [anexos, setAnexos] = useState([])
    const [searchRazaoText, setSearchRazaoText] = useState('')
    const [nivelContaAtual, setNivelContaAtual] = useState(null)
    const [razaoDataMemory, setRazaoDataMemory] = useState([])
    const [modalComentariosShow, setModalComentariosShow] = useState([false, null, { id: '' }]);
    const [anexosRazao, setAnexosRazao] = useState([])
    const [modalShow, setModalShow] = useState([false]);
    const [mesConsulta, setMesConsulta] = useState();
    const [mesConsultaFinal, setMesConsultaFinal] = useState();
    const [anoConsulta, setAnoConsulta] = useState(null)
    const [filtro, setFiltro] = useState({ mb: true, ml: true, mc: true, txRO: true, adr: true, trih: true, rn: true, ro: true, iha: true, ihc: true, tihp: true, fix: false, noColab: true })
    const [sticky, setSticky] = useState({ position: 'sticky', top: 0, zIndex: 2 })
    const [variacaoHidden, setVariacaoHidden] = useState({ horizontal: true, vertical: true })
    const [numeroDeIntervalos, setNumeroDeIntervalos] = useState(1)
    const [baseVertical, setBaseVertical] = useState({ id: null })
    const [myNoColab, setMyNoColab] = useState({ data: [], active: 'Total' })

    const [MSLoading, setMSLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [selectedAtual, setSelectedAtual] = useState([]);

    const [contratos, setContratos] = useState([])
    const [modalContratosShow, setModalContratosShow] = useState([])
    const [modalRespostaVisible, setModalRespostaVisible] = useState([false, { nome: '', comentario: '' }])
    const [tooltipText, setToolipText] = useState('Adicionar anexo')
    const [checked, setChecked] = useState(false)
    const [selectedUser, setSelectedUser] = useState([]);
    const [myUsers, setMyUsers] = useState(null)
    const [UnidadeAtualKey, setUnidadeAtualKey] = useState('')
    const [totalSaldo, setTotalSaldo] = useState();
    const [isSearchEmpty, setIsSearchEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [modalRazaoAllMonths, setModalRazaoAllMonths] = useState(false)
    const [unidadeName, setUnidadeName] = useState();

    var meses = ["", "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    const fetchModalMesesData = async (mesInicial, mesFinal, unidades) => {
        try {
            setIsLoading(true)
            const mes = mesFinal ? `${mesInicial}-${mesFinal}` : mesInicial;
            const anoConsultado = anoConsulta;
            const unidade = unidades
            const search = (document.getElementById('searchInput')?.value.trim().toLowerCase().replace(/,/g, '.') || "");

            const queryParams = new URLSearchParams({
                mes,
                anoConsultado,
                unidade,
                search,
                limit,
                offset,
                organizacao_id: JSON.stringify(cookies.usuario.organizacao_id)
            }).toString();

            const response = await api.get(`/razoes?${queryParams}`, {
                headers: {
                    authorization: `Bearer ${cookies.usuario.accessToken}`,
                },
            });


            setModalRazaoAllMonths(true)
            setTotalSaldo(response.data.total)
            const newData = response.data.razaoData;

            if (newData.length) {
                setRazaoData(current => [...current, ...newData]);
                setOffset(currentOffset => currentOffset + limit);

            }

        } catch (err) {
            console.error("Erro ao buscar os dados:", err);
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleSearchChange = (text) => {
        setIsSearchEmpty(text === "");
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault();
            setRazaoData([]);
            fetchModalMesesData(mesConsulta, mesConsultaFinal);

        }
    };

    function filtraRazao(searchRazaoText) {
        const filteredRazao = razaoDataMemory.filter(registro => {
            let registroLowercase = (registro.historico).toLowerCase();

            let searchTermLowercase = searchRazaoText.toLowerCase();

            return registroLowercase.indexOf(searchTermLowercase) > -1 ||
                registro.centrodecusto_id.includes(searchRazaoText) ||
                registro.data.includes(searchRazaoText) ||
                (registro.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' C').includes(searchRazaoText) ||
                (registro.debito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' D').includes(searchRazaoText);
        });
        setRazaoData(filteredRazao)
    }

    async function loadMyUsers() {
        if (!myUsers) {
            var res = await api.get('/usuarios', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            var myArr = []
            res.data.reduce((acc, cv) => { if (cv.id !== cookies.usuario.id) { myArr.push({ label: cv.nome, value: cv.id }) } }, 0)
            setMyUsers(myArr)
        }
    }

    useEffect(async () => {
        var arrayUnidades = []
        JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
            arrayUnidades.push({ label: unidade.unidade, value: unidade.id, type: 'unidades' })
        })
        var combosFormated = await api.post('/combos/select', { combos: JSON.parse(sessionStorage.getItem('unidadesMemory')).reduce((acc, cv) => acc = [...acc, cv.combo_id], []) }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        if (combosFormated.status === 400) notify("Erro ao carregar combos!")

        if (combosFormated.data.length > 1) {
            var array = [{
                label: 'Unidades',
                options: arrayUnidades
            }, {
                label: 'Combos',
                options: combosFormated?.data,
            }]
        } else {
            var array = [{
                label: 'Unidades',
                options: arrayUnidades
            }]
        }
        setMSLoading(false)
        setUnidades(array)
        loadMyUsers()
    }, [MSLoading, sessionStorage.getItem('unidadesMemory')])

    async function loadData() {
        if ((mesConsulta <= mesConsultaFinal)) {
            dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: true })
            var unidades = "VAZIO"
            var unidadesRawFormat = "VAZIO"
            var nomeUnidades = ""
            selected.map(unidade => {
                if (unidades === "VAZIO") {
                    unidades = unidade.value
                    unidadesRawFormat = "'" + unidade.value + "'"
                    nomeUnidades = unidade.label
                } else {
                    unidades = unidades + ',' + unidade.value
                    unidadesRawFormat = unidadesRawFormat + ',' + "'" + unidade.value + "'"
                    nomeUnidades = nomeUnidades + ', ' + unidade.label
                }
            })
            setUnidadeAtualKey(selected.reduce((acc, cv) => acc += cv.value, ''))

            setUnidadeAtualKey(selected.reduce((acc, cv) => acc += cv.value, ''))

            var organizacao_idRaw = ([...new Set(JSON.parse(sessionStorage.getItem('unidades')).filter(u => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(u.id)).reduce((acc, cv) => acc = [...acc, cv.organizacao_id], []))].reduce((acc, cv) => acc += (cv + ','), ''))
            organizacao_idRaw = organizacao_idRaw.slice(0, -1)

            if (selected.find(d => d.type === 'combos')) {
                // get dre mensal de todas as unidades dos combos
                var allUnidades = selected.reduce((acc, cv) => acc = [...acc, ...cv.unidades_id], [])
                organizacao_idRaw = ([...new Set(JSON.parse(sessionStorage.getItem('unidades')).filter(u => selected.reduce((acc, cv) => acc = [...acc, ...cv.unidades_id], []).includes(u.id)).reduce((acc, cv) => acc = [...acc, cv.organizacao_id], []))].reduce((acc, cv) => acc += (cv + ','), ''))
                organizacao_idRaw = organizacao_idRaw.slice(0, -1)

                var response = await api.post(`/dreMensal/combos/${anoConsulta}/${mesConsulta}/${mesConsultaFinal}/${organizacao_idRaw}?usuario_id=${cookies.usuario.id}`, {
                    unidades: allUnidades
                }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

                setDreData2(response.data.dados)
                setCodigosDre(response.data.modeloDre)

                var allUnidadesRawFormat = "'" + allUnidades.join("','") + "'"

                const contratosReturn = await api.get(`/contratos/ativos/${allUnidadesRawFormat}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                setContratos(contratosReturn.data)

                const comentariosReturn = await api.get(`/getComentarios/${allUnidades.join(',')}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                setComentarios(comentariosReturn.data.comentarios)
                setComentariosSelecionados(comentariosReturn.data.comentarios)
                setDestinatarios(comentariosReturn.data.destinatarios)
                setAnexos(comentariosReturn.data.anexos)

                var registrosResponse = await api.get(`/registrosDreMensalComparativo/${allUnidades.join(',')}/ADR_ROOM,OCC_PERC,PHYSICAL_ROOMS,OCC_ROOMS,ADULTS_IN_HOUSE,CHILDREN_IN_HOUSE,GUEST_IN_HOUSE/${anoConsulta}/${mesConsulta}/${mesConsultaFinal}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })


                const myColabs = await api.get(`/funcionarios/${unidades}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

                setMyNoColab({ ...myNoColab, data: myColabs.data })
                setRegistros(registrosResponse.data)

                dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: false })
                setConsultado(false)

            } else {
                var response = await api.get(`/dreMensal/${unidades}/${anoConsulta}/${mesConsulta}/${mesConsultaFinal}/${organizacao_idRaw}?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

                setDreData2(response.data.dados)
                setCodigosDre(response.data.modeloDre)

                const contratosReturn = await api.get(`/contratos/ativos/${unidadesRawFormat}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                setContratos(contratosReturn.data)


                const comentariosReturn = await api.get(`/getComentarios/${unidades}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                setComentarios(comentariosReturn.data.comentarios)
                setComentariosSelecionados(comentariosReturn.data.comentarios)
                setDestinatarios(comentariosReturn.data.destinatarios)
                setAnexos(comentariosReturn.data.anexos)

                var registrosResponse = await api.get(`/registrosDreMensalComparativo/${unidades}/ADR_ROOM,OCC_PERC,PHYSICAL_ROOMS,OCC_ROOMS,ADULTS_IN_HOUSE,CHILDREN_IN_HOUSE,GUEST_IN_HOUSE/${anoConsulta}/${mesConsulta}/${mesConsultaFinal}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })


                const myColabs = await api.get(`/funcionarios/${unidades}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

                setMyNoColab({ ...myNoColab, data: myColabs.data })
                setRegistros(registrosResponse.data)

                dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: false })
                setConsultado(false)

            }

        } else {
            notify("Data final anterior à data inicial!")
        }
    }

    function handleBase(id) {
        if (id) {
            if (baseVertical.id) {
                document.getElementById('linha-' + baseVertical.id).classList.remove('selectedVertical')
                document.getElementById('linha-' + id).classList.add('selectedVertical')
                setBaseVertical({ id: dreData2.find(d => d.codigodre_id === id).codigodre_id })
            } else {
                document.getElementById('linha-' + id).classList.add('selectedVertical')
                setBaseVertical({ id: dreData2.find(d => d.codigodre_id === id).codigodre_id })
            }
        }
    }

    function variacaoVertical(valor, unidade) {
        if (valor.codigo_id !== baseVertical.id && baseVertical.id && dreData2.find(d => d.unidade_id === unidade && d.codigodre_id === valor.codigo_id) && dreData2.find(d => d.unidade_id === unidade && d.codigodre_id === baseVertical.id)) {
            let cima = dreData2.filter(d => d.unidade_id === unidade && d.codigodre_id === valor.codigo_id).reduce((acc, cv) => acc += cv.valor, 0)
            let baixo = dreData2.filter(d => d.unidade_id === unidade && d.codigodre_id === baseVertical.id).reduce((acc, cv) => acc += cv.valor, 0)
            if (baixo === 0) { return } else { return (cima / baixo).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 }) }
        }
    }

    function BuildForm(prop) {
        var size = prop.size
        var max = 0
        var min = 0
        var arrValoresTaxa = [...document.getElementsByClassName('valorTaxa')]
        arrValoresTaxa.map(element => {
            var valor = element.lastChild.data.replace('.', '')
            valor = valor.replace(',', '.')
            valor = valor.replace('%', '')
            valor = parseFloat(valor)
            if (valor > max) {
                max = valor
            }
            if (valor < min) {
                min = valor
            }
        })
        var formulario = []
        if (size <= 0) {
            size = 1
        }
        for (let i = 1; i <= size; i++) {
            formulario.push(
                <Form.Row style={{ justifyContent: 'center' }}>
                    <Form.Group>
                        {size === i && size !== 1
                            ? <Button variant="light" style={{ backgroundColor: 'transparent' }} onClick={() => setNumeroDeIntervalos(numeroDeIntervalos - 1)}><BiMinusCircle /></Button>
                            : null
                        }
                    </Form.Group>
                    <Form.Group controlId={"formFiltroBottom" + i}>
                        <Form.Control type="number" defaultValue={min} placeholder="Minimo" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control type='text' value={'< Taxa de Variação <='} disabled style={{ backgroundColor: 'transparent', border: 'none' }} />
                    </Form.Group>
                    <Form.Group controlId={"formFiltroTop" + i}>
                        <Form.Control type="number" defaultValue={max} placeholder="Máximo" />
                    </Form.Group>
                    <Form.Group controlId={"formFiltroColorBTN" + i}>
                        <Form.Control
                            type="color"
                            style={{ width: 'revert' }}
                            defaultValue="#800000"
                            title="Escolha a cor"
                        />
                    </Form.Group>
                    <Form.Group>
                        {size === i
                            ? <Button variant="light" style={{ backgroundColor: 'transparent' }} onClick={() => setNumeroDeIntervalos(numeroDeIntervalos + 1)}><BiPlusCircle /></Button>
                            : null
                        }
                    </Form.Group>
                </Form.Row>
            )
        }
        return formulario
    }

    const notify = ($msg) => toast($msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    async function openRazao(codigo, unidadeId, type, valor) {
        dispatch({ type: 'OPEN_LOADING', text: "Buscando registros...", isLoading: true })

        if (type === 'combos') {
            var comboUnidadeId = JSON.parse(sessionStorage.getItem('unidades')).filter(u => u.combo_id === unidadeId).reduce((acc, cv) => acc = [...acc, cv.id], [])
            comboUnidadeId = JSON.stringify(comboUnidadeId)
            await api.get(`/registrorazaomes/${comboUnidadeId}/${codigo}/${mesConsulta}/${anoConsulta}/${mesConsultaFinal}?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => {
                    if (res.data.razao.length) {
                        setRazaoData(res.data.razao)
                        setRazaoDataMemory(res.data.razao)
                        setAnexosRazao(res.data.anexosRazao)
                        setModalShow([true, codigosDre.find(d => d.codigo_id === codigo).descricao, valor])
                        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
                    } else {
                        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
                        notify("Nenhum lançamento registrado!")
                    }
                })

        } else {
            var unidades_idArr = unidadeId.split(',')
            unidades_idArr = JSON.stringify(unidades_idArr)
            await api.get(`/registrorazaomes/${unidades_idArr}/${codigo}/${mesConsulta}/${anoConsulta}/${mesConsultaFinal}?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => {
                    if (res.data.razao.length) {
                        setRazaoData(res.data.razao)
                        setRazaoDataMemory(res.data.razao)
                        setAnexosRazao(res.data.anexosRazao)
                        setModalShow([true, codigosDre.find(d => d.codigo_id === codigo).descricao, valor])
                        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
                    } else {
                        dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
                        notify("Nenhum lançamento registrado!")
                    }
                })

        }
    }

    function hideShow(classname) {
        var arr = [...document.getElementsByClassName(classname)]
        arr.map(d => !d.classList.value.includes('linha-zerada') ? d.hidden = !d.hidden : null)
    }

    function buildAnoSelector() {
        var opt = []
        opt.push(<option value="Ano">Ano</option>)
        for (let i = new Date().getFullYear(); i >= 2019; i--) {
            opt.push(<option value={i}>{i}</option>)
        }
        return opt
    }


    function verificaStatusIconSintetica(conta, unidade) {
        var perguntasId = destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
        var respostasId = comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
        respostasId = [...new Set(respostasId)]

        if (!conta.destaque) {
            if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
            } else if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
            } else if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
            } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && !respostasId.includes(d.id) && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Line style={{ marginLeft: '5px' }} color='red' />)
            } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && respostasId.includes(d.id) && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Line style={{ marginLeft: '5px' }} color='green' />)
            } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Line style={{ marginLeft: '5px' }} color='blue' />)
            }
        }
    }

    function verificaStatusIconAnalitica(conta, unidade) {
        var perguntasId = destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
        var respostasId = comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
        respostasId = [...new Set(respostasId)]

        if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
            return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
        } else if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
            return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
        } else if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && d.unidade_id === unidade.value && d.origem === 'realizado').length > 0) {
            return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
        }
    }

    function todosOsMeses() {
        var meses = []
        for (let i = parseInt(mesConsulta); i <= parseInt(mesConsultaFinal); i++) {
            meses.push(i)
        }
        return meses
    }

    return (
        <div className={"analytics-body analytics-container"}>
            <Container fluid>
                <Row className="rowSelector">
                    <Col xs={0} md={0} lg={2} xl={2}></Col>
                    <Col xs={12} md={6} lg={5} xl={4} style={{ zIndex: 6 }}>
                        <Select
                            isMulti
                            name="colors"
                            options={unidades}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selected}
                            onChange={(e) => {
                                if ([...new Set(e.reduce((acc, cv) => acc = [...acc, cv.type], []))].length > 1) {
                                    var cleanSelect = [e.pop()]
                                    setSelected(cleanSelect)
                                } else {
                                    setSelected(e)
                                }
                            }}
                            closeMenuOnSelect={false}
                        />
                    </Col>
                    <Col xs={4} md={2} lg={1} xl={1}>
                        <select name="month" id="month" onChange={e => setMesConsulta(e.target.value)}>
                            <option value="Mes">Início</option>
                            <option value="01">Janeiro</option>
                            <option value="02">Fevereiro</option>
                            <option value="03">Março</option>
                            <option value="04">Abril</option>
                            <option value="05">Maio</option>
                            <option value="06">Junho</option>
                            <option value="07">Julho</option>
                            <option value="08">Agosto</option>
                            <option value="09">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                    </Col>
                    <Col xs={4} md={2} lg={1} xl={1}>
                        <select name="month" id="month" onChange={e => setMesConsultaFinal(e.target.value)}>
                            <option value="Mes">Fim</option>
                            <option value="01">Janeiro</option>
                            <option value="02">Fevereiro</option>
                            <option value="03">Março</option>
                            <option value="04">Abril</option>
                            <option value="05">Maio</option>
                            <option value="06">Junho</option>
                            <option value="07">Julho</option>
                            <option value="08">Agosto</option>
                            <option value="09">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                    </Col>
                    <Col xs={4} md={1} lg={1} xl={1} >
                        <select name="ano" id="ano" onChange={e => setAnoConsulta(e.target.value)}>
                            {buildAnoSelector()}
                        </select>
                    </Col>
                    <Col xs={4} md={1} lg={1} xl={1}>
                        <Button variant="secondary" onClick={() => loadData()}>Consultar</Button>
                    </Col>
                    <Col xs={0} md={0} lg={1} xl={1}></Col>
                    <Col xs={12} md={1} lg={1} xl={1} hidden={consultado}>
                        <Button variant="dark" onClick={() => setModalFilterShow([true])}><BiFilterAlt /></Button>
                    </Col>
                </Row>
            </Container>
            <Table bordered hover size='sm' style={{ fontSize: 'small' }} className="analytics-table">
                <thead style={sticky}>
                    <tr style={{ backgroundColor: 'white' }}>
                        {mesConsulta && mesConsultaFinal
                            ? mesConsulta === mesConsultaFinal
                                ? <td colSpan={selected.length + 2} style={{ fontSize: 'x-large' }} >DRE Analítico de {meses[parseInt(mesConsulta)]} de {anoConsulta}</td>
                                : <td colSpan={selected.length + 2} style={{ fontSize: 'x-large' }}>DRE Analítico de {meses[parseInt(mesConsulta)]} até {meses[parseInt(mesConsultaFinal)]} de {anoConsulta}</td>
                            : <td colSpan={selected.length + 2} style={{ fontSize: 'x-large' }}>DRE Analítico de ____ até ____ de ____</td>
                        }
                    </tr>
                    <tr style={{ backgroundColor: 'white' }}><td colSpan={selected.length + 2} style={{ fontSize: 'large' }}>Comparativo entre: {selected.map(unidade => { return (unidade.label + " ") })}</td></tr>
                    <tr style={{ fontSize: 'medium', cursor: 'default' }}>
                        <td className="analytics-table-subtitle stickyCollumns" style={{ zIndex: 4, background: '#119e91' }} >Contas</td>
                        {selected.map(unidade => {
                            return (<td className="analytics-table-subtitle">{unidade.label}
                                <FaChartLine style={{ width: 20, cursor: "pointer" }} onClick={() => { fetchModalMesesData(mesConsulta, mesConsultaFinal, unidade.value); setUnidadeName(unidade.label) }} />
                            </td>)
                        })}
                    </tr>
                    <tr hidden={filtro.noColab} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">Nº de Colaboradores
                            <DropdownButton
                                as={ButtonGroup}
                                id={`dropdown-button-drop-Colab`}
                                size="sm"
                                variant="primary"
                                title={myNoColab.active}
                            >
                                <Dropdown.Item eventKey="1" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Almoxarifado' })}>Almoxarifado</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Compras' })}>Compras</Dropdown.Item>
                                <Dropdown.Item eventKey="3" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Controladoria' })}>Controladoria</Dropdown.Item>
                                <Dropdown.Item eventKey="4" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Cozinha' })}>Cozinha</Dropdown.Item>
                                <Dropdown.Item eventKey="5" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Eventos' })}>Eventos</Dropdown.Item>
                                <Dropdown.Item eventKey="6" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Limpeza' })}>Limpeza</Dropdown.Item>
                                <Dropdown.Item eventKey="7" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Marketing' })}>Marketing</Dropdown.Item>
                                <Dropdown.Item eventKey="8" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Tecnologia da Informação' })}>Tecnologia da Informação</Dropdown.Item>
                                <Dropdown.Item eventKey="9" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Vendas' })}>Vendas</Dropdown.Item>
                                <Dropdown.Item eventKey="10" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Recepção' })}>Recepção</Dropdown.Item>
                                <Dropdown.Item eventKey="11" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Governança' })}>Governança</Dropdown.Item>
                                <Dropdown.Item eventKey="12" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Manutenção' })}>Manutenção</Dropdown.Item>
                                <Dropdown.Item eventKey="13" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Gerencia' })}>Gerencia</Dropdown.Item>
                                <Dropdown.Item eventKey="14" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Restaurante' })}>Restaurante</Dropdown.Item>
                                <Dropdown.Item eventKey="15" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Portaria' })}>Portaria</Dropdown.Item>
                                {/* <Dropdown.Item eventKey="16" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Afastados' })}>Afastados</Dropdown.Item> */}
                                <Dropdown.Item eventKey="17" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Total' })}>Total</Dropdown.Item>
                            </DropdownButton>
                        </td>
                        {selected.map(unidade => {
                            var filtrado = myNoColab.data.filter(d => unidade.value === d.unidade_id)
                            switch (myNoColab.active) {
                                case 'Almoxarifado':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.almoxarifado, 0)
                                    break;
                                case 'Compras':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.compras, 0)
                                    break;
                                case 'Controladoria':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.controladoria, 0)
                                    break;
                                case 'Cozinha':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.cozinha, 0)
                                    break;
                                case 'Eventos':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.eventos, 0)
                                    break;
                                case 'Limpeza':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.limpeza, 0)
                                    break;
                                case 'Marketing':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.marketing, 0)
                                    break;
                                case 'Tecnologia da Informação':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.tecnologiadainformacao, 0)
                                    break;
                                case 'Vendas':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.vendas, 0)
                                    break;
                                case 'Recepção':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.recepcao, 0)
                                    break;
                                case 'Governança':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.governanca, 0)
                                    break;
                                case 'Manutenção':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.manutencao, 0)
                                    break;
                                case 'Gerencia':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.gerencia, 0)
                                    break;
                                case 'Restaurante':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.restaurante, 0)
                                    break;
                                case 'Portaria':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.portaria, 0)
                                    break;
                                case 'Afastados':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.afastados, 0)
                                    break;
                                case 'Total':
                                    var valor = filtrado.reduce((acc, cv) => acc += cv.total, 0)
                                    break;

                                default:
                                    break;
                            }
                            return <td>{valor}</td>
                        })}
                    </tr>
                    <tr hidden={filtro.txRO} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                        <td className="analytics-dre stickyCollumns">Taxa de Ocupação</td>
                        {selected.map(unidade => {
                            var filtrado = registros.filter(d => 'OCC_PERC' === d.codigo_id && unidade.value === d.unidade_id)
                            var total = filtrado.reduce((acc, atual) => {
                                return acc + atual.valor
                            }, 0);
                            let qtd = filtrado.length
                            if (qtd > 0) {
                                return <td>{((total / qtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                            } else {
                                <td>0%</td>
                            }
                        })}
                    </tr>
                    <tr hidden={filtro.adr} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">ADR</td>
                        {selected.map(unidade => {
                            var filtrado = registros.filter(d => 'ADR_ROOM' === d.codigo_id && unidade.value === d.unidade_id)
                            var total = filtrado.reduce((acc, atual) => {
                                return acc + atual.valor
                            }, 0);
                            let qtd = filtrado.length
                            if (qtd > 0) {
                                return <td>{(total / qtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            } else {
                                <td>R$ 0,00</td>
                            }
                        })}
                    </tr>
                    <tr hidden={filtro.trih} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">Total Room In Hotel</td>
                        {selected.map(unidade => {
                            if (unidade.type === 'combos') return
                            return <td>{(JSON.parse(sessionStorage.getItem('unidades')).find(d => d.id === unidade.value).uhs) + " UHs"}</td>
                        })}
                    </tr>
                    <tr hidden={filtro.rn} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">Room Nights</td>
                        {selected.map(unidade => {
                            var filtrado = registros.filter(d => 'PHYSICAL_ROOMS' === d.codigo_id && unidade.value === d.unidade_id)
                            var total = filtrado.reduce((acc, atual) => {
                                return acc + atual.valor
                            }, 0);
                            let qtd = filtrado.length
                            if (qtd > 0) {
                                return <td>{(total * qtd) + " RNs"}</td>
                            } else {
                                <td>0 RNs</td>
                            }
                        })}
                    </tr>
                    <tr hidden={filtro.ro} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">Rooms Occupied</td>
                        {selected.map(unidade => {
                            var filtrado = registros.filter(d => 'OCC_ROOMS' === d.codigo_id && unidade.value === d.unidade_id)
                            var total = filtrado.reduce((acc, atual) => {
                                return acc + atual.valor
                            }, 0);
                            let qtd = filtrado.length
                            if (qtd > 0) {
                                return <td>{(total) + ' UHs'}</td>
                            } else {
                                <td>0 UHs</td>
                            }
                        })}
                    </tr>
                    <tr hidden={filtro.iha} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">In-House Adults</td>
                        {selected.map(unidade => {
                            var filtrado = registros.filter(d => 'ADULTS_IN_HOUSE' === d.codigo_id && unidade.value === d.unidade_id)
                            var total = filtrado.reduce((acc, atual) => {
                                return acc + atual.valor
                            }, 0);
                            let qtd = filtrado.length
                            if (qtd > 0) {
                                return <td>{(total)} <VscPerson /></td>
                            } else {
                                <td>0 <VscPerson /></td>
                            }
                        })}
                    </tr>
                    <tr hidden={filtro.ihc} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">In-House Children</td>
                        {selected.map(unidade => {
                            var filtrado = registros.filter(d => 'CHILDREN_IN_HOUSE' === d.codigo_id && unidade.value === d.unidade_id)
                            var total = filtrado.reduce((acc, atual) => {
                                return acc + atual.valor
                            }, 0);
                            let qtd = filtrado.length
                            if (qtd > 0) {
                                return <td>{(total)} <MdChildCare /></td>
                            } else {
                                <td>0 <MdChildCare /></td>
                            }
                        })}
                    </tr>
                    <tr hidden={filtro.tihp} style={{ fontWeight: 700, fontSize: 'small', cursor: 'default', background: '#e8e8e8' }}>
                        <td className="analytics-dre stickyCollumns">Total In-House People</td>
                        {selected.map(unidade => {
                            var filtrado = registros.filter(d => 'GUEST_IN_HOUSE' === d.codigo_id && unidade.value === d.unidade_id)
                            var total = filtrado.reduce((acc, atual) => {
                                return acc + atual.valor
                            }, 0);
                            let qtd = filtrado.length
                            if (qtd > 0) {
                                return <td>{(total)} <BsPeople /></td>
                            } else {
                                <td>0 <BsPeople /></td>
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {codigosDre.map(codigo => {
                        var classes = 'linha-' + codigo.contasuperior
                        if ((codigo.composicao && dreData2.filter(d => codigo.composicao.includes(d.codigodre_id)).reduce((acc, cv) => acc += cv.valor, 0) === 0)) {
                            classes = classes + ' linha-zerada'
                        }
                        if ((codigo.composicao === null && dreData2.filter(d => d.codigodre_id === codigo.codigo_id).reduce((acc, cv) => acc += cv.valor, 0) === 0)) {
                            classes = classes + ' linha-zerada'
                        }
                        return (
                            <tr key={UnidadeAtualKey + 'linha-' + codigo.codigo_id} id={'linha-' + codigo.codigo_id} hidden={classes.includes('linha-zerada') ? true : codigo.nivel === 2 ? true : false}
                                onClick={!codigo.destaque && codigo.composicao ? () => { hideShow('linha-' + codigo.codigo_id) } : null} className={classes} style={codigo.destaque ? { fontSize: 'small', background: '#ebeaea', whiteSpace: 'nowrap', fontWeight: 'bold' } : { whiteSpace: 'nowrap', fontSize: 'small' }}>
                                <td onClick={contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? () => setModalContratosShow([true, codigo.descricao, codigo.codigo_id]) : null} className="analytics-dre stickyCollumns" style={codigo.destaque ? { fontSize: 'small', background: 'inherit', whiteSpace: 'nowrap', fontWeight: 'bold' } : { whiteSpace: 'nowrap', fontSize: 'small' }}>{contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? <GrDocument /> : null} {codigo.codigo_id === codigo.descricao ? codigo.codigo_id : contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? codigo.codigo_id + ' - ' + codigo.descricao : '• ' + codigo.codigo_id + ' - ' + codigo.descricao}</td>
                                {selected.map(unidade => {
                                    const valorCalculado = dreData2?.filter(d => d.unidade_id === unidade.value && codigo.codigo_id === d.codigodre_id)?.reduce((acc, cv) => acc += cv.valor, 0)
                                    return (<td
                                        onClick={codigo.composicao === null && codigo.codigo_id !== codigo.descricao ? () => openRazao(codigo.codigo_id, unidade.value, unidade.type, valorCalculado) : null}
                                        onContextMenu={codigo.composicao === null ? (e) => { e.preventDefault(); openRazao(codigo.codigo_id, unidade.value, unidade.type, valorCalculado) } : codigo.destaque === true ? null : (e) => { e.preventDefault(); setSelectedAtual([unidade]); setComentariosSelecionados(comentarios.filter(c => c.unidade_id === unidade.value)); setModalComentariosShow([true, codigo.descricao, codigo, todosOsMeses(), "realizado"]); setNivelContaAtual('sintetica') }}
                                    >
                                        {codigo.composicao === null ? valorCalculado?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                            : dreData2.filter(d => d.unidade_id === unidade.value && codigo.composicao.includes(d.codigodre_id)).reduce((acc, cv) => acc += cv.valor, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        {codigo.composicao ? verificaStatusIconSintetica(codigo, unidade) : verificaStatusIconAnalitica(codigo, unidade)}
                                        <div className="valorTaxaVertical" hidden={variacaoHidden.vertical}>{variacaoVertical(codigo, unidade.value)}</div></td>)
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <ModalContratos contratos={contratos} modalContratosShow={modalContratosShow} onClickButton={() => setModalContratosShow([false, null, null])} open={modalContratosShow[0]} onClose={() => setModalContratosShow([false, null, null])} title='Contratos'
            />
            <Modal
                show={modalFilterShow[0]}
                onHide={() => setModalFilterShow([false])}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='lg'
                scrollable
            >
                <Modal.Header closeButton style={{ gap: '15px' }}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="danger" style={{ marginRight: '8px' }} disabled><BiFilterAlt /></Button> Filtro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => { setFiltro({ ...filtro, fix: !filtro.fix }); if (!filtro.fix) { setSticky({}) } else { setSticky({ position: 'sticky', top: 0 }) } }} >
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.fix} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Fixar cabeçalho'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => { if (!variacaoHidden.vertical && baseVertical.id !== null) { document.getElementById('linha-' + baseVertical.id).classList.remove('selectedVertical'); setBaseVertical({ id: null }) }; setVariacaoHidden({ ...variacaoHidden, vertical: !variacaoHidden.vertical }) }} >
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!variacaoHidden.vertical} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Mostrar variação vertical'} disabled />
                            </InputGroup>
                        </Col>
                        <Col hidden={selected.find(d => d.type === 'combos')}>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, txRO: !filtro.txRO })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.txRO} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Taxa de Ocupação'} disabled />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, adr: !filtro.adr })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.adr} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Diária média (ADR)'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, trih: !filtro.trih })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.trih} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Número de UHs'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, rn: !filtro.rn })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.rn} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Room Nights'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, ro: !filtro.ro })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.ro} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'UHs Ocupadas'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, iha: !filtro.iha })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.iha} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Adultos In-House'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, ihc: !filtro.ihc })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.ihc} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Crianças In-House'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, tihp: !filtro.tihp })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.tihp} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Total de pessoas In-House'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setFiltro({ ...filtro, noColab: !filtro.noColab })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.noColab} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Numero de Colaboradores'} disabled />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Form id="formFiltroDRE" hidden={variacaoHidden.horizontal}
                    // onChange={(e) => styleFilter(e)} 
                    >
                        <BuildForm size={numeroDeIntervalos} />
                    </Form>
                    <Row xs={2}>
                        <Form.Group as={Col} hidden={variacaoHidden.vertical}>
                            <Form.Label>Conta para comparação:</Form.Label>
                            <Form.Control as="select" onChange={e => handleBase(e.target.value)}>
                                <option value={null}>Selecione uma conta</option>
                                <optgroup label="Contas Sintéticas">
                                    {codigosDre.filter(d => d.nivel === 1).map(d => (<option value={d.codigo_id}>{d.descricao}</option>))}
                                </optgroup>
                                <optgroup label="Contas Analíticas">
                                    {codigosDre.filter(d => d.nivel === 2).map(d => (<option value={d.codigo_id}>{d.descricao}</option>))}
                                </optgroup>
                            </Form.Control>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' onClick={() => setModalFilterShow([false])}>Filtrar</Button>
                </Modal.Footer>
            </Modal>
            <ModalRazao
                loadData={loadData}
                open={modalShow[0]}
                onClose={() =>
                    setModalShow([0])
                }
                onClickButton={() =>
                    setModalShow([0])
                }
                title={modalShow[1]}
                searchInput={
                    <FormControl
                        type="search"
                        placeholder="Buscar registro"
                        aria-label="searchRazaoBar"
                        aria-describedby="basic-addon1"
                        value={searchRazaoText}
                        onChange={e => { setSearchRazaoText(e.target.value) }}
                        onBlur={e => { filtraRazao(e.target.value); }}
                    />
                }
                setRazaoData={setRazaoData}
                anexosRazao={anexosRazao}
                comentarios={comentarios}
                notify={notify}
                razaoData={razaoData}
                setModalNovoComentarios={setModalNovoComentarios}
                setModalComentariosShow={setModalComentariosShow}
                setNivelContaAtual={setNivelContaAtual}
                modalShow={modalShow[2]}
            />
            <ModalRazaoAllContas
                offset={offset}
                fetchModalMesesData={fetchModalMesesData}
                selectedMonth={mesConsultaFinal}
                open={modalRazaoAllMonths}
                onClose={() => { setModalRazaoAllMonths(false); setRazaoData([]); setOffset(0); }
                }
                onClickButton={() => { setModalRazaoAllMonths(false); setRazaoData([]); setOffset(0); }
                }
                title={unidadeName}
                isSearchEmpty={isSearchEmpty}
                isLoading={isLoading}
                searchInput={
                    <FormControl
                        type="search"
                        id='searchInput'
                        placeholder="Buscar registro"
                        aria-label="searchRazaoBar"
                        aria-describedby="basic-addon1"
                        onChange={(event) => { handleSearchChange(event?.target?.value); setOffset(0); }}
                        onBlur={() => { setRazaoData([]); fetchModalMesesData(mesConsulta, mesConsultaFinal); }}
                        onKeyDown={(event) => { handleKeyDown(event) }}
                    />
                }
                setRazaoData={setRazaoData}
                anexosRazao={anexosRazao}
                comentarios={comentarios}
                notify={notify}
                razaoData={razaoData}
                setModalNovoComentarios={setModalNovoComentarios}
                setModalComentariosShow={setModalComentariosShow}
                setNivelContaAtual={setNivelContaAtual}
                totalSaldo={totalSaldo}
                modalShow={modalShow[2]}
            />
            <ModalComentarios anexos={anexos} comentarios={comentariosSelecionados}
                destinatarios={destinatarios} modalComentariosShow={modalComentariosShow} modalShow={modalShow} notify={notify} onClickButton={() => (setComentariosSelecionados(comentarios), setModalComentariosShow([false, null, { id: null }]))} onClose={() => (setComentariosSelecionados(comentarios), setModalComentariosShow([false, null, { id: null }]))}
                open={modalComentariosShow[0]} razaoData={razaoData} setModalNovoComentarios={setModalNovoComentarios} setModalRespostaVisible={setModalRespostaVisible}
                setNivelContaAtual={setNivelContaAtual} title='Comentarios' />

            <ModalNovoComentario
                anoConsulta={anoConsulta}
                loadData={loadData}
                title='Adicionar Comentário'
                onClose={() => setModalNovoComentarios([false])}
                open={modalNovoComentarios[0]}
                modalComentariosShow={modalComentariosShow}
                modalNovoComentarios={modalNovoComentarios}
                nivelContaAtual={nivelContaAtual}
                onClickButton={() => { setModalNovoComentarios([false]); setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
                selected={selectedAtual}
                setToolipText={setToolipText} tooltipText={tooltipText}
                notify={notify}

            />
            <ModalRespostas
                anoConsulta={anoConsulta}
                selected={selectedAtual}
                loadData={loadData}
                setToolipText={setToolipText}
                tooltipText={tooltipText}
                modalRespostaVisible={modalRespostaVisible}
                notify={notify}
                open={modalRespostaVisible[0]}
                onClose={() => setModalRespostaVisible([false, { nome: '', comentario: '' }])}
                onClickButton={() => { setModalRespostaVisible([false, { nome: '', comentario: '' }]);; setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
                title='Adicione Resposta'
                modalComentariosShow={modalComentariosShow}
                nivelContaAtual={nivelContaAtual} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                style={{ zIndex: 1000000 }}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover />
        </div>
    )
}