import React from "react";
export const UserProfileContext = React.createContext();

export const UserProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = React.useState(null);

  const updateProfile = (data) => {
    setProfileData(data);
  };

  return (
    <UserProfileContext.Provider value={{ profileData, updateProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};
