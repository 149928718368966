// styles.ts
import styled from 'styled-components';

export const DashboardContainer = styled.div`
  background: #fff;
  color: black;
  padding: 20px;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;


export const RowSpinner = styled.div`
  display: flex;
align-items: center;
  justify-content: center;
  margin: 40px;
`;


export const DataItem = styled.div`
  width: calc(25% - 10px); 
  margin-bottom: 10px;
  background: #F7F7F7;
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;

  @media (max-width: 760px) {
    width: 100%; 
    margin-bottom: 20px; 
  }
`;

export const Title = styled.h2`
  font-size: 1rem;
  margin: 0;
  color: black
`;

export const Value = styled.div`
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

export const PercentageRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Percentage = styled.span`
  font-size: 1rem;
  padding: 2px 5px;
  border-radius: 3px;
`;

export const Select = styled.select`
  padding: 5px;
  margin-right: 10px;
  background: #F7F7F7;
  color: black;
  border: 1px solid rgba(0,0,0,.125);

  @media (max-width: 760px) {
    width: 100%; 
    margin-bottom: 10px; 
  }
`;

export const SelectDateContainer = styled.div`
gap: 10;
`

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: stretch; 
  }
`;
