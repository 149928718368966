import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import sha256 from "crypto-js/sha256";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import packageInfo from "../../../package.json";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.css";

import api from "../../services/api";

import icon from "../../assets/analyticsicon.png";

import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

export default function Login() {
  const [, setCookie, removeCookie] = useCookies(["usuario"]);

  var history = useHistory();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [senhaCrypto, setSenhaCrypto] = useState("");

  const [esqueciSenha, setEsqueciSenha] = useState("");

  const [show, setShow] = useState(false);
  const [alteraSenhaShow2, setAlteraSenhaShow2] = useState(false);
  const [novaSenha, setNovaSenha] = useState("");
  const [novaSenha2, setNovaSenha2] = useState("");
  const [err, setErr] = useState([true, true, true]);
  const [isLoggin, setIsLoggin] = useState(true);
  const [urlParams, setUrlParams] = useState(null);
  const [visible, setSenhaVisible] = useState("password");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose3 = () => setAlteraSenhaShow2(false);

  async function esqueciSenhaHandler() {
    await api.get(`/esqueciSenha/${esqueciSenha}`).then((res) => {
      setAlteraSenhaShow2(false);
      setShow(false);
    });
  }

  async function alterarSenhaHandler2(pin) {
    if (pin) {
      await api.get(`/verificaPin/${pin}`).then((res) => {
        if (res.data === "ok") {
          setAlteraSenhaShow2(true);
          setShow(false);
        } else {
          notify("Pin incorreto!");
          window.location.search = "";
        }
      });
    }
  }

  async function alterarSenhaHandler() {
    if (novaSenha.length < 8 && novaSenha2.length >= 8) {
      setErr([true, false, true]);
    } else if (novaSenha.length >= 8 && novaSenha2.length < 8) {
      setErr([false, true, true]);
    } else if (novaSenha.length < 8 && novaSenha2.length < 8) {
      setErr([false, false, true]);
    } else if (novaSenha.length >= 8 && novaSenha2.length >= 8) {
      setErr([true, true, true]);
      if (novaSenha === novaSenha2) {
        await api
          .put(
            `/trocaSenha/${urlParams.email}/${sha256(novaSenha2).toString()}`
          )
          .then((res) => {
            if (res.data) {
              setShow(false);
              setAlteraSenhaShow2(false);
              setUrlParams(null);
              window.location.search = "";
              notify("Senha alterada com sucesso!");
            }
          });
      } else {
        setErr([true, true, false]);
      }
    }
  }

  async function loginHandler(e) {
    setIsLoggin(!isLoggin);
    e.preventDefault();
    try {
      await api
        .get(`/usuarioLogado/${email.toLowerCase()}/${sha256(senhaCrypto)}`)
        .then(async (res) => {
          if (res.data.length !== 0) {
            if (res.data[0].inativo === true) return notify("Usuário inativo!");
            dispatch({ type: "LOGAR_SISTEMA", usuario: res.data[0] });
            setCookie("currentVersion", packageInfo.version, {
              maxAge: 2160000,
            });
            setCookie("usuario", res.data[0], { maxAge: 2160000 });
            sessionStorage.setItem("isDrawerOpen", JSON.stringify(false));
            await api
              .get(`/unidades/${res.data[0].id}`, {
                headers: { authorization: `Bearer ${res.data[0].accessToken}` },
              })
              .then((res) => {
                sessionStorage.setItem("unidades", JSON.stringify(res.data));
                sessionStorage.setItem(
                  "unidadesMemory",
                  JSON.stringify(res.data)
                );
                sessionStorage.setItem(
                  "unidadesCarregadas",
                  JSON.stringify(res.data)
                );
              });
            await api
              .get(`/permissaoCargos/${res.data[0].permissao}`, {
                headers: { authorization: `Bearer ${res.data[0].accessToken}` },
              })
              .then((res) => {
                sessionStorage.setItem(
                  "permissoesCargos",
                  JSON.stringify(res.data)
                );
              });
            await api
              .get(`/permissoes/${res.data[0].permissao}`, {
                headers: { authorization: `Bearer ${res.data[0].accessToken}` },
              })
              .then((res) => {
                sessionStorage.setItem("permissoes", JSON.stringify(res.data));
              });
            history.push(res.data[0].url);
          } else {
            setIsLoggin(true);
            notify("Usuário não encontrado!");
          }
        });
    } catch (error) {
      console.error("error -> " + error);
      if (error.message.includes("406")) {
        setIsLoggin(true);
        return notify("Usuário inativo!");
      }
      if (error.message.includes("417")) {
        setIsLoggin(true);
        return notify("Senha incorreta!");
      }
      setIsLoggin(true);
      notify("Usuário não encontrado!");
    }
  }

  useEffect(() => {
    if (window.location.search !== "") {
      var urlParams = {};
      var match,
        pl = /\+/g, // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
          return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

      // eslint-disable-next-line
      while ((match = search.exec(query)))
        urlParams[decode(match[1])] = decode(match[2]);
      setUrlParams(urlParams);
      alterarSenhaHandler2(urlParams.key);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    removeCookie("usuario");
  }, [removeCookie]);

  const notify = ($msg) =>
    toast($msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  return (
    <div className="login-container">
      <img className="brandicon" src={icon} alt={"Analytics"} />
      <div className="shadeIndex">
        <span className="systemVersion">{packageInfo.version}</span>
      </div>
      <Card style={{ width: "18rem", opacity: 0.9 }}>
        <Card.Header>
          <div className="titulo">Seja bem-vindo!</div>
          <div className="subtitulo">
            Identifique-se para utilizar o sistema:
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={loginHandler}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                size="sm"
                required
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <InputGroup size="sm">
                <Form.Control
                  size="sm"
                  required
                  type={visible}
                  onChange={(e) => {
                    setSenhaCrypto(e.target.value);
                  }}
                  placeholder="Senha"
                />
                {senhaCrypto !== "" ? (
                  <InputGroup.Append
                    onClick={() =>
                      visible === "password"
                        ? setSenhaVisible("text")
                        : setSenhaVisible("password")
                    }
                  >
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      id="basic-addon2"
                    >
                      {visible === "password" ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </InputGroup.Text>
                  </InputGroup.Append>
                ) : null}
              </InputGroup>
            </Form.Group>
            <Button
              size="sm"
              variant="outline-secondary"
              disabled={!isLoggin}
              type="submit"
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={isLoggin}
              />
              <div hidden={!isLoggin}>Entrar</div>
            </Button>
          </Form>
        </Card.Body>
        <Card.Footer className="login-card-footer">
          <Button
            size="sm"
            variant="link"
            className="login-link"
            onClick={handleShow}
          >
            Esqueci minha senha!
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Recuperação de senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Control
                size="sm"
                required
                type="email"
                onChange={(e) => {
                  setEsqueciSenha(e.target.value);
                }}
                placeholder="Email"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleClose}>
            Fechar
          </Button>
          <Button size="sm" variant="primary" onClick={esqueciSenhaHandler}>
            Alterar senha
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={alteraSenhaShow2}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Recuperação de senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <InputGroup>
                <Form.Control
                  size="sm"
                  required
                  type="password"
                  onChange={(e) => {
                    setNovaSenha(e.target.value);
                  }}
                  placeholder="Nova senha"
                />
              </InputGroup>
              <Form.Text hidden={err[1]} className="text-muted">
                Senha precisa ter mais de 8 caracteres.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <Form.Control
                  size="sm"
                  required
                  type="password"
                  onChange={(e) => {
                    setNovaSenha2(e.target.value);
                  }}
                  placeholder="Confirmar nova senha"
                />
              </InputGroup>
              <Form.Text hidden={err[0]} className="text-muted">
                Senha precisa ter mais de 8 caracteres.
              </Form.Text>
              <Form.Text hidden={err[2]} className="text-muted">
                As Senhas não coincidem.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleClose3}>
            Fechar
          </Button>
          <Button size="sm" variant="primary" onClick={alterarSenhaHandler}>
            Alterar senha
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        style={{ zIndex: 1000000 }}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}
