/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Button, Col, Modal, Form } from 'react-bootstrap'

import { useCookies } from 'react-cookie';
// redux
import { useDispatch } from 'react-redux'
// icons
import { MdDelete } from 'react-icons/md'

import api from '../../services/api'

function GourmetControle() {

  const [cookies] = useCookies();
  const [dailyData, setDailyData] = useState([])
  const [monthlyData, setMonthlyData] = useState([])
  const [unidadeFiltro, setUnidadeFiltro] = useState(JSON.parse(sessionStorage.getItem('unidades')).filter(d => d.restaurante)[0].id)
  const [diarioVisivel, setDiarioVisivel] = useState(true)
  const [showDelete, setShowDelete] = useState([false])
  const dispatch = useDispatch()

  useEffect(() => {
    loadData()
  }, [])

  async function loadData() {
    dispatch({ type: 'OPEN_LOADING', text: "Carregando...", isLoading: true })
    await api.get('/registrosgourmetmensal', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
      .then(res => {
        setMonthlyData(res.data)
      })
    await api.get('/registrosgourmetdiario', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
      .then(res => {
        setDailyData(res.data)
      })
    dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
  }

  async function deleteRegistro(tabela, id) {
    if (tabela === 'diario') {
      dispatch({ type: 'OPEN_LOADING', text: "Apagando...", isLoading: true })
      await api.delete(`/registrosgourmetdiario/${id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        .then(res => {
          document.getElementById(id).hidden = true
          setShowDelete([false])
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        })
    } else if (tabela === 'mensal') {
      dispatch({ type: 'OPEN_LOADING', text: "Apagando...", isLoading: true })
      await api.delete(`/registrosgourmetmensal/${id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        .then(res => {
          document.getElementById(id).hidden = true
          setShowDelete([false])
          dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        })
    }
  }

  return (
    <Container fluid>
      <Row style={{ margin: ' 10px 0' }}>
        <Col>
          <Button onClick={() => setDiarioVisivel(!diarioVisivel)}>Registros {diarioVisivel ? "mensais" : "diários"}</Button>
        </Col>
        <Form.Group as={Col} style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <Form.Label size='sm' style={{ float: 'left' }}>Escolha a unidade:</Form.Label>
          <Form.Control size='sm' as="select" onChange={e => setUnidadeFiltro(e.target.value)} defaultValue="Unidade...">
            {JSON.parse(sessionStorage.getItem('unidades')).filter(d => d.restaurante).map(unidade => {
              return <option value={unidade.id}>{unidade.unidade}</option>
            })}
          </Form.Control>
        </Form.Group>
      </Row>
      <Row>
        <Col>
          <Table style={{ backgroundColor: 'white', fontSize: 'small' }} striped bordered hover size="sm" responsive hidden={!diarioVisivel}>
            <thead style={{ backgroundColor: '#119e91', color: 'white' }}>
              <tr><td colSpan='18'>Registros Diários</td></tr>
              <tr>
                <td rowSpan='2'>Ações</td>
                <td rowSpan='2'>Data</td>
                <td rowSpan='2'>Unidae</td>
                <td rowSpan='2'>Nº Hospedes</td>
                <td colSpan='5'>Couvert</td>
                <td colSpan='6'>Receita</td>
                <td colSpan='3'>Taxa de Captação</td>
              </tr>
              <tr>
                <td>Café Incluso</td>
                <td>Café Incluso consumido</td>
                <td>Café Não incluso</td>
                <td>Almoço</td>
                <td>Jantar</td>
                <td>Café</td>
                <td>Almoço</td>
                <td>Jantar</td>
                <td>Banquete</td>
                <td>Bar</td>
                <td>total A&B</td>
                <td>Café</td>
                <td>Almoço</td>
                <td>Jantar</td>
              </tr>
            </thead>
            <tbody>
              {dailyData.filter(d => d.unidade_id === unidadeFiltro).map(registro => {
                return (
                  <tr id={registro.id}>
                    <td style={{ cursor: 'pointer', textAlign: 'center', color: 'red' }} onClick={() => setShowDelete([true, "dia " + registro.data.substring(8, 10) + '/' + registro.data.substring(5, 7) + '/' + registro.data.substring(0, 4), registro.unidade_id, registro.id, 'diario'])}><MdDelete /></td>
                    <td>{registro.data.substring(8, 10) + '/' + registro.data.substring(5, 7) + '/' + registro.data.substring(0, 4)}</td>
                    <td>{registro.unidade_id}</td>
                    <td>{registro.numerohospedes}</td>
                    <td>{registro.cafeincluso}</td>
                    <td>{registro.cafeinclusoconsumido}</td>
                    <td>{registro.cafenaoincluso}</td>
                    <td>{registro.couvertalmoco}</td>
                    <td>{registro.couvertjantar}</td>
                    <td>{registro.receitacafe ? registro.receitacafe.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</td>
                    <td>{registro.receitaalmoco ? registro.receitaalmoco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</td>
                    <td>{registro.receitajantar ? registro.receitajantar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</td>
                    <td>{registro.receitabanquete ? registro.receitabanquete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</td>
                    <td>{registro.receitabar ? registro.receitabar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</td>
                    <td>{registro.receitatotalaeb ? registro.receitatotalaeb.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</td>
                    <td>{registro.taxacaptacaocafe ? registro.taxacaptacaocafe.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 }) : '-'}</td>
                    <td>{registro.taxacaptacaoalmoco ? registro.taxacaptacaoalmoco.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 }) : '-'}</td>
                    <td>{registro.taxacaptacaojantar ? registro.taxacaptacaojantar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 }) : '-'}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Table style={{ backgroundColor: 'white', fontSize: 'small' }} striped bordered hover size="sm" responsive hidden={diarioVisivel}>
            <thead style={{ backgroundColor: '#119e91', color: 'white' }}>
              <tr><td colSpan='14'>Registros Mensais</td></tr>
              <tr>
                <td >Ações</td>
                <td>Data</td>
                <td>Unidade</td>
                <td>Apartamentos Disponíveis</td>
                <td>Apartamentos vendidos</td>
                <td>Horas Trab. Cozinha</td>
                <td>Horas Trab. Rest + Ass.</td>
                <td>Mão de Obra</td>
                <td>Mercadoria Consumida</td>
                <td>Numero de Salas Hotel</td>
                <td>Receita Liquida</td>
                <td>Salas Alugadas</td>
                <td>Taxa Ocupacao</td>
                <td>valorização do Inventário</td>
              </tr>
            </thead>
            <tbody>
              {monthlyData.filter(d => d.unidade_id === unidadeFiltro).map(registro => {
                return (
                  <tr id={registro.id}>
                    <td style={{ cursor: 'pointer', textAlign: 'center', color: 'red' }} onClick={() => setShowDelete([true, "mês " + registro.data.substring(5, 7) + '/' + registro.data.substring(0, 4), registro.unidade_id, registro.id, 'mensal'])}><MdDelete /></td>
                    <td>{registro.data.substring(5, 7) + '/' + registro.data.substring(0, 4)}</td>
                    <td>{registro.unidade_id}</td>
                    <td>{registro.apartamentosdisponiveis}</td>
                    <td>{registro.apartamentosvendidos}</td>
                    <td>{registro.horastrabalhadascozinha}</td>
                    <td>{registro.horastrabalhadasrestmaisass}</td>
                    <td>{registro.maodeobra}</td>
                    <td>{registro.mercadoriaconsumida}</td>
                    <td>{registro.numerodesalashotel}</td>
                    <td>{registro.receitaliquida}</td>
                    <td>{registro.salasalugadasmes}</td>
                    <td>{registro.taxaocupacao}</td>
                    <td>{registro.valorizacaoinvestario}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showDelete[0]} onHide={() => setShowDelete([false])}>
        <Modal.Header closeButton>
          <Modal.Title>Deletar registro</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja deletar o registro do {showDelete[1]} da unidade {showDelete[2] ? JSON.parse(sessionStorage.getItem('unidades')).filter(d => d.restaurante && d.id === showDelete[2])[0].unidade : null} !</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowDelete([false])}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={() => deleteRegistro(showDelete[4], showDelete[3])}>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default GourmetControle;