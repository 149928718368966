import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { IconType } from "react-icons";
import { AiFillSave } from "react-icons/ai";

interface Props {
  onClick: () => void;
  id: string;
  popoverTxt?: string;
  icon?: IconType;
  variant?: string;
}

export const HoverButton: React.FC<Props> = ({ onClick, id, popoverTxt, icon, variant }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{popoverTxt ? popoverTxt : 'Salvar'}</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={popover}>
      <Button
        onClick={onClick}
        style={{ float: "right" }}
        id={id}
        variant={variant ? variant : "outline-primary"}
        size="sm"
      >
        {icon ? icon : <AiFillSave />}
      </Button>
    </OverlayTrigger>
  );
};
