import React from "react";
import RevenueManangerMobile from "./revenueManagerMainMobile.tsx";
import RevenueManangerDesktop from "./revenueManagerMainDesktop.tsx";
import { Desktop, Mobile } from "./styles.ts";

const RevenueMananger: React.FC = () => {
  return (
    <>
      <Desktop>
        <RevenueManangerDesktop />
      </Desktop>
      <Mobile>
        <RevenueManangerMobile />
      </Mobile>
    </>
  );
};

export default RevenueMananger;
