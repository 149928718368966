/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Form, Col } from 'react-bootstrap'
import { useCookies } from 'react-cookie';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../services/api'

// redux
import { useDispatch } from 'react-redux'

import './styles.css';

function RegistroDiarioGourmet() {

    const dispatch = useDispatch()

    const [cookies] = useCookies();

    const notify = ($msg, $time = 5000) => toast($msg, {
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    async function registroHandler(e) {
        e.preventDefault()
        dispatch({ type: 'OPEN_LOADING', text: "Salvando...", isLoading: true })

        if (e.target.formGridNumeroHospedes.value === '') {
            e.target.formGridNumeroHospedes.value = 0
        } else {
            e.target.formGridNumeroHospedes.value = e.target.formGridNumeroHospedes.value.replace(/,/g, '.')
        }
        if (e.target.formGridCafeIncluso.value === '') {
            e.target.formGridCafeIncluso.value = 0
        } else {
            e.target.formGridCafeIncluso.value = e.target.formGridCafeIncluso.value.replace(/,/g, '.')
        }
        if (e.target.formGridCafeInclusoConsumido.value === '') {
            e.target.formGridCafeInclusoConsumido.value = 0
        } else {
            e.target.formGridCafeInclusoConsumido.value = e.target.formGridCafeInclusoConsumido.value.replace(/,/g, '.')
        }
        if (e.target.formGridCafeNaoIncluso.value === '') {
            e.target.formGridCafeNaoIncluso.value = 0
        } else {
            e.target.formGridCafeNaoIncluso.value = e.target.formGridCafeNaoIncluso.value.replace(/,/g, '.')
        }
        if (e.target.formGridCouvertAlmoco.value === '') {
            e.target.formGridCouvertAlmoco.value = 0
        } else {
            e.target.formGridCouvertAlmoco.value = e.target.formGridCouvertAlmoco.value.replace(/,/g, '.')
        }
        if (e.target.formGridCouvertJantar.value === '') {
            e.target.formGridCouvertJantar.value = 0
        } else {
            e.target.formGridCouvertJantar.value = e.target.formGridCouvertJantar.value.replace(/,/g, '.')
        }
        if (e.target.formGridReceitaAlmoco.value === '') {
            e.target.formGridReceitaAlmoco.value = 0
        } else {
            e.target.formGridReceitaAlmoco.value = e.target.formGridReceitaAlmoco.value.replace(/,/g, '.')
        }
        if (e.target.formGridReceitaJantar.value === '') {
            e.target.formGridReceitaJantar.value = 0
        } else {
            e.target.formGridReceitaJantar.value = e.target.formGridReceitaJantar.value.replace(/,/g, '.')
        }
        if (e.target.formGridReceitaCafe.value === '') {
            e.target.formGridReceitaCafe.value = 0
        } else {
            e.target.formGridReceitaCafe.value = e.target.formGridReceitaCafe.value.replace(/,/g, '.')
        }
        if (e.target.formGridReceitaBar.value === '') {
            e.target.formGridReceitaBar.value = 0
        } else {
            e.target.formGridReceitaBar.value = e.target.formGridReceitaBar.value.replace(/,/g, '.')
        }
        if (e.target.formGridReceitaBanquete.value === '') {
            e.target.formGridReceitaBanquete.value = 0
        } else {
            e.target.formGridReceitaBanquete.value = e.target.formGridReceitaBanquete.value.replace(/,/g, '.')
        }

        await api.post('/registrosgourmetdiario', {
            gourmet: {
                id: e.target.formGridUnidade.value + e.target.formGridData.value,
                data: e.target.formGridData.value,
                unidade_id: e.target.formGridUnidade.value,
                usuario_id: cookies.usuario.id,
                numerohospedes: parseInt(e.target.formGridNumeroHospedes.value),
                cafeincluso: parseInt(e.target.formGridCafeIncluso.value),
                cafeinclusoconsumido: parseInt(e.target.formGridCafeInclusoConsumido.value),
                cafenaoincluso: parseInt(e.target.formGridCafeNaoIncluso.value),
                couvertalmoco: parseInt(e.target.formGridCouvertAlmoco.value),
                couvertjantar: parseInt(e.target.formGridCouvertJantar.value),
                receitaalmoco: parseFloat(e.target.formGridReceitaAlmoco.value),
                receitajantar: parseFloat(e.target.formGridReceitaJantar.value),
                receitacafe: parseFloat(e.target.formGridReceitaCafe.value),
                receitabar: parseFloat(e.target.formGridReceitaBar.value),
                receitabanquete: parseFloat(e.target.formGridReceitaBanquete.value),
                receitatotalaeb: parseFloat(e.target.formGridReceitaAlmoco.value) + parseFloat(e.target.formGridReceitaJantar.value) + parseFloat(e.target.formGridReceitaCafe.value) + parseFloat(e.target.formGridReceitaBar.value) + parseFloat(e.target.formGridReceitaBanquete.value),
                taxacaptacaoalmoco: parseInt(e.target.formGridCouvertAlmoco.value) / parseInt(e.target.formGridNumeroHospedes.value),
                taxacaptacaojantar: parseInt(e.target.formGridCouvertJantar.value) / parseInt(e.target.formGridNumeroHospedes.value),
                taxacaptacaocafe: (parseInt(e.target.formGridCafeInclusoConsumido.value) +
                    parseInt(e.target.formGridCafeNaoIncluso.value)) / parseInt(e.target.formGridNumeroHospedes.value),
            }
        },{headers:{'authorization': `Bearer ${cookies.usuario.accessToken}`}}).then(res => {
            e.target = null
            document.getElementById("formGourmetDiario").reset()
            dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
            notify(res.data.mensagem)
        })
    }





    return (
        <div className="registros-container">
            <div className="registros-body">
                <div className="registros-nomeHotel" style={{ textAlign: 'start' }}>Resultado Diário:</div>
                <Form id="formGourmetDiario" onSubmit={registroHandler}>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridUnidade">
                            <Form.Label size='sm' style={{ float: 'left' }}>Unidade:</Form.Label>
                            <Form.Control size='sm' as="select" defaultValue="Unidade...">
                                {JSON.parse(sessionStorage.getItem('unidades')).filter(d=>d.restaurante).map(unidade => {
                                    return <option value={unidade.id}>{unidade.unidade}</option>
                                })}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridData">
                            <Form.Label size='sm' style={{ float: 'left' }}>Data:</Form.Label>
                            <Form.Control size='sm' type="date" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridNumeroHospedes">
                            <Form.Label size='sm' style={{ float: 'left' }}>Número de Hospedes:</Form.Label>
                            <Form.Control size='sm' placeholder="Nº hospedes" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridCafeIncluso">
                            <Form.Label size='sm' style={{ float: 'left' }}>Café da manhã incluso:</Form.Label>
                            <Form.Control size='sm' placeholder="Café da manhã incluso" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridCafeNaoIncluso">
                            <Form.Label size='sm' style={{ float: 'left' }}>Café da manhã não incluso:</Form.Label>
                            <Form.Control size='sm' placeholder="Café da manhã não incluso" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridCafeInclusoConsumido">
                            <Form.Label size='sm' style={{ float: 'left' }}>Café da manhã incluso consumido:</Form.Label>
                            <Form.Control size='sm' placeholder="Café da manhã incluso consumido" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6} controlId="formGridCouvertAlmoco">
                            <Form.Label size='sm' style={{ float: 'left' }}>Couvert Almoço:</Form.Label>
                            <Form.Control size='sm' placeholder="Couvert Almoço" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6} controlId="formGridCouvertJantar">
                            <Form.Label size='sm' style={{ float: 'left' }}>Couvert Jantar:</Form.Label>
                            <Form.Control size='sm' placeholder="Couvert jantar" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={4} lg={4} xl={4} controlId="formGridReceitaCafe">
                            <Form.Label size='sm' style={{ float: 'left' }}>Receita Café da manhã:</Form.Label>
                            <Form.Control size='sm' placeholder="Receita Café da manhã" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={4} lg={4} xl={4} controlId="formGridReceitaAlmoco">
                            <Form.Label size='sm' style={{ float: 'left' }}>Receita Almoço:</Form.Label>
                            <Form.Control size='sm' placeholder="Receita Almoço" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={4} lg={4} xl={4} controlId="formGridReceitaJantar">
                            <Form.Label size='sm' style={{ float: 'left' }}>Receita Jantar:</Form.Label>
                            <Form.Control size='sm' placeholder="Receita jantar" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6} controlId="formGridReceitaBar">
                            <Form.Label size='sm' style={{ float: 'left' }}>Receita Bar:</Form.Label>
                            <Form.Control size='sm' placeholder="Receita Bar" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6} controlId="formGridReceitaBanquete">
                            <Form.Label size='sm' style={{ float: 'left' }}>Receita Banquete:</Form.Label>
                            <Form.Control size='sm' placeholder="Receita Banquete" />
                        </Form.Group>
                    </Form.Row>

                    <Button variant="primary" size='sm' type="submit" style={{ float: 'right' }}>
                        Enviar
                    </Button>
                </Form>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                style={{zIndex: 1000000}}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover />
        </div>
    );
}

export default RegistroDiarioGourmet;