/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { Table, Button, Container, Row, Col } from 'react-bootstrap'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useCookies } from 'react-cookie';
// API
import api from '../../../services/api';

// redux
import { useDispatch } from 'react-redux'

export default function DRE() {

    const dispatch = useDispatch()
    const [cookies] = useCookies();

    const [unidadesSelecionadas, setUnidadesSelecioandas] = useState([])
    const [registros, setRegistros] = useState([])
    const [anoConsulta, setAnoConsulta] = useState([])

    const [unidades, setUnidades] = useState([])
    const [mesConsulta, setMesConsulta] = useState();
    const [mesConsultaFinal, setMesConsultaFinal] = useState();

    const [MSLoading, setMSLoading] = useState(true);
    const [selected, setSelected] = useState([]);

    var meses = ["", "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    useEffect(() => {
        var array = []
        JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
            array.push({ label: unidade.unidade, value: unidade.id })
        })
        setMSLoading(false)
        setUnidades(array)
    }, [MSLoading, sessionStorage.getItem('unidadesMemory')])

    async function loadData() {
        if ((mesConsulta <= mesConsultaFinal)) {
            dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: true })
            var unidades = "VAZIO"
            var nomeUnidades = ""
            selected.map(unidade => {
                if (unidades === "VAZIO") {
                    unidades = unidade.value
                    nomeUnidades = unidade.label
                } else {
                    unidades = unidades + ',' + unidade.value
                    nomeUnidades = nomeUnidades + ', ' + unidade.label
                }
            })
            setUnidadesSelecioandas(nomeUnidades)

            var codigosLista = ['FOOD_BEV_REVENUE', 'ROOM_REVENUE', 'OTHER_REVENUE', 'TOTAL_REVENUE', 'OCC_ROOMS', 'HOUSE_USE_ROOMS', 'DAYUSE_ROOMS', 'PHYSICAL_ROOMS']

            await api.get(`/registrosDreMensal/${unidades}/${codigosLista}/${anoConsulta}/${mesConsulta}/${mesConsultaFinal}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => {
                    var codigoFOOD_BEV_REVENUE = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    var codigoROOM_REVENUE = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    var codigoOTHER_REVENUE = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    var codigoTOTAL_REVENUE = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    var codigoOCC_ROOMS = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    var codigoHOUSE_USE_ROOMS = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    var codigoDAYUSE_ROOMS = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    var codigoPHYSICAL_ROOMS = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0 }
                    res.data.map(registro => {
                        if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                            codigoFOOD_BEV_REVENUE.id = registro.codigo_id
                            codigoFOOD_BEV_REVENUE.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoFOOD_BEV_REVENUE.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoFOOD_BEV_REVENUE.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoFOOD_BEV_REVENUE.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoFOOD_BEV_REVENUE.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoFOOD_BEV_REVENUE.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoFOOD_BEV_REVENUE.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoFOOD_BEV_REVENUE.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoFOOD_BEV_REVENUE.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoFOOD_BEV_REVENUE.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoFOOD_BEV_REVENUE.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoFOOD_BEV_REVENUE.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoFOOD_BEV_REVENUE.dezembro += registro.valor
                            }
                        } else if (registro.codigo_id === 'ROOM_REVENUE') {
                            codigoROOM_REVENUE.id = registro.codigo_id
                            codigoROOM_REVENUE.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoROOM_REVENUE.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoROOM_REVENUE.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoROOM_REVENUE.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoROOM_REVENUE.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoROOM_REVENUE.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoROOM_REVENUE.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoROOM_REVENUE.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoROOM_REVENUE.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoROOM_REVENUE.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoROOM_REVENUE.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoROOM_REVENUE.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoROOM_REVENUE.dezembro += registro.valor
                            }
                        } else if (registro.codigo_id === 'OTHER_REVENUE') {
                            codigoOTHER_REVENUE.id = registro.codigo_id
                            codigoOTHER_REVENUE.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoOTHER_REVENUE.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoOTHER_REVENUE.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoOTHER_REVENUE.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoOTHER_REVENUE.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoOTHER_REVENUE.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoOTHER_REVENUE.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoOTHER_REVENUE.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoOTHER_REVENUE.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoOTHER_REVENUE.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoOTHER_REVENUE.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoOTHER_REVENUE.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoOTHER_REVENUE.dezembro += registro.valor
                            }
                        } else if (registro.codigo_id === 'TOTAL_REVENUE') {
                            codigoTOTAL_REVENUE.id = registro.codigo_id
                            codigoTOTAL_REVENUE.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoTOTAL_REVENUE.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoTOTAL_REVENUE.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoTOTAL_REVENUE.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoTOTAL_REVENUE.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoTOTAL_REVENUE.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoTOTAL_REVENUE.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoTOTAL_REVENUE.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoTOTAL_REVENUE.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoTOTAL_REVENUE.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoTOTAL_REVENUE.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoTOTAL_REVENUE.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoTOTAL_REVENUE.dezembro += registro.valor
                            }
                        } else if (registro.codigo_id === 'OCC_ROOMS') {
                            codigoOCC_ROOMS.id = registro.codigo_id
                            codigoOCC_ROOMS.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoOCC_ROOMS.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoOCC_ROOMS.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoOCC_ROOMS.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoOCC_ROOMS.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoOCC_ROOMS.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoOCC_ROOMS.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoOCC_ROOMS.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoOCC_ROOMS.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoOCC_ROOMS.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoOCC_ROOMS.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoOCC_ROOMS.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoOCC_ROOMS.dezembro += registro.valor
                            }
                        } else if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                            codigoHOUSE_USE_ROOMS.id = registro.codigo_id
                            codigoHOUSE_USE_ROOMS.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoHOUSE_USE_ROOMS.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoHOUSE_USE_ROOMS.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoHOUSE_USE_ROOMS.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoHOUSE_USE_ROOMS.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoHOUSE_USE_ROOMS.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoHOUSE_USE_ROOMS.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoHOUSE_USE_ROOMS.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoHOUSE_USE_ROOMS.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoHOUSE_USE_ROOMS.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoHOUSE_USE_ROOMS.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoHOUSE_USE_ROOMS.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoHOUSE_USE_ROOMS.dezembro += registro.valor
                            }
                        } else if (registro.codigo_id === 'DAYUSE_ROOMS') {
                            codigoDAYUSE_ROOMS.id = registro.codigo_id
                            codigoDAYUSE_ROOMS.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoDAYUSE_ROOMS.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoDAYUSE_ROOMS.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoDAYUSE_ROOMS.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoDAYUSE_ROOMS.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoDAYUSE_ROOMS.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoDAYUSE_ROOMS.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoDAYUSE_ROOMS.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoDAYUSE_ROOMS.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoDAYUSE_ROOMS.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoDAYUSE_ROOMS.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoDAYUSE_ROOMS.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoDAYUSE_ROOMS.dezembro += registro.valor
                            }
                        } else if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                            codigoPHYSICAL_ROOMS.id = registro.codigo_id
                            codigoPHYSICAL_ROOMS.descricao = registro.descricao
                            if (registro.data.substring(5, 7) === '01') {
                                codigoPHYSICAL_ROOMS.janeiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoPHYSICAL_ROOMS.fevereiro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoPHYSICAL_ROOMS.marco += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoPHYSICAL_ROOMS.abril += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoPHYSICAL_ROOMS.maio += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoPHYSICAL_ROOMS.junho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoPHYSICAL_ROOMS.julho += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoPHYSICAL_ROOMS.agosto += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoPHYSICAL_ROOMS.setembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoPHYSICAL_ROOMS.outubro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoPHYSICAL_ROOMS.novembro += registro.valor
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoPHYSICAL_ROOMS.dezembro += registro.valor
                            }
                        }
                    })
                    dispatch({ type: 'CLOSE_LOADING', text: "Analisando...", isLoading: false })
                    //taxa ocupação accor = (rooms occupied (OCC_ROOMS) - houseuse rooms (HOUSE_USE_ROOMS) + day use rooms (DAYUSE_ROOMS))/ inventario total (PHYSICAL_ROOMS)
                    var adr = {
                        id: 'ADR',
                        descricao: 'Diária média',
                        janeiro: (codigoROOM_REVENUE.janeiro / (codigoOCC_ROOMS.janeiro - codigoHOUSE_USE_ROOMS.janeiro + codigoDAYUSE_ROOMS.janeiro)),
                        fevereiro: (codigoROOM_REVENUE.fevereiro / (codigoOCC_ROOMS.fevereiro - codigoHOUSE_USE_ROOMS.fevereiro + codigoDAYUSE_ROOMS.fevereiro)),
                        marco: (codigoROOM_REVENUE.marco / (codigoOCC_ROOMS.marco - codigoHOUSE_USE_ROOMS.marco + codigoDAYUSE_ROOMS.marco)),
                        abril: (codigoROOM_REVENUE.abril / (codigoOCC_ROOMS.abril - codigoHOUSE_USE_ROOMS.abril + codigoDAYUSE_ROOMS.abril)),
                        maio: (codigoROOM_REVENUE.maio / (codigoOCC_ROOMS.maio - codigoHOUSE_USE_ROOMS.maio + codigoDAYUSE_ROOMS.maio)),
                        junho: (codigoROOM_REVENUE.junho / (codigoOCC_ROOMS.junho - codigoHOUSE_USE_ROOMS.junho + codigoDAYUSE_ROOMS.junho)),
                        julho: (codigoROOM_REVENUE.julho / (codigoOCC_ROOMS.julho - codigoHOUSE_USE_ROOMS.julho + codigoDAYUSE_ROOMS.julho)),
                        agosto: (codigoROOM_REVENUE.agosto / (codigoOCC_ROOMS.agosto - codigoHOUSE_USE_ROOMS.agosto + codigoDAYUSE_ROOMS.agosto)),
                        setembro: (codigoROOM_REVENUE.setembro / (codigoOCC_ROOMS.setembro - codigoHOUSE_USE_ROOMS.setembro + codigoDAYUSE_ROOMS.setembro)),
                        outubro: (codigoROOM_REVENUE.outubro / (codigoOCC_ROOMS.outubro - codigoHOUSE_USE_ROOMS.outubro + codigoDAYUSE_ROOMS.outubro)),
                        novembro: (codigoROOM_REVENUE.novembro / (codigoOCC_ROOMS.novembro - codigoHOUSE_USE_ROOMS.novembro + codigoDAYUSE_ROOMS.novembro)),
                        dezembro: (codigoROOM_REVENUE.dezembro / (codigoOCC_ROOMS.dezembro - codigoHOUSE_USE_ROOMS.dezembro + codigoDAYUSE_ROOMS.dezembro))
                    }

                    var taxaOcupacao = {
                        id: 'TO',
                        descricao: 'Taxa Ocupação',
                        janeiro: ((codigoOCC_ROOMS.janeiro - codigoHOUSE_USE_ROOMS.janeiro + codigoDAYUSE_ROOMS.janeiro) / codigoPHYSICAL_ROOMS.janeiro),
                        fevereiro: ((codigoOCC_ROOMS.fevereiro - codigoHOUSE_USE_ROOMS.fevereiro + codigoDAYUSE_ROOMS.fevereiro) / codigoPHYSICAL_ROOMS.fevereiro),
                        marco: ((codigoOCC_ROOMS.marco - codigoHOUSE_USE_ROOMS.marco + codigoDAYUSE_ROOMS.marco) / codigoPHYSICAL_ROOMS.marco),
                        abril: ((codigoOCC_ROOMS.abril - codigoHOUSE_USE_ROOMS.abril + codigoDAYUSE_ROOMS.abril) / codigoPHYSICAL_ROOMS.abril),
                        maio: ((codigoOCC_ROOMS.maio - codigoHOUSE_USE_ROOMS.maio + codigoDAYUSE_ROOMS.maio) / codigoPHYSICAL_ROOMS.maio),
                        junho: ((codigoOCC_ROOMS.junho - codigoHOUSE_USE_ROOMS.junho + codigoDAYUSE_ROOMS.junho) / codigoPHYSICAL_ROOMS.junho),
                        julho: ((codigoOCC_ROOMS.julho - codigoHOUSE_USE_ROOMS.julho + codigoDAYUSE_ROOMS.julho) / codigoPHYSICAL_ROOMS.julho),
                        agosto: ((codigoOCC_ROOMS.agosto - codigoHOUSE_USE_ROOMS.agosto + codigoDAYUSE_ROOMS.agosto) / codigoPHYSICAL_ROOMS.agosto),
                        setembro: ((codigoOCC_ROOMS.setembro - codigoHOUSE_USE_ROOMS.setembro + codigoDAYUSE_ROOMS.setembro) / codigoPHYSICAL_ROOMS.setembro),
                        outubro: ((codigoOCC_ROOMS.outubro - codigoHOUSE_USE_ROOMS.outubro + codigoDAYUSE_ROOMS.outubro) / codigoPHYSICAL_ROOMS.outubro),
                        novembro: ((codigoOCC_ROOMS.novembro - codigoHOUSE_USE_ROOMS.novembro + codigoDAYUSE_ROOMS.novembro) / codigoPHYSICAL_ROOMS.novembro),
                        dezembro: ((codigoOCC_ROOMS.dezembro - codigoHOUSE_USE_ROOMS.dezembro + codigoDAYUSE_ROOMS.dezembro) / codigoPHYSICAL_ROOMS.dezembro)
                    }

                    var revpar = {
                        id: 'revpar',
                        descricao: 'RevPAR',
                        janeiro: taxaOcupacao.janeiro * adr.janeiro,
                        fevereiro: taxaOcupacao.fevereiro * adr.fevereiro,
                        marco: taxaOcupacao.marco * adr.marco,
                        abril: taxaOcupacao.abril * adr.abril,
                        maio: taxaOcupacao.maio * adr.maio,
                        junho: taxaOcupacao.junho * adr.junho,
                        julho: taxaOcupacao.julho * adr.julho,
                        agosto: taxaOcupacao.agosto * adr.agosto,
                        setembro: taxaOcupacao.setembro * adr.setembro,
                        outubro: taxaOcupacao.outubro * adr.outubro,
                        novembro: taxaOcupacao.novembro * adr.novembro,
                        dezembro: taxaOcupacao.dezembro * adr.dezembro
                    }

                    var trevpar = {
                        id: 'trevpar',
                        descricao: 'TRevPAR',
                        janeiro: codigoTOTAL_REVENUE.janeiro / codigoPHYSICAL_ROOMS.janeiro,
                        fevereiro: codigoTOTAL_REVENUE.fevereiro / codigoPHYSICAL_ROOMS.fevereiro,
                        marco: codigoTOTAL_REVENUE.marco / codigoPHYSICAL_ROOMS.marco,
                        abril: codigoTOTAL_REVENUE.abril / codigoPHYSICAL_ROOMS.abril,
                        maio: codigoTOTAL_REVENUE.maio / codigoPHYSICAL_ROOMS.maio,
                        junho: codigoTOTAL_REVENUE.junho / codigoPHYSICAL_ROOMS.junho,
                        julho: codigoTOTAL_REVENUE.julho / codigoPHYSICAL_ROOMS.julho,
                        agosto: codigoTOTAL_REVENUE.agosto / codigoPHYSICAL_ROOMS.agosto,
                        setembro: codigoTOTAL_REVENUE.setembro / codigoPHYSICAL_ROOMS.setembro,
                        outubro: codigoTOTAL_REVENUE.outubro / codigoPHYSICAL_ROOMS.outubro,
                        novembro: codigoTOTAL_REVENUE.novembro / codigoPHYSICAL_ROOMS.novembro,
                        dezembro: codigoTOTAL_REVENUE.dezembro / codigoPHYSICAL_ROOMS.dezembro
                    }

                    setRegistros([taxaOcupacao, adr, revpar, trevpar, codigoROOM_REVENUE, codigoOTHER_REVENUE, codigoFOOD_BEV_REVENUE, codigoTOTAL_REVENUE])
                })
        } else {
            notify("Data final anterior à data inicial!")
        }
    }

    const notify = ($msg) => toast($msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    return (
        <Container fluid>
            <Row className="rowSelector">
                <Col xs={3} md={3} xl={1}>Unidades:</Col>
                <Col xs={9} md={9} xl={4}>
                    <MultiSelect
                        options={unidades}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        className="MultiSelect"
                        isLoading={MSLoading}
                        overrideStrings={{
                            "allItemsAreSelected": "Todos as unidades selecionadas.",
                            "clearSearch": "Limpar busca",
                            "noOptions": "Sem opções",
                            "search": "Buscar",
                            "selectAll": "Selecionar Todas",
                            "selectSomeItems": "Escolha as unidades..."
                        }}
                    />
                </Col>
                <Col xs={3} md={2} xl={1}>Início:</Col>
                <Col xs={9} md={4} xl={1}>
                    <select name="month" id="month" onChange={e => setMesConsulta(e.target.value)}>
                        <option value="Mes">Mês</option>
                        <option value="01">Janeiro</option>
                        <option value="02">Fevereiro</option>
                        <option value="03">Março</option>
                        <option value="04">Abril</option>
                        <option value="05">Maio</option>
                        <option value="06">Junho</option>
                        <option value="07">Julho</option>
                        <option value="08">Agosto</option>
                        <option value="09">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                    </select>
                </Col>
                <Col xs={3} md={2} xl={1}>Fim:</Col>
                <Col xs={9} md={4} xl={1}>
                    <select name="month" id="month" onChange={e => setMesConsultaFinal(e.target.value)}>
                        <option value="Mes">Mês</option>
                        <option value="01">Janeiro</option>
                        <option value="02">Fevereiro</option>
                        <option value="03">Março</option>
                        <option value="04">Abril</option>
                        <option value="05">Maio</option>
                        <option value="06">Junho</option>
                        <option value="07">Julho</option>
                        <option value="08">Agosto</option>
                        <option value="09">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                    </select>
                </Col>
                <Col xs={12} md={12} xl={1}>Ano:</Col>
                <Col xs={12} md={12} xl={1}>
                    <select name="ano" id="ano" onChange={e => setAnoConsulta(e.target.value)}>
                        <option value="Ano">Ano</option>
                        {Array(new Date().getFullYear() - 2018).fill(1).map((d, idx) => <option key={idx} value={new Date().getFullYear() - idx}>{new Date().getFullYear() - idx}</option>)}
                    </select>
                </Col>
                <Col xs={12} md={12} xl={1}>
                    <Button variant="secondary" onClick={() => loadData()}>Consultar</Button>
                </Col>
            </Row>
            <Row>
                <Table bordered responsive hover className="analytics-table">
                    <thead>
                        <tr>
                            {mesConsulta && mesConsultaFinal
                                ? mesConsulta === mesConsultaFinal
                                    ? <td colSpan="15" className="analytics-table-title">Comparativo Mensal de {meses[parseInt(mesConsulta)]}</td>
                                    : <td colSpan="15" className="analytics-table-title">Comparativo Mensal de {meses[parseInt(mesConsulta)]} até {meses[parseInt(mesConsultaFinal)]}</td>
                                : <td colSpan="15" className="analytics-table-title">Comparativo Mensal de _____ até ____</td>
                            }
                        </tr>
                        <tr><td colSpan="15" className="analytics-table-title">{unidadesSelecionadas}</td></tr>
                    </thead>
                    <tbody>
                        <tr style={{ cursor: 'default' }}>
                            <td colSpan='2' className="analytics-table-subtitle" width="20%">Valores</td>
                            {mesConsulta <= 1 && mesConsultaFinal >= 1
                                ? <td className="analytics-table-subtitle">Janeiro</td>
                                : null
                            }
                            {mesConsulta <= 2 && mesConsultaFinal >= 2
                                ? <td className="analytics-table-subtitle">Fevereiro</td>
                                : null
                            }
                            {mesConsulta <= 3 && mesConsultaFinal >= 3
                                ? <td className="analytics-table-subtitle">Março</td>
                                : null
                            }
                            {mesConsulta <= 4 && mesConsultaFinal >= 4
                                ? <td className="analytics-table-subtitle">Abril</td>
                                : null
                            }
                            {mesConsulta <= 5 && mesConsultaFinal >= 5
                                ? <td className="analytics-table-subtitle">Maio</td>
                                : null
                            }
                            {mesConsulta <= 6 && mesConsultaFinal >= 6
                                ? <td className="analytics-table-subtitle">Junho</td>
                                : null
                            }
                            {mesConsulta <= 7 && mesConsultaFinal >= 7
                                ? <td className="analytics-table-subtitle">Julho</td>
                                : null
                            }
                            {mesConsulta <= 8 && mesConsultaFinal >= 8
                                ? <td className="analytics-table-subtitle">Agosto</td>
                                : null
                            }
                            {mesConsulta <= 9 && mesConsultaFinal >= 9
                                ? <td className="analytics-table-subtitle">Setembro</td>
                                : null
                            }
                            {mesConsulta <= 10 && mesConsultaFinal >= 10
                                ? <td className="analytics-table-subtitle">Outubro</td>
                                : null
                            }
                            {mesConsulta <= 11 && mesConsultaFinal >= 11
                                ? <td className="analytics-table-subtitle">Novembro</td>
                                : null
                            }
                            {mesConsulta <= 12 && mesConsultaFinal >= 12
                                ? <td className="analytics-table-subtitle">Dezembro</td>
                                : null
                            }
                        </tr>
                        {registros.map((registro, idx) => {
                            if (registro.id === 'TO') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{isNaN(registro.janeiro) ? '0%' : registro.janeiro.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{isNaN(registro.fevereiro) ? '0%' : registro.fevereiro.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{isNaN(registro.marco) ? '0%' : registro.marco.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{isNaN(registro.abril) ? '0%' : registro.abril.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{isNaN(registro.maio) ? '0%' : registro.maio.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{isNaN(registro.junho) ? '0%' : registro.junho.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{isNaN(registro.julho) ? '0%' : registro.julho.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{isNaN(registro.agosto) ? '0%' : registro.agosto.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{isNaN(registro.setembro) ? '0%' : registro.setembro.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{isNaN(registro.outubro) ? '0%' : registro.outubro.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{isNaN(registro.novembro) ? '0%' : registro.novembro.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{isNaN(registro.dezembro) ? '0%' : registro.dezembro.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                        : null
                                    }
                                </tr>)
                            } else if (registro.id === 'ADR') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{isNaN(registro.janeiro) ? 'R$ 0,00' : registro.janeiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{isNaN(registro.fevereiro) ? 'R$ 0,00' : registro.fevereiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{isNaN(registro.marco) ? 'R$ 0,00' : registro.marco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{isNaN(registro.abril) ? 'R$ 0,00' : registro.abril.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{isNaN(registro.maio) ? 'R$ 0,00' : registro.maio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{isNaN(registro.junho) ? 'R$ 0,00' : registro.junho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{isNaN(registro.julho) ? 'R$ 0,00' : registro.julho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{isNaN(registro.agosto) ? 'R$ 0,00' : registro.agosto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{isNaN(registro.setembro) ? 'R$ 0,00' : registro.setembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{isNaN(registro.outubro) ? 'R$ 0,00' : registro.outubro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{isNaN(registro.novembro) ? 'R$ 0,00' : registro.novembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{isNaN(registro.dezembro) ? 'R$ 0,00' : registro.dezembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                </tr>)
                            } else if (registro.id === 'revpar') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{isNaN(registro.janeiro) ? 'R$ 0,00' : registro.janeiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{isNaN(registro.fevereiro) ? 'R$ 0,00' : registro.fevereiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{isNaN(registro.marco) ? 'R$ 0,00' : registro.marco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{isNaN(registro.abril) ? 'R$ 0,00' : registro.abril.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{isNaN(registro.maio) ? 'R$ 0,00' : registro.maio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{isNaN(registro.junho) ? 'R$ 0,00' : registro.junho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{isNaN(registro.julho) ? 'R$ 0,00' : registro.julho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{isNaN(registro.agosto) ? 'R$ 0,00' : registro.agosto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{isNaN(registro.setembro) ? 'R$ 0,00' : registro.setembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{isNaN(registro.outubro) ? 'R$ 0,00' : registro.outubro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{isNaN(registro.novembro) ? 'R$ 0,00' : registro.novembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{isNaN(registro.dezembro) ? 'R$ 0,00' : registro.dezembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                </tr>)
                            } else if (registro.id === 'trevpar') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{isNaN(registro.janeiro) ? 'R$ 0,00' : registro.janeiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{isNaN(registro.fevereiro) ? 'R$ 0,00' : registro.fevereiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{isNaN(registro.marco) ? 'R$ 0,00' : registro.marco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{isNaN(registro.abril) ? 'R$ 0,00' : registro.abril.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{isNaN(registro.maio) ? 'R$ 0,00' : registro.maio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{isNaN(registro.junho) ? 'R$ 0,00' : registro.junho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{isNaN(registro.julho) ? 'R$ 0,00' : registro.julho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{isNaN(registro.agosto) ? 'R$ 0,00' : registro.agosto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{isNaN(registro.setembro) ? 'R$ 0,00' : registro.setembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{isNaN(registro.outubro) ? 'R$ 0,00' : registro.outubro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{isNaN(registro.novembro) ? 'R$ 0,00' : registro.novembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{isNaN(registro.dezembro) ? 'R$ 0,00' : registro.dezembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                </tr>)
                            } else if (registro.id === 'ROOM_REVENUE') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{registro.janeiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{registro.fevereiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{registro.marco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{registro.abril.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{registro.maio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{registro.junho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{registro.julho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{registro.agosto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{registro.setembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{registro.outubro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{registro.novembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{registro.dezembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                </tr>)
                            } else if (registro.id === 'OTHER_REVENUE') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{registro.janeiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{registro.fevereiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{registro.marco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{registro.abril.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{registro.maio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{registro.junho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{registro.julho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{registro.agosto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{registro.setembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{registro.outubro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{registro.novembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{registro.dezembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                </tr>)
                            } else if (registro.id === 'FOOD_BEV_REVENUE') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{registro.janeiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{registro.fevereiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{registro.marco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{registro.abril.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{registro.maio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{registro.junho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{registro.julho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{registro.agosto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{registro.setembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{registro.outubro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{registro.novembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{registro.dezembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                </tr>)
                            } else if (registro.id === 'TOTAL_REVENUE') {
                                return (<tr key={idx}>
                                    <td colSpan='2' className="analytics-dre">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{registro.janeiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{registro.fevereiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{registro.marco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{registro.abril.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{registro.maio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{registro.junho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{registro.julho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{registro.agosto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{registro.setembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{registro.outubro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{registro.novembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{registro.dezembro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        : null
                                    }
                                </tr>)
                            }
                        })}
                    </tbody>
                </Table>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    style={{ zIndex: 1000000 }}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover />
            </Row>
        </Container>
    )
}