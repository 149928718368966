import styled from "styled-components";

export const TittleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    background-color: #e8e8e8;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 4px;
  }
`;

export const Tittle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
`;

export const IconContainer = styled.div`
  font-size: 2em;
  color: #119e91;
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ProfileContainer = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const ProfilePhoto = styled.input`
  width: 220px;
  height: 220px;
  border: 3px solid #ddd;
  margin-bottom: 30px;
`;

export const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const SaveButton = styled.button`
  width: 80%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const EditButton = styled.button`
  width: 80%;
  padding: 10px;
  background-color: white;
  color: #656575;
  border: 1px solid #656575;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #f2f2f2;
    color: #5a5a5a;
    border-color: #5a5a5a;
  }
`;

export const EditButtonPassword = styled.button`
  width: 80%;
  padding: 10px;
  background-color: #1f7bf4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #5aa0f5;
  }
`;

export const CancelButton = styled(SaveButton)`
  background-color: white;
  color: #656575;
  border: 1px solid #656575;
  border-radius: 5px;

  &:hover {
    background-color: #f2f2f2;
    color: #5a5a5a;
    border-color: #5a5a5a;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 250px;
  overflow: hidden;
  cursor: pointer;
`;

export const AvatarImage = styled.img`
  width: 250px;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const EditIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: url("../../assets/user.png") no-repeat center;
  background-size: contain;
`;
