import React, { useEffect, useState } from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../services/api';
import { useCookies } from 'react-cookie';
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import 'dayjs/locale/pt-br'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slide from '@mui/material/Slide';

import { makeStyles } from '@material-ui/core/styles';
import { BsFillPersonLinesFill, } from 'react-icons/bs';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStyles } from '../styles';
import { useMediaQuery } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function NovoColaborador() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory()
    const [cookies] = useCookies([]);

    const [colabAtual, setColabAtual] = useState({ nome: '', email: '', cpf: '', celular: '', sexo: [] })
    const [unidades, setUnidades] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [cargos, setCargos] = useState([])


    const [openAuto, setOpenAuto] = useState(false);
    const [optionsLideres, setOptionsLideres] = useState([]);
    const loading = openAuto && optionsLideres.length === 0;

    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        type: '',
        title: '',
        centroDeCusto: '',
        salarioBase: '',
    });

    const notify = ($msg, $time = 5000) => toast($msg, {
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    const handleClose = () => {
        setDialogValue({
            type: '',
            title: '',
            centroDeCusto: '',
            salarioBase: '',
        });
        toggleOpen(false);
    };

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                await loadLideres()
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!openAuto) {
            setOptionsLideres([]);
        }
    }, [openAuto]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (dialogValue.dial.type === 'departamento') {
            var res = await api.post('/rh/departamentos', { departamento: dialogValue.title, codigocentrodecusto: dialogValue.centroDeCusto, usuario_id: cookies.usuario.id }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

            if (res.status !== 200) return alert('Erro ao criar departamento')

            setDepartamentos([...departamentos, res.data[0]])
            setColabAtual({ ...colabAtual, departamento: res.data[0] })

        } else if (dialogValue.dial.type === 'cargo') {
            var salario = 0
            if (!!dialogValue.salarioBase) salario = dialogValue.salarioBase.replace(/\D/g, '')

            var res = await api.post('/rh/cargos', { cargo: dialogValue.title, salarioBase: salario / 100, usuario_id: cookies.usuario.id }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

            if (res.status !== 200) return alert('Erro ao criar cargo')

            setCargos([...cargos, res.data[0]])
            setColabAtual({ ...colabAtual, cargo: res.data[0] })
        }

        handleClose();
    };

    var colabID = location.state.colab

    useEffect(() => {
        loadColabData(colabID)
        loadDepartamentos()
        loadCargos()
        loadLideres()
        setUnidades(sortArr(JSON.parse(sessionStorage.getItem('unidades')), 'unidade'))
    }, [])

    const loadDepartamentos = async () => {
        var departamentos = await api.get('/rh/departamentos/' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setDepartamentos(departamentos.data)
    }

    const loadCargos = async () => {
        var cargos = await api.get('/rh/cargos/' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setCargos(cargos.data)
    }

    const loadLideres = async () => {
        var lideres = await api.get('/rh/lideres/' + cookies.usuario.id, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        setOptionsLideres(lideres.data)
    }

    const sortArr = (arr, col) => {
        return arr.sort((a, b) => a[col] < b[col] ? -1 : a[col] > b[col] ? 1 : 0)
    }

    const loadColabData = async (colabID) => {
        if (colabID === undefined) return history.goBack()
        if (parseInt(colabID) === -1) return
        var colabData = await api.get('/rh/colaborador/atual/' + colabID, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

        return setColabAtual(colabData.data[0])
    }

    const handleFileChange = (e) => {
        if (e.target.files.length === 0) return setColabAtual({ ...colabAtual, foto: null, fotoFile: null })
        var file = e.target.files[0]
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setColabAtual({ ...colabAtual, foto: reader.result, fotoFile: file })
        }
    }

    const handleEmailChange = (e) => {
        var email = e.target.value
        setColabAtual({ ...colabAtual, email: email })
    }

    const validateEmail = (email) => {
        var emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i
        if (!emailRegex.test(email) && email.length > 0) {
            setColabAtual({ ...colabAtual, email: email, emailError: true })
        } else {
            setColabAtual({ ...colabAtual, email: email, emailError: false })
        }
    }

    const handleCpfChange = (e) => {
        var cpf = e.target.value
        cpf = cpf.replace(/\D/g, '')
        if (cpf.length > 11) {
            cpf = cpf.substring(0, 11)
        }
        if (cpf.length > 9) {
            cpf = cpf.replace(/^(\d\d\d)(\d{3})(\d{3})(\d{0,2}).*/, '$1.$2.$3-$4')
        } else if (cpf.length > 6) {
            cpf = cpf.replace(/^(\d\d\d)(\d{3})(\d{0,3})/, '$1.$2.$3')
        } else if (cpf.length > 3) {
            cpf = cpf.replace(/^(\d\d\d)(\d{0,3})/, '$1.$2')
        } else if (cpf.length > 0) {
            cpf = cpf.replace(/^(\d*)/, '$1')
        }
        if (cpf.length === 11) {
            var cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/
            if (cpfRegex.test(cpf)) {
                setColabAtual({ ...colabAtual, cpf: cpf, cpfError: false })
            } else {
                setColabAtual({ ...colabAtual, cpf: cpf, cpfError: true })
            }
        } else {
            setColabAtual({ ...colabAtual, cpf: cpf, cpfError: false })
        }
    }

    const handleCelularChange = (e) => {
        var celular = e.target.value
        celular = celular.replace(/\D/g, '')
        celular = celular.replace(/^0/, '')
        if (celular.length > 11) {
            celular = celular.substring(0, 11)
        }
        if (celular.length > 10) {
            celular = celular.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
        } else if (celular.length > 6) {
            celular = celular.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
        } else if (celular.length > 2) {
            celular = celular.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
        } else if (celular.length > 0) {
            celular = celular.replace(/^(\d*)/, '($1')
        }
        if (celular.length > 15) {
            celular = celular.substring(0, 15)
        }
        if (celular.length === 15) {
            setColabAtual({ ...colabAtual, celular: celular, celularError: false })
        } else {
            setColabAtual({ ...colabAtual, celular: celular, celularError: true })
        }
    }

    const handleSave = async () => {
        if (colabAtual.nome === null || colabAtual.nome === '') return notify('Nome inválido')
        if (colabAtual.email === null || colabAtual.email === '') return notify('Email inválido')
        if (!!!colabAtual.unidade) return notify('Unidade inválida')
        if (!!!colabAtual.cargo) return notify('Cargo inválido')
        if (!!!colabAtual.departamento) return notify('Departamento inválido')

        if (colabAtual.fotoFile != null) {
            var formData = new FormData()
            formData.append('file', colabAtual.fotoFile)
            var foto = await api.post('/rh/colaborador/profile/upload', formData, { headers: { 'Content-Type': 'multipart/form-data', 'authorization': `bearer ${cookies.usuario.accessToken}` } })
            delete colabAtual.foto
            colabAtual.fotoUrl = foto.data
        }

        var salario = 0
        if (!!colabAtual.salario) salario = parseFloat((colabAtual.salario + '').replace(/\D/g, '')) / 100

        if (!!!colabAtual?.id) {
            await api.post('/rh/colaborador', { colab: { ...colabAtual, salario: salario, usuario_id: cookies.usuario.id } }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => {
                    return history.goBack()
                })
        } else {
            await api.put('/rh/colaborador', { colab: { ...colabAtual, salario: salario, usuario_id: cookies.usuario.id } }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => {
                    return history.goBack()
                })
        }
        setColabAtual({ ...colabAtual, id: null, nome: '', nomeError: false, email: '', emailError: false, cpf: '', cpfError: false, celular: '', celularError: false, unidade: '', unidadeError: false, cargo: '', cargoError: false, departamento: '', departamentoError: false, foto: null, fotoFile: null })
    }

    function handleSalario(e) {
        const valorDigitado = (e + '').replace(/[^\d]/g, ''); // Remover caracteres não numéricos

        if (valorDigitado === '') {
            return ''
        }

        const valorComCasasDecimais = parseFloat(valorDigitado) / 100; // Converter para valor com casas decimais

        const valorFormatado = valorComCasasDecimais.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return valorFormatado
    }

    const handleUnidade = (e) => {
        setColabAtual({ ...colabAtual, unidade: e })
    }

    const handleSexo = (e) => {
        setColabAtual({ ...colabAtual, sexo: e })
    }
    const isMobile = useMediaQuery('(max-width:680px)');

    return (
        <Container
            fluid
            className={classes.card}
        >
            <div className={classes.titleContainer}>
                <div style={{ alignSelf: "baseline" }} className={classes.icon}>
                    <BsFillPersonLinesFill />
                </div>
                <div className={classes.text}>
                    Cadastro de Colaborador {colabAtual.nome}
                </div>
            </div>
            <Row style={{ padding: '50px 30px 0 30px', width: '100%', height: '90%', overflow: 'auto' }}>
                <Col md={4} className={classes.photoCol}>
                    <h4 style={{ color: '#119e91' }}>Adicione sua foto aqui</h4>
                    <input type='file' hidden id='file' onChange={(e) => handleFileChange(e)} />
                    <Avatar style={{ cursor: 'pointer' }} onClick={() => document.getElementById('file').click()} id="profile_picture" variant='rounded' className={classes.small} src={colabAtual.foto} />
                </Col>
                <Col md style={{ alignSelf: 'center' }}>
                    <Row>
                        <Col><TextField size="small" required className={classes.input} id="standard-Nome" value={colabAtual.nome} error={colabAtual.nomeError} onChange={(e) => setColabAtual({ ...colabAtual, nome: e.target.value })} label="Nome Completo" variant="outlined" /></Col>
                    </Row>
                    <Row>
                        <Col><TextField size="small" required className={classes.input} error={colabAtual.emailError} value={colabAtual.email} onBlur={(e) => validateEmail(e.target.value)} onChange={(e) => handleEmailChange(e)} id="standard-Nome" label="Email" variant="outlined" /></Col>
                    </Row>
                    <Row>
                        <Col><TextField size="small" required className={classes.input} id="standard-Nome" label="CPF" error={colabAtual.cpfError} onChange={(e) => handleCpfChange(e)} value={colabAtual.cpf} variant="outlined" /></Col>
                    </Row>
                    <Row>
                        <Col><TextField size="small" className={classes.input} id="standard-Nome" label="Celular" error={colabAtual.celularError} onChange={(e) => handleCelularChange(e)} value={colabAtual.celular} variant="outlined" /></Col>
                    </Row>
                    <Row>
                        <Col>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker format="DD/MM/YYYY" value={dayjs(colabAtual.data_nascimento).add(3, 'hours')} onChange={(e) => setColabAtual({ ...colabAtual, data_nascimento: e.format("YYYY-MM-DD"), nascimento: e.format("DD/MM/YYYY"), nascimentoBD: e.format("YYYY-MM-DD") })} className={classes.input} label="Data de Nascimento" />
                            </LocalizationProvider>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select
                                options={[{ id: 'M', value: 'M', label: "Masculino" }, { id: 'F', value: 'F', label: "Feminino" }, { id: 'NDA', value: 'NDA', label: "Não quis se identificar" }, { id: '+', value: '+', label: "Outros" }]}
                                classNamePrefix="select_colab"
                                className={classes.input}
                                placeholder="Escolha o sexo..."
                                closeMenuOnSelect
                                isClearable
                                value={colabAtual.sexo}
                                onChange={(newValue) => { handleSexo([newValue]) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select
                                options={unidades?.reduce((acc, cv) => acc = [...acc, { label: cv.unidade, id: cv.unidade_id, value: cv.unidade_id }], [])}
                                className={classes.input}
                                classNamePrefix="select_colab"
                                placeholder="Escolha a unidade..."
                                closeMenuOnSelect
                                value={colabAtual.unidade}
                                isClearable
                                onChange={(newValue) => { handleUnidade([newValue]) }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md style={{ alignSelf: 'center' }}>
                    <Row>
                        <Col>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker format="DD/MM/YYYY" value={dayjs(colabAtual.data_admissao).add(3, 'hours')} onChange={(e) => setColabAtual({ ...colabAtual, data_admissao: e.format("YYYY-MM-DD"), admissao: e.format("DD/MM/YYYY"), admissaoBD: e.format("YYYY-MM-DD") })} className={classes.input} label="Data de Admissão" />
                            </LocalizationProvider>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Select
                                options={optionsLideres}
                                className={classes.input}
                                classNamePrefix="select_colab"
                                placeholder="Escolha o lider/chefia..."
                                closeMenuOnSelect
                                value={colabAtual.lider}
                                isClearable
                                onChange={(newValue) => { setColabAtual({ ...colabAtual, chefia_id: newValue.id, lider: newValue }) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CreatableSelect
                                options={departamentos?.reduce((acc, cv) => acc = [...acc, { label: cv.departamento, id: cv.id, value: cv.id }], [])}
                                className={classes.input}
                                classNamePrefix="select_colab"
                                placeholder="Escolha o departamento..."
                                closeMenuOnSelect
                                value={departamentos?.reduce((acc, cv) => acc = [...acc, { label: cv.departamento, id: cv.id, value: cv.id }], []).find(d => d.id === colabAtual.departamento_id)}
                                isClearable
                                onChange={(newValue) => {
                                    if (newValue?.__isNew__) {
                                        toggleOpen(true);
                                        setDialogValue({
                                            dial: {
                                                title: 'Departamento',
                                                type: 'departamento',
                                            },
                                            title: newValue.label,
                                            centroDeCusto: '',
                                        });
                                    } else {
                                        setColabAtual({ ...colabAtual, departamento: [newValue] })
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CreatableSelect
                                options={cargos?.reduce((acc, cv) => acc = [...acc, { label: cv.cargo, id: cv.id, value: cv.id, salario: handleSalario(cv.salario * 100) }], [])}
                                className={classes.input}
                                classNamePrefix="select_colab"
                                placeholder="Escolha o cargo..."
                                closeMenuOnSelect
                                value={cargos?.reduce((acc, cv) => acc = [...acc, { label: cv.cargo, id: cv.id, value: cv.id, salario: handleSalario(cv.salario * 100) }], []).find(c => c.id == colabAtual.cargo_id)}
                                isClearable
                                onChange={(newValue) => {
                                    if (newValue?.__isNew__) {
                                        toggleOpen(true);
                                        setDialogValue({
                                            dial: {
                                                title: 'Cargo',
                                                type: 'cargo',
                                            },
                                            title: newValue.inputValue,
                                            centroDeCusto: '',
                                            salarioBase: '',
                                        });
                                    } else {
                                        setColabAtual({ ...colabAtual, cargo_id: newValue.id, cargo: [newValue] });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                className={classes.input}
                                id="standard-Nome"
                                size="small"
                                label="Salario"
                                error={colabAtual.salarioError}
                                onChange={(e) => setColabAtual({ ...colabAtual, salario: handleSalario(e.target.value) })}
                                value={handleSalario(colabAtual.salario)}
                                helperText={colabAtual?.cargo?.[0]?.salario ? 'Salario Base: ' + colabAtual?.cargo?.[0]?.salario : null}
                                variant="outlined" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                id="standard-Nome"
                                label="Comentário"
                                multiline
                                size="small"
                                maxRows={5}
                                minRows={5}
                                variant="outlined"
                                className={classes.input}
                                value={colabAtual.comentario ? colabAtual.comentario : ''}
                                onChange={(e) => setColabAtual({ ...colabAtual, comentario: e.target.value })}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ padding: '0', width: '100%', height: '10%', overflow: 'auto' }}>
                <Col md style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-end', padding: 20 }}>
                    <Button variant="outline-secondary" style={{ margin: '0 10px', width: isMobile ? '100%' : 'auto' }} onClick={() => history.goBack()}>Voltar</Button>
                    <Button style={{ width: isMobile ? '100%' : 'auto' }} onClick={() => handleSave()}>Salvar</Button>
                </Col>
            </Row>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                fullWidth='true'
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Criar novo {dialogValue?.dial?.type}</DialogTitle>
                    <DialogContent>
                        <Row>
                            <Col>
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    className={classes.input}
                                    required
                                    value={dialogValue.title}
                                    onChange={(event) =>
                                        setDialogValue({
                                            ...dialogValue,
                                            title: event.target.value,
                                        })
                                    }
                                    label={dialogValue?.dial?.title}
                                    type="text"
                                    variant="outlined"
                                />
                            </Col>
                        </Row>
                        {dialogValue?.dial?.type === 'departamento'
                            ? <Row>
                                <Col>
                                    <TextField
                                        autoFocus
                                        className={classes.input}
                                        margin="normal"
                                        required
                                        value={dialogValue.centroDeCusto}
                                        onChange={(event) =>
                                            setDialogValue({
                                                ...dialogValue,
                                                centroDeCusto: event.target.value,
                                            })
                                        }
                                        label="Centro de Custo"
                                        type="text"
                                        variant="outlined"
                                    />
                                </Col>
                            </Row>
                            : null}
                        {dialogValue?.dial?.type === 'cargo'
                            ? <Row >
                                <Col>
                                    <TextField
                                        autoFocus
                                        margin="normal"
                                        className={classes.input}
                                        value={dialogValue.salarioBase}
                                        onChange={(e) => {
                                            setDialogValue({
                                                ...dialogValue,
                                                salarioBase: handleSalario(e.target.value)
                                            })
                                        }
                                        }
                                        label="Salario Base"
                                        type="text"
                                        variant="outlined"
                                        helperText="Complete o cadastro do cargo após a criação"
                                    />
                                </Col>
                            </Row>
                            : null}
                    </DialogContent>
                    <DialogActions>
                        <Button size='sm' variant="outline-secondary" onClick={handleClose}>Cancelar</Button>
                        <Button size='sm' type="submit">Salvar</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                style={{ zIndex: 1000000 }}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover />

        </Container>

    );
}

export default NovoColaborador;