import React, { useState } from "react";
import { SwitchModal } from "./switchmodal.tsx";

interface ColumnVisibility {
  [key: string]: boolean;
}

export const initialColumnVisibility: ColumnVisibility = {
  "Apts Ocupados": true,
  "Taxa de Ocupação": true,
  "Taxa de Ocupação Anterior": true,
  "Diária Média": true,
  RevPar: true,
  Receita: true,
  Ranking: true,
  "Pick-up": true,
  "Apts Ocupados Budget": true,
  "Taxa de Ocupação Budget": true,
  "Taxa de Ocupação Anterior Budget": true,
  "Diária Média Budget": true,
  "RevPar Budget": true,
  "Receita Budget": true,
  "Diferença Budget": true,
};

interface SwitchModalRevenueProps {
  setModalMostrarEsconder: any;
  modalMostrarEsconder: any;
  columnVisibility: ColumnVisibility;
  setColumnVisibility: (any) => void;
}

const SwitchModalRevenue: React.FC<SwitchModalRevenueProps> = ({
  setModalMostrarEsconder,
  modalMostrarEsconder,
  columnVisibility,
  setColumnVisibility,
}) => {
  const toggleColumnVisibility = (column: string) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const switches = Object.keys(columnVisibility).map((column) => ({
    onToggle: () => toggleColumnVisibility(column),
    checked: columnVisibility[column],
    label: column,
  }));

  return (
    <SwitchModal
      title="Mostrar/Esconder Colunas"
      onHide={() => setModalMostrarEsconder([false])}
      show={modalMostrarEsconder[0]}
      switches={switches}
    />
  );
};

export default SwitchModalRevenue;
