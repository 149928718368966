import React, { useState } from "react";
import SelectModal from "./selectmodal.tsx";

const chartTypeOptions = [
  { value: "bar", label: "Barra" },
  { value: "pie", label: "Pizza" },
];

const metricOptions = [
  { value: "taxaOcupacao", label: "Taxa de Ocupação" },
  { value: "aptsOcupados", label: "Apartamentos Ocupados" },
  { value: "revPar", label: "RevPar" },
  { value: "receita", label: "Receita" },
  { value: "diariaMedia", label: "Diaria Media" },
];
interface ChartModalProps {
  show: boolean;
  onHide: () => void;
  onSelectType: (type: string) => void;
  onSelectMetric: (metric: string) => void;
  onConfirm: (chartType: string, metric: string) => void;
}

const ChartModal: React.FC<ChartModalProps> = ({ show, onHide, onConfirm }) => {
  const [selectedChartType, setSelectedChartType] = useState("");
  const [selectedMetric, setSelectedMetric] = useState("");

  const handleChartTypeChange = (selectedOption) => {
    setSelectedChartType(selectedOption);
  };

  const handleMetricChange = (selectedOption) => {
    setSelectedMetric(selectedOption);
  };

  const handleConfirm = () => {
    if (selectedChartType && selectedMetric) {
      onConfirm(selectedChartType, selectedMetric);
    }
    onHide();
  };

  return (
    <SelectModal
      show={show}
      onHide={onHide}
      title="Configurações do Gráfico"
      primaryButtonLabel="Confirmar"
      outlineButtonLabel="Cancelar"
      onPrimaryButtonClick={handleConfirm}
      select={[
        {
          value: selectedChartType,
          label: "Tipo de Gráfico",
          onSelect: handleChartTypeChange,
          options: chartTypeOptions,
          isMulti: false,
        },
        {
          value: selectedMetric,
          label: "Métrica",
          onSelect: handleMetricChange,
          options: metricOptions,
          isMulti: false,
        },
      ]}
    />
  );
};

export default ChartModal;
