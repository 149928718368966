import { useState, useEffect } from 'react';
import api from '../services/api';
import { useCookies } from 'react-cookie';

export const useCombos = (isLoadRestaurante) => {
    const [combos, setCombos] = useState([]);
    const [cookies] = useCookies(["usuario"]);

    useEffect(() => {
        async function loadCombos() {
            try {
                const response = await api.get(`/combos?usuario_id=${cookies.usuario.id}&loadrestaurante=${isLoadRestaurante}`, {
                    headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` }
                });

                setCombos(response.data);
            } catch (error) {
                console.error("Erro ao carregar combos!", error);
            }
        }

        loadCombos();
    }, [cookies.usuario.accessToken]);

    return combos;
};
