import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { FiInfo } from "react-icons/fi";
import Typography from "@mui/material/Typography";
import { formatarValor } from "../../../helpers/formatarValor.ts";

export const CustomTooltip = ({ unidade, tipo }) => {
  const renderTooltipContent = () => {
    if (tipo === "mensal" || tipo === "acumulado") {
      return (
        <>
          <Typography color="inherit">
            {unidade.indice.label} Acumulado 12M
          </Typography>
          {unidade.indice[tipo].map((item) => (
            <React.Fragment key={item.label}>
              <Typography variant="body2" color="inherit">
                {/* {item.label} */}
              </Typography>
              {item.value.map((val) => (
                <Typography variant="body2" key={val.mes}>
                  {`${val.mes}: ${formatarValor(
                    val.indice / 100,
                    "porcentagem"
                  )}`}
                </Typography>
              ))}
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return <Typography color="inherit">Tipo inválido</Typography>;
    }
  };

  const tooltipContent = renderTooltipContent();

  return (
    <Tooltip title={tooltipContent} placement="right" arrow>
      <div style={{ width: 20 }}>
        <FiInfo size={24} style={{ cursor: "pointer" }} />
      </div>
    </Tooltip>
  );
};
