const initial_state = {
    isLoading: false,
    text: '',
    icon: 1,
}

export default function loading(state = initial_state, action) {
    switch (action.type) {
        case 'OPEN_LOADING':
            return { ...state, text: action.text, isLoading:action.isLoading, icon: action.icon, };

        case 'CLOSE_LOADING':
            return { ...state, text: '', isLoading:action.isLoading };

        default:
            return state;
    }
}