import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%', margin: ' 50px 0 0 0', height: 'auto'
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        '&:hover': {
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#e8   e8e8',
        }
    },
    icon: {
        fontSize: '2em',
        color: '#119e91',
    },
    text: {
        fontSize: '1.2em',
        fontWeight: 'bold',
        color: '#333',
    },
    small: {
        width: 'auto !important',
        height: 'auto !important',
        maxWidth: '70%',
        maxHeight: '90%',
    },
    smallColab: {
        width: '80px ',
        height: '80px ',
        [theme.breakpoints.down('sm')]: {
            width: '100px ',
            height: '100px ',
            alignSelf: 'center',
        },
    },
    iconSpace: {
        background: 'yellow',
        width: '60px',
        height: '60px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleSpace: {
        fontSize: '25px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        gap: '15px',
        zIndex: 10,
        position: 'fixed',
        left: '90px',
    },
    iconSpace2: {
        background: 'yellow',
        width: '60px',
        height: '60px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleSpace2: {
        margin: '-30px 30px',
        fontSize: '25px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        gap: '15px',
        zIndex: 10,
    },
    photoCol: {
        display: 'flex',
        justifyContent: 'center',
        height: 'auto',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 20,
    },
    input: {
        marginTop: '10px !important',
        marginBottom: '10px !important',
        width: '100%',
    },
    card: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        padding: 0,
        margin: 'calc(5vh - 10px) 0',
        borderRadius: '8px',
        boxShadow: '3px 3px 10px gray',
        height: 'auto',
        width: '96vw',
    },
    fabPos: {
        position: 'fixed !important',
        bottom: '16px',
        right: '16px',
    },
    subtitle: {
        fontSize: 'small',
        color: 'gray',
        fontWeight: 'normal',
        fontStyle: 'italic',
    },
    backdrop: {
        color: '#fff',
    },
    tableRow: {
        verticalAlign: 'middle',
        textAlign: 'left',
        padding: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        fontSize: '0.875rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            padding: theme.spacing(0.5),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
    },
    tableCell: {
        verticalAlign: 'middle',
        textAlign: 'left',
        padding: theme.spacing(1.5),
        borderBottom: `1.5px solid ${theme.palette.divider}`,
        fontSize: '0.875rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: theme.spacing(1.5),
            borderBottom: 'none',
            '&:last-child': {
                borderBottom: `1.5px solid ${theme.palette.divider}`,
            },
        },
    },
    breakWord: {
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    breakAll: {
        wordBreak: 'break-all',
    },
}));