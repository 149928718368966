import React, { FC } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Select from "react-select";

interface GenericModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  primaryButtonLabel?: string;
  outlineButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  onOutlineButtonClick?: () => void;
  moreSelects?: React.ReactElement;
  select?: Array<{
    value?: string | string[];
    label: string;
    onSelect: (value: string) => void | any;
    options: Array<{ value: string; label: string }>;
    isMulti: boolean;
  }>;
}

const SelectModal: FC<GenericModalProps> = ({
  show,
  onHide,
  title,
  primaryButtonLabel = "Confirmar",
  outlineButtonLabel = "Cancelar",
  onPrimaryButtonClick,
  onOutlineButtonClick,
  select,
  children,
  moreSelects,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(select) &&
          select.map((selectProp, index) => (
            <Form.Group as={Col} key={index}>
              <Form.Label>{selectProp.label}</Form.Label>
              <Select
                isMulti={selectProp.isMulti}
                options={selectProp.options}
                onChange={(selectedOption) =>
                  selectProp.onSelect(selectedOption.value)
                }
              />
            </Form.Group>
          ))}
        <div style={{ paddingTop: 20 }}>{moreSelects}</div>
        {children}
      </Modal.Body>
      <Modal.Footer>
        {outlineButtonLabel && (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={onOutlineButtonClick || onHide}
          >
            {outlineButtonLabel}
          </Button>
        )}
        {primaryButtonLabel && (
          <Button
            size="sm"
            variant="primary"
            onClick={onPrimaryButtonClick || onHide}
          >
            {primaryButtonLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModal;
