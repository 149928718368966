/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup,
    Modal,
    OverlayTrigger,
    Popover,
    Row,
    Table,
    Tooltip
} from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import Select from 'react-select';

// icons
import { BiFilterAlt, BiMinusCircle, BiPlusCircle } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import { GrDocument } from 'react-icons/gr';
import { MdChildCare } from 'react-icons/md';
import { RiMessage2Fill, RiMessage2Line } from 'react-icons/ri';
import { VscPerson } from 'react-icons/vsc';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Modais
import ModalContratos from '../../../components/modals/models/modalContratos.tsx';
import ModalNovoComentario from '../../../components/modals/models/modalNovoComentario.tsx';
import ModalRespostas from '../../../components/modals/models/modalRespostas.tsx';
import ModalComentarios from '../../../components/modals/models/modalComentarios.tsx';
import ModalRazao from '../../../components/modals/models/modalRazao.tsx';
import ModalRazaoAllContas from '../../../components/modals/models/modalRazaoAllContas.tsx'
import { FaChartLine } from "react-icons/fa";


// API
import api from '../../../services/api';

// redux
import { useDispatch } from 'react-redux';

// Styles
import './styles.css';

export default function DRE() {

    const dispatch = useDispatch()
    const [cookies] = useCookies([]);

    const [dreData, setDreData] = useState([])
    const [unidadesSelecionadas, setUnidadesSelecioandas] = useState([])
    const [registros, setRegistros] = useState([])
    const [baseVertical, setBaseVertical] = useState({ id: null })
    const [codigosDre, setCodigosDre] = useState([])
    const [willConsolidar, setWillConsolidar] = useState([])
    const [anoConsulta, setAnoConsulta] = useState(null)
    const [myUsers, setMyUsers] = useState(null)
    const [myNoColab, setMyNoColab] = useState({ data: [], active: 'Total' })
    const [contratos, setContratos] = useState([])
    const [modalContratosShow, setModalContratosShow] = useState([])

    const [unidades, setUnidades] = useState([])
    const [mesConsulta, setMesConsulta] = useState("01");
    const [mesConsultaFinal, setMesConsultaFinal] = useState("01");

    const [MSLoading, setMSLoading] = useState(true);
    const [selected, setSelected] = useState([]);

    const [searchRazaoText, setSearchRazaoText] = useState('')
    const [modalShow, setModalShow] = useState([false]);
    const [modalComentariosShow, setModalComentariosShow] = useState([false, null, { id: '' }]);
    const [modalNovoComentarios, setModalNovoComentarios] = useState([false]);
    const [modalFilterShow, setModalFilterShow] = useState([false]);
    const [filtro, setFiltro] = useState({ mb: true, ml: true, mc: true, txRO: true, adr: true, trih: true, rn: true, ro: true, iha: true, ihc: true, tihp: true, fix: false, noColab: true, totalShow: true })
    const [razaoData, setRazaoData] = useState([])
    const [razaoDataMemory, setRazaoDataMemory] = useState([])
    const [sticky, setSticky] = useState({ position: 'sticky', top: 0, zIndex: 1 })
    const [comentarios, setComentarios] = useState([])
    const [destinatarios, setDestinatarios] = useState([])
    const [anexos, setAnexos] = useState([])
    const [anexosRazao, setAnexosRazao] = useState([])
    const [modalRespostaVisible, setModalRespostaVisible] = useState([false, { nome: '', comentario: '' }])
    const [nivelContaAtual, setNivelContaAtual] = useState(null)
    const [tooltipText, setToolipText] = useState('Adicionar anexo')

    const [contaIdAnexoAtual, setContaIdAnexoAtual] = useState(null)

    const [UnidadeKeyAtual, setUnidadeKeyAtual] = useState('')

    const [numeroDeIntervalos, setNumeroDeIntervalos] = useState(1)
    const [consultado, setConsultado] = useState(true)
    const [variacaoHidden, setVariacaoHidden] = useState({ horizontal: true, vertical: true })
    const [modalRazaoAllMonths, setModalRazaoAllMonths] = useState(false)
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [allMesesData, setAllMesesData] = useState();
    const [totalSaldo, setTotalSaldo] = useState();
    const [isSearchEmpty, setIsSearchEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    var meses = ["", "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

    const fetchModalMesesData = async (selectedMonth) => {
        try {
            setIsLoading(true)
            const mes = selectedMonth;
            const anoConsultado = anoConsulta;
            const unidade = selected.map(codigoId => codigoId?.value);
            const search = (document.getElementById('searchInput')?.value.trim().toLowerCase().replace(/,/g, '.') || "");

            const queryParams = new URLSearchParams({
                mes,
                anoConsultado,
                unidade,
                search,
                limit,
                offset,
                organizacao_id: JSON.stringify(cookies.usuario.organizacao_id)
            }).toString();

            const response = await api.get(`/razoes?${queryParams}`, {
                headers: {
                    authorization: `Bearer ${cookies.usuario.accessToken}`,
                },
            });


            setModalRazaoAllMonths(true)
            setTotalSaldo(response.data.total)
            const newData = response.data.razaoData;

            if (newData.length) {
                setRazaoData(current => [...current, ...newData]);
                setOffset(currentOffset => currentOffset + limit);

            }

        } catch (err) {
            console.error("Erro ao buscar os dados:", err);
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleSearchChange = (text) => {
        setIsSearchEmpty(text === "");
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault();
            setRazaoData([]);
            fetchModalMesesData(allMesesData);

        }
    };

    useEffect(() => {
        var array = []
        JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
            array.push({ label: unidade.unidade, value: unidade.id })
        })
        setMSLoading(false)
        setUnidades(array)
        loadMyUsers()
    }, [MSLoading, sessionStorage.getItem('unidadesMemory')])

    async function adicionaAnexoRazao() {
        var myFiles = document.getElementById('anexoAtualRazao').files
        if (myFiles.length) {
            for (let i = 0; i < myFiles.length; i++) {
                const dados = new FormData()

                dados.append('file', myFiles[i])
                dados.append('drerazao_id', contaIdAnexoAtual)

                await api.post('/filesRazao', dados, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            }
            document.getElementById('anexoAtualRazao').value = null
            loadData()
        }
    }

    async function loadMyUsers() {
        if (!myUsers) {
            var res = await api.get('/usuarios', { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            var myArr = []
            res.data.reduce((acc, cv) => { if (cv.id !== cookies.usuario.id) { myArr.push({ label: cv.nome, value: cv.id }) } }, 0)
            setMyUsers(myArr)
        }
    }

    async function loadData() {
        if ((mesConsulta <= mesConsultaFinal) && anoConsulta && selected.length !== 0) {
            dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: true })
            var unidades = "VAZIO"
            var unidadesRawFormat = "VAZIO"
            var nomeUnidades = ""
            selected.map(unidade => {
                if (unidades === "VAZIO") {
                    unidades = unidade.value
                    unidadesRawFormat = "'" + unidade.value + "'"
                    nomeUnidades = unidade.label
                } else {
                    unidades = unidades + ',' + unidade.value
                    unidadesRawFormat = unidadesRawFormat + ',' + "'" + unidade.value + "'"
                    nomeUnidades = nomeUnidades + ', ' + unidade.label
                }
            })
            setUnidadesSelecioandas(nomeUnidades)

            setUnidadeKeyAtual(selected.reduce((acc, cv) => acc += cv.value, ''))

            var organizacao_idRaw = ([...new Set(JSON.parse(sessionStorage.getItem('unidades')).filter(u => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(u.id)).reduce((acc, cv) => acc = [...acc, cv.organizacao_id], []))].reduce((acc, cv) => acc += (cv + ','), ''))
            organizacao_idRaw = organizacao_idRaw.slice(0, -1)

            await api.get(`/dreMensal/${unidades}/${anoConsulta}/01/12/${organizacao_idRaw}?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res2 => {
                    dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
                    setConsultado(false)
                    setDreData(res2.data.dados.filter(d => !d.destaque))
                    setCodigosDre(res2.data.modeloDre)
                    setWillConsolidar(res2.data.willConsolidar)

                })

            const contratosReturn = await api.get(`/contratos/ativos/${unidadesRawFormat}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            setContratos(contratosReturn.data)

            const comentariosReturn = await api.get(`/getComentarios/${unidades}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            setComentarios(comentariosReturn.data.comentarios)
            setDestinatarios(comentariosReturn.data.destinatarios)
            setAnexos(comentariosReturn.data.anexos)

            const myColabs = await api.get(`/funcionarios/${unidades}/${anoConsulta}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

            setMyNoColab({ ...myNoColab, data: myColabs.data })

            await api.get(`/registrosDreMensal/${unidades}/ADR_ROOM,OCC_PERC,PHYSICAL_ROOMS,OCC_ROOMS,ADULTS_IN_HOUSE,CHILDREN_IN_HOUSE,GUEST_IN_HOUSE/${anoConsulta}/01/12`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                .then(res => {

                    var codigoADR_ROOM = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, janeiroqtd: 0, fevereiroqtd: 0, marcoqtd: 0, abrilqtd: 0, maioqtd: 0, junhoqtd: 0, julhoqtd: 0, agostoqtd: 0, setembroqtd: 0, outubroqtd: 0, novembroqtd: 0, dezembroqtd: 0, total: 0, qtd: 0 }
                    var codigoOCC_PERC = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, janeiroqtd: 0, fevereiroqtd: 0, marcoqtd: 0, abrilqtd: 0, maioqtd: 0, junhoqtd: 0, julhoqtd: 0, agostoqtd: 0, setembroqtd: 0, outubroqtd: 0, novembroqtd: 0, dezembroqtd: 0, total: 0, qtd: 0 }
                    var codigoPHYSICAL_ROOMS = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, janeiroqtd: 0, fevereiroqtd: 0, marcoqtd: 0, abrilqtd: 0, maioqtd: 0, junhoqtd: 0, julhoqtd: 0, agostoqtd: 0, setembroqtd: 0, outubroqtd: 0, novembroqtd: 0, dezembroqtd: 0, total: 0, qtd: 0 }
                    var codigoOCC_ROOMS = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, janeiroqtd: 0, fevereiroqtd: 0, marcoqtd: 0, abrilqtd: 0, maioqtd: 0, junhoqtd: 0, julhoqtd: 0, agostoqtd: 0, setembroqtd: 0, outubroqtd: 0, novembroqtd: 0, dezembroqtd: 0, total: 0, qtd: 0 }
                    var codigoADULTS_IN_HOUSE = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, janeiroqtd: 0, fevereiroqtd: 0, marcoqtd: 0, abrilqtd: 0, maioqtd: 0, junhoqtd: 0, julhoqtd: 0, agostoqtd: 0, setembroqtd: 0, outubroqtd: 0, novembroqtd: 0, dezembroqtd: 0, total: 0, qtd: 0 }
                    var codigoCHILDREN_IN_HOUSE = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, janeiroqtd: 0, fevereiroqtd: 0, marcoqtd: 0, abrilqtd: 0, maioqtd: 0, junhoqtd: 0, julhoqtd: 0, agostoqtd: 0, setembroqtd: 0, outubroqtd: 0, novembroqtd: 0, dezembroqtd: 0, total: 0, qtd: 0 }
                    var codigoGUEST_IN_HOUSE = { janeiro: 0, fevereiro: 0, marco: 0, abril: 0, maio: 0, junho: 0, julho: 0, agosto: 0, setembro: 0, outubro: 0, novembro: 0, dezembro: 0, janeiroqtd: 0, fevereiroqtd: 0, marcoqtd: 0, abrilqtd: 0, maioqtd: 0, junhoqtd: 0, julhoqtd: 0, agostoqtd: 0, setembroqtd: 0, outubroqtd: 0, novembroqtd: 0, dezembroqtd: 0, total: 0, qtd: 0 }
                    res.data.map(registro => {
                        if (registro.codigo_id === 'ADR_ROOM') {
                            codigoADR_ROOM.id = registro.codigo_id
                            codigoADR_ROOM.descricao = registro.descricao
                            codigoADR_ROOM.total += registro.valor
                            codigoADR_ROOM.qtd++
                            if (registro.data.substring(5, 7) === '01') {
                                codigoADR_ROOM.janeiro += registro.valor
                                codigoADR_ROOM.janeiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoADR_ROOM.fevereiro += registro.valor
                                codigoADR_ROOM.fevereiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoADR_ROOM.marco += registro.valor
                                codigoADR_ROOM.marcoqtd++
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoADR_ROOM.abril += registro.valor
                                codigoADR_ROOM.abrilqtd++
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoADR_ROOM.maio += registro.valor
                                codigoADR_ROOM.maioqtd++
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoADR_ROOM.junho += registro.valor
                                codigoADR_ROOM.junhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoADR_ROOM.julho += registro.valor
                                codigoADR_ROOM.julhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoADR_ROOM.agosto += registro.valor
                                codigoADR_ROOM.agostoqtd++
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoADR_ROOM.setembro += registro.valor
                                codigoADR_ROOM.setembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoADR_ROOM.outubro += registro.valor
                                codigoADR_ROOM.outubroqtd++
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoADR_ROOM.novembro += registro.valor
                                codigoADR_ROOM.novembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoADR_ROOM.dezembro += registro.valor
                                codigoADR_ROOM.dezembroqtd++
                            }
                        } else if (registro.codigo_id === 'OCC_PERC') {
                            codigoOCC_PERC.id = registro.codigo_id
                            codigoOCC_PERC.descricao = registro.descricao
                            codigoOCC_PERC.qtd++
                            codigoOCC_PERC.total += registro.valor
                            if (registro.data.substring(5, 7) === '01') {
                                codigoOCC_PERC.janeiro += registro.valor
                                codigoOCC_PERC.janeiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoOCC_PERC.fevereiro += registro.valor
                                codigoOCC_PERC.fevereiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoOCC_PERC.marco += registro.valor
                                codigoOCC_PERC.marcoqtd++
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoOCC_PERC.abril += registro.valor
                                codigoOCC_PERC.abrilqtd++
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoOCC_PERC.maio += registro.valor
                                codigoOCC_PERC.maioqtd++
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoOCC_PERC.junho += registro.valor
                                codigoOCC_PERC.junhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoOCC_PERC.julho += registro.valor
                                codigoOCC_PERC.julhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoOCC_PERC.agosto += registro.valor
                                codigoOCC_PERC.agostoqtd++
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoOCC_PERC.setembro += registro.valor
                                codigoOCC_PERC.setembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoOCC_PERC.outubro += registro.valor
                                codigoOCC_PERC.outubroqtd++
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoOCC_PERC.novembro += registro.valor
                                codigoOCC_PERC.novembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoOCC_PERC.dezembro += registro.valor
                                codigoOCC_PERC.dezembroqtd++
                            }
                        } else if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                            codigoPHYSICAL_ROOMS.id = registro.codigo_id
                            codigoPHYSICAL_ROOMS.descricao = registro.descricao
                            codigoPHYSICAL_ROOMS.qtd++
                            codigoPHYSICAL_ROOMS.total += registro.valor
                            if (registro.data.substring(5, 7) === '01') {
                                codigoPHYSICAL_ROOMS.janeiro += registro.valor
                                codigoPHYSICAL_ROOMS.janeiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoPHYSICAL_ROOMS.fevereiro += registro.valor
                                codigoPHYSICAL_ROOMS.fevereiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoPHYSICAL_ROOMS.marco += registro.valor
                                codigoPHYSICAL_ROOMS.marcoqtd++
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoPHYSICAL_ROOMS.abril += registro.valor
                                codigoPHYSICAL_ROOMS.abrilqtd++
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoPHYSICAL_ROOMS.maio += registro.valor
                                codigoPHYSICAL_ROOMS.maioqtd++
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoPHYSICAL_ROOMS.junho += registro.valor
                                codigoPHYSICAL_ROOMS.junhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoPHYSICAL_ROOMS.julho += registro.valor
                                codigoPHYSICAL_ROOMS.julhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoPHYSICAL_ROOMS.agosto += registro.valor
                                codigoPHYSICAL_ROOMS.agostoqtd++
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoPHYSICAL_ROOMS.setembro += registro.valor
                                codigoPHYSICAL_ROOMS.setembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoPHYSICAL_ROOMS.outubro += registro.valor
                                codigoPHYSICAL_ROOMS.outubroqtd++
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoPHYSICAL_ROOMS.novembro += registro.valor
                                codigoPHYSICAL_ROOMS.novembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoPHYSICAL_ROOMS.dezembro += registro.valor
                                codigoPHYSICAL_ROOMS.dezembroqtd++
                            }
                        } else if (registro.codigo_id === 'OCC_ROOMS') {
                            codigoOCC_ROOMS.id = registro.codigo_id
                            codigoOCC_ROOMS.descricao = registro.descricao
                            codigoOCC_ROOMS.qtd++
                            codigoOCC_ROOMS.total += registro.valor
                            if (registro.data.substring(5, 7) === '01') {
                                codigoOCC_ROOMS.janeiro += registro.valor
                                codigoOCC_ROOMS.janeiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoOCC_ROOMS.fevereiro += registro.valor
                                codigoOCC_ROOMS.fevereiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoOCC_ROOMS.marco += registro.valor
                                codigoOCC_ROOMS.marcoqtd++
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoOCC_ROOMS.abril += registro.valor
                                codigoOCC_ROOMS.abrilqtd++
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoOCC_ROOMS.maio += registro.valor
                                codigoOCC_ROOMS.maioqtd++
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoOCC_ROOMS.junho += registro.valor
                                codigoOCC_ROOMS.junhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoOCC_ROOMS.julho += registro.valor
                                codigoOCC_ROOMS.julhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoOCC_ROOMS.agosto += registro.valor
                                codigoOCC_ROOMS.agostoqtd++
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoOCC_ROOMS.setembro += registro.valor
                                codigoOCC_ROOMS.setembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoOCC_ROOMS.outubro += registro.valor
                                codigoOCC_ROOMS.outubroqtd++
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoOCC_ROOMS.novembro += registro.valor
                                codigoOCC_ROOMS.novembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoOCC_ROOMS.dezembro += registro.valor
                                codigoOCC_ROOMS.dezembroqtd++
                            }
                        } else if (registro.codigo_id === 'ADULTS_IN_HOUSE') {
                            codigoADULTS_IN_HOUSE.id = registro.codigo_id
                            codigoADULTS_IN_HOUSE.descricao = registro.descricao
                            codigoADULTS_IN_HOUSE.qtd++
                            codigoADULTS_IN_HOUSE.total += registro.valor
                            if (registro.data.substring(5, 7) === '01') {
                                codigoADULTS_IN_HOUSE.janeiro += registro.valor
                                codigoADULTS_IN_HOUSE.janeiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoADULTS_IN_HOUSE.fevereiro += registro.valor
                                codigoADULTS_IN_HOUSE.fevereiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoADULTS_IN_HOUSE.marco += registro.valor
                                codigoADULTS_IN_HOUSE.marcoqtd++
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoADULTS_IN_HOUSE.abril += registro.valor
                                codigoADULTS_IN_HOUSE.abrilqtd++
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoADULTS_IN_HOUSE.maio += registro.valor
                                codigoADULTS_IN_HOUSE.maioqtd++
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoADULTS_IN_HOUSE.junho += registro.valor
                                codigoADULTS_IN_HOUSE.junhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoADULTS_IN_HOUSE.julho += registro.valor
                                codigoADULTS_IN_HOUSE.julhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoADULTS_IN_HOUSE.agosto += registro.valor
                                codigoADULTS_IN_HOUSE.agostoqtd++
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoADULTS_IN_HOUSE.setembro += registro.valor
                                codigoADULTS_IN_HOUSE.setembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoADULTS_IN_HOUSE.outubro += registro.valor
                                codigoADULTS_IN_HOUSE.outubroqtd++
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoADULTS_IN_HOUSE.novembro += registro.valor
                                codigoADULTS_IN_HOUSE.novembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoADULTS_IN_HOUSE.dezembro += registro.valor
                                codigoADULTS_IN_HOUSE.dezembroqtd++
                            }
                        } else if (registro.codigo_id === 'CHILDREN_IN_HOUSE') {
                            codigoCHILDREN_IN_HOUSE.id = registro.codigo_id
                            codigoCHILDREN_IN_HOUSE.descricao = registro.descricao
                            codigoCHILDREN_IN_HOUSE.qtd++
                            codigoCHILDREN_IN_HOUSE.total += registro.valor
                            if (registro.data.substring(5, 7) === '01') {
                                codigoCHILDREN_IN_HOUSE.janeiro += registro.valor
                                codigoCHILDREN_IN_HOUSE.janeiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoCHILDREN_IN_HOUSE.fevereiro += registro.valor
                                codigoCHILDREN_IN_HOUSE.fevereiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoCHILDREN_IN_HOUSE.marco += registro.valor
                                codigoCHILDREN_IN_HOUSE.marcoqtd++
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoCHILDREN_IN_HOUSE.abril += registro.valor
                                codigoCHILDREN_IN_HOUSE.abrilqtd++
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoCHILDREN_IN_HOUSE.maio += registro.valor
                                codigoCHILDREN_IN_HOUSE.maioqtd++
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoCHILDREN_IN_HOUSE.junho += registro.valor
                                codigoCHILDREN_IN_HOUSE.junhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoCHILDREN_IN_HOUSE.julho += registro.valor
                                codigoCHILDREN_IN_HOUSE.julhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoCHILDREN_IN_HOUSE.agosto += registro.valor
                                codigoCHILDREN_IN_HOUSE.agostoqtd++
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoCHILDREN_IN_HOUSE.setembro += registro.valor
                                codigoCHILDREN_IN_HOUSE.setembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoCHILDREN_IN_HOUSE.outubro += registro.valor
                                codigoCHILDREN_IN_HOUSE.outubroqtd++
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoCHILDREN_IN_HOUSE.novembro += registro.valor
                                codigoCHILDREN_IN_HOUSE.novembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoCHILDREN_IN_HOUSE.dezembro += registro.valor
                                codigoCHILDREN_IN_HOUSE.dezembroqtd++
                            }
                        } else if (registro.codigo_id === 'GUEST_IN_HOUSE') {
                            codigoGUEST_IN_HOUSE.id = registro.codigo_id
                            codigoGUEST_IN_HOUSE.descricao = registro.descricao
                            codigoGUEST_IN_HOUSE.qtd++
                            codigoGUEST_IN_HOUSE.total += registro.valor
                            if (registro.data.substring(5, 7) === '01') {
                                codigoGUEST_IN_HOUSE.janeiro += registro.valor
                                codigoGUEST_IN_HOUSE.janeiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '02') {
                                codigoGUEST_IN_HOUSE.fevereiro += registro.valor
                                codigoGUEST_IN_HOUSE.fevereiroqtd++
                            }
                            if (registro.data.substring(5, 7) === '03') {
                                codigoGUEST_IN_HOUSE.marco += registro.valor
                                codigoGUEST_IN_HOUSE.marcoqtd++
                            }
                            if (registro.data.substring(5, 7) === '04') {
                                codigoGUEST_IN_HOUSE.abril += registro.valor
                                codigoGUEST_IN_HOUSE.abrilqtd++
                            }
                            if (registro.data.substring(5, 7) === '05') {
                                codigoGUEST_IN_HOUSE.maio += registro.valor
                                codigoGUEST_IN_HOUSE.maioqtd++
                            }
                            if (registro.data.substring(5, 7) === '06') {
                                codigoGUEST_IN_HOUSE.junho += registro.valor
                                codigoGUEST_IN_HOUSE.junhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '07') {
                                codigoGUEST_IN_HOUSE.julho += registro.valor
                                codigoGUEST_IN_HOUSE.julhoqtd++
                            }
                            if (registro.data.substring(5, 7) === '08') {
                                codigoGUEST_IN_HOUSE.agosto += registro.valor
                                codigoGUEST_IN_HOUSE.agostoqtd++
                            }
                            if (registro.data.substring(5, 7) === '09') {
                                codigoGUEST_IN_HOUSE.setembro += registro.valor
                                codigoGUEST_IN_HOUSE.setembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '10') {
                                codigoGUEST_IN_HOUSE.outubro += registro.valor
                                codigoGUEST_IN_HOUSE.outubroqtd++
                            }
                            if (registro.data.substring(5, 7) === '11') {
                                codigoGUEST_IN_HOUSE.novembro += registro.valor
                                codigoGUEST_IN_HOUSE.novembroqtd++
                            }
                            if (registro.data.substring(5, 7) === '12') {
                                codigoGUEST_IN_HOUSE.dezembro += registro.valor
                                codigoGUEST_IN_HOUSE.dezembroqtd++
                            }
                        }
                    })
                    setRegistros([codigoOCC_PERC, codigoADR_ROOM, codigoPHYSICAL_ROOMS, codigoOCC_ROOMS, codigoADULTS_IN_HOUSE, codigoCHILDREN_IN_HOUSE, codigoGUEST_IN_HOUSE])
                })

        } else if (anoConsulta && selected.length !== 0) {
            notify("Data final anterior à data inicial!")
        } else if (selected.length !== 0) {
            notify("Ano da consulta indefinido!")
        } else {
            notify("Escolha pelo menos uma unidade para consulta!")
        }
    }

    const notify = ($msg) => toast($msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    async function openRazao(codigo, mes, valor) {
        dispatch({ type: 'OPEN_LOADING', text: "Buscando registros...", isLoading: true })

        var unidades = selected.reduce((acc, cv) => acc = [...acc, cv.value], [])
        unidades = JSON.stringify(unidades)

        const res = await api.get(`/registrorazaomes/${unidades}/${codigo}/${mes}/${anoConsulta}/${mes}?usuario_id=${cookies.usuario.id}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
        if (res.data.razao.length) {
            setRazaoData(res.data.razao)
            setAnexosRazao(res.data.anexosRazao)
            setRazaoDataMemory(res.data.razao)
            setModalShow([true, codigosDre.find(d => d.codigo_id === codigo).descricao, valor])
            dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
        } else {
            dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
            notify("Nenhum lançamento registrado!")
        }
    }

    function handleBase(id) {
        if (id) {
            if (baseVertical.id) {
                document.getElementById('linha-' + baseVertical.id).classList.remove('selectedVertical')
                document.getElementById('linha-' + id).classList.add('selectedVertical')
                setBaseVertical({ id })
            } else {
                document.getElementById('linha-' + id).classList.add('selectedVertical')
                setBaseVertical({ id })
            }
        } else {
            setBaseVertical({ id })
        }
    }

    function filtraRazao(searchRazaoText) {
        const filteredRazao = razaoDataMemory.filter(registro => {
            let registroLowercase = (registro.historico).toLowerCase();

            let searchTermLowercase = searchRazaoText.toLowerCase();

            return registroLowercase.indexOf(searchTermLowercase) > -1 ||
                registro.centrodecusto_id.includes(searchRazaoText) ||
                registro.data.includes(searchRazaoText) ||
                (registro.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' C').includes(searchRazaoText) ||
                (registro.debito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + ' D').includes(searchRazaoText);
        });
        setRazaoData(filteredRazao)
    }

    function verificaVariação(codigo, anteriorMes, proximoMes) {
        var anterior = 0
        var proximo = 0
        if (anteriorMes === 0) return

        codigo.composicao
            ? anterior = dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) === anteriorMes).reduce((acc, cv) => acc += cv.valor, 0)
            : anterior = dreData.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) === anteriorMes).reduce((acc, cv) => acc += cv.valor, 0)

        codigo.composicao
            ? proximo = dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) === proximoMes).reduce((acc, cv) => acc += cv.valor, 0)
            : proximo = dreData.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) === proximoMes).reduce((acc, cv) => acc += cv.valor, 0)

        let variacao = proximo - anterior
        let taxavariacao = variacao / Math.abs(anterior)

        if (isNaN(taxavariacao)) return
        if (!isFinite(taxavariacao)) return

        if (taxavariacao === 0) {
            return <div className="valorTaxa" hidden={variacaoHidden.horizontal}>{taxavariacao.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</div>
        }
        if (taxavariacao > 0) {
            return <div className="valorTaxa" hidden={variacaoHidden.horizontal}><GoArrowUp className="icon" />{taxavariacao.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</div>
        } else {
            return <div className="valorTaxa" hidden={variacaoHidden.horizontal}><GoArrowDown className="icon" />{taxavariacao.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</div>
        }
    }

    function styleFilter() {
        var auxArr = []
        for (let i = 1; i <= numeroDeIntervalos; i++) {
            auxArr.push({
                min: parseFloat(document.getElementById('formFiltroBottom' + i).value),
                max: parseFloat(document.getElementById('formFiltroTop' + i).value),
                color: document.getElementById('formFiltroColorBTN' + i).value
            })
        }
        var arrValoresTaxa = [...document.getElementsByClassName('valorTaxa')]
        arrValoresTaxa.map(element => {
            var valor = element.lastChild.data.replace('.', '')
            valor = valor.replace(',', '.')
            valor = valor.replace('%', '')
            valor = parseFloat(valor)
            if (!isNaN(valor)) {
                var color = auxArr.find(d => d.max >= valor && d.min < valor)
                if (color) {
                    element.style.color = color.color
                }
            }
        })
        setNumeroDeIntervalos(1)
        setModalFilterShow([false]);
    }

    function hideShow(classname) {
        var arr = [...document.getElementsByClassName(classname)]
        arr.map(d => !d.classList.value.includes('linha-zerada') ? d.hidden = !d.hidden : null)
    }

    function BuildForm(prop) {
        var size = prop.size
        var max = 0
        var min = 0
        var arrValoresTaxa = [...document.getElementsByClassName('valorTaxa')]
        arrValoresTaxa.map(element => {
            var valor = element.lastChild.data.replace('.', '')
            valor = valor.replace(',', '.')
            valor = valor.replace('%', '')
            valor = parseFloat(valor)
            if (valor > max) {
                max = valor
            }
            if (valor < min) {
                min = valor
            }
        })
        var formulario = []
        if (size <= 0) {
            size = 1
        }
        for (let i = 1; i <= size; i++) {
            formulario.push(
                <Form.Row style={{ justifyContent: 'center' }}>
                    <Form.Group>
                        {size === i && size !== 1
                            ? <Button variant="light" style={{ backgroundColor: 'transparent' }} onClick={() => setNumeroDeIntervalos(numeroDeIntervalos - 1)}><BiMinusCircle /></Button>
                            : null
                        }
                    </Form.Group>
                    <Form.Group controlId={"formFiltroBottom" + i}>
                        <Form.Control type="number" defaultValue={min} placeholder="Minimo" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control type='text' value={'< Taxa de Variação <='} disabled style={{ backgroundColor: 'transparent', border: 'none' }} />
                    </Form.Group>
                    <Form.Group controlId={"formFiltroTop" + i}>
                        <Form.Control type="number" defaultValue={max} placeholder="Máximo" />
                    </Form.Group>
                    <Form.Group controlId={"formFiltroColorBTN" + i}>
                        <Form.Control
                            type="color"
                            style={{ width: 'revert' }}
                            defaultValue="black"
                            title="Escolha a cor"
                        />
                    </Form.Group>
                    <Form.Group>
                        {size === i
                            ? <Button variant="light" style={{ backgroundColor: 'transparent' }} onClick={() => setNumeroDeIntervalos(numeroDeIntervalos + 1)}><BiPlusCircle /></Button>
                            : null
                        }
                    </Form.Group>
                </Form.Row>
            )
        }
        return formulario
    }

    function variacaoVertical(valor, mes) {
        var base = codigosDre.find(d => d.codigo_id === baseVertical.id)
        if (mes === 'total') {
            if (base) {
                if (base.composicao) {
                    var baseValor = dreData.filter(d => base.composicao.includes(d.codigodre_id)).reduce((acc, cv) => acc += cv.valor, 0)
                } else {
                    var baseValor = dreData.filter(d => d.codigodre_id === valor.codigo_id).reduce((acc, cv) => acc += cv.valor, 0)
                }
                if (valor.codigo_id !== baseVertical.id) {
                    if (baseValor !== 0) {
                        if (valor.composicao) {
                            return (dreData.filter(d => valor.composicao.includes(d.codigodre_id)).reduce((acc, cv) => acc += cv.valor, 0) / baseValor).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                        } else {
                            return (dreData.filter(d => d.codigodre_id === valor.codigo_id).reduce((acc, cv) => acc += cv.valor, 0) / baseValor).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                        }
                    }
                }
            }
        } else {
            if (base) {
                if (base.composicao) {
                    var baseValor = dreData.filter(d => base.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) === mes).reduce((acc, cv) => acc += cv.valor, 0)
                } else {
                    var baseValor = dreData.filter(d => d.codigodre_id === valor.codigo_id && parseInt(d.data.substring(5, 7)) === mes).reduce((acc, cv) => acc += cv.valor, 0)
                }
                if (valor.codigo_id !== baseVertical.id) {
                    if (baseValor !== 0) {
                        if (valor.composicao) {
                            return (dreData.filter(d => valor.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) === mes).reduce((acc, cv) => acc += cv.valor, 0) / baseValor).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                        } else {
                            return (dreData.filter(d => d.codigodre_id === valor.codigo_id && parseInt(d.data.substring(5, 7)) === mes).reduce((acc, cv) => acc += cv.valor, 0) / baseValor).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })
                        }
                    }
                }
            }
        }
    }

    function abrePopoverBadge() {
        return (
            <OverlayTrigger placement="left-start" overlay={<Popover id="popover-basic">
                <Popover.Title as="h5">Ajuda</Popover.Title>
                <Popover.Content>
                    <p><b>Legenda:</b></p>
                    <RiMessage2Fill style={{ marginLeft: '5px' }} color='red' /> - Pergunta sem resposta nessa conta <br></br>
                    <RiMessage2Fill style={{ marginLeft: '5px' }} color='green' /> - Perguntas respondidas nessa conta<br></br>
                    <RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' /> - Comentarios nessa conta <br></br>
                    <RiMessage2Line style={{ marginLeft: '5px' }} color='red' /> - Pergunta sem resposta em conta analitica desse grupo<br></br>
                    <RiMessage2Line style={{ marginLeft: '5px' }} color='green' /> - Perguntas respondidas em conta analitica desse grupo<br></br>
                    <RiMessage2Line style={{ marginLeft: '5px' }} color='blue' /> - Comentarios em conta analitica desse grupo<br></br>
                    <p> <br></br><b>Funções do mouse:</b></p>
                    <p><b>Contas Sinteticas:</b> </p>
                    <ul>
                        <li>Botão esquerdo: Expandir contas sinteticas</li>
                        <li>Botão direito:  Abrir comentários contas sinteticas</li>
                    </ul>

                    <p><b>Contas Analiticas:</b></p>
                    <ul>
                        <li>Botão esquerdo: Abrir razão</li>
                        <li>Botão direito: Abrir razão</li>
                    </ul>
                </Popover.Content>
            </Popover>}>
                <Badge pill variant={'info'} style={{ float: 'right', color: 'black' }}>?</Badge>
            </OverlayTrigger>
        )
    }

    function verificaStatusIconSintetica(conta, mes) {
        var perguntasId = destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
        var respostasId = comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
        respostasId = [...new Set(respostasId)]

        if (!conta.destaque) {
            if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
            } else if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
            } else if (comentarios.filter(d => !conta.composicao.includes(d.codigodre_id) && d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
            } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Line style={{ marginLeft: '5px' }} color='red' />)
            } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Line style={{ marginLeft: '5px' }} color='green' />)
            } else if (comentarios.filter(d => conta.composicao.includes(d.codigodre_id) && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
                return (<RiMessage2Line style={{ marginLeft: '5px' }} color='blue' />)
            }
        }
    }

    function verificaStatusIconAnalitica(conta, mes) {
        var perguntasId = destinatarios.reduce((acc, cv) => acc = [...acc, cv.drerazaocomentario_id + ''], [])
        var respostasId = comentarios.reduce((acc, cv) => { if (cv.drerazaocomentario_id === null) { return acc } else { return acc = [...acc, cv.drerazaocomentario_id + ''] } }, [])
        respostasId = [...new Set(respostasId)]

        if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && !respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
            return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='red' />)
        } else if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && perguntasId.includes(d.id) && respostasId.includes(d.id) && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
            return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='green' />)
        } else if (comentarios.filter(d => d.codigodre_id === conta.codigo_id && !perguntasId.includes(d.id) && d.drerazaocomentario_id == null && parseFloat(d.mes) === mes && d.origem === 'realizado').length > 0) {
            return (<RiMessage2Fill style={{ marginLeft: '5px' }} color='blue' />)
        }
    }

    return (
        <div className={"analytics-body analytics-container"}>
            <Container fluid >
                <Row className="rowSelector">
                    <Col xs={0} md={0} lg={0} xl={1} >
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={5} style={{ zIndex: 2 }}>
                        <Select
                            isMulti
                            name="colors"
                            options={unidades}
                            closeMenuOnSelect={false}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={setSelected}
                        />
                    </Col>
                    <Col xs={4} md={3} lg={3} xl={1} >
                        <select name="month" id="month" onChange={e => setMesConsulta(e.target.value)}>
                            <option value="Mes">Início</option>
                            <option value="01">Janeiro</option>
                            <option value="02">Fevereiro</option>
                            <option value="03">Março</option>
                            <option value="04">Abril</option>
                            <option value="05">Maio</option>
                            <option value="06">Junho</option>
                            <option value="07">Julho</option>
                            <option value="08">Agosto</option>
                            <option value="09">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                    </Col>
                    <Col xs={4} md={3} lg={3} xl={1} >
                        <select name="month" id="month" onChange={e => setMesConsultaFinal(e.target.value)}>
                            <option value="Mes">Fim</option>
                            <option value="01">Janeiro</option>
                            <option value="02">Fevereiro</option>
                            <option value="03">Março</option>
                            <option value="04">Abril</option>
                            <option value="05">Maio</option>
                            <option value="06">Junho</option>
                            <option value="07">Julho</option>
                            <option value="08">Agosto</option>
                            <option value="09">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                        </select>
                    </Col>
                    <Col xs={4} md={1} lg={1} xl={1} >
                        <select name="ano" id="ano" onChange={e => setAnoConsulta(e.target.value)}>
                            <option value="Ano">Ano</option>
                            {Array(new Date().getFullYear() - 2018).fill(1).map((a, idx) => <option key={'1' + idx} value={new Date().getFullYear() - idx}>{new Date().getFullYear() - idx}</option>)}
                        </select>
                    </Col>
                    <Col xs={6} md={1} lg={1} xl={1}>
                        <Button variant="secondary" onClick={() => loadData()}>Consultar</Button>
                    </Col>
                    <Col xs={0} md={1} lg={1} xl={1}>
                    </Col>
                    <Col xs={6} md={1} lg={1} xl={1} hidden={consultado}>
                        <Button variant="dark" onClick={() => setModalFilterShow([true])}><BiFilterAlt /></Button>
                    </Col>
                </Row>
            </Container>
            <Table bordered hover size='sm' className="analytics-table">
                <thead style={sticky}>
                    <tr style={{ backgroundColor: 'white' }}>
                        {mesConsulta && mesConsultaFinal
                            ? mesConsulta === mesConsultaFinal
                                ? <th style={{ fontSize: 'x-large' }} colSpan="15">DRE Analítico de {meses[parseInt(mesConsulta)]} de {anoConsulta} {abrePopoverBadge()}</th>
                                : <th style={{ fontSize: 'x-large' }} colSpan="15">DRE Analítico de {meses[parseInt(mesConsulta)]} até {meses[parseInt(mesConsultaFinal)]} de {anoConsulta}{abrePopoverBadge()}</th>
                            : <th style={{ fontSize: 'x-large' }} colSpan="15">DRE Analítico de _____ até ____ {abrePopoverBadge()}</th>
                        }
                    </tr>
                    <tr style={{ backgroundColor: 'white' }}><th style={{ fontSize: 'large' }} colSpan="15" >{unidadesSelecionadas}</th></tr>
                    <tr style={{ fontSize: 'medium', cursor: 'default' }}>
                        <th colSpan='2' style={{ zIndex: 2, background: '#119e91', color: 'white' }} className="analytics-table-subtitle stickyCollumns" width="20%">Contas</th>
                        {Array.from({ length: 12 }, (_, i) => i + 1)
                            .filter(month => mesConsulta <= month && mesConsultaFinal >= month)
                            .map(month => (
                                <th className="analytics-table-subtitle" key={month}>
                                    {new Date(0, month - 1).toLocaleString('pt-BR', { month: 'long' })}
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip

                                                style={{ zIndex: 100000 }}
                                            >
                                                Visualizar Razao Mensal
                                            </Tooltip>
                                        }
                                    >
                                        <FaChartLine style={{ width: 20, cursor: "pointer" }} onClick={() => { setAllMesesData(month.toString().padStart(2, '0')); fetchModalMesesData(month.toString().padStart(2, '0')) }} />
                                    </OverlayTrigger>
                                </th>
                            ))
                        }

                        {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow && (
                            <th className="analytics-table-subtitle">Total</th>
                        )}
                    </tr>

                    <tr hidden={filtro.noColab} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                        <td colSpan='2' className="analytics-dre stickyCollumns">Nº Colaboradores
                            <DropdownButton
                                as={ButtonGroup}
                                id={`dropdown-button-drop-Colab`}
                                size="sm"
                                variant="primary"
                                title={myNoColab.active}
                            >
                                <Dropdown.Item eventKey="1" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Almoxarifado' })}>Almoxarifado</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Compras' })}>Compras</Dropdown.Item>
                                <Dropdown.Item eventKey="3" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Controladoria' })}>Controladoria</Dropdown.Item>
                                <Dropdown.Item eventKey="4" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Cozinha' })}>Cozinha</Dropdown.Item>
                                <Dropdown.Item eventKey="5" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Eventos' })}>Eventos</Dropdown.Item>
                                <Dropdown.Item eventKey="6" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Limpeza' })}>Limpeza</Dropdown.Item>
                                <Dropdown.Item eventKey="7" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Marketing' })}>Marketing</Dropdown.Item>
                                <Dropdown.Item eventKey="8" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Tecnologia da Informação' })}>Tecnologia da Informação</Dropdown.Item>
                                <Dropdown.Item eventKey="9" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Vendas' })}>Vendas</Dropdown.Item>
                                <Dropdown.Item eventKey="10" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Recepção' })}>Recepção</Dropdown.Item>
                                <Dropdown.Item eventKey="11" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Governança' })}>Governança</Dropdown.Item>
                                <Dropdown.Item eventKey="12" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Manutenção' })}>Manutenção</Dropdown.Item>
                                <Dropdown.Item eventKey="13" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Gerencia' })}>Gerencia</Dropdown.Item>
                                <Dropdown.Item eventKey="14" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Restaurante' })}>Restaurante</Dropdown.Item>
                                <Dropdown.Item eventKey="15" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Portaria' })}>Portaria</Dropdown.Item>
                                {/* <Dropdown.Item eventKey="16" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Afastados' })}>Afastados</Dropdown.Item> */}
                                <Dropdown.Item eventKey="17" onSelect={() => setMyNoColab({ ...myNoColab, active: 'Total' })}>Total</Dropdown.Item>
                            </DropdownButton></td>
                        {mesConsulta <= 1 && mesConsultaFinal >= 1
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 1).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 2 && mesConsultaFinal >= 2
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 2).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 3 && mesConsultaFinal >= 3
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 3).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 4 && mesConsultaFinal >= 4
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 4).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 5 && mesConsultaFinal >= 5
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 5).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 6 && mesConsultaFinal >= 6
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 6).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 7 && mesConsultaFinal >= 7
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 7).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 8 && mesConsultaFinal >= 8
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 8).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 9 && mesConsultaFinal >= 9
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 9).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 10 && mesConsultaFinal >= 10
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 10).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 11 && mesConsultaFinal >= 11
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 11).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                        {mesConsulta <= 12 && mesConsultaFinal >= 12
                            ? <td>
                                {myNoColab.data ? myNoColab.active === 'Almoxarifado' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.almoxarifado, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Compras' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.compras, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Controladoria' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.controladoria, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Cozinha' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.cozinha, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Eventos' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.eventos, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Limpeza' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.limpeza, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Marketing' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.marketing, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Tecnologia da Informação' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.tecnologiadainformacao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Vendas' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.vendas, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Recepção' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.recepcao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Governança' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.governanca, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Manutenção' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.manutencao, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Gerencia' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.gerencia, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Restaurante' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.restaurante, 0) : null : null}
                                {myNoColab.data ? myNoColab.active === 'Portaria' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.portaria, 0) : null : null}
                                {/* {myNoColab.data ? myNoColab.active === 'Afastados' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.afastados, 0) : null : null} */}
                                {myNoColab.data ? myNoColab.active === 'Total' ? myNoColab.data.filter(d => d.mes === 12).reduce((acc, cv) => acc + cv.total, 0) : null : null}
                            </td>
                            : null
                        }
                    </tr>
                    {registros.map((registro, idx) => {
                        if (registro.id === 'OCC_PERC') {
                            return (<tr key={UnidadeKeyAtual + '2' + idx} hidden={filtro.txRO} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                <td colSpan='2' className="analytics-dre stickyCollumns">{registro.descricao}</td>
                                {mesConsulta <= 1 && mesConsultaFinal >= 1
                                    ? <td>{isNaN((registro.janeiro / registro.janeiroqtd) / 100) ? "0%" : ((registro.janeiro / registro.janeiroqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 2 && mesConsultaFinal >= 2
                                    ? <td>{isNaN((registro.fevereiro / registro.fevereiroqtd) / 100) ? "0%" : ((registro.fevereiro / registro.fevereiroqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 3 && mesConsultaFinal >= 3
                                    ? <td>{isNaN((registro.marco / registro.marcoqtd) / 100) ? "0%" : ((registro.marco / registro.marcoqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 4 && mesConsultaFinal >= 4
                                    ? <td>{isNaN((registro.abril / registro.abrilqtd) / 100) ? "0%" : ((registro.abril / registro.abrilqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 5 && mesConsultaFinal >= 5
                                    ? <td>{isNaN((registro.maio / registro.maioqtd) / 100) ? "0%" : ((registro.maio / registro.maioqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 6 && mesConsultaFinal >= 6
                                    ? <td>{isNaN((registro.junho / registro.junhoqtd) / 100) ? "0%" : ((registro.junho / registro.junhoqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 7 && mesConsultaFinal >= 7
                                    ? <td>{isNaN((registro.julho / registro.julhoqtd) / 100) ? "0%" : ((registro.julho / registro.julhoqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 8 && mesConsultaFinal >= 8
                                    ? <td>{isNaN((registro.agosto / registro.agostoqtd) / 100) ? "0%" : ((registro.agosto / registro.agostoqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 9 && mesConsultaFinal >= 9
                                    ? <td>{isNaN((registro.setembro / registro.setembroqtd) / 100) ? "0%" : ((registro.setembro / registro.setembroqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 10 && mesConsultaFinal >= 10
                                    ? <td>{isNaN((registro.outubro / registro.outubroqtd) / 100) ? "0%" : ((registro.outubro / registro.outubroqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 11 && mesConsultaFinal >= 11
                                    ? <td>{isNaN((registro.novembro / registro.novembroqtd) / 100) ? "0%" : ((registro.novembro / registro.novembroqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta <= 12 && mesConsultaFinal >= 12
                                    ? <td>{isNaN((registro.dezembro / registro.dezembroqtd) / 100) ? "0%" : ((registro.dezembro / registro.dezembroqtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                                {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                    ? <td>{isNaN((registro.total / registro.qtd) / 100) ? "0%" : ((registro.total / registro.qtd) / 100).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                    : null
                                }
                            </tr>)
                        } else if (registro.id === 'ADR_ROOM') {
                            return (<tr key={UnidadeKeyAtual + '3' + idx} hidden={filtro.adr} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                <td colSpan='2' className="analytics-dre stickyCollumns">{registro.descricao}</td>
                                {mesConsulta <= 1 && mesConsultaFinal >= 1
                                    ? <td>{isNaN(registro.janeiro / registro.janeiroqtd) ? "R$ 0,00" : (registro.janeiro / registro.janeiroqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 2 && mesConsultaFinal >= 2
                                    ? <td>{isNaN(registro.fevereiro / registro.fevereiroqtd) ? "R$ 0,00" : (registro.fevereiro / registro.fevereiroqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 3 && mesConsultaFinal >= 3
                                    ? <td>{isNaN(registro.marco / registro.marcoqtd) ? "R$ 0,00" : (registro.marco / registro.marcoqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 4 && mesConsultaFinal >= 4
                                    ? <td>{isNaN(registro.abril / registro.abrilqtd) ? "R$ 0,00" : (registro.abril / registro.abrilqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 5 && mesConsultaFinal >= 5
                                    ? <td>{isNaN(registro.maio / registro.maioqtd) ? "R$ 0,00" : (registro.maio / registro.maioqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 6 && mesConsultaFinal >= 6
                                    ? <td>{isNaN(registro.junho / registro.junhoqtd) ? "R$ 0,00" : (registro.junho / registro.junhoqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 7 && mesConsultaFinal >= 7
                                    ? <td>{isNaN(registro.julho / registro.julhoqtd) ? "R$ 0,00" : (registro.julho / registro.julhoqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 8 && mesConsultaFinal >= 8
                                    ? <td>{isNaN(registro.agosto / registro.agostoqtd) ? "R$ 0,00" : (registro.agosto / registro.agostoqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 9 && mesConsultaFinal >= 9
                                    ? <td>{isNaN(registro.setembro / registro.setembroqtd) ? "R$ 0,00" : (registro.setembro / registro.setembroqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 10 && mesConsultaFinal >= 10
                                    ? <td>{isNaN(registro.outubro / registro.outubroqtd) ? "R$ 0,00" : (registro.outubro / registro.outubroqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 11 && mesConsultaFinal >= 11
                                    ? <td>{isNaN(registro.novembro / registro.novembroqtd) ? "R$ 0,00" : (registro.novembro / registro.novembroqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta <= 12 && mesConsultaFinal >= 12
                                    ? <td>{isNaN(registro.dezembro / registro.dezembroqtd) ? "R$ 0,00" : (registro.dezembro / registro.dezembroqtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                                {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                    ? <td>{isNaN(registro.total / registro.qtd) ? "R$ 0,00" : (registro.total / registro.qtd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    : null
                                }
                            </tr>)
                        } else if (registro.id === 'PHYSICAL_ROOMS') {
                            return (<>
                                <tr key={UnidadeKeyAtual + '4' + idx} hidden={filtro.trih} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                    <td colSpan='2' className="analytics-dre stickyCollumns">{registro.descricao}</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                    {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                        ? <td>{(JSON.parse(sessionStorage.getItem('unidades')).filter(d => selected.reduce((acc, cv) => acc = [...acc, cv.value], []).includes(d.id)).reduce((acc, cv) => acc += cv.uhs, 0)) + " UHs"}</td>
                                        : null
                                    }
                                </tr>
                                <tr key={UnidadeKeyAtual + '5' + idx} hidden={filtro.rn} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                    <td colSpan='2' className="analytics-dre stickyCollumns">Room Nights</td>
                                    {mesConsulta <= 1 && mesConsultaFinal >= 1
                                        ? <td>{isNaN(registro.janeiro) ? "0 RNs" : (registro.janeiro) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 2 && mesConsultaFinal >= 2
                                        ? <td>{isNaN(registro.fevereiro) ? "0 RNs" : (registro.fevereiro) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 3 && mesConsultaFinal >= 3
                                        ? <td>{isNaN(registro.marco) ? "0 RNs" : (registro.marco) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 4 && mesConsultaFinal >= 4
                                        ? <td>{isNaN(registro.abril) ? "0 RNs" : (registro.abril) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 5 && mesConsultaFinal >= 5
                                        ? <td>{isNaN(registro.maio) ? "0 RNs" : (registro.maio) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 6 && mesConsultaFinal >= 6
                                        ? <td>{isNaN(registro.junho) ? "0 RNs" : (registro.junho) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 7 && mesConsultaFinal >= 7
                                        ? <td>{isNaN(registro.julho) ? "0 RNs" : (registro.julho) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 8 && mesConsultaFinal >= 8
                                        ? <td>{isNaN(registro.agosto) ? "0 RNs" : (registro.agosto) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 9 && mesConsultaFinal >= 9
                                        ? <td>{isNaN(registro.setembro) ? "0 RNs" : (registro.setembro) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 10 && mesConsultaFinal >= 10
                                        ? <td>{isNaN(registro.outubro) ? "0 RNs" : (registro.outubro) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 11 && mesConsultaFinal >= 11
                                        ? <td>{isNaN(registro.novembro) ? "0 RNs" : (registro.novembro) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta <= 12 && mesConsultaFinal >= 12
                                        ? <td>{isNaN(registro.dezembro) ? "0 RNs" : (registro.dezembro) + ' RNs'}</td>
                                        : null
                                    }
                                    {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                        ? <td>{isNaN(registro.total) ? "0 RNs" : (registro.total) + ' RNs'}</td>
                                        : null
                                    }
                                </tr>
                            </>)
                        } else if (registro.id === 'OCC_ROOMS') {
                            return (<tr key={UnidadeKeyAtual + '6' + idx} hidden={filtro.ro} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                <td colSpan='2' className="analytics-dre stickyCollumns">{registro.descricao}</td>
                                {mesConsulta <= 1 && mesConsultaFinal >= 1
                                    ? <td>{isNaN(registro.janeiro) ? "0 UHs" : (registro.janeiro) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 2 && mesConsultaFinal >= 2
                                    ? <td>{isNaN(registro.fevereiro) ? "0 UHs" : (registro.fevereiro) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 3 && mesConsultaFinal >= 3
                                    ? <td>{isNaN(registro.marco) ? "0 UHs" : (registro.marco) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 4 && mesConsultaFinal >= 4
                                    ? <td>{isNaN(registro.abril) ? "0 UHs" : (registro.abril) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 5 && mesConsultaFinal >= 5
                                    ? <td>{isNaN(registro.maio) ? "0 UHs" : (registro.maio) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 6 && mesConsultaFinal >= 6
                                    ? <td>{isNaN(registro.junho) ? "0 UHs" : (registro.junho) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 7 && mesConsultaFinal >= 7
                                    ? <td>{isNaN(registro.julho) ? "0 UHs" : (registro.julho) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 8 && mesConsultaFinal >= 8
                                    ? <td>{isNaN(registro.agosto) ? "0 UHs" : (registro.agosto) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 9 && mesConsultaFinal >= 9
                                    ? <td>{isNaN(registro.setembro) ? "0 UHs" : (registro.setembro) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 10 && mesConsultaFinal >= 10
                                    ? <td>{isNaN(registro.outubro) ? "0 UHs" : (registro.outubro) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 11 && mesConsultaFinal >= 11
                                    ? <td>{isNaN(registro.novembro) ? "0 UHs" : (registro.novembro) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta <= 12 && mesConsultaFinal >= 12
                                    ? <td>{isNaN(registro.dezembro) ? "0 UHs" : (registro.dezembro) + ' UHs'}</td>
                                    : null
                                }
                                {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                    ? <td>{isNaN(registro.total) ? "0 UHs" : (registro.total) + ' UHs'} </td>
                                    : null
                                }
                            </tr>)
                        } else if (registro.id === 'ADULTS_IN_HOUSE') {
                            return (<tr key={UnidadeKeyAtual + '7' + idx} hidden={filtro.iha} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                <td colSpan='2' className="analytics-dre stickyCollumns">{registro.descricao}</td>
                                {mesConsulta <= 1 && mesConsultaFinal >= 1
                                    ? <td>{isNaN(registro.janeiro) ? "0 " : (registro.janeiro)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 2 && mesConsultaFinal >= 2
                                    ? <td>{isNaN(registro.fevereiro) ? "0 " : (registro.fevereiro)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 3 && mesConsultaFinal >= 3
                                    ? <td>{isNaN(registro.marco) ? "0 " : (registro.marco)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 4 && mesConsultaFinal >= 4
                                    ? <td>{isNaN(registro.abril) ? "0 " : (registro.abril)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 5 && mesConsultaFinal >= 5
                                    ? <td>{isNaN(registro.maio) ? "0 " : (registro.maio)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 6 && mesConsultaFinal >= 6
                                    ? <td>{isNaN(registro.junho) ? "0 " : (registro.junho)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 7 && mesConsultaFinal >= 7
                                    ? <td>{isNaN(registro.julho) ? "0 " : (registro.julho)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 8 && mesConsultaFinal >= 8
                                    ? <td>{isNaN(registro.agosto) ? "0 " : (registro.agosto)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 9 && mesConsultaFinal >= 9
                                    ? <td>{isNaN(registro.setembro) ? "0 " : (registro.setembro)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 10 && mesConsultaFinal >= 10
                                    ? <td>{isNaN(registro.outubro) ? "0 " : (registro.outubro)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 11 && mesConsultaFinal >= 11
                                    ? <td>{isNaN(registro.novembro) ? "0 " : (registro.novembro)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta <= 12 && mesConsultaFinal >= 12
                                    ? <td>{isNaN(registro.dezembro) ? "0 " : (registro.dezembro)}<VscPerson /></td>
                                    : null
                                }
                                {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                    ? <td>{isNaN(registro.total) ? "0 " : (registro.total)} <VscPerson /> </td>
                                    : null
                                }
                            </tr>)
                        } else if (registro.id === 'CHILDREN_IN_HOUSE') {
                            return (<tr key={UnidadeKeyAtual + '8' + idx} hidden={filtro.ihc} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                <td colSpan='2' className="analytics-dre stickyCollumns">{registro.descricao}</td>
                                {mesConsulta <= 1 && mesConsultaFinal >= 1
                                    ? <td>{isNaN(registro.janeiro) ? "0 " : (registro.janeiro)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 2 && mesConsultaFinal >= 2
                                    ? <td>{isNaN(registro.fevereiro) ? "0 " : (registro.fevereiro)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 3 && mesConsultaFinal >= 3
                                    ? <td>{isNaN(registro.marco) ? "0 " : (registro.marco)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 4 && mesConsultaFinal >= 4
                                    ? <td>{isNaN(registro.abril) ? "0 " : (registro.abril)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 5 && mesConsultaFinal >= 5
                                    ? <td>{isNaN(registro.maio) ? "0 " : (registro.maio)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 6 && mesConsultaFinal >= 6
                                    ? <td>{isNaN(registro.junho) ? "0 " : (registro.junho)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 7 && mesConsultaFinal >= 7
                                    ? <td>{isNaN(registro.julho) ? "0 " : (registro.julho)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 8 && mesConsultaFinal >= 8
                                    ? <td>{isNaN(registro.agosto) ? "0 " : (registro.agosto)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 9 && mesConsultaFinal >= 9
                                    ? <td>{isNaN(registro.setembro) ? "0 " : (registro.setembro)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 10 && mesConsultaFinal >= 10
                                    ? <td>{isNaN(registro.outubro) ? "0 " : (registro.outubro)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 11 && mesConsultaFinal >= 11
                                    ? <td>{isNaN(registro.novembro) ? "0 " : (registro.novembro)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta <= 12 && mesConsultaFinal >= 12
                                    ? <td>{isNaN(registro.dezembro) ? "0 " : (registro.dezembro)} <MdChildCare /></td>
                                    : null
                                }
                                {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                    ? <td>{isNaN(registro.total) ? "0 " : (registro.total)} <MdChildCare /> </td>
                                    : null
                                }
                            </tr>)
                        } else if (registro.id === 'GUEST_IN_HOUSE') {
                            return (<tr key={UnidadeKeyAtual + '9' + idx} hidden={filtro.tihp} style={{ fontWeight: 700, cursor: 'default', background: '#e8e8e8', fontSize: 'small' }}>
                                <td colSpan='2' className="analytics-dre stickyCollumns">{registro.descricao}</td>
                                {mesConsulta <= 1 && mesConsultaFinal >= 1
                                    ? <td>{isNaN(registro.janeiro) ? "0 " : (registro.janeiro)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 2 && mesConsultaFinal >= 2
                                    ? <td>{isNaN(registro.fevereiro) ? "0 " : (registro.fevereiro)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 3 && mesConsultaFinal >= 3
                                    ? <td>{isNaN(registro.marco) ? "0 " : (registro.marco)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 4 && mesConsultaFinal >= 4
                                    ? <td>{isNaN(registro.abril) ? "0 " : (registro.abril)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 5 && mesConsultaFinal >= 5
                                    ? <td>{isNaN(registro.maio) ? "0 " : (registro.maio)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 6 && mesConsultaFinal >= 6
                                    ? <td>{isNaN(registro.junho) ? "0 " : (registro.junho)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 7 && mesConsultaFinal >= 7
                                    ? <td>{isNaN(registro.julho) ? "0 " : (registro.julho)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 8 && mesConsultaFinal >= 8
                                    ? <td>{isNaN(registro.agosto) ? "0 " : (registro.agosto)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 9 && mesConsultaFinal >= 9
                                    ? <td>{isNaN(registro.setembro) ? "0 " : (registro.setembro)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 10 && mesConsultaFinal >= 10
                                    ? <td>{isNaN(registro.outubro) ? "0 " : (registro.outubro)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 11 && mesConsultaFinal >= 11
                                    ? <td>{isNaN(registro.novembro) ? "0 " : (registro.novembro)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta <= 12 && mesConsultaFinal >= 12
                                    ? <td>{isNaN(registro.dezembro) ? "0 " : (registro.dezembro)} <BsPeople /></td>
                                    : null
                                }
                                {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                    ? <td>{isNaN(registro.total) ? "0 " : (registro.total)} <BsPeople /> </td>
                                    : null
                                }
                            </tr>)
                        }
                    })}
                </thead>
                <tbody>
                    {codigosDre.map((codigo, idx) => {
                        var classes = 'linha-' + codigo.contasuperior
                        if ((codigo.composicao && dreData.filter(d => codigo.composicao.includes(d.codigodre_id)).reduce((acc, cv) => acc += Math.pow(cv.valor, 2), 0) === 0)) {
                            classes = classes + ' linha-zerada'
                        }
                        if ((codigo.composicao === null && dreData.filter(d => d.codigodre_id === codigo.codigo_id).reduce((acc, cv) => acc += Math.pow(cv.valor, 2), 0) === 0)) {
                            classes = classes + ' linha-zerada'
                        }

                        return (
                            <tr key={UnidadeKeyAtual + '10' + idx} id={'linha-' + codigo.codigo_id} hidden={classes.includes('linha-zerada') ? true : codigo.nivel >= 2 ? true : false}
                                onClick={!codigo.destaque && codigo.composicao ? () => { hideShow('linha-' + codigo.codigo_id) } : null} className={classes} style={codigo.destaque ? { fontSize: 'small', background: '#ebeaea', whiteSpace: 'nowrap', fontWeight: 'bold' } : { whiteSpace: 'nowrap', fontSize: 'small', backgroundColor: 'white' }}>
                                <td colSpan='2' onClick={contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? () => { setModalContratosShow([true, codigo.descricao, codigo.codigo_id]) } : null} style={codigo.destaque ? { fontSize: 'small', background: 'inherit', whiteSpace: 'nowrap', fontWeight: 'bold' } : { whiteSpace: 'nowrap', fontSize: 'small' }} className="analytics-dre stickyCollumns"> {contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? <GrDocument /> : null} {codigo.codigo_id === codigo.descricao ? codigo.codigo_id : contratos.find(contrato => contrato.planodecontas_id === codigo.codigo_id) ? codigo.codigo_id + ' - ' + codigo.descricao : '• ' + codigo.codigo_id + ' - ' + codigo.descricao}</td>
                                {Array(12).fill(1).map((el, idx) => {
                                    if (mesConsulta <= (idx + 1) && mesConsultaFinal >= (idx + 1)) {
                                        if ((codigo.codigo_id === '320101' || codigo.codigo_id === 'RECEITA DE ALIMENTOS E BEBIDAS' || codigo.codigo_id === 'RECEITA  BRUTA' || codigo.codigo_id === 'RECEITA LIQUIDA') && selected.find(d => d.value.includes('R')) && selected.find(d => d.value.includes('H')) && anoConsulta >= 2021 && (willConsolidar.limitemes > (idx + 1) || willConsolidar.limiteano > anoConsulta)) {
                                            const valorCalculado = dreData?.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0) + dreData?.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0);
                                            return (
                                                <td key={UnidadeKeyAtual + '11' + idx} onClick={codigo.composicao === null ? () => openRazao(codigo.codigo_id, (idx + 1), valorCalculado) : null}
                                                    onContextMenu={codigo.composicao === null ? (e) => { e.preventDefault(); openRazao(codigo.codigo_id, (idx + 1), valorCalculado) } : codigo.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, codigo.descricao, codigo, [(idx + 1)], "realizado"]); setNivelContaAtual('sintetica') }}>{codigo.composicao
                                                        ? (dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0) + dreData.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                        : (valorCalculado?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}
                                                    {codigo.composicao ? verificaStatusIconSintetica(codigo, (idx + 1)) : verificaStatusIconAnalitica(codigo, (idx + 1))}
                                                    {verificaVariação(codigo, idx, idx + 1)}
                                                    <div className="valorTaxaVertical" hidden={variacaoHidden.vertical}>{variacaoVertical(codigo, (idx + 1))}</div></td>
                                            )
                                        } else if ((codigo.codigo_id === '410101' || codigo.codigo_id === 'CUSTOS DE ALIMENTOS E BEBIDAS' || codigo.codigo_id === 'TOTAL DOS CUSTOS' || codigo.codigo_id === 'TOTAL DE CUSTOS E DESPESAS OPERACIONAIS') && selected.find(d => d.value.includes('R')) && selected.find(d => d.value.includes('H')) && anoConsulta >= 2021 && (willConsolidar.limitemes > (idx + 1) || willConsolidar.limiteano > anoConsulta)) {
                                            const valorCalculado = dreData?.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0) - dreData?.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0);
                                            return (
                                                <td key={UnidadeKeyAtual + '12' + idx} onClick={codigo.composicao === null ? () => openRazao(codigo.codigo_id, (idx + 1), valorCalculado) : null}
                                                    onContextMenu={codigo.composicao === null ? (e) => { e.preventDefault(); openRazao(codigo.codigo_id, (idx + 1), valorCalculado) } : codigo.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, codigo.descricao, codigo, [(idx + 1)], "realizado"]); setNivelContaAtual('sintetica') }}>{codigo.composicao
                                                        ? (dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0) - dreData.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                        : (valorCalculado?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}
                                                    {codigo.composicao ? verificaStatusIconSintetica(codigo, (idx + 1)) : verificaStatusIconAnalitica(codigo, (idx + 1))}
                                                    {verificaVariação(codigo, idx, idx + 1)}
                                                    <div className="valorTaxaVertical" hidden={variacaoHidden.vertical}>{variacaoVertical(codigo, (idx + 1))}</div></td>
                                            )
                                        } else {
                                            const valorCalculado = dreData.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0);
                                            return (
                                                <td key={UnidadeKeyAtual + '13' + idx} onClick={codigo.composicao === null ? () => openRazao(codigo.codigo_id, (idx + 1), valorCalculado) : null}
                                                    onContextMenu={codigo.composicao === null ? (e) => { e.preventDefault(); openRazao(codigo.codigo_id, (idx + 1), valorCalculado) } : codigo.destaque === true ? null : (e) => { e.preventDefault(); setModalComentariosShow([true, codigo.descricao, codigo, [(idx + 1)], "realizado"]); setNivelContaAtual('sintetica') }}>{codigo.composicao
                                                        ? dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) === (idx + 1)).reduce((acc, cv) => acc += cv.valor, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                        : valorCalculado?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                    {codigo.composicao ? verificaStatusIconSintetica(codigo, (idx + 1)) : verificaStatusIconAnalitica(codigo, (idx + 1))}
                                                    {verificaVariação(codigo, idx, idx + 1)}
                                                    <div className="valorTaxaVertical" hidden={variacaoHidden.vertical}>{variacaoVertical(codigo, (idx + 1))}</div></td>
                                            )
                                        }
                                    }
                                })}
                                {mesConsulta && mesConsultaFinal && (parseInt(mesConsultaFinal) - parseInt(mesConsulta)) > 0 && filtro.totalShow
                                    ? (codigo.codigo_id === '320101' || codigo.codigo_id === 'RECEITA DE ALIMENTOS E BEBIDAS' || codigo.codigo_id === 'RECEITA  BRUTA' || codigo.codigo_id === 'RECEITA LIQUIDA') && selected.find(d => d.value.includes('R')) && selected.find(d => d.value.includes('H')) && anoConsulta >= 2021
                                        ? <td key={UnidadeKeyAtual}>
                                            {codigo.composicao
                                                ? (dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal).reduce((acc, cv) => acc += cv.valor, 0) + dreData.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal && (willConsolidar.limitemes > parseInt(d.data.substring(5, 7)) || willConsolidar.limiteano > anoConsulta)).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                : (dreData.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal).reduce((acc, cv) => acc += cv.valor, 0) + dreData.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal && (willConsolidar.limitemes > parseInt(d.data.substring(5, 7)) || willConsolidar.limiteano > anoConsulta)).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            {/* {verificaVariação(codigo, '11', '12')} */}
                                            <div className="valorTaxaVertical" hidden={variacaoHidden.vertical}>{variacaoVertical(codigo, 'total')}</div>
                                        </td>
                                        : (codigo.codigo_id === '410101' || codigo.codigo_id === 'CUSTOS DE ALIMENTOS E BEBIDAS' || codigo.codigo_id === 'TOTAL DOS CUSTOS' || codigo.codigo_id === 'TOTAL DE CUSTOS E DESPESAS OPERACIONAIS') && selected.find(d => d.value.includes('R')) && selected.find(d => d.value.includes('H')) && anoConsulta >= 2021
                                            ? <td key={UnidadeKeyAtual}>
                                                {codigo.composicao
                                                    ? (dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal).reduce((acc, cv) => acc += cv.valor, 0) - dreData.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal && (willConsolidar.limitemes > parseInt(d.data.substring(5, 7)) || willConsolidar.limiteano > anoConsulta)).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                    : (dreData.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal).reduce((acc, cv) => acc += cv.valor, 0) - dreData.filter(d => d.codigodre_id === '410101' && d.unidade_id.includes('H') && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal && (willConsolidar.limitemes > parseInt(d.data.substring(5, 7)) || willConsolidar.limiteano > anoConsulta)).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                {/* {verificaVariação(codigo, '11', '12')} */}
                                                <div className="valorTaxaVertical" hidden={variacaoHidden.vertical}>{variacaoVertical(codigo, 'total')}</div>
                                            </td>
                                            : <td key={UnidadeKeyAtual}>
                                                {codigo.composicao
                                                    ? (dreData.filter(d => codigo.composicao.includes(d.codigodre_id) && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                    : (dreData.filter(d => d.codigodre_id === codigo.codigo_id && parseInt(d.data.substring(5, 7)) >= mesConsulta && parseInt(d.data.substring(5, 7)) <= mesConsultaFinal).reduce((acc, cv) => acc += cv.valor, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                {/* {verificaVariação(codigo, '11', '12')} */}
                                                <div className="valorTaxaVertical" hidden={variacaoHidden.vertical}>{variacaoVertical(codigo, 'total')}</div>
                                            </td>
                                    : null
                                }
                            </tr>
                        )

                    })}
                </tbody>
            </Table>
            <Modal
                show={modalFilterShow[0]}
                onHide={() => setModalFilterShow([false])}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='lg'
                scrollable
            >
                <Modal.Header closeButton style={{ gap: '15px' }}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="danger" style={{ marginRight: '8px' }} disabled><BiFilterAlt /></Button> Filtro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row xs={1} md={2}>
                        <Col>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => { setFiltro({ ...filtro, fix: !filtro.fix }); if (!filtro.fix) { setSticky({}) } else { setSticky({ position: 'sticky', top: 0 }) } }} >
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.fix} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Fixar cabeçalho'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, totalShow: !filtro.totalShow })} >
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={filtro.totalShow} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Mostrar Total'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => setVariacaoHidden({ ...variacaoHidden, horizontal: !variacaoHidden.horizontal })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!variacaoHidden.horizontal} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Mostrar variação Horizontal'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px' }} onClick={() => { if (!variacaoHidden.vertical && baseVertical.id !== null) { document.getElementById('linha-' + baseVertical.id).classList.remove('selectedVertical'); setBaseVertical({ id: null }) }; setVariacaoHidden({ ...variacaoHidden, vertical: !variacaoHidden.vertical }) }} >
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!variacaoHidden.vertical} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Mostrar variação vertical'} disabled />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, txRO: !filtro.txRO })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.txRO} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Taxa de Ocupação'} disabled />
                            </InputGroup>

                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, adr: !filtro.adr })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.adr} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Diária média (ADR)'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, trih: !filtro.trih })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.trih} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Número de UHs'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, rn: !filtro.rn })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.rn} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Room Nights'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, ro: !filtro.ro })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.ro} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'UHs Ocupadas'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, iha: !filtro.iha })} >
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.iha} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Adultos In-House'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, ihc: !filtro.ihc })} >
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.ihc} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Crianças In-House'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, tihp: !filtro.tihp })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.tihp} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Total de pessoas In-House'} disabled />
                            </InputGroup>
                            <InputGroup style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => setFiltro({ ...filtro, noColab: !filtro.noColab })}>
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={!filtro.noColab} />
                                <FormControl style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer' }} aria-label="Text input with checkbox" value={'Numero de Colaboradores'} disabled />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Form id="formFiltroDRE" hidden={variacaoHidden.horizontal}
                    >
                        <BuildForm size={numeroDeIntervalos} />
                    </Form>
                    <Row xs={2}>
                        <Form.Group as={Col} hidden={variacaoHidden.vertical}>
                            <Form.Label>Conta para comparação:</Form.Label>
                            <Form.Control as="select" onChange={e => handleBase(e.target.value)}>
                                <option value={null}>Selecione uma conta</option>
                                <optgroup label="Contas Sintéticas">
                                    {codigosDre.filter(d => d.composicao !== null).map((d, idx) => (<option key={UnidadeKeyAtual + '14' + idx} value={d.codigo_id}>{d.descricao}</option>))}
                                </optgroup>
                                <optgroup label="Contas Analíticas">
                                    {codigosDre.filter(d => d.composicao === null).map((d, idx) => (<option key={UnidadeKeyAtual + '15' + idx} value={d.codigo_id}>{d.descricao}</option>))}
                                </optgroup>
                            </Form.Control>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='sm' onClick={() => { setModalFilterShow([false]); if (!variacaoHidden.horizontal) { styleFilter() } }}>Filtrar</Button>
                </Modal.Footer>
            </Modal>

            <ModalRazao
                loadData={loadData}
                open={modalShow[0]}
                onClose={() => { setModalShow([0]); setRazaoData([]) }
                }
                onClickButton={() => { setModalShow([0]); setRazaoData([]) }
                }
                title={modalShow[1]}
                searchInput={
                    <FormControl
                        type="search"
                        placeholder="Buscar registro"
                        aria-label="searchRazaoBar"
                        aria-describedby="basic-addon1"
                        value={searchRazaoText}
                        onChange={e => { setSearchRazaoText(e.target.value); filtraRazao(e.target.value); }}
                    />
                }
                setRazaoData={setRazaoData}
                anexosRazao={anexosRazao}
                comentarios={comentarios}
                notify={notify}
                razaoData={razaoData}
                setModalNovoComentarios={setModalNovoComentarios}
                setModalComentariosShow={setModalComentariosShow}
                setNivelContaAtual={setNivelContaAtual}
                modalShow={modalShow[2]}
            />
            <ModalRazaoAllContas
                offset={offset}
                fetchModalMesesData={fetchModalMesesData}
                selectedMonth={allMesesData}
                open={modalRazaoAllMonths}
                onClose={() => { setModalRazaoAllMonths(false); setRazaoData([]); setOffset(0); }
                }
                onClickButton={() => { setModalRazaoAllMonths(false); setRazaoData([]); setOffset(0); }
                }
                title={unidadesSelecionadas}
                isSearchEmpty={isSearchEmpty}
                isLoading={isLoading}
                searchInput={
                    <FormControl
                        type="search"
                        id='searchInput'
                        placeholder="Buscar registro"
                        aria-label="searchRazaoBar"
                        aria-describedby="basic-addon1"
                        onChange={(event) => { handleSearchChange(event?.target?.value); setOffset(0); }}
                        onBlur={() => { setRazaoData([]); fetchModalMesesData(allMesesData); }}
                        onKeyDown={(event) => { handleKeyDown(event) }}
                    />
                }
                setRazaoData={setRazaoData}
                anexosRazao={anexosRazao}
                comentarios={comentarios}
                notify={notify}
                razaoData={razaoData}
                setModalNovoComentarios={setModalNovoComentarios}
                setModalComentariosShow={setModalComentariosShow}
                setNivelContaAtual={setNivelContaAtual}
                totalSaldo={totalSaldo}
                modalShow={modalShow[2]}
            />
            <ModalContratos contratos={contratos} modalContratosShow={modalContratosShow} onClickButton={() => setModalContratosShow([false, null, null])} open={modalContratosShow[0]} onClose={() => setModalContratosShow([false, null, null])} title='Contratos'
            />
            <ModalComentarios anexos={anexos} comentarios={comentarios}
                destinatarios={destinatarios} modalComentariosShow={modalComentariosShow} modalShow={modalShow} notify={notify} onClickButton={() => setModalComentariosShow([false, null, { id: null }])} onClose={() => setModalComentariosShow([false, null, { id: null }])}
                open={modalComentariosShow[0]} razaoData={razaoData} setModalNovoComentarios={setModalNovoComentarios} setModalRespostaVisible={setModalRespostaVisible}
                setNivelContaAtual={setNivelContaAtual} title='Comentarios' />
            <ModalNovoComentario
                anoConsulta={anoConsulta}
                loadData={loadData}
                title='Adicionar Comentário'
                onClose={() => setModalNovoComentarios([false])}
                open={modalNovoComentarios[0]}
                modalComentariosShow={modalComentariosShow}
                modalNovoComentarios={modalNovoComentarios}
                nivelContaAtual={nivelContaAtual}
                onClickButton={() => { setModalNovoComentarios([false]); setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
                selected={selected}
                setToolipText={setToolipText} tooltipText={tooltipText}
                notify={notify}

            />
            <ModalRespostas
                anoConsulta={anoConsulta}
                selected={selected}
                loadData={loadData}
                setToolipText={setToolipText}
                tooltipText={tooltipText}
                modalRespostaVisible={modalRespostaVisible}
                notify={notify}
                open={modalRespostaVisible[0]}
                onClose={() => setModalRespostaVisible([false, { nome: '', comentario: '' }])}
                onClickButton={() => { setModalRespostaVisible([false, { nome: '', comentario: '' }]);; setToolipText("Adicionar anexo"); document.getElementById("anexoAtualPergunta").value = null }}
                title='Adicione Resposta'
                modalComentariosShow={modalComentariosShow}
                nivelContaAtual={nivelContaAtual} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                style={{ zIndex: 1000000 }}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover />
        </div>
    )
}