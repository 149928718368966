// HotelSelectorModal.tsx
import React, { useState } from "react";
import SelectModal from "./selectmodal.tsx";
import Select from "react-select";

interface HotelSelectorModalProps {
  mockData: any;
  show: boolean;
  onHide: () => void;
  onConfirm: (selectedHotelName: string) => void;
}

interface SelectedHotel {
  value: string;
  label: string;
}

const HotelSelectorModal: React.FC<HotelSelectorModalProps> = ({
  show,
  onHide,
  onConfirm,
  mockData,
}) => {
  const [selectedHotels, setSelectedHotels] = useState<SelectedHotel[]>([]);
  const [comparisonMetric, setComparisonMetric] = useState({
    value: "all",
    label: "Todas as Metricas",
  });

  const comparisonOptions = [
    { value: "all", label: "Todas as Metricas" },
    { value: "aptsOcupados", label: "Apts Ocupados" },
    { value: "taxaOcupacao", label: "Taxa de Ocupação" },
    { value: "taxaOcupacaoAnterior", label: "Taxa Ocupacao Anterior" },
    { value: "diariaMedia", label: "Diari aMedia" },
    { value: "revPar", label: "RevPar" },
    { value: "receita", label: "Receita" },
  ];

  const handleMultipleHotelChange = (selectedOptions) => {
    setSelectedHotels(selectedOptions);
  };

  const handleComparisonChange = (selectedOption) => {
    setComparisonMetric(selectedOption);
  };

  const handleConfirm = () => {
    if (selectedHotels?.length > 0) {
      const selectedInfo = {
        hotels: selectedHotels?.map((h) => h.value),
        metric: comparisonMetric ? comparisonMetric.value : null,
      };
      onConfirm(selectedInfo);
    }
    onHide();
  };

  return (
    <SelectModal
      show={show}
      onHide={onHide}
      title="Selecionar Hotel"
      primaryButtonLabel="Confirmar"
      outlineButtonLabel="Cancelar"
      onPrimaryButtonClick={handleConfirm}
      moreSelects={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <h6>Selecione uma unidade</h6>
          <Select
            isMulti
            options={mockData.map((hotel) => ({
              value: hotel.nome,
              label: hotel.nome,
            }))}
            onChange={handleMultipleHotelChange}
            className="basic-multi-select"
            classNamePrefix="select"
          />
          <h6>Selecione o que gostaria de comparar (opcional)</h6>
          <Select
            options={comparisonOptions}
            onChange={handleComparisonChange}
            className="basic-single"
            classNamePrefix="select"
          />
        </div>
      }
    />
  );
};

export default HotelSelectorModal;
