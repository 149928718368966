/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Col, Row } from 'react-bootstrap'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { useCookies } from 'react-cookie';
// API
import api from '../../../services/api';

// redux
import { useDispatch } from 'react-redux'

export default function Performance() {

    const dispatch = useDispatch()

    const [cookies] = useCookies();
    const [H5223, setH5223] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H5691, setH5691] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H6497, setH6497] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H6522, setH6522] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H7437, setH7437] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H7547, setH7547] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H8180, setH8180] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H8182, setH8182] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H9327, setH9327] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H9449, setH9449] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [H9558, setH9558] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

    const [unidades, setUnidades] = useState([])
    const [dataConsulta, setDataConsulta] = useState(new Date());
    const [dataConsultaFinal, setDataConsultaFinal] = useState(new Date());

    useEffect(() => {
        var array = []
        JSON.parse(sessionStorage.getItem('unidadesMemory')).map(unidade => {
            array.push({ label: unidade.unidade, value: unidade.id })
        })
        setUnidades(array)
    }, [sessionStorage.getItem('unidadesMemory')])

    async function loadData() {
        dispatch({ type: 'OPEN_LOADING', text: "Analisando...", isLoading: true })
        var h5223 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h5691 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h6497 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h6522 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h7437 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h7547 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h8180 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h8182 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h9327 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h9449 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var h9558 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        var ini = dataConsulta.getFullYear() + "-" + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (dataConsulta.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
        var fini = dataConsultaFinal.getFullYear() + "-" + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + "-" + (dataConsultaFinal.getDate()).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })

        var codigos = 'ROOM_REVENUE,FOOD_BEV_REVENUE,OTHER_REVENUE,TOTAL_REVENUE,OCC_ROOMS,HOUSE_USE_ROOMS,DAYUSE_ROOMS,PHYSICAL_ROOMS'

        await api.get(`/performance/${ini}/${fini}/${codigos}`, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
            .then(res => {
                console.log(res.data)
                res.data.performance.map(registro => {
                    switch (registro.unidade_id) {
                        case 'H5223':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h5223[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h5223[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h5223[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h5223[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h5223[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h5223[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h5223[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h5223[9] += registro.valor
                            }

                            break;
                        case 'H5691':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h5691[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h5691[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h5691[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h5691[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h5691[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h5691[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h5691[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h5691[9] += registro.valor
                            }
                            break;
                        case 'H6497':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h6497[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h6497[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h6497[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h6497[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h6497[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h6497[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h6497[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h6497[9] += registro.valor
                            }
                            break;
                        case 'H6522':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h6522[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h6522[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h6522[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h6522[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h6522[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h6522[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h6522[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h6522[9] += registro.valor
                            }
                            break;
                        case 'H7437':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h7437[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h7437[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h7437[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h7437[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h7437[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h7437[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h7437[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h7437[9] += registro.valor
                            }
                            break;
                        case 'H7547':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h7547[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h7547[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h7547[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h7547[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h7547[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h7547[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h7547[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h7547[9] += registro.valor
                            }
                            break;
                        case 'H8180':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h8180[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h8180[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h8180[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h8180[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h8180[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h8180[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h8180[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h8180[9] += registro.valor
                            }
                            break;
                        case 'H8182':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h8182[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h8182[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h8182[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h8182[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h8182[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h8182[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h8182[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h8182[9] += registro.valor
                            }
                            break;
                        case 'H9327':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h9327[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h9327[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h9327[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h9327[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h9327[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h9327[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h9327[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h9327[9] += registro.valor
                            }
                            break;
                        case 'H9449':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h9449[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h9449[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h9449[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h9449[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h9449[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h9449[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h9449[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h9449[9] += registro.valor
                            }
                            break;
                        case 'H9558':
                            if (registro.codigo_id === 'ROOM_REVENUE') {
                                h9558[0] += registro.valor
                            }
                            if (registro.codigo_id === 'FOOD_BEV_REVENUE') {
                                h9558[1] += registro.valor
                            }
                            if (registro.codigo_id === 'OTHER_REVENUE') {
                                h9558[2] += registro.valor
                            }
                            if (registro.codigo_id === 'TOTAL_REVENUE') {
                                h9558[3] += registro.valor
                            }
                            if (registro.codigo_id === 'OCC_ROOMS') {
                                h9558[6] += registro.valor
                            }
                            if (registro.codigo_id === 'HOUSE_USE_ROOMS') {
                                h9558[7] += registro.valor
                            }
                            if (registro.codigo_id === 'DAYUSE_ROOMS') {
                                h9558[8] += registro.valor
                            }
                            if (registro.codigo_id === 'PHYSICAL_ROOMS') {
                                h9558[9] += registro.valor
                            }
                            break;
                        default:
                            break;
                    }
                })
                setH5223(h5223)
                setH5691(h5691)
                setH6497(h6497)
                setH6522(h6522)
                setH7437(h7437)
                setH7547(h7547)
                setH8180(h8180)
                setH8182(h8182)
                setH9327(h9327)
                setH9449(h9449)
                setH9558(h9558)

                dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
            })
    }
    return (
        <Container fluid>
            <Row className="rowSelector">
                <Col xs={3} md={2} xl={2}>Início:</Col>
                <Col xs={9} md={4} xl={3}>
                    <DatePicker
                        selected={dataConsulta}
                        onChange={date => setDataConsulta(date)}
                        selectsStart
                        dateFormat="dd/MM/yyyy"
                        startDate={dataConsulta}
                        endDate={dataConsultaFinal}
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </Col>
                <Col xs={3} md={2} xl={2}>Fim:</Col>
                <Col xs={9} md={4} xl={3}>
                    <DatePicker
                        selected={dataConsultaFinal}
                        onChange={date => setDataConsultaFinal(date)}
                        selectsEnd
                        startDate={dataConsulta}
                        endDate={dataConsultaFinal}
                        minDate={dataConsulta}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </Col>
                <Col xs={12} md={12} xl={2}>
                    <Button variant="secondary" onClick={() => loadData()}>Consultar</Button>
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover responsive className="analytics-table">
                    <thead>
                        <tr>
                            <td colSpan="12" className="analytics-table-title">
                                Análise comparativa performance dos hoteis no periodo:
                                {dataConsulta.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsulta.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + dataConsulta.getFullYear() + " "}
                                até
                                {dataConsultaFinal.getDate().toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + (dataConsultaFinal.getMonth() + 1).toLocaleString('pt-BR', { minimumIntegerDigits: 2 }) + '/' + dataConsultaFinal.getFullYear()}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="analytics-table-subtitle">Valores</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H7547').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H9327').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H9558').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H6497').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H9449').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H8182').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H7437').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H5223').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H5691').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H8180').label}
                                </td>)
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? (<td className="analytics-table-subtitle">
                                    {unidades.find(u => u.value === 'H6522').label}
                                </td>)
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Taxa de Ocupação</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{H7547[9] === 0 ? "0%" : ((H7547[8] + H7547[6] - H7547[7]) / H7547[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{H9327[9] === 0 ? "0%" : ((H9327[8] + H9327[6] - H9327[7]) / H9327[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{H9558[9] === 0 ? "0%" : ((H9558[8] + H9558[6] - H9558[7]) / H9558[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{H6497[9] === 0 ? "0%" : ((H6497[8] + H6497[6] - H6497[7]) / H6497[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{H9449[9] === 0 ? "0%" : ((H9449[8] + H9449[6] - H9449[7]) / H9449[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{H8182[9] === 0 ? "0%" : ((H8182[8] + H8182[6] - H8182[7]) / H8182[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{H7437[9] === 0 ? "0%" : ((H7437[8] + H7437[6] - H7437[7]) / H7437[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{H5223[9] === 0 ? "0%" : ((H5223[8] + H5223[6] - H5223[7]) / H5223[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{H5691[9] === 0 ? "0%" : ((H5691[8] + H5691[6] - H5691[7]) / H5691[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{H8180[9] === 0 ? "0%" : ((H8180[8] + H8180[6] - H8180[7]) / H8180[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{H6522[9] === 0 ? "0%" : ((H6522[8] + H6522[6] - H6522[7]) / H6522[9]).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2 })}</td>
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Diária Média</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{(H7547[8] + H7547[6] - H7547[7]) === 0 ? "R$ 0,00" : (H7547[0] / (H7547[8] + H7547[6] - H7547[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{(H9327[8] + H9327[6] - H9327[7]) === 0 ? "R$ 0,00" : (H9327[0] / (H9327[8] + H9327[6] - H9327[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{(H9558[8] + H9558[6] - H9558[7]) === 0 ? "R$ 0,00" : (H9558[0] / (H9558[8] + H9558[6] - H9558[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{(H6497[8] + H6497[6] - H6497[7]) === 0 ? "R$ 0,00" : (H6497[0] / (H6497[8] + H6497[6] - H6497[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{(H9449[8] + H9449[6] - H9449[7]) === 0 ? "R$ 0,00" : (H9449[0] / (H9449[8] + H9449[6] - H9449[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{(H8182[8] + H8182[6] - H8182[7]) === 0 ? "R$ 0,00" : (H8182[0] / (H8182[8] + H8182[6] - H8182[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{(H7437[8] + H7437[6] - H7437[7]) === 0 ? "R$ 0,00" : (H7437[0] / (H7437[8] + H7437[6] - H7437[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{(H5223[8] + H5223[6] - H5223[7]) === 0 ? "R$ 0,00" : (H5223[0] / (H5223[8] + H5223[6] - H5223[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{(H5691[8] + H5691[6] - H5691[7]) === 0 ? "R$ 0,00" : (H5691[0] / (H5691[8] + H5691[6] - H5691[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{(H8180[8] + H8180[6] - H8180[7]) === 0 ? "R$ 0,00" : (H8180[0] / (H8180[8] + H8180[6] - H8180[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{(H6522[8] + H6522[6] - H6522[7]) === 0 ? "R$ 0,00" : (H6522[0] / (H6522[8] + H6522[6] - H6522[7])).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">RevPar</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{H7547[9] === 0 ? "R$ 0,00" : (H7547[0] / H7547[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{H9327[9] === 0 ? "R$ 0,00" : (H9327[0] / H9327[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{H9558[9] === 0 ? "R$ 0,00" : (H9558[0] / H9558[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{H6497[9] === 0 ? "R$ 0,00" : (H6497[0] / H6497[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{H9449[9] === 0 ? "R$ 0,00" : (H9449[0] / H9449[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{H8182[9] === 0 ? "R$ 0,00" : (H8182[0] / H8182[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{H7437[9] === 0 ? "R$ 0,00" : (H7437[0] / H7437[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{H5223[9] === 0 ? "R$ 0,00" : (H5223[0] / H5223[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{H5691[9] === 0 ? "R$ 0,00" : (H5691[0] / H5691[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{H8180[9] === 0 ? "R$ 0,00" : (H8180[0] / H8180[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{H6522[9] === 0 ? "R$ 0,00" : (H6522[0] / H6522[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">TRevPar</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{H7547[9] === 0 ? "R$ 0,00" : (H7547[3] / H7547[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{H9327[9] === 0 ? "R$ 0,00" : (H9327[3] / H9327[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{H9558[9] === 0 ? "R$ 0,00" : (H9558[3] / H9558[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{H6497[9] === 0 ? "R$ 0,00" : (H6497[3] / H6497[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{H9449[9] === 0 ? "R$ 0,00" : (H9449[3] / H9449[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{H8182[9] === 0 ? "R$ 0,00" : (H8182[3] / H8182[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{H7437[9] === 0 ? "R$ 0,00" : (H7437[3] / H7437[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{H5223[9] === 0 ? "R$ 0,00" : (H5223[3] / H5223[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{H5691[9] === 0 ? "R$ 0,00" : (H5691[3] / H5691[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{H8180[9] === 0 ? "R$ 0,00" : (H8180[3] / H8180[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{H6522[9] === 0 ? "R$ 0,00" : (H6522[3] / H6522[9]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Receita Hospedagem</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{H7547[0] === 0 ? "R$ 0,00" : H7547[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{H9327[0] === 0 ? "R$ 0,00" : H9327[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{H9558[0] === 0 ? "R$ 0,00" : H9558[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{H6497[0] === 0 ? "R$ 0,00" : H6497[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{H9449[0] === 0 ? "R$ 0,00" : H9449[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{H8182[0] === 0 ? "R$ 0,00" : H8182[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{H7437[0] === 0 ? "R$ 0,00" : H7437[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{H5223[0] === 0 ? "R$ 0,00" : H5223[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{H5691[0] === 0 ? "R$ 0,00" : H5691[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{H8180[0] === 0 ? "R$ 0,00" : H8180[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{H6522[0] === 0 ? "R$ 0,00" : H6522[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Receita A&B</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{H7547[1] === 0 ? "R$ 0,00" : H7547[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{H9327[1] === 0 ? "R$ 0,00" : H9327[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{H9558[1] === 0 ? "R$ 0,00" : H9558[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{H6497[1] === 0 ? "R$ 0,00" : H6497[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{H9449[1] === 0 ? "R$ 0,00" : H9449[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{H8182[1] === 0 ? "R$ 0,00" : H8182[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{H7437[1] === 0 ? "R$ 0,00" : H7437[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{H5223[1] === 0 ? "R$ 0,00" : H5223[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{H5691[1] === 0 ? "R$ 0,00" : H5691[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{H8180[1] === 0 ? "R$ 0,00" : H8180[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{H6522[1] === 0 ? "R$ 0,00" : H6522[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Outras Receitas</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{H7547[2] === 0 ? "R$ 0,00" : H7547[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{H9327[2] === 0 ? "R$ 0,00" : H9327[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{H9558[2] === 0 ? "R$ 0,00" : H9558[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{H6497[2] === 0 ? "R$ 0,00" : H6497[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{H9449[2] === 0 ? "R$ 0,00" : H9449[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{H8182[2] === 0 ? "R$ 0,00" : H8182[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{H7437[2] === 0 ? "R$ 0,00" : H7437[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{H5223[2] === 0 ? "R$ 0,00" : H5223[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{H5691[2] === 0 ? "R$ 0,00" : H5691[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{H8180[2] === 0 ? "R$ 0,00" : H8180[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{H6522[2] === 0 ? "R$ 0,00" : H6522[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                        </tr>
                        <tr>
                            <td className="analytics-table-subline">Receita Total</td>
                            {unidades.find(u => u.value === 'H7547')
                                ? <td className="analytics-table-subline">{H7547[3] === 0 ? "R$ 0,00" : H7547[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9327')
                                ? <td className="analytics-table-subline">{H9327[3] === 0 ? "R$ 0,00" : H9327[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9558')
                                ? <td className="analytics-table-subline">{H9558[3] === 0 ? "R$ 0,00" : H9558[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6497')
                                ? <td className="analytics-table-subline">{H6497[3] === 0 ? "R$ 0,00" : H6497[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H9449')
                                ? <td className="analytics-table-subline">{H9449[3] === 0 ? "R$ 0,00" : H9449[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8182')
                                ? <td className="analytics-table-subline">{H8182[3] === 0 ? "R$ 0,00" : H8182[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H7437')
                                ? <td className="analytics-table-subline">{H7437[3] === 0 ? "R$ 0,00" : H7437[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5223')
                                ? <td className="analytics-table-subline">{H5223[3] === 0 ? "R$ 0,00" : H5223[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H5691')
                                ? <td className="analytics-table-subline">{H5691[3] === 0 ? "R$ 0,00" : H5691[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H8180')
                                ? <td className="analytics-table-subline">{H8180[3] === 0 ? "R$ 0,00" : H8180[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                            {unidades.find(u => u.value === 'H6522')
                                ? <td className="analytics-table-subline">{H6522[3] === 0 ? "R$ 0,00" : H6522[3].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                : null
                            }
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    )
}