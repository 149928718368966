/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useCookies } from 'react-cookie';

import api from '../../../services/api';

import { create, all } from 'mathjs'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Icons
import { AiOutlinePlus, AiOutlinePlusCircle, AiOutlineReconciliation } from 'react-icons/ai';
import { FaReceipt, FaRegCalendarAlt } from 'react-icons/fa';
import { IoDocumentAttachOutline, IoShieldCheckmarkOutline, IoTrashOutline } from 'react-icons/io5';
import { IoMdOpen } from 'react-icons/io';
import { BiDownvote, BiErrorAlt, BiSearchAlt } from 'react-icons/bi';

function ExtratoFundoDeReservas() {

  var location = useLocation();
  const [patrimonialObj, setPatrimonialObj] = useState(location.state.patrimonialObj);
  const [lancamento, setLancamento] = useState({ modalVisivel: false, limit: 1 })
  const [novoLancamento, setNovoLancamento] = useState({})
  const [conciliacao, setConciliacao] = useState([])
  const [concliacaoLancamento, setConciliacaoLancamento] = useState([])
  const [arquivos, setArquivos] = useState([])
  const [historicoMemory, setHistoricoMemory] = useState([])
  const [departamentos, setDepartamentos] = useState([])
  const [cookies] = useCookies([]);
  const config = {}
  const math = create(all, config)

  const notify = ($msg, $time = 5000, $id = null) => toast($msg, {
    toastId: $id,
    position: "top-right",
    autoClose: $time,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

  useEffect(() => {
    loadData()
  }, [location.state.patrimonialObj])

  function loadMore() {
    setLancamento({ ...lancamento, limit: lancamento.limit++ })
    loadData()
  }

  async function loadData() {
    setPatrimonialObj(location.state.patrimonialObj)

    var data = await api.get('/patrimoniais/fundodereserva/historico/lancamento/' + location.state.patrimonialObj.value + '/' + lancamento.limit, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    if (data.status !== 200) return notify("Erro ao carregar histórico.")

    setArquivos(data.data.arquivos)
    setHistoricoMemory(data.data.historico)
    setConciliacao(data.data.conciliacao)
    setDepartamentos(data.data.departamentos)
    setLancamento({ ...lancamento, startDate: null, endDate: null, modalFiltroPeriodoVisible: false, modalConciliacaoVisivel: false, modalVisivel: false, historico: data.data.historico })
  }


  async function adicionarLancamento() {
    var hasError = false
    if (!!!novoLancamento.data) {
      notify("Data invalida")
      hasError = true
    }

    if (!!!novoLancamento.historico) {
      notify("Histórico invalido")
      hasError = true
    }

    if (!!!novoLancamento.valor) {
      notify("Valor invalido")
      hasError = true
    }

    if (!!!novoLancamento.notaFiscal && novoLancamento.tipo !== 'Crédito') {
      notify("Nota Fiscal não inserida")
      hasError = true
    }

    if (!!!novoLancamento.autorizacaoDeCompra && novoLancamento.tipo !== 'Crédito') {
      notify("Autorização de compra não inserida")
      hasError = true
    }

    if (!!!novoLancamento?.orcamentos?.length && novoLancamento.tipo !== 'Crédito') {
      notify("Orçamentos não inseridos")
      hasError = true
    }

    if ((!!!novoLancamento?.departamento || novoLancamento?.orcamentos === 'Escolha o Departamento') && novoLancamento.tipo !== 'Crédito') {
      notify("Departamento não definido")
      hasError = true
    }

    if (hasError) return setNovoLancamento({
      ...novoLancamento,
      orcamentosvalid: !!novoLancamento.orcamentos?.length,
      datavalid: !!novoLancamento.data,
      valorvalid: !!novoLancamento.valor,
      notaFiscalvalid: novoLancamento.tipo === 'Crédito' ? null : !!novoLancamento.notaFiscal,
      autorizacaoDeCompravalid: !!novoLancamento.autorizacaoDeCompra,
      historicovalid: !!novoLancamento.historico,
      departamentovalid: (!!!novoLancamento?.departamento) || novoLancamento?.orcamentos === 'Escolha o Departamento' ? false : true
    })

    setNovoLancamento({
      ...novoLancamento,
      orcamentosvalid: true,
      datavalid: true,
      valorvalid: true,
      notaFiscalvalid: true,
      autorizacaoDeCompravalid: true,
      historicovalid: true,
      departamentovalid: true
    })

    var otherFiles = {}

    if (!!novoLancamento.autorizacaoDeCompra) {
      const fdautorizacaoDeCompra = new FormData()

      fdautorizacaoDeCompra.append('file', novoLancamento.autorizacaoDeCompra)

      var responsefdautorizacaoDeCompra = await api.post('/patrimoniais/fundodereserva/historico/lancamento/file', fdautorizacaoDeCompra, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })


      otherFiles = {
        ...otherFiles,
        autorizacao_url: responsefdautorizacaoDeCompra.data.newFileId[0],
      }
    }

    if (!!novoLancamento?.orcamentos?.length) {
      var orcamentos_ids = []

      await Promise.all(
        novoLancamento.orcamentos.map(async orcamento => {
          const fdorcamento = new FormData()

          fdorcamento.append('file', orcamento)

          var responseOrcamentos = await api.post('/patrimoniais/fundodereserva/historico/lancamento/file', fdorcamento, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
          orcamentos_ids.push(responseOrcamentos.data.newFileId[0])
        })
      )

      otherFiles = {
        ...otherFiles,
        orcamentos_url: orcamentos_ids
      }
    }

    if (!!novoLancamento?.notaFiscal) {
      const fdnf_file = new FormData()

      fdnf_file.append('file', novoLancamento.notaFiscal)

      var responsefdnf_file = await api.post('/patrimoniais/fundodereserva/historico/lancamento/file', fdnf_file, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

      otherFiles = {
        ...otherFiles,
        nf_url: responsefdnf_file.data.newFileId[0],
      }
    }

    var tipo = novoLancamento.tipo ? novoLancamento.tipo : 'Débito'

    var responseNovaMovimentacao = await api.post('/patrimoniais/fundodereserva/historico/lancamento', {
      data: novoLancamento.data,
      historico: novoLancamento.historico,
      ano: novoLancamento.data.getFullYear(),
      mes: novoLancamento.data.getMonth() + 1,
      patrimonial_id: patrimonialObj.value,
      usuario_id: cookies.usuario.id,
      valor: novoLancamento.valor,
      tipo,
      departamento_id: tipo !== 'Débito' ? '' : novoLancamento.departamento,
      ...otherFiles
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    if (responseNovaMovimentacao.status !== 200) return notify("Erro ao cadastrar movimentação, entre em contato com o suporte.")

    setNovoLancamento({})
    notify("Movimentação cadastrada com sucesso.")

    loadData()
  }

  async function conciliarFundo() {
    var hasError = false

    var dataAux = concliacaoLancamento.data

    if (!!!concliacaoLancamento.data) {
      dataAux = new Date()
    }

    if (concliacaoLancamento.saldoCC === null || concliacaoLancamento.saldoCC === undefined || concliacaoLancamento.saldoCC === '') {
      notify("Saldo conta corrente invalido")
      hasError = true
    }

    if (concliacaoLancamento.saldoAplicacao === null || concliacaoLancamento.saldoAplicacao === undefined || concliacaoLancamento.saldoAplicacao === '') {
      notify("Saldo aplicação invalido")
      hasError = true
    }

    if (!!!concliacaoLancamento?.extratos?.length) {
      notify("Extratos não inseridos")
      hasError = true
    }

    if (hasError) return setConciliacaoLancamento({
      ...concliacaoLancamento,
      saldoCCValid: (concliacaoLancamento.saldoCC === null || concliacaoLancamento.saldoCC === undefined || concliacaoLancamento.saldoCC === '') ? false : true,
      saldoAplicacaoValid: (concliacaoLancamento.saldoAplicacao === null || concliacaoLancamento.saldoAplicacao === undefined || concliacaoLancamento.saldoAplicacao === '') ? false : true,
      extratosvalid: !!concliacaoLancamento?.extratos?.length,
    })

    setConciliacaoLancamento({
      ...concliacaoLancamento,
      saldoCCValid: true,
      saldoAplicacaoValid: true,
      extratosvalid: true,
    })

    var filesId = {}

    if (!!concliacaoLancamento?.extratos?.length) {
      var Extratos_ids = []

      await Promise.all(
        concliacaoLancamento.extratos.map(async extrato => {
          const fdextratos = new FormData()

          fdextratos.append('file', extrato)

          var responseExtratos = await api.post('/patrimoniais/fundodereserva/historico/lancamento/file', fdextratos, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
          Extratos_ids.push(responseExtratos.data.newFileId[0])
        })
      )

      filesId = {
        extratos_url: Extratos_ids
      }
    }

    var responseNovaMovimentacao = await api.post('/patrimoniais/fundodereserva/historico/conciliar', {
      data: new Date(new Date(dataAux).getTime() + (3 * 60 * 60 * 1000)),
      ano: new Date().getFullYear(),
      mes: new Date().getMonth() + 1,
      patrimonial_id: patrimonialObj.value,
      usuario_id: cookies.usuario.id,
      ca: concliacaoLancamento.saldoAplicacao,
      cc: concliacaoLancamento.saldoCC,
      ...filesId
    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
    if (responseNovaMovimentacao.status !== 200) return notify("Erro ao cadastrar movimentação, entre em contato com o suporte.")

    setConciliacaoLancamento({})
    setLancamento({ ...lancamento, modalConciliacaoVisivel: false })
    notify("Movimentação cadastrada com sucesso.")
    loadData()
  }

  async function loadDataPeriod() {
    if (!!!lancamento.endDate) return notify("Escolha a data de fim")

    var data = await api.get('/patrimoniais/fundodereserva/historico/lancamento/' + location.state.patrimonialObj.value + '/' + lancamento.limit + '?startDate=' + lancamento.startDate.toISOString().substr(0, 10) + '&endDate=' + lancamento.endDate.toISOString().substr(0, 10), { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    if (data.status !== 200) return notify("Erro ao carregar histórico.")

    setConciliacao(data.data.conciliacao)
    setHistoricoMemory(data.data.historico)
    setDepartamentos(data.data.departamentos)
    setArquivos(data.data.arquivos)
    setLancamento({ ...lancamento, modalFiltroPeriodoVisible: false, modalConciliacaoVisivel: false, modalVisivel: false, historico: data.data.historico })
  }

  function hideShowMoreDetails(idf, idm) {
    if (document.getElementById(idf).hidden) {
      document.getElementById(idf).hidden = false
      document.getElementById(idm).firstChild.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>'
    } else {
      document.getElementById(idf).hidden = true
      document.getElementById(idm).firstChild.innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>'
    }

  }

  function padronizaString(str) {
    return ('' + str).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])|\s/g, '').toLowerCase()
  }

  function filterLancamentos(textToFind) {
    if (textToFind === '') return setLancamento({ ...lancamento, textToFind: null, historico: historicoMemory })

    var strFix = (padronizaString(textToFind))

    setLancamento({
      ...lancamento,
      textToFind: strFix,
      historico: historicoMemory.filter(d =>
        padronizaString(d.historico).includes(strFix) ||
        padronizaString(d.ano).includes(strFix) ||
        padronizaString(d.mes).includes(strFix) ||
        padronizaString(d.tipo).includes(strFix) ||
        new Date(d.data).toLocaleDateString('pt-br').includes(textToFind) ||
        (d.valor + '').includes(strFix) ||
        padronizaString(departamentos.find(e => e.value === d.departamento_id)?.label).includes(strFix) ||
        (d.tipo === 'Crédito' ? padronizaString('Entrada').includes(strFix) : null) ||
        (d.tipo === 'Débito' ? padronizaString('Saida').includes(strFix) : null)
      )
    })
  }

  function calculaConciliacao() {
    var limiteData = [...new Set(conciliacao?.reduce((acc, cv) => acc = [...acc, cv.data], []))][0]
    if (!!!limiteData) limiteData = new Date()

    var ultimo = (lancamento?.historico?.filter(d => new Date(d.data) <= new Date([...new Set(conciliacao?.reduce((acc, cv) => acc = [...acc, cv.data], []))][0])).pop())

    var saldoExtrato = ultimo?.saldo
    var saldoBanco = conciliacao?.reduce((acc, cv) => acc += cv.valor, 0)

    return (saldoBanco - saldoExtrato)
  }

  async function deleteHistorico(hid) {
    if (!window.confirm('Deseja realmente excluir o lançamento? Esta ação não poderá ser desfeita.')) return

    await api.delete('/patrimoniais/fundodereserva/historico/lancamento/' + hid, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })

    loadData()
  }

  return (
    <>
      <Table size="sm" bordered hover responsive align="center" style={{ width: '98%', background: '#fff', fontSize: 'small', marginBottom: '50px' }}>
        <thead>
          <tr>
            <th style={{ fontSize: 'x-large', padding: '15px 20px' }} colSpan={8}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                Extrato Movimentações Fundo de Reserva
                <InputGroup style={{ maxWidth: '300px' }}>
                  <Form.Control placeholder="Buscar Registro" onChange={(e) => { filterLancamentos(e.target.value) }} />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2"><BiSearchAlt /></InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </th>
          </tr>
          <tr>
            <th style={{ fontSize: 'large', padding: '10px 15px' }} colSpan={8}>
              {patrimonialObj?.label}
            </th>
          </tr>
          <tr>
            <th style={{ textAlign: 'center' }} width='2%'><IoDocumentAttachOutline /></th>
            <th style={{ textAlign: 'center' }} width='5%'>Data</th>
            <th style={{ textAlign: 'center' }} width='10%'>Departamento</th>
            <th style={{ textAlign: 'center' }} width='50%'>Histórico</th>
            <th style={{ textAlign: 'center' }} width='10%'>Entrada</th>
            <th style={{ textAlign: 'center' }} width='10%'>Saída</th>
            <th style={{ textAlign: 'center' }} width='13%'>Saldo</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {lancamento?.historico?.filter(d => new Date(d.data) <= new Date([...new Set(conciliacao?.reduce((acc, cv) => acc = [...acc, cv.data], []))][0])).map((historicoAtual, idx) => {
            if (historicoAtual === undefined) return
            var hasAnexos = !!historicoAtual.nf_url + !!historicoAtual.orcamentos_url.length + !!historicoAtual.autorizacao_url

            return (<>
              <tr onClick={hasAnexos ? () => hideShowMoreDetails('arquivos' + idx, 'mainDetail-' + idx) : null}
                id={'mainDetail-' + idx}
                style={historicoAtual.tipo === 'Consolidação' ? { background: '#119e91', color: 'white', fontWeight: 'bold' } : {}}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{hasAnexos ? <AiOutlinePlusCircle /> : null}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{historicoAtual.datadisplay}</td>
                <td style={{ verticalAlign: 'middle' }}>{departamentos.find(d => d.value === historicoAtual.departamento_id)?.label}</td>
                <td style={{ verticalAlign: 'middle' }}>{historicoAtual.historico}</td>
                <td style={historicoAtual.tipo === 'Crédito' ? { verticalAlign: 'middle', textAlign: 'right' } : { verticalAlign: 'middle', textAlign: 'center' }}>{historicoAtual.tipo === 'Crédito' ? "R$ " + historicoAtual.valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '-'}</td>
                <td style={historicoAtual.tipo === 'Débito' ? { verticalAlign: 'middle', textAlign: 'right' } : { verticalAlign: 'middle', textAlign: 'center' }}>{historicoAtual.tipo === 'Débito' ? "R$ " + historicoAtual.valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '-'}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{"R$ " + historicoAtual?.saldo?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</td>
                <td>
                  {historicoAtual?.isdeletable
                    ? <Button variant='outline-danger' onClick={(e) => { e.preventDefault(); deleteHistorico(historicoAtual.id) }} ><IoTrashOutline /></Button> : null
                  }
                </td>
              </tr>
              {hasAnexos
                ? <tr id={'arquivos' + idx} hidden={true} style={{ background: 'white' }}>
                  <td colSpan={8}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr style={{ background: 'white' }}>
                          <th width='20%'>Arquivo</th>
                          <th>Nome</th>
                          <th width='8%' style={{ textAlign: 'center' }}>Anexo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>{historicoAtual.tipo === 'Crédito' ? 'Extrato:' : 'Nota Fiscal:'}</td>
                          <td style={{ verticalAlign: 'middle' }}>{arquivos?.find(a => a.id === historicoAtual?.nf_url)?.originalfilename}</td>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><Button size='sm' onClick={() => window.open(arquivos?.find(a => a.id === historicoAtual?.nf_url)?.url, 'blank')}><IoMdOpen /></Button></td>
                        </tr>
                        <tr hidden={historicoAtual.tipo === 'Crédito'}>
                          <td style={{ verticalAlign: 'middle' }}>Autorização de compra:</td>
                          <td style={{ verticalAlign: 'middle' }}>{arquivos?.find(a => a.id === historicoAtual?.autorizacao_url)?.originalfilename}</td>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><Button size='sm' onClick={() => window.open(arquivos?.find(a => a.id === historicoAtual?.autorizacao_url)?.url, 'blank')}><IoMdOpen /></Button></td>
                        </tr>
                        {historicoAtual?.orcamentos_url.map((orc, idx) => {
                          return (
                            <tr hidden={historicoAtual.tipo === 'Crédito'}>
                              <td style={{ verticalAlign: 'middle' }}>Oraçamento {idx + 1}:</td>
                              <td style={{ verticalAlign: 'middle' }}>{arquivos?.find(a => a.id === historicoAtual?.nf_url)?.originalfilename}</td>
                              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><Button size='sm' onClick={() => window.open(arquivos?.find(a => a.id === orc)?.url, 'blank')}><IoMdOpen /></Button></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
                : null
              }
            </>)
          })}
          <tr hidden={!!lancamento.textToFind || (!!lancamento.startDate && !!lancamento.endDate) ? true : false}><td colSpan={8} style={{ padding: '2px', background: '#119e91' }}></td></tr>
          <tr hidden={!!lancamento.textToFind || (!!lancamento.startDate && !!lancamento.endDate) ? true : false}>
            <th style={{ textAlign: 'center' }} colSpan={8}>
              Conciliação
            </th>
          </tr>
          <tr hidden={!!lancamento.textToFind || (!!lancamento.startDate && !!lancamento.endDate) ? true : false}>
            <th style={{ textAlign: 'center' }} width='2%'><IoDocumentAttachOutline /></th>
            <th style={{ textAlign: 'center' }} width='5%'>Data</th>
            <th style={{ textAlign: 'center' }} width='80%' colSpan={4}>Histórico</th>
            <th style={{ textAlign: 'center' }} colSpan={2} width='13%'>Saldo</th>
          </tr>
          {conciliacao?.map((historicoAtual, idx) => {
            if (historicoAtual === undefined) return
            var hasAnexos = !!historicoAtual.extratos_url
            return (<>
              <tr hidden={!!lancamento.textToFind || (!!lancamento.startDate && !!lancamento.endDate) ? true : false} onClick={hasAnexos ? () => hideShowMoreDetails('arquivos-consolidacao', 'mainDetail-consolidacao') : null}
                style={{ background: '#119e91', color: 'white', fontWeight: 'bold' }}
                id={'mainDetail-consolidacao'}>
                {idx === 0
                  ? <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>{hasAnexos ? <AiOutlinePlusCircle /> : null}</td>
                  : null
                }
                {idx === 0
                  ? <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>{historicoAtual.datadisplay}</td>
                  : null
                }
                <td style={{ verticalAlign: 'middle' }} colSpan={4}>{historicoAtual.historico}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'right' }} colSpan={2}>{"R$ " + historicoAtual.valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</td>
              </tr>
            </>)
          })}
          {[...new Set(conciliacao?.reduce((acc, cv) => acc = [...acc, ...cv.extratos_url], []))].length > 0
            ? <tr hidden={true} style={{ background: 'white' }} id={'arquivos-consolidacao'}>
              <td colSpan={8}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr style={{ background: 'white' }}>
                      <th width='20%'>Arquivo</th>
                      <th>Nome</th>
                      <th width='8%' style={{ textAlign: 'center' }}>Anexo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...new Set(conciliacao?.reduce((acc, cv) => acc = [...acc, ...cv.extratos_url], []))].map((extrato, idx) => {
                      return (
                        <tr >
                          <td style={{ verticalAlign: 'middle' }}>Extrato {idx + 1}:</td>
                          <td style={{ verticalAlign: 'middle' }}>{arquivos?.find(a => a.id === extrato)?.originalfilename}</td>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><Button size='sm' onClick={() => window.open(arquivos?.find(a => a.id === extrato)?.url, 'blank')}><IoMdOpen /></Button></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
            : null
          }
          <tr hidden={!!lancamento.textToFind || (!!lancamento.startDate && !!lancamento.endDate) ? true : false}>
            <th style={{ fontSize: 'large', padding: '10px 15px', verticalAlign: 'middle' }} rowSpan={2} colSpan={4}>Conferência:</th>
            <td style={math.abs(calculaConciliacao()) <= 0.1 ? { verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', color: 'green' } : { verticalAlign: 'middle', textAlign: 'center', fontSize: 'large', color: 'red' }} rowSpan={2} >
              {math.abs(calculaConciliacao()) <= 0.1 ? <IoShieldCheckmarkOutline /> : <BiErrorAlt />}
              <span style={{ padding: '5px' }}>{math.abs(calculaConciliacao()) <= 0.1 ? 'Ok' : 'Errado'}</span>
            </td>
            <th>Total em Conta:</th>
            <td colSpan={2} style={{ verticalAlign: 'middle', textAlign: 'right' }}>{!!conciliacao?.reduce((acc, cv) => acc += cv?.valor, 0) ? 'R$ ' + conciliacao?.reduce((acc, cv) => acc += cv?.valor, 0).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : 'R$ - '}</td>
          </tr>
          <tr hidden={!!lancamento.textToFind || (!!lancamento.startDate && !!lancamento.endDate) ? true : false}>
            <th>Diferença:</th>
            <td colSpan={2} style={{ verticalAlign: 'middle', textAlign: 'right' }}>{math.abs(calculaConciliacao()) > 0.1 ? 'R$ ' + calculaConciliacao().toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : 'R$ -'}</td>
          </tr>
          <tr><td colSpan={8} style={{ padding: '2px', background: '#119e91' }}></td></tr>
          <tr>
            <th style={{ textAlign: 'center' }} colSpan={8}>
              Compras futuras aprovadas
            </th>
          </tr>
          <tr>
            <th style={{ textAlign: 'center' }} width='2%'><IoDocumentAttachOutline /></th>
            <th style={{ textAlign: 'center' }} width='5%'>Data</th>
            <th style={{ textAlign: 'center' }} width='10%'>Departamento</th>
            <th style={{ textAlign: 'center' }} width='50%'>Histórico</th>
            <th style={{ textAlign: 'center' }} width='10%'>Entrada</th>
            <th style={{ textAlign: 'center' }} width='10%'>Saída</th>
            <th style={{ textAlign: 'center' }} width='13%'>Saldo</th>
            <th>Ação</th>
          </tr>
          {lancamento?.historico?.filter(d => new Date(d.data) > new Date([...new Set(conciliacao?.reduce((acc, cv) => acc = [...acc, cv.data], []))][0])).map((historicoAtual, idx) => {
            if (historicoAtual === undefined) return
            var hasAnexos = !!historicoAtual.nf_url + !!historicoAtual.orcamentos_url.length + !!historicoAtual.autorizacao_url

            return (<>
              <tr onClick={hasAnexos ? () => hideShowMoreDetails('arquivosFuturo' + idx, 'mainDetailFuturo-' + idx) : null}
                id={'mainDetailFuturo-' + idx}
                style={historicoAtual.tipo === 'Consolidação' ? { background: '#119e91', color: 'white', fontWeight: 'bold' } : {}}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{hasAnexos ? <AiOutlinePlusCircle /> : null}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{historicoAtual.datadisplay}</td>
                <td style={{ verticalAlign: 'middle' }}>{departamentos.find(d => d.value === historicoAtual.departamento_id)?.label}</td>
                <td style={{ verticalAlign: 'middle' }}>{historicoAtual.historico}</td>
                <td style={historicoAtual.tipo === 'Crédito' ? { verticalAlign: 'middle', textAlign: 'right' } : { verticalAlign: 'middle', textAlign: 'center' }}>{historicoAtual.tipo === 'Crédito' ? "R$ " + historicoAtual.valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '-'}</td>
                <td style={historicoAtual.tipo === 'Débito' ? { verticalAlign: 'middle', textAlign: 'right' } : { verticalAlign: 'middle', textAlign: 'center' }}>{historicoAtual.tipo === 'Débito' ? "R$ " + historicoAtual.valor.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '-'}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>{"R$ " + historicoAtual?.saldo?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</td>
                <td>
                  {historicoAtual?.isdeletable
                    ? <Button variant='outline-danger' onClick={(e) => { e.preventDefault(); deleteHistorico(historicoAtual.id) }} ><IoTrashOutline /></Button> : null
                  }
                </td>
              </tr>
              {hasAnexos
                ? <tr id={'arquivosFuturo' + idx} hidden={true} style={{ background: 'white' }}>
                  <td colSpan={8}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr style={{ background: 'white' }}>
                          <th width='20%'>Arquivo</th>
                          <th>Nome</th>
                          <th width='8%' style={{ textAlign: 'center' }}>Anexo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: 'middle' }}>{historicoAtual.tipo === 'Crédito' ? 'Extrato:' : 'Nota Fiscal:'}</td>
                          <td style={{ verticalAlign: 'middle' }}>{arquivos?.find(a => a.id === historicoAtual?.nf_url)?.originalfilename}</td>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><Button size='sm' onClick={() => window.open(arquivos?.find(a => a.id === historicoAtual?.nf_url)?.url, 'blank')}><IoMdOpen /></Button></td>
                        </tr>
                        <tr hidden={historicoAtual.tipo === 'Crédito'}>
                          <td style={{ verticalAlign: 'middle' }}>Autorização de compra:</td>
                          <td style={{ verticalAlign: 'middle' }}>{arquivos?.find(a => a.id === historicoAtual?.autorizacao_url)?.originalfilename}</td>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><Button size='sm' onClick={() => window.open(arquivos?.find(a => a.id === historicoAtual?.autorizacao_url)?.url, 'blank')}><IoMdOpen /></Button></td>
                        </tr>
                        {historicoAtual?.orcamentos_url.map((orc, idx) => {
                          return (
                            <tr hidden={historicoAtual.tipo === 'Crédito'}>
                              <td style={{ verticalAlign: 'middle' }}>Oraçamento {idx + 1}:</td>
                              <td style={{ verticalAlign: 'middle' }}>{arquivos?.find(a => a.id === historicoAtual?.nf_url)?.originalfilename}</td>
                              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><Button size='sm' onClick={() => window.open(arquivos?.find(a => a.id === orc)?.url, 'blank')}><IoMdOpen /></Button></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
                : null
              }
            </>)
          })}
        </tbody>
      </Table>

      <Modal
        show={lancamento.modalVisivel}
        onHide={() => setLancamento({ ...lancamento, modalVisivel: false })}
        centered
        size='lg'
        scrollable
      >
        <Modal.Header closeButton style={{ gap: '15px' }}>
          <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="success" style={{ marginRight: '8px' }} disabled><FaReceipt /></Button> Formulário Movimentação do Fundo de Reserva
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: '6px' }} >
            <Col lg>
              <InputGroup size="sm" className="mb-3" >
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Data:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small" isValid={novoLancamento.datavalid === true} isInvalid={novoLancamento.datavalid === false} onChange={e => setNovoLancamento({ ...novoLancamento, data: e.target.valueAsDate })} type='date' aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
            <Col lg>
              <InputGroup size="sm" className="mb-3" >
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Tipo:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control size="sm" as="select" defaultValue="Débito" value={novoLancamento?.tipo} onChange={e => setNovoLancamento({ ...novoLancamento, tipo: e.target.value })} >
                  <option>Débito</option>
                  <option>Crédito</option>
                </Form.Control>
              </InputGroup>
            </Col>
            <Col lg>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Valor</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small" isValid={novoLancamento.valorvalid === true} isInvalid={novoLancamento.valorvalid === false}
                  onChange={(e) => e.target.value = e.target.value.replaceAll(',', '.')}
                  onBlur={(e) => {
                    if (e.target.value === '') { e.target.value = 0 }
                    e.target.value = e.target.value.replaceAll(',', '.').replace(/[A-Za-z\u00C0-\u00FF]/gi, '')
                    if (isNaN(e.target.value.slice(-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                    e.target.value = math.evaluate(e.target.value);
                    setNovoLancamento({ ...novoLancamento, valor: math.evaluate(e.target.value) })
                    e.target.value = parseFloat(e.target.value).toLocaleString('pt-br');
                  }}
                  aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
          </Row>
          <Row style={{ margin: '6px' }} hidden={novoLancamento.tipo === 'Crédito'}>
            <Col lg>
              <InputGroup size="sm" className="mb-3" >
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Departamento:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control isValid={novoLancamento.departamentovalid === true} isInvalid={novoLancamento.departamentovalid === false} size="sm" as="select" defaultValue="Escolha o Departamento" onChange={e => setNovoLancamento({ ...novoLancamento, departamento: e.target.value })} >
                  <option hidden>Escolha o Departamento</option>
                  {departamentos?.map(d => <option value={d.value}>{d.label}</option>)}
                </Form.Control>
              </InputGroup>
            </Col>
          </Row>
          <Row style={{ margin: '6px' }}>
            <Col lg>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Histórico</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small" isValid={novoLancamento.historicovalid === true} isInvalid={novoLancamento.historicovalid === false} onChange={e => setNovoLancamento({ ...novoLancamento, historico: e.target.value })} aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
          </Row>
          <div style={{ border: '1px solid black', borderRadius: '6px', background: '#f1f1f1' }}>
            <Row style={{ margin: '6px' }}>
              <Col>
                Arquivos:
              </Col>
            </Row>
            <Row style={{ margin: '6px' }} hidden={novoLancamento.tipo === 'Crédito' ? true : false}>
              <Col lg>
                <Form.File id="formcheck-api-custom" custom>
                  <Form.File.Input isValid={novoLancamento.notaFiscalvalid === true} isInvalid={novoLancamento.notaFiscalvalid === false} onChange={e => setNovoLancamento({ ...novoLancamento, notaFiscal: e.target.files[0] })} />
                  <Form.File.Label data-browse="Anexar">
                    {!!novoLancamento?.notaFiscal?.name ? novoLancamento?.notaFiscal?.name : 'Nota Fiscal'}
                  </Form.File.Label>
                </Form.File>
              </Col>
              <Col lg>
                <Form.File id="formcheck-api-custom" custom>
                  <Form.File.Input defaultValue={novoLancamento?.autorizacaoDeCompra} isValid={novoLancamento.autorizacaoDeCompravalid === true} isInvalid={novoLancamento.autorizacaoDeCompravalid === false} onChange={e => setNovoLancamento({ ...novoLancamento, autorizacaoDeCompra: e.target.files[0] })} />
                  <Form.File.Label data-browse="Anexar">
                    {!!novoLancamento?.autorizacaoDeCompra?.name ? novoLancamento?.autorizacaoDeCompra?.name : 'Autorização de Compra'}
                  </Form.File.Label>
                </Form.File>
              </Col>
            </Row>
            <Row style={{ margin: '6px' }} hidden={novoLancamento.tipo === 'Crédito' ? true : false}>
              <Col lg>
                <Form.File id="formcheck-api-custom" custom>
                  <Form.File.Input multiple isValid={novoLancamento.orcamentosvalid === true} isInvalid={novoLancamento.orcamentosvalid === false} onChange={e => setNovoLancamento({ ...novoLancamento, orcamentos: [...e.target.files] })} />
                  <Form.File.Label data-browse={!!novoLancamento?.orcamentos?.length ? "Anexar (" + novoLancamento?.orcamentos?.length + ')' : "Anexar (" + 0 + ')'}>
                    {!!novoLancamento?.orcamentos?.length ? novoLancamento?.orcamentos.reduce((acc, cv, idx, arr) => idx + 1 === arr.length ? acc += cv.name + '' : acc += cv.name + ', ', '') : 'Orçamentos'}
                  </Form.File.Label>
                </Form.File>
              </Col>
            </Row>
            <Row style={{ margin: '6px' }} hidden={novoLancamento.tipo === 'Crédito' ? false : true}>
              <Col lg>
                <Form.File id="formcheck-api-custom" custom>
                  <Form.File.Input isValid={novoLancamento.notaFiscalvalid === true} isInvalid={novoLancamento.notaFiscalvalid === false} onChange={e => setNovoLancamento({ ...novoLancamento, notaFiscal: e.target.files[0] })} />
                  <Form.File.Label data-browse="Anexar">
                    {!!novoLancamento?.notaFiscal?.name ? novoLancamento?.notaFiscal?.name : 'Extrato'}
                  </Form.File.Label>
                </Form.File>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' onClick={() => adicionarLancamento()}>Salvar</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={lancamento.modalConciliacaoVisivel}
        onHide={() => setLancamento({ ...lancamento, modalConciliacaoVisivel: false })}
        centered
        size='lg'
        scrollable
      >
        <Modal.Header closeButton style={{ gap: '15px' }}>
          <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="success" style={{ marginRight: '8px' }} disabled><AiOutlineReconciliation /></Button> Formulário Conciliação do Fundo de Reserva
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: '6px' }} >
            <Col lg={4}>
              <InputGroup size="sm" className="mb-3" >
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Data:</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small" max={new Date().toISOString().split('T')[0]} onChange={e => setConciliacaoLancamento({ ...concliacaoLancamento, data: e.target.valueAsDate })} type='date' defaultValue={new Date().toISOString().split('T')[0]} />
              </InputGroup>
            </Col>
            <Col lg>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Histórico</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small" value={"Consolidação do fundo de reserva"} readOnly />
              </InputGroup>
            </Col>
          </Row>
          <Row style={{ margin: '6px' }}>
            <Col lg>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Saldo Conta Corrente</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small"
                  onChange={(e) => e.target.value = e.target.value.replaceAll(',', '.')}
                  onBlur={(e) => {
                    if (e.target.value === '') { e.target.value = 0 }
                    e.target.value = e.target.value.replaceAll(',', '.').replace(/[A-Za-z\u00C0-\u00FF]/gi, '')
                    if (isNaN(e.target.value.slice(-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                    e.target.value = math.evaluate(e.target.value);
                    setConciliacaoLancamento({ ...concliacaoLancamento, saldoCC: math.evaluate(e.target.value) })
                    e.target.value = parseFloat(e.target.value).toLocaleString('pt-br');
                  }}
                  aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
            <Col lg>
              <InputGroup size="sm" className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-sm">Saldo Aplicações</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl aria-label="Small"
                  onChange={(e) => e.target.value = e.target.value.replaceAll(',', '.')}
                  onBlur={(e) => {
                    if (e.target.value === '') { e.target.value = 0 }
                    e.target.value = e.target.value.replaceAll(',', '.').replace(/[A-Za-z\u00C0-\u00FF]/gi, '')
                    if (isNaN(e.target.value.slice(-1))) e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                    e.target.value = math.evaluate(e.target.value);
                    setConciliacaoLancamento({ ...concliacaoLancamento, saldoAplicacao: math.evaluate(e.target.value) })
                    e.target.value = parseFloat(e.target.value).toLocaleString('pt-br');
                  }}
                  aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Col>
          </Row>
          <div style={{ border: '1px solid black', borderRadius: '6px', background: '#f1f1f1' }}>
            <Row style={{ margin: '6px' }}>
              <Col>
                Arquivos:
              </Col>
            </Row>
            <Row style={{ margin: '6px' }}>
              <Col lg>
                <Form.File id="formcheck-api-custom" custom>
                  <Form.File.Input multiple isValid={concliacaoLancamento?.extratosvalid === true} isInvalid={concliacaoLancamento?.extratosvalid === false} onChange={e => setConciliacaoLancamento({ ...concliacaoLancamento, extratos: [...e.target.files] })} />
                  <Form.File.Label data-browse={!!concliacaoLancamento?.extratos?.length ? "Anexar (" + concliacaoLancamento?.extratos?.length + ')' : "Anexar (" + 0 + ')'}>
                    {!!concliacaoLancamento?.extratos?.length ? concliacaoLancamento?.extratos.reduce((acc, cv, idx, arr) => idx + 1 === arr.length ? acc += cv.name + '' : acc += cv.name + ', ', '') : 'Extratos'}
                  </Form.File.Label>
                </Form.File>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' onClick={() => conciliarFundo()}>Conciliar</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={lancamento.modalFiltroPeriodoVisible}
        onHide={() => setLancamento({ ...lancamento, modalFiltroPeriodoVisible: false })}
        centered
        size='md'
        scrollable
      >
        <Modal.Header closeButton style={{ gap: '15px' }}>
          <Modal.Title id="contained-modal-title-vcenter" style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="success" style={{ marginRight: '8px' }} disabled><FaRegCalendarAlt /></Button> Buscar por periodo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignSelf: 'center' }}>
          <Row>
            <Col>Inicio</Col>
            <Col>Fim</Col>
          </Row>
          <Row>
            <Col><input placeholder='DD/MM/AAAA' readOnly value={lancamento?.startDate?.toLocaleDateString('pt-br')} /></Col>
            <Col><input placeholder='DD/MM/AAAA' readOnly value={lancamento?.endDate?.toLocaleDateString('pt-br')} /></Col>
          </Row>
          <div style={{ textAlign: 'center', marginTop: '5px' }}>
            <DatePicker
              selected={lancamento.startDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setLancamento({ ...lancamento, startDate: start, endDate: end })
              }}
              startDate={lancamento.startDate}
              endDate={lancamento.endDate}
              selectsRange
              inline
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" size='sm' onClick={() => { loadData() }}>Limpar</Button>
          <Button size='sm' onClick={() => loadDataPeriod()}>Buscar</Button>
        </Modal.Footer>
      </Modal>
      <Fab
        id='fabToHide'
        icon={<AiOutlinePlus />}
        mainButtonStyles={{ backgroundColor: '#17a2b8' }}
        // actionButtonStyles={{ fontSize: 'large' }}
        alwaysShowTitle={true}
        style={{ bottom: 0, right: 0, zoom: '80%', margin: '20px', zIndex: 100 }}
      >
        {/* Bottom */}

        <Action
          text={<span style={{ fontSize: 'large' }}>Adicionar lançamento</span>}
          style={{ backgroundColor: 'blue' }}
          onClick={() => setLancamento({ ...lancamento, modalVisivel: true })}
        >
          <FaReceipt />
        </Action>

        <Action
          text={<span style={{ fontSize: 'large' }}>Conciliar Fundo de Reserva</span>}
          style={{ backgroundColor: 'blue' }}
          onClick={() => setLancamento({ ...lancamento, modalConciliacaoVisivel: true })}
        >
          <AiOutlineReconciliation />
        </Action>

        <Action
          text={<span style={{ fontSize: 'large' }}>Carregar mais</span>}
          style={{ backgroundColor: 'blue' }}
          onClick={() => loadMore()}
        >
          <BiDownvote />
        </Action>

        <Action
          text={<span style={{ fontSize: 'large' }}>Carregar Periodo</span>}
          style={{ backgroundColor: 'blue' }}
          onClick={() => setLancamento({ ...lancamento, modalFiltroPeriodoVisible: true })}
        >
          <FaRegCalendarAlt />
        </Action>

        {/* Top */}
      </Fab >

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        style={{ zIndex: 1000000, color: '#2c3e50' }}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover />
    </>
  );
}

export default ExtratoFundoDeReservas;