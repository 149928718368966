const initial_state = {
    usuarioLogado: [],
    logado: false,
};

export default function usuarioLogado(state = initial_state, action) {
    switch (action.type) {
        case 'LOGAR_SISTEMA':
            return { ...state, usuarioLogado: action.usuario, logado: true };

        case 'DESLOGAR_SISTEMA':
            return { ...state, usuarioLogado: [], logado: false };

        default:
            return state;
    }
}