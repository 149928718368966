/* eslint-disable array-callback-return */
import React from 'react';
import { Button, Form, Col } from 'react-bootstrap'
import { useCookies } from 'react-cookie';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../services/api'

import './styles.css';

// redux
import { useDispatch } from 'react-redux'

function RegistroMensalGourmet() {

    const dispatch = useDispatch()

    const [cookies] = useCookies();

    const notify = ($msg, $time = 5000) => toast($msg, {
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    async function registroHandler(e) {
        e.preventDefault()
        dispatch({ type: 'OPEN_LOADING', text: "Salvando...", isLoading: true, })

        if (e.target.formGridApartamentosDisponiveis.value === '') {
            e.target.formGridApartamentosDisponiveis.value = 0
        } else {
            e.target.formGridApartamentosDisponiveis.value = e.target.formGridApartamentosDisponiveis.value.replace(/,/g, '.')
        }
        if (e.target.formGridApartamentosVendidos.value === '') {
            e.target.formGridApartamentosVendidos.value = 0
        } else {
            e.target.formGridApartamentosVendidos.value = e.target.formGridApartamentosVendidos.value.replace(/,/g, '.')
        }
        if (e.target.formGridReceitaLiquida.value === '') {
            e.target.formGridReceitaLiquida.value = 0
        } else {
            e.target.formGridReceitaLiquida.value = e.target.formGridReceitaLiquida.value.replace(/,/g, '.')
        }
        if (e.target.formGridMercadoriaConsumida.value === '') {
            e.target.formGridMercadoriaConsumida.value = 0
        } else {
            e.target.formGridMercadoriaConsumida.value = e.target.formGridMercadoriaConsumida.value.replace(/,/g, '.')
        }
        if (e.target.formGridMaoDeObra.value === '') {
            e.target.formGridMaoDeObra.value = 0
        } else {
            e.target.formGridMaoDeObra.value = e.target.formGridMaoDeObra.value.replace(/,/g, '.')
        }
        if (e.target.formGridHorasTrabalhadasCozinha.value === '') {
            e.target.formGridHorasTrabalhadasCozinha.value = 0
        } else {
            e.target.formGridHorasTrabalhadasCozinha.value = e.target.formGridHorasTrabalhadasCozinha.value.replace(/,/g, '.')
        }
        if (e.target.formGridHorasTrabalhadasrestmaisass.value === '') {
            e.target.formGridHorasTrabalhadasrestmaisass.value = 0
        } else {
            e.target.formGridHorasTrabalhadasrestmaisass.value = e.target.formGridHorasTrabalhadasrestmaisass.value.replace(/,/g, '.')
        }
        if (e.target.formGridValorizacaoInventario.value === '') {
            e.target.formGridValorizacaoInventario.value = 0
        } else {
            e.target.formGridValorizacaoInventario.value = e.target.formGridValorizacaoInventario.value.replace(/,/g, '.')
        }
        if (e.target.formGridNumeroSalarHotel.value === '') {
            e.target.formGridNumeroSalarHotel.value = 0
        } else {
            e.target.formGridNumeroSalarHotel.value = e.target.formGridNumeroSalarHotel.value.replace(/,/g, '.')
        }
        if (e.target.formGridSalasAlugadasMes.value === '') {
            e.target.formGridSalasAlugadasMes.value = 0
        } else {
            e.target.formGridSalasAlugadasMes.value = e.target.formGridSalasAlugadasMes.value.replace(/,/g, '.')
        }

        await api.post('/registrosgourmetmensal', {
            gourmet: {
                id: e.target.formGridUnidade.value + e.target.formGridData.value,
                data: e.target.formGridData.value,
                unidade_id: e.target.formGridUnidade.value,
                usuario_id: cookies.usuario.id,
                taxaocupacao: parseInt(e.target.formGridApartamentosVendidos.value) / parseInt(e.target.formGridApartamentosDisponiveis.value),
                apartamentosdisponiveis: parseInt(e.target.formGridApartamentosDisponiveis.value),
                apartamentosvendidos: parseInt(e.target.formGridApartamentosVendidos.value),
                receitaliquida: parseFloat(e.target.formGridReceitaLiquida.value),
                mercadoriaconsumida: parseFloat(e.target.formGridMercadoriaConsumida.value),
                maodeobra: parseFloat(e.target.formGridMaoDeObra.value),
                horastrabalhadascozinha: parseFloat(e.target.formGridHorasTrabalhadasCozinha.value),
                horastrabalhadasrestmaisass: parseFloat(e.target.formGridHorasTrabalhadasrestmaisass.value),
                valorizacaoinvestario: parseFloat(e.target.formGridValorizacaoInventario.value),
                numerodesalashotel: parseInt(e.target.formGridNumeroSalarHotel.value),
                salasalugadasmes: parseInt(e.target.formGridSalasAlugadasMes.value),
            }
        },{headers:{'authorization': `Bearer ${cookies.usuario.accessToken}`}}).then(res => {
            document.getElementById("formGourmetMensal").reset()
            dispatch({ type: 'CLOSE_LOADING', text: "", isLoading: false })
            notify(res.data.mensagem)
        })
    }





    return (
        <div className="registros-container">
            <div className="registros-body">
                <div className="registros-nomeHotel" style={{ textAlign: 'start' }}>Resultado Mensal:</div>
                <Form id="formGourmetMensal" onSubmit={registroHandler}>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3} controlId="formGridUnidade">
                            <Form.Label size='sm' style={{ float: 'left' }}>Unidade:</Form.Label>
                            <Form.Control as="select" size='sm' defaultValue="Unidade...">
                                {JSON.parse(sessionStorage.getItem('unidades')).filter(d=>d.restaurante).map(unidade => {
                                    return <option value={unidade.id}>{unidade.unidade}</option>
                                })}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3} controlId="formGridData">
                            <Form.Label size='sm' style={{ float: 'left' }}>Data:</Form.Label>
                            <Form.Control size='sm' type="date" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3} controlId="formGridApartamentosDisponiveis">
                            <Form.Label size='sm' style={{ float: 'left' }}>Apartamentos disponíveis:</Form.Label>
                            <Form.Control size='sm' placeholder="Apartamentos disponiveis" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3} controlId="formGridApartamentosVendidos">
                            <Form.Label size='sm' style={{ float: 'left' }}>Apartamentos vendidos:</Form.Label>
                            <Form.Control size='sm' placeholder="Apartamentos vendidos" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridReceitaLiquida">
                            <Form.Label size='sm' style={{ float: 'left' }}>Receita liquida:</Form.Label>
                            <Form.Control size='sm' placeholder="Receita liquida" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridMercadoriaConsumida">
                            <Form.Label size='sm' style={{ float: 'left' }}>Mercadoria consumida:</Form.Label>
                            <Form.Control size='sm' placeholder="Mercadoria consumida" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridValorizacaoInventario">
                            <Form.Label size='sm' style={{ float: 'left' }}>Valorização do inventário:</Form.Label>
                            <Form.Control size='sm' placeholder="Valorização do inventário" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridMaoDeObra">
                            <Form.Label size='sm' style={{ float: 'left' }}>Mão de obra:</Form.Label>
                            <Form.Control size='sm' placeholder="Mão de obra" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridHorasTrabalhadasCozinha">
                            <Form.Label size='sm' style={{ float: 'left' }}>Horas trabalhadas na cozinha:</Form.Label>
                            <Form.Control size='sm' placeholder="Cozinha" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4} controlId="formGridHorasTrabalhadasrestmaisass">
                            <Form.Label size='sm' style={{ float: 'left' }}>Horas trabalhadas rest + ass:</Form.Label>
                            <Form.Control size='sm' placeholder="Rest + ass" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6} controlId="formGridNumeroSalarHotel">
                            <Form.Label size='sm' style={{ float: 'left' }}>Número de salas no hotel:</Form.Label>
                            <Form.Control size='sm' placeholder="Número de salas no hotel" />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} md={6} lg={6} xl={6} controlId="formGridSalasAlugadasMes">
                            <Form.Label size='sm' style={{ float: 'left' }}>Salas alugadas no mês:</Form.Label>
                            <Form.Control size='sm' placeholder="Salas alugadas no mês" />
                        </Form.Group>
                    </Form.Row>

                    <Button variant="primary" size='sm' type="submit" style={{ float: 'right' }}>
                        Enviar
                    </Button>
                </Form>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                style={{zIndex: 1000000}}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover />
        </div>
    );
}

export default RegistroMensalGourmet;