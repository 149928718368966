import React from 'react';
import { useCookies } from 'react-cookie';
import readXlsxFile from 'read-excel-file'
import XMLParser from 'react-xml-parser';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// redux
import { useDispatch } from 'react-redux'

import api from '../../../../../services/api'



export default function TabAdmin() {


    const dispatch = useDispatch()
    const [cookies] = useCookies();
    let usuarioId = cookies.usuario.id

    const notify = ($msg, $time = 5000) => toast($msg, {
        position: "bottom-right",
        autoClose: $time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    async function handlerInputFileListaAcionistas(file) {

        if (!file.target.value.includes('xlsx')) {
            notify("Arquivo inválido!")
            document.getElementsByName('listaAcionistas')[0].value = null
            return
        }
        dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
        await readXlsxFile(file.target.files[0])
            .then(async res => {
                res.shift()
                var acionistas = []
                res?.map(linha => acionistas.push({
                    patrimonial_id: linha?.[0],
                    nome: linha?.[1],
                    "cpf/cnpj": linha?.[2],
                    destinatario: linha?.[3],
                    copia: linha?.[4],
                    acoes: linha?.[5],
                    pix: linha?.[6],
                    banco: linha?.[7],
                    agencia: linha?.[8],
                    conta: linha?.[9],
                    isPJ: linha?.[2].length === 18 ? true : false,
                    isPix: linha?.[6].length > 3 ? true : false,
                    reside_exterior: linha?.[10].substring(0, 1).toUpperCase() === 'S' ? true : false,
                    pais_residencia: linha?.[10].substring(0, 1).toUpperCase() === 'S' ? linha?.[11] : null,
                    usuario_id: cookies.usuario.id,
                    tipoPix: linha?.[6] === linha?.[2] ? '03' : linha?.[6].includes('@') ? '02' : linha?.[6].includes('+55') ? '01' : '04'
                })
                )
                try {
                    await api.post('/patrimoniais/create/acionistas', { acionistas }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                        .then(res => {
                            document.getElementsByName('listaAcionistas')[0].value = null
                            dispatch({ type: 'OPEN_LOADING', text: "Carregando dados...", isLoading: false })
                            if (res.status !== 201) return notify("Erro ao salvar, tente novamente mais tarde!")
                            return notify("Salvo com sucesso!")
                        })
                } catch (error) {
                    console.error(error)
                    document.getElementsByName('listaAcionistas')[0].value = null
                    dispatch({ type: 'OPEN_LOADING', text: "Carregando dados...", isLoading: false })
                    notify("Erro ao salvar, tente novamente mais tarde!")
                }
            })

    }

    async function handlerInputFileDadosBancarios(file) {
        if (!file.target.value.includes('xlsx')) {
            notify("Arquivo inválido!")
            document.getElementsByName('listaDadosBancarios')[0].value = null
            return
        }
        dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
        await readXlsxFile(file.target.files[0])
            .then(async res => {
                document.getElementsByName('listaDadosBancarios')[0].value = null
                res.shift()
                var dadosBancariosUpdate = []
                res.map((linha, idx) => {
                    if (('' + linha[2]).replace(/[^a-zA-Z]/g, '').length > 0) return notify('Erro na coluna C do acionista ' + linha[1])
                    if (('' + linha[3]).replaceAll('X', '').replaceAll('x', '').replace(/[^a-zA-Z]/g, '').length > 0) return notify('Erro na coluna D do acionista ' + linha[1])
                    if (('' + linha[4]).replace(/[^a-zA-Z]/g, '').length > 0) return notify('Erro na coluna E do acionista ' + linha[1])
                    if (('' + linha[5]).replaceAll('X', '').replaceAll('x', '').replace(/[^a-zA-Z]/g, '').length > 0) return notify('Erro na coluna F do acionista ' + linha[1])
                    if (('' + linha[6]).replaceAll('X', '').replaceAll('x', '').replace(/[^a-zA-Z]/g, '').length > 0) return notify('Erro na coluna G do acionista ' + linha[1])

                    return dadosBancariosUpdate.push({
                        patrimonial_id: linha[0],
                        nome: linha[1],
                        'cpf/cnpj': linha[2],
                        pix: linha[3],
                        tipoPix: '03',
                        codigobanco_id: linha[4],
                        agencia: linha[5],
                        conta: linha[6]
                    })
                })
                try {
                    await api.post('/patrimoniais/acionista/dadosBancarios/fix', {
                        listaAcionista: dadosBancariosUpdate
                    }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                } catch (error) {
                    console.error(error)
                    dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: false })
                    return notify("Erro ao salvar, tente novamente mais tarde!")
                }
                dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: false })
                return notify("Acionistas atualizados com sucesso!")
            })
    }

    async function handlerInputFileListaApartamentos(file) {

        if (!file.target.value.includes('xlsx')) {
            notify("Arquivo inválido!")
            document.getElementsByName('listaApartamentos')[0].value = null
            return
        }
        dispatch({ type: 'OPEN_LOADING', text: "Enviando dados...", isLoading: true })
        await readXlsxFile(file.target.files[0])
            .then(async res => {
                res.shift()
                var apartamentos = []
                res?.map(linha => apartamentos.push({
                    "cpf/cnpj": linha?.[0],
                    unidade_id: linha?.[1],
                    apartamentos: linha?.[2],
                    usuario_id: cookies.usuario.id,
                })
                )
                try {
                    await api.post('/patrimoniais/create/apartamentos', { apartamentos }, { headers: { 'authorization': `Bearer ${cookies.usuario.accessToken}` } })
                        .then(res => {
                            document.getElementsByName('listaApartamentos')[0].value = null
                            dispatch({ type: 'OPEN_LOADING', text: "Carregando dados...", isLoading: false })
                            if (res.status !== 201) return notify("Erro ao salvar, tente novamente mais tarde!")
                            return notify("Salvo com sucesso!")
                        })
                } catch (error) {
                    console.error(error)
                    document.getElementsByName('listaApartamentos')[0].value = null
                    dispatch({ type: 'OPEN_LOADING', text: "Carregando dados...", isLoading: false })
                    notify("Erro ao salvar, tente novamente mais tarde!")
                }
            })

    }

    const divStyle = {
        width: '100%',
        maxWidth: '600px',
        padding: '10px',
        borderBottom: '1px solid #eee',
    };

    const inputStyle = {
        cursor: 'pointer',
        padding: '8px 10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        outline: 'none',
        width: '100%',
        maxWidth: '600px',
        marginBottom: '5px',
    };

    const labelStyle = {
        display: 'block',
        marginTop: '5px',
    };


    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                padding: '20px',
                border: '1px dashed #ccc',
                borderRadius: '10px',
                marginTop: '20px',
                backgroundColor: '#f9f9f9',
                maxWidth: '1000px',
                margin: '20px auto',
            }}>
                <div style={{
                    width: '100%',
                    maxWidth: '600px',
                    padding: '10px',
                    borderBottom: '1px solid #eee',
                }}>
                    <div>
                        <label style={{ fontWeight: 'bold' }}>Lista de Acionistas Padrão: </label>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <input type="file" name="listaAcionistas" onChange={e => handlerInputFileListaAcionistas(e)} accept='.xlsx' style={inputStyle} />
                        <label style={labelStyle}>(Arquivo em xlsx) </label>
                    </div>
                </div>

                <div style={divStyle}>
                    <div>
                        <label style={{ fontWeight: 'bold' }}>Lista de Apartamentos Padrão: </label>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <input type="file" name="listaApartamentos" onChange={e => handlerInputFileListaApartamentos(e)} accept='.xlsx' style={inputStyle} />
                        <label style={labelStyle}>(Arquivo em xlsx) </label>
                    </div>
                </div>

                <div style={divStyle}>
                    <div>
                        <label style={{ fontWeight: 'bold' }}>Lista de Dados Bancários Acionistas: </label>
                    </div>
                    <div >
                        <input type="file" name="listaDadosBancarios" onChange={e => handlerInputFileDadosBancarios(e)} accept='.xlsx' style={inputStyle} />
                        <label style={labelStyle}></label>
                    </div>
                </div>
            </div>
        </>
    )
}

