import {combineReducers} from 'redux'

import searchBar from './searchBar'
import unidades from './unidades'
import loading from './loading'
import usuarioLogado from './usuarioLogado'
import cargos from './cargos'
import patrimoniais from './patrimoniais'

export default combineReducers({
    searchBar,
    unidades,
    loading,
    usuarioLogado,
    cargos,
    patrimoniais,
})