import api from "../../../../services/api";

interface ComboProps {
  unidade_id: any;
  mes: any;
  ano: any;
  cookies: any;
}

export async function Combo({ unidade_id, mes, ano, cookies }: ComboProps) {
  try {
    const response = await api.get(
      `/dre/realizadoxrealizado?unidade_id=${unidade_id}&mes=${mes}&ano=${ano}&usuario_id=${cookies.usuario.id}`,
      {
        headers: { authorization: `Bearer ${cookies.usuario.accessToken}` },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      // Captura a mensagem de erro personalizada retornada pela API
      const errorMessage = error.response.data.error;
      console.error("Erro ao carregar combos!", errorMessage);
      throw errorMessage; // Retorna a mensagem de erro personalizada
    } else {
      console.error("Erro ao carregar combos!", error.message);
      throw error.message; // Retorna undefined ou outro valor de sua escolha em caso de erro genérico
    }
  }
}

export default Combo;
