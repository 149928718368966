import React, { useState } from "react";
import { TableCellTitle, mockData, visibilidade } from "./mock.ts";
import {
  TableContainer,
  TableHeader,
  TableCell,
  TableRow,
  TableHeaderCell,
  TableCellPickUp,
  TableCellDiferenca,
  AuthLy,
  IconContainer,
  TittleContainer,
  Tittle,
} from "./styles.ts";
import { Action, Fab } from "react-tiny-fab";
import { BiFilterAlt, BiTargetLock } from "react-icons/bi";
import { BsEyeSlash, BsFillBarChartFill, BsTable } from "react-icons/bs";
import SwitchModalRevenue from "../../components/modals/models/modalMostrarEsconderRevenue.tsx";
import ChartComponent from "./chart.tsx";
import ChartModal from "../../components/modals/models/chartConstructorModal.tsx";
import HotelSelectorModal from "../../components/modals/models/revenueManagerModal.tsx";

interface SelectedData {
  hotels: string[];
  metric: string | null;
}

const RevenueManangerMobile: React.FC = () => {
  const [modalMostrarEsconder, setModalMostrarEsconder] = useState([false]);
  const [columnVisibility, setColumnVisibility] = useState(visibilidade);
  const isMobileDevice = () => {
    const maxWidth = 768;
    return window.innerWidth <= maxWidth;
  };
  const [showChartModal, setShowChartModal] = useState(false);
  const [showHotelModal, setShowHotelModal] = useState(isMobileDevice());
  const [chartType, setChartType] = useState("bar");
  const [metric, setMetric] = useState("taxaOcupacao");
  const [isChartVisible, setIsChartVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<SelectedData>({
    hotels: [],
    metric: null,
  });

  const handleChartUpdate = (newChartType, newMetric) => {
    setIsChartVisible(true);
    setChartType(newChartType);
    setMetric(newMetric);
  };

  const handleSelectedData = (data) => {
    setSelectedData(data);
  };

  const filteredHotels =
    selectedData.hotels.length > 0
      ? mockData.filter((hotel) => selectedData.hotels.includes(hotel.nome))
      : [];

  return (
    <AuthLy>
      <TittleContainer>
        <IconContainer style={{ alignSelf: "baseline" }}>
          <BiTargetLock />
        </IconContainer>
        <Tittle>Revenue Manager</Tittle>
      </TittleContainer>
      <TableContainer>
        {filteredHotels.length > 0 && (
          <>
            <TableHeader></TableHeader>
            <TableRow>
              {selectedData.metric === "all" && columnVisibility && (
                <>
                  {filteredHotels.map((hotel, index) => (
                    <div key={index}>
                      <TableHeaderCell>{hotel.nome}</TableHeaderCell>
                      {Object.keys(hotel.realizado).map((item) => (
                        <TableCell
                          style={{
                            display: columnVisibility["realizado_" + item]
                              ? "block"
                              : "none",
                          }}
                        >
                          {TableCellTitle[item] + " | " + hotel.realizado[item]}
                        </TableCell>
                      ))}
                      {Object.keys(hotel.orcado).map((item) => (
                        <TableCell
                          style={{
                            display: columnVisibility["orcado_" + item]
                              ? "block"
                              : "none",
                          }}
                        >
                          {TableCellTitle[item] + " | " + hotel.realizado[item]}
                        </TableCell>
                      ))}

                      {columnVisibility["pickUp"] && (
                        <TableCellPickUp>
                          {TableCellTitle["pickUp"] + " | " + hotel.pickUp}
                        </TableCellPickUp>
                      )}

                      {columnVisibility["diferencaBudget"] && (
                        <TableCellDiferenca color={hotel.corDiferenca}>
                          {TableCellTitle["diferencaBudget"] +
                            " | " +
                            hotel.diferencaBudget}
                        </TableCellDiferenca>
                      )}
                    </div>
                  ))}
                </>
              )}

              {selectedData.metric &&
                selectedData.metric !== "all" &&
                filteredHotels.map((hotel, index) => (
                  <>
                    <TableHeaderCell>{hotel.nome}</TableHeaderCell>
                    <TableCell>
                      {TableCellTitle[selectedData.metric ?? ""] +
                        " | " +
                        hotel.realizado[selectedData.metric ?? ""]}
                    </TableCell>
                  </>
                ))}
            </TableRow>
          </>
        )}
      </TableContainer>
      <Fab
        event="click"
        icon={<BiFilterAlt />}
        alwaysShowTitle={true}
        mainButtonStyles={{ backgroundColor: "#17a2b8" }}
        style={{
          bottom: 0,
          right: 0,
          zoom: "80%",
          margin: "20px",
          zIndex: 100,
        }}
      >
        <Action
          text="Ocultar informações "
          style={{ backgroundColor: "blue" }}
          onClick={() => {
            setModalMostrarEsconder([true]);
          }}
        >
          <BsEyeSlash />
        </Action>
        <Action
          style={{ backgroundColor: "blue" }}
          onClick={() => {
            setShowChartModal(true);
          }}
        >
          <BsFillBarChartFill />
        </Action>
        <Action
          text="Construir Gráfico"
          style={{ backgroundColor: "blue" }}
          onClick={() => {
            setShowHotelModal(true);
          }}
        >
          <BsTable />
        </Action>
      </Fab>
      <HotelSelectorModal
        show={showHotelModal}
        onHide={() => setShowHotelModal(false)}
        onConfirm={handleSelectedData}
        mockData={mockData}
      />
      {isChartVisible && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 30,
            paddingBottom: 30,
            height: "auto",
          }}
        >
          <Tittle>
            Gráfico de {chartType} sobre {metric}
          </Tittle>
          <ChartComponent height="400" chartType={chartType} metric={metric} />
        </div>
      )}
      <SwitchModalRevenue
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        modalMostrarEsconder={modalMostrarEsconder}
        setModalMostrarEsconder={setModalMostrarEsconder}
      />
      <ChartModal
        show={showChartModal}
        onHide={() => setShowChartModal(false)}
        onSelectType={setChartType}
        onSelectMetric={setMetric}
        onConfirm={handleChartUpdate}
      />
    </AuthLy>
  );
};

export default RevenueManangerMobile;
