import { useState, useEffect } from 'react';

export const useUnidades = () => {
    const [unidades, setUnidades] = useState([]); // Estado inicial como array vazio

    useEffect(() => {
        const unidadesString = sessionStorage.getItem("unidades");
        const unidadesCarregadas = unidadesString ? JSON.parse(unidadesString) : [];
        setUnidades(unidadesCarregadas);
    }, []);

    return unidades; // Sempre retorna um array
};

